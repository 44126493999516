<ngx-skeleton-loader
  count="1"
  appearance="circle"
  animation="progress-dark"
  [theme]="{
    height: '44px',
    width: '44px',
    margin: '0px'
  }"
  *ngIf="isLoading; else avatarSelector"
></ngx-skeleton-loader>

<ng-template #avatarSelector>
  <div class="avatar-box"
       [ngClass]="{ 'default-bg': avatarUrl === '-' || avatarUrl === '0'}"
       [ngStyle]="{'background-image': 'url(' + avatarUrl + ')'}"
  >
    <!-- Avatar image will be added here -->
    <input hidden type="file" #avatarSelector (change)="onFileSelected($event)">

    <!-- Add New avatar icon -->
    <div class="avatar-icon" (click)="avatarSelector.click()">
      <mat-icon>add_a_photo</mat-icon>
    </div>
  </div>
</ng-template>
