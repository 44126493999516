<div class="d-flex w-100">
  <div
    #autocompleteControl
    class="form-field new-styles"
    [ngClass]="{
      'w-small': inputSize === 'input-small',
      'w-semi-small': inputSize === 'input-semi-small',
      'w-medium': inputSize === 'input-medium',
      'w-large': inputSize === 'input-large'
    }"
    #autocomplete
    appearance="fill"
    #t="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    style="height: 42px !important"
  >
    <div>
      <input
        matInput
        readonly
        type="text"
        class="w-100 input-base"
        [formControl]="control"
        [value]="controlValue"
        placeholder="Please Select"
      />
      <div class="end-icon-box" style="height: 42px !important">
        <mat-icon [ngClass]="{ rotate: t.menuOpen }">
          keyboard_arrow_up
        </mat-icon>
      </div>

      <mat-menu
        #menu="matMenu"
        class="extended-menu singleSelectQ questionnaire"
      >
        <div
          #menuBox
          class="autocomplete-menu-box"
          [ngStyle]="{ width: controlWidthSingle + 38.25 + 'px' }"
        >
          <!-- Autocomplete options -->
          <div
            class="option-list-item"
            (click)="
              isMultiselect
                ? multiSelectionMade($event, option)
                : selectionMade($event, option)
            "
            *ngFor="
              let option of autocompleteOptions
                | autocompleteSearch : searchText
            "
            [ngClass]="{
              highlight: option === selectedOption
            }"
          >
            <span>
              <img
                class="selected"
                alt="selected item indicator"
                *ngIf="
                  option === selectedOption ||
                  (id === 'NameOfExchange' &&
                    selectedExchanges.indexOf(option) !== -1) ||
                  (id === 'NameOfRegulator' &&
                    selectedRegulators.indexOf(option) !== -1)
                "
                src="../../../../assets/icons/product-filter-active-indicator.svg"
              />
              <ng-container [ngSwitch]="id">
                <!-- Regulator Name -->
                <ng-container
                  *ngSwitchCase="questionnaireItemIds.NameOfRegulator"
                >
                  {{ option.ListOfRegulators }}
                </ng-container>

                <ng-container
                  *ngSwitchCase="questionnaireItemIds.CountryOfIncorporation"
                >
                  {{ option.Name }}
                </ng-container>

                <ng-container *ngSwitchCase="questionnaireItemIds.EntityType">
                  {{ option.Name }}
                </ng-container>

                <ng-container
                  *ngSwitchCase="questionnaireItemIds.EntitySubType"
                >
                  {{ option.Name }}
                </ng-container>

                <ng-container
                  *ngSwitchCase="questionnaireItemIds.HighRiskBusiness"
                >
                  {{ option.Name }}
                </ng-container>

                <ng-container
                  *ngSwitchCase="questionnaireItemIds.NameOfExchange"
                >
                  {{ option.ListOfExchange }}
                </ng-container>

                <ng-container
                  *ngSwitchCase="questionnaireItemIds.NameOfParentExchange"
                >
                  {{ option.ListOfExchange }}
                </ng-container>

                <ng-container
                  *ngSwitchCase="questionnaireItemIds.IsOwnershipKnown"
                >
                  {{ option.Value }}
                </ng-container>

                <ng-container
                  *ngSwitchCase="
                    questionnaireItemIds.ListedRegulatedGovernmentOwnership
                  "
                >
                  {{ option.Value }}
                </ng-container>

                <ng-container *ngSwitchDefault>
                  {{ option }}
                </ng-container>
              </ng-container>
            </span>
          </div>

          <!-- Search Box -->
          <div
            class="search-box"
            *ngIf="isAutocompleteSearchable"
            (click)="$event.stopPropagation()"
          >
            <div class="input-box">
              <input
                autofocus
                type="text"
                [(ngModel)]="searchText"
                placeholder="Search By Name"
              />
              <div class="search-icon">
                <img
                  alt="search-icon"
                  src="../../../../../../../assets/icons/search-questionnaire.svg"
                />
              </div>
            </div>
            <button
              (click)="handleSearchSubmit()"
              [disabled]="
                !selectionChangeMade ||
                (!selectionChangeMade && isOptionSelected)
              "
            >
              Submit
            </button>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>

  <!-- Selected options -->
  <div class="selection-box-multiselect" *ngIf="isMultiselect">
    <ng-container [ngSwitch]="id">
      <ng-container *ngSwitchCase="'NameOfExchange'">
        <ng-container *ngFor="let exchange of exchangesSelection">
          <div class="selected-item-multiselect">
            <span class="item-text">
              <img
                class="selected"
                alt="selected item indicator"
                src="../../../../assets/icons/product-filter-active-indicator.svg"
              />
              {{ exchange }}
            </span>
            <mat-icon
              class="remove-selection"
              (click)="handleRemoveExchangeOption(exchange)"
            >
              close
            </mat-icon>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'NameOfRegulator'">
        <ng-container *ngFor="let regulator of regulatorsSelection">
          <div class="selected-item-multiselect">
            <span class="item-text">
              <img
                class="selected"
                alt="selected item indicator"
                src="../../../../assets/icons/product-filter-active-indicator.svg"
              />
              {{ regulator }}
            </span>
            <mat-icon
              class="remove-selection"
              (click)="handleRemoveNameOfRegulatorOption(regulator)"
              >close</mat-icon
            >
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
