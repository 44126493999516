import {
  Inject,
  Component,
  OnDestroy,
  ChangeDetectorRef,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import {
  DateAdapter,
  MatDateFormats,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { CalendarHelper } from './calendar-helper';

@Component({
  selector: 'datepicker-custom-header-example',
  templateUrl: 'calendar.component.html',
  styleUrls: ['calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerCustomHeaderExample implements OnChanges {
  public exampleHeader = CustomCalendarHeader;

  constructor(private calendarHelper: CalendarHelper) {}

  public get today(): Date {
    return new Date();
  }

  @Input() public theme: string = '';
  @Input() elementIdx: number = -1;
  @Input() public selectedDate: Date = new Date();
  @Input() public selectedDateString: string = '--/--/----';
  @Output('dateChanged') onDateChanged: EventEmitter<any> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDate) {
      if (changes.selectedDate.currentValue) {
        const date = new Date(changes.selectedDate.currentValue);

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        const temp = new Date(
          changes.selectedDate.currentValue + 'Z'
        ).toLocaleDateString();

        this.selectedDateString =
          temp === 'Invalid Date' ? '--/--/----' : temp.replace(/\./g, '/');

        this.calendarHelper.updateCalendarDate(
          new Date(`${year}/${month}/${day}`)
        );
      } else {
        this.selectedDateString = '--/--/----';
      }
    }
  }

  createDate(date: Date) {
    return new Date(date);
  }

  dateChanged(event: any): void {
    this.onDateChanged.emit({ idx: this.elementIdx, value: event.value });
  }
}

@Component({
  selector: 'example-header',
  styles: [
    `
      .example-header {
        display: flex;
        align-items: center;
        padding: 0.5em;
      }

      .example-header-label {
        flex: 1;
        height: 1em;
        font-weight: 500;
        text-align: center;
      }

      .example-double-arrow .mat-icon {
        margin: -22%;
      }

      .justify-space-between {
        justify-content: space-between;
      }

      .docs-calendar {
        padding: 10px 16px 16px 16px;
      }

      .styled-label {
        color: #acacac;
        font-family: Poppins-Regular;
        font-size: 12px;
      }

      .year-selector {
        display: flex;
        flex-direction: column-reverse;
        width: 4rem;
      }
    `,
  ],
  template: `
    <div class="docs-calendar">
      <div
        class="d-flex w-100 justify-space-between"
        style="border-bottom: 1px solid #efebeb; padding-bottom: 15px"
      >
        <!-- month selector -->
        <div class="month-selector">
          <div class="d-flex styled-label">{{ periodLabel }}</div>
          <div>
            <mat-select [value]="months[currentMonth]?.value">
              <mat-option
                *ngFor="let month of months"
                [value]="month.value"
                (click)="changeMonth(month.value)"
              >
                {{ month.label }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <!-- year selector -->
        <div class="year-selector">
          <div>
            <mat-select id="year-selector" [value]="currentYear">
              <mat-option
                *ngFor="let year of years"
                [value]="year"
                (click)="changeYear(year)"
              >
                {{ year }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCalendarHeader<D> implements OnInit, OnDestroy {
  private _destroyed = new Subject<void>();

  public years: number[] = [];
  public selectedMonth: number = 0;

  public themeColor: string = '';
  public selectedDate: Date = new Date();

  public get currentMonth() {
    return this.selectedDate.getMonth();
  }

  public get currentYear() {
    return this.selectedDate.getFullYear();
  }

  constructor(
    private _calendar: MatCalendar<any>,
    private _dateAdapter: DateAdapter<D>,
    private calendarHelper: CalendarHelper,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());

    const currentYear = new Date().getFullYear();
    for (let i = currentYear - 50; i <= currentYear + 50; i++) {
      this.years.push(i);
    }
  }

  ngOnInit(): void {
    this._calendar.activeDate = new Date();

    this.calendarHelper.onCalendarDateChange().subscribe((date) => {
      this.selectedDate = date;
    });
  }

  months: { label: string; value: number }[] = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
  ];

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return moment().format('D MMM yyyy');
  }

  changeMonth(selectedMonth: number) {
    const activeDate = new Date(this._calendar.activeDate);
    activeDate.setMonth(selectedMonth - 1);
    this._calendar.activeDate = activeDate;
    this._calendar.updateTodaysDate();
  }

  changeYear(selectedYear: number) {
    const activeDate = new Date(this._calendar.activeDate);
    activeDate.setFullYear(selectedYear);
    this._calendar.activeDate = activeDate;
    this._calendar.updateTodaysDate();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
}
