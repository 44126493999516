<div class="test">

<div mat-dialog-title class="d-flex justify-between">
    <div class="dialog-label">{{ dialogHeader }}</div>

    <div class="close-btn" (click)="onCancelClick()">
        <div class="icon-wrapper">
            <div class="horizontal"></div>
            <div class="vertical"></div>
        </div>
    </div>
</div>


    <mat-dialog-content class="add-new-contact-dialog">
        <form [style.overflow]="'hidden'" [formGroup]="dialogForm">
            <!--  First Name  -->
            <div class="dialog-form-control">
                <mat-label>First Name</mat-label>
                <input type="text" class="text-input-styled" formControlName="FirstName"/>
            </div>

            <!--  Middle Name  -->
            <div class="dialog-form-control">
                <mat-label>Middle Name</mat-label>
                <input class="text-input-styled" formControlName="MiddleName"/>
            </div>

            <!--  Last Name  -->
            <div class="dialog-form-control">
                <mat-label>Last Name</mat-label>
                <input class="text-input-styled" formControlName="LastName"/>
            </div>

            <!--  Type  -->
            <div class="dialog-form-control">
                <mat-label>Type</mat-label>
                <mat-select class="select-input-styled" formControlName="Type">
                    <ng-container *ngFor="let option of dropdownData?.ContactTypes">
                        <mat-option [value]="option">{{ option }}</mat-option>
                    </ng-container>
                </mat-select>
            </div>

            <!--  Role  -->
            <div class="dialog-form-control">
                <mat-label>Role</mat-label>
                <mat-select class="select-input-styled" formControlName="Role">
                    <ng-container *ngFor="let option of dropdownData?.ContactRoles">
                        <mat-option [value]="option">{{ option }}</mat-option>
                    </ng-container>
                </mat-select>
            </div>

            <!--  Job Title   -->
            <div class="dialog-form-control">
                <mat-label>Job Title</mat-label>
                <input class="text-input-styled" formControlName="JobTitle"/>
            </div>

            <!--  Phone  -->
            <div class="dialog-form-control">
                <mat-label>Phone</mat-label>
                <input class="text-input-styled" formControlName="Phone"/>
            </div>

            <!--  Email  -->
            <div class="dialog-form-control">
                <mat-label>Email</mat-label>
                <input class="text-input-styled" formControlName="Email"/>
            </div>

            <!--  Is Primary  -->
            <div class="dialog-form-control d-flex flex-column">
                <mat-label>Primary</mat-label>
                <mat-checkbox class="checkbox-input" formControlName="IsPrimary"></mat-checkbox>
            </div>
        </form>
    </mat-dialog-content>


<mat-dialog-actions>
    <button
        mat-button
        class="action-btn-base continue"
        (click)="onContinueClick()"
    >
        Continue
    </button>
    <button mat-button class="action-btn-base cancel" (click)="onCancelClick()">
        Cancel
    </button>
</mat-dialog-actions>
</div>
