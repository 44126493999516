import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DocuLibraryService } from 'src/app/services/docu-library.service';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { Helper } from '../../../../../helpers/helper';

interface SelectedOption {
  CA: {
    selectedExemptionCategory: string;
    selectedExemptionOverride: string;
  };
  US: {
    selectedExemptionCategory: string;
    selectedExemptionOverride: string;
  };
  EUR: {
    selectedExemptionCategory: string;
    selectedExemptionOverride: string;
  };
  AUS: {
    selectedExemptionCategory: string;
    selectedExemptionOverride: string;
  };
}

@Component({
  selector: 'docu-library-exemptions',
  templateUrl: './exemptions.component.html',
  styleUrls: ['./exemptions.component.scss'],
})
export class DocuLibraryExemptionTab implements OnInit, AfterViewInit {
  public dataSource: any[] = [];
  panelOpenState: boolean[] = [];

  exemptionFormGroups: any[] = [];
  dropdownReqData!: any;
  initialExemptions!: any;
  dropdownsData: any = [];
  selectedOptions: SelectedOption = {
    CA: { selectedExemptionCategory: '', selectedExemptionOverride: '' },
    US: { selectedExemptionCategory: '', selectedExemptionOverride: '' },
    EUR: { selectedExemptionCategory: '', selectedExemptionOverride: '' },
    AUS: { selectedExemptionCategory: '', selectedExemptionOverride: '' },
  };

  @Input() isLoading: boolean = false;

  public get clientId() {
    return this.helper.getQuestionnaireClientId();
  }

  public getExemptionNameDisplayValue(index: number) {
    if (this.dropdownReqData) {
      const exemptionCategoryIds =
        this.initialExemptions[index].ExemptionNameIds;

      const displayNames = this.dropdownReqData.Exemptions.filter((x: any) =>
        exemptionCategoryIds.includes(x.Id)
      ).map((x: any) => x.Name);

      return displayNames.length ? displayNames : null;
    }
  }

  public getExemptionCategoryDisplayValue(index: number) {
    if (this.dropdownReqData) {
      const exemptionCategoryIds =
        this.initialExemptions[index].ExemptionCategoryIds;

      const categoryObjects = this.dropdownReqData.Exemptions.filter(
        (x: any) => x.ExemptionCategories.length
      )
        .map((x: any) => x.ExemptionCategories)
        .flat()
        .filter((x: any) => exemptionCategoryIds.includes(x.Id));

      const displayNames = categoryObjects.map((x: any) => x.Name);

      return displayNames.length ? displayNames : null;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private questionaireService: QuestionnaireService,
    private docuLibService: DocuLibraryService,
    private helper: Helper
  ) {}

  ngAfterViewInit() {}

  ngOnInit(): void {
    this.isLoading = true;
    this.questionaireService.getExemptionDropdowns().subscribe((res: any) => {
      this.dropdownReqData = res.Data;
      this.questionaireService
        .getClientExemption(this.clientId)
        .subscribe((r) => {
          this.isLoading = false;
          this.dataSource = r.Data;
          this.initialExemptions = r.Data;
          this.exemptionFormGroups = r.Data.map((e: any) => {
            const getExemptionCategories = (data: any) => {
              if (data) {
                const selectedExemptions = res.Data.Exemptions.filter(
                  (x: any) => x.ExemptionCategories.length
                )
                  .map((x: any) => x.ExemptionCategories)
                  .flat()
                  .filter((x: any) => e.ExemptionCategoryIds.includes(x.Id));

                return selectedExemptions;
              }

              return [];
            };

            const areaCode: 'CA' | 'US' | 'EUR' | 'AUS' =
              res.Data?.Exemptions.find((v: any) => v.AreaId === e.AreaId).Area
                .Code;

            this.selectedOptions[areaCode].selectedExemptionCategory =
              res.Data?.ExemptionOverrides.find(
                (v: any) => v.Id === e.ExemptionOverrideId
              )?.Name;

            this.selectedOptions[areaCode].selectedExemptionOverride =
              res.Data?.ExemptionOverrides?.find(
                (v: any) => v.Id === e.ExemptionOverrideId
              )?.OverrideExemptionCategories?.find(
                (category: any) => category.Id === e.OverrideExemptionCategoryId
              )?.Name;

            return this.formBuilder.group({
              AreaId: e.AreaId,
              AreaCode: res.Data?.Exemptions.find(
                (v: any) => v.AreaId === e.AreaId
              ).Area.Code,
              AreaName: res.Data?.Exemptions.find(
                (v: any) => v.AreaId === e.AreaId
              ).Area.Name,
              ClientId: e.ClientId,
              exemptionId: e.ExemptionNameIds,
              exemptionName: res.Data?.Exemptions.filter((v: any) =>
                e.ExemptionNameIds.includes(v.Id)
              ).map((v: any) => v.Id),

              exemptionCategory: getExemptionCategories(res.Data),

              exemptionOverride: res.Data?.ExemptionOverrides.find(
                (v: any) => v.Id === e.ExemptionOverrideId
              )?.Name,
              overrideExemptionCategory: res.Data?.ExemptionOverrides?.find(
                (v: any) => v.Id === e.ExemptionOverrideId
              )?.OverrideExemptionCategories?.find(
                (category: any) => category.Id === e.OverrideExemptionCategoryId
              )?.Name,
              comment: e.Comment,
            });
          });
          this.isLoading = false;
        });
    });
  }
}
