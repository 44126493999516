<div class="user-profile-page">
  <div class="user-profile-header">Profile</div>

  <div class="spinner-box" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>

  <form
    class="form-box"
    [formGroup]="userProfileForm"
    [ngClass]="{ 'hide-form': loading }"
  >
    <div class="full-name-box d-flex w-100">
      <!-- First name -->
      <styled-input-field
        class="w-100"
        label="Full Name"
        [control]="userProfileForm.controls['fullName']"
      >
      </styled-input-field>
    </div>

    <!-- Email -->
    <styled-input-field
      label="Email Address"
      [control]="userProfileForm.controls['email']"
    >
    </styled-input-field>

    <!-- Role -->
    <styled-input-field
      label="Role"
      control="role"
      [control]="userProfileForm.controls['role']"
    ></styled-input-field>

    <!-- Manager -->
    <styled-input-field
      label="Manager"
      control="manager"
      [control]="userProfileForm.controls['manager']"
    ></styled-input-field>
  </form>

  <!-- Change profile avatar -->
  <change-profile-avatar
    [customImages]="customImages"
    [profileImageUrl]="profileImgUrl"
    [profileImageBackground]="'#E7EBFB'"
  ></change-profile-avatar>

  <!-- Change Pass Button -->
  <a routerLink="/change-password" class="change-pass-btn">Change Password</a>
</div>
