<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Checkbox -->
    <ng-container matColumnDef="Checkbox">
        <th mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
            <div class="d-flex t-head-styled"></div>
        </th>
        <td mat-cell *matCellDef="let element" class="mat-column-checkbox">
            <div class="t-body-styled id-section" [style.color]="'#7D8187'">
                <mat-checkbox (change)="handleCheckboxChange(element)" [checked]="element.isSelected"></mat-checkbox>
            </div>
        </td>
    </ng-container>

    <!-- ID -->
    <ng-container matColumnDef="ClientId">
        <th mat-header-cell *matHeaderCellDef class="mat-column-id">
            <div class="d-flex t-head-styled">
                <app-active-sorting-indicator
                    *ngIf="isSortingActive(tableColumns.CustomerId)"
                >
                </app-active-sorting-indicator>
                {{ tableColumns.CustomerId }}
                <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting(tableColumns.CustomerId)"
                    [isActive]="isSortingActive(tableColumns.CustomerId)"
                >
                </app-sort-indicator>
            </div>
        </th>
        <td mat-cell *matCellDef="let element" class="mat-column-id">
            <div class="t-body-styled id-section" [style.color]="'#7D8187'">
                {{ element.ClientId }}
            </div>
        </td>
    </ng-container>

    <!-- Account Number -->
    <ng-container matColumnDef="AccountNumber">
        <th mat-header-cell *matHeaderCellDef [style.color]="'#6E6893'">
            <div class="d-flex t-head-styled">
                <!-- <app-active-sorting-indicator *ngIf="isSortingActive(tableColumns.AccountNumber)"> -->
                <!-- </app-active-sorting-indicator> -->
                {{ tableColumns.AccountNumber }}
                <!-- <app-sort-indicator -->
                <!-- [sortDirection]="sortingDirection" -->
                <!-- (click)="handleSorting(tableColumns.AccountNumber)" -->
                <!-- [isActive]="sortingColumn === tableColumns.AccountNumber"> -->
                <!-- </app-sort-indicator> -->
            </div>
        </th>
        <td mat-cell *matCellDef="let element">
            <div class="d-flex align-center h-100 w-100">
                <div
                    class="t-body-styled id-section cut-text"
                    [style.color]="'#7D8187'"
                    matTooltipPosition="before"
                    matTooltipClass="custom-tooltip-acc-num"
                    [matTooltip]="getAccountNums(element.AccountNumber)"
                >

                    {{ displayAccountNumber(element.AccountNumber) }}
                </div>
            </div>
            <div class="t-body-styled account-number"></div>
        </td>
    </ng-container>

    <!-- Legal Name -->
    <ng-container matColumnDef="LegalName">
        <th mat-header-cell *matHeaderCellDef>
            <div class="d-flex t-head-styled">
                <app-active-sorting-indicator
                    *ngIf="isSortingActive(tableColumns.LegalName)"
                >
                </app-active-sorting-indicator>
                {{ tableColumns.LegalName }}
                <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting(tableColumns.LegalName)"
                    [isActive]="sortingColumn === tableColumns.LegalName"
                >
                </app-sort-indicator>
            </div>
        </th>
        <td mat-cell *matCellDef="let element">
            <div
                class="t-body-styled legal-name-styled"
                (click)="handleLegalNameClick(element)"
            >
                {{ element.LegalName ? element.LegalName : "-" }}
            </div>
        </td>
    </ng-container>

    <!-- LEI -->
    <ng-container matColumnDef="Lei">
        <th mat-header-cell *matHeaderCellDef>
            <div class="d-flex t-head-styled">
                <app-active-sorting-indicator *ngIf="isSortingActive(tableColumns.Lei)">
                </app-active-sorting-indicator>
                {{ tableColumns.Lei }}
                <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting(tableColumns.Lei)"
                    [isActive]="sortingColumn === tableColumns.Lei"
                >
                </app-sort-indicator>
            </div>
        </th>
        <td mat-cell *matCellDef="let element">
            <div class="t-body-styled">
                {{ element.Lei ? element.Lei : "-" }}
            </div>
        </td>
    </ng-container>

    <!-- Type -->
    <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef class="mat-column-status">
            <div class="d-flex t-head-styled">
                <app-active-sorting-indicator
                    *ngIf="isSortingActive(tableColumns.Type)"
                >
                </app-active-sorting-indicator>
                {{ tableColumns.Type }}
                <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting(tableColumns.Type)"
                    [isActive]="sortingColumn === tableColumns.Type"
                >
                </app-sort-indicator>
            </div>
        </th>
        <td mat-cell *matCellDef="let element" class="mat-column-status">
            <div class="t-body-styled">
                {{ element.Type }}
            </div>
        </td>
    </ng-container>

    <!-- Status -->
    <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef class="mat-column-status">
            <div class="d-flex t-head-styled">
                <app-active-sorting-indicator
                    *ngIf="isSortingActive(tableColumns.Status)"
                >
                </app-active-sorting-indicator>
                {{ tableColumns.Status }}
                <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting(tableColumns.Status)"
                    [isActive]="sortingColumn === tableColumns.Status"
                >
                </app-sort-indicator>
            </div>
        </th>
        <td mat-cell *matCellDef="let element" class="mat-column-status">
            <div
                [class]="
          't-body-styled t-status-base' + ' ' + getStatusClass(element.Status)
        "
            >
                {{ element.Status ? toUpperCase(element.Status) : "-" }}
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns; let i = index"
        [style.border-bottom]="
      i !== dataSource.length - 1 ? '0.5px solid #B3B3B380' : ''
    "
    ></tr>
</table>
