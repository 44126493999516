import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { Helper } from '../helpers/helper';
import { Observable } from 'rxjs/internal/Observable';
import { LeftMenuModel } from '../models/left-menu';
import { UpdateUserStatusModel, UserAccessModel } from '../models/user';

@Injectable()
export class UserService extends BaseService {
  private baseUrl: string = environment.baseUrl;
  public errorMessage!: string;
  constructor(private http: HttpClient, helper: Helper) {
    super(helper);
  }

  public putUserStatus(
    userStatus: UpdateUserStatusModel
  ): Observable<LeftMenuModel> {
    var data = JSON.stringify(userStatus);
    return this.http.put<any>(
      this.baseUrl + `/user/userStatus`,
      data,
      super.header()
    );
  }

  public getAnalysts(userId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/user/analysts`, {
      params: new HttpParams().set('userId', userId),
      headers: super.header().headers,
    });
  }

  public getPubSubUrl(userId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/user/pubSubUrl`, {
      params: new HttpParams().set('userId', userId),
      headers: super.header().headers,
    });
  }

  public getUserAccess(params: {
    PageNumber: number;
    PageSize: number;
    SortBy: string;
    SortDirection: string;
    FilterColumns:
      | [
          {
            Name: string;
            Includes: string[];
          }
        ]
      | [];
  }): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + `/user/userAccess`,
      {
        ...params,
        PageNumber: params.PageNumber + 1,
      },
      { headers: super.header().headers }
    );
  }

  public updateUserAccess(users: UserAccessModel[]): Observable<any> {
    var data = JSON.stringify(users);
    return this.http.post<any>(
      this.baseUrl + `/user/postUsersAccess?userId=` + this.helper.getUserId(),
      data,
      super.header()
    );
  }

  public deleteUserAccess(deleteUserId: string): Observable<any> {
    return this.http.delete<any>(this.baseUrl + `/user/deleteUserAccess`, {
      params: new HttpParams().set('userAccessId', deleteUserId),
      headers: super.header().headers,
    });
  }

  public getUserProfileData(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/user/profile`, {
      params: new HttpParams().set('userId', this.helper.getUserId()),
      headers: super.header().headers,
    });
  }

  public postUserProfileIcon(avatar: FormData): Observable<any> {
    return this.http.post<any>(this.baseUrl + `/user/profileIcon`, avatar, {
      headers: super.header().headers.delete('Content-Type'), // deleted content-type header since by default we have it set to 'application/json'
    });
  }

  public putSelectedUserProfileIcon(avatarUrl: string): Observable<any> {
    const queryParams = avatarUrl;

    return this.http.put<any>(
      this.baseUrl + `/user/profileAvatar?avatarUrl=${queryParams}`,
      {},
      super.header()
    );
  }

  public setNewPassword(oldPass: string, newPass: string): Observable<any> {
    const data = JSON.stringify({ OldPassword: oldPass, NewPassword: newPass });
    return this.http.post<any>(
      this.baseUrl + `/User/password?userId=` + this.helper.getUserId(),
      data,
      super.header()
    );
  }

  public confirmEmailRegistration(
    token: string,
    email: string
  ): Observable<any> {
    const data = JSON.stringify({ token: token, email: email });
    return this.http.post<any>(
      this.baseUrl + `/User/confirmEmail`,
      data,
      super.header()
    );
  }

  public resetPassword(
    token: string,
    email: string,
    password: string
  ): Observable<any> {
    const data = JSON.stringify({
      token: token,
      email: email,
      password: password,
    });
    return this.http.post<any>(
      this.baseUrl + `/User/resetPassword`,
      data,
      super.header()
    );
  }

  public forgotPassword(email: string): Observable<any> {
    const data = JSON.stringify({ Email: email });
    return this.http.post<any>(
      this.baseUrl + `/User/forgotPassword`,
      data,
      super.header()
    );
  }

  public getUserAccessColumnValues(columnName: string): Observable<string[]> {
    return this.http.get<any>(this.baseUrl + `/User/getColumnValues`, {
      params: new HttpParams().set('columnName', columnName),
      headers: super.header().headers,
    });
  }

  public getUserAccessFilteredColumnValues(
    columnName: string,
    appliedFilters: {
      Name: string;
      Includes: string[];
      SortDirection?: string;
    }[]
  ): Observable<string[]> {
    appliedFilters.map((x) => {
      delete x.SortDirection;
      return x;
    });

    return this.http.post<any>(
      this.baseUrl + `/Filter/postUserAccessFilter?columnName=${columnName}`,
      appliedFilters,
      {
        headers: super.header().headers,
      }
    );
  }
}
