<div mat-dialog-title s>
  <h1 class="dialog-heading">Descope</h1>
</div>

<div mat-dialog-content>
  <div class="d-flex" style="flex-direction: column">
    <ng-container *ngFor="let descopeItem of data">
      <div class="company-name-wrapper">
        <div class="company-logo">
          <img
            style="width: 77px; height: 77px; margin-right: 1rem"
            src="./../assets/icons/KG.svg"
            alt=""
          />
        </div>

        <div
          class="d-flex"
          style="
            justify-content: space-between;
            width: 720px;
            align-items: center;
          "
        >
          <div class="company-name-type">
            <div class="company-name">{{ descopeItem.ClientName }}</div>
            <div class="company-type">{{ descopeItem.ClientType }}</div>
          </div>
          <img
            class="cursor-pointer"
            (click)="handleRemove(descopeItem)"
            style="width: 40px; height: 40px"
            src="./../assets/icons/descope-delete.svg"
            alt=""
          />
        </div>
      </div>
    </ng-container>
  </div>

  <div style="color: #c7c7c7; font-size: 16px; margin-bottom: 1rem">
    DESCRIPTION
  </div>

  <textarea
    class="text-area"
    [(ngModel)]="descopeReason"
    placeholder="Specify reason for descope"
  ></textarea>
</div>

<div mat-dialog-actions class="actions-wrapper">
  <button mat-button (click)="handleClose()" class="descope-btn-cancel">
    Cancel
  </button>
  <button mat-button (click)="handleSave()" class="descope-btn-submit">
    Save
  </button>
</div>
