import { SelectionModel } from '@angular/cdk/collections';
import {
  OnInit,
  Component,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import {
  ProductDropdownsData,
  ProductModel,
} from 'src/app/models/questionnaire';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { map, startWith } from 'rxjs/operators';
import { Helper } from 'src/app/helpers/helper';

export interface Products {
  AntecedentStatus: string | null;
  AnticipatedActivity: string | null;
  Approver: string | null;
  Booking: string | null;
  Bucket: string | null;
  ClientId: number | null;
  Comment: string | null;
  Compliance: string | null;
  Currency: string | null;
  Date: string | null;
  DateApproved: string | null;
  DateRequested: string | null;
  DateStatusChange: string | null;
  Id: number;
  IntendedUseOfAccount: string | null;
  Limit: string | null;
  Name: string | null;
  ProductCategory: string | null;
  ProductType: string | null;
  Requestor: string | null;
  Selling: string | null;
  SourceSystem: string | null;
  Status: string | null;
  IsSelected: boolean;
  IsNotSaved?: boolean | null;
}

export interface ProductUpdateModel {
  CaseId: number;
  ProductToSave: Products[];
}

export interface SelectedProductInfo {
  Id: number;
  Requestor: string;
  DateRequested: string;
  Approver: string;
  DateApproved: string;
  AntecedentStatus: string;
  DateStatusChange: string;
  AnticipatedActivity: string;
  SourceSystem: string;
  Comment: string;
  IntendedUseOfAccount: string;
  Limit: string;
  Currency: string;
  Status: string;
}

interface ApplyFilterPayload {
  columnName: string;
  filters: string[];
}

export interface ProductFiltersPreparationPayload {
  ClientId: number;
  PageNumber: number;
  PageSize: number;
  SortBy: string;
  SortDirection: string;
  FilterColumns: any;
}

@Component({
  selector: 'questionnaire-table',
  templateUrl: './questionnaire-table.component.html',
  styleUrls: ['./questionnaire-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'wiam-select-overlay' },
    },
  ],
})
export class QuestionnaireTableComponent
  implements OnInit, AfterViewInit, OnChanges
{
  public pageSize: number = 20;
  public pageNumber: number = 1;
  public sortingDirection: string = 'desc';
  public sortBy: string = 'Id'; // by default filtering by Product Id, another available value would be Date
  public filteringColumns: any = {};
  public addNewProductInProgress: boolean = false;
  public isEditMode: boolean = false;
  public isApprovalComplianceOverride: boolean = false;
  public changesWereMade: boolean = false;
  public editingStarted: boolean = false;
  public currentDate: string = moment().format('YYYY-MM-DD hh:mm:ss');
  public dropdownsCommonData: ProductDropdownsData = {
    ProductCategories: [],
    Bookings: [],
    Sellings: [],
    Statuses: [],
    Compilances: [],
    Buckets: [],
  };
  public productTypes: string[] = [];
  public currentEditNode: ProductModel = {
    Id: 0,
    Booking: '',
    Bucket: '',
    ClientId: 0,
    Compliance: '',
    Date: '',
    Name: '',
    ProductCategory: '',
    ProductType: '',
    Selling: '',
    Status: '',
    Requestor: '',
    DateRequested: '',
    Approver: '',
    DateApproved: '',
    AntecedentStatus: '',
    DateStatusChange: '',
    AnticipatedActivity: '',
    SourceSystem: '',
    Comment: '',
    IntendedUseOfAccount: '',
    Limit: '',
    Currency: '',
    IsSelected: false,
    IsNotSaved: true,
  };

  @Input() public loading: boolean = false;
  public selection = new SelectionModel<Products>(true, []);
  @Input() public dataSource: Products[] = [];
  public displayedColumns: string[] =
    this.currentCaseStage === 'Approval'
      ? [
          'checkbox',
          'productId',
          'productCategory',
          'productType',
          'Booking',
          'selling',
          'status',
          'compliance',
          'bucket',
          'date',
          'information',
          'threeDotsMenu',
        ]
      : [
          'checkbox',
          'productId',
          'productCategory',
          'productType',
          'Booking',
          'selling',
          'status',
          'compliance',
          'bucket',
          'date',
          'information',
        ];
  public selectedTableRows: number[] = [];

  selectedProductInfo: any = {
    Id: 0,
    Requestor: '',
    DateRequested: '',
    Approver: '',
    DateApproved: '',
    AntecedentStatus: '',
    DateStatusChange: '',
    AnticipatedActivity: '',
    SourceSystem: '',
    Comment: '',
    IntendedUseOfAccount: '',
    Limit: '',
    Currency: '',
    Status: '',
  };

  productsThatShouldBeCreated: ProductModel[] = [];
  updatedProducts: Products[] = [];

  productForm: FormGroup = new FormGroup({
    ProductCategory: new FormControl('', [Validators.required]),
    ProductType: new FormControl('', [Validators.required]),
    Booking: new FormControl('', [Validators.required]),
    Selling: new FormControl('', [Validators.required]),
    Status: new FormControl('', [Validators.required]),
    Compliance: new FormControl('', []),
  });

  bookingOptions: string[] = [];
  sellingOptions: string[] = [];
  complianceOptions: string[] = [];
  filteredBookings!: Observable<string[]>;
  filteredSellings!: Observable<string[]>;

  selectedViewProduct: Products | undefined;

  public get currentCaseStage() {
    return this.helper.getQuestionnaireCaseStage();
  }

  public isCheckboxActiveInitialReview(row: any): boolean {
    return (
      this.currentCaseStage === 'Initial Review' &&
      row.Status !== 'Active' &&
      row.Status !== 'Frozen'
    );
  }

  public isCheckAllActiveInitialReview(): boolean {
    return this.dataSource.some(
      (x) => x.Status === 'Active' || x.Status === 'Frozen'
    );
  }

  constructor(
    private questionnaireService: QuestionnaireService,
    private helper: Helper
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedTableRows = this.dataSource
      .filter((item: any) => item.IsSelected)
      .map((item: any) => item.Id);
  }

  @Output('onSetProductInfo') productInfoEmitter = new EventEmitter<Event>();
  @Output('onStopEditingProduct') stopEditEmitter = new EventEmitter();

  @Output('onProductSelection') selectProductEmitter = new EventEmitter();

  @Input() public clientId: string = '';
  @Output() applyProductFilterEmitter =
    new EventEmitter<ProductFiltersPreparationPayload>();

  @Input() public isCheckboxReadonly: boolean = false;

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.dataSource
        .filter((x) => x.Status === 'Active' || x.Status === 'Frozen')
        .forEach((product) => {
          if (product.Status !== 'Closed') {
            product.IsSelected = false;
            const idx = this.updatedProducts.findIndex(
              (p) => p.Id === product.Id
            );
            if (idx === -1) {
              this.updatedProducts.push(product);
            } else {
              this.updatedProducts[idx].IsSelected = false;
            }
          }
        });
    } else {
      this.dataSource
        .filter((x) => x.Status === 'Active' || x.Status === 'Frozen')
        .forEach((product, index) => {
          if (product.Status !== 'Closed') {
            product.IsSelected = true;
            const idx = this.updatedProducts.findIndex(
              (p) => p.Id === product.Id
            );
            if (idx === -1) {
              this.updatedProducts.push(product);
            } else {
              this.updatedProducts[idx].IsSelected = true;
            }
          }
        });
    }
    this.stopEditEmitter.emit({
      addNewProductInProgress: this.addNewProductInProgress,
      isEdited: true,
      updatedProducts: this.updatedProducts,
      newProducts: this.productsThatShouldBeCreated,
    });
  }

  isAllSelected() {
    if (this.dataSource.length) {
      const validProducts = this.dataSource.filter(
        (val) => val.Status !== 'Closed'
      );
      const selectedProducts = validProducts.filter((val) => val.IsSelected);
      return validProducts.length === selectedProducts.length;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
    this.questionnaireService.getDropDownsData().subscribe((res: any) => {
      this.dropdownsCommonData = res.Data;
      this.bookingOptions = res.Data.Bookings;
      this.sellingOptions = res.Data.Sellings;
      this.complianceOptions = res.Data.Compilances;
    });
    this.filteredBookings = this.productForm.controls.Booking.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', this.bookingOptions))
    );
    this.filteredSellings = this.productForm.controls.Selling.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', this.sellingOptions))
    );
  }

  ngAfterViewInit(): void {}

  private _filter(value: string, filteringOptions: any[]): string[] {
    const filterValue = value.toLowerCase();
    return filteringOptions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  setProductInfo(element: Products) {
    if (element) {
      for (const key of Object.keys(this.selectedProductInfo)) {
        this.selectedProductInfo[key] = element[key as keyof Products];
      }
      this.selectedProductInfo.productId = element.Id;
      this.productInfoEmitter.emit(this.selectedProductInfo);

      this.selectedViewProduct = element;
    }
    this.selectedProductInfo.Id = element.Id;
    this.productInfoEmitter.emit(this.selectedProductInfo);
  }

  modelChanged(event: string, field: any) {
    this.dataSource = this.dataSource.map((el: any) => {
      if (el.Id === this.currentEditNode.Id) {
        el[field] = event;
      }
      return el;
    });
    this.changesWereMade = true;
  }

  handleEditProduct(element?: any) {
    if (this.currentCaseStage === 'Approval') {
      this.displayedColumns = [
        'checkbox',
        'productId',
        'productCategory',
        'productType',
        'Booking',
        'selling',
        'status',
        'compliance',
        'bucket',
        'date',
        'information',
      ];
    }

    if (element) {
      const index = this.dataSource.indexOf(element);
      this.dataSource[index].IsNotSaved = true;
      const updateIndex = this.productsThatShouldBeCreated.indexOf(element);

      if (updateIndex !== -1) {
        this.productsThatShouldBeCreated = this.productsThatShouldBeCreated.map(
          (x, index) => {
            if (index === updateIndex) {
              x.IsNotSaved = true;
            }
            return x;
          }
        );
      }

      this.isEditMode = true;
      this.currentEditNode = { ...element };

      this.questionnaireService
        .getProductTypes(this.currentEditNode.ProductCategory || '')
        .subscribe((res: any) => {
          this.productTypes = res.Data;
        });
    } else {
      this.addNewProductInProgress = true;
      this.dataSource = [
        {
          Id: 0,
          Name: '',
          ProductCategory: null,
          ProductType: null,
          Booking: null,
          Selling: null,
          Status: 'Requested',
          Compliance: '-',
          Bucket: 'Onboarded On',
          Date: new Date().toJSON(),
          ClientId: +this.clientId,
          Requestor: '',
          DateRequested: null,
          Approver: '',
          DateApproved: null,
          AntecedentStatus: '',
          DateStatusChange: new Date().toJSON(),
          AnticipatedActivity: '',
          SourceSystem: '',
          Comment: '',
          IntendedUseOfAccount: '',
          Limit: '',
          Currency: '',
          IsSelected: true,
          IsNotSaved: true,
        },
        ...this.dataSource,
      ];
      this.currentEditNode = this.dataSource[0];
    }
    this.productForm.setValue({
      ProductCategory: this.currentEditNode.ProductCategory,
      ProductType: this.currentEditNode.ProductType,
      Booking: this.currentEditNode.Booking,
      Selling: this.currentEditNode.Selling,
      Status: this.currentEditNode.Status,
      Compliance: this.currentEditNode.Compliance,
    });

    this.stopEditEmitter.emit({
      addNewProductInProgress: this.addNewProductInProgress,
      isEditMode: this.isEditMode,
      // isEdited: false,
    });
  }

  handleOverrideApprovalCompliance(element?: any) {
    this.isEditMode = true;
    this.isApprovalComplianceOverride = true;

    if (this.currentCaseStage === 'Approval') {
      this.displayedColumns = [
        'checkbox',
        'productId',
        'productCategory',
        'productType',
        'Booking',
        'selling',
        'status',
        'compliance',
        'bucket',
        'date',
        'information',
      ];
    }

    this.currentEditNode = { ...element };
    this.productForm.setValue({
      ProductCategory: this.currentEditNode.ProductCategory,
      ProductType: this.currentEditNode.ProductType,
      Booking: this.currentEditNode.Booking,
      Selling: this.currentEditNode.Selling,
      Status: this.currentEditNode.Status,
      Compliance: this.currentEditNode.Compliance,
    });

    this.stopEditEmitter.emit({
      addNewProductInProgress: this.addNewProductInProgress,
      isEditMode: this.isEditMode,
    });
  }

  handleEditingNodeProductCategoryChange(
    selectionEvent: any,
    elementId: number
  ): void {
    const elementIndex = this.dataSource.findIndex(
      (data) => data.Id === elementId && data.IsNotSaved
    );

    this.dataSource[elementIndex].ProductCategory = selectionEvent.value;
    this.dataSource[elementIndex].ProductType = '';
    this.dataSource = [...this.dataSource];
    this.productForm.controls['ProductType'].setValue('');
    this.currentEditNode = { ...this.dataSource[elementIndex] };

    this.questionnaireService
      .getProductTypes(selectionEvent.value)
      .subscribe((res: any) => {
        this.productTypes = res.Data;
      });
  }

  handleEditingNodeProductTypeChange(
    selectionEvent: any,
    elementId: number
  ): void {
    const elementIndex = this.dataSource.findIndex(
      (data) => data.Id === elementId && data.IsNotSaved
    );
    this.dataSource[elementIndex].ProductType = selectionEvent.value;
    this.dataSource = [...this.dataSource];
    this.productForm.controls['ProductType'].setValue(selectionEvent.value);
    this.currentEditNode = { ...this.dataSource[elementIndex] };
  }

  handleEditingNodeBookingChange(selectionEvent: any, elementId: number): void {
    const elementIndex = this.dataSource.findIndex(
      (data) => data.Id === elementId && data.IsNotSaved
    );
    this.dataSource[elementIndex].Booking = selectionEvent.option.value;
    this.dataSource = [...this.dataSource];
    this.productForm.controls['Booking'].setValue(selectionEvent.option.value);
    this.currentEditNode = { ...this.dataSource[elementIndex] };
  }

  handleEditingNodeSellingChange(selectionEvent: any, elementId: number): void {
    const elementIndex = this.dataSource.findIndex(
      (data) => data.Id === elementId && data.IsNotSaved
    );
    this.dataSource[elementIndex].Selling = selectionEvent.option.value;
    this.dataSource = [...this.dataSource];
    this.productForm.controls['Selling'].setValue(selectionEvent.option.value);
    this.currentEditNode = { ...this.dataSource[elementIndex] };
  }

  handleEditingNodeStatusChange(selectionEvent: any, elementId: number): void {
    // this.dataSource[elementIndex].Status = selectionEvent.value;
    // this.dataSource = [...this.dataSource];
    this.productForm.controls['Status'].setValue(selectionEvent.value);
    this.currentEditNode.Status = selectionEvent.value;
    this.currentEditNode.IsSelected = true;

    const index = this.dataSource.findIndex(
      (x) => x.Id === this.currentEditNode.Id
    );

    if (index !== -1) {
      this.dataSource[index].IsSelected = true;
    }
  }

  handleEditingNodeComplianceChange(
    selectionEvent: any,
    elementId: number
  ): void {
    this.productForm.controls['Compliance'].setValue(selectionEvent.value);
    this.currentEditNode.Compliance = selectionEvent.value;
  }

  handleCheckboxChange(event: Products): void {
    const selectedRowId = event.Id;
    event.IsSelected = !event.IsSelected;

    const sourceIdx = this.dataSource.findIndex((p) => p.Id === selectedRowId);
    const updtIdx = this.updatedProducts.findIndex(
      (p) => p.Id === selectedRowId
    );
    if (updtIdx >= 0) {
      this.updatedProducts.splice(updtIdx, 1);
    } else {
      this.updatedProducts.unshift(this.dataSource[sourceIdx]);
    }

    this.selectProductEmitter.emit(
      this.selectedTableRows[this.selectedTableRows.length - 1]
    );
    this.stopEditEmitter.emit({
      addNewProductInProgress: this.addNewProductInProgress,
      isEdited: true,
      updatedProducts: this.updatedProducts,
      newProducts: this.productsThatShouldBeCreated,
    });

    if (!this.selectedTableRows.some((x) => x === selectedRowId)) {
      this.selectedTableRows.push(selectedRowId);
    } else {
      const rowIndex = this.selectedTableRows.indexOf(selectedRowId);

      this.selectedTableRows = [
        ...this.selectedTableRows.filter((x, index) => index !== rowIndex),
      ];
    }

    this.selectProductEmitter.emit(
      this.selectedTableRows[this.selectedTableRows.length - 1]
    );
  }

  checkIfSelected(id: number, itemStatus: string): boolean {
    if (itemStatus === 'Closed') {
      return false;
    } else {
      return this.selectedTableRows.some((x) => x === id);
    }
  }

  checkIfRowInEditMode(row: any): boolean {
    if (this.addNewProductInProgress && row.Id === 0)
      return this.currentEditNode.Id === row.Id && row.IsNotSaved;
    else if (this.isEditMode && row.Id === 0)
      return this.currentEditNode.Id === row.Id && row.IsNotSaved;
    else if (
      (this.addNewProductInProgress || this.isEditMode) &&
      this.currentEditNode.Id === row.Id &&
      row.Id !== 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleStopEditing(): void {
    if (this.currentEditNode.Id === 0 && !this.isEditMode) {
      this.dataSource.splice(0, 1);
      const updatedDataSource = [...this.dataSource];
      this.dataSource = updatedDataSource;
    }

    this.addNewProductInProgress = false;
    this.isEditMode = false;
    this.isApprovalComplianceOverride = false;

    this.dataSource = [...this.dataSource].map((x) => {
      x.IsNotSaved = false;
      return x;
    });

    this.stopEditEmitter.emit({
      addNewProductInProgress: this.addNewProductInProgress,
      isEditMode: this.isEditMode,
      isEdited: false,
      updatedProducts: this.updatedProducts,
      newProducts: this.productsThatShouldBeCreated,
    });

    this.currentEditNode = {
      Id: 0,
      Booking: '',
      Bucket: '',
      ClientId: +this.clientId,
      Compliance: '',
      Date: '',
      Name: '',
      ProductCategory: '',
      ProductType: '',
      Selling: '',
      Status: '',
      Requestor: '',
      DateRequested: '',
      Approver: '',
      DateApproved: '',
      AntecedentStatus: '',
      DateStatusChange: '',
      AnticipatedActivity: '',
      SourceSystem: '',
      Comment: '',
      IntendedUseOfAccount: '',
      Limit: '',
      Currency: '',
      IsSelected: false,
      IsNotSaved: true,
    };

    if (this.currentCaseStage === 'Approval') {
      this.displayedColumns = [
        'checkbox',
        'productId',
        'productCategory',
        'productType',
        'Booking',
        'selling',
        'status',
        'compliance',
        'bucket',
        'date',
        'information',
        'threeDotsMenu',
      ];
    }
  }

  saveProductChanges(element: any): void {
    element = {
      ...element,
      ...this.productForm.value,
    };

    if (element.Id === 0 && !this.isEditMode) {
      const updatedDatasource = this.dataSource.filter(
        (product) => product.Id !== 0
      );
      const itemToUnshift = this.dataSource[0];

      itemToUnshift.IsNotSaved = false;
      this.productsThatShouldBeCreated.unshift(itemToUnshift);
      this.dataSource = [
        ...this.productsThatShouldBeCreated,
        ...updatedDatasource,
      ];
    } else {
      if (element.Id === 0) {
        const updtIdx = this.productsThatShouldBeCreated.findIndex(
          (p) => p.Id === element.Id && p.IsNotSaved
        );
        this.productsThatShouldBeCreated[updtIdx] = element;
      } else {
        const updtIdx = this.updatedProducts.findIndex(
          (p) => p.Id === element.Id
        );
        if (updtIdx >= 0) {
          this.updatedProducts[updtIdx] = element;
        } else {
          this.updatedProducts.unshift(element);
        }
      }
      if (element.Id !== 0) {
        const dataSourceIdx = this.dataSource.findIndex(
          (p) => p.Id === element.Id
        );

        this.dataSource[dataSourceIdx] = element;
      }
      this.dataSource = [...this.dataSource];
    }

    this.addNewProductInProgress = false;
    this.isEditMode = false;
    this.isApprovalComplianceOverride = false;

    this.stopEditEmitter.emit({
      addNewProductInProgress: this.addNewProductInProgress,
      isEditMode: this.isEditMode,
      isEdited: this.productForm.dirty,
      updatedProducts: this.updatedProducts,
      newProducts: this.productsThatShouldBeCreated,
    });

    this.currentEditNode = {
      Id: 0,
      Booking: '',
      Bucket: '',
      ClientId: +this.clientId,
      Compliance: '',
      Date: '',
      Name: '',
      ProductCategory: '',
      ProductType: '',
      Selling: '',
      Status: '',
      Requestor: '',
      DateRequested: '',
      Approver: '',
      DateApproved: '',
      AntecedentStatus: '',
      DateStatusChange: '',
      AnticipatedActivity: '',
      SourceSystem: '',
      Comment: '',
      IntendedUseOfAccount: '',
      Limit: '',
      Currency: '',
      IsSelected: false,
      IsNotSaved: true,
    };

    this.dataSource.map((x) => {
      x.IsNotSaved = false;
      return x;
    });

    if (this.currentCaseStage === 'Approval') {
      this.displayedColumns = [
        'checkbox',
        'productId',
        'productCategory',
        'productType',
        'Booking',
        'selling',
        'status',
        'compliance',
        'bucket',
        'date',
        'information',
        'threeDotsMenu',
      ];
    }
  }

  public cleanEmptyArrays(obj: any): void {
    for (let propName in obj) {
      if (obj[propName].length === 0) {
        delete obj[propName];
      }
    }
    return obj;
  }

  public handleEmitApplyFilterEvent(): void {
    const requestPayload: ProductFiltersPreparationPayload = {
      ClientId: +this.clientId,
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      SortBy: this.sortBy,
      SortDirection: this.sortingDirection,
      FilterColumns: this.cleanEmptyArrays(this.filteringColumns),
    };

    this.applyProductFilterEmitter.emit(requestPayload);
  }

  public handleApplyFilters(filterData: ApplyFilterPayload): void {
    this.filteringColumns[`${filterData.columnName}`] = filterData.filters;

    this.handleEmitApplyFilterEvent();
  }

  public handleSortByDate(): void {
    this.sortBy = 'Date';
    this.sortingDirection = this.sortingDirection === 'desc' ? 'asc' : 'desc';

    this.handleEmitApplyFilterEvent();
  }

  public isColumnFiltered(column: any): boolean {
    return column in this.filteringColumns;
  }

  public getAvailableStatuses(status: string | null): string[] {
    if (this.currentCaseStage === 'Approval') {
      switch (status) {
        case 'Frozen': return ['Active', 'Closed'];
        case 'Active': return ['Closed', 'Frozen'];
        case 'Requested': return ['Active', 'Frozen'];

        default: return ['Active', 'Closed', 'Frozen'];
      }

    } else {
      switch (status) {
        case 'Requested': return ['Pending freezing', 'Pending closure'];
        case 'Pending freezing': return ['Pending closure'];
        case 'Pending closure': return ['Pending freezing'];

        default: return ['Pending freezing', 'Pending closure'];
      }
    }
  }

  public onSelectOpen(event: any): void {
    const overlay = document.querySelector('.cdk-overlay-pane') as HTMLElement;
    const selectWidth = event.target.closest('.wiam-select').offsetWidth;
    overlay.style.minWidth = `${selectWidth}px`;
    overlay.style.maxWidth = `${selectWidth}px`;
    overlay.style.width = `${selectWidth}px`;
  }

  checkIfEdited(row: any) {
    this.updatedProducts.some((x) => x.Id === row.Id);
  }
}
