import { Component, OnInit } from '@angular/core';
import { StatisticService } from 'src/app/services/statistic.service';
import { AnalyticDataSource } from 'src/app/services/analytic.datasource';

@Component({
  selector: 'app-initial-review',
  templateUrl: './initial-review.component.html',
  styleUrls: ['./initial-review.component.css'],
})
export class InitialReviewComponent implements OnInit {
  public noDataAvailable: boolean = false;
  dataSource: AnalyticDataSource = new AnalyticDataSource(
    this.statisticService
  );

  public displayedColumns = [
    'NudgeIdentifier',
    'CheckBox',
    'ClientId',
    'ClientName',
    'ParentGrouping',
    'DueDateStr',
    'CountryOfIncorporation',
    'Revenue',
    'Bucket',
    'ClientType',
    'Analyst',
    'QCReviewer',
    'ClientContactTitle',
    'ClientContactEmail',
    'ClientContactName',
    'CompletionDateStr',
    'InitialOutreachDateStr',
    'ReasonForDescope',
    'Products',
    'RiskScore',
    // 'Status',
    // 'Stage',
    // 'RowMenu',
    // 'Status',
    // 'Stage',
    'Aging',
    'Link',
    'NextStage',
    'RowMenu',
  ];

  constructor(public statisticService: StatisticService) {}

  ngOnInit(): void {}

  changeDisplayedColumns(chips: any) {
    this.displayedColumns = chips;
  }

  handleNoTableData(event: boolean) {
    this.noDataAvailable = event;
  }
}
