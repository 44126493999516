<div class="d-flex flex-column">
  <div
    style="height: 42px !important"
    class="form-field new-form-field"
    [ngClass]="{
      'w-small': inputSize === 'input-small',
      'w-medium': inputSize === 'input-medium',
      'w-large': inputSize === 'input-large'
    }"
    appearance="fill"
  >
    <input
      matInput
      type="text"
      aria-label="Number"
      [formControl]="control"
      class="input-base"
      [value]="inputValue"
      placeholder="Enter Text Here"
    />
    <div class="end-icon-box" style="height: 42px !important">
      <mat-icon> keyboard_arrow_up </mat-icon>
    </div>
  </div>

  <span *ngIf="showValidationError && elId === 'NewLegalName'" class="error-msg"
    >This field cannot be empty</span
  >
</div>
