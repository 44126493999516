import { FormControl } from '@angular/forms';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import {
  Countries,
  EntityTypes,
  ExchangesItem,
  HightRiskBusiness,
  InputTypesByLength,
  KnownOwnerships,
  QuestionnaireItemIds,
  RegulatedGovernmentOwnership,
  Regulators,
  SubEntityTypes,
} from '../../tab-data-table/types';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QuestionnaireDataList } from '../../tab-container/types';

@Component({
  selector: 'autocomplete-component',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class QuestionnaireAutocomplete
  implements OnInit, OnChanges, AfterViewInit, AfterViewChecked, OnDestroy
{
  public selectedOption: string = '';
  public filteredOptions: string[] | undefined;
  public searchText: string = '';
  public autocompleteControlWidth: number = 0;
  public autocompleteControlHeight: number = 0;
  public initialValue: string = '';
  public questionnaireItemIds;
  public controlValue: string = '';
  public controlSubTypeValue: string = '';
  public nameIdsList: QuestionnaireItemIds[] = [
    QuestionnaireItemIds.NameOfRegulator,
    QuestionnaireItemIds.CountryOfIncorporation,
    QuestionnaireItemIds.EntityType,
    QuestionnaireItemIds.EntitySubType,
    QuestionnaireItemIds.HighRiskBusiness,
  ];
  public selectionChangeMade: boolean = false;
  private destroy$ = new Subject<void>();
  public selectedExchanges: {
    Description: string;
    Id: number;
    IsCRAApproved: boolean;
    ListOfExchange: string;
  }[] = [];
  public selectedRegulators: Regulators[] = [];
  public exchangesSelection: string[] = [];
  public regulatorsSelection: string[] = [];

  constructor() {
    this.questionnaireItemIds = QuestionnaireItemIds;
  }

  @Input() public id: QuestionnaireItemIds | undefined;
  @Input() public inputLabel: string = '';
  @Input() public questionnaireData: QuestionnaireDataList | undefined;
  @Input() public placeholder: string = '';
  @Input() public autocompleteOptions: any[] = [];
  @Input() public inputSize: InputTypesByLength = InputTypesByLength.smallInput;
  @Input() public control: FormControl = new FormControl('');
  @Input() public isAutocompleteSearchable: boolean = false;
  @Input() public isMultiselect: boolean = false;

  @Output() public hideEntitySubType = new EventEmitter();

  @ViewChild(MatMenuTrigger) autocomplete: MatMenuTrigger | undefined;
  @ViewChild('autocompleteControl') autocompleteControl: ElementRef =
    new ElementRef(null);
  // @ViewChild('autocomplete') control: ElementRef = new ElementRef(null);

  @ViewChild('menu') menu!: MatMenu;
  @ViewChildren('menuPanel') menuPanels!: QueryList<ElementRef>;
  @ViewChild('menuBox') menuBox!: ElementRef;

  public get isOptionSelected(): boolean {
    return !!this.selectedOption;
  }

  public get controlWidthSingle() {
    return this.autocompleteControl?.nativeElement?.offsetWidth || 0;
  }

  public controlValuesUpdate(): void {
    switch (this.id) {
      case QuestionnaireItemIds.CountryOfIncorporation:
        const selectedCountryOfIncorporation = this
          .selectedOption as unknown as Countries;

        this.controlValue = selectedCountryOfIncorporation
          ? selectedCountryOfIncorporation.Name
          : '';
        break;

      case QuestionnaireItemIds.EntityType:
        this.controlValue = this.control.value?.Name || null;
        break;

      case QuestionnaireItemIds.EntitySubType:
        this.controlValue = this.control.value || null;
        break;

      case QuestionnaireItemIds.ClientHasAssetManagerInvestAdvisor:
        break;

      case QuestionnaireItemIds.HighRiskBusiness:
        this.controlValue = this.control.value?.Name || null;
        break;

      case QuestionnaireItemIds.NameOfExchange:
        this.selectedExchanges = this.control.value
          ? this.control.value.map(
              (x: { NameOfExchange: string; NameOfExchangeId: number }) => {
                const autocompleteOption = this.autocompleteOptions.find(
                  (element) => element.Id === x.NameOfExchangeId
                );

                return autocompleteOption;
              }
            )
          : [];

        const selection = this.selectedExchanges.map((x) => x.ListOfExchange);
        this.exchangesSelection = selection;

        this.controlValue =
          selection && selection.length ? selection.join(', ') : '';
        break;

      case QuestionnaireItemIds.NameOfParentExchange:
        this.controlValue = this.control.value?.ListOfExchange || null;
        break;

      case QuestionnaireItemIds.NameOfRegulator:
        this.selectedRegulators = this.control.value
          ? this.control.value.map(
              (x: { NameOfRegulator: string; NameOfRegulatorId: number }) => {
                const autocompleteOption = this.autocompleteOptions.find(
                  (element) => element.Id === x.NameOfRegulatorId
                );

                return autocompleteOption;
              }
            )
          : [];

        const regulatorSelection = this.selectedRegulators.map(
          (x) => x.ListOfRegulators
        );
        this.regulatorsSelection = regulatorSelection;

        this.controlValue =
          regulatorSelection && regulatorSelection.length
            ? regulatorSelection.join(', ')
            : '';
        break;

      case QuestionnaireItemIds.IsOwnershipKnown:
        this.controlValue = this.control.value
          ? this.control.value.Value
          : null;
        break;

      case QuestionnaireItemIds.ListedRegulatedGovernmentOwnership:
        this.controlValue = this.control.value?.Value || null;
        break;

      case QuestionnaireItemIds.InvestmentRevenueBreakdownSourceOfFunds:
        switch (this.control.value) {
          case 1:
            this.controlValue = 'Investment Breakdown';
            break;
          case 2:
            this.controlValue = 'Revenue Breakdown';
            break;
          case 3:
            this.controlValue = 'Source of Funds';
            break;

          default:
            this.controlValue = '';
            break;
        }
        break;

      default:
        break;
    }
  }

  ngOnInit(): void {
    // presetting existing value
    this.control.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((newValue) => {
        if (!this.selectedOption) {
          this.selectedOption = newValue;
        }
        this.controlValuesUpdate();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formControl) {
      if (changes.id.currentValue === 'EntitySubType') {
        this.selectedOption = this.autocompleteOptions.find(
          (x) => x.Name === this.control.value
        );
      }

      if (changes.id.currentValue === 'ListedRegulatedGovernmentOwnership') {
        this.selectedOption = this.autocompleteOptions.find(
          (x) => x.Value === this.control.value
        );
      }
    }
  }

  ngAfterViewInit(): void {
    this.menuBox.nativeElement.style.width =
      this.autocompleteControl.nativeElement.offsetWidth + 38.5 + 'px';
  }

  ngAfterViewChecked(): void {
    const element = this.autocompleteControl.nativeElement;
    if (element && element.offsetWidth !== this.autocompleteControlWidth) {
      this.autocompleteControlWidth = element.offsetWidth;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public multiSelectionMade(event: Event, option: any): void {
    event.stopPropagation();
    this.selectionChangeMade = true;

    switch (this.id) {
      case QuestionnaireItemIds.NameOfExchange:
        const exchange = this.selectedExchanges.find(
          (el) => el.Id === option.Id
        );

        !exchange
          ? this.selectedExchanges.push(option)
          : (this.selectedExchanges = this.selectedExchanges.filter(
              (el) => el.Id !== exchange.Id
            ));

        if (this.selectedExchanges.length === 0) {
          this.control.setValue(null);
          return;
        }
        break;

      case QuestionnaireItemIds.NameOfRegulator:
        const regulator = this.selectedRegulators.find(
          (el) => el.Id === option.Id
        );

        !regulator
          ? this.selectedRegulators.push(option)
          : (this.selectedRegulators = this.selectedRegulators.filter(
              (el) => el.Id !== regulator.Id
            ));

        if (this.selectedRegulators.length === 0) {
          this.control.setValue(null);
          return;
        }
        break;

      default:
        break;
    }
  }

  public selectionMade(event: Event, option: string): void {
    !this.isAutocompleteSearchable ? null : event.stopPropagation();
    this.selectionChangeMade = true;
    this.selectedOption = this.selectedOption === option ? '' : option;

    if (!this.selectedOption) {
      this.control.setValue(null);
      return;
    }

    switch (this.id) {
      case QuestionnaireItemIds.IsOwnershipKnown:
        const selectedIsOwnershipKnown = this
          .selectedOption as unknown as KnownOwnerships;

        this.controlValue = selectedIsOwnershipKnown.Value;

        this.control.setValue({
          ...selectedIsOwnershipKnown,
        });
        break;

      case QuestionnaireItemIds.ListedRegulatedGovernmentOwnership:
        const selectedListedRegulatedGovernmentOwnership = this
          .selectedOption as unknown as RegulatedGovernmentOwnership;

        this.controlValue = selectedListedRegulatedGovernmentOwnership.Value;

        this.control.setValue({
          ...selectedListedRegulatedGovernmentOwnership,
        });
        break;

      case QuestionnaireItemIds.NameOfParentExchange:
        const selectedNameOfParentExchange = this
          .selectedOption as unknown as ExchangesItem;

        this.controlValue = selectedNameOfParentExchange.ListOfExchange;

        this.control.setValue(selectedNameOfParentExchange.ListOfExchange);
        break;

      default:
        if (!this.isAutocompleteSearchable) this.control.setValue(option);
        break;
    }

    if (!this.isAutocompleteSearchable) this.control.setValue(option);
  }

  public handleSearchSubmit(): void {
    this.selectionChangeMade = false;
    switch (this.id) {
      case QuestionnaireItemIds.CountryOfIncorporation:
        const selected = this.selectedOption as unknown as Countries;
        this.controlValue = selected.Name;

        this.control.setValue({
          ...selected,
        });
        break;

      case QuestionnaireItemIds.EntityType:
        const selectedEntityType = this
          .selectedOption as unknown as EntityTypes;
        this.controlValue = selectedEntityType.Name;

        this.hideEntitySubType.emit();
        this.control.setValue({
          ...selectedEntityType,
        });
        break;

      case QuestionnaireItemIds.EntitySubType:
        const selectedEntitySubType = this
          .selectedOption as unknown as SubEntityTypes;
        this.controlValue = selectedEntitySubType.Name;

        this.control.setValue(selectedEntitySubType.Name);
        break;

      case QuestionnaireItemIds.HighRiskBusiness:
        const selectedHighRiskBusiness = this
          .selectedOption as unknown as HightRiskBusiness;
        this.controlValue = selectedHighRiskBusiness.Name;

        this.control.setValue({
          ...selectedHighRiskBusiness,
        });
        break;

      case QuestionnaireItemIds.InvestmentRevenueBreakdownSourceOfFunds:
        const selectedBreakdown = this.selectedOption as unknown as number;

        switch (selectedBreakdown) {
          case 1:
            this.controlValue = 'Investment Breakdown';
            break;
          case 2:
            this.controlValue = 'Revenue Breakdown';
            break;
          case 3:
            this.controlValue = 'Source of Funds';
            break;

          default:
            this.controlValue = '';
            break;
        }

        this.control.setValue({
          selectedBreakdown,
        });
        break;

      case QuestionnaireItemIds.NameOfExchange:
        this.controlValue = this.selectedExchanges
          .map((x) => x.ListOfExchange)
          .join(', ');

        this.control.setValue(
          this.selectedExchanges.map((x) => ({
            NameOfExchange: x.ListOfExchange,
            NameOfExchangeId: x.Id,
          }))
        );
        break;

      case QuestionnaireItemIds.NameOfRegulator:
        this.controlValue = this.selectedRegulators
          .map((x) => x.ListOfRegulators)
          .join(', ');

        this.control.setValue(
          this.selectedRegulators.map((x) => ({
            NameOfRegulator: x.ListOfRegulators,
            NameOfRegulatorId: x.Id,
          }))
        );
        break;

      default:
        this.control.setValue(this.selectedOption);
        break;
    }

    this.autocomplete?.closeMenu();
    this.searchText = '';
  }

  public checkIfNameIsKey(id: QuestionnaireItemIds): boolean {
    return this.nameIdsList.includes(id);
  }

  public onMenuOpened(): void {
    const menuPanel = document.querySelector(
      '#' + this.menu.panelId
    ) as HTMLElement;
    const rect = menuPanel.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const bottomOffset = windowHeight - rect.bottom;
    const menuHeight = menuPanel.scrollHeight;

    if (bottomOffset < menuHeight) {
      menuPanel.scrollTop = menuHeight - windowHeight + bottomOffset;
      menuPanel.scrollIntoView();
    }
  }

  public handleRemoveNameOfRegulatorOption(selectedOption: any): void {
    const updatedExchangeOptions = this.control.value.filter(
      (x: { NameOfRegulator: string }) => x.NameOfRegulator !== selectedOption
    );
    console.log(updatedExchangeOptions);

    this.control.setValue(updatedExchangeOptions);
    this.controlValue = updatedExchangeOptions
      .map((x: { NameOfRegulator: string }) => x.NameOfRegulator)
      .join(', ');
  }

  public handleRemoveExchangeOption(selectedExchangeOption: string): void {
    const updatedExchangeOptions = this.control.value.filter(
      (x: { NameOfExchange: string }) =>
        x.NameOfExchange !== selectedExchangeOption
    );

    this.control.setValue(updatedExchangeOptions);
    this.controlValue = updatedExchangeOptions
      .map((x: { NameOfExchange: string }) => x.NameOfExchange)
      .join(', ');
  }
}
