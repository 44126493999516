<div class="d-flex left-menu-wrapper">
    <ng-container *ngIf="childRouteActive; else parentRoute">
        <div class="docu-lib-wrapper-box-left-panel">
            <div class="d-flex collapse-menu info-section-box">
                <div class="section-wrapper"></div>
                <div class="d-flex">
                    <div class="avatar-upload">
                        <app-avatar-uploader [avatarUrl]="selectedCaseAvatarUrl"></app-avatar-uploader>
                    </div>
                    <div class="info-section d-flex">
                        <div class="studio-name">{{ selectedLegalName }}</div>
                        <div class="studio-id">ID : {{ currentDocuLibId }}</div>
                    </div>
                </div>
            </div>

            <mat-nav-list class="sidemenu">
                <ng-container *ngFor="let tab of tabs">
                    <a
                        mat-list-item
                        class="nav-tab-link"
                        [routerLink]="[tab.routerLink]"
                        [routerLinkActive]="['selected-docu-lib-item']"
                        [class.selected-item-border]="isOnProfileChildRoute(tab)"
                        [disabled]="tab && tab.subTabs && tab.subTabs.length"
                    >
                        <div
                            class="nav-caption"
                            [routerLinkActive]="['nav-caption-selected']"
                        >
                            <div class="icon-text">
                                <img
                                    routerLinkActive
                                    alt="analytics icon"
                                    src="{{ tab.imgUrl }}"
                                    #rla="routerLinkActive"
                                    class="button-base docu-lib-sidebar-icon-base"
                                />
                                <span class="tab-label">
                  {{ tab.tabName }}
                </span>
                            </div>
                        </div>
                    </a>

                    <ng-container *ngIf="tab.subTabs && tab.subTabs.length">
                        <ng-container *ngFor="let subTab of tab.subTabs">
                            <a
                                mat-list-item
                                class="nav-tab-link sub-tab"
                                [routerLink]="[subTab.routerLink]"
                                [routerLinkActive]="['selected-docu-lib-item']"
                            >
                                <div
                                    class="nav-caption sub-tab-caption"
                                    [routerLinkActive]="['nav-caption-selected']"
                                >
                                    <div class="icon-text">
                    <span class="tab-label">
                      {{ subTab.tabName }}
                    </span>
                                    </div>
                                </div>
                            </a>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </mat-nav-list>
        </div>

        <div class="d-flex justify-content-center" style="margin-left: -28px">
            <img
                class="login-logo"
                src="../../../assets/icons/login-logo.svg"
                alt=""
            />
        </div>
    </ng-container>

    <ng-template #parentRoute>
        <ng-container>
            <div class="form-wrapper">
                <div class="search-label">Search</div>
                <form [formGroup]="clientForm" (ngSubmit)="onSubmit()">
                    <!-- Customer ID -->
                    <div class="d-flex form-control-styled">
                        <label for="id">Customer ID</label>
                        <input type="text" id="id" formControlName="id"/>
                    </div>

                    <!-- Account Number -->
                    <div class="d-flex form-control-styled">
                        <label for="accountNumber">Account Number</label>
                        <input
                            type="text"
                            id="accountNumber"
                            formControlName="accountNumber"
                        />
                    </div>

                    <!-- Legal Name -->
                    <div class="d-flex form-control-styled">
                        <label for="legalName">Legal Name</label>
                        <input type="text" id="legalName" formControlName="legalName"/>
                    </div>

                    <!-- LEI -->
                    <div class="d-flex form-control-styled">
                        <label for="lei">LEI</label>
                        <input type="text" id="lei" formControlName="lei"/>
                    </div>

                    <!-- Client Only Checkbox -->
                    <div class="d-flex form-control-styled flex-row client-only">
                        <mat-checkbox
                            formControlName="clientOnly"
                            class="checkbox-input no-pointer-events"
                        ></mat-checkbox>
                        <div>Client Only</div>
                    </div>

                    <button mat-button class="docu-lib-search-button">Search</button>
                </form>
            </div>

            <div
                class="d-flex justify-content-center logo-wrapper"
            >
                <img
                    class="login-logo"
                    src="../../../assets/icons/login-logo.svg"
                    alt=""
                />
            </div>
        </ng-container>
    </ng-template>
</div>
