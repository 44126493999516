<div>
  <div *ngIf="!authentication && !isSetPassRoute"><app-login></app-login></div>
  <div *ngIf="!authentication && isForgotPassRoute">
    <router-outlet></router-outlet>
  </div>

  <div *ngIf="authentication || isSetPassRoute">
    <mat-sidenav-container fullscreen>
      <mat-sidenav
        opened
        mode="side"
        [@openClose]="sidenavOpened"
        [ngClass]="{
          sidenav: isOpen && isOnDashboardApp,
          'sidenav-collapsed': !isOpen && isOnDashboardApp
        }"
        [class.dashboard-theme]="isOnDashboardApp"
        [class.docu-library-theme]="isOnDocuLibApp"
      >
        <ng-container [ngSwitch]="getAppType">
          <!-- Dashboard Side Nav -->
          <ng-container *ngSwitchCase="appTypes.Dashboard">
            <app-left-panel
              [isNavOpened]="isOpen"
              (handleSidenavOpenClose)="handleOpenClose()"
            >
            </app-left-panel>
          </ng-container>

          <!-- Docu Library Side Nav -->
          <ng-container *ngSwitchCase="appTypes.DocLibrary">
            <docu-library-left-panel></docu-library-left-panel>
          </ng-container>

          <ng-container *ngSwitchDefault></ng-container>
        </ng-container>
      </mat-sidenav>

      <mat-sidenav-content
        class="sidenav-content"
        [ngStyle]="{ 'margin-left': isOpen ? '300px' : '100px' }"
      >
        <app-head
          *ngIf="showAppHead"
          [showSearch]="showSearch"
          [isUserMenuFixed]="isUserMenuFixed"
        ></app-head>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
