import { Pipe, PipeTransform } from '@angular/core';
import { Countries, Regions } from './types';

@Pipe({ name: 'autocompleteMultiselectSearch' })
export class AutocompleteMultiselectSearchPipe<T> implements PipeTransform {
  transform(items: T[], searchText: string): T[] {
    if (!items) {
      return [];
    }

    if (!searchText) {
      return items;
    }

    searchText = searchText.toLocaleLowerCase();

    if (items.some((x) => (x as unknown as Countries).CountryName)) {
      return items.filter((it) =>
        (it as unknown as Countries).CountryName.toLocaleLowerCase().includes(
          searchText
        )
      );
    }

    return items.filter((it) =>
      (it as unknown as Regions).Name.toLocaleLowerCase().includes(searchText)
    );
  }
}
