<div mat-dialog-title class="dialog-heading">
    Alert Message
</div>

<div mat-dialog-content class="dialog-content">
    <span>
        <span class="client-name">
            {{ data.structureName }}
        </span>
        <span>
            and its associations will be deleted.
        </span>
    </span>
</div>

<div mat-dialog-actions class="dialog-actions">
    <button (click)="handleDeleteClick()" class="btn-base delete-button">Delete</button>
    <button mat-dialog-close class="btn-base cancel-button">Cancel</button>
</div>
