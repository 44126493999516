import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ConfirmationData {
  data!: any[];
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css'],
})
export class RescopeDialog implements OnInit {
  selectedData: any[] = [];

  constructor(
    public dialogo: MatDialogRef<RescopeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationData
  ) {
    console.log(data);

    this.selectedData = data.data
      .filter((el) => el.Stage === 'Descoped')
      .map((el) => `#INV-${el.ClientId}`);
  }

  closeDialog(): void {
    this.dialogo.close({ confirm: false });
  }

  confirm(): void {
    this.dialogo.close({ confirm: true });
  }

  ngOnInit(): void {}
}
