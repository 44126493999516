export class StatisticReturnModel {
  Total: number = 0;
  Data: AnalyticModel[] = [];
}
export class AnalyticModel {
  ClientId: number = 0;
  ClientName: string = '';
  ParentGrouping: string = '';
  DueDate: string = '';
  CountryOfIncorporation: string = '';
  Stage: string = '';
  Revenue: string = '';
  RiskScore: string = '';
  Bucket: string = '';
  ClientType: string = '';
  Analyst: string = '';
  AnalystId: number = 0;
  QCReviewer: string = '';
  ClientContactTitle: string = '';
  ClientContactEmail: string = '';
  ClientContactName: string = '';
  CompletionDate: Date = new Date();
  InitialOutreachDate: Date = new Date();
  ReasonForDescope: string = '';
  Products: string = '';
  Selected: boolean = false;
  IsNudge: boolean = false;
  CaseId: number = -1;

  constructor(ClientId: number, ClientName: string, ParentGrouping: string, DueDate: string, CountryOfIncorporation: string, Stage: string, Revenue: string, RiskScore: string, Bucket: string, ClientType: string, Analyst: string, QCReviewer: string, ClientContactTitle: string, ClientContactEmail: string, ClientContactName: string, CompletionDate: Date, InitialOutreachDate: Date, ReasonForDescope: string, Products: string) {
    this.ClientId = ClientId;
    this.ClientName = ClientName;
    this.ParentGrouping = ParentGrouping;
    this.DueDate = DueDate;
    this.CountryOfIncorporation = CountryOfIncorporation;
    this.Stage = Stage;
    this.Revenue = Revenue;
    this.RiskScore = RiskScore;
    this.Bucket = Bucket;
    this.ClientType = ClientType;
    this.Analyst = Analyst;
    this.QCReviewer = QCReviewer;
    this.ClientContactTitle = ClientContactTitle;
    this.ClientContactEmail = ClientContactEmail;
    this.ClientContactName = ClientContactName;
    this.CompletionDate = CompletionDate;
    this.InitialOutreachDate = InitialOutreachDate;
    this.ReasonForDescope = ReasonForDescope;
    this.Products = Products;
  }
}

export class AnalyticRequestModel {
  UserId: string = '';
  PageNumber: number = 0;
  PageSize: number = 0;
  SortBy: string = '';
  SortDirection: string = '';
  Filter: string = '';
  FilterColumnNames: string[] = [];
  FilterColumnValues: string[] = [];
  Data: AnalyticModel[] = [];
}

export class AnalyticTableItem{
  CaseStatus: string = '';
  Total: number = 0;
  Daily: number = 0;
  Monthly: number = 0;
  AverageAging: number = 0;
  Expanded: boolean = false;
  Analyst?: string = '';
}
