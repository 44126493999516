import {Subscription} from 'rxjs';
import {Helper} from 'src/app/helpers/helper';
import {AppTypes} from 'src/app/layout/types';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'docu-library-header',
  templateUrl: './docu-library-header.component.html',
  styleUrls: ['./docu-library-header.component.scss'],
})
export class DocuLibraryHeaderComponent implements OnInit, OnDestroy {
  public isOnChildRoute: boolean = false;
  public activeRouteSubscription: Subscription = new Subscription();
  public headerText: string = 'Search List';
  public isProductInfoOpened: boolean = false;

  constructor(private helper: Helper, private router: Router) {
  }

  ngOnInit(): void {
    this.activeRouteSubscription = this.helper
      .onDocuLibraryNavInfoChange()
      .subscribe((data) => (this.isOnChildRoute = data));

    this.router.events.subscribe((data) => {
      this.helper.setIsDocuLibProductInfoOpened(false);
      if (this.router.url.includes('/products-and-accounts')) {
        this.headerText = 'Products & Accounts'
      }
      else if (this.router.url.includes('/doc-requirements')) {
        this.headerText = 'Document Requirements'
      }
      else if (this.router.url.includes('/exemption')) {
        this.headerText = 'Exemption'
      }
      else if (this.router.url.includes('/risk')) {
        this.headerText = 'Risk'
      }
      else if (this.router.url.includes('/profile')) {
        this.headerText = 'Profile'
      }
      else if (this.router.url.includes('/structure')) {
        this.headerText = 'Structure'
      }
    });

    this.helper.onDocuLibProductInfoChange().subscribe((isOpened) => this.isProductInfoOpened = isOpened);
  }

  ngOnDestroy(): void {
    this.activeRouteSubscription.unsubscribe();
    this.helper.setIsDocuLibProductInfoOpened(false);
  }

  public handleNavigateToDashboard(): void {
    this.helper.setAppType(AppTypes.Dashboard);
  }

  public handleNavigateToDocuLibSearch(): void {
    this.isOnChildRoute = false;
    this.helper.setDocuLibChildRouteActive(false);
  }
}
