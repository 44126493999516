<div
  *ngIf="isLoading"
  class="loader-wrapper"
  [ngStyle]="{ height: isFullScreen ? '100vh' : '100%' }"
>
  <div
    class="loader-box"
    [ngStyle]="{
      position: isFullScreen ? 'absolute' : 'initial',
      padding: isFullScreen ? '10rem 0' : '0',
      width: '100%'
    }"
  >
    <div class="flex-box">
      <img
        class="login-logo"
        src="../../../assets/icons/login-logo.svg"
        alt=""
      />
    </div>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <div class="loading-label">Loading...</div>
  </div>
</div>
