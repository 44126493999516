<div class="wrapper">
  <div class="info-section border-right">
    <div class="label client-id">Client ID</div>
    <div class="value client-id-val">{{ clientId }}</div>
  </div>
  <div class="info-section" [ngClass]="{ 'border-right': !isOnArchive }">
    <div class="label">Client Name</div>
    <div class="value">{{ clientName }}</div>
  </div>
  <div class="info-section" *ngIf="!isOnArchive">
    <div class="label">Bucket</div>
    <div class="value">{{ clientBucket ? clientBucket : "-" }}</div>
  </div>
</div>
