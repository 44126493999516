import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-with-logo',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderWithLogo {
  @Input() public isLoading: boolean = false;
  @Input() public isFullScreen: boolean = true;

}
