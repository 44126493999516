import { Helper } from '../helpers/helper';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  ProductFiltersPayload,
  QuestionnaireRequestModel,
} from '../models/questionnaire';
import { ProductUpdateModel } from '../components/questionnaire/questionnaire-data-table/questionnaire-table.component';
import { QuestionnaireDataList } from '../components/questionnaire/tabs/questionaire-tab/tab-container/types';
import { RiskScoreData } from '../components/questionnaire/tabs/risk-score/tab-container/types';
import { EnhancedCreateDocumentPayload } from '../components/questionnaire/document-requirement/add-new-document-dialog/types';
import { EnhancedDocRequirement } from '../components/questionnaire/document-requirement/types';
import { ArchiveDataSource } from '../components/questionnaire/document-requirement/archive/types';

@Injectable()
export class QuestionnaireService extends BaseService {
  private baseUrl: string = environment.baseUrl;
  public errorMessage!: string;

  constructor(private http: HttpClient, helper: Helper) {
    super(helper);
  }

  public getQuestionnaire(
    clientId: number,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string
  ): Observable<QuestionnaireRequestModel> {
    return this.http.get<any>(this.baseUrl + `/Product`, {
      params: new HttpParams()
        .set('clientId', clientId)
        .set('sortBy', sortBy)
        .set('sortDirection', sortDirection),
      headers: super.header().headers,
    });
  }

  public getAudiLog(clientId: number): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + `/Product/productHistory?clientId=` + clientId,
      {
        headers: super.header().headers,
      }
    );
  }

  public getDropDownsData(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/Product/commonData`, {
      headers: super.header().headers,
    });
  }

  public getProductTypes(productCategory: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/Product/productTypes`, {
      params: new HttpParams().set('productCategory', productCategory),
      headers: super.header().headers,
    });
  }

  public getProductFilteringOptions(payload: {
    clientId: number;
    columnName: string;
  }): Observable<any> {
    const { clientId, columnName } = payload;

    return this.http.get<any>(this.baseUrl + `/Product/getColumnFilter`, {
      params: new HttpParams()
        .set('clientId', clientId)
        .set('columnName', columnName),
      headers: super.header().headers,
    });
  }

  public getProductsWithFilters(
    payload: ProductFiltersPayload
  ): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + `/Product/filter`,
      payload,
      super.header()
    );
  }

  public createNewProducts(payload: ProductUpdateModel) {
    return this.http.post<any>(
      this.baseUrl + `/Product`,
      payload,
      super.header()
    );
  }

  public fetchQuestionnaireData(clientId: number) {
    return this.http.get<{
      Message: string;
      Data: QuestionnaireDataList;
      Success: boolean;
    }>(this.baseUrl + `/Questionnaire`, {
      params: new HttpParams().set('clientId', clientId),
      headers: super.header().headers,
    });
  }

  public submitQuestionnaireData(payload: any) {
    return this.http.post<any>(
      this.baseUrl + `/Questionnaire`,
      payload,

      super.header()
    );
  }

  public getBuckets(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/Case/buckets`, super.header());
  }

  public fetchDropdownData<T>(key: string) {
    return this.http.get<{
      Message: string;
      Data: T[];
      Success: boolean;
    }>(this.baseUrl + `/Questionnaire/dropdown`, {
      params: new HttpParams().set('dropDownName', key),
      headers: super.header().headers,
    });
  }

  public setNewBucketForCase(caseId: any, bucket: string) {
    const payload = JSON.stringify({ CaseId: caseId, BucketName: bucket });
    return this.http.post<any>(
      this.baseUrl + `/Case/bucket`,
      payload,
      super.header()
    );
  }

  public getExemptionDropdowns(): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + `/Exemption/dropdown`,
      super.header()
    );
  }

  public saveExemptions(exemptions: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + `/Exemption`,
      exemptions,
      super.header()
    );
  }

  public getClientExemption(clientId: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/Exemption`, {
      params: new HttpParams().set('clientId', clientId),
      headers: super.header().headers,
    });
  }

  public getPolicies(clientId: number, selected: boolean): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/Product/policy`, {
      params: new HttpParams()
        .set('clientId', clientId)
        .set('selected', selected),
      headers: super.header().headers,
    });
  }

  public fetchRiskScoreData(clientId: number) {
    return this.http.get<{
      Message: string;
      Data: RiskScoreData;
      Success: boolean;
    }>(this.baseUrl + `/RiskScore`, {
      params: new HttpParams().set('clientId', clientId),
      headers: super.header().headers,
    });
  }

  public fetchRiskScoreDropdownData() {
    return this.http.get<{
      Message: string;
      Data: {
        AdverseInformations: any[];
        CountryCategories: { Name: string; Id: number }[];
        PEPs: any[];
        Risks: { Name: string; Id: number }[];
      };
      Success: boolean;
    }>(this.baseUrl + `/RiskScore/dropdown`, {
      headers: super.header().headers,
    });
  }

  public saveRiskScoreData(payload: RiskScoreData) {
    return this.http.post<{
      Message: string;
      Data: RiskScoreData;
      Success: boolean;
    }>(this.baseUrl + `/RiskScore`, payload, super.header());
  }

  public getDocumentRequirements(clientId: any): Observable<any> {
    return this.http.get<{
      Message: string;
      Data: any[];
      Success: boolean;
    }>(this.baseUrl + `/Requirement`, {
      params: new HttpParams()
        .set('id', clientId)
        .set('sortBy', 'id')
        .set('sortDirection', 'desc'),
      headers: super.header().headers,
    });
  }

  public getDocumentRequirementsArchive(clientId: number): Observable<any> {
    return this.http.get<{
      Message: string;
      Data: ArchiveDataSource[];
      Success: boolean;
    }>(this.baseUrl + `/Requirement/archived`, {
      params: new HttpParams()
        .set('id', clientId)
        .set('sortBy', 'RequirementId')
        .set('sortDirection', 'desc'),
      headers: super.header().headers,
    });
  }

  public getDocumentRequirementsArchivePaginated(
    clientId: number,
    page: number,
    pageSize: number
  ): Observable<any> {
    return this.http.post<{
      Message: string;
      Data: ArchiveDataSource[];
      Success: boolean;
    }>(
      this.baseUrl + `/Requirement/archived/filter`,
      {
        ClientId: clientId,
        PageNumber: page,
        PageSize: pageSize,
        SortBy: 'UploadedOn',
        SortDirection: 'desc',
        FilterColumns: {},
      },
      super.header()
    );
  }

  public getDocumentCategory() {
    return this.http.get<{
      Message: string;
      Data: {
        Id: number;
        Name: string;
      }[];
      Success: boolean;
    }>(this.baseUrl + `/Requirement/documentCategoriesDropDown`, {
      headers: super.header().headers,
    });
  }

  public getDocumentRequirementDropdown(clientId: string, categoryId: number) {
    return this.http.get<{
      Message: string;
      Data: {
        Id: number;
        Name: string;
        ValidityPeriod: number;
        IsDisabled: boolean;
      }[];
      Success: boolean;
    }>(this.baseUrl + `/Requirement/documentRequirementsDropDown`, {
      params: new HttpParams()
        .set('clientId', +clientId)
        .set('categoryId', categoryId),
      headers: super.header().headers,
    });
  }

  public submitNewDocument(payload: EnhancedCreateDocumentPayload) {
    return this.http.post<{
      Message: string;
      Data: any;
      Success: boolean;
    }>(this.baseUrl + `/Requirement/requirement`, payload, super.header());
  }

  public updateExistingDocument(payload: EnhancedDocRequirement) {
    return this.http.post<{
      Message: string;
      Data: any;
      Success: boolean;
    }>(this.baseUrl + `/Requirement/requirement`, payload, super.header());
  }

  public uploadNewFileDocument(file: FormData) {
    const headerFileUpload = () => {
      let headers = new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        withCredentials: 'true',
      });
      if (this.helper.isAuthenticated()) {
        headers = headers.set(
          'Authorization',
          'Bearer ' + this.helper.getToken()
        );
      }
      return { headers: headers };
    };

    return this.http.post<{
      Message: string;
      Data: string;
      Success: boolean;
    }>(this.baseUrl + `/Requirement/uploadDocument`, file, headerFileUpload());
  }

  public removeDocumentRequirement(requirementArchiveId: number) {
    return this.http.delete<{
      Message: string;
      Data: any;
      Success: boolean;
    }>(
      this.baseUrl +
        `/Requirement/deleteArchive?requirementArchiveId=${requirementArchiveId}`,
      super.header()
    );
  }

  public getDocumentReqAuditLog(clientId: number): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + `/Requirement/requirementHistory?clientId=` + clientId,
      {
        headers: super.header().headers,
      }
    );
  }

  public documentIsSelectedUpdate(
    clientId: number,
    caseId: number
  ): Observable<any> {
    return this.http.put<any>(
      this.baseUrl + `/Requirement/requirementsPolicies`,
      { clientId: +clientId, CaseId: caseId },
      super.header()
    );
  }

  public autogenerateRequirement(caseId: any, clientId: any) {
    return this.http.post<{
      Message: string;
      Data: any;
      Success: boolean;
    }>(
      this.baseUrl + `/Requirement/autoGenerateRequirements`,
      { CaseId: +caseId, ClientId: +clientId },
      super.header()
    );
  }

  public deleteRequirement(caseId: number, clientId: number) {
    return this.http.post<{
      Message: string;
      Data: any;
      Success: boolean;
    }>(
      this.baseUrl + `/Requirement/deleteRequirements`,
      { CaseId: +caseId, ClientId: +clientId },
      super.header()
    );
  }

  public calculateProductComplianceStatus(
    clientId: number,
    caseId: number
  ): Observable<any> {
    return this.http.put<any>(
      this.baseUrl + `/Product/calculateComplianceStatus`,
      { clientId: +clientId, CaseId: caseId },
      super.header()
    );
  }

  public updateProductBucket(
    clientId: number,
    caseId: number
  ): Observable<any> {
    return this.http.put<any>(
      this.baseUrl + `/Product/productsBucket`,
      { ClientId: +clientId, CaseId: caseId },
      super.header()
    );
  }

  public moveToDocumentLib(clientId: number): Observable<any> {
    return this.http.put<any>(
      this.baseUrl + `/Product/moveToDocumentLibrary?clientId=${clientId}`,
      {},
      super.header()
    );
  }
}
