import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Helper } from '../helpers/helper';
import { UpdateUserStatusModel } from '../models/user';
import { NotificationService } from '../services/notification.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-head',
  templateUrl: './head.component.html',
  styleUrls: ['./head.component.css'],
})
export class HeadComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();
  @ViewChild('searchInput') searchInput!: ElementRef;
  searchInputControl = new FormControl();
  filterTextChanged: Subject<string> = new Subject<string>();
  subject: Subject<string> = new Subject<string>();
  filterChangeSubscription!: Subscription;
  isAccountSettingsOpened: boolean = false;
  profileImgUrl: string = '';
  profileImgBackground: string = '#E7EBFB';
  isProfileImgLoading: boolean = true;

  totalUnseen: number = 0;

  isNotificationsFullHeight: boolean = false;

  @Input() public showSearch = true;
  @Input() isUserMenuFixed = false;

  notifications: any[] = [];

  constructor(
    public helper: Helper,
    private router: Router,
    private userService: UserService,
    private notificationsService: NotificationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit() {
    this.helper.getUpdatedImgLoadingStatus().subscribe((isLoading) => {
      this.isProfileImgLoading = isLoading;
    });

    this.helper.getUpdatedProfileImg().subscribe((data: { imgUrl: string }) => {
      if (data) {
        this.profileImgUrl = data.imgUrl;
      }
    });

    const isRequestNotifications = !this.router.url.includes('/forgot-password') 
      && !this.router.url.includes('/set-password')
      && !this.router.url.includes('/login');

    if (this.helper.isAuthenticated() && isRequestNotifications) {
      this.notificationsService
        .getNotifications(
          this.isNotificationsFullHeight && this.notifications.length <= 11
            ? 12
            : 5,
          0
        )
        .subscribe((res: any) => {
          this.notifications = res.Data;
          this.totalUnseen = this.notifications[0]?.TotalUnseen;
        });
      this.helper.isNewNotificationReceived().subscribe((res: any) => {
          res.TotalUnseen = this.totalUnseen + 1;
          this.notifications.unshift(res);
          this.totalUnseen = this.notifications[0]?.TotalUnseen;
      });
      setInterval(
        () => {
          if(this.helper.isAuthenticated() && isRequestNotifications) {
            this.notificationsService
            .getNotifications(
              this.isNotificationsFullHeight && this.notifications.length <= 11
                ? 12
                : 5,
              0
            )
            .subscribe((res: any) => {
              this.notifications = res.Data;
              this.totalUnseen = this.notifications[0]?.TotalUnseen;
            });
          }
        }, 60000 //1 minute
      ); 
       
    }

    this.searchInputControl.valueChanges.subscribe((value) => {
      const filter = String(value);
      if (filter.startsWith('')) {
        this._filter(filter);
      }
    });
    this.filterChangeSubscription = this.helper
      .isSearchFilterChanged()
      .subscribe((value: any) => {
        if (typeof value === 'string' || value instanceof String) {
          this.searchInputControl.setValue(value);
        }
      });
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };

  public logout() {
    var userStatus = new UpdateUserStatusModel();
    userStatus.UserStatus = 0; //0 - Offline
    userStatus.UserId = this.helper.getUserId();
    this.userService.putUserStatus(userStatus).subscribe((data: any) => {
      if (data.Success) {
        this.helper.logout();
      }
    });
  }

  private _filter(value: string): Observable<string> {
    const filterValue = value;
    if (this.filterTextChanged.observers.length === 0) {
      this.filterTextChanged
        .pipe(debounceTime(600), distinctUntilChanged())
        .subscribe((filterValue) => {
          if (filterValue != ' ' && filterValue.length > 0) {
            this.helper.setSearchFilter(filterValue);
          } else {
            this.helper.setSearchFilter('');
          }
        });
    }

    this.filterTextChanged.next(filterValue);

    return this.subject.asObservable();
  }

  public handleNotificationClick(): void {}

  public onModalScrollDown() {
    if (this.helper.isAuthenticated()) {
      this.notificationsService
        .getNotifications(
          this.notifications.length + 5,
          this.notifications.length
        )
        .subscribe((res: any) => {
          this.notifications = [...this.notifications, ...res.Data];
        });
    }
  }

  public changeNotificationsViewMore(): void {
    if (
      !this.isNotificationsFullHeight &&
      this.notifications.length <= 11 &&
      this.helper.isAuthenticated()
    ) {
      this.notificationsService
        .getNotifications(12, 0)
        .subscribe((res: any) => {
          this.notifications = [...this.notifications, ...res.Data];
        });
    }
    this.isNotificationsFullHeight = !this.isNotificationsFullHeight;
  }

  public handleNotificationsClose(): void {
    if (this.helper.isAuthenticated()) {
      this.notificationsService
        .setNotificationsAsSeen()
        .subscribe((res: any) => {
          if (res.Success) {
            this.notificationsService
              .getNotifications(
                this.isNotificationsFullHeight &&
                  this.notifications.length <= 11
                  ? 12
                  : 5,
                0
              )
              .subscribe((res: any) => {
                this.notifications = res.Data;
                this.totalUnseen = this.notifications[0]?.TotalUnseen;
              });
          }
        });
      this.isNotificationsFullHeight = false;
    }
  }
}
