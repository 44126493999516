import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Helper } from 'src/app/helpers/helper';
import { UserProfileModel } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit {
  public loading = false;
  public userProfileData: UserProfileModel = new UserProfileModel();
  public userProfileForm: FormGroup = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl(''),
    role: new FormControl(''),
    manager: new FormControl(''),
  });
  public profileImgUrl = '';

  public customImages: string[] = [];

  constructor(private userService: UserService, private helper: Helper) {}

  ngOnInit() {
    this.loading = true;

    this.helper.getUpdatedProfileImg().subscribe((data: { imgUrl: string }) => {
      if (data) {
        this.profileImgUrl = data.imgUrl;
      }
    });

    this.userService.getUserProfileData().subscribe((res) => {
      this.userProfileData = res.Data;
      this.userProfileForm = new FormGroup({
        fullName: new FormControl(
          `${this.userProfileData.FirstName} ${this.userProfileData.LastName}`
        ),
        email: new FormControl(this.userProfileData.Email),
        role: new FormControl(this.userProfileData.Role),
        manager: new FormControl(this.userProfileData.Manager),
      });

      this.customImages = this.userProfileData.IconsUrls;
      this.profileImgUrl = this.userProfileData.AvatarUrl;

      setTimeout(() => {
        this.loading = false;
        this.helper.updateImgLoadingStatus(false);
      }, 1000);
    });
  }
}
