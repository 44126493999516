<div class="separator-wrapper">
  <div class="vertical-separator">
  </div>
</div>
<div class="d-flex h-full">
  
  <div class="h-full w-100" #policiesWrapper>
    <div class="loader-container" *ngIf="isLoading" >
      <mat-spinner [diameter]="70"></mat-spinner>
    </div>
    <div [ngStyle]="{ opacity: isLoading ? 0 : 1, height: '100%' }">
      <div class="d-flex" class="policy" #policyBox [ngClass]="{ 'shadowed-policy': !isPoliciesForSelectedProducts }" *ngFor="let policy of policiesToShow">
        <div class="policy-country">{{ policy.countryCode }}</div>
        <div class="policy-status" [ngClass]="policy.status">
          <div>
            {{ policy.status }}
          </div>
        </div>
      </div>
      <div class="next-btn" *ngIf="policiesToShow.length && !isLoading && (policiesToShow.length !== policies.length)" (click)="showNextPolicies()">
        <img src="./../../../../assets/icons/arrow-right-purple.svg"/>
      </div>
      <div class="toggle-btn" #selectedBtn >
        <mat-icon *ngIf="isPoliciesForSelectedProducts">
          remove_red_eye
        </mat-icon>
        <mat-icon *ngIf="!isPoliciesForSelectedProducts">visibility_off</mat-icon>
      </div>
    </div>
    
  </div>
  
</div>