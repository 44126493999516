<div class="paginator-container">
  <div
    class="data-counter"
    *ngIf="showDataCounter && numberOfItems && !hidePaginationLabel"
  >
    <span
      >Showing
      <span class="value">{{ showingFrom + "-" + showingTo }}</span> from
      <span class="value">{{ numberOfItems }}</span> data</span
    >
  </div>
  <div class="pagination" *ngIf="numberOfItems">
    <button
      class="prev-btn"
      (click)="prevPage()"
      [disabled]="currentPage.index === 0"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>

    <div class="d-flex">
      <ng-container *ngFor="let page of numberOfPages; let i = index">
        <button
          class="page-btn-wrapper"
          (click)="goToPage(i)"
          *ngIf="
            i + 1 >= currentPage.index - maxSize &&
            i - 1 <= currentPage.index + maxSize
          "
          [class.active]="currentPage.index === i"
        >
          {{ i + 1 }}
        </button>
      </ng-container>
    </div>

    <button
      class="next-btn"
      (click)="nextPage()"
      [disabled]="currentPage.index === numberOfPages.length - 1"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
