import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-custom-edge',
  templateUrl: './custom-edge.component.html',
  styleUrls: ['./custom-edge.component.css']
})
export class CustomEdgeComponent implements OnInit {
  @Input() path: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
