import { Location } from '@angular/common';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import {
  MatSnackBar,
} from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  @ViewChild('snackbar') snackbar!: TemplateRef<any>;

  changePassForm: FormGroup = new FormGroup({});
  isSaving: boolean = false;

  resultMessage!: string;
  result!: boolean;

  confirmRegistrationToken!: string;
  resetPasswordToken!: string;
  email!: string;

  activeControl!: any;

  isSetPassword!: boolean;

  constructor(
    private userService: UserService,
    private location: Location,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router) {
  }

  get changePassFormControls() {
    return this.changePassForm.controls;
  }

  ngOnInit(): void {
    this.changePassForm = this.fb.group({
      oldPassword: [''],
      newPassword: [''],
      confirmPassword: [''],
    });

    this.route.data.subscribe((data: any) => {
      this.isSetPassword = data.isSetPass;
    });
    if (this.isSetPassword) {
      this.email = this.route.snapshot.params.email;
      this.confirmRegistrationToken = this.route.snapshot.params.token;
      this.confirmEmailRegistration();
    }
  }

  confirmEmailRegistration() {
    this.userService.confirmEmailRegistration(this.confirmRegistrationToken, this.email).subscribe((res: any) => {
      if(res && res.Success) {
        this.result = true;
        this.resetPasswordToken = res.Data;
      }
      else {
        this._snackBar.open(res.Data, 'X', { duration: 3000, panelClass: ['error-snackbar'] });
        this.router.navigate(['/login']);
      }
    })
  }

  handleChangeOldPassword() {
    const { oldPassword } = this.changePassFormControls;
    if(!oldPassword.value) {
      this.changePassFormControls.oldPassword.setErrors({ required: true });
    }
  }

  handleChangeNewPassword() {
    const { confirmPassword } = this.changePassFormControls;
    this.validatePassword(this.changePassFormControls.newPassword);
    if(confirmPassword.value && confirmPassword.value !== this.changePassFormControls.newPassword.value) {
      this.changePassFormControls.confirmPassword.setErrors({ ...this.changePassFormControls.confirmPassword.errors, isEqualToNew: true })
    }
  }

  handleChangeConfirmPassword() {
    this.validatePassword(this.changePassFormControls.confirmPassword);
    if(this.changePassFormControls.confirmPassword.value !== this.changePassFormControls.newPassword.value) {
      this.changePassFormControls.confirmPassword.setErrors({ ...this.changePassFormControls.confirmPassword.errors, isEqualToNew: true })
    }
  }

  handleCancelBtn() {
    this.location.back();
  }

  handleClick(event: any) {
    this.activeControl = this.changePassFormControls[event];
    this.validatePassword(this.activeControl);
  }

  submitChangePassword() {
    this.isSaving = true;
    const newPassword = this.changePassFormControls.newPassword.value;
    if (!this.isSetPassword) {
      const oldPassword = this.changePassFormControls.oldPassword.value;
      this.userService.setNewPassword(oldPassword, newPassword).subscribe((res: any) => {
        this.resultMessage = res.Message;
        this.result = res.Success;
        this.isSaving = false;
        if(res.Success && !res.Message) {
          this.resultMessage = 'Password changed!';
        }
        this.openSnackBar();
        if(res.Success) {
          setTimeout(() => {
            this.location.back();
          }, 1200);
        }
      });
    } else {
      this.userService.resetPassword(this.resetPasswordToken, this.email, newPassword).subscribe((res: any) => {
        this.resultMessage = res.Message;
        this.result = res.Success;
        this.isSaving = false;
        if(res.Success && !res.Message) {
          this.resultMessage = 'Password changed!';
        }
        this.openSnackBar();
        if(res.Success) {
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 1200);
        }
      });
    }
  }

  validatePassword(passwordControl: AbstractControl) {
    const { value } = passwordControl;
    if(!value) {
      passwordControl.setErrors({ ...passwordControl.errors, required: true });
    }
    if(value.length < 6) {
      passwordControl.setErrors({ ...passwordControl.errors, minlength: true });
    }
    if(!RegExp(".*[0-9].*").test(value)) {
      passwordControl.setErrors({ ...passwordControl.errors, containNumber: true });
    }
    if(!RegExp(".*[a-z].*").test(value)) {
      passwordControl.setErrors({ ...passwordControl.errors, lowercase: true });
    }
    if(!RegExp(".*[A-Z].*").test(value)) {
      passwordControl.setErrors({ ...passwordControl.errors, uppercase: true });
    }
    if(!RegExp("[-+_!@#$%^&*.,?]").test(value)) {
      passwordControl.setErrors({ ...passwordControl.errors, specialCharacter: true });
    }
  }

  openSnackBar() {
    this._snackBar.openFromTemplate(this.snackbar, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 3000,
      panelClass: ['success-snackbar'],
    });
  }

  showPassword(elementId: string) {
    const passwordInput = document.getElementById(elementId) as HTMLInputElement;
    if (passwordInput) passwordInput.type = 'text';
  }

  hidePassword(elementId: string) {
    const passwordInput = document.getElementById(elementId) as HTMLInputElement;
    if (passwordInput) passwordInput.type = 'password';
  }

}
