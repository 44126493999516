import { flatten } from '@angular/compiler';
import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { Chart, ChartConfiguration, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { AnalyticService } from 'src/app/services/analytic.service';

export interface ChartData {
  Analyst: string;
  AverageAging: number;
  CaseStatus: string;
  Daily: number;
  Monthly: number;
  Total: number;
}

@Component({
  selector: 'data-chart',
  templateUrl: './data-charts.component.html',
  styleUrls: ['./data-charts.component.css'],
})
export class DataChart implements OnChanges, AfterContentChecked {
  public chart!: Chart<any>;
  public isChartSetup: boolean = false;
  public colorTemplate: string[] = [];
  public loading: boolean = false;
  public chartConfig: ChartConfiguration = {
    type: 'bar',
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      aspectRatio: 5.5,
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          beginAtZero: false,
          grid: {
            display: false,
            lineWidth: 0.5,
            drawBorder: false,
          },
          display: false,
        },
      },

      plugins: {
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            usePointStyle: true,
          },
        },
      },
    },
    plugins: [],
  };
  public chartConfigOptions: any = {
    aspectRatio: 5.5,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: false,
        grid: {
          display: false,
          lineWidth: 0.5,
          drawBorder: false,
        },
        display: false,
      },
    },

    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
    },
  };

  public cartesianPositionPlugin = {
    id: 'cartesianPositionPlugin',
    beforeDraw: (chart: any, args: any, pluginOptions: any) => {
      const { ctx, chartArea: { top, right, bottom, left, width, height }, scales: { x, y } } = chart;
      y.left = y.left - 50;

      if(y._gridLineItems) {
        ctx.save();
        for(let i = 1; i < y._gridLineItems.length; i++) {
          ctx.beginPath();
          ctx.lineWidth = 2;
          ctx.strokeStyle = '#8E4EB9';
          ctx.moveTo(left - 40, y?._gridLineItems[i].ty1);
          ctx.lineTo(left - 30, y._gridLineItems[i].ty1);
          ctx.stroke();
          ctx.closePath();
          ctx.restore();
        }
        ctx.beginPath();
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#8E4EB9';
        ctx.moveTo(left - 30, y?._gridLineItems[0].ty1);
        ctx.lineTo(left - 30, y._gridLineItems[y._gridLineItems.length - 1].ty1 - 1);
        ctx.stroke();
        ctx.closePath();
      }
    }
  }

  @ViewChild('chartCanvas') chartCanvas!: ElementRef;

  get stepToggleSectionWidth() {
    return (document.querySelector('#toggle-button-group') as any)?.offsetWidth;
  }

  constructor(public analyticService: AnalyticService) {}

  @Input() public title = '';
  @Input() public chartId: string = '';
  @Input() public chartData: any[] = [];
  @Input() public allData: any[] = [];
  @Input() public analysts: any[] = [];
  @Input() public selectedStep: { label: string; value: number } = {
    label: 'Initial Review',
    value: 1,
  };
  @Input() public selectedAnalysts: { label: string; value: number; teamId: number; color: string; }[] = [];

  @Output() public loadingEvent = new EventEmitter();

  ngAfterContentChecked(): void {
    const ctx = document.getElementById(this.chartId);

    if (!this.isChartSetup && this.chartData.length && this.stepToggleSectionWidth && this.selectedStep.value === 0) {
      this.chartCanvas.nativeElement.style.setProperty(
        'width',
        this.stepToggleSectionWidth + 'px'
      );
    }

    if (ctx && !this.isChartSetup && !this.chart && this.chartData.length) {
      this.createChart();
      this.isChartSetup = true;
    }
  }

  ngOnChanges(): void {
    const ctx = document.getElementById(this.chartId);

    if (ctx && !this.isChartSetup && !this.chart) {
      this.createChart();
      this.isChartSetup = true;
    } else if(!!this.chart) {
      this.createGeneralDataChart(this.selectedStep.value);
    }
  }

  flatten(arr: any[]) {
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(
        Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
      );
    }, []);
  }

  createChart() {
    if (this.chart) {
      this.chart.destroy();
    }

    Chart.register(...registerables);

    const chartData = this.chartData
      .filter((el) => el.Analyst)
      .filter((el) => el.CaseStatus === this.selectedStep.label);

    const chartLabels = chartData.map((el) => el.Analyst);
    const analystsColors = this.selectedAnalysts.length ? chartLabels.map(label => this.selectedAnalysts.find(analyst => analyst.label === label)?.color || '') : [];

    this.chartConfig.data = {
      labels: chartLabels,
      datasets: [
        {
          data:
            this.chartId === 'numOfCases'
              ? chartData.map((el) => el.Total === -1 ? 0 : el.Total)
              : chartData.map((el) => el.AverageAging === -1 ? 0 : el.AverageAging),
          backgroundColor: analystsColors,
          borderRadius: 50,
          borderSkipped: false,
          barThickness: 15,
        },
      ],
    };

    // Chart configuration chartConfig
    this.chartConfig = {
      ...this.chartConfig,
      options: {
        aspectRatio: 1,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: false,
            grid: {
              display: false,
              lineWidth: 0.5,
              drawBorder: true,
            },
            ticks: {
              color: '#8E4EB9',
            },
            display: false,
          },
          y: {
            grid: {
              borderDash: [9, 5],
              drawTicks: false,
              drawBorder: false,
            },
            ticks: {
              color: '#8E4EB9',
              autoSkip: true,
              maxTicksLimit: 8,
              crossAlign: 'far',
            },
          }
        },
        layout: {
          padding: {
            left: 50
          }
        },

        plugins: {
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              usePointStyle: true,
            },
          },
        },
      },
      plugins: [this.cartesianPositionPlugin],
    };
    this.chart = new Chart(this.chartId, this.chartConfig);

    this.colorTemplate = analystsColors;
  }

  createGeneralDataChart(index: number): void {
    
    if (index === 0) {
      const AllData = {
        type: 'bar',
        data: {
          labels: [
            '',
            '',
            'Initial Review',
            'Outreach',
            'Name Screening',
            'Escalation',
            'Data Validation',
            'Approval',
            'Completed',
            'Descoped',
            '',
            '',
          ],
          datasets: [
            {
              label: 'All step data',
              data: [
                0,
                0,
                this.chartData
                  .filter((el) => el.CaseStatus === 'Initial Review')
                  .reduce(
                    (accum, currentValue) => accum + currentValue.Total,
                    0
                  ),
                this.chartData
                  .filter((el) => el.CaseStatus === 'Outreach')
                  .reduce(
                    (accum, currentValue) => accum + currentValue.Total,
                    0
                  ),
                this.chartData
                  .filter((el) => el.CaseStatus === 'Name Screening')
                  .reduce(
                    (accum, currentValue) => accum + currentValue.Total,
                    0
                  ),
                this.chartData
                  .filter((el) => el.CaseStatus === 'Escalation')
                  .reduce(
                    (accum, currentValue) => accum + currentValue.Total,
                    0
                  ),
                this.chartData
                  .filter((el) => el.CaseStatus === 'Data Validation')
                  .reduce(
                    (accum, currentValue) => accum + currentValue.Total,
                    0
                  ),
                this.chartData
                  .filter((el) => el.CaseStatus === 'Approval')
                  .reduce(
                    (accum, currentValue) => accum + currentValue.Total,
                    0
                  ),
                this.chartData
                  .filter((el) => el.CaseStatus === 'Complete')
                  .reduce(
                    (accum, currentValue) => accum + currentValue.Total,
                    0
                  ),
                this.chartData
                  .filter((el) => el.CaseStatus === 'Descoped')
                  .reduce(
                    (accum, currentValue) => accum + currentValue.Total,
                    0
                  ),
                0,
                0,
              ],
              backgroundColor: [
                '#e1e1e1',
                '#e1e1e1',
                '#8E4EB9',
                '#8E4EB9',
                '#8E4EB9',
                '#8E4EB9',
                '#8E4EB9',
                '#8E4EB9',
                '#8E4EB9',
                '#8E4EB9',
                '#e1e1e1',
                '#e1e1e1',
              ],
              borderRadius: 50,
              borderSkipped: false,
              barThickness: 15,
              outerWidth: 833,
            },
          ],
        },
        options: {
          aspectRatio: 1,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              beginAtZero: false,
              grid: {
                display: false,
                lineWidth: 0.5,
                drawBorder: true,
              },
              ticks: {
                color: '#8E4EB9',
                fontFamily: 'Poppins-Medium',
              },
              border: {
                color: '#8E4EB9',
              },
              display: false,
            },
            y: {
              grid: {
                borderDash: [9, 5],
                drawTicks: false,
                drawBorder: false,
              },
              ticks: {
                color: '#8E4EB9',
                fontFamily: 'Poppins-Medium',
                autoSkip: true,
                maxTicksLimit: 8,
                crossAlign: 'far',
              },
            },
          },
          layout: {
            padding: {
              left: 50,
            },
          },

          plugins: {
            cartesianPositionPlugin: true,
            legend: {
              display: false,
              position: 'bottom',
              labels: {
                usePointStyle: true,
              },
            },
            annotation: {
              annotations: {
                box1: {
                  type: 'box',
                  xMin: 1.5,
                  xMax: 2.5,
                  yMin: 0,
                  backgroundColor: 'rgba(202, 202, 202, 0.2)',
                  borderColor: 'transparent'
                },
                box2: {
                  type: 'box',
                  xMin: 3.5,
                  xMax: 4.5,
                  yMin: 0,
                  backgroundColor: 'rgba(202, 202, 202, 0.2)',
                  borderColor: 'transparent'
                },
                box3: {
                  type: 'box',
                  xMin: 5.5,
                  xMax: 6.5,
                  yMin: 0,
                  backgroundColor: 'rgba(202, 202, 202, 0.2)',
                  borderColor: 'transparent'
                },
                box4: {
                  type: 'box',
                  xMin: 7.5,
                  xMax: 8.5,
                  yMin: 0,
                  backgroundColor: 'rgba(202, 202, 202, 0.2)',
                  borderColor: 'transparent'
                },
              },
            },
          },
        },
        plugins: [this.cartesianPositionPlugin, annotationPlugin],
      };

      const averageAgingAll = {
        type: 'bar',
        data: {
          labels: [
            '',
            '',
            'Initial Review',
            'Outreach',
            'Name Screening',
            'Escalation',
            'Data Validation',
            'Approval',
            'Completed',
            'Descoped',
            '',
            '',
          ],
          datasets: [
            {
              label: 'All step data',
              data: [
                0,
                0,
                this.allData
                  .filter(
                    (el) =>
                      el.CaseStatus === 'Initial Review' && el.Analyst === null
                  )
                  .find((el) => !el.Analyst && el.AverageAging !== -1)?.AverageAging || 0,
                this.allData
                  .filter(
                    (el) => el.CaseStatus === 'Outreach' && el.Analyst === null
                  )
                  .find((el) => !el.Analyst && el.AverageAging !== -1)?.AverageAging || 0,
                this.allData
                  .filter(
                    (el) =>
                      el.CaseStatus === 'Name Screening' && el.Analyst === null
                  )
                  .find((el) => !el.Analyst && el.AverageAging !== -1)?.AverageAging || 0,
                this.allData
                  .filter(
                    (el) =>
                      el.CaseStatus === 'Escalation' && el.Analyst === null
                  )
                  .find((el) => !el.Analyst && el.AverageAging !== -1)?.AverageAging || 0,
                this.allData
                  .filter(
                    (el) =>
                      el.CaseStatus === 'Data Validation' && el.Analyst === null
                  )
                  .find((el) => !el.Analyst && el.AverageAging !== -1)?.AverageAging || 0,
                this.allData
                  .filter(
                    (el) => el.CaseStatus === 'Approval' && el.Analyst === null
                  )
                  .find((el) => !el.Analyst && el.AverageAging !== -1)?.AverageAging || 0,
                this.allData
                  .filter(
                    (el) => el.CaseStatus === 'Complete' && el.Analyst === null
                  )
                  .find((el) => !el.Analyst && el.AverageAging !== -1)?.AverageAging || 0,
                this.allData
                  .filter(
                    (el) => el.CaseStatus === 'Descoped' && el.Analyst === null
                  )
                  .find((el) => !el.Analyst && el.AverageAging !== -1)?.AverageAging || 0,
                0,
                0,
              ],
              backgroundColor: [
                '#e1e1e1',
                '#e1e1e1',
                '#8E4EB9',
                '#8E4EB9',
                '#8E4EB9',
                '#8E4EB9',
                '#8E4EB9',
                '#8E4EB9',
                '#8E4EB9',
                '#8E4EB9',
                '#e1e1e1',
                '#e1e1e1',
              ],
              borderRadius: 50,
              borderSkipped: false,
              barThickness: 15,
              outerWidth: 833,
            },
          ],
        },
        options: {
          aspectRatio: 1,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              beginAtZero: false,
              grid: {
                display: false,
                lineWidth: 0.5,
                drawBorder: true,
              },
              ticks: {
                color: '#8E4EB9',
                fontFamily: 'Poppins-Medium',
              },
              border: {
                color: '#8E4EB9',
              },
              display: false,
            },
            y: {
              grid: {
                borderDash: [9, 5],
                drawTicks: false,
                drawBorder: false,
              },
              ticks: {
                color: '#8E4EB9',
                fontFamily: 'Poppins-Medium',
                autoSkip: true,
                maxTicksLimit: 8,
                crossAlign: 'far',
              },
            },
          },
          layout: {
            padding: {
              left: 50,
            },
          },

          plugins: {
            cartesianPositionPlugin: true,
            legend: {
              display: false,
              position: 'bottom',
              labels: {
                usePointStyle: true,
              },
            },
            annotation: {
              annotations: {
                box1: {
                  type: 'box',
                  xMin: 1.5,
                  xMax: 2.5,
                  yMin: 0,
                  backgroundColor: 'rgba(202, 202, 202, 0.2)',
                  borderColor: 'transparent',
                },
                box2: {
                  type: 'box',
                  xMin: 3.5,
                  xMax: 4.5,
                  yMin: 0,
                  backgroundColor: 'rgba(202, 202, 202, 0.2)',
                  borderColor: 'transparent',
                },
                box3: {
                  type: 'box',
                  xMin: 5.5,
                  xMax: 6.5,
                  yMin: 0,
                  backgroundColor: 'rgba(202, 202, 202, 0.2)',
                  borderColor: 'transparent',
                },
                box4: {
                  type: 'box',
                  xMin: 7.5,
                  xMax: 8.5,
                  yMin: 0,
                  backgroundColor: 'rgba(202, 202, 202, 0.2)',
                  borderColor: 'transparent',
                },
              },
            },
          },
        },
        plugins: [this.cartesianPositionPlugin, annotationPlugin],
      };

      this.recreateChart(AllData, averageAgingAll);
    } else {
      this.recreateChart();
    }
  }

  recreateChart(config?: any, averageAgingConfig?: any): void {
    const chartData = this.chartData
      .filter((el) => el.Analyst)
      .filter((el) => el.CaseStatus === this.selectedStep.label);

    Chart.register(annotationPlugin);
    Chart.getChart('numOfCases')?.destroy();
    Chart.getChart('averageAging')?.destroy();

    if (config && averageAgingConfig) {
      this.chart = new Chart('numOfCases', config);
      this.chart = new Chart('averageAging', averageAgingConfig);

      return;
    }
    const chartLabels = chartData.map((el) => el.Analyst);
    const analystsColors = this.selectedAnalysts.length ? chartLabels.map(label => this.selectedAnalysts.find(analyst => analyst.label === label)?.color || '') : [];

    this.chart = new Chart('numOfCases', {
      type: 'bar',
      data: {
        labels: chartLabels,
        datasets: [
          {
            data: chartData.map((el) => el.Total),
            backgroundColor: analystsColors,
            borderRadius: 50,
            borderSkipped: false,
            barThickness: 15,
          },
        ],
      },
      options: {
        ...this.chartConfigOptions,
        aspectRatio: 1,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              beginAtZero: false,
              grid: {
                display: false,
                lineWidth: 0.5,
                drawBorder: true,
              },
              ticks: {
                color: '#8E4EB9',
                fontFamily: 'Poppins-Medium'
              },
              border: {
                color: '#8E4EB9'
              },
              display: false,
            },
            y: {
              grid: {
                borderDash: [9, 5],
                drawTicks: false,
                drawBorder: false,
              },
              ticks: {
                color: '#8E4EB9',
                fontFamily: 'Poppins-Medium',
                autoSkip: true,
                maxTicksLimit: 8,
                crossAlign: 'far',
              },
            }
          },
          layout: {
            padding: {
              left: 50
            }
          },

          plugins: {
            cartesianPositionPlugin: true,
            legend: {
                display: false,
                position: 'bottom',
                labels: {
                  usePointStyle: true,
                },
              },
          },
      },
      plugins: [this.cartesianPositionPlugin],
    });
    this.chart = new Chart('averageAging', {
      type: 'bar',
      data: {
        labels: chartLabels,
        datasets: [
          {
            data: chartData.map((el) => el.AverageAging && el.AverageAging !== -1 ? el.AverageAging : 0 ),
            backgroundColor: analystsColors,
            borderRadius: 50,
            borderSkipped: false,
            barThickness: 15,
          },
        ],
      },
      options: {
        ...this.chartConfigOptions,
        aspectRatio: 1,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              beginAtZero: false,
              grid: {
                display: false,
                lineWidth: 0.5,
                drawBorder: true,
              },
              ticks: {
                color: '#8E4EB9',
                fontFamily: 'Poppins-Medium'
              },
              border: {
                color: '#8E4EB9'
              },
              display: false,
            },
            y: {
              grid: {
                borderDash: [9, 5],
                drawTicks: false,
                drawBorder: false,
              },
              ticks: {
                color: '#8E4EB9',
                fontFamily: 'Poppins-Medium',
                autoSkip: true,
                maxTicksLimit: 8,
                crossAlign: 'far',
              },
            }
          },
          layout: {
            padding: {
              left: 50
            }
          },

          plugins: {
            cartesianPositionPlugin: true,
            legend: {
                display: false,
                position: 'bottom',
                labels: {
                  usePointStyle: true,
                },
              },
          },
      },
      plugins: [this.cartesianPositionPlugin],
    });
    this.colorTemplate = analystsColors;

  }
}
