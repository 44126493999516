<div class="loader-container" *ngIf="isLoading; else dataBlock">
  <mat-spinner [diameter]="70"></mat-spinner>
</div>

<ng-template #dataBlock>
  <div class="container-page">
    <div class="collapsible-table">
      <div class="table-wrapper">
        <!-- Audit Log Button -->
        <div class="d-flex justify-content-end audit-log-wrapper">
          <button class="audit-log-btn" mat-button (click)="handleOpenAuditLog()">
            Audit Log
          </button>
        </div>

        <ng-container *ngIf="dataSource.length > 0; else noDataBlock">
          <!-- Data Table -->
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- Product ID -->
            <ng-container matColumnDef="productId">
              <th mat-header-cell *matHeaderCellDef class="mat-column-id">
                <div class="d-flex t-head-styled">
                  <app-active-sorting-indicator
                    *ngIf="isSortingActive('Product ID')"
                  >
                  </app-active-sorting-indicator>
                  Product ID
                  <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting('Product ID')"
                    [isActive]="isSortingActive('Product ID')"
                  >
                  </app-sort-indicator>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="mat-column-id">
                <div class="t-body-styled id-section" [style.color]="'#7D8187'">
                  {{ element?.Id }}
                </div>
              </td>
            </ng-container>

            <!-- Product Category -->
            <ng-container matColumnDef="productCategory">
              <th mat-header-cell *matHeaderCellDef [style.color]="'#6E6893'">
                <div class="d-flex t-head-styled">
                  <app-active-sorting-indicator
                    customStyling="docu-lib-accounts"
                    *ngIf="isSortingActive('Product Category')"
                  >
                  </app-active-sorting-indicator>
                  Product Category
                  <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting('Product Category')"
                    [isActive]="isSortingActive('Product Category')"
                  >
                  </app-sort-indicator>
                </div>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="t-body-styled account-number">
                  {{ element?.ProductCategory }}
                </div>
              </td>
            </ng-container>

            <!-- Product Type -->
            <ng-container matColumnDef="productType">
              <th mat-header-cell *matHeaderCellDef>
                <div class="d-flex t-head-styled">
                  <app-active-sorting-indicator
                    customStyling="docu-lib-accounts"
                    *ngIf="isSortingActive('Product Type')"
                  >
                  </app-active-sorting-indicator>
                  Product Type
                  <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting('Product Type')"
                    [isActive]="isSortingActive('Product Type')"
                  >
                  </app-sort-indicator>
                </div>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="t-body-styled legal-name-styled">
                  {{ element?.ProductType }}
                </div>
              </td>
            </ng-container>

            <!-- Booking -->
            <ng-container matColumnDef="booking">
              <th mat-header-cell *matHeaderCellDef>
                <div class="d-flex t-head-styled">
                  <app-active-sorting-indicator
                    customStyling="docu-lib-accounts"
                    *ngIf="isSortingActive('Booking')"
                  >
                  </app-active-sorting-indicator>
                  Booking
                  <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting('Booking')"
                    [isActive]="isSortingActive('Booking')"
                  >
                  </app-sort-indicator>
                </div>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="t-body-styled">
                  {{ element?.Booking }}
                </div>
              </td>
            </ng-container>

            <!-- Selling -->
            <ng-container matColumnDef="selling">
              <th mat-header-cell *matHeaderCellDef class="mat-column-status">
                <div class="d-flex t-head-styled">
                  <app-active-sorting-indicator
                    customStyling="docu-lib-accounts"
                    *ngIf="isSortingActive('Selling')"
                  >
                  </app-active-sorting-indicator>
                  Selling
                  <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting('Selling')"
                    [isActive]="isSortingActive('Selling')"
                  >
                  </app-sort-indicator>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="mat-column-status">
                <div class="t-body-styled">
                  {{ element?.Selling }}
                </div>
              </td>
            </ng-container>

            <!-- Status -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef class="mat-column-status">
                <div class="d-flex t-head-styled">
                  <app-active-sorting-indicator
                    customStyling="docu-lib-accounts"
                    *ngIf="isSortingActive('Status')"
                  >
                  </app-active-sorting-indicator>
                  Status
                  <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting('Status')"
                    [isActive]="isSortingActive('Status')"
                  >
                  </app-sort-indicator>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="mat-column-status">
                <div
                  [class]="
                  't-body-styled t-status-base' +
                  ' ' +
                  toUpperCase(element.Status)
                "
                >
                  {{ toUpperCase(element?.Status) }}
                </div>
              </td>
            </ng-container>

            <!-- Compliance -->
            <ng-container matColumnDef="compliance">
              <th mat-header-cell *matHeaderCellDef class="mat-column-status">
                <div class="d-flex t-head-styled">
                  <app-active-sorting-indicator
                    customStyling="docu-lib-accounts"
                    *ngIf="isSortingActive('Compliance')"
                  >
                  </app-active-sorting-indicator>
                  Compliance
                  <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting('Compliance')"
                    [isActive]="isSortingActive('Compliance')"
                  >
                  </app-sort-indicator>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="mat-column-status">
                <div class="t-body-styled">
                  {{ element?.Compliance }}
                </div>
              </td>
            </ng-container>

            <!-- Bucket -->
            <ng-container matColumnDef="bucket">
              <th mat-header-cell *matHeaderCellDef class="mat-column-status">
                <div class="d-flex t-head-styled">
                  <app-active-sorting-indicator
                    customStyling="docu-lib-accounts"
                    *ngIf="isSortingActive('Bucket')"
                  >
                  </app-active-sorting-indicator>
                  Bucket
                  <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting('Bucket')"
                    [isActive]="isSortingActive('Bucket')"
                  >
                  </app-sort-indicator>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="mat-column-status">
                <div class="t-body-styled">
                  {{ element?.Bucket }}
                </div>
              </td>
            </ng-container>

            <!-- Date -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef class="mat-col-date">
                <div class="d-flex t-head-styled">
                  <app-active-sorting-indicator
                    customStyling="docu-lib-accounts"
                    *ngIf="isSortingActive('Date')"
                  >
                  </app-active-sorting-indicator>
                  Date
                  <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting('Date')"
                    [isActive]="isSortingActive('Date')"
                  >
                  </app-sort-indicator>
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="mat-col-date"
                [class.text-smaller]="isPreviewVisible"
              >
                <div class="t-body-styled">
                  {{ element?.Date | date: "dd MMM yyyy" }}
                </div>
              </td>
            </ng-container>

            <!-- See Icon -->
            <ng-container matColumnDef="seeIcon">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="mat-column-see-icon"
              ></th>
              <td mat-cell *matCellDef="let element" class="mat-column-see-icon">
                <div class="t-body-styled">
                  <button
                    class="info-eye-btn styled"
                    (click)="handleProductInfoOpen(element)"
                  >
                    <div
                      *ngIf="selectedProduct?.Id === element.Id; else infoIcon"
                      class="info-eye-container"
                    >
                      <mat-icon>remove_red_eye</mat-icon>
                    </div>
                    <ng-template #infoIcon>
                      <img
                        alt="info icon"
                        class="info-btn"
                        src="../../../../assets/icons/product-info-icon.svg"
                      />
                    </ng-template>
                  </button>
                </div>
              </td>
            </ng-container>

            <tr
              mat-header-row
              class="sticky-header"
              *matHeaderRowDef="displayedColumns"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns; let i = index"
              [class.active-row]="selectedProduct?.Id === row?.Id"
            ></tr>
          </table>
        </ng-container>

        <ng-template #noDataBlock>
          <div class="d-flex justify-content-center">
            <no-data-component></no-data-component>
          </div>
        </ng-template>
      </div>
    </div>

    <div
      class="collapsible-info-section"
      [class.flex-grow-custom]="isPreviewVisible"
      [class.flex-grow-custom-0]="!isPreviewVisible"
      [ngStyle]="{ 'margin-left': isPreviewVisible ? '1.5rem' : '0' }"
    >
      <div *ngIf="isPreviewVisible">
        <div
          #productInfo
          class="product-info container-overflow"
          style="overflow-y: auto; width: 100%"
        >
          <product-info
            [productData]="selectedProduct"
            (onProductInfoChange)="productInfoChange($event)"
          ></product-info>
        </div>
      </div>
    </div>
  </div>
</ng-template>
