import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import 'moment-timezone';
import { Subscription } from 'rxjs';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';

@Component({
  selector: 'audit-log-modal',
  templateUrl: 'audit-log-modal.component.html',
  styleUrls: ['audit-log-modal.component.scss'],
})
export class AuditLogModalComponent implements OnInit, OnDestroy {
  data: any[] = [];
  isLoading: boolean = false;

  audiLogSubscription!: Subscription;

  constructor(
    private questionaryService: QuestionnaireService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  public get isOnDocReqPage(): boolean {
    return this.dialogData.isOnDocReq;
  }

  ngOnInit(): void {
    this.isLoading = true;

    switch (this.isOnDocReqPage) {
      case true:
        this.audiLogSubscription = this.questionaryService
          .getDocumentReqAuditLog(this.dialogData.ClientId)
          .subscribe((res: any) => {
            this.data = res.Data.map((log: any) => {
              const utcTimeString = log.CreatedOn;
              const timezone = moment.tz.guess();
              const localTime = moment.utc(utcTimeString).tz(timezone);
              log.CreatedOnDate = localTime.format('DD MMM YY');
              log.CreatedOnTime = localTime.format('HH:mm:ss');
              return log;
            });
            this.isLoading = false;
          });

        break;

      default:
        this.audiLogSubscription = this.questionaryService
          .getAudiLog(this.dialogData.ClientId)
          .subscribe((res: any) => {
            this.data = res.Data.map((log: any) => {
              const utcTimeString = log.CreatedOn;
              const timezone = moment.tz.guess();
              const localTime = moment.utc(utcTimeString).tz(timezone);
              log.CreatedOnDate = localTime.format('DD MMM YY');
              log.CreatedOnTime = localTime.format('HH:mm:ss');
              return log;
            });
            this.isLoading = false;
          });

        break;
    }
  }

  ngOnDestroy(): void {
    this.audiLogSubscription.unsubscribe();
  }

  isAddedMessage(log: any) {
    return (
      log.PropertyName == 'Status' &&
      !log.OldValue &&
      log.NewValue.includes('Requested')
    );
  }

  getProperLogText(action: any) {
    switch (action.PropertyName) {
      case 'New document generation':
        return 'Generated By';

      case 'generate':
        return 'Generated By';

      case 'Action':
        return 'Set to';

      case 'Comment':
        return 'Comment Modified By';

      case 'DocumentUrl':
        return 'Uploaded By';

      case 'Auto generate':
        return 'Generated By System';

      default:
        return '';
    }
  }
}
