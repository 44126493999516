<div class="d-flex cursor" style="align-items: center">
  <div
    style="height: 42px !important; margin: 0 20px"
    class="form-field new-form-field"
    [ngClass]="{
      'w-small': inputSize === 'input-small',
      'w-semi-small': inputSize === 'input-semi-small',
      'w-medium': inputSize === 'input-medium',
      'w-large': inputSize === 'input-large'
    }"
    #autocomplete
    appearance="fill"
    #t="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    #autocompleteControl
  >
    <input
      matInput
      readonly
      multiple
      type="text"
      class="input-base"
      [(ngModel)]="controlValue"
      placeholder="Please Select"
      [value]="controlValue"
    />
    <div class="end-icon-box" style="height: 42px !important">
      <mat-icon [ngClass]="{ rotate: t.menuOpen }">
        keyboard_arrow_up
      </mat-icon>
    </div>

    <mat-menu #menu="matMenu" class="extended-menu multiselectQ">
      <!-- Allocation selector -->
      <div class="allocation-selector">
        <!-- 
      style="position: sticky !important; top: 0" -->
        <div class="selector-btn" (click)="changeAllocationMethod($event)">
          <div class="label-box">
            {{ currentAllocationMethod }}
          </div>
          <div class="icon-box">
            <div class="d-flex">
              <mat-icon>keyboard_arrow_down</mat-icon>
              <mat-icon style="margin-left: -12px">keyboard_arrow_up</mat-icon>
            </div>
          </div>
        </div>
      </div>

      <div #menuBox class="autocomplete-menu-box">
        <div class="wrapper">
          <!-- Autocomplete options -->
          <div
            class="option-list-item"
            *ngFor="
              let option of autocompleteOptions
                | autocompleteMultiselectSearch : searchText;
              let i = index
            "
            (click)="selectionMade($event, option, i)"
            [ngClass]="{
              highlight: checkIfSelected(option)
            }"
          >
            <div class="d-flex align-items-center list-item">
              <span
                style="
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                "
              >
                <img
                  class="selected"
                  alt="selected item indicator"
                  *ngIf="checkIfSelected(option)"
                  src="../../../../assets/icons/product-filter-active-indicator.svg"
                />
                {{ option.Name }}
              </span>
              <div
                *ngIf="checkIfSelected(option)"
                class="percentage-input-wrapper"
                (click)="$event.stopPropagation()"
              >
                <input
                  required
                  matInput
                  type="number"
                  min="0"
                  max="100"
                  class="percentage-input"
                  placeholder="Enter %"
                  [(ngModel)]="dataModel[i].Value"
                  [value]="+dataModel[i].Value"
                  (input)="onPercentageInputChange(option, i, $event)"
                  [ngClass]="{
                    validationError: isControlValid(i) || !isPercentagesValid()
                  }"
                  onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                />

                <div
                  class="validationError"
                  *ngIf="isControlValid(i) || !isPercentagesValid()"
                  class="error"
                >
                  <span
                    class="not-valid-value"
                    *ngIf="isControlValid(i) && selectedOptions.length === 1"
                  >
                    Max acceptable value is 100 %
                  </span>

                  <span
                    class="incorrect-sum"
                    *ngIf="!isPercentagesValid() && selectedOptions.length > 1"
                  >
                    Incorrect percentage sum
                  </span>
                </div>
                <div class="end-icon-box" style="width: 24px; height: 26px">
                  <mat-icon> keyboard_arrow_up </mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Search Box -->
        <div
          class="search-box"
          *ngIf="isAutocompleteSearchable"
          (click)="$event.stopPropagation()"
        >
          <div class="input-box">
            <input
              autofocus
              type="text"
              [(ngModel)]="searchText"
              placeholder="Search By Name"
              (input)="handleSearchInputChange($event)"
            />
            <div class="search-icon">
              <img
                alt="search-icon"
                src="../../../../../../../assets/icons/search-questionnaire.svg"
              />
            </div>
          </div>
          <button (click)="handleSearchSubmit()" [disabled]="isDisabled()">
            Submit
          </button>
        </div>
      </div>
    </mat-menu>
  </div>

  <!-- selected items -->
  <div class="selected-options-wrapper">
    <ng-container *ngFor="let selectedOption of selectedOptionsResult">
      <div class="selection">
        <img
          class="selected"
          alt="selected item indicator"
          src="../../../../assets/icons/product-filter-active-indicator.svg"
        />
        <span style="white-space: nowrap">
          {{ selectedOption.Name }}
        </span>

        <div class="percentage">
          {{ selectedOption.Value + "%" }}
        </div>

        <mat-icon
          class="remove-selection"
          (click)="handleRemoveOption(selectedOption)"
          >close</mat-icon
        >
      </div>
    </ng-container>
  </div>
</div>
