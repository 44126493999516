<div class="loader-container" *ngIf="isLoading; else dataBlock">
  <mat-spinner [diameter]="70"></mat-spinner>
</div>
<ng-template #dataBlock>
  <ng-container *ngIf="dataSource.length; else noDataBlock">
    <div class="exemptions-container">

      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel
          hideToggle="true"
          *ngFor="let form of exemptionFormGroups; let i = index"
          [expanded]="true"
          #caExemptionExPanel
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ form.value.AreaName }}
            </mat-panel-title>
            <mat-panel-description>
              <div class="expansion-icon-wrapper">
                <mat-icon *ngIf="!caExemptionExPanel.expanded">add</mat-icon>
                <mat-icon *ngIf="caExemptionExPanel.expanded">remove</mat-icon>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <form [formGroup]="form">
            <!-- Exemption -->
            <div class="property-wrapper">
              <div class="property-label col-5">
                {{ form.value.AreaCode }} Exemption
              </div>
              <div class="property-input-wrapper d-flex">
                <div
                  class="selected-category-names d-flex"
                  *ngIf="getExemptionNameDisplayValue(i)?.length > 0"
                >
                  <div
                    class="data-block"
                    *ngFor="let category of getExemptionNameDisplayValue(i)"
                  >
                    {{ category }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Exemption Category -->
            <div class="property-wrapper">
              <div class="property-label col-5">
                {{ form.value.AreaCode }} Exemption Category
              </div>
              <div class="property-input-wrapper d-flex">
                <div
                  class="selected-categories d-flex"
                  *ngIf="
                    getExemptionCategoryDisplayValue(i)?.length > 0;
                    else dash
                  "
                >
                  <div
                    class="data-block"
                    *ngFor="let category of getExemptionCategoryDisplayValue(i)"
                  >
                    <img
                      class="selected"
                      alt="selected item indicator"
                      src="../../../../assets/icons/product-filter-active-indicator.svg"
                    />
                    {{ category }}
                  </div>
                </div>

                <ng-template #dash>
                  <div class="selected-categories d-flex">
                    <div class="data-block">-</div>
                  </div>
                </ng-template>
              </div>
            </div>

            <!-- Exemption Override -->
            <div class="property-wrapper">
              <div class="property-label col-5">
                {{ form.value.AreaCode }} Exemption Override
              </div>
              <div class="property-input-wrapper col-11">
                <div class="selected-categories d-flex">
                  <div class="data-block">
                    {{
                    form.value.exemptionOverride
                      ? form.value.exemptionOverride
                      : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Override Exemption Category -->
            <div class="property-wrapper">
              <div class="property-label col-5">
                {{ form.value.AreaCode }} Override Exemption Category
              </div>
              <div class="property-input-wrapper col-11">
                <div class="selected-categories d-flex">
                  <div class="data-block">
                    {{
                    form.value.overrideExemptionCategory
                      ? form.value.overrideExemptionCategory
                      : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Comment -->
            <div class="property-wrapper comment">
              <div class="property-label col-5">Comment</div>
              <div class="property-input-wrapper">
                <div class="selected-categories d-flex">
                  <div class="data-block">
                    {{ form.value.comment ? form.value.comment : "-" }}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </ng-container>
</ng-template>

<ng-template #noDataBlock>
  <div class="d-flex justify-content-center">
    <no-data-component></no-data-component>
  </div>
</ng-template>
