import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Subscription, throwError } from 'rxjs';
import { Helper } from '../helpers/helper';

@Injectable()
export class BaseService {
  constructor(public helper: Helper) {}

  public shopId!: string;
  subscription!: Subscription;
  public isAuthenticated(): boolean {
    return this.helper.isAuthenticated();
  }

  public extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  public handleError(error: Response | any) {
    // In a real-world app, we might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return throwError(errMsg);
  }

  public header() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      withCredentials: 'true',
    });
    if (this.helper.isAuthenticated()) {
      headers = headers.set(
        'Authorization',
        'Bearer ' + this.helper.getToken()
      );
    }
    return { headers: headers };
  }

  public setToken(data: any) {
    this.helper.setToken(data);
  }

  public setIsAuth(data: any) {
    this.helper.setIsAuth(data);
  }

  public failToken(error: Response | any) {
    this.helper.failToken();
    return this.handleError(Response);
  }
}
