import {
  Input,
  OnInit,
  Output,
  Component,
  QueryList,
  EventEmitter,
  ViewChildren,
} from '@angular/core';
import { Helper } from '../helpers/helper';
import { MessageModel } from '../models/websocket';
import { MenuService } from '../services/menu.service';
import { LeftMenuModel, TeamModel } from '../models/left-menu';
import { SidebarTeamDropdown } from './left-panel-team-dropdown/sidebar-team-dropdown.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.css'],
})
export class LeftPanelComponent implements OnInit {
  @Input() isNavOpened: boolean = true;
  @Output() handleSidenavOpenClose = new EventEmitter();
  @ViewChildren('team_dropdown') components!: QueryList<SidebarTeamDropdown>;

  menuData: LeftMenuModel = new LeftMenuModel();
  teams: TeamModel[] = [new TeamModel()];
  userName: string = '';
  updateMenuData: any;
  public menuChangeSubscription: any;
  userStatusSubscription: any;
  nudgeRecievedSubscription: any;
  profileImgUrl: string = '';
  profileImgBackground: string = '';
  isProfileImgLoading: boolean = true;
  userRoleMain: string = '';
  userRoleSecondary: string = '';
  menuDataLoading: boolean = false;

  constructor(
    private helper: Helper,
    private menuService: MenuService,
    private router: Router
  ) {}

  public get userRole() {
    return this.helper.getUserRole();
  }

  public get isApprovalStageVisible() {
    return (
      this.userRole?.includes('Admin') || this.userRole?.includes('Manager')
    );
  }

  ngOnInit(): void {
    this.helper.getUpdatedImgLoadingStatus().subscribe((isLoading) => {
      this.isProfileImgLoading = isLoading;
    });
    this.menuDataLoading = true;
    this.getAndSetMenuDate();
    this.setRefreshUserStatusTimeout();
    this.menuChangeSubscription = this.helper
      .isLeftMenuChanged()
      .subscribe((isChanged: boolean) => {
        if (isChanged) {
          this.getAndSetMenuDate(false);
        }
      });
    this.userStatusSubscription = this.helper
      .isWsMessageRecieved()
      .subscribe((message: MessageModel) => {
        if (message.NotificationType == 1) {
          var userId = message.Message.split(',')[0].split('=')[1];
          var userStatus = message.Message.split(',')[1].split('=')[1];
          this.teams.forEach((team) => {
            var user = team.Users.find((user) => user.UserId == userId);
            if (user) {
              user.UserStatus = userStatus;
              user.Status = this.convertUserStatus(userStatus);
            }
          });
        }
      });

    this.nudgeRecievedSubscription = this.helper
      .isNudgeRecieved()
      .subscribe((msg: any) => {
        this.getAndSetMenuDate();
      });
  }

  setRefreshUserStatusTimeout() {
    this.updateMenuData = setInterval(
      () => this.getAndSetMenuDate(false),
      60000
    ); //1 minute
  }

  getAndSetMenuDate(isAllMenuData: boolean = true) {
    var user = this.helper.getUser();
    var userId = user.Id;
    this.userName = user.FirstName + ' ' + user.LastName;

    const userRoles = user.Role.split(', ');

    if (userRoles.length <= 2) {
      this.userRoleMain = user.Role;
    } else {
      const priorityRoles: string[] = [];
      const secondaryRoles: string[] = [];

      userRoles.map((x) => {
        if (x === 'Administrator' || x === 'Manager' || x === 'Analyst') {
          priorityRoles.push(x);
        } else secondaryRoles.push(x);

        return x;
      });

      this.userRoleMain = priorityRoles.join(
        priorityRoles.length > 1 ? ', ' : ''
      );
      this.userRoleSecondary = secondaryRoles.join(
        secondaryRoles.length > 1 ? ', ' : ''
      );
    }

    const isMenuAvailable =
      !this.router.url.includes('/forgot-password') &&
      !this.router.url.includes('/set-password') &&
      !this.router.url.includes('/login');

    if (this.helper.isAuthenticated() && isMenuAvailable) {
      this.menuService.getLeftMenu().subscribe((response: any) => {
        this.menuData = response.Data;
        this.setTabsCounts();
        this.setUserData();

        this.profileImgBackground = '#E7EBFB';

        this.helper.updateProfileImg({
          imgUrl: this.menuData.ProfileUrl,
        });

        this.helper.updateImgLoadingStatus(false);
        this.menuDataLoading = false;
      });
    }
  }

  setTabsCounts() {
    this.tabs.forEach((tab) => {
      switch (tab.tabName) {
        case 'Dashboard':
          tab.numberOfItems = this.menuData.DashboardCount;
          tab.showNotification = this.menuData.IsNudge;
          break;
        case 'Initial Review':
          tab.numberOfItems = this.menuData.InitialReviewCount;
          break;
        case 'Outreach':
          tab.numberOfItems = this.menuData.OutreachCount;
          break;
        case 'Name Screening':
          tab.numberOfItems = this.menuData.NameScreeningCount;
          break;
        case 'Data Validation':
          tab.numberOfItems = this.menuData.DataValidationCount;
          break;
        case 'Escalation':
          tab.numberOfItems = this.menuData.EscalationCount;
          break;
        case 'Approval':
          tab.numberOfItems = this.menuData.ApprovalsCount;
          break;
        case 'Complete':
          tab.numberOfItems = this.menuData.CompleteCount;
          break;
        case 'Descoped':
          tab.numberOfItems = this.menuData.DescopeCount;
          break;
      }
    });
  }

  setUserData() {
    this.teams = this.menuData.TeamStatusList;
    this.teams.forEach((team) => {
      team.TeamImgUrl = 'url(./../assets/images-female.png)';
      let i = 1;
      team.Users.forEach((user) => {
        if (!user.UserIconUrl) {
          if (i == 1) {
            user.UserIconUrl = 'url(./../assets/images.png)';
            i++;
          } else if (i == 2) {
            user.UserIconUrl = 'url(./../assets/images-new.png)';
            i++;
          } else if (i == 3) {
            user.UserIconUrl = 'url(./../assets/new-one.png)';
            i = 1;
          }
        }
        user.Status = this.convertUserStatus(user.UserStatus);
      });
    });
  }

  public tabs = [
    {
      routerLink: '/home',
      imgUrl: './assets/icons/dashboard.svg',
      tabName: 'Dashboard',
      numberOfItems: this.menuData.DashboardCount,
      showNotification: this.menuData.IsNudge,
    },
    {
      routerLink: '/initial-review',
      imgUrl: './assets/icons/initial-review.svg',
      tabName: 'Initial Review',
      numberOfItems: this.menuData.InitialReviewCount,
      showNotification: false,
    },
    {
      routerLink: '/outreach',
      imgUrl: './assets/icons/outreach.svg',
      tabName: 'Outreach',
      showNotification: false,
      numberOfItems: this.menuData.OutreachCount,
    },
    {
      routerLink: '/nameScreening',
      imgUrl: './assets/icons/name-screening.svg',
      tabName: 'Name Screening',
      showNotification: false,
      numberOfItems: this.menuData.NameScreeningCount,
    },
    {
      routerLink: '/escalation',
      imgUrl: './assets/icons/escalation.svg',
      tabName: 'Escalation',
      showNotification: false,
      numberOfItems: this.menuData.EscalationCount,
    },
    {
      routerLink: '/data-validation',
      imgUrl: './assets/icons/data-validation.svg',
      tabName: 'Data Validation',
      showNotification: false,
      numberOfItems: this.menuData.DataValidationCount,
    },
    {
      routerLink: '/approvals',
      imgUrl: './assets/icons/approvals.svg',
      tabName: 'Approval',
      showNotification: false,
      numberOfItems: this.menuData.ApprovalsCount,
    },
    {
      routerLink: '/complete',
      imgUrl: './assets/icons/completed.svg',
      tabName: 'Complete',
      showNotification: false,
      numberOfItems: this.menuData.CompleteCount,
    },
    {
      routerLink: '/descope',
      imgUrl: './assets/icons/descope.svg',
      tabName: 'Descoped',
      showNotification: false,
      numberOfItems: this.menuData.DescopeCount,
    },
    {
      routerLink: '/analytics',
      imgUrl: './assets/icons/analytics.svg',
      imgUrlHighlight: './assets/icons/analytics_highlight.svg',
      imgUrlHighlightCollapsed:
        './assets/icons/analytics_highlight_collapsed.svg',
      tabName: 'Analytics',
      numberOfItems: null,
      showNotification: false,
      isAnalytics: true,
    },
  ];

  onSidenavClose = () => {
    this.handleSidenavOpenClose.emit();
    this.isNavOpened = !this.isNavOpened;
    window.localStorage.setItem('isNavOpened', `${this.isNavOpened}`);
    this.components.toArray().map((team) => team.closeTeam());
  };

  showTeam(): void {
    if (this.isNavOpened === false) {
      this.handleSidenavOpenClose.emit();
    }
  }

  convertUserStatus(userStatus: string): string {
    return userStatus == 'Online'
      ? 'green'
      : userStatus == 'Offline'
      ? 'red'
      : 'orange';
  }
}
