import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Helper } from 'src/app/helpers/helper';
import { AuditLogModel } from 'src/app/models/audit_log_model';
import { StatisticService } from 'src/app/services/statistic.service';

@Component({
  selector: 'app-audit-log-dialog',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.css'],
})
export class AuditLogDialog implements OnInit {
  public auditLogData: AuditLogModel[] = [];
  public isDataLoading: boolean = false;

  constructor(
    private helper: Helper,
    private statisticService: StatisticService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.isDataLoading = true;

    this.statisticService
      .getAuditLogInfo(this.helper.getUser().Id, this.data)
      .subscribe((res) => {
        this.auditLogData = res.Data;
        this.isDataLoading = false;
      });
  }
}
