import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticModel } from 'src/app/models/analytic';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css'],
})
export class NotAllItemsProcessedDialog implements OnInit {
  selectedData: AnalyticModel[] = [];
  descopeItemsToBeExcluded: string[] = [];
  rescopeItemsToBeExcluded: string[] = [];
  isDiscopeDialog: boolean = false;
  isRescopeDialog: boolean = false;

  constructor(
    public dialogo: MatDialogRef<NotAllItemsProcessedDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isDiscopeDialog = data.isDescopeDialog;
    this.isRescopeDialog = data.isRescopeDialog;
    this.selectedData = data.selected;

    this.descopeItemsToBeExcluded = this.selectedData
      .filter((el) => el.Stage === 'Descoped')
      .map((el) => `#INV-${el.ClientId}`);

    this.rescopeItemsToBeExcluded = this.selectedData
      .filter((el) => el.Stage !== 'Descoped')
      .map((el) => `#INV-${el.ClientId}`);
  }

  closeDialog(): void {
    this.dialogo.close({ confirm: false });
  }

  confirm(): void {
    this.dialogo.close({ confirm: true });
  }

  ngOnInit(): void {}
}
