import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Helper} from "../../../../../../../../helpers/helper";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DocuLibraryService} from "../../../../../../../../services/docu-library.service";
import {DropdownOptions} from "../../regulatory/common/data-tabs/sdl-data/types";
import {GeneralInfoPayload, IndustryTypesAndSectors} from "./types";

@Component({
    selector: 'app-general-info-tab-container',
    templateUrl: './general-info-tab-container.component.html',
    styleUrls: ['./general-info-tab-container.component.css']
})
export class GeneralInfoTabContainerComponent implements OnInit {
    public generalInfoForm: FormGroup = new FormGroup({});
    public entityTypeForm: FormGroup = new FormGroup({});
    public incorporationForm: FormGroup = new FormGroup({});
    public regulationForm: FormGroup = new FormGroup({});
    public listingForm: FormGroup = new FormGroup({});
    public charityRegistrationForm: FormGroup = new FormGroup({});
    public industryForm: FormGroup = new FormGroup({});
    public registeredAddressForm: FormGroup = new FormGroup({});
    public mailingAddressForm: FormGroup = new FormGroup({});
    public contactInfoForm: FormGroup = new FormGroup({});
    public financialInformationForm: FormGroup = new FormGroup({});
    public externalDatabaseForm: FormGroup = new FormGroup({});
    public keyDatesForm: FormGroup = new FormGroup({});

    public isLoading: boolean = false;
    public dataSource: GeneralInfoPayload | undefined;
    public dropdownData: DropdownOptions | null = null;

    public industryTypes: string[] | null = null;
    public industrySectors: string[] | null = null;
    public industriesInfo: IndustryTypesAndSectors[] = [];

    constructor(
        private helper: Helper,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private generalInfoService: DocuLibraryService,
    ) {
    }

    get clientId(): string {
        return this.helper.getQuestionnaireClientId();
    }

    get externalDatabasesArray() {
        return this.externalDatabaseForm.get('externalDatabases') as FormArray;
    }

    get regulationArray() {
        return this.regulationForm.get('regulation') as FormArray;
    }

    get listingArray() {
        return this.listingForm.get('listings') as FormArray;
    }

    get listingPristine(): boolean {
        return this.listingArray?.controls.every(x => {
            return x.value.exchangeNameCountry.pristine && x.value.listingId.pristine
        })
    }

    get regulationsPristine(): boolean {
        return this.regulationArray?.controls.every(x => {
            return x.value.regulatorNameCountry.pristine && x.value.regulationId.pristine
        })
    }

    get externalDatabasesPristine(): boolean {
        return this.externalDatabaseForm.pristine && this.externalDatabasesArray?.controls.every(x => {
            return x.value.externalDatabaseSource.pristine && x.value.externalDatabaseId.pristine
        })
    }

    get isSaveDisabled(): boolean {
        return this.generalInfoForm.pristine
            && this.entityTypeForm.pristine
            && this.incorporationForm.pristine
            && this.regulationForm.pristine
            && this.regulationsPristine
            && this.listingForm.pristine
            && this.listingPristine
            && this.charityRegistrationForm.pristine
            && this.industryForm.pristine
            && this.registeredAddressForm.pristine
            && this.mailingAddressForm.pristine
            && this.contactInfoForm.pristine
            && this.financialInformationForm.pristine
            && this.externalDatabaseForm.pristine
            && this.externalDatabasesPristine
            && this.keyDatesForm.pristine;
    }

    ngOnInit(): void {
        this.fetchGeneralInfoData();
    }

    private fetchGeneralInfoData(): void {
        this.isLoading = true;

        this.generalInfoService.fetchRegulatoryDropdownOptions().subscribe((dropDawnData) => {
            this.dropdownData = dropDawnData.Data;

            this.generalInfoService.fetchGeneralInfoData(parseInt(this.clientId)).subscribe((data) => {
                this.dataSource = data.Data;
                this.fillFormData(data.Data);
                this.isLoading = false;
                this.cdr.detectChanges();
            });
        });
    }

    private fillFormData(data: GeneralInfoPayload): void {
        this.generalInfoForm = this.formBuilder.group({
            cid: [data?.CID, Validators.required],
            clientName: [data?.ClientName, Validators.required],
            website: [data?.WebSite, Validators.required],
            alsoKnownAs: [data?.AlsoKnownAs, Validators.required],
            previouslyKnownAs: [data?.PreviouslyKnownAs, Validators.required],
        });

        this.entityTypeForm = this.formBuilder.group({
            entityType: [data?.EntityType, Validators.required],
            entitySubType: [data?.EntitySubType, Validators.required],
        });

        this.incorporationForm = this.formBuilder.group({
            incorporationCountry: [data?.CountryIncorporation, Validators.required],
            incorporationDate: [data?.IncorporationDate, Validators.required],
            incorporationNumber: [data?.IncorporationNumber, Validators.required],
        });

        this.regulationForm = this.formBuilder.group({
            regulation: this.formBuilder.array(
                data?.Regulations?.length
                    ? data?.Regulations.map(x => ({
                        regulatorNameCountry: this.formBuilder.control(x.Country),
                        regulationId: this.formBuilder.control(x.RegulationId)
                    })) : [{
                        regulatorNameCountry: this.formBuilder.control(null),
                        regulationId: this.formBuilder.control(null)
                    }]
            ),
        });

        this.listingForm = this.formBuilder.group({
            listings: this.formBuilder.array(
                data?.Listings?.length ?
                    data?.Listings.map(x => ({
                        exchangeNameCountry: this.formBuilder.control(x.Country),
                        listingId: this.formBuilder.control(x.ListingId),
                    })) : [{
                        exchangeNameCountry: this.formBuilder.control(null),
                        listingId: this.formBuilder.control(null),
                    }],
            ),
        });

        this.charityRegistrationForm = this.formBuilder.group({
            countryOfRegistration: [data?.CountryOfRegistration, Validators.required],
            nameOfFederalAgency: [data?.NameOfFederalAgency, Validators.required],
            registrationId: [data?.RegistrationId, Validators.required],
        });

        this.industryForm = this.formBuilder.group({
            industry: [data?.Industry, Validators.required],
            industryType: [data?.IndustryType, Validators.required],
            sector: [data?.Sector, Validators.required],
            natureOfBusiness: [data?.NatureOfBusiness, Validators.required],
        });

        this.industryForm.valueChanges.subscribe(data => {
            if (data.industry) {
                this.generalInfoService.fetchIndustryTypesAndSectors(data.industry).subscribe(res => {
                    this.industriesInfo = res.Data;
                    this.industryTypes = this.industriesInfo.length ? this.industriesInfo.map(x => x.IndustryType) : null;
                });
            }

            if (data.industry && data.industryType) {
                const industryType = this.industryForm.controls['industryType'].value;
                const industryTypeInfo = this.industriesInfo.find(x => x.IndustryType === industryType);
                this.industrySectors = industryTypeInfo ? industryTypeInfo.Sectors : null;
            }
        });

        this.registeredAddressForm = this.formBuilder.group({
            street: [data?.Street, Validators.required],
            suiteOrUnit: [data?.Suite, Validators.required],
            city: [data?.City, Validators.required],
            country: [data?.CountryOfRegisteredAddress, Validators.required],
            provinceOrState: [data?.Province, Validators.required],
            postalOrZip: [data?.PostalCode, Validators.required],
        });

        this.mailingAddressForm = this.formBuilder.group({
            street: [data?.StreetForMailing, Validators.required],
            suiteOrUnit: [data?.SuiteForMailing, Validators.required],
            city: [data?.CityForMailing, Validators.required],
            country: [data?.CountryOfRegisteredAddressForMailing, Validators.required],
            provinceOrState: [data?.ProvinceForMailing, Validators.required],
            postalOrZip: [data?.PostalCodeForMailing, Validators.required],
        });

        this.contactInfoForm = this.formBuilder.group({
            phone: [data?.PhoneNumber, Validators.required],
            fax: [data?.Fax, Validators.required],
            email: [data?.Email, Validators.required],
        });

        this.financialInformationForm = this.formBuilder.group({
            revenue: [data?.Revenue, Validators.required],
            totalAssetsOrAssetsUnderManagement: [data?.TotalAssets, Validators.required],
            totalEquity: [data?.TotalEquity, Validators.required],
            typeOfFinancialBreakdown: [data?.TypeOfFinancialBreakdown, Validators.required],
            listOfRegions: [data?.QuestionnaireBreakdowns, Validators.required],
        });

        this.externalDatabaseForm = this.formBuilder.group({
            externalDatabases: this.formBuilder.array(
                data?.ExternalDatabases?.length
                    ? data.ExternalDatabases.map(x => ({
                        externalDatabaseSource: this.formBuilder.control(x.ExternalDatabaseSource),
                        externalDatabaseId: this.formBuilder.control(x.ExternalDatabaseId),
                    }))
                    : [{
                        externalDatabaseSource: this.formBuilder.control(null),
                        externalDatabaseId: this.formBuilder.control(null),
                    }]
            ),
        });

        this.keyDatesForm = this.formBuilder.group({
            initialOnboardingDate: [data?.InitialOnboardingDate, Validators.required],
            lastRefreshDate: [data?.LastRefreshDate, Validators.required],
            nextRefreshDate: [data?.NextRefreshDate, Validators.required],
        });
    }

    public handleAddNewExternalDB(): void {
        this.externalDatabaseForm.markAsDirty();

        const newControl: FormControl = this.formBuilder.control({
            externalDatabaseSource: this.formBuilder.control(null),
            externalDatabaseId: this.formBuilder.control(null),
        }, Validators.required);

        this.externalDatabasesArray.push(newControl);
    }

    public change(event: string): void {
        this.industryForm.controls['industryType'].setValue(null);
        this.industryForm.controls['sector'].setValue(null);
    }

    public handleSetMailingAddress(): void {
        this.mailingAddressForm.controls['street'].setValue(
            this.registeredAddressForm.controls['street'].value,
        );
        this.mailingAddressForm.controls['suiteOrUnit'].setValue(
            this.registeredAddressForm.controls['suiteOrUnit'].value,
        );
        this.mailingAddressForm.controls['city'].setValue(
            this.registeredAddressForm.controls['city'].value,
        );
        this.mailingAddressForm.controls['country'].setValue(
            this.registeredAddressForm.controls['country'].value,
        );
        this.mailingAddressForm.controls['provinceOrState'].setValue(
            this.registeredAddressForm.controls['provinceOrState'].value,
        );
        this.mailingAddressForm.controls['postalOrZip'].setValue(
            this.registeredAddressForm.controls['postalOrZip'].value,
        );
    }

    public handleUpdateGeneralInfo(): void {
        if (this.dataSource)
            this.generalInfoService.updateGeneralInfoData({
                Id: this.dataSource.Id,
                ClientId: this.clientId,
                WebSite: this.generalInfoForm.controls['website'].value,
                AlsoKnownAs: this.generalInfoForm.controls['alsoKnownAs'].value,
                PreviouslyKnownAs: this.generalInfoForm.controls['previouslyKnownAs'].value,

                IncorporationDate: this.incorporationForm.controls['incorporationDate'].value,
                IncorporationNumber: this.incorporationForm.controls['incorporationNumber'].value,

                Regulation: this.regulationArray.getRawValue().map(x => ({
                    Country: x.regulatorNameCountry.value,
                    RegulationId: x.regulationId.value
                })),

                Listing: this.listingArray.getRawValue().map(x => ({
                    Country: x.exchangeNameCountry.value,
                    ListingId: x.listingId.value
                })),

                CountryOfRegistration: this.charityRegistrationForm.controls['countryOfRegistration'].value,
                NameOfFederalAgency: this.charityRegistrationForm.controls['nameOfFederalAgency'].value,
                RegistrationId: this.charityRegistrationForm.controls['registrationId'].value,

                Industry: this.industryForm.controls['industry'].value,
                IndustryType: this.industryForm.controls['industryType'].value,
                Sector: this.industryForm.controls['sector'].value,
                NatureOfBusiness: this.industryForm.controls['natureOfBusiness'].value,

                Street: this.registeredAddressForm.controls['street'].value,
                Suite: this.registeredAddressForm.controls['suiteOrUnit'].value,
                City: this.registeredAddressForm.controls['city'].value,
                CountryOfRegisteredAddress: this.registeredAddressForm.controls['country'].value,
                Province: this.registeredAddressForm.controls['provinceOrState'].value,
                PostalCode: this.registeredAddressForm.controls['postalOrZip'].value,

                StreetForMailing: this.mailingAddressForm.controls['street'].value,
                SuiteForMailing: this.mailingAddressForm.controls['suiteOrUnit'].value,
                CityForMailing: this.mailingAddressForm.controls['city'].value,
                CountryOfRegisteredAddressForMailing: this.mailingAddressForm.controls['country'].value,
                ProvinceForMailing: this.mailingAddressForm.controls['provinceOrState'].value,
                PostalCodeForMailing: this.mailingAddressForm.controls['postalOrZip'].value,

                PhoneNumber: this.contactInfoForm.controls['phone'].value,
                Fax: this.contactInfoForm.controls['fax'].value,
                Email: this.contactInfoForm.controls['email'].value,

                Revenue: this.financialInformationForm.controls['revenue'].value,
                TotalAssets: this.financialInformationForm.controls['totalAssetsOrAssetsUnderManagement'].value,
                TotalEquity: this.financialInformationForm.controls['totalEquity'].value,
                TypeOfFinancialBreakdown: this.financialInformationForm.controls['typeOfFinancialBreakdown'].value,

                ExternalDatabase: this.externalDatabasesArray.getRawValue().map(x => ({
                    ExternalDatabaseSource: x.externalDatabaseSource.value,
                    ExternalDatabaseId: x.externalDatabaseId.value
                })),
            }).subscribe(res => {
                this.fetchGeneralInfoData();
            })
    }
}
