import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appBalanceFormatter]'
})
export class BalanceFormatterDirectiveDirective {
  constructor(private el: ElementRef) {
  }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, ''); // Remove non-numeric characters
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // Add spaces every 3 digits
    input.value = value;
  }
}
