import { Component, Input, OnInit } from '@angular/core';
import { Helper } from 'src/app/helpers/helper';

@Component({
  selector: 'change-profile-avatar',
  templateUrl: './change-profile-avatar.component.html',
  styleUrls: ['./change-profile-avatar.component.css'],
})
export class ChangeProfileAvatar implements OnInit {
  constructor(private helper: Helper) {}

  public loading: boolean = true;

  ngOnInit(): void {
    this.helper.getUpdatedImgLoadingStatus().subscribe((isLoading) => {
      this.loading = isLoading;
    });
  }

  @Input() public customImages: string[] = [];
  @Input() public profileImageUrl: string = '';
  @Input() public profileImageBackground: string = '';

  public isChangePhotoDialogOpen = false;

  public handleOpenSelectAvatarDialog(): void {
    this.isChangePhotoDialogOpen = !this.isChangePhotoDialogOpen;
  }
}
