import { Pipe, PipeTransform } from '@angular/core';

interface SearchType {
  column: string;
  checked: boolean;
}

@Pipe({ name: 'search' })
export class DataTableFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): SearchType[] {
    if (!items) return [];
    if (!searchText) return items;

    searchText = searchText.toLocaleLowerCase();

    return items.filter((it) =>
      it.column.toLocaleLowerCase().includes(searchText)
    );
  }
}
