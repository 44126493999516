<div class="d-flex w-100 justify-center align-center spinner-box" *ngIf="isLoading; else dataBlock">
    <mat-spinner [diameter]="70"></mat-spinner>
</div>

<ng-template #dataBlock>
    <ng-container *ngIf="dataSource; else noDataBlock">
        <div class="d-flex w-100 tab-three-column-box">
            <form [formGroup]="emirForm" class="d-flex w-100">
                <!-- I Column -->
                <div class="d-flex flex-column data-column">
                    <!-- Institution -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="institution">Institution</label>
                        <input id="institution" formControlName="institution">
                    </div>

                    <!-- Third Country Entity -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="thirdCountryEntity">Third Country Entity</label>
                        <input id="thirdCountryEntity" formControlName="thirdCountryEntity">
                    </div>

                    <!-- Out Of Scope Entity -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="outOfScopeEntity">Out Of Scope Entity</label>
                        <input id="outOfScopeEntity" formControlName="outOfScopeEntity">
                    </div>

                    <!-- Out Of Scope Entity Type -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="outOfScopeEntityType">Out Of Scope Entity Type</label>
                        <input id="outOfScopeEntityType" formControlName="outOfScopeEntityType">
                    </div>

                    <!-- EMIR Entity Type -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="emirEntityType">EMIR Entity Type</label>
                        <input id="emirEntityType" formControlName="emirEntityType">
                    </div>

                    <!-- Clearing Threshold -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="clearingThreshold">Clearing Threshold</label>
                        <input id="clearingThreshold" formControlName="clearingThreshold">
                    </div>

                    <!-- Pension Scheme Arrangement -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="pensionSchemeArrangement">Pension Scheme Arrangement</label>
                        <input id="pensionSchemeArrangement" formControlName="pensionSchemeArrangement">
                    </div>

                    <!-- Utilizing Pension Scheme Exemption -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="utilizingPensionSchemeExemption">Utilizing Pension Scheme Exemption</label>
                        <input id="utilizingPensionSchemeExemption" formControlName="utilizingPensionSchemeExemption">
                    </div>
                </div>

                <!-- II Column -->
                <div class="d-flex flex-column data-column">
                    <!-- EMIR Clearing Categorization -->
                    <app-regulatory-block-with-header [isHeaderMargin]="false"
                                                      blockHeader="EMIR Clearing Categorization">
                        <!-- Clearing Category IRS -->
                        <div class="control-box w-100">
                            <label for="clearingCategoryIrs">Clearing Category IRS</label>
                            <input id="clearingCategoryIrs" formControlName="clearingCategoryIrs">
                        </div>

                        <!-- Clearing Category Second Rates RTS -->
                        <div class="control-box w-100">
                            <label for="clearingCategorySecondRatesRts">Clearing Category Second Rates RTS</label>
                            <input id="clearingCategorySecondRatesRts" formControlName="clearingCategorySecondRatesRts">
                        </div>

                        <!-- Clearing Category Index CDS -->
                        <div class="control-box w-100">
                            <label for="clearingCategoryIndexCds">Clearing Category Index CDS</label>
                            <input id="clearingCategoryIndexCds" formControlName="clearingCategoryIndexCds">
                        </div>

                        <!-- Alternative Investment Fund -->
                        <div class="control-box w-100">
                            <label for="alternativeInvestmentFund">Alternative Investment Fund</label>
                            <input id="alternativeInvestmentFund" formControlName="alternativeInvestmentFund">
                        </div>

                        <!-- Type Of Pension Scheme Arrangement -->
                        <div class="control-box w-100">
                            <label for="typeOfPensionSchemeArrangement">Type Of Pension Scheme Arrangement</label>
                            <input id="typeOfPensionSchemeArrangement" formControlName="typeOfPensionSchemeArrangement">
                        </div>

                        <!-- Use Of Pension Arrangement Exemption -->
                        <div class="control-box w-100">
                            <label for="useOfPensionArrangementExemption">Use Of Pension Arrangement Exemption</label>
                            <input id="useOfPensionArrangementExemption"
                                   formControlName="useOfPensionArrangementExemption">
                        </div>
                    </app-regulatory-block-with-header>
                </div>

                <!-- III Column -->
                <div class="d-flex flex-column data-column">
                    <!-- EMIR Trade Reporting Block -->
                    <app-regulatory-block-with-header [isHeaderMargin]="false" blockHeader="EMIR Trade Reporting">
                        <!-- Entity Corporate Sector -->
                        <div class="control-box w-100">
                            <label for="entityCorporateSector">Entity Corporate Sector</label>
                            <input id="entityCorporateSector" formControlName="entityCorporateSector">
                        </div>

                        <!-- Trading Capacity Of Reporting Party -->
                        <div class="control-box w-100">
                            <label for="tradingCapacityOfReportingParty">Trading Capacity Of Reporting Party</label>
                            <input id="tradingCapacityOfReportingParty"
                                   formControlName="tradingCapacityOfReportingParty">
                        </div>

                        <!-- Markit Beneficiary ID -->
                        <div class="control-box w-100">
                            <label for="markitBeneficiaryId">Markit Beneficiary ID</label>
                            <input id="markitBeneficiaryId" formControlName="markitBeneficiaryId">
                        </div>

                        <!-- Transactions Undertaken By This Entity Are Directly Linked To Commercial Activity Or Treasury Financing -->
                        <div class="control-box w-100">
                            <label for="markitBeneficiaryId">
                                Transactions Undertaken By This Entity Are Directly Linked To Commercial Activity Or
                                Treasury Financing
                            </label>
                            <input
                                id="transactionsUndertakenByEntityDirectlyLinkedToCommercialActivityOrTreasuryFinancing"
                                formControlName="transactionsUndertakenByEntityDirectlyLinkedToCommercialActivityOrTreasuryFinancing"
                            >
                        </div>

                        <!-- Date Last Updated -->
                        <div class="control-box w-100">
                            <label for="dateLastUpdated">Date Last Updated</label>
                            <input
                                readonly
                                id="dateLastUpdated"
                                [matDatepicker]="picker"
                                class="date-picker-padding"
                                formControlName="dateLastUpdated"
                            >
                            <mat-datepicker-toggle [for]="picker" matIconSuffix class="custom-toggle"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                    </app-regulatory-block-with-header>
                </div>
            </form>

            <!-- Save Block -->
            <div class="d-flex w-100 justify-end">
                <div class="d-flex save-wrapper">
                    <div class="d-flex flex-col">
                        <span class="last-updated">
                            <span class="updated-on-label">
                                Last Updated On:
                            </span>
                            <span class="updated-on-value">
                                {{ dataSource.DateLastUpdated | date }}
                            </span>
                        </span>
                        <div class="updated-by">
                            by Wiam Lou
                        </div>
                    </div>

                    <button class="save-button" [disabled]="emirForm.pristine" (click)="updateEmirData()">Save</button>
                </div>
            </div>
        </div>


    </ng-container>

    <ng-template #noDataBlock>
        <div class="d-flex justify-content-center">
            <no-data-component></no-data-component>
        </div>
    </ng-template>
</ng-template>

