<div class="d-flex w-100 justify-center align-center spinner-box" *ngIf="isLoading; else dataBlock">
    <mat-spinner [diameter]="70"></mat-spinner>
</div>

<ng-template #dataBlock>
    <ng-container *ngIf="dataSource; else noDataBlock">
        <div class="d-flex w-100 tab-three-column-box">
            <form [formGroup]="sdlForm" class="d-flex w-100">
                <!-- I Column -->
                <div class="d-flex flex-column data-column">
                    <!-- Information Recipient Status -->
                    <div class="control-box w-100">
                        <label for="informationRecipientStatus">Information Recipient Status</label>
                        <ng-select
                            [items]="dropdownData ? dropdownData.InformationRecipientStatuses : null"
                            id="informationRecipientStatus"
                            formControlName="informationRecipientStatus">
                        </ng-select>
                    </div>

                    <!-- Information Requested For Purposes Of Transacting In -->
                    <div class="control-box w-100">
                        <label for="informationRequestedForPurposesOfTransactingIn">
                            Information Requested For Purposes Of Transacting In
                        </label>
                        <ng-select
                            [items]="dropdownData ? dropdownData.InformationRequestedForPurposesOfTransactingInTypes : null"
                            id="informationRequestedForPurposesOfTransactingIn"
                            formControlName="informationRequestedForPurposesOfTransactingIn">
                        </ng-select>
                    </div>

                    <!-- Applicable Margin Regulation -->
                    <div class="control-box w-100">
                        <label for="applicableMarginRegulation">Applicable Margin Regulation</label>
                        <ng-select
                            [items]="dropdownData ? dropdownData.ApplicableMarginRegulations : null"
                            id="applicableMarginRegulation"
                            formControlName="applicableMarginRegulation">
                        </ng-select>
                    </div>

                    <!-- PART I: REGISTRATION STATUS -->
                    <app-regulatory-block-with-header
                        blockHeader="PART I: REGISTRATION STATUS"
                    >
                        <!-- Registration Category -->
                        <div class="control-box w-100">
                            <label for="registrationCategory">Registration Category</label>
                            <ng-select
                                [items]="dropdownData ? dropdownData.RegistrationCategories : null"
                                id="registrationCategory"
                                formControlName="registrationCategory">
                            </ng-select>
                        </div>

                        <!-- Prudential Regulator Status -->
                        <div class="control-box w-100">
                            <label for="prudentialRegulatorStatus">Prudential Regulator Status</label>
                            <ng-select
                                [items]="['Yes', 'No']"
                                id="prudentialRegulatorStatus"
                                formControlName="prudentialRegulatorStatus">
                            </ng-select>
                        </div>

                        <!-- Multi-Branch Status -->
                        <div class="control-box w-100">
                            <label for="multiBranchStatus">Multi-Branch Status</label>
                            <ng-select
                                id="multiBranchStatus"
                                [items]="['Yes', 'No']"
                                formControlName="multiBranchStatus">
                            </ng-select>
                        </div>
                    </app-regulatory-block-with-header>

                    <!-- PART II: CONNECTION TO THE UNITED STATES -->
                    <app-regulatory-block-with-header blockHeader="PART II: CONNECTION TO THE UNITED STATES">
                        <!-- A. General Questions -->
                        <app-regulatory-sub-block-with-header
                            class="negative-margin-sub-block"
                            subBlockHeader="A. General Questions"
                        >
                            <!-- U.S. Resident Or Entity -->
                            <div class="control-box w-100">
                                <label for="registrationCategory">U.S. Resident Or Entity</label>
                                <ng-select
                                    [items]="['Yes', 'No']"
                                    id="usaResidentOrEntity"
                                    formControlName="usaResidentOrEntity">
                                </ng-select>
                            </div>

                            <!-- Prudential Regulator Status -->
                            <div class="control-box w-100">
                                <label for="usaPersonCftc2020">U.S. Person (CFTC 2020 Rules/SEC Rules)</label>
                                <ng-select
                                    [items]="['Yes', 'No']"
                                    id="usaPersonCftc2020"
                                    formControlName="usaPersonCftc2020">
                                </ng-select>
                            </div>
                        </app-regulatory-sub-block-with-header>

                        <!-- B. CFTC 2020 Rules -->
                        <app-regulatory-sub-block-with-header
                            class="margin-sub-block"
                            subBlockHeader="B. CFTC 2020 Rules"
                        >
                            <!-- U.S. Person Guarantees (CFTC 2020 Rules) -->
                            <div class="control-box w-100">
                                <label for="usaPersonGuaranteesCftc2020">
                                    U.S. Person Guarantees (CFTC 2020 Rules)
                                </label>
                                <ng-select
                                    [items]="dropdownData ? dropdownData.USPersonGuaranteesCFTC2020Rules : null"
                                    id="usaPersonGuaranteesCftc2020"
                                    formControlName="usaPersonGuaranteesCftc2020">
                                </ng-select>
                            </div>

                            <!-- Financial Entity Guarantees (CFTC 2020 Rules) -->
                            <div class="control-box w-100">
                                <label for="financialEntityGuarantees">Financial Entity Guarantees (CFTC 2020
                                    Rules)</label>
                                <ng-select
                                    [items]="dropdownData ? dropdownData.FinancialEntityGuaranteesCFTC2020Rules : null"
                                    id="financialEntityGuarantees"
                                    formControlName="financialEntityGuarantees">
                                </ng-select>
                            </div>

                            <!-- SD Affiliate -->
                            <div class="control-box w-100">
                                <label for="sdAffiliate">SD Affiliate</label>
                                <ng-select
                                    [items]="['Yes', 'No']"
                                    id="sdAffiliate"
                                    formControlName="sdAffiliate">
                                </ng-select>
                            </div>

                            <!-- Swap Entity Significant Risk Subsidiary -->
                            <div class="control-box w-100">
                                <label for="swapEntitySignificantRiskSubsidiary">Swap Entity Significant Risk
                                    Subsidiary</label>
                                <ng-select
                                    [items]="['Yes', 'No']"
                                    id="swapEntitySignificantRiskSubsidiary"
                                    formControlName="swapEntitySignificantRiskSubsidiary">
                                </ng-select>
                            </div>
                        </app-regulatory-sub-block-with-header>
                    </app-regulatory-block-with-header>
                </div>

                <!-- II Column -->
                <div class="d-flex flex-column data-column">
                    <!-- C. CFTC 2013 Interpretive Guidance -->
                    <app-regulatory-sub-block-with-header
                        class="w-100"
                        subBlockHeader="C. CFTC 2013 Interpretive Guidance"
                    >
                        <!-- U.S. Person -->
                        <div class="control-box w-100">
                            <label for="usaPerson"> U.S. Person</label>
                            <ng-select
                                [items]="['Yes', 'No']"
                                id="usaPerson"
                                formControlName="usaPerson">
                            </ng-select>
                        </div>

                        <!-- Affiliate Conduit -->
                        <div class="control-box w-100">
                            <label for="financialEntityGuarantees">Affiliate Conduit</label>
                            <ng-select
                                [items]="['Yes', 'No']"
                                id="affiliateConduit"
                                formControlName="affiliateConduit">
                            </ng-select>
                        </div>

                        <!-- U.S. Person Guarantees -->
                        <div class="control-box w-100">
                            <label for="usaPersonGuarantees">U.S. Person Guarantees</label>
                            <ng-select
                                [items]="dropdownData ? dropdownData.USPersonGuarantees : null"
                                id="usaPersonGuarantees"
                                formControlName="usaPersonGuarantees">
                            </ng-select>
                        </div>
                    </app-regulatory-sub-block-with-header>

                    <!-- D. CFTC Margin Rules -->
                    <app-regulatory-sub-block-with-header
                        class="w-100 margin-sub-block"
                        subBlockHeader="D. CFTC Margin Rules"
                    >
                        <!-- U.S. Person (CFTC Margin Rules) -->
                        <div class="control-box w-100">
                            <label for="usaPersonCftcMargin">U.S. Person (CFTC Margin Rules)</label>
                            <ng-select
                                [items]="['Yes', 'No']"
                                id="usaPersonCftcMargin"
                                formControlName="usaPersonCftcMargin">
                            </ng-select>
                        </div>

                        <!-- Foreign Consolidated Subsidiary (CFTC Margin Rules) -->
                        <div class="control-box w-100">
                            <label for="foreignConsolidatedSubsidiaryCftcMarginRules">
                                Foreign Consolidated Subsidiary (CFTC Margin Rules)
                            </label>
                            <ng-select
                                [items]="['Yes', 'No']"
                                id="foreignConsolidatedSubsidiaryCftcMarginRules"
                                formControlName="foreignConsolidatedSubsidiaryCftcMarginRules">
                            </ng-select>
                        </div>

                        <!-- U.S. Person Guarantees (CFTC Margin Rules) -->
                        <div class="control-box w-100">
                            <label for="usaPersonGuaranteesCftcMarginRules">
                                U.S. Person Guarantees (CFTC Margin Rules)
                            </label>
                            <ng-select
                                [items]="dropdownData ? dropdownData.USPersonGuaranteesCFTCMarginRules : null"
                                id="usaPersonGuaranteesCftcMarginRules"
                                formControlName="usaPersonGuaranteesCftcMarginRules">
                            </ng-select>
                        </div>
                    </app-regulatory-sub-block-with-header>

                    <!-- E. PR Margin Rules -->
                    <app-regulatory-sub-block-with-header
                        class="w-100 margin-sub-block"
                        subBlockHeader="E. PR Margin Rules"
                    >
                        <!-- PR Foreign Consolidated Subsidiary -->
                        <div class="control-box w-100">
                            <label for="prForeignConsolidatedSubsidiary">PR Foreign Consolidated Subsidiary</label>
                            <ng-select
                                [items]="['Yes', 'No']"
                                id="prForeignConsolidatedSubsidiary"
                                formControlName="prForeignConsolidatedSubsidiary">
                            </ng-select>
                        </div>

                        <!-- PR U.S. Guarantees -->
                        <div class="control-box w-100">
                            <label for="prUsaGuarantees">PR U.S. Guarantees</label>
                            <ng-select
                                [items]="dropdownData ? dropdownData.PRUSGuarantees : null"
                                id="prUsaGuarantees"
                                formControlName="prUsaGuarantees">
                            </ng-select>
                        </div>
                    </app-regulatory-sub-block-with-header>

                    <!-- F. Multi-Branch Entities -->
                    <app-regulatory-sub-block-with-header class="w-100 margin-sub-block"
                                                          subBlockHeader="F. Multi-Branch Entities">
                        <!-- SBS Through Foreign Branch (SEC Rules) -->
                        <div class="control-box w-100">
                            <label for="sbsThroughForeignBranchSecRules">SBS Through Foreign Branch (SEC Rules)</label>
                            <ng-select
                                [items]="dropdownData ? dropdownData.SBSThroughForeignBranchSECRules : null"
                                id="sbsThroughForeignBranchSecRules"
                                formControlName="sbsThroughForeignBranchSecRules">
                            </ng-select>
                        </div>

                        <!-- Swaps Booked Through U.S. Branch (CFTC 2020 Rules) -->
                        <div class="control-box w-100">
                            <label for="swapsBookedThroughUsaBranchCftc2020Rules">
                                Swaps Booked Through U.S. Branch (CFTC 2020 Rules)
                            </label>
                            <ng-select
                                [items]="dropdownData ? dropdownData.SwapsBookedThroughUSBranchCFTC2020Rules : null"
                                id="swapsBookedThroughUsaBranchCftc2020Rules"
                                formControlName="swapsBookedThroughUsaBranchCftc2020Rules">
                            </ng-select>
                        </div>

                        <!-- Swaps Conducted Through Foreign Branch (CFTC 2020 Rules) -->
                        <div class="control-box w-100">
                            <label for="swapsConductedThroughForeignBranchCftc2020Rules">
                                Swaps Conducted Through Foreign Branch (CFTC 2020 Rules)
                            </label>
                            <ng-select
                                [items]="dropdownData ? dropdownData.SwapsConductedThroughForeignBranchCFTC2020Rules : null"
                                id="swapsConductedThroughForeignBranchCftc2020Rules"
                                formControlName="swapsConductedThroughForeignBranchCftc2020Rules">
                            </ng-select>
                        </div>

                        <!-- Swaps Through U.S. Branch (CFTC Margin Rules) -->
                        <div class="control-box w-100">
                            <label for="swapsThroughUsaBranchCftcMarginRules">
                                Swaps Through U.S. Branch (CFTC Margin Rules)
                            </label>
                            <ng-select
                                [items]="dropdownData ? dropdownData.SwapsThroughUSBranchCFTCMarginRules : null"
                                id="swapsThroughUsaBranchCftcMarginRules"
                                formControlName="swapsThroughUsaBranchCftcMarginRules">
                            </ng-select>
                        </div>

                        <!-- Swaps Or SBS Through U.S. Branch (PR Margin Rules) -->
                        <div class="control-box w-100">
                            <label for="swapsOrSbsThroughUsaBranchPrMarginRules">
                                Swaps Or SBS Through U.S. Branch (PR Margin Rules)
                            </label>
                            <ng-select
                                [items]="dropdownData ? dropdownData.SwapsOrSBSThroughUSBranchPRMarginRules : null"
                                id="swapsOrSbsThroughUsaBranchPrMarginRules"
                                formControlName="swapsOrSbsThroughUsaBranchPrMarginRules">
                            </ng-select>
                        </div>
                    </app-regulatory-sub-block-with-header>
                </div>

                <!-- III Column -->
                <div class="d-flex flex-column data-column">
                    <!-- PART III: EXEMPTION FROM MARGIN RULES -->
                    <app-regulatory-block-with-header
                        [isHeaderMargin]="false"
                        blockHeader="PART III: EXEMPTION FROM MARGIN RULES"
                    >
                        <!-- A. CFTC and PR Margin Rules -->
                        <app-regulatory-sub-block-with-header subBlockHeader="A. CFTC and PR Margin Rules">
                            <!-- Non-Financial End User Status -->
                            <div class="control-box w-100">
                                <label for="nonFinancialEndUserStatus">Non-Financial End User Status</label>
                                <ng-select
                                    [items]="dropdownData ? dropdownData.NonFinancialEndUserStatuses : null"
                                    id="nonFinancialEndUserStatus"
                                    formControlName="nonFinancialEndUserStatus">
                                </ng-select>
                            </div>

                            <!-- Hedging Exemption Status -->
                            <div class="control-box w-100">
                                <label for="nonFinancialEndUserStatus">Hedging Exemption Status</label>
                                <ng-select
                                    [items]="dropdownData ? dropdownData.HedgingExemptionStatuses : null"
                                    id="hedgingExemptionStatus"
                                    formControlName="hedgingExemptionStatus">
                                </ng-select>
                            </div>

                            <!-- Exemption Elections -->
                            <div class="control-box w-100">
                                <label for="exemptionElections">Exemption Elections</label>
                                <ng-select
                                    [items]="dropdownData ? dropdownData.ExemptionElections : null"
                                    id="exemptionElections"
                                    formControlName="exemptionElections">
                                </ng-select>
                            </div>

                            <!-- Exemption Reporting -->
                            <div class="control-box w-100">
                                <label for="exemptionReporting">Exemption Reporting</label>
                                <ng-select
                                    [items]="dropdownData ? dropdownData.ExemptionReporting : null"
                                    id="exemptionReporting"
                                    formControlName="exemptionReporting">
                                </ng-select>
                            </div>

                            <!-- Financial Obligations -->
                            <div class="control-box w-100">
                                <label for="financialObligations">Financial Obligations</label>
                                <ng-select
                                    [items]="dropdownData ? dropdownData.FinancialObligations : null"
                                    id="financialObligations"
                                    formControlName="financialObligations">
                                </ng-select>
                            </div>

                            <!-- SEC Issuer/Filer -->
                            <div class="control-box w-100">
                                <label for="secIssuerFiler">SEC Issuer/Filer</label>
                                <ng-select
                                    [items]="dropdownData ? dropdownData.SECIssuerFiler : null"
                                    id="secIssuerFiler"
                                    formControlName="secIssuerFiler">
                                </ng-select>
                            </div>

                            <!-- SEC Central Index Key Number -->
                            <div class="control-box w-100">
                                <label for="secCentralIndexKeyNumber">SEC Central Index Key Number</label>
                                <input id="secCentralIndexKeyNumber" formControlName="secCentralIndexKeyNumber">
                            </div>

                            <!-- Board Approval -->
                            <div class="control-box w-100">
                                <label for="boardApproval">Board Approval</label>
                                <ng-select
                                    [items]="dropdownData ? dropdownData.BoardApproval : null"
                                    id="boardApproval"
                                    formControlName="boardApproval">
                                </ng-select>
                            </div>
                        </app-regulatory-sub-block-with-header>

                        <!-- B. SEC Margin Rules -->
                        <app-regulatory-sub-block-with-header subBlockHeader="B. SEC Margin Rules">
                            <!-- Exemption Status -->
                            <div class="control-box w-100">
                                <label for="exemptionStatus">Exemption Status</label>
                                <ng-select
                                    [items]="dropdownData ? dropdownData.ExemptionStatuses : null"
                                    id="exemptionStatus"
                                    formControlName="exemptionStatus">
                                </ng-select>
                            </div>

                            <!-- Exemption Election -->
                            <div class="control-box w-100">
                                <label for="exemptionElection">Exemption Election</label>
                                <ng-select
                                    [items]="dropdownData ? dropdownData.ExemptionElections : null"
                                    id="exemptionElection"
                                    formControlName="exemptionElection">
                                </ng-select>
                            </div>

                            <!-- Commercial End User Reporting -->
                            <div class="control-box w-100">
                                <label for="commercialEndUserReporting">Commercial End User Reporting</label>
                                <textarea
                                    id="commercialEndUserReporting"
                                    formControlName="commercialEndUserReporting"
                                ></textarea>
                            </div>
                        </app-regulatory-sub-block-with-header>
                    </app-regulatory-block-with-header>

                    <app-regulatory-block-with-header blockHeader="PART IV: FINANCIAL ENTITY STATUS">
                        <!-- Financial Entity -->
                        <div class="control-box w-100">
                            <label for="financialEntity">Financial Entity</label>
                            <ng-select
                                [items]="['Yes', 'No']"
                                id="financialEntity"
                                formControlName="financialEntity">
                            </ng-select>
                        </div>
                    </app-regulatory-block-with-header>
                </div>
            </form>

            <!-- Save Block -->
            <div class="d-flex w-100 justify-end">
                <div class="d-flex save-wrapper">
                    <div class="d-flex flex-col">
                <span class="last-updated">
                    <span class="updated-on-label">
                        Last Updated On:
                    </span>
                    <span class="updated-on-value">
                        {{ dataSource.DateLastUpdating | date }}
                    </span>
                </span>
                        <div class="updated-by">
                            by Wiam Lou
                        </div>
                    </div>

                    <button class="save-button" [disabled]="sdlForm.pristine" (click)="updateSdlData()">Save</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #noDataBlock>
        <div class="d-flex justify-content-center">
            <no-data-component></no-data-component>
        </div>
    </ng-template>
</ng-template>

