import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Chart, registerables } from 'chart.js';
import * as moment from 'moment';
import { AnalyticService } from 'src/app/services/analytic.service';
import { StepData } from '../../analytics.component';

@Component({
  selector: 'data-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css'],
})
export class DataLineChart implements OnInit, OnChanges, AfterContentChecked {
  public chart!: Chart<any>;
  @Input() public chartData: {
    Trendings: { DateTime: string; Value: number; Id: number }[];
    UserTrendings: { TrendingDate: string; Total: number }[];
  } = {
    Trendings: [],
    UserTrendings: [],
  };
  public loading: boolean = false;
  public isChartSetup: boolean = false;
  public chartId: string = 'trending-line-chart';

  @Input() currentMonth: string = '';
  @Input() currentStepObj: StepData = {
    label: '',
    value: 0,
  };

  @Output() public chartDataEmitter = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef,
    private analyticService: AnalyticService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.analyticService.getTrendingGraphData().subscribe((res) => {
      this.chartData = res.Data;
      this.loading = false;
      this.chartDataEmitter.emit(this.chartData);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const ctx = document.getElementById(this.chartId);
    if (ctx && !this.isChartSetup && !this.chart) {
      this.createChart();
      this.isChartSetup = true;
    } else if (!changes.currentStepObj?.firstChange) {
      Chart.getChart(this.chartId)?.destroy();
      this.createChart();
      this.isChartSetup = true;
    }

    if (changes && changes.chartData) {
      Chart.getChart(this.chartId)?.destroy();
      this.createChart();
      this.isChartSetup = true;
    }
  }

  ngAfterContentChecked(): void {
    const ctx = document.getElementById(this.chartId);

    if (ctx && !this.isChartSetup && !this.chart) {
      this.createChart();
      this.isChartSetup = true;
    }
  }

  createChart() {
    Chart.register(...registerables);

    const monthSpecificTrendData = this.chartData.Trendings?.filter((el) =>
      moment(el.DateTime).format('DD MMMM').includes(this.currentMonth)
    );
    const monthSpecificUserTrendData = this.chartData.UserTrendings?.filter(
      (el) =>
        moment(el.TrendingDate).format('DD MMMM').includes(this.currentMonth)
    );

    const trendData = monthSpecificTrendData?.map((el) => el.Value);
    const trendLabels = monthSpecificTrendData?.map((el) =>
      moment(el.DateTime).format('DD MMM')
    );

    const userTrendData = monthSpecificUserTrendData?.map((el) => el.Total);
    const element = document.getElementById(this.chartId) as HTMLCanvasElement;

    let gradientColor;
    if (element) {
      const ctx = element.getContext('2d');

      if (ctx) {
        gradientColor = ctx.createLinearGradient(0, 0, 0, 450);
        gradientColor.addColorStop(0, '#fd305c7a');
        gradientColor.addColorStop(1, '#ffffff97');

        ctx.fillStyle = gradientColor;
        ctx.fillRect(20, 20, 150, 100);
      }
    }

    const zeroRadiusPointsAmount = userTrendData?.length;
    const zeroPointsRadiusArray = Array(zeroRadiusPointsAmount).fill(0);
    const allPoints = [
      ...zeroPointsRadiusArray,
      ...Array(trendData?.length - userTrendData?.length).fill(3),
    ];

    this.chart = new Chart(this.chartId, {
      type: 'line',
      data: {
        labels: trendLabels,
        datasets: [
          {
            fill: false,
            tension: 0.1,
            borderWidth: 1,
            label: 'Trend',
            data: trendData,
            normalized: true,
            pointHoverRadius: 10,
            pointRadius: allPoints,
            borderColor: '#07E5CC',
            borderDash: [5, 2, 5, 10],
            pointBorderColor: '#07E5CC',
            pointBackgroundColor: '#07E5CC',
            pointHoverBorderColor: '#07E5CC',
          },
          {
            fill: true,
            borderWidth: 4,
            pointRadius: 10,
            normalized: true,
            data: userTrendData,
            pointHoverRadius: 15,
            borderColor: '#FD305E',
            label: 'Number of cases',
            backgroundColor: gradientColor,
            pointBackgroundColor: '#FD305E',
          },
        ],
      },
      options: {
        responsive: true,
        events: ['click', 'toggle', 'pointerout'],
        interaction: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true,
            grid: {
              lineWidth: 4,
              display: true,
            },
          },
          x: {
            grid: {
              color: '#EFF1F3',
            },
            ticks: {
              color: '#797C8C',
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: false,
            external: (context) => {
              let tooltipEl = document.getElementById('custom-tooltip');

              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'custom-tooltip';
                tooltipEl.innerHTML = '<div></div>';
                document.body.appendChild(tooltipEl);
              }

              const tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = '0';
                return;
              }

              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              const getBody = (bodyItem: any) => {
                return bodyItem.lines;
              };

              if (tooltipModel.body) {
                const bodyLines = tooltipModel.body.map(getBody);

                let innerHtml =
                  context.tooltip.body.length === 2
                    ? `
                    <div class="tooltip-box" style="display: flex">
                      <div
                        style="
                          width: 45px;
                          height: 45px;
                          display: flex;
                          margin-right: 1rem;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <img
                          style="margin-top: 28px"
                          alt="trending completed icon"
                          src="../../../../../assets/icons/trending-tick.svg"
                        />
                      </div>
                      <div
                        style="
                          display: flex;
                          width: fit-content;
                          align-items: center;
                          justify-content: center;
                          flex-direction: column-reverse;
                        "
                      >
                        ${bodyLines
                          .map(
                            (el) => `
                        <div style="font-weight: bold">${el}</div>
                        `
                          )
                          .toString()
                          .replace(',', '')}
                          <div style="color: #FD305E">${moment(
                            new Date(tooltipModel.title[0])
                          ).format('D-MM-YYYY')}</div>
                      </div>
                    </div>
                    `
                    : `

                    <div style="display: flex; flex-direction: column; position: relative;"> 
                      <div class="tooltip-box" style="margin-left: 1rem; color: white">
                          ${
                            bodyLines
                              .reduce((acc, val) => acc.concat(val), [])[0]
                              .match(/Trend\: (.*)/)[1]
                          }
                      </div>
                      <div style="height: 20px; border-left: 3px solid #07E5CC; width: 5px; position: absolute; bottom: -25px; left: 28px;"></div>
                    </div>
                    `;

                tooltipEl!.innerHTML = innerHtml;
              }

              const position = context.chart.canvas.getBoundingClientRect();

              tooltipEl.style.background =
                context.tooltip.body.length === 2 ? 'white' : '#07E5CC';
              tooltipEl.style.borderRadius =
                context.tooltip.body.length === 2 ? '25px' : '10px';
              tooltipEl.style.display = 'flex';
              tooltipEl.style.justifyContent = 'center';
              tooltipEl.style.padding = '0.5rem 2rem 0.5rem 0.75rem';
              tooltipEl.style.boxShadow = '5px 17px 34px -6px #C6CBE2';
              tooltipEl.style.opacity = '1';
              tooltipEl.style.zIndex = '999';
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.pointerEvents = 'none';

              this.chart.data.datasets.map((el) => {
                if (el.label === 'Trend') {
                  el.pointHoverBackgroundColor = '#07E5CC';
                  el.pointHoverRadius =
                    this.chart.tooltip?.dataPoints.length === 2 ? 0 : 7.5;
                } else {
                  el.pointBorderColor = '#FD305E';
                  el.pointHoverBorderWidth = 10;
                  el.pointHoverBackgroundColor = 'white';
                }
                return el;
              });

              if (tooltipModel.body.length === 2) {
                tooltipEl!.style.left = `${
                  position.left + window.pageXOffset + tooltipModel.caretX + 5
                }px`;

                tooltipEl!.style.top = `${
                  position.top + window.pageYOffset + tooltipModel.caretY - 65
                }px`;
              } else {
                tooltipEl!.style.left =
                  position.left +
                  window.pageXOffset +
                  tooltipModel.caretX -
                  tooltipEl!.clientWidth / 2 +
                  'px';

                tooltipEl!.style.top =
                  position.top +
                  window.pageYOffset +
                  tooltipModel.caretY -
                  60 +
                  'px';
              }
              this.chart.update();
            },
          },
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              usePointStyle: true,
            },
          },
        },
      },
    });
  }

  resetTooltip() {
    const tooltipEl = document.getElementById('custom-tooltip');
    if (tooltipEl) {
      tooltipEl.style.opacity = '0';
    }
  }
}
