import { AnalyticModel } from "./analytic";
import { UserModel } from "./user";

export class MessageModel
{
  Message: string = '';
  CreatedOn: Date = new Date();
  User: UserModel = new UserModel();
  Case: any = {};
  NotificationType: NotificationTypeEnum = NotificationTypeEnum.None;
}

export class NotificationRequestModel
{
  UserId: string = '';
  ToAll: boolean = false;
  Message: string = '';
  SaveToDb: boolean = false;
  Type: NotificationTypeEnum = NotificationTypeEnum.None;
  Data: AnalyticModel[] = [];
}

export enum NotificationTypeEnum {
  None = 0,
  StatusChange = 1,
  AssignChange,
  Nudge
};

