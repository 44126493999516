import {
  OnInit,
  ViewChild,
  Component,
  AfterViewInit,
  ChangeDetectorRef,
  HostListener,
  AfterViewChecked,
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AnalyticService } from 'src/app/services/analytic.service';
import * as moment from 'moment';
import { DataChart } from './data-charts/bar-chart/data-charts.component';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { flatten } from '@angular/compiler';
import { DataLineChart } from './data-charts/line-chart/line-chart.component';
import { DatePipe } from '@angular/common';
import { Helper } from 'src/app/helpers/helper';

export interface Trending {
  DateTime: string;
  Id: number;
  Value: number;
}

export interface ChartData {
  Analyst: string;
  AverageAging: number;
  CaseStatus: string;
  Daily: number;
  Monthly: number;
  Total: number;
}

export interface StepData {
  label: string;
  value: number;
}

export const analystsColors: string[] = [
  '#FF771D',
  '#FF1CD2',
  '#00DCD4',
  '#1DFF4A',
  '#F8F003',
  '#8A1B1B',
  '#AE7097',
  '#9FEB9F',
  '#28957F',
  '#174D37',
  '#888365',
  '#D66970',
  '#FF4A55',
  '#007EFF',
  '#8E4EB9',
];

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'wiam-select-overlay' },
    },
  ],
})
export class AnalyticsComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  targetSet: boolean = false;
  trendingData: any = {};
  showChangeTarget: boolean = false;
  newTarget: number = 0;
  calendarDate = new Date();
  loading: boolean = false;
  bucket: string = '';
  reportDate: string = moment().format('DD-MM-YYYY');
  reportDates: string[] = [];
  chartData: ChartData[] = [];
  allData: ChartData[] = [];
  analyticsTable: any;
  selectedStep: StepData = {
    label: 'Initial Review',
    value: 1,
  };
  allSteps: StepData[] = [
    { label: 'All', value: 0 },
    { label: 'Initial Review', value: 1 },
    { label: 'Outreach', value: 2 },
    { label: 'Name Screening', value: 3 },
    { label: 'Escalation', value: 4 },
    { label: 'Data Validation', value: 5 },
    { label: 'Approval', value: 6 },
    { label: 'Complete', value: 7 },
    { label: 'Descoped', value: 8 },
  ];
  public allStepsTrending: StepData[] = [
    { label: 'January', value: 0 },
    { label: 'February', value: 1 },
    { label: 'March', value: 2 },
    { label: 'April', value: 3 },
    { label: 'May', value: 4 },
    { label: 'June', value: 5 },
    { label: 'July', value: 6 },
    { label: 'August', value: 7 },
    { label: 'September', value: 8 },
    { label: 'October', value: 9 },
    { label: 'November', value: 10 },
    { label: 'December', value: 11 },
  ];
  public selectedStepTrending: StepData = {
    label: 'January',
    value: 0,
  };
  teams: { label: string; value: number; users: [] }[] = [];
  analysts: { label: string; value: number; teamId: number; color: string }[] =
    [];
  buckets: { label: string; value: string }[] = [];

  filteredAnalysts: {
    label: string;
    value: number;
    teamId: number;
    color: string;
  }[] = [];
  selectedTeam: { label: string; value: number; users: [] } = {
    label: '',
    value: 0,
    users: [],
  };
  selectedAnalysts: {
    label: string;
    value: number;
    teamId: number;
    color: string;
  }[] = [];
  selectedBuckets: { label: string; value: string }[] = [];

  private analytics$ = new Subject<any>();

  public currentTargetValue: number = 0;
  isUserCanDownloadReport: boolean = false;

  handleGetCurrentTarget(event: any) {
    const formatDate = (date: moment.Moment) =>
      moment(date).format('MM-DD-YYYY dddd');

    const findComparisonDateIndex = (index: number) => {
      const currentEntryDate = formatDate(trendingData[index].DateTime);

      return currentEntryDate.includes('Monday')
        ? currentDateIndex - 3
        : currentEntryDate.includes('Sunday')
        ? currentDateIndex - 2
        : currentDateIndex - 1;
    };

    const trendingData = [...event.Trendings];
    const currentDate = formatDate(moment());

    const currentDateIndex = trendingData.indexOf(
      trendingData.find(
        (trend: Trending) => formatDate(moment(trend.DateTime)) === currentDate
      )
    );

    const comparisonDateIndex = findComparisonDateIndex(currentDateIndex);

    this.currentTargetValue =
      trendingData[currentDateIndex].Value -
      trendingData[comparisonDateIndex].Value;
  }

  ngAfterViewChecked(): void {}

  constructor(
    public cdr: ChangeDetectorRef,
    public analyticService: AnalyticService,
    private datePipe: DatePipe,
    private helper: Helper
  ) {
    const userRoles = helper.getUser().Role.split(', ');
    this.isUserCanDownloadReport = userRoles.some((role: string) => role === "Administrator" || role === "Manager" || role === "Backup Manager");
  }

  @ViewChild(DataChart) chartComponent!: DataChart;
  @ViewChild('lineChart') lineChart!: DataLineChart;

  @HostListener('scroll', ['$event']) onScroll(event: any) {
    this.lineChart.resetTooltip();
  }

  ngAfterViewInit(): void {}

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {}

  ngOnInit(): void {
    this.loading = true;

    this.analyticService.getTeams().subscribe((res) => {
      const allAnalysts = this.setColorsToAnalysts(
        [].concat.apply([], [...res.Data.map((team: any) => team.Users)]),
        analystsColors
      );

      this.analysts = [
        ...allAnalysts.map((analyst: any) => ({
          label: analyst.FullName,
          value: analyst.Id,
          teamId: analyst.TeamId,
          color: analyst.color,
        })),
      ];
      this.filteredAnalysts = [...this.analysts];

      this.teams = [
        { label: 'All', value: 0, users: allAnalysts },
        ...res.Data.map((team: any) => ({
          label: team.Name,
          value: team.TeamId,
          users: team.Users,
        })),
      ];
      this.selectedAnalysts = [...this.filteredAnalysts];
      this.selectedTeam = this.teams[0];
    });

    this.analyticService.getBuckets().subscribe((res) => {
      this.buckets = res.Data.map((bucket: string) => ({
        label: bucket,
        value: bucket,
      }));
    });

    const analyticsDebounced = this.analytics$.pipe(
      debounceTime(200),
      switchMap((value) => {
        return this.analyticService.getAnalyticTable(
          this.selectedBuckets.map((bucket) => bucket.value),
          this.reportDate
        );
      })
    );

    analyticsDebounced.subscribe(
      (res: { Message: string; Success: boolean; Data: ChartData[] }) => {
        this.allData = res.Data;
        this.chartData = this.selectedAnalysts.length
          ? res.Data.filter((data) =>
              this.selectedAnalysts.some(
                (analyst) => analyst.label === data.Analyst
              )
            )
          : res.Data;
        this.analyticsTable = res.Data.map((item: ChartData) => {
          const analystColor = this.selectedAnalysts.find(
            (selected) => selected.label === item.Analyst
          )?.color;
          return {
            AnalystColor: analystColor,
            ...item,
          };
        });
        this.loading = false;
      }
    );

    this.analytics$.next();
  }

  onDownloadClick() {
    this.analyticService.getFile(this.bucket, this.reportDate).subscribe(
      (response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        var date = new Date();
        var currentDateStr = this.datePipe.transform(date, 'yyyyMMdd_HHmmss');
        var filename = 'Dashboard Report ' + currentDateStr + '.xlsx';
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  refreshClick() {
    this.loading = true;
    this.selectedAnalysts = [];
    this.selectedTeam = this.teams[0];
    this.bucket = '';
    this.reportDate = moment().format('DD-MM-YYYY');
    this.calendarDate = new Date();
    this.analytics$.next(this.reportDate);
  }

  flatten(arr: any[]) {
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(
        Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
      );
    }, []);
  }

  formatDate(date: Date): string {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    var ddStr = dd.toString();
    var mmStr = mm.toString();
    var yyyyStr = yyyy.toString();

    if (dd < 10) {
      ddStr = '0' + ddStr;
    }
    if (mm < 10) {
      mmStr = '0' + mmStr;
    }

    return ddStr + '-' + mmStr + '-' + yyyyStr;
  }

  setColorsToAnalysts(analysts: any[], colors: string[]) {
    const updatedColors = this.flatten(
      Array(Math.ceil(analysts!.length / colors.length)).fill(colors)
    );
    updatedColors.length = analysts.length;

    analysts.forEach((analyst, index) => {
      analyst.color = updatedColors[index];
    });
    return analysts;
  }

  handleStepChange(value: number) {
    this.selectedStep = this.allSteps.find((el) => el.value === value)!;
    this.cdr.detectChanges();
    this.chartComponent.createGeneralDataChart(this.selectedStep.value);
  }

  handleTrendingStepChange(value: number) {
    this.selectedStepTrending = this.allStepsTrending.find(
      (el) => el.value === value
    )!;
  }

  handleDataLoading(event: boolean) {
    this.loading = event;
  }

  handleFilterDate(event: string) {
    this.reportDate = event;
    this.loading = true;

    this.analytics$.next(event);
  }

  handleTeamChange(event: any) {
    this.selectedTeam = event.value;
    this.filteredAnalysts = event.value.users.map((team: any) => ({
      label: team.FullName,
      value: team.Id,
      teamId: team.TeamId,
      color: this.analysts.find(
        (analyst: any) => analyst.label === team.FullName
      )?.color,
    }));
    this.selectedAnalysts = [...this.filteredAnalysts];
    if (this.selectedAnalysts.length) {
      this.chartData = this.allData.filter((data: any) =>
        this.selectedAnalysts.some((analyst) => analyst.label === data.Analyst)
      );
      this.analyticsTable = this.allData.map((item: ChartData) => {
        const analystColor = this.selectedAnalysts.find(
          (selected) => selected.label === item.Analyst
        )?.color;
        return {
          AnalystColor: analystColor,
          ...item,
        };
      });
    } else {
      this.chartData = [];
      this.analyticsTable = [];
      this.loading = false;
    }
  }

  handleAnalystFilter(event: Event): void {
    this.filteredAnalysts = this.analysts.filter((analyst) =>
      analyst.label.includes((event.target as HTMLInputElement).value)
    );
  }

  handleAnalystChange(event: any) {
    this.selectedAnalysts = event.value;

    if (this.selectedAnalysts.length) {
      this.chartData = this.allData.filter((data: any) =>
        this.selectedAnalysts.some((analyst) => analyst.label === data.Analyst)
      );
      this.analyticsTable = this.allData.map((item: ChartData) => {
        const analystColor = this.selectedAnalysts.find(
          (selected) => selected.label === item.Analyst
        )?.color;
        return {
          AnalystColor: analystColor,
          ...item,
        };
      });
    } else {
      this.chartData = [];
      this.analyticsTable = [];
      this.loading = false;
    }
  }

  handleUnselectAllAnalysts() {
    this.selectedAnalysts = [];
    this.analyticsTable = [];
    this.chartData = [];
  }

  handleBucketChange(event: any): void {
    this.loading = true;
    this.selectedBuckets = event.value;

    this.analytics$.next(this.reportDate);
  }

  handleOpenChangeTarget(event: any) {
    if (event.target.id === 'change-target-input') {
      return;
    }

    this.showChangeTarget = !this.showChangeTarget;
  }

  handleInputChange(event: any) {
    this.newTarget = event.target.value;
    this.currentTargetValue = event.target.value;
  }

  setNewTarget() {
    this.targetSet = true;
    this.analyticService
      .updateTrendingGraphTarget(+this.newTarget)
      .subscribe((el) => {
        this.trendingData = el.Data;
        this.handleGetCurrentTarget(el.Data);
      });
  }
}
