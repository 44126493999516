import {
  state,
  style,
  animate,
  trigger,
  transition,
} from '@angular/animations';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Helper } from '../helpers/helper';
import { map, shareReplay } from 'rxjs/operators';
import { delay } from 'rxjs/internal/operators/delay';
import { UpdateUserStatusModel, UserModel } from '../models/user';
import { MatSidenav } from '@angular/material/sidenav';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/internal/operators/startWith';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  ViewChild,
  OnDestroy,
  HostListener,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { WebsocketService } from '../services/websocket.service';
import { MessageModel, NotificationTypeEnum } from '../models/websocket';
import { AppTypes } from './types';

export interface Message {
  Message: string;
  CreatedOn: Date;
  User: UserModel;
  Case: any;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          width: '300px',
        })
      ),
      state(
        'closed',
        style({
          width: '100px',
        })
      ),
      transition('open <=> closed', animate('0.1s')),
    ]),
  ],
})
export class AppComponent implements OnInit, OnChanges, OnDestroy {
  title = 'DDDWeb';
  authentication: boolean = true;
  subscription!: Subscription;
  @ViewChild('sidenav') sidenav: MatSidenav | undefined;
  userInactive: Subject<any> = new Subject();
  userActivity: any;
  public messages: any;

  public sidenavOpened: string = 'opened';
  public isOpen: boolean = true;
  public sidenavState = window.localStorage.getItem('isNavOpened');

  public showSearch = true;
  public showAppHead = true;

  public isSetPassRoute: boolean = false;
  public isForgotPassRoute: boolean = false;
  public isUserMenuFixed: boolean = false;

  public get getAppType() {
    return this.helper.getAppType();
  }

  public get appTypes(): typeof AppTypes {
    return AppTypes;
  }

  public get isOnDashboardApp(): boolean {
    return this.getAppType === this.appTypes.Dashboard;
  }

  public get isOnDocuLibApp(): boolean {
    return this.getAppType === this.appTypes.DocLibrary;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private helper: Helper,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private userService: UserService,
    private wsService: WebsocketService
  ) {
    window.localStorage.setItem('isNavOpened', 'true');

    if (this.helper.isAuthenticated()) {
      this.helper.setUserStatus('Online');
      var userStatus = new UpdateUserStatusModel();
      userStatus.UserId = this.helper.getUserId();
      userStatus.UserStatus = 1; //1 - Online
      this.userService.putUserStatus(userStatus).subscribe((data) => {
        console.log(data);
      });
      this.subscribeToMessages(this.helper.getUserId());
    }
    this.setTimeout();
    this.userInactive.subscribe(() => {
      var userStatus = new UpdateUserStatusModel();
      userStatus.UserId = this.helper.getUserId();
      userStatus.UserStatus = 2; // 2 - Away
      if (this.helper.isAuthenticated()) {
        this.userService.putUserStatus(userStatus).subscribe((data) => {
          this.helper.setUserStatus('Away');
        });
      }
    });

    router.events.subscribe((val: any) => {
      const routerWithoutSearchBar = [
        '/analytics',
        '/user-access-management',
        'user-profile',
        '/archive',
        '/structures'
      ];
      const routerWithoutAppHead = [
        '/questionnaire',
        '/archive',
        '/docu-library',
        '/structures'
      ];

      if (val.url === '/forgot-password') {
        this.isForgotPassRoute = true;
        return;
      }
      this.isForgotPassRoute = false;

      if (val.url === '/analytics') {
        this.isUserMenuFixed = true;
      } else if (val.url !== undefined) {
        this.isUserMenuFixed = false;
      }

      if (val.url) {
        this.showSearch = !routerWithoutSearchBar.some((route) =>
          val.url.includes(route)
        );

        this.showAppHead = !routerWithoutAppHead.some((route) =>
          val.url.includes(route)
        );

        this.isOpen = val.url.includes('/questionnaire') ? false : true;
        this.isOpen = val.url.includes('/structures') ? false : true;
        this.sidenavOpened = this.isOpen ? 'opened' : 'closed';

        if (
          val.url.includes('/questionnaire') ||
          val.url.includes('/archive') ||
          val.url.includes('/structures')
        ) {
          window.localStorage.setItem('isNavOpened', `false`);
        }

        // DOCU LIBRARY
        if (val.url.includes('/docu-library')) {
          this.helper.setAppType(AppTypes.DocLibrary);
        } else {
          this.helper.setAppType(AppTypes.Dashboard);
        }

        return;
      }

      this.sidenavState = window.localStorage.getItem('isNavOpened');
      this.isOpen = this.sidenavState === 'true';
      this.sidenavOpened = this.isOpen ? 'opened' : 'closed';
    });
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {}

  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.helper.isAuthenticated()) {
      var userStatus = new UpdateUserStatusModel();
      userStatus.UserId = this.helper.getUserId();
      userStatus.UserStatus = 0; // 0 - Offline
      this.userService.putUserStatus(userStatus).subscribe((data) => {
        this.helper.setUserStatus('Offline');
      });
    }
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
    if (
      this.helper.isAuthenticated() &&
      (this.helper.getUserStatus() == 'Offline' ||
        this.helper.getUserStatus() == 'Away')
    ) {
      var userStatus = new UpdateUserStatusModel();
      userStatus.UserId = this.helper.getUserId();
      userStatus.UserStatus = 1; // 1 - Online
      this.userService.putUserStatus(userStatus).subscribe((data) => {
        this.helper.setUserStatus('Online');
      });
    }
  }

  setTimeout() {
    this.userActivity = setTimeout(
      () => this.userInactive.next(undefined),
      300000
    ); //5 minutes
  }

  ngAfterViewInit() {
    this.subscription = this.helper
      .isAuthenticationChanged()
      .pipe(startWith(this.helper.isAuthenticated()), delay(0))
      .subscribe((value: boolean) => {
        this.authentication = value;
        if (this.router.url.includes('forgot-password')) {
          this.authentication = false;
          this.isForgotPassRoute = true;
          this.isSetPassRoute = false;
          return;
        } else {
          if (this.router.url.includes('set-password')) {
            this.isSetPassRoute = true;
          } else if (!value) {
            this.isSetPassRoute = false;
            this.router.navigate(['/login']);
          } else {
            this.isSetPassRoute = false;
            this.subscribeToMessages(this.helper.getUserId());
          }
        }
      });
  }

  subscribeToMessages(userId: string) {
    if (!this.messages) {
      this.wsService.connect(userId).subscribe(
        (response: any) => {
          this.messages = response.subscribe((resMessage: any) => {
            let data = JSON.parse(resMessage.data);
            var message = new MessageModel();
            message.Message = data.Message;
            message.CreatedOn = data.CreatedOn;
            message.User = data.User;
            message.Case = data.Case;
            message.NotificationType = data.NotificationType;
            if (message.NotificationType == NotificationTypeEnum.Nudge) {
              this.helper.callNudgeRecieved(message);
              this.helper.callNewNotificationRecieved({
                HtmlMessage: data.HtmlMessage,
                Id: data.Id,
                IsSeen: data.IsSeen,
                TotalUnseen: data.TotalUnseen,
              });
            }
            this.helper.callWsMessageRecieved(message);
          });
        },
        (error) => {
          this.helper.setPubSubUrl('');
          console.log('WebSocket error: ' + error);
        },
        () => {
          this.helper.setPubSubUrl('');
          console.log('websocket complete');
        }
      );
    }
  }

  public handleOpenClose() {
    this.isOpen = !this.isOpen;
    this.sidenavOpened = this.isOpen ? 'opened' : 'closed';

    window.localStorage.setItem('isNavOpened', `${this.isOpen}`);
  }
}
