<div class="d-flex change-avatar-wrapper">
  <img
    [src]="profileImageUrl ? profileImageUrl : './assets/icons/customer.png'"
    alt="current profile photo"
    class="current-profile-photo"
    *ngIf="!loading"
    [ngStyle]="{ background: profileImageBackground }"
  />
  <ngx-skeleton-loader
    count="1"
    *ngIf="loading"
    class="skeleton-loader"
    [theme]="{
      height: '100%',
      width: '100%'
    }"
  ></ngx-skeleton-loader>
</div>

<div class="d-flex change-btn-box">
  <button (click)="handleOpenSelectAvatarDialog()" class="change-profile-photo">
    Change Photo
  </button>

  <avatar-selector
    [ngClass]="{
      'show-dialog': isChangePhotoDialogOpen,
      'hide-dialog': !isChangePhotoDialogOpen
    }"
    [customImages]="customImages"
    (closeDialogEmitter)="handleOpenSelectAvatarDialog()"
  ></avatar-selector>
</div>
