export class UserModel {
  Id: string = '00000000-0000-0000-0000-000000000000';
  Email: string = '';
  FirstName: string = '';
  LastName: string = '';
  UserName: string = '';
  Token: string = '';
  AvatarUrl: string = '';
  ImgUrl: string = '';
  Selected: boolean = false;
  Background: string = '';
  Role: string = '';
}

export class UpdateUserStatusModel {
  UserId: string = '00000000-0000-0000-0000-000000000000';
  UserStatus: number = 0;
}

export class UserAccessModel {
  UserId: string = '00000000-0000-0000-0000-000000000000';
  EmployeeName: string = '';
  Email: string = '';
  Manager: string = '';
  ManagersList: string[] = [];
  AccessRoles: string[] = [];
  AllRoles: string[] = [];
  Active: boolean = false;
}

export class UserProfileModel {
  FirstName: string = '';
  LastName: string = '';
  Email: string = '';
  Role: string = '';
  Manager: string = '';
  AvatarUrl: string = '';
  IconsUrls: string[] = [];
}

export class ProfileImage {
  Data: string = '';
  Description: string = '';
  Filextension: string = '';
  Size: number = 0;
}
