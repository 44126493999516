import { Component, Input } from '@angular/core';

@Component({
  selector: 'client-info-section',
  templateUrl: './client-info-section.component.html',
  styleUrls: ['./client-info-section.component.scss'],
})
export class QuestionnaireClientInfo {
  @Input() public clientId: string = '0289832';
  @Input() public clientName: string = 'Customer Plus';
  @Input() public clientBucket: string = 'Onboarding';
  @Input() public isOnArchive: boolean = false;
}
