<mat-toolbar  [ngClass]="{ 'fixed-usermenu': isUserMenuFixed }"  class="topbar flex-column">
  <div class="fixed-width-columns form-container topbar">
    <!-- search bar section -->
    <div *ngIf="showSearch" class="m-r-44 width-100 search-bar-wrapper">
      <input
        tabindex="0"
        #searchInput
        id="searchInput"
        placeholder="Search here"
        [formControl]="searchInputControl"
        class="input-icon width-271 search-box width-100"
      />
      <span class="search-icon" matSuffix>
        <img src="./../assets/icons/search-icon.svg" alt="search icon" />
      </span>
    </div>

    <!-- notifications section -->
    <notification_btn-with-count
      [numberOfNotification]="totalUnseen"
      (click)="handleNotificationClick()"
      [mdePopoverTriggerFor]="appPopover"
      mdePopoverTriggerOn="click"
      #popoverTrigger="mdePopoverTrigger"
    ></notification_btn-with-count>
    <mde-popover
      #notificationContainer
      class="notifications-popover"
      #appPopover="mdePopover"
      [mdePopoverCloseOnClick]="false"
      [mdePopoverOverlapTrigger]="false"
      [mdePopoverPositionX]="'before'"
      (close)="handleNotificationsClose()"
    >
      <div class="notifications-header">
        <h3>Notifications</h3>
      </div>
      <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
        (scrolled)="onModalScrollDown()" class="notifications-container" [ngClass]="{ 'full-h': isNotificationsFullHeight }">
        <div *ngIf="notifications.length === 0;" class="d-flex w-100 wrapper-no-data">
          <div class="img-label">
            <div class="no-data-box">
              <img alt="no data" style="width: 100px" src="../../../../assets/empty.png" />
            </div>
            <div class="no-data-label">Notifications are empty</div>
          </div>
        </div>
        <div *ngIf="notifications.length">
          <div class="d-flex notification" *ngFor="let notification of notifications">
            <div class="is-seen-wrapper">
              <div class="is-seen" [ngClass]="{
                      seen: notification.IsSeen,
                      unseen: !notification.IsSeen
                    }">
                <div class="circle" *ngIf="!notification.IsSeen"></div>
              </div>
            </div>
            <div [innerHTML]="notification.HtmlMessage"></div>
          </div>
        </div>
      </div>
      <div class="notifications-footer">
        <a class="view-more-btn" (click)="changeNotificationsViewMore()">
          <mat-icon *ngIf="!isNotificationsFullHeight"
            >keyboard_arrow_down</mat-icon
          >
          <mat-icon *ngIf="isNotificationsFullHeight"
            >keyboard_arrow_up</mat-icon
          >
          View More
        </a>
      </div>
    </mde-popover>

    <!-- account settings -->
    <div
      class="frow m-r-0 m-l-40 user-account-section"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
    >
      <div class="cursor-pointer">
        <img
          mat-button
          alt="user account"
          class="user-profile-photo"
          *ngIf="!isProfileImgLoading"
          [ngStyle]="{
            'background-color': '#E7EBFB',
            padding: profileImgUrl ? '0px' : '8px'
          }"
          [src]="profileImgUrl ? profileImgUrl : './assets/icons/customer.png'"
        />
        <ngx-skeleton-loader
          count="1"
          appearance="circle"
          class="skeleton-loader"
          [ngStyle]="{ 'margin-top': isProfileImgLoading ? '1rem' : '0' }"
          *ngIf="isProfileImgLoading"
          [theme]="{
            height: '50px',
            width: '50px',
            background: '#eff1f6',
            'margin-top': '1.5rem'
          }"
        ></ngx-skeleton-loader>

        <img
          alt="arrow"
          class="collapsed-sidenav-arrow"
          src="./../assets/icons/arrow.svg"
          [ngStyle]="{ 'margin-bottom': isProfileImgLoading ? '3rem' : '0' }"
          [ngClass]="{ 'rotate-arrow': t.menuOpen }"
        />
      </div>

      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/welcome">
          <div class="d-flex account-settings-menu-item">
            <img
              class="icon-base"
              src="./../assets/icons/home.svg"
              alt="home"
            />
            Home
          </div>
        </button>
        <button mat-menu-item routerLink="/user-profile">
          <div class="d-flex account-settings-menu-item">
            <img
              class="icon-base"
              src="./../assets/icons/profile.svg"
              alt="profile"
            />
            Profile
          </div>
        </button>
        <button mat-menu-item routerLink="/user-access-management">
          <div class="d-flex account-settings-menu-item">
            <img
              class="icon-base"
              src="./../assets/icons/user-access.svg"
              alt="user access"
            />
            User Access
          </div>
        </button>

        <button mat-menu-item (click)="logout()">
          <div class="d-flex account-settings-logout-item">
            <img
              class="icon-base"
              src="./../assets/icons/log-out.svg"
              alt="log-out"
            />
            Logout
          </div>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
