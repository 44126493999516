<div class="d-flex chart-wrapper w-1oo">
  <div
    class="w-100">
    <canvas
      #chartCanvas
      [id]="chartId"
      style="min-height: 200px; max-height: 250px"
      [ngClass]="{ 'd-none': !chartData.length }"
    >
      {{ chart }}
    </canvas>

    <div id="legend" *ngIf="selectedStep.value !== 0">
      <ng-container *ngIf="chart && chart.data; else empty">
        <ng-container
          *ngFor="let dataEntry of chart.data.labels; let i = index"
        >
          <div class="legend-item">
            <div
              class="color-indicator"
              [style.background]="colorTemplate[i]"
            ></div>
            {{ dataEntry }}
          </div>
        </ng-container>
      </ng-container>
      <ng-template #empty></ng-template>
    </div>
  </div>
  <div class="no-data-box" *ngIf="!chartData.length">
    <div class="img-container">
      <img class="width-80" src="./../../../../../assets/empty.png" alt="">
    </div>
    <div class="descr-container">
      <h6 class="label">No data</h6>
      <span class="descr">No result found for this particular filter setting</span>
    </div>
  </div>
</div>
