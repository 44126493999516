import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'styled-input-field',
  templateUrl: './styled-input-field.component.html',
  styleUrls: ['./styled-input-field.component.css'],
})
export class StyledInputField implements OnChanges {
  @Input() public label = '';
  @Input() public placeholder = '';
  @Input() public value = '';
  @Input() public control: any;

  ngOnChanges(changes: SimpleChanges): void {}
}
