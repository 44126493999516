import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import {
  Input,
  OnInit,
  Output,
  Component,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

interface ApplyFilterPayload {
  columnName: string;
  filters: string[];
}

@Component({
  selector: 'filtering-component',
  templateUrl: './filtering-component.component.html',
  styleUrls: ['./filtering-component.component.scss'],
})
export class ProductFilteringComponent implements OnInit {
  public filterOptions: string[] = [];
  public appliedFilters: string[] = [];

  constructor(private questionnaireService: QuestionnaireService) {}

  @Input() public clientId: number = -1;
  @Input() public columnName: string = '';

  @Output() ApplyFilterEmitter = new EventEmitter<ApplyFilterPayload>();

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;

  ngOnInit(): void {
    if (this.clientId > 0 && this.columnName) {
      this.questionnaireService
        .getProductFilteringOptions({
          clientId: this.clientId,
          columnName: this.columnName,
        })
        .subscribe((res) => {
          this.filterOptions = res.Data;
        });
    }
  }

  public handleApplyFilter(event: MouseEvent, currentFilter: string): void {
    event.stopPropagation();

    const isNewFilter = !this.appliedFilters.some((x) => x === currentFilter);

    if (isNewFilter) {
      this.appliedFilters.push(currentFilter);
    } else {
      this.appliedFilters = [
        ...this.appliedFilters.filter((x) => x !== currentFilter),
      ];
    }
    this.trigger.closeMenu();

    this.ApplyFilterEmitter.emit({
      columnName: this.columnName,
      filters: this.appliedFilters,
    });
  }
}
