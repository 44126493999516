<div class="exemptions-container">
  <div class="loader-container" *ngIf="isLoading">
    <mat-spinner [diameter]="70"></mat-spinner>
  </div>
  <mat-accordion *ngIf="!isLoading" class="example-headers-align" multi>
    <mat-expansion-panel
      hideToggle="true"
      [expanded]="true"
      #caExemptionExPanel
      *ngFor="let form of forms; let i = index"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ form.value.Area }}
        </mat-panel-title>
        <mat-panel-description>
          <div class="expansion-icon-wrapper">
            <mat-icon *ngIf="!caExemptionExPanel.expanded">add</mat-icon>
            <mat-icon *ngIf="caExemptionExPanel.expanded">remove</mat-icon>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <form
        [formGroup]="form"
        *ngIf="isVisible(form.value.Area); else otherRegionView"
      >
        <div class="property-wrapper">
          <div class="property-label col-5">
            {{ form.value.Area }} Exemption
          </div>
          <div class="property-input-wrapper d-flex col-11">
            <mat-select
              disableOptionCentering
              aria-placeholder="Please Select"
              placeholder="Please Select"
              class="wiam-select disabled"
              panelClass="wiam-select-panel wiam-select-panel-singl"
              [value]="getExemptionNameDisplayValue(i).join(', ')"
            >
              <mat-option [value]="getExemptionNameDisplayValue(i).join(', ')">
                <span>{{ getExemptionNameDisplayValue(i).join(", ") }}</span>
              </mat-option>
            </mat-select>

            <div
              class="selected-category-names d-flex"
              *ngIf="getExemptionNameDisplayValue(i)?.length > 0"
            >
              <div
                class="data-block"
                *ngFor="let category of getExemptionNameDisplayValue(i)"
              >
                <img
                  class="selected"
                  alt="selected item indicator"
                  src="../../../../assets/icons/product-filter-active-indicator.svg"
                />
                {{ category }}
              </div>
            </div>
          </div>
        </div>

        <div class="property-wrapper">
          <div class="property-label col-5">
            {{ form.value.Area }} Exemption Category
          </div>
          <div class="property-input-wrapper col-11 d-flex">
            <mat-select
              disableOptionCentering
              aria-placeholder="Please Select"
              placeholder="Please Select"
              class="wiam-select disabled"
              panelClass="wiam-select-panel wiam-select-panel-singl"
              [value]="getExemptionCategoryDisplayValue(i)?.join(', ')"
            >
              <mat-option
                [value]="getExemptionCategoryDisplayValue(i)?.join(', ')"
              >
                <span>{{
                  getExemptionCategoryDisplayValue(i)?.join(", ")
                }}</span>
              </mat-option>
            </mat-select>

            <div
              class="selected-categories d-flex"
              *ngIf="getExemptionCategoryDisplayValue(i)?.length > 0"
            >
              <div
                class="data-block"
                *ngFor="let category of getExemptionCategoryDisplayValue(i)"
              >
                <img
                  class="selected"
                  alt="selected item indicator"
                  src="../../../../assets/icons/product-filter-active-indicator.svg"
                />
                {{ category }}
              </div>
            </div>
          </div>
        </div>
        <div class="property-wrapper">
          <div class="property-label col-5">
            {{ form.value.Area }} Exemption Override
          </div>
          <div class="property-input-wrapper col-11">
            <mat-select
              disableOptionCentering
              aria-placeholder="Please Select"
              placeholder="Please Select"
              class="wiam-select"
              panelClass="wiam-select-panel wiam-select-panel-singl"
              [value]="form.value.exemptionOverride"
              formControlName="exemptionOverride"
            >
              <mat-option
                *ngFor="let eo of getExemptionOverridesForForm(form)"
                [value]="eo"
                (click)="handleExemptionOverride(eo, form)"
              >
                <span>{{ eo }}</span>
              </mat-option>
            </mat-select>
          </div>
        </div>

        <div
          class="property-wrapper"
          *ngIf="
            form.value.exemptionOverride &&
            form.value.exemptionOverride !== 'Not Exempt'
          "
        >
          <div class="property-label col-5">
            {{ form.value.AreaCode }} Override Exemption Category
          </div>
          <div class="property-input-wrapper col-11">
            <mat-select
              disableOptionCentering
              aria-placeholder="Please Select"
              placeholder="Please Select"
              class="wiam-select"
              panelClass="wiam-select-panel wiam-select-panel-singl"
              [value]="form.value.overrideExemptionCategory"
              formControlName="overrideExemptionCategory"
            >
              <mat-option
                *ngFor="let item of getOverrideExemptionCategoriesForForm(form)"
                [value]="item"
                (click)="handleExemptionOverrideCategory(item, form)"
              >
                <span>{{ item }}</span>
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div
          class="property-wrapper comment"
          *ngIf="form.value.overrideExemptionCategory"
        >
          <div class="property-label col-5">Comment</div>
          <div class="property-input-wrapper col-11">
            <input
              class="primary-input"
              placeholder="Enter text here..."
              [value]="form.value.Comment"
              formControlName="Comment"
            />
            <div class="arrow-box">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
          </div>
        </div>
      </form>

      <!-- Other Region View -->
      <ng-template #otherRegionView>
        <form [formGroup]="form">
          <div class="property-wrapper">
            <div class="property-label col-5">
              {{ form.value.Area }} Exemption
            </div>
            <div class="property-input-wrapper d-flex col-11 align-center">
              Undetermined
            </div>
          </div>

          <div class="property-wrapper">
            <div class="property-label col-5">
              {{ form.value.Area }} Exemption Category
            </div>
            <div class="property-input-wrapper col-11 d-flex align-center">
              -
            </div>
          </div>

          <div class="property-wrapper">
            <div class="property-label col-5">
              {{ form.value.Area }} Exemption Override
            </div>
            <div class="property-input-wrapper col-11 align-center">-</div>
          </div>

          <div class="property-wrapper">
            <div class="property-label col-5">
              {{ form.value.Area }} Override Exemption Category
            </div>
            <div class="property-input-wrapper col-11 d-flex align-center">
              -
            </div>
          </div>

          <div class="property-wrapper comment">
            <div class="property-label col-5">Comment</div>
            <div class="property-input-wrapper col-11 align-center">
              No comment
            </div>
          </div>
        </form>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="d-flex justify-end">
    <div class="save-btn">
      <button
        class="primary-purple-btn"
        (click)="saveExemptions()"
        [ngClass]="{ disabled: !isSaveEnabled() }"
      >
        Save
      </button>
    </div>
    <div class="submit-btn m-l-10">
      <button
        class="primary-purple-btn"
        (click)="submitExemptions()"
        [ngClass]="{ disabled: !isSubmitEnabled || isSaveEnabled() }"
      >
        Submit
      </button>
    </div>
  </div>
</div>
