<div class="data-block-wrapper">
    <div class="d-flex block-header" *ngIf="isHeaderVisible">
        {{ blockHeader }}

        <div class="register-address-checkbox" *ngIf="isMailingAddressBlock">
            <mat-checkbox (click)="setMailingAddressEmitter.emit()">Same as Physical Address</mat-checkbox>
        </div>

        <div class="add-new-external-database" *ngIf="isExternalDatabaseBlock">
            <button (click)="addNewExternalDbClickedEmitter.emit()" class="add-btn">+ Add</button>
        </div>
    </div>

    <div class="block-content">
        <ng-content></ng-content>
    </div>
</div>
