import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { AnalyticModel, StatisticReturnModel } from '../models/analytic';
import { StatisticService } from './statistic.service';
import { catchError, finalize } from 'rxjs/operators';
import { FilterColumn } from '../models/filter_column';

export class AnalyticDataSource implements DataSource<AnalyticModel> {
  private analyticsSubject = new BehaviorSubject<AnalyticModel[]>([]);
  private analyticsPaginationSubject = new BehaviorSubject<AnalyticModel[]>([]);

  private addNewEntryTableFilters = new BehaviorSubject<any[]>([]);

  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public analyticCount: number = 0;

  constructor(private statisticService: StatisticService) {}

  loadAnalytics(
    userId: string,
    filter: string,
    sortField: string,
    sortDirection: string,
    pageIndex: number,
    pageSize: number,
    filterColumns: FilterColumn[]
  ) {
    this.loadingSubject.next(true);
    if (filterColumns.length > 0) {
      filterColumns[0].PageNumber = pageIndex;
      filterColumns[0].SortBy = sortField;
      filterColumns[0].SortDirection = sortDirection;
      filterColumns[0].Filter = filter;
      this.statisticService
        .postStatisticByFilteredColumns(filterColumns)
        .pipe(
          catchError(() => of([])),
          finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((analytics: any) => {
          this.analyticCount = analytics.Total;
          this.analyticsSubject.next(analytics.Data);
        });
    } else {
      this.statisticService
        .getUserStatistic(
          userId,
          pageIndex,
          pageSize,
          sortField,
          sortDirection,
          filter
        )
        .pipe(
          catchError(() => of([])),
          finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((analytics: any) => {
          this.analyticCount = analytics.Total;
          this.analyticsSubject.next(analytics.Data);
        });
    }
  }

  filterData(
    data: any[],
    filterColumns: FilterColumn[],
    sortField: string,
    sortDirection: string
  ) {
    if (filterColumns.length > 0) {
      for (let i = 0; i < filterColumns.length; i++) {
        data = data.filter((item) => {
          var columnName = filterColumns[i].ColumnName.substring(
            filterColumns[i].ColumnName.indexOf('.') + 1
          );
          return filterColumns[i].Excludes.indexOf(item[columnName]) == -1;
        });
      }
    }
    if (sortField) {
      if (sortDirection == 'asc') {
        data = data.sort((a, b) => {
          var columnName = sortField.substring(sortField.indexOf('.') + 1);
          return a[columnName] > b[columnName] ? 1 : -1;
        });
      } else {
        data = data.sort((a, b) => {
          var columnName = sortField.substring(sortField.indexOf('.') + 1);
          return a[columnName] < b[columnName] ? 1 : -1;
        });
      }
    }

    this.analyticCount = data.length;
    this.analyticsSubject.next(data);
  }

  connect(collectionViewer: CollectionViewer): Observable<AnalyticModel[]> {
    console.log('Connecting data source');
    return this.analyticsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.analyticsSubject.complete();
    this.loadingSubject.complete();
  }

  saveDataForPagination(data: AnalyticModel[]): void {
    return this.analyticsPaginationSubject.next(data);
  }

  getDataForPagination(): Observable<AnalyticModel[]> {
    return this.analyticsPaginationSubject.asObservable();
  }

  passData(data: AnalyticModel[]) {
    this.getDataForPagination().subscribe((res) => {
      this.analyticCount = res.length;
      this.analyticsSubject.next(data);
    });
  }

  getData(): any[] {
    let data: AnalyticModel[] = [];
    this.analyticsSubject.subscribe((res) => (data = res));
    return data && data.length ? data : [];
  }

  handleFilteringSortingNewEntry(): any[] {
    let data: AnalyticModel[] = [];
    this.analyticsSubject.subscribe((res) => (data = res));
    return data && data.length ? data : [];
  }

  saveFiltersForAddNewEntryTable(data: any[]): void {
    return this.addNewEntryTableFilters.next(data);
  }

  getFiltersForAddNewEntryTable(): Observable<any[]> {
    return this.addNewEntryTableFilters.asObservable();
  }

  getFilterValues(): any[] {
    let data: any[] = [];
    this.getFiltersForAddNewEntryTable().subscribe((res) => (data = res));

    return data && data.length ? data : [];
  }
}
