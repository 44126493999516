<button
  [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger"
  class="column-header-menu-btn"
>
  <div
    class="column-header-menu-icon"
    [ngClass]="{ 'rotate-arrow': menuTrigger.menuOpen }"
  >
    <img
      alt="filter-icon"
      src="../../../../assets/icons/product-filtering-icon.svg"
    />
  </div>
</button>

<mat-menu #menu="matMenu">
  <ng-container *ngIf="filterOptions.length > 0; else noFiltersFound">
    <ng-container *ngFor="let filter of filterOptions; let i = index">
      <button
        [class.single-menu-item]="filterOptions.length === 1"
        mat-menu-item
        (click)="handleApplyFilter($event, filter)"
      >
        <div class="d-flex">
          <img
            alt="selected-filter"
            class="filter-selected"
            [class.visible]="appliedFilters.includes(filter)"
            src="../../../../assets/icons/product-filter-active-indicator.svg"
          />
          <span
            class="sort-btn"
            style="color: #636775; font-size: 12px; font-family: Poppins-Light"
          >
            {{ filter }}
          </span>
        </div>
      </button>
    </ng-container>
  </ng-container>

  <ng-template #noFiltersFound>
    <div class="d-flex no-filters-found">No filters available</div>
  </ng-template>
</mat-menu>
