import {
  Input,
  OnInit,
  OnChanges,
  Component,
  ViewChild,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import {
  Regions,
  Exchanges,
  Countries,
  Regulators,
  EntityTypes,
  KnownOwnerships,
  HightRiskBusiness,
  InputTypesByLength,
  InputTypesByPurpose,
  QuestionnaireItemIds,
  QuestionnaireListType,
  RegulatedGovernmentOwnership,
  InvestmentRevenueBreakdownSourceOfFunds,
  SubEntityTypes,
  SelectedPolicies,
  FormValidityStatus,
} from './types';
import { forkJoin, of } from 'rxjs';
import { MatTable } from '@angular/material/table';
import { QuestionnaireDataList } from '../tab-container/types';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError } from 'rxjs/operators';
import { Helper } from 'src/app/helpers/helper';

@Component({
  selector: 'questionnaire-tab-table',
  templateUrl: './questionnaire-tab-data-table.component.html',
  styleUrls: ['./questionnaire-tab-data-table.component.scss'],
})
export class QuestionnaireTabTableComponent implements OnInit, OnChanges {
  public inputSize = InputTypesByLength;
  public inputTypes = InputTypesByPurpose;
  public displayedColumns: string[] = ['questionLabel', 'dataInputSection'];
  public dropdowns: string[] = [
    'Regulators',
    'RiskBusinesses',
    'OwnershipKnows',
    'RegulatedGovernmentOwnership',
    'Countries',
    'EntityTypes',
    'Exchanges',
    'GeographicalRegions',
  ];
  public regulators: Regulators[] = [];
  public regions: Regions[] = [];
  public countries: Countries[] = [];
  public entityTypes: EntityTypes[] = [];
  public entitySubTypes: EntityTypes[] = [];
  public hightRiskBusinesses: HightRiskBusiness[] = [];
  public exchanges: Exchanges[] = [];
  public knownOwnerships: KnownOwnerships[] = [];
  public regulatedGovernmentOwnership: RegulatedGovernmentOwnership[] = [];
  public clientName: string = '';
  public caseId: number = -1;
  public questionnaireId: number = -1;
  public listedRegulatedGovernmentOwnershipOptions: any;
  public selectedPolicies: SelectedPolicies[] = [];
  public isNewLegalNameValid: boolean = true;
  public showNewLegalNameValidationMessage: boolean = false;
  public isSaving: boolean = false;

  constructor(
    private helper: Helper,
    private snackBar: MatSnackBar,
    private questionnaireService: QuestionnaireService
  ) {}

  @Input() public isLoading: boolean = false;
  @Input() public questionnaireData: QuestionnaireDataList | undefined;
  @ViewChild(MatTable) questionnaireMatTable: MatTable<Element> | undefined;

  @Output() public isSaveDisabled = new EventEmitter();
  @Output() public isSubmitDisabled = new EventEmitter();
  @Output() public initDataPopulated = new EventEmitter();
  @Output() public isSectionOpened = new EventEmitter<boolean>();
  @Output() public isSectionCollapsed = new EventEmitter<boolean>();

  public get isFormValid(): boolean {
    return this.questionnaireForm.status === FormValidityStatus.valid;
  }

  public questionnaireForm = new FormGroup({
    Id: new FormControl(0),
    CaseId: new FormControl(''),
    ClientName: new FormControl(''),

    ClientId: new FormControl(''),
    LEI: new FormControl(''),
    ClientLegalName: new FormControl(''), // preselected value
    ClientLegalChange: new FormControl(''),
    NewLegalName: new FormControl(''),
    IsClientIncorporated: new FormControl(''),
    CountryOfIncorporation: new FormControl({ Id: -1, Name: '' }, []),
    EntityType: new FormControl('', []),
    EntitySubType: new FormControl('', []),
    ClientHasAssetManagerInvestAdvisor: new FormControl('', []),
    LegalNameAssetManagerInvestAdvisor: new FormControl('', []),
    // legalNameOfAssetManagerOrInvestmentAdvisor: new FormControl('', []),
    IsAssetManagerAiRegulated: new FormControl('', []),
    IsAssetManagerAlListed: new FormControl('', []),
    HighRiskBusiness: new FormControl(''),
    CountryOfOperationKnown: new FormControl(''),
    InvestmentRevenueBreakdownSourceOfFunds: new FormControl(''),
    InvestmentBreakdown: new FormControl(''),
    RevenueBreakdown: new FormControl(''),
    SourceOfFunds: new FormControl(''),
    IsClientListed: new FormControl(''),
    NameOfExchange: new FormControl(''),
    DoesClientMoreThan75MillionCad: new FormControl(''),
    IsClientRegulated: new FormControl(''),
    NameOfRegulator: new FormControl('', []),
    IsOwnershipKnown: new FormControl('', []),
    ListedRegulatedGovernmentOwnership: new FormControl('', []),
    IsParentListed: new FormControl('', []),
    NameOfParentExchange: new FormControl('', []),
    DoesListedParentMoreThan75MillionCad: new FormControl('', []),
    IsClientFinancialConsoloidatedWithParent: new FormControl('', []),
    IsParentRegulated: new FormControl('', []),
    NameOfParentRegulator: new FormControl('', []),
    HowMuchAssetsUnderManagement: new FormControl('', []),
    WasChangeOwnerhsipSinceLastCollected: new FormControl(''),
    WasChangeControlPersonSinceLastColl: new FormControl(''),
  });

  public questionnaireList: QuestionnaireListType[] = [
    {
      id: QuestionnaireItemIds.ClientId,
      label: 'Client ID',
      inputType: InputTypesByPurpose.readonlyInput,
      isVisible: true,
      formControl: this.questionnaireForm.controls['ClientId'],
    },
    {
      id: QuestionnaireItemIds.LEI,
      label: 'LEI',
      inputSize: InputTypesByLength.largeInput,
      inputType: InputTypesByPurpose.regularInput,
      isVisible: true,
      formControl: this.questionnaireForm.controls['LEI'],
    },
    {
      id: QuestionnaireItemIds.ClientLegalName,
      label: 'Client Legal Name',
      inputType: InputTypesByPurpose.readonlyInput,
      isVisible: true,
      formControl: this.questionnaireForm.controls['ClientLegalName'],
    },
    {
      id: QuestionnaireItemIds.ClientLegalChange,
      label: 'Client Legal Name Change?',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: true,
      formControl: this.questionnaireForm.controls['ClientLegalChange'],
      autocompleteOptions: ['Yes', 'No'],
    },
    {
      id: QuestionnaireItemIds.NewLegalName,
      label: 'New Legal Name',
      inputSize: InputTypesByLength.largeInput,
      inputType: InputTypesByPurpose.regularInput,
      isVisible: false,
      formControl: this.questionnaireForm.controls['NewLegalName'],
    },
    {
      id: QuestionnaireItemIds.IsClientIncorporated,
      label: 'Is the client incorporated?',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: true,
      formControl: this.questionnaireForm.controls['IsClientIncorporated'],
      autocompleteOptions: ['Yes', 'No'],
    },
    {
      id: QuestionnaireItemIds.CountryOfIncorporation,
      label: 'Country of Incorporation',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl: this.questionnaireForm.controls['CountryOfIncorporation'],
      autocompleteOptions: this.countries,
      isAutocompleteSearchable: true,
    },
    {
      id: QuestionnaireItemIds.EntityType,
      label: 'Entity Type',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: true,
      formControl: this.questionnaireForm.controls['EntityType'],
      autocompleteOptions: this.entityTypes,
      isAutocompleteSearchable: true,
    },
    {
      id: QuestionnaireItemIds.EntitySubType,
      label: 'Entity Sub-Type',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl: this.questionnaireForm.controls['EntitySubType'],
      autocompleteOptions: this.entitySubTypes,
      isAutocompleteSearchable: true,
    },
    {
      id: QuestionnaireItemIds.ClientHasAssetManagerInvestAdvisor,
      label: 'Client has an Asset Manager/Investment Advisor?',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl:
        this.questionnaireForm.controls['ClientHasAssetManagerInvestAdvisor'],
      autocompleteOptions: ['Yes', 'No', 'Unknown'],
    },
    {
      id: QuestionnaireItemIds.LegalNameAssetManagerInvestAdvisor,
      label: 'Legal Name of Asset Manager/Investment Advisor',
      inputSize: InputTypesByLength.largeInput,
      inputType: InputTypesByPurpose.regularInput,
      isVisible: false,
      formControl:
        this.questionnaireForm.controls['LegalNameAssetManagerInvestAdvisor'],
    },
    {
      id: QuestionnaireItemIds.IsAssetManagerAiRegulated,
      label: 'Is the Asset Manager/AI regulated?',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl: this.questionnaireForm.controls['IsAssetManagerAiRegulated'],
      autocompleteOptions: ['Yes', 'No'],
    },
    {
      id: QuestionnaireItemIds.IsAssetManagerAlListed,
      label: 'Is the Asset Manager/AI Listed?',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl: this.questionnaireForm.controls['IsAssetManagerAlListed'],
      autocompleteOptions: ['Yes', 'No'],
    },
    {
      id: QuestionnaireItemIds.HighRiskBusiness,
      label: 'High Risk Business',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: true,
      formControl: this.questionnaireForm.controls['HighRiskBusiness'],
      autocompleteOptions: this.hightRiskBusinesses,
      isAutocompleteSearchable: true,
    },
    {
      id: QuestionnaireItemIds.CountryOfOperationKnown,
      label: 'Country of primary Business Known?',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: true,
      formControl: this.questionnaireForm.controls['CountryOfOperationKnown'],
      autocompleteOptions: ['Yes', 'No'],
    },
    {
      id: QuestionnaireItemIds.InvestmentRevenueBreakdownSourceOfFunds,
      label: 'Investment/Revenue Breakdown or Source of Funds',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl:
        this.questionnaireForm.controls[
          'InvestmentRevenueBreakdownSourceOfFunds'
        ],
      autocompleteOptions: [
        'Investment Breakdown',
        'Revenue Breakdown',
        'Source of Funds',
      ],
    },
    {
      id: QuestionnaireItemIds.InvestmentBreakdown,
      label: 'Investment Breakdown',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInputWithSelections,
      isVisible: false,
      formControl: this.questionnaireForm.controls['InvestmentBreakdown'],
      autocompleteOptions: this.countries,
      isAutocompleteSearchable: true,
    },
    {
      id: QuestionnaireItemIds.RevenueBreakdown,
      label: 'Revenue Breakdown',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInputWithSelections,
      isVisible: false,
      formControl: this.questionnaireForm.controls['RevenueBreakdown'],
      autocompleteOptions: this.countries,
      isAutocompleteSearchable: true,
    },
    {
      id: QuestionnaireItemIds.SourceOfFunds,
      label: 'Source of Funds',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInputWithSelections,
      isVisible: false,
      formControl: this.questionnaireForm.controls['SourceOfFunds'],
      autocompleteOptions: this.countries,
      isAutocompleteSearchable: true,
    },
    {
      id: QuestionnaireItemIds.IsClientListed,
      label: 'Is the client Listed?',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: true,
      formControl: this.questionnaireForm.controls['IsClientListed'],
      autocompleteOptions: ['Yes', 'No'],
    },
    {
      id: QuestionnaireItemIds.NameOfExchange,
      label: 'Name of the Exchange',
      inputSize: InputTypesByLength.largeInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl: this.questionnaireForm.controls['NameOfExchange'],
      autocompleteOptions: ['options'],
      isAutocompleteSearchable: true,
      isMultiselect: true,
    },
    {
      id: QuestionnaireItemIds.DoesClientMoreThan75MillionCad,
      label: 'Does the client have more than $75 million CAD in Net assets?',
      inputSize: InputTypesByLength.largeInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl:
        this.questionnaireForm.controls['DoesClientMoreThan75MillionCad'],
      autocompleteOptions: ['Yes', 'No'],
    },
    {
      id: QuestionnaireItemIds.IsClientRegulated,
      label: 'Is the client regulated',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: true,
      formControl: this.questionnaireForm.controls['IsClientRegulated'],
      autocompleteOptions: ['Yes', 'No'],
    },
    {
      id: QuestionnaireItemIds.NameOfRegulator,
      label: 'Name of the regulator',
      inputSize: InputTypesByLength.mediumInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl: this.questionnaireForm.controls['NameOfRegulator'],
      autocompleteOptions: this.regulators,
      isAutocompleteSearchable: true,
      isMultiselect: true,
    },
    {
      id: QuestionnaireItemIds.IsOwnershipKnown,
      label: 'Is the Ownership Known?',
      inputSize: InputTypesByLength.semiSmallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl: this.questionnaireForm.controls['IsOwnershipKnown'],
      autocompleteOptions: this.knownOwnerships,
    },
    {
      id: QuestionnaireItemIds.ListedRegulatedGovernmentOwnership,
      label: 'Listed/Regulated/Government ownership interest',
      inputSize: InputTypesByLength.semiSmallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl:
        this.questionnaireForm.controls['ListedRegulatedGovernmentOwnership'],
      autocompleteOptions: this.regulatedGovernmentOwnership,
    },
    {
      id: QuestionnaireItemIds.IsParentListed,
      label: 'Is the Parent Listed?',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: true,
      formControl: this.questionnaireForm.controls['IsParentListed'],
      autocompleteOptions: ['Yes', 'No'],
    },
    {
      id: QuestionnaireItemIds.NameOfParentExchange,
      label: 'Name of Parent Exchange',
      inputSize: InputTypesByLength.semiSmallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl: this.questionnaireForm.controls['NameOfParentExchange'],
      autocompleteOptions: ['Yes - Wholly owned-100%'],
      isAutocompleteSearchable: true,
    },
    {
      id: QuestionnaireItemIds.DoesListedParentMoreThan75MillionCad,
      label:
        'Does the listed parent have more than $75 million CAD in Net assets?',
      inputSize: InputTypesByLength.semiSmallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl:
        this.questionnaireForm.controls['DoesListedParentMoreThan75MillionCad'],
      autocompleteOptions: ['Yes', 'No'],
    },
    {
      id: QuestionnaireItemIds.IsClientFinancialConsoloidatedWithParent,
      label: "Is the client's financials consolidated with the Listed Parent?",
      inputSize: InputTypesByLength.largeInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl:
        this.questionnaireForm.controls[
          'IsClientFinancialConsoloidatedWithParent'
        ],
      autocompleteOptions: ['Yes', 'No'],
    },
    {
      id: QuestionnaireItemIds.IsParentRegulated,
      label: 'Is the Parent Regulated?',
      inputSize: InputTypesByLength.largeInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl: this.questionnaireForm.controls['IsParentRegulated'],
      autocompleteOptions: ['Yes', 'No'],
    },
    {
      id: QuestionnaireItemIds.NameOfParentRegulator,
      label: 'Name of Parent Regulator',
      inputSize: InputTypesByLength.mediumInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl: this.questionnaireForm.controls['NameOfParentRegulator'],
      autocompleteOptions: this.regulators,
    },
    {
      id: QuestionnaireItemIds.HowMuchAssetsUnderManagement,
      label: 'How much assets under management does the client have?',
      inputSize: InputTypesByLength.semiSmallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: false,
      formControl:
        this.questionnaireForm.controls['HowMuchAssetsUnderManagement'],
      autocompleteOptions: [
        '<$50 Million',
        '$50-$100 Million',
        '> $100 Million',
        'Unknown',
      ],
    },
    {
      id: QuestionnaireItemIds.WasChangeOwnerhsipSinceLastCollected,
      label: 'Was there a change in ownership since last collected?',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: true,
      formControl:
        this.questionnaireForm.controls['WasChangeOwnerhsipSinceLastCollected'],
      autocompleteOptions: ['Yes', 'No', 'Unknown'],
    },
    {
      id: QuestionnaireItemIds.WasChangeControlPersonSinceLastColl,
      label: 'Was there a change in control person since last collected?',
      inputSize: InputTypesByLength.smallInput,
      inputType: InputTypesByPurpose.autocompleteInput,
      isVisible: true,
      formControl:
        this.questionnaireForm.controls['WasChangeControlPersonSinceLastColl'],
      autocompleteOptions: ['Yes', 'No', 'Unknown'],
    },
  ];

  public dataSource = this.questionnaireList.filter((x) => x.isVisible);

  private updateAutocompleteOptions<T>(
    data: { Data: T[]; Message: string; Success: boolean },
    updateKey?: QuestionnaireItemIds
  ): void {
    this.questionnaireList.map((x) => {
      if (updateKey)
        if (x.id === updateKey)
          x.autocompleteOptions = data && data.Data ? data.Data : [];

      return x;
    });
  }

  ngOnInit(): void {
    this.fetchSelectedPolicies();

    this.questionnaireForm.valueChanges.subscribe((changes) => {
      this.handleDataTableChange(changes);

      this.isSaveDisabled.emit(false);
      this.isSubmitDisabled.emit(true);
    });

    // fetching all the required dropdown options
    this.isLoading = true;
    forkJoin(
      this.dropdowns.map((key) =>
        this.questionnaireService.fetchDropdownData(key)
      )
    )
      .pipe(catchError((err) => of([])))
      .subscribe(
        ([
          regulators,
          hightRiskBusinesses,
          knownOwnerships,
          regulatedGovernmentOwnership,
          countries,
          entityTypes,
          exchanges,
          regions,
        ]) => {
          // Regions and Countries should be separated since
          // we pass them into autocomplete component vie @Input() decorator
          this.regions = regions?.Data as Regions[];
          this.countries = countries?.Data as Countries[];

          this.listedRegulatedGovernmentOwnershipOptions =
            regulatedGovernmentOwnership?.Data;

          this.updateAutocompleteOptions(
            regulators,
            QuestionnaireItemIds.NameOfRegulator
          );

          this.updateAutocompleteOptions(
            regulators,
            QuestionnaireItemIds.NameOfParentRegulator
          );

          this.updateAutocompleteOptions(
            countries,
            QuestionnaireItemIds.CountryOfIncorporation
          );

          this.updateAutocompleteOptions(
            entityTypes,
            QuestionnaireItemIds.EntityType
          );

          this.updateAutocompleteOptions(
            hightRiskBusinesses,
            QuestionnaireItemIds.HighRiskBusiness
          );

          this.updateAutocompleteOptions(
            exchanges,
            QuestionnaireItemIds.NameOfExchange
          );

          this.updateAutocompleteOptions(
            exchanges,
            QuestionnaireItemIds.NameOfParentExchange
          );

          this.updateAutocompleteOptions(
            knownOwnerships,
            QuestionnaireItemIds.IsOwnershipKnown
          );

          this.updateAutocompleteOptions(
            regulatedGovernmentOwnership,
            QuestionnaireItemIds.ListedRegulatedGovernmentOwnership
          );

          if (
            this.questionnaireData &&
            this.listedRegulatedGovernmentOwnershipOptions
          ) {
            setTimeout(() => {
              if (this.questionnaireData) {
                this.handleDataPopulate(this.questionnaireData);
                this.isLoading = false;
              }
            }, 1000);
          }
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.questionnaireData) {
      const data = changes.questionnaireData.currentValue;
      this.handleDataPopulate(data);

      this.isSaveDisabled.emit(true);
      this.isSubmitDisabled.emit(false);
    }
  }

  public handleDataTableChange(changes: any): void {
    const convertBooleanToString = (value: boolean | string) => {
      if (typeof value === 'string') return value;
      return value ? 'Yes' : 'No';
    };

    this.questionnaireList.map((x) => {
      switch (x.id) {
        case QuestionnaireItemIds.NewLegalName:
          x.isVisible =
            convertBooleanToString(changes.ClientLegalChange) === 'Yes';

          if (convertBooleanToString(changes.ClientLegalChange) === 'Yes') {
            this.isNewLegalNameValid = this.questionnaireForm.controls[
              QuestionnaireItemIds.NewLegalName
            ].value
              ? true
              : false;
          } else {
            this.isNewLegalNameValid = true;
          }

          if (convertBooleanToString(changes.ClientLegalChange) === 'No') {
            if (x.formControl.value) x.formControl.setValue(null);
          }
          break;

        case QuestionnaireItemIds.CountryOfIncorporation:
          x.isVisible =
            convertBooleanToString(changes.IsClientIncorporated) === 'Yes';

          if (convertBooleanToString(changes.IsClientIncorporated) === 'No') {
            if (x.formControl.value) x.formControl.setValue(null);
          }
          break;

        case QuestionnaireItemIds.EntitySubType:
          let entities =
            typeof changes.EntityType === 'string'
              ? changes.EntityType
              : (changes.EntityType as unknown as EntityTypes);

          const subEntities =
            entities?.SubEntityTypes as unknown as SubEntityTypes[];
          const isVisible = !(
            subEntities &&
            subEntities.length === 1 &&
            subEntities[0].Name === '-'
          );

          x.isVisible = isVisible && entities;

          if (!isVisible) {
            if (x.formControl.value) x.formControl.setValue(null);
          }

          if (subEntities?.length) {
            this.entitySubTypes = subEntities ? subEntities : [];
            this.questionnaireList.map((x) => {
              if (x.id === QuestionnaireItemIds.EntitySubType)
                x.autocompleteOptions = this.entitySubTypes;
            });
          }
          break;

        case QuestionnaireItemIds.ClientHasAssetManagerInvestAdvisor:
          x.isVisible =
            (changes.EntityType as unknown as EntityTypes)?.Name === 'Fund';
          break;

        case QuestionnaireItemIds.LegalNameAssetManagerInvestAdvisor:
          x.isVisible = changes.ClientHasAssetManagerInvestAdvisor === 'Yes';

          if (changes.ClientHasAssetManagerInvestAdvisor === 'No') {
            if (x.formControl.value) x.formControl.setValue(null);
          }
          break;

        case QuestionnaireItemIds.IsAssetManagerAiRegulated:
          x.isVisible = changes.ClientHasAssetManagerInvestAdvisor === 'Yes';

          if (changes.ClientHasAssetManagerInvestAdvisor === 'No') {
            if (x.formControl.value) x.formControl.setValue(null);
          }
          break;

        case QuestionnaireItemIds.IsAssetManagerAlListed:
          x.isVisible = changes.ClientHasAssetManagerInvestAdvisor === 'Yes';

          if (changes.ClientHasAssetManagerInvestAdvisor === 'No') {
            if (x.formControl.value) x.formControl.setValue(null);
          }
          break;

        case QuestionnaireItemIds.NameOfExchange:
          if (changes.IsClientListed === 'Yes') {
            x.isVisible = true;
          } else {
            x.isVisible = false;
            if (x.formControl.value) x.formControl.setValue(null);
          }

          break;

        case QuestionnaireItemIds.DoesClientMoreThan75MillionCad:
          x.isVisible =
            changes.IsClientListed === 'Yes' &&
            this.isCanadaAmongSelectedPolicies;

          if (changes.IsClientListed === 'No') {
            if (x.formControl.value) x.formControl.setValue(null);
          }
          break;

        case QuestionnaireItemIds.NameOfRegulator:
          x.isVisible = changes.IsClientRegulated === 'Yes';

          if (changes.IsClientRegulated === 'No') {
            if (x.formControl.value) x.formControl.setValue(null);
          }
          break;

        case QuestionnaireItemIds.IsOwnershipKnown:
          x.isVisible = true; // Generate if all applicable policies are not exempt
          break;

        case QuestionnaireItemIds.ListedRegulatedGovernmentOwnership:
          const knownOwnership = (
            changes.IsOwnershipKnown as unknown as KnownOwnerships
          )?.Value;

          x.isVisible =
            knownOwnership === 'Yes - Owned by a listed entity' ||
            knownOwnership === 'Yes- Owned by a regulated entity' ||
            knownOwnership === 'Yes- Owned by the Government';
          break;

        case QuestionnaireItemIds.NameOfParentExchange:
          const ownership = (
            changes.IsOwnershipKnown as unknown as KnownOwnerships
          )?.Value;

          x.isVisible = ownership === 'Yes - Owned by a listed entity';
          break;

        case QuestionnaireItemIds.DoesListedParentMoreThan75MillionCad:
          x.isVisible =
            changes.IsParentListed === 'Yes' &&
            this.isCanadaAmongSelectedPolicies;

          if (changes.IsParentListed === 'No') {
            if (x.formControl.value) x.formControl.setValue(null);
          }
          break;

        case QuestionnaireItemIds.IsClientFinancialConsoloidatedWithParent:
          x.isVisible =
            changes.IsParentListed === 'Yes' &&
            this.isCanadaAmongSelectedPolicies;

          if (changes.IsParentListed === 'No') {
            if (x.formControl.value) x.formControl.setValue(null);
          }
          break;

        case QuestionnaireItemIds.NameOfParentRegulator:
          x.isVisible =
            changes.IsOwnershipKnown === 'Yes - Owned by a regulated entity';
          break;

        case QuestionnaireItemIds.HowMuchAssetsUnderManagement:
          x.isVisible =
            (changes.EntityType as unknown as EntityTypes)?.Name ===
            'Investment Advisor'; // Manager??????
          break;

        case QuestionnaireItemIds.InvestmentRevenueBreakdownSourceOfFunds:
          x.isVisible = changes.CountryOfOperationKnown === 'Yes';

          if (
            convertBooleanToString(changes.CountryOfOperationKnown) === 'No'
          ) {
            if (x.formControl.value) x.formControl.setValue(null);
          }
          break;

        case QuestionnaireItemIds.InvestmentBreakdown:
          x.isVisible =
            changes.InvestmentRevenueBreakdownSourceOfFunds ===
              'Investment Breakdown' &&
            changes.CountryOfOperationKnown === 'Yes';

          if (
            convertBooleanToString(changes.CountryOfOperationKnown) === 'No'
          ) {
            if (x.formControl.value) x.formControl.setValue(null);
          }
          break;

        case QuestionnaireItemIds.RevenueBreakdown:
          x.isVisible =
            changes.InvestmentRevenueBreakdownSourceOfFunds ===
              'Revenue Breakdown' && changes.CountryOfOperationKnown === 'Yes';

          if (
            convertBooleanToString(changes.CountryOfOperationKnown) === 'No'
          ) {
            if (x.formControl.value) x.formControl.setValue(null);
          }
          break;

        case QuestionnaireItemIds.SourceOfFunds:
          x.isVisible =
            changes.InvestmentRevenueBreakdownSourceOfFunds ===
              'Source of Funds' && changes.CountryOfOperationKnown === 'Yes';

          if (
            convertBooleanToString(changes.CountryOfOperationKnown) === 'No'
          ) {
            if (x.formControl.value) x.formControl.setValue(null);
          }
          break;

        case QuestionnaireItemIds.IsParentRegulated:
          x.isVisible =
            (changes.IsOwnershipKnown as unknown as KnownOwnerships)?.Value ===
            'Yes- Owned by a regulated entity';
          break;

        default:
          break;
      }

      return x;
    });
    this.tableContentChanged();
    this.dataSource = this.questionnaireList.filter((x) => x.isVisible);
  }

  public handleDataPopulate(questionnaireData: QuestionnaireDataList): void {
    if (questionnaireData) {
      this.caseId = questionnaireData.CaseId;
      this.questionnaireId = questionnaireData.Id;
      this.clientName = questionnaireData.ClientName;

      const keys = Object.keys(questionnaireData);

      keys.map((key) => {
        // TODO: WE NEED TO FIX THE TYPO IN IsAssetMangerAiRegulated AND REMOVE IT
        // CONDITION ADDED SOLELY TO RESOLVE THE TYPO ISSUE
        if (key === ('IsAssetMangerAiRegulated' as QuestionnaireItemIds)) {
          this.questionnaireList.map((x) => {
            if (x.id === QuestionnaireItemIds.IsAssetManagerAiRegulated) {
              if (typeof questionnaireData[key] === 'boolean') {
                x.formControl.setValue(questionnaireData[key] ? 'Yes' : 'No');
              }
            }
          });
        }

        this.questionnaireList.map((x) => {
          if (x.id === key) {
            switch (typeof questionnaireData[key]) {
              case 'boolean':
                x.formControl.setValue(questionnaireData[key] ? 'Yes' : 'No');
                break;

              default:
                if (x.id === QuestionnaireItemIds.CountryOfIncorporation) {
                  const countryOfIncorporation = x.autocompleteOptions?.find(
                    (x) => x.Name === questionnaireData.CountryOfIncorporation
                  );
                  x.formControl.setValue(countryOfIncorporation);
                } else if (x.id === QuestionnaireItemIds.EntityType) {
                  const entityType = x.autocompleteOptions?.find(
                    (x) => x.Name === questionnaireData.EntityType
                  );

                  x.formControl.setValue(entityType);
                } else if (x.id === QuestionnaireItemIds.EntitySubType) {
                  x.formControl.setValue(questionnaireData[key]);
                } else if (x.id === QuestionnaireItemIds.HighRiskBusiness) {
                  const hightRiskBusinesses = x.autocompleteOptions?.find(
                    (x) => x.Name === questionnaireData.HighRiskBusiness
                  );

                  x.formControl.setValue(hightRiskBusinesses);
                } else if (x.id === QuestionnaireItemIds.NameOfExchange) {
                  x.formControl.setValue(questionnaireData[key]);
                } else if (x.id === QuestionnaireItemIds.NameOfRegulator) {
                  x.formControl.setValue(questionnaireData[key]);
                } else if (x.id === QuestionnaireItemIds.IsOwnershipKnown) {
                  const isOwnershipKnown = x.autocompleteOptions?.find(
                    (x) => x.Value === questionnaireData.IsOwnershipKnown
                  );

                  x.formControl.setValue(isOwnershipKnown);
                } else if (
                  x.id ===
                  QuestionnaireItemIds.ListedRegulatedGovernmentOwnership
                ) {
                  const options = this.questionnaireList.find(
                    (x) =>
                      x.id ===
                      QuestionnaireItemIds.ListedRegulatedGovernmentOwnership
                  )?.autocompleteOptions;

                  const ownership = options
                    ? options.find(
                        (x) =>
                          x.Value ===
                          questionnaireData.ListedRegulatedGovernmentOwnership
                      )
                    : null;

                  if (ownership) x.formControl.setValue(ownership.Value);
                } else if (
                  x.id ===
                  QuestionnaireItemIds.InvestmentRevenueBreakdownSourceOfFunds
                ) {
                  switch (
                    questionnaireData.InvestmentRevenueBreakdownSourceOfFunds
                  ) {
                    case 1:
                      x.formControl.setValue('Investment Breakdown');
                      break;
                    case 2:
                      x.formControl.setValue('Revenue Breakdown');
                      break;
                    case 3:
                      x.formControl.setValue('Source of Funds');
                      break;

                    default:
                      break;
                  }

                  // x.formControl.setValue(breakdowns);
                } else if (x.id === QuestionnaireItemIds.InvestmentBreakdown) {
                  const investmentBreakdown =
                    questionnaireData.InvestmentBreakdown;

                  x.formControl.setValue(investmentBreakdown);
                } else if (x.id === QuestionnaireItemIds.RevenueBreakdown) {
                  const revenueBreakdown = questionnaireData.RevenueBreakdown;

                  x.formControl.setValue(revenueBreakdown);
                } else if (x.id === QuestionnaireItemIds.SourceOfFunds) {
                  const sourceOfFunds = questionnaireData.SourceOfFunds;

                  x.formControl.setValue(sourceOfFunds);
                } else x.formControl.setValue(questionnaireData[key]);

                break;
            }
          }
          return x;
        });
      });
    }
    this.initDataPopulated.emit();
  }

  public onSubmit(): void {
    this.showNewLegalNameValidationMessage = false;
    const getControlValue = (key: string) => {
      const control = this.questionnaireForm.get(key);

      if (
        key === QuestionnaireItemIds.ListedRegulatedGovernmentOwnership &&
        typeof control?.value === 'string'
      ) {
        const options = this.questionnaireList.find(
          (x) =>
            x.id === QuestionnaireItemIds.ListedRegulatedGovernmentOwnership
        )?.autocompleteOptions;

        const ownership = options
          ? options.find((x) => x.Value === control?.value)
          : null;

        return ownership;
      }

      return control?.value === 'Yes'
        ? true
        : control?.value === 'No'
        ? false
        : control?.value;
    };

    const getBreakdowns = (value: string) => {
      switch (value) {
        case InvestmentRevenueBreakdownSourceOfFunds.InvestmentBreakdown:
          return 1;
        case InvestmentRevenueBreakdownSourceOfFunds.RevenueBreakdown:
          return 2;
        case InvestmentRevenueBreakdownSourceOfFunds.SourceOfFunds:
          return 3;

        default:
          return 0;
      }
    };

    const formBreakdownObjects = (
      incomingBreakdowns: {
        Name: string;
        Value: number;
        Id: number;
      }[],
      control: QuestionnaireItemIds
    ) => {
      switch (control) {
        case QuestionnaireItemIds.InvestmentBreakdown:
          const existingInvBreakdown =
            this.questionnaireData?.InvestmentBreakdown || [];

          const transformedBreakdowns = incomingBreakdowns?.map(
            (newBreakdown) => {
              existingInvBreakdown.map(
                (existingBreakdown: {
                  Id: number;
                  Name: string;
                  Value: number;
                }) => {
                  if (newBreakdown.Name === existingBreakdown.Name) {
                    newBreakdown.Id = existingBreakdown.Id;
                  }

                  return existingBreakdown;
                }
              );

              return {
                Id: existingInvBreakdown.length ? newBreakdown.Id : 0,
                Name: newBreakdown.Name,
                Value: newBreakdown.Value,
              };
            }
          );

          return transformedBreakdowns;

        case QuestionnaireItemIds.RevenueBreakdown:
          const existingRevenueBreakdown =
            this.questionnaireData?.RevenueBreakdown || [];

          const transformedRevenueBreakdown = incomingBreakdowns?.map(
            (newBreakdown) => {
              existingRevenueBreakdown.map(
                (existingBreakdown: {
                  Id: number;
                  Name: string;
                  Value: number;
                }) => {
                  if (newBreakdown.Name === existingBreakdown.Name) {
                    newBreakdown.Id = existingBreakdown.Id;
                  }

                  return existingBreakdown;
                }
              );

              return {
                Id: existingRevenueBreakdown.length ? newBreakdown.Id : 0,
                Name: newBreakdown.Name,
                Value: newBreakdown.Value,
              };
            }
          );

          return transformedRevenueBreakdown;

        case QuestionnaireItemIds.SourceOfFunds:
          const existingSourceOfFunds =
            this.questionnaireData?.SourceOfFunds || [];

          const transformedSourceOfFunds = incomingBreakdowns?.map(
            (newBreakdown) => {
              existingSourceOfFunds.map(
                (existingBreakdown: {
                  Id: number;
                  Name: string;
                  Value: number;
                }) => {
                  if (newBreakdown.Name === existingBreakdown.Name) {
                    newBreakdown.Id = existingBreakdown.Id;
                  }

                  return existingBreakdown;
                }
              );

              return {
                Id: existingSourceOfFunds.length ? newBreakdown.Id : 0,
                Name: newBreakdown.Name,
                Value: newBreakdown.Value,
              };
            }
          );
          return transformedSourceOfFunds;

        default:
          return [{ Id: 0, Name: '', Value: 0 }];
      }
    };

    const payloadNew = {
      Id: this.questionnaireId || 0,
      ClientId: getControlValue('ClientId'),
      CaseId: this.caseId,
      ClientName: this.clientName,
      LEI: getControlValue('LEI'),
      ClientLegalName: getControlValue('ClientLegalName'),
      ClientLegalChange: getControlValue('ClientLegalChange'),
      NewLegalName: getControlValue('NewLegalName'),
      IsClientIncorporated: getControlValue('IsClientIncorporated'),

      // Country Of Incorporation
      CountryOfIncorporationId:
        getControlValue('CountryOfIncorporation')?.Id || null,
      CountryOfIncorporation:
        getControlValue('CountryOfIncorporation')?.Name || null,

      // Entity Type
      EntityTypeId: getControlValue('EntityType')?.Id || null,
      EntityType: getControlValue('EntityType')?.Name || null,

      // Entity Sub Type
      EntitySubTypeId:
        getControlValue('EntityType')?.SubEntityTypes?.find(
          (x: any) => x.Name === getControlValue('EntitySubType')
        )?.Id || null,
      EntitySubType:
        getControlValue('EntityType')?.SubEntityTypes?.find(
          (x: any) => x.Name === getControlValue('EntitySubType')
        )?.Name || null,

      ClientHasAssetManagerInvestAdvisor: getControlValue(
        'ClientHasAssetManagerInvestAdvisor'
      ),
      LegalNameAssetManagerInvestAdvisor: getControlValue(
        'LegalNameAssetManagerInvestAdvisor'
      ),
      IsAssetMangerAiRegulated:
        getControlValue('IsAssetManagerAiRegulated') || null,
      IsAssetManagerAlListed: getControlValue('IsAssetManagerAlListed'),

      // High Risk Business
      HighRiskBusinessId: getControlValue('HighRiskBusiness')?.Id || null,
      HighRiskBusiness: getControlValue('HighRiskBusiness')?.Name || null,

      CountryOfOperationKnown: getControlValue('CountryOfOperationKnown'),

      InvestmentRevenueBreakdownSourceOfFunds: getBreakdowns(
        getControlValue('InvestmentRevenueBreakdownSourceOfFunds')
      ),

      InvestmentBreakdown:
        formBreakdownObjects(
          getControlValue('InvestmentBreakdown'),
          QuestionnaireItemIds.InvestmentBreakdown
        ) || null,

      RevenueBreakdown:
        formBreakdownObjects(
          getControlValue('RevenueBreakdown'),
          QuestionnaireItemIds.RevenueBreakdown
        ) || null,

      SourceOfFunds:
        formBreakdownObjects(
          getControlValue('SourceOfFunds'),
          QuestionnaireItemIds.SourceOfFunds
        ) || null,

      IsClientListed: getControlValue('IsClientListed'),

      // Name Of Exchange
      NameOfExchange: getControlValue('NameOfExchange') || [],

      DoesClientMoreThan75MillionCad: getControlValue(
        'DoesClientMoreThan75MillionCad'
      ),
      IsClientRegulated: getControlValue('IsClientRegulated'),

      // Name Of Regulator
      NameOfRegulator: getControlValue('NameOfRegulator') || [],

      // Is Ownership Known
      IsOwnershipKnownId: getControlValue('IsOwnershipKnown')?.Id || null,
      IsOwnershipKnown: getControlValue('IsOwnershipKnown')?.Value || null,

      // Listed Regulated Government Ownership
      ListedRegulatedGovernmentOwnershipId:
        getControlValue('ListedRegulatedGovernmentOwnership')?.Id || null,
      ListedRegulatedGovernmentOwnership:
        getControlValue('ListedRegulatedGovernmentOwnership')?.Value || null,

      IsParentListed: getControlValue('IsParentListed'),

      // Name of Parent Exchange
      NameOfParentExchange:
        typeof getControlValue('NameOfParentExchange') === 'object'
          ? getControlValue('NameOfParentExchange')?.ListOfExchange
          : getControlValue('NameOfParentExchange') || null,

      DoesListedParentMoreThan75MillionCad: getControlValue(
        'DoesListedParentMoreThan75MillionCad'
      ),
      IsClientFinancialConsoloidatedWithParent: getControlValue(
        'IsClientFinancialConsoloidatedWithParent'
      ),
      IsParentRegulated: getControlValue('IsParentRegulated'),
      NameOfParentRegulatorId:
        getControlValue('NameOfParentRegulator')?.Id || null,
      NameOfParentRegulator:
        getControlValue('NameOfParentRegulator')?.Name || null,

      HowMuchAssetsUnderManagementId:
        getControlValue('HowMuchAssetsUnderManagementId')?.Id || null,
      HowMuchAssetsUnderManagement:
        getControlValue('HowMuchAssetsUnderManagementId')?.Name || null,

      WasChangeOwnerhsipSinceLastCollected: getControlValue(
        'WasChangeOwnerhsipSinceLastCollected'
      ),
      WasChangeControlPersonSinceLastColl: getControlValue(
        'WasChangeControlPersonSinceLastColl'
      ),
    };

    this.isSaving = true;
    this.questionnaireService
      .submitQuestionnaireData(payloadNew)
      .subscribe((res) => {
        this.isSaving = false;
        this.snackBar.open('Changes successfully saved!', 'X', {
          panelClass: ['success-snackbar'],
          duration: 3000,
        });

        if (res.Data.NewLegalName) {
          this.questionnaireForm.controls['ClientLegalName'].setValue(
            res.Data.NewLegalName
          );
          this.questionnaireForm.controls['ClientName'].setValue(
            res.Data.NewLegalName
          );
          this.helper.setQuestionnaireClientName(res.Data.NewLegalName);
        }

        this.isSaveDisabled.emit(true);
        this.isSubmitDisabled.emit(false);
      });
  }

  public handleHideFormField() {
    this.questionnaireForm.controls[
      QuestionnaireItemIds.EntitySubType
    ].setValue(null);
  }

  public fetchSelectedPolicies() {
    const clientId = this.helper.getQuestionnaireClientId();

    this.questionnaireService
      .getPolicies(+clientId, true)
      .subscribe((policies) => {
        this.selectedPolicies = policies.Data;
      });
  }

  public get isCanadaAmongSelectedPolicies(): boolean {
    return this.selectedPolicies.some((x) => x.CountryCode === 'CA');
  }

  public handleNewLegalNameInvalid() {
    this.showNewLegalNameValidationMessage = true;

    document
      .querySelector('#' + QuestionnaireItemIds.NewLegalName)
      ?.scrollIntoView({ behavior: 'smooth' });
  }

  public tableContentChanged(): () => void {
    // re-rendering table content on dataSource change
    // timeout was added to prevent exceeding maximum call stack
    const timeoutId = setTimeout(
      () => this.questionnaireMatTable?.renderRows(),
      0
    );
    return () => clearTimeout(timeoutId);
  }

  public handleRemoveItemClick(selectedOptions: any): void {
    if (this.questionnaireData)
      switch (this.questionnaireData.InvestmentRevenueBreakdownSourceOfFunds) {
        case 1:
          this.questionnaireForm.controls['InvestmentBreakdown'].setValue(
            selectedOptions
          );
          break;

        case 2:
          this.questionnaireForm.controls['RevenueBreakdown'].setValue(
            selectedOptions
          );
          break;

        case 3:
          this.questionnaireForm.controls['SourceOfFunds'].setValue(
            selectedOptions
          );
          break;

        default:
          break;
      }
  }
}
