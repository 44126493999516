<div class="loader-container" *ngIf="isLoading; else dataBlock">
  <mat-spinner [diameter]="70"></mat-spinner>
</div>

<ng-template #dataBlock>
  <ng-container *ngIf="dataSource; else noDataBlock">
    <div class="exemptions-container">
      <!-- Risk Attributes -->
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel hideToggle="true" [expanded]="true" #riskAttributes>
          <mat-expansion-panel-header>
            <mat-panel-title> Risk Attributes</mat-panel-title>
            <mat-panel-description>
              <div class="expansion-icon-wrapper">
                <mat-icon *ngIf="!riskAttributes.expanded">add</mat-icon>
                <mat-icon *ngIf="riskAttributes.expanded">remove</mat-icon>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <form [formGroup]="riskAttributesForm">
            <ng-container *ngFor="let item of formControls">
              <div class="property-wrapper" *ngIf="item !== 'CountryCategoryIds'">
                <div class="property-label col-5">{{ controls[item] }}</div>
                <div class="property-input-wrapper col-11 d-flex">
                  {{
                  item === "CountryCategories" &&
                  riskAttributesControlValue(item).length
                    ? riskAttributesControlValue(item).join(", ")
                    : riskAttributesControlValue(item).Name || "-"
                  }}
                </div>
              </div>
            </ng-container>
          </form>
        </mat-expansion-panel>
      </mat-accordion>

      <!-- Potential Screening Outcome -->
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel
          hideToggle="true"
          [expanded]="true"
          #potentialScreeningOutcome
        >
          <mat-expansion-panel-header>
            <mat-panel-title>{{ isOnStructures ? 'Screening Outcome' : 'Potential Screening Outcome'}}</mat-panel-title>
            <mat-panel-description>
              <div class="expansion-icon-wrapper">
                <mat-icon *ngIf="!potentialScreeningOutcome.expanded">add</mat-icon>
                <mat-icon *ngIf="potentialScreeningOutcome.expanded">
                  remove
                </mat-icon>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <form [formGroup]="potentialScreeningOutcomeForm">
            <!-- PEP -->
            <div class="property-wrapper">
              <div class="property-label col-5">PEP</div>
              <div class="property-input-wrapper col-11">
                {{ potentialScreeningOutcomeForm.controls['Pep'].value.Name || "-" }}
              </div>
            </div>

            <!-- PEP DESCRIPTION -->
            <div class="property-wrapper">
              <div class="property-label col-5">PEP Description</div>
              <div class="property-input-wrapper col-11">
                {{ potentialScreeningOutcomeForm.controls['PepDescription'].value || "-" }}
              </div>
            </div>

            <!-- Adverse Information -->
            <div class="property-wrapper">
              <div class="property-label col-5">Adverse Information</div>
              <div class="property-input-wrapper col-11">
                {{  potentialScreeningOutcomeForm.controls['AdverseInformation'].value.Name || "-" }}
              </div>
            </div>

            <!-- Negative Information Detail -->
            <div class="property-wrapper">
              <div class="property-label col-5">Negative Information Detail</div>
              <div class="property-input-wrapper col-11">
                {{ potentialScreeningOutcomeForm.controls['NegativeInformationDetail'].value || '-' }}
              </div>
            </div>
          </form>
        </mat-expansion-panel>
      </mat-accordion>

      <!-- Overall Risk Score -->
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel hideToggle="true" [expanded]="true" #potentialRisk>
          <mat-expansion-panel-header>
            <mat-panel-title> Overall Risk Score</mat-panel-title>
            <mat-panel-description>
              <div class="expansion-icon-wrapper">
                <mat-icon *ngIf="!potentialRisk.expanded">add</mat-icon>
                <mat-icon *ngIf="potentialRisk.expanded">remove</mat-icon>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <form [formGroup]="potentialRiskForm">
            <!-- Client Risk Score -->
            <div class="property-wrapper">
              <div class="property-label col-5">Client Risk Score</div>
              <div class="property-input-wrapper col-11">
                {{
                potentialRiskForm.controls["ClientRiskScore"].value.Name || "-"
                }}
              </div>
            </div>

            <!-- Override Client Risk Score -->
            <div class="property-wrapper">
              <div class="property-label col-5">Override Client Risk Score</div>
              <div class="property-input-wrapper col-11">
                {{
                potentialRiskForm.controls["OverrideClientRiskScore"].value
                  ?.Name || "-"
                }}
              </div>
            </div>

            <!-- Comment -->
            <div class="property-wrapper">
              <div class="property-label col-5">Comment</div>
              <div class="property-input-wrapper col-11">
                {{ potentialRiskForm.controls["Comment"].value || "-" }}
              </div>
            </div>
          </form>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </ng-container>
</ng-template>

<ng-template #noDataBlock>
  <div class="d-flex justify-content-center">
    <no-data-component></no-data-component>
  </div>
</ng-template>
