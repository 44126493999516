import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
    selector: 'app-connected-cccl-inputs',
    templateUrl: './connected-cccl-inputs.component.html',
    styleUrls: ['./connected-cccl-inputs.component.css']
})
export class ConnectedCcclInputsComponent implements OnInit {
    @Input() public pairId: number = 0;
    @Input() public abstractCcclControl: AbstractControl = new FormControl();

    constructor() {}

    ngOnInit(): void {
    }

    get valueA() {
        return this.abstractCcclControl.value;
    }
}
