<div class="dialog-wrapper">
  <div class="header d-flex">Action Required</div>

  <div class="d-flex justify-space-between dialog-content">
    <ng-container

    >
      <ng-container>
        <div style="padding: 3rem 0">
          Please ensure you choose the Effective Date before continuing
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="action-footer">
    <mat-spinner
      [diameter]="35"
      *ngIf="loading"
      style="margin-right: 1rem"
    ></mat-spinner>


    <button
      mat-button
      class="action-base"
      [mat-dialog-close]="false"
    >
      {{ "Close" }}
    </button>
  </div>
</div>
