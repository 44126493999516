import {Component, OnInit} from '@angular/core';
import {Helper} from '../../../../../../../helpers/helper';
import {DocuLibProductsPayload, SelectedDocuLibProductInfo} from './types';
import {DocuLibraryService} from '../../../../../../../services/docu-library.service';
import {MatDialog} from "@angular/material/dialog";
import {DocuLibAuditLogModalComponent} from "../../components/docu-lib-audit-log-modal/audit-log-modal.component";
import {QuestionnaireService} from "../../../../../../../services/questionnaire.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-docu-lib-products',
  templateUrl: './docu-lib-products.component.html',
  styleUrls: ['./docu-lib-products.component.scss'],
})
export class DocuLibProductsComponent implements OnInit {
  public isLoading: boolean = false;
  public selectedProduct: any;
  public isPreviewVisible: boolean = false;
  public displayedColumns: string[] = [
    'productId',
    'productCategory',
    'productType',
    'booking',
    'selling',
    'status',
    'compliance',
    'bucket',
    'date',
    'seeIcon',
  ];
  public sortingColumn: string | null = null;
  public sortingDirection: 'asc' | 'desc' | null = null;
  public dataSource: any[] = [];

  public get clientId(): string {
    return this.helper.getQuestionnaireClientId();
  }

  constructor(
    private helper: Helper,
    private docuLibService: DocuLibraryService,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar
  ) {
  }

  private fetchDocuLibProducts(payload?: DocuLibProductsPayload): void {
    this.docuLibService
      .getDocuLibProducts({
        ClientId: parseInt(this.clientId),
        PageNumber: 1,
        PageSize: 15,
        SortBy: this.sortingColumn
          ? this.getSortingColumn(this.sortingColumn)
          : 'Id',
        SortDirection: this.sortingDirection || 'desc',
        FilterColumns: {},
      })
      .subscribe((res) => {
        this.dataSource = res.Data;
        this.isLoading = false;
      });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.fetchDocuLibProducts();
  }

  public toUpperCase(data: string): string {
    return data.toUpperCase();
  }

  public handleProductInfoOpen(element: SelectedDocuLibProductInfo): void {
    if (!this.selectedProduct || this.selectedProduct.Id !== element.Id) {
      this.selectedProduct = element;
      this.isPreviewVisible = true;
    } else {
      this.selectedProduct = undefined;
      this.isPreviewVisible = false;
    }

    this.helper.setIsDocuLibProductInfoOpened(this.isPreviewVisible);
  }

  public handleSorting(column: string): void {
    if (this.sortingColumn !== column) {
      this.sortingColumn = column;
      this.sortingDirection = 'asc';
      this.fetchDocuLibProducts();
    } else if (
      this.sortingColumn === column &&
      this.sortingDirection === 'asc'
    ) {
      this.sortingDirection = 'desc';
      this.fetchDocuLibProducts();
    } else {
      this.sortingColumn = null;
      this.fetchDocuLibProducts();
    }
  }

  private getSortingColumn(columnName: string): string {
    switch (columnName) {
      case 'Product ID':
        return 'Id';
      case 'Product Category':
        return 'ProductCategory.Name';
      case 'Product Type':
        return 'ProductType.Name';
      case 'Booking':
        return 'Booking.CountryName';
      case 'Selling':
        return 'Selling.CountryName';
      case 'Status':
        return 'Status.Name'

      default:
        return columnName;
    }
  }

  public isSortingActive(sortingColumn: string): boolean {
    return sortingColumn === this.sortingColumn;
  }

  public handleOpenAuditLog(): void {
    const auditLogModalRef = this.dialog.open(DocuLibAuditLogModalComponent, {
      panelClass: 'audit-log-modal',
      width: '50%',
      data: {
        clientId: parseInt(this.clientId),
        isOnDocuLibProducts: true,
      },
    });
  }

  public productInfoChange(data: any): void {
    const payload = { ...this.selectedProduct, ...data };
    this.docuLibService.editDocuLibProductInfo(payload).subscribe((res) => {
      this.matSnackBar.open(
        `Product #${this.selectedProduct.Id} successfully edited!`,
        'X',
        {
          duration: 3000,
          panelClass: ['success-snackbar'],
        }
      );
    });
  }
}
