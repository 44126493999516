import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'docu-library-products-and-accounts',
  templateUrl: './products-and-accounts.component.html',
  styleUrls: ['./products-and-accounts.component.scss'],
})
export class DocuLibraryProductsAndAccounts implements OnInit {
  public dataSource: [] = [];
  public isChildRoute: boolean = false;
  public childRoutes: string[] = [
    'products',
    'accounts',
  ];

  constructor(private route: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.isChildRoute = this.childRoutes.some((x) =>
        this.route.url.includes(x)
      );
    });
  }
}
