<div class="add-new-structure-wrapper d-flex w-100" *ngIf="isPanelOpened"
     [ngStyle]="{ height: isPanelOpened ? '5.5rem' : '0rem', padding: isPanelOpened ? '1rem 2rem' : '0rem 2rem', 'z-index': isPanelOpened ? 1 : -50 }">
    <form [ngStyle]="{ opacity: isPanelOpened ? 1 : 0 }" [formGroup]="addNewStructureForm" class="d-flex w-100"
          (ngSubmit)="onSubmit()">
        <div class="d-flex w-100 justify-between">
            <div class="d-flex">
                <!--    Entity Type    -->
                <div class="control">
                    <label for="entityType">Entity Type</label>
                    <mat-select
                            id="entityType"
                            disableOptionCentering
                            aria-placeholder="Please Select"
                            placeholder="Please Select"
                            class="wiam-select entity-type"
                            panelClass="wiam-select-panel-singl"
                            formControlName="entityType"
                    >
                        <mat-option [value]="'Entity'">
                            <span>Entity</span>
                        </mat-option>
                        <mat-option [value]="'Individual'">
                            <span>Individual</span>
                        </mat-option>
                    </mat-select>
                </div>

                <!--    CID    -->
                <ng-container *ngIf="addNewStructureForm.controls['entityType'].value">
                    <div class="control" >
                        <label for="cid">CID</label>
                        <input type="text" id="cid" formControlName="cid">
                    </div>
                </ng-container>

                <!--    Rendering fields for Individual Entity Type     -->
                <ng-container
                        *ngIf="addNewStructureForm.controls['entityType'].value === 'Individual'; else entityType">
                    <!--    First Name    -->
                    <div class="control">
                        <label for="firstName">First Name</label>
                        <input type="text" id="firstName" formControlName="firstName">
                    </div>

                    <!--    Middle Name    -->
                    <div class="control">
                        <label for="middleName">Middle Name</label>
                        <input type="text" id="middleName" formControlName="middleName">
                    </div>

                    <!--    Last Name    -->
                    <div class="control">
                        <label for="lastName">Last Name</label>
                        <input type="text" id="lastName" formControlName="lastName">
                    </div>
                </ng-container>

                <ng-template #entityType>
                    <ng-container *ngIf="addNewStructureForm.controls['entityType'].value">
                        <!--    Entity Name    -->
                        <div class="control">
                            <label for="entityName" class="entity-name-label">Entity Name</label>
                            <input id="entityName" class="entity-name" formControlName="entityName">
                        </div>
                    </ng-container>
                </ng-template>

                <!--    Search Button    -->
                <div class="control">
                    <label style="color: transparent">search</label>
                    <button
                      type="submit"
                      class="search-btn"
                      [disabled]="!addNewStructureForm.controls['entityType'].value"
                    >
                      Search
                    </button>
                </div>
            </div>

            <div *ngIf="isSearchActive" class="control" style="justify-self: end">
                <label style="color: transparent">add new</label>
                <button (click)="handleAddNew()"  class="search-btn">Add New</button>
            </div>
        </div>
    </form>
</div>
