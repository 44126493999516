import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-tab-block-with-header',
    templateUrl: './tab-block-with-header.component.html',
    styleUrls: ['./tab-block-with-header.component.scss']
})
export class TabBlockWithHeaderComponent implements OnInit {
    @Input() public sectionLabel: string = '';

    constructor() {
    }

    ngOnInit(): void {
    }

}
