<div class="dialog-wrapper">
    <div class="header d-flex">Add new Document</div>

    <div class="d-flex justify-space-between doc-category">
        <div class="d-flex" style="width: 20vw">
            <div class="label">Document Category</div>
        </div>

        <!-- Doc Category -->
        <div class="select-box">
            <mat-form-field floatLabel="never">
                <mat-select placeholder="Select">
                    <mat-option
                        class="new-doc-option"
                        *ngFor="let option of docCategory"
                        (click)="handleDocCategoryChange(option)"
                        [value]="option.Id"
                    >{{ option.Name }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <!-- Doc Req -->
    <div class="d-flex justify-space-between doc-req">
        <div class="d-flex" style="width: 20vw">
            <div class="label">Document Requirement</div>
        </div>
        <div class="select-box">
            <mat-form-field floatLabel="never">
                <mat-select placeholder="Select">
                    <mat-option
                        class="new-doc-option"
                        *ngFor="let option of docRequirement"
                        [value]="option.Id"
                        [disabled]="option.IsDisabled"
                        (click)="handleDocReqChange(option)"
                    >
                        {{ option.Name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <!-- Validity Period -->
    <div class="d-flex justify-space-between doc-req">
        <div class="d-flex" style="width: 20vw">
            <div class="label">Validity Period</div>
        </div>
        <div class="select-box">
            <input
                min="0"
                mat-input
                type="number"
                [(ngModel)]="validityPeriod"
                class="validity-period-input"
            />
        </div>
    </div>

    <div class="action-footer">
        <mat-spinner
            [diameter]="35"
            *ngIf="isAddNewDocLoading"
            style="margin-right: 1rem"
        ></mat-spinner>
        <button mat-button class="action-base" [mat-dialog-close]="true">
            Cancel
        </button>
        <button
            mat-button
            class="action-base submit"
            (click)="handleSubmitNewDocument()"
        >
            Submit
        </button>
    </div>
</div>
