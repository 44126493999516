<div *ngIf="!isLoading" class="login-container">
  <div class="login-form-wrapper">
    <div class="flex-box login-logo-box">
      <img src="../../../assets/icons/login-logo.svg" alt="" />
    </div>

    <form class="form-styled" [formGroup]="loginForm">
      <div class="flex-box flex-column" style="position: relative">
        <div class="styled-label">Username</div>
        <div class="alert-danger" [ngClass]="{ 'show-error': isError }">
          {{ errorMessage }}
        </div>

        <input
          name="username"
          class="styled-login-input"
          placeholder="Username"
          [(ngModel)]="userName"
          formControlName="email"
          (input)="handleInputChange()"
          [ngClass]="{
            'input-error':
              isError ||
              (loginFormControl.email.touched &&
                loginFormControl.email.status === 'INVALID')
          }"
        />
        <div
          class="email-incorrect"
          [ngClass]="{
            'show-error':
              loginFormControl.email.touched &&
              loginFormControl.email.status === 'INVALID'
          }"
        >
          {{
            loginFormControl.email.value === ""
              ? "This field is required"
              : "Incorrect Email Address Format"
          }}
        </div>
      </div>

      <div class="flex-box flex-column pwd-label" style="position: relative">
        <div class="styled-label">Password</div>
        <input
          matInput
          type="password"
          name="Password"
          id="password-input"
          class="styled-login-input"
          placeholder="Password"
          [(ngModel)]="password"
          formControlName="password"
          (input)="handleInputChange()"
          [ngClass]="{
            'input-error':
              isError ||
              (loginFormControl.password.touched &&
                loginFormControl.password.status === 'INVALID')
          }"
        />
        <div
          class="email-incorrect"
          [ngClass]="{
            'show-error':
              loginFormControl.password.touched &&
              loginFormControl.password.status === 'INVALID'
          }"
        >
          {{
            loginFormControl.password.value === ""
              ? "This field is required"
              : "Incorrect Password Format"
          }}
        </div>
        <img
          class="toggle-pass"
          src="../../../assets/icons/eye-icon.svg"
          alt="toggle show password"
          (mousedown)="showPassword()"
          (mouseup)="hidePassword()"
        />
      </div>

      <div
        class="flex-box wrapper"
        [class.login-button-wrapper]="
          userName &&
          password &&
          !isError &&
          !(
            loginFormControl.password.touched &&
            loginFormControl.password.status === 'INVALID'
          ) &&
          !(
            loginFormControl.email.touched &&
            loginFormControl.email.status === 'INVALID'
          )
        "
      >
        <button
          mat-button
          type="submit"
          class="login-btn"
          (click)="login(userName, password)"
          [disabled]="
            !(userName && password) ||
            isError ||
            (loginFormControl.password.touched &&
              loginFormControl.password.status === 'INVALID') ||
            (loginFormControl.email.touched &&
              loginFormControl.email.status === 'INVALID')
          "
        >
          LOGIN
        </button>
      </div>
    </form>

    <div class="extra-nav">
      <div class="d-flex login-nav forgot-btn">
        <a (click)="forgotPassword()">Forgot your password?</a>
      </div>
    </div>
  </div>
</div>

<app-loader-with-logo [isLoading]="isLoading"></app-loader-with-logo>
