import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { Helper } from '../helpers/helper';
import { Observable } from 'rxjs/internal/Observable';
import {
  AnalyticModel,
  AnalyticRequestModel,
  StatisticReturnModel,
} from '../models/analytic';
import { FilterColumn } from '../models/filter_column';

interface DataTableAppliedFilters {
  ColumnName: string;
  Includes: string[];
  Excludes: string[];
  PageSize: number;
  PageNumber: number;
  SortBy: string;
  SortDirection: string;
  Filter: string;
  UserId: string;
}

@Injectable()
export class StatisticService extends BaseService {
  private baseUrl: string = environment.baseUrl;
  public errorMessage!: string;
  constructor(private http: HttpClient, helper: Helper) {
    super(helper);
  }

  public getHeaders() {
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      withCredentials: 'true',
    });
    if (this.helper.isAuthenticated()) {
      headers = headers.set(
        'Authorization',
        'Bearer ' + this.helper.getToken()
      );
    }
    return { headers: headers };
  }

  public getUserStatistic(
    userId: string,
    pageNumber: number,
    pageSize: number,
    sortField: string,
    sortDirection: string,
    filter: string
  ): Observable<StatisticReturnModel> {
    return this.http.get<any>(this.baseUrl + `/case`, {
      params: new HttpParams()
        .set('userId', userId)
        .set('pageNumber', pageNumber)
        .set('pageSize', pageSize)
        .set('sortBy', sortField)
        .set('sortDirection', sortDirection)
        .set('filter', filter),
      headers: super.header().headers,
    });
  }

  public getUserStatisticByColumnFilter(
    request: AnalyticRequestModel
  ): Observable<StatisticReturnModel> {
    return this.http.post<any>(
      this.baseUrl + `/case/getFiltered`,
      request,
      super.header()
    );
  }

  public getDistinctColumnValues(columnName: string): Observable<string[]> {
    return this.http.get<any>(this.baseUrl + `/case/getDistinctColumnValues`, {
      params: new HttpParams().set('columnName', columnName),
      headers: super.header().headers,
    });
  }

  public postStatisticByFilteredColumns(
    request: FilterColumn[]
  ): Observable<StatisticReturnModel> {
    var data = JSON.stringify(request);
    return this.http.post<any>(
      this.baseUrl + `/case/getStatisticByFilteredColumns`,
      data,
      super.header()
    );
  }

  public postExcelFile(formData: FormData, userId: string): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + `/case?userId=` + userId,
      formData,
      this.getHeaders()
    );
  }

  public postSaveExcelFile(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + `/case/new`,
      data,
      this.getHeaders()
    );
  }

  public updateAnalytic(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + `/case/updateAnalytic`,
      data,
      this.getHeaders()
    );
  }

  public downloadReport(): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/case/download`, {
      params: new HttpParams().set('userId', this.helper.getUserId()),
      headers: super.header().headers,
      responseType: 'blob' as 'json',
    });
  }

  public getAuditLogInfo(userId: string, caseId: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/AuditLog`, {
      params: new HttpParams().set('userId', userId).set('caseId', caseId),
      headers: super.header().headers,
    });
  }

  public getDataTableFilteredColumnValues(
    columnName: string,
    appliedFilters: DataTableAppliedFilters[]
  ): Observable<string[]> {
    return this.http.post<any>(
      this.baseUrl + `/Filter/postFilter?columnName=${columnName}`,
      appliedFilters,
      {
        headers: super.header().headers,
      }
    );
  }

  public updateCaseIsInitialReviewCompleted(
    clientId: number,
    caseId: number
  ): Observable<any> {
    return this.http.put<any>(
      this.baseUrl + `/Case/caseProperties`,
      { ClientId: +clientId, CaseId: caseId },
      super.header()
    );
  }
}
