import {Helper} from '../helpers/helper';
import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DataListPayload, NewCasePayload, TableItem} from "../components/docu-library/types";
import {
    AddNewDocuLibAccountPayload
} from "../components/docu-library/components/tabs/products-and-accounts/sub-tabs/docu-lib-accounts/types";
import {
    DocuLibProductsPayload
} from "../components/docu-library/components/tabs/products-and-accounts/sub-tabs/docu-lib-products/types";
import {Products} from "../components/questionnaire/questionnaire-data-table/questionnaire-table.component";
import {
    DropdownOptions,
    SdlDataPayload
} from "../components/docu-library/components/tabs/profile/sub-tabs/regulatory/common/data-tabs/sdl-data/types";
import {
    Df1DataPayload
} from "../components/docu-library/components/tabs/profile/sub-tabs/regulatory/common/data-tabs/df1-data/types";
import {
    Df2DataPayload
} from "../components/docu-library/components/tabs/profile/sub-tabs/regulatory/common/data-tabs/df2-data/types";
import {
    EmirDataPayload
} from "../components/docu-library/components/tabs/profile/sub-tabs/regulatory/common/data-tabs/emir-data/types";
import {
    CcclDataPayload
} from "../components/docu-library/components/tabs/profile/sub-tabs/regulatory/common/data-tabs/cccl-data/types";
import {TaxDataPayload} from "../components/docu-library/components/tabs/profile/sub-tabs/tax/types";
import {
    ContactDataPayload
} from "../components/docu-library/components/tabs/profile/sub-tabs/contact/common/contact-data-table/types";
import {
    GeneralInfoPayload, IndustryTypesAndSectors
} from "../components/docu-library/components/tabs/profile/sub-tabs/general-info/general-info-tab-container/types";

@Injectable()
export class DocuLibraryService extends BaseService {
    private baseUrl: string = environment.baseUrl;
    public errorMessage!: string;

    constructor(private http: HttpClient, helper: Helper) {
        super(helper);
    }

    public getDocuLibraryDataList(payload: DataListPayload): Observable<{
        Data: { Response: TableItem[], Total: number }
    }> {
        return this.http.post<{ Data: { Response: TableItem[], Total: number } }>(this.baseUrl + `/Product/docLibrary`,
            payload,
            super.header()
        );
    }

    public createNewCase(payload: NewCasePayload): Observable<{ Data: string }> {
        return this.http.post<{ Data: string }>(this.baseUrl + `/Case/docuLibraryNewCase`,
            payload,
            super.header()
        );
    }

    public getCreateNewCaseDropdown(): Observable<{Data: { ClientTypes: string[], Countries: string[], Buckets: string[] }}> {
        return this.http.get<{
            Data: { ClientTypes: string[], Countries: string[], Buckets: string[] };
        }>(this.baseUrl + `/Case/createNewClientDropdownData`, {
            params: new HttpParams(),
            headers: super.header().headers,
        });
    }

    public getDocuLibAccounts(payload: { id: number, sortBy: string, sortDirection: 'asc' | 'desc' }): Observable<any> {
        return this.http.get<{
            Message: string;
            Data: any[];
            Success: boolean;
        }>(this.baseUrl + `/Account`, {
            params: new HttpParams()
                .set('id', payload.id)
                .set('sortBy', payload.sortBy)
                .set('sortDirection', payload.sortDirection),
            headers: super.header().headers,
        });
    }

    public addDocuLibAccount(payload: AddNewDocuLibAccountPayload): Observable<any> {
        return this.http.post<{ Data: any[] }>(this.baseUrl + `/Account/account`,
            payload,
            super.header()
        );
    }

    public deleteDocuLibAccount(id: number): Observable<any> {
        return this.http.delete<any>(this.baseUrl +
            `/Account/deleteAccount?accountId=${id}`,
            super.header());
    }

    public deleteDocuLibAccountHistory(id: number): Observable<any> {
        return this.http.delete<any>(this.baseUrl +
            `/Account/accountHistoryDelete?accountId=${id}`,
            super.header(),
        );
    }

    public getDocuLibProducts(payload: DocuLibProductsPayload): Observable<{ Data: any[] }> {
        return this.http.post<{ Data: any[] }>(
            this.baseUrl + `/Product/docLibraryProducts`,
            payload,
            super.header(),
        );
    }

    public getDocuLibProductsAuditLog(clientId: number): Observable<any> {
        return this.http.get<any>(
            this.baseUrl + `/Product/docuLibraryProductHistory?clientId=` + clientId,
            {
                headers: super.header().headers,
            }
        );
    }

    public getDocuLibAccountsAuditLog(clientId: number): Observable<any> {
        return this.http.get<any>(
            this.baseUrl + `/Account/accountHistory?clientId=` + clientId,
            {
                headers: super.header().headers,
            }
        );
    }

    public editDocuLibProductInfo(payload: Products): Observable<{ Data: any[] }> {
        return this.http.post<{ Data: any[] }>(
            this.baseUrl + `/Product/docuLibrarySave`,
            payload,
            super.header(),
        );
    }

    public uploadClientAvatar(payload: { clientId: string, avatar: FormData }): Observable<{ Data: string }> {
        return this.http.post<any>(this.baseUrl + `/Case/profileIcon?clientId=${payload.clientId}`, payload.avatar, {
            // deleted content-type header since by default we have it set to 'application/json'
            headers: super.header().headers.delete('Content-Type'),
        });
    }

    public saveClientAvatar(payload: { clientId: string, avatarUrl: string }): Observable<{ Data: any }> {
        const {clientId, avatarUrl} = payload;
        return this.http.put<{ Data: string }>(
            this.baseUrl + `/Case/profileAvatar?clientId=${clientId}&avatarUrl=${avatarUrl}`,
            {}, {
                headers: super.header().headers,
            },
        );
    }

    // Products
    public fetchRegulatoryDropdownOptions(): Observable<{ Data: DropdownOptions }> {
        return this.http.get<{ Data: DropdownOptions }>(
            this.baseUrl + `/Profile/dropdown`,
            {
                headers: super.header().headers,
            }
        );
    }

    public fetchRegulatorySdlData(clientId: number): Observable<{ Data: SdlDataPayload }> {
        return this.http.get<{ Data: SdlDataPayload }>(
            this.baseUrl + `/Profile/regulatorySDL?clientId=` + clientId,
            {
                headers: super.header().headers,
            }
        );
    }

    public updateRegulatorySdlData(payload: any): Observable<{ Data: any }> {
        return this.http.put<{ Data: any }>(
            this.baseUrl + `/Profile/saveRegulatorySDL`,
            payload, {
                headers: super.header().headers,
            },
        );
    }

    public fetchRegulatoryDf1Data(clientId: number): Observable<{ Data: Df1DataPayload }> {
        return this.http.get<{ Data: Df1DataPayload }>(
            this.baseUrl + `/Profile/regulatoryDFOne?clientId=` + clientId,
            {
                headers: super.header().headers,
            }
        );
    }

    public updateRegulatoryDf1Data(payload: Df1DataPayload): Observable<{ Data: Df1DataPayload }> {
        return this.http.put<{ Data: Df1DataPayload }>(
            this.baseUrl + `/Profile/saveRegulatoryDFOne`,
            payload, {
                headers: super.header().headers,
            },
        );
    }

    public fetchRegulatoryDf2Data(clientId: number): Observable<{ Data: Df2DataPayload }> {
        return this.http.get<{ Data: Df2DataPayload }>(
            this.baseUrl + `/Profile/regulatoryDFTwo?clientId=` + clientId,
            {
                headers: super.header().headers,
            }
        );
    }

    public updateRegulatoryDf2Data(payload: Df2DataPayload): Observable<{ Data: Df2DataPayload }> {
        return this.http.put<{ Data: Df2DataPayload }>(
            this.baseUrl + `/Profile/saveRegulatoryDFTwo`,
            payload, {
                headers: super.header().headers,
            },
        );
    }

    public fetchRegulatoryEmirData(clientId: number): Observable<{ Data: EmirDataPayload }> {
        return this.http.get<{ Data: EmirDataPayload }>(
            this.baseUrl + `/Profile/regulatoryEMIR?clientId=` + clientId,
            {
                headers: super.header().headers,
            }
        );
    }

    public updateRegulatoryEmirData(payload: EmirDataPayload): Observable<{ Data: EmirDataPayload }> {
        return this.http.put<{ Data: EmirDataPayload }>(
            this.baseUrl + `/Profile/saveRegulatoryEMIR`,
            payload, {
                headers: super.header().headers,
            },
        );
    }

    public fetchRegulatoryCcclData(clientId: number): Observable<{ Data: CcclDataPayload }> {
        return this.http.get<{ Data: CcclDataPayload }>(
            this.baseUrl + `/Profile/regulatoryCCCL?clientId=` + clientId,
            {
                headers: super.header().headers,
            }
        );
    }

    public updateRegulatoryCcclData(payload: CcclDataPayload): Observable<{ Data: CcclDataPayload }> {
        return this.http.put<{ Data: CcclDataPayload }>(
            this.baseUrl + `/Profile/saveRegulatoryCCCL`,
            payload, {
                headers: super.header().headers,
            },
        );
    }

    public fetchTaxData(clientId: number): Observable<{ Data: TaxDataPayload }> {
        return this.http.get<{ Data: TaxDataPayload }>(
            this.baseUrl + `/Profile/tax?clientId=` + clientId,
            {
                headers: super.header().headers,
            }
        );
    }

    public updateTaxData(payload: TaxDataPayload): Observable<{ Data: TaxDataPayload }> {
        return this.http.put<{ Data: TaxDataPayload }>(
            this.baseUrl + `/Profile/saveTax`,
            payload, {
                headers: super.header().headers,
            },
        );
    }

    public fetchContactData(clientId: number, pageNumber: number, PageSize: number): Observable<{
        Data: { Contacts: ContactDataPayload[], Total: number }
    }> {
        return this.http.post<{ Data: { Contacts: ContactDataPayload[], Total: number } }>(
            this.baseUrl + `/Profile/contact`, {
                ClientId: clientId,
                PageNumber: pageNumber,
                PageSize: PageSize,
            },
            {
                headers: super.header().headers,
            }
        );
    }

    public saveContactData(payload: ContactDataPayload): Observable<{ Data: ContactDataPayload }> {
        return this.http.put<{ Data: ContactDataPayload }>(
            this.baseUrl + `/Profile/saveContact`,
            payload, {
                headers: super.header().headers,
            },
        );
    }

    public deleteContactData(clientId: number, contactId: number): Observable<{ Data: boolean }> {
        return this.http.delete<{ Data: boolean }>(
            this.baseUrl + `/Profile/deleteContact?clientId=${clientId}&contactId=${contactId}`,
            super.header(),
        );
    }

    public fetchGeneralInfoData(clientId: number): Observable<{ Data: GeneralInfoPayload }> {
        return this.http.get<{ Data: GeneralInfoPayload }>(
            this.baseUrl + `/Profile/generalInformation?clientId=` + clientId,
            {
                headers: super.header().headers,
            }
        );
    }

    public updateGeneralInfoData(payload: any): Observable<{ Data: any }> {
        return this.http.put<{ Data: any }>(
            this.baseUrl + `/Profile/saveGeneralInformation`,
            payload, {
                headers: super.header().headers,
            },
        );
    }

    public fetchIndustryTypesAndSectors(industry: string): Observable<{ Data: IndustryTypesAndSectors[] }> {
        return this.http.get<{ Data: IndustryTypesAndSectors[] }>(
            this.baseUrl + `/Profile/industryTypes?industry=${industry}`,
            {
                headers: super.header().headers,
            }
        );
    }
}
