import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Product} from "../../questionnaire-container/approve-validation-popup/types";

@Component({
  selector: 'app-effective-date-validation',
  templateUrl: './effective-date-validation.component.html',
  styleUrls: ['./effective-date-validation.component.scss']
})
export class EffectiveDateValidationComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      displayData: Product[];
      approvalConditionPassed: boolean;
    }
  ) {
  }

  ngOnInit(): void {
  }

  public loading: boolean = false;
}
