import { Helper } from 'src/app/helpers/helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentCategory, DocumentRequirement } from './types';
import { Component, OnInit } from '@angular/core';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'add-new-document-dialog',
  templateUrl: 'add-new-document.component.html',
  styleUrls: ['add-new-document.component.scss'],
})
export class AddNewDocument implements OnInit {
  public docCategory: DocumentCategory[] = [];
  public docRequirement: DocumentRequirement[] = [];
  public validityPeriod: number = 0;
  public selectedDocumentCategory: DocumentCategory | undefined;
  public selectedDocumentRequirement: DocumentRequirement | undefined;
  public isAddNewDocLoading: boolean = false;

  constructor(
    private helper: Helper,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AddNewDocument>,
    private questionnaireService: QuestionnaireService
  ) {}

  public get clientId() {
    return this.helper.getQuestionnaireClientId();
  }

  public get caseId() {
    return this.helper.getQuestionnaireCaseId();
  }

  ngOnInit(): void {
    this.questionnaireService.getDocumentCategory().subscribe((res) => {
      this.docCategory = res.Data;
    });
  }

  handleDocCategoryChange(option: DocumentCategory) {
    this.selectedDocumentCategory = option;
    this.questionnaireService
      .getDocumentRequirementDropdown(this.clientId, option.Id)
      .subscribe((res) => {
        this.docRequirement = res.Data;
      });
  }

  handleDocReqChange(option: DocumentRequirement) {
    this.selectedDocumentRequirement = option;
    this.validityPeriod = option.ValidityPeriod;
  }

  handleSubmitNewDocument() {
    this.isAddNewDocLoading = true;

    this.questionnaireService
      .submitNewDocument({
        CaseId: this.caseId!,
        RequirementToSave: [
          {
            ClientId: +this.clientId,
            DocumentCategory: this.selectedDocumentCategory!.Name,
            DocumentRequirement: this.selectedDocumentRequirement!.Name,
            Action: null,
            Validity: null,
            ValidityPeriod: this.validityPeriod,
            UploadedOn: null,
            EffectiveDate: null,
            ValidUntil: null,
            DocumentUrl: null,
            Comment: null,
          },
        ],
      })
      .subscribe((res) => {
        this.isAddNewDocLoading = false;
        this.dialogRef.close();

        this.snackBar.open('New document added!', 'X', {
          duration: 3000,
          panelClass: ['success-snackbar'],
        });
      });
  }
}
