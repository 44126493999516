import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-regulatory-sub-block-with-header',
    templateUrl: './regulatory-sub-block-with-header.component.html',
    styleUrls: ['./regulatory-sub-block-with-header.component.css']
})
export class RegulatorySubBlockWithHeaderComponent implements OnInit {
    @Input() public subBlockHeader: string = '';

    constructor() {
    }

    ngOnInit(): void {
    }
}
