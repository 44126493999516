import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {UserModel} from '../models/user';
import {MessageModel} from '../models/websocket';
import {CaseStage} from '../components/questionnaire/questionnaire-container/types';
import {AppTypes} from '../layout/types';
import {TableItem} from "../components/docu-library/types";
import {StructuresDataList} from "../components/structures/structures-container/structures-container.component";
import {FormGroup} from "@angular/forms";

interface DataTableAppliedFilters {
    ColumnName: string;
    Includes: string[];
    Excludes: string[];
    PageSize: number;
    PageNumber: number;
    SortBy: string;
    SortDirection: string;
    Filter: string;
    UserId: string;
}

interface StructureItem {
    ChildStructure: StructureItem[],
    EntityType: string,
    ClientName: string,
    ClientPercentage: string,
    ClientType: string,
    Id: number,
    IsVisible: boolean,
    ParentId: number,
}

interface NewStructure {
    ClientAssciation: string | null,
    ClientId: number | null,
    Country: string | null,
    EntityName: string | null,
    FirstName: string | null,
    LastName: string | null,
    MiddleName: string | null,
    State: string | null,
    IsVisible: boolean,
    ParentId: number,
}

interface DocuLibSearchPayload {
    id: '',
    accountNumber: '',
    legalName: '',
    lei: '',
    clientOnly: false,
}

@Injectable()
export class Helper {
    private authenticationChanged = new Subject<boolean>();
    private searchFilterChanges = new Subject<string>();
    private leftMenuChanged = new Subject<boolean>();
    private wsMessageRecieved = new Subject<any>();
    private nudgeRecieved = new Subject<any>();
    private newNotificationReceived = new Subject<any>();
    private profileImgUpdated = new Subject<any>();
    private profileImgLoading = new Subject<any>();
    private userAccessFiltersApplied = new Subject<
        { Name: string; Includes: string[] }[]
    >();
    private dataTableFiltersApplied = new BehaviorSubject<
        DataTableAppliedFilters[]
    >([]);
    private syncUserAccessFilters = new Subject<string>();
    private newEntryAdded = new Subject<boolean>();
    private questionnaireTabSwitchSubject = new Subject<number>();
    private policyUpdateSubject = new Subject();
    private questionnaireMultiselectResetSubject = new Subject();
    private questionnaireHeaderSubject = new Subject<boolean>();
    private isDocumentRequirementPreviewOpened = new Subject<boolean>();
    private archiveDocUrlSubject = new Subject<string>();
    private refreshDocRequirementsSubject = new Subject();
    private currentCaseStageSubject = new Subject<CaseStage>();
    private docuLibIdSubject = new Subject<string>();
    private docuLibSelectedCaseSubject = new Subject<any>();
    private docuLibraryNavSubject = new BehaviorSubject<boolean>(false);

    private docuLibProductInfoSubject = new BehaviorSubject<boolean>(false);

    private docuLibSearchDataSubject = new Subject<DocuLibSearchPayload>();

    private addNewStructureSubject = new Subject();

    private selectedStructureItemSubject = new BehaviorSubject<StructuresDataList | null>(null);

    private openAddNewStructurePanelSubject = new Subject<boolean>();

    private openStructureSearchResultsSubject = new Subject<{ isOpen: boolean, formData: FormGroup | null }>();

    private structureToBeCreatedSubject = new Subject<NewStructure>();

    private selectedStructureToBeCreated = new BehaviorSubject<{
        ParentId: number,
        ClientId: number,
        ClientName: string | null,
        FirstName: string | null,
        LastName: string | null,
        MiddleName: string | null,
        EntityType: string,
        IsVisible: boolean | null,
        RestoreParentId: number
    } | null>(null);

    private selectedParentStructureIdSubject = new Subject<number>();
    private selectedStructureIdSubject = new Subject<number>();

    private rightTabStateSubject = new Subject<boolean>();
    private newlyCreatedStructureSubject = new Subject<StructureItem>();
    private SearchResultsRightTabSubject = new BehaviorSubject<number | null>(null);


    constructor() {
    }

    public logout(): void {
        this.setStorageIsAuthValue(undefined);
        this.removeDetailsFromStorage();
        this.authenticationChanged.next(false);
    }

    public isAuthenticationChanged(): any {
        return this.authenticationChanged.asObservable();
    }

    public isSearchFilterChanged(): any {
        return this.searchFilterChanges.asObservable();
    }

    public isWsMessageRecieved(): any {
        return this.wsMessageRecieved.asObservable();
    }

    public isNudgeRecieved(): any {
        return this.nudgeRecieved.asObservable();
    }

    public isNewNotificationReceived(): any {
        return this.newNotificationReceived.asObservable();
    }

    public callWsMessageRecieved(data: MessageModel): void {
        this.wsMessageRecieved.next(data);
    }

    public callNudgeRecieved(data: any): void {
        this.nudgeRecieved.next(data);
    }

    public callNewNotificationRecieved(data: any): void {
        this.newNotificationReceived.next(data);
    }

    public setSearchFilter(value: string): void {
        if (this.getSearchFilter() != value) {
            this.searchFilterChanges.next(value);
            this.setSearchFilterStorage(value);
        }
    }

    public isLeftMenuChanged(): any {
        return this.leftMenuChanged.asObservable();
    }

    public callLeftMenuChanged(): void {
        this.leftMenuChanged.next(true);
    }

    public getSearchFilter(): string {
        return this.getSearchFilterStorage();
    }

    public failToken(): void {
        this.setStorageToken(undefined);
    }

    public setIsAuth(data: any): void {
        this.setStorageIsAuthValue(JSON.stringify(data));
        this.authenticationChanged.next(data);
    }

    public setUserStatus(status: string): void {
        window.localStorage['currentStatus'] = status;
    }

    // GET/SET Client Id
    public setQuestionnaireClientId(id: string): void {
        window.localStorage['clientId'] = id;
    }

    public getQuestionnaireClientId() {
        return window.localStorage['clientId'];
    }

    // GET/SET Case Id
    public setQuestionnaireCaseId(id: string): void {
        window.localStorage['caseId'] = id;
    }

    public getQuestionnaireCaseId() {
        const caseId = window.localStorage['caseId'];
        return caseId ? parseInt(caseId) : null;
    }

    // GET/SET Client Name
    public setQuestionnaireClientName(name: string): void {
        window.localStorage['clientName'] = name;
    }

    public getQuestionnaireClientName() {
        return window.localStorage['clientName'];
    }

    // GET/SET Bucket
    public setQuestionnaireBucket(bucket: string): void {
        window.localStorage['bucket'] = bucket;
    }

    public getQuestionnaireBucket() {
        return window.localStorage['bucket'];
    }

    public isAuthenticated(): boolean {
        return (
            window.localStorage['isAuth'] === true ||
            window.localStorage['isAuth'] === 'true'
        );
    }

    public setRedirectUrl(url: string): void {
        this.setUrlForRedirection(url);
    }

    public setUser(user: any): void {
        this.setUserStorage(JSON.stringify(user));
    }

    public setToken(data: any): void {
        this.setStorageToken(JSON.stringify(data));
    }

    public getUser(): UserModel {
        if (window.localStorage['user'] != null) {
            return JSON.parse(window.localStorage['user']);
        }
        return new UserModel();
    }

    public getUserStatus(): string {
        return window.localStorage['currentStatus'];
    }

    public getUserId(): string {
        return this.getUser().Id;
    }

    public getRedirectUrl(): string {
        return this.getAndDeleteRedirectUrl();
    }

    public getToken(): any {
        if (
            window.localStorage['token'] === undefined ||
            window.localStorage['token'] === null ||
            window.localStorage['token'] === 'null' ||
            window.localStorage['token'] === 'undefined' ||
            window.localStorage['token'] === ''
        ) {
            return '';
        }
        let obj = JSON.parse(window.localStorage['token']);
        return obj.Token;
    }

    public setPubSubUrl(url: string): void {
        this.setPubSubUrlStorage(url);
    }

    public getPubSubUrl(): string {
        return this.getPubSubUrlStorage();
    }

    public isDataFirstUpload(): any {
        return window.localStorage['isDataFirstUpload'] === 'true';
    }

    public setIsDataFirstUpload(value: boolean): void {
        window.localStorage['isDataFirstUpload'] = value;
    }

    public removeElementFromArray(array: any[], elemntToRemove: any): any[] {
        const index = array.indexOf(elemntToRemove);
        if (index > -1) {
            // only splice array when item is found
            array.splice(index, 1); // 2nd parameter means remove one item only
        }
        return array;
    }

    private setStorageToken(value: any): void {
        window.localStorage['token'] = value;
    }

    private setStorageIsAuthValue(value: any): void {
        window.localStorage['isAuth'] = value;
    }

    private setUrlForRedirection(value: string): void {
        window.localStorage['redirectUrl'] = value;
    }

    private getAndDeleteRedirectUrl(): string {
        var redirectUrl = window.localStorage['redirectUrl'];
        if (redirectUrl != undefined) {
            window.localStorage.removeItem('redirectUrl');
        }

        return redirectUrl;
    }

    private setUserStorage(user: any) {
        window.localStorage['user'] = user;
    }

    private removeDetailsFromStorage() {
        window.localStorage.removeItem('isAuth');
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
    }

    private getSearchFilterStorage(): string {
        return window.localStorage['searchFilter'];
    }

    private setSearchFilterStorage(value: string): void {
        window.localStorage['searchFilter'] = value;
    }

    private setPubSubUrlStorage(url: string): void {
        window.localStorage['pubSubUrl'] = url;
    }

    private getPubSubUrlStorage(): string {
        return window.localStorage['pubSubUrl'];
    }

    public updateProfileImg(params: { imgUrl: string }): any {
        return this.profileImgUpdated.next(params);
    }

    public getUpdatedProfileImg(): Observable<{
        imgUrl: string;
    }> {
        return this.profileImgUpdated.asObservable();
    }

    public updateImgLoadingStatus(isLoading: boolean): any {
        return this.profileImgLoading.next(isLoading);
    }

    public getUpdatedImgLoadingStatus(): Observable<boolean> {
        return this.profileImgLoading.asObservable();
    }

    // user access filters sync
    public isUserAccessFiltersChanged(): any {
        return this.userAccessFiltersApplied.asObservable();
    }

    public callUserAccessFiltersChanged(
        appliedFilters: { Name: string; Includes: string[] }[]
    ): void {
        this.userAccessFiltersApplied.next(appliedFilters);
    }

    // data table filters sync
    public isDataTableFiltersChanged() {
        return this.dataTableFiltersApplied.asObservable();
    }

    public callDataTableFiltersChanged(
        appliedFilters: DataTableAppliedFilters[]
    ): void {
        this.dataTableFiltersApplied.next(appliedFilters);
    }

    public shouldUserAccessFilterBeSynced() {
        return this.syncUserAccessFilters.asObservable();
    }

    public triggerSyncOfUserAccessFilters(filterPanelOpened: string) {
        return this.syncUserAccessFilters.next(filterPanelOpened);
    }

    // Handling data source update once new entry added
    public isNewEntryAdded() {
        return this.newEntryAdded.asObservable();
    }

    public newEntryAddedSuccessfully(usNewEntryAdded: boolean) {
        return this.newEntryAdded.next(usNewEntryAdded);
    }

    public questionnaireTabSwitched() {
        return this.questionnaireTabSwitchSubject.asObservable();
    }

    public switchQuestionnaireTab(tabIndex: number) {
        return this.questionnaireTabSwitchSubject.next(tabIndex);
    }

    public onPoliciesChange() {
        return this.policyUpdateSubject.asObservable();
    }

    public refreshPolicy() {
        return this.policyUpdateSubject.next();
    }

    public onQuestionnaireMultiselectChange() {
        return this.questionnaireMultiselectResetSubject.asObservable();
    }

    public resetQuestionnaireMultiselectValue() {
        return this.questionnaireMultiselectResetSubject.next();
    }

    public onQuestionnaireHeaderChange() {
        return this.questionnaireHeaderSubject.asObservable();
    }

    public updateQuestionnaireHeader(param: boolean) {
        return this.questionnaireHeaderSubject.next(param);
    }

    public onPreviewOpenedChange() {
        return this.isDocumentRequirementPreviewOpened.asObservable();
    }

    public updatePreviewOpened(param: boolean = false) {
        return this.isDocumentRequirementPreviewOpened.next(param);
    }

    public onArchiveDocUrlChange() {
        return this.archiveDocUrlSubject.asObservable();
    }

    public updateArchiveDocUrl(param: string) {
        return this.archiveDocUrlSubject.next(param);
    }

    public onShouldDocRequirementsUpdate() {
        return this.refreshDocRequirementsSubject.asObservable();
    }

    public updateDocRequirements() {
        return this.refreshDocRequirementsSubject.next();
    }

    public getQuestionnaireCaseStage() {
        return window.localStorage.getItem('questionnaireCaseStage');
    }

    public updateQuestionnaireCaseStage(param: string) {
        return window.localStorage.setItem('questionnaireCaseStage', param);
    }

    public setUserRole(role: string) {
        window.localStorage['userRole'] = role;
    }

    public getUserRole() {
        return window.localStorage.getItem('userRole');
    }

    public setAppType(appType: AppTypes): void {
        window.localStorage['AppType'] = appType;
    }

    public getAppType(): AppTypes {
        return window.localStorage['AppType'];
    }

    public setDocuLibChildRouteActive(isActive: boolean): void {
        return this.docuLibraryNavSubject.next(isActive);
    }

    public onDocuLibraryNavInfoChange() {
        return this.docuLibraryNavSubject.asObservable();
    }

    public setSelectedDocuLibId(id: string): void {
        return this.docuLibIdSubject.next(id);
    }

    public onDocuLibIdChange() {
        return this.docuLibIdSubject.asObservable();
    }

    public setSelectedDocuLibCase(selectedCase: TableItem): void {
        return this.docuLibSelectedCaseSubject.next(selectedCase);
    }

    public onSelectedDocuLibCaseChange() {
        return this.docuLibSelectedCaseSubject.asObservable();
    }

    public setIsDocuLibProductInfoOpened(isOpened: boolean): void {
        return this.docuLibProductInfoSubject.next(isOpened);
    }

    public onDocuLibProductInfoChange() {
        return this.docuLibProductInfoSubject.asObservable();
    }

    public triggerDocuLibSearchSubmit(formData: DocuLibSearchPayload): void {
        return this.docuLibSearchDataSubject.next(formData);
    }

    public onDocuLibSearchSubmit() {
        return this.docuLibSearchDataSubject.asObservable();
    }

    public setDocuLibSelectedAccountId(id: number) {
        window.localStorage.setItem('docuLibSelectedAccId', id.toString());
    }

    public getDocuLibSelectedAccountId() {
        return window.localStorage.getItem('docuLibSelectedAccId');
    }

    public onNewStructureCreate() {
        return this.addNewStructureSubject.asObservable();
    }

    public refreshStructuresData() {
        return this.addNewStructureSubject.next();
    }

    public onNewStructureSelected() {
        return this.selectedStructureItemSubject.asObservable();
    }

    public selectNewStructureItem(structure: StructuresDataList | null) {
        return this.selectedStructureItemSubject.next(structure);
    }

    public onAddNewStructurePanelChange() {
        return this.openAddNewStructurePanelSubject.asObservable();
    }

    public updateAddNewStructurePanel(isOpen: boolean) {
        return this.openAddNewStructurePanelSubject.next(isOpen);
    }

    public onSearchResultsPanelChange() {
        return this.openStructureSearchResultsSubject.asObservable();
    }

    public updateSearchResultsPanel(isOpen: boolean, formData: FormGroup | null) {
        return this.openStructureSearchResultsSubject.next({isOpen, formData});
    }

    public onNewStructureItemCreated() {
        return this.structureToBeCreatedSubject.asObservable();
    }

    public passNewStructureItem(item: NewStructure) {
        this.structureToBeCreatedSubject.next(item);
    }

    public onAddNewStructureClick() {
        return this.selectedStructureToBeCreated.asObservable();
    }

    public passNewStructureForCreation(item: {
        ParentId: number,
        ClientId: number,
        ClientName: string | null,
        FirstName: string | null,
        LastName: string | null,
        MiddleName: string | null,
        EntityType: string,
        IsVisible: boolean | null,
        RestoreParentId: number,
    } | null) {
        this.selectedStructureToBeCreated.next(item);
    }

    public onSelectedParentIdChange() {
        return this.selectedParentStructureIdSubject.asObservable();
    }

    public setNewSelectedParentId(id: number) {
        this.selectedParentStructureIdSubject.next(id);
    }

    public onSelectedStructureIdChange() {
        return this.selectedStructureIdSubject.asObservable();
    }

    public setNewSelectedStructureId(id: number) {
        this.selectedParentStructureIdSubject.next(id);
    }


    public onRightTabStateChange() {
        return this.rightTabStateSubject.asObservable();
    }

    public changeRightTabState(isOpened: boolean) {
        this.rightTabStateSubject.next(isOpened);
    }

    public onNewItemGenerated() {
        return this.newlyCreatedStructureSubject.asObservable();
    }

    public passNewItemId(id: StructureItem) {
        this.newlyCreatedStructureSubject.next(id);
    }

    public onSearchSectionRightTabOpen() {
        return this.SearchResultsRightTabSubject.asObservable();
    }

    public openRightTabForSelectedRow(id: number|null) {
        this.SearchResultsRightTabSubject.next(id);
    }
}
