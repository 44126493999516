<div class="d-flex w-100 tab-container">
    <mat-tab-group class="w-100">
        <mat-tab label="Structure">
            <!-- Add new Structure Item Header -->
            <app-add-new-structure-header #target></app-add-new-structure-header>

            <!-- Structure Search Results -->
            <app-structure-search-results></app-structure-search-results>

            <!-- Zoom Box -->
            <div class="zoom-box d-flex align-center">
                <div class="d-flex zoom-dropdown" (click)="handleToggleZoomDropdown()" >
                    <div class="zoom-indicator">
                        {{ this.selectedZoomPercentage }}%
                    </div>
                    <img src="../assets/icons/zoom-icon.svg" alt="" style="margin-left: 0.5rem">
                </div>

                <mat-spinner *ngIf="isAddNewStructureLoading" [style.margin-left]="'1rem'" [diameter]="30"></mat-spinner>

                <div class="drop-down-body" *ngIf="zoomDropdownOpened">
                    <div class="dropdown-option" (click)="handleSelectZoomOption(100)" [class.selected]="selectedZoomPercentage === 100">
                        <img *ngIf="selectedZoomPercentage === 100" src="../assets/icons/product-filter-active-indicator.svg" alt="">
                        <div class="option-label">
                            100%
                        </div>
                    </div>
                    <div class="dropdown-option" (click)="handleSelectZoomOption(75)" [class.selected]="selectedZoomPercentage === 75">
                        <img *ngIf="selectedZoomPercentage === 75" src="../assets/icons/product-filter-active-indicator.svg" alt="">
                        <div class="option-label">
                            75%
                        </div>
                    </div>
                    <div class="dropdown-option" (click)="handleSelectZoomOption(50)" [class.selected]="selectedZoomPercentage === 50">
                        <img *ngIf="selectedZoomPercentage === 50" src="../assets/icons/product-filter-active-indicator.svg" alt="">
                        <div class="option-label">
                            50%
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex w-100 structure-tree-box"
               >
                <div class="d-flex flex-grow-1 overflow-auto h-100 bg-colored">
                    <!-- Content for the "Structure" tab goes here -->
                    <div class="d-flex w-100 h-100 justify-content-start structure-container">
                        <div class="structure-item" cdkDrag>
                            <!-- Custom Node Recursive Rendering -->
                            <app-custom-node
                                class="d-flex"
                                [parentStructure]="structure"
                                [class.zoom-50]="selectedZoomPercentage === 50"
                                [class.zoom-75]="selectedZoomPercentage === 75"
                                [class.zoom-100]="selectedZoomPercentage === 100">
                            </app-custom-node>
                        </div>
                    </div>
                </div>
                <div class="d-flex w-0 right-tab-container overflow-auto" [class.w-20]="rightTabOpened">
                    <app-right-tab (reFetchStructureData)="fetchStructuresData()" class="w-100"
                                   *ngIf="rightTabOpened"></app-right-tab>
                </div>
            </div>
        </mat-tab>


        <mat-tab label="Name Screening">
            <!-- Content for the "Name Screening" tab goes here -->
        </mat-tab>
        <mat-tab label="Risk Score">
            <!-- Content for the "Risk Score" tab goes here -->
           <!-- <docu-library-risks [isOnStructures]="true"></docu-library-risks>-->
            <risk-score-tab-container [isOnStructures]="true"></risk-score-tab-container>
        </mat-tab>
    </mat-tab-group>
</div>

