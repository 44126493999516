import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'docu-library-regulatory-sub-tab',
    templateUrl: './regulatory.component.html',
    styleUrls: ['./regulatory.component.scss'],
})
export class DocuLibraryRegulatorySubTab implements OnInit {
    public dataSource: [] = [];
    public isLoading: boolean = true;

    ngOnInit() {
        setTimeout(() => {
            this.isLoading = false;
        }, 1500)
    }
}
