<div *ngIf="!!title" class="header">
  <div mat-dialog-title
    class="dialog-heading">
    {{ title }}
  </div>
</div>

<div mat-dialog-content class="dialog-content">
  <p>
    {{ message }}
  </p>
  <div [innerHTML]="additionalHTML">
  </div>
</div>

<div class="dialog-actions">
  <button
    mat-flat-button
    mat-dialog-close
    class="close-button"
  >
    Close
  </button>
</div>
