<div class="d-flex chart-wrapper" [ngClass]="{ backgroundF: isAllChartValuesZero }">
  <div *ngIf="!isAllChartValuesZero" class="pie-wrapper-one">
    <div class="pie-wrapper-two">
      <div class="pie-chart-wrapper">
        <canvas [id]="chartId" #lineChartCanvas style="min-height: 200px; max-height: 500px"
          [ngClass]="{ width1: true }">
          {{ chart }}
        </canvas>
      </div>
    </div>
  </div>
  <div class="no-data-box" *ngIf="isAllChartValuesZero" style="min-height: 250px; max-height: 500px"
    [ngClass]="{ width1: true }">
    <div class="img-container">
      <img src="./../../../../../assets/empty.png" alt="" />
    </div>
    <div class="descr-container">
      <h6 class="label">Pie chart values are empty</h6>
    </div>
  </div>
  <data-progress-bars [selectedBucket]="selectedBucket"></data-progress-bars>
</div>