<div class="change-password-wrapper">
    <div class="logo-wrapper">
        <img src="../../../assets/icons/login-logo.svg"/>
    </div>
    <div class="form-wrapper">
        <form class="change-pass-form" [formGroup]="changePassForm" (ngSubmit)="submitChangePassword()">
            <div class="header">
                <h2>Welcome</h2>
                <span>Please select a new password</span>
            </div>
            <div class="inputs-wrapper">
              <div *ngIf="!isSetPassword" class="input-wrapper"
                [ngClass]="{'error-input-wrapper': changePassForm.controls['oldPassword'].hasError('required')}">
                <label class="input-label">Old Password</label>
                <input type="password" placeholder="Old Password" (input)="handleChangeOldPassword()" [formControl]="$any(changePassForm).controls['oldPassword']">
                <mat-error *ngIf="changePassForm.controls['oldPassword'].hasError('required')">
                  Old password is required.
                </mat-error>
              </div>
              <div class="input-wrapper"
                [ngClass]="{'error-input-wrapper': changePassForm.controls['newPassword'].hasError('required')}">
                <label class="input-label">New Password</label>
                <input type="password" placeholder="New Password" id="new-pass" (input)="handleChangeNewPassword()" (click)="handleClick('newPassword')" [formControl]="$any(changePassForm).controls['newPassword']">
                <mat-error *ngIf="changePassForm.controls['newPassword'].hasError('required')">
                  New password required.
                </mat-error>
                <mat-icon
                  class="toggle-pass"
                  (mousedown)="showPassword('new-pass')"
                  (mouseup)="hidePassword('new-pass')">
                  remove_red_eye
                </mat-icon>
              </div>
              <div class="input-wrapper"
                [ngClass]="{'error-input-wrapper': changePassForm.controls['confirmPassword'].hasError('required') || changePassForm.controls['confirmPassword'].hasError('isEqualToNew')}">
                <label class="input-label">Confirm Password</label>
                <input type="password" placeholder="Confirm Password" id="confirm-pass" (input)="handleChangeConfirmPassword()" (click)="handleClick('confirmPassword')" [formControl]="$any(changePassForm).controls['confirmPassword']">
                <mat-error *ngIf="changePassForm.controls['confirmPassword'].hasError('required')">
                  Confirm password required.
                </mat-error>
                <mat-error *ngIf="changePassForm.controls['confirmPassword'].hasError('isEqualToNew')">
                  Confirmed Password doesn't match New Password.
                </mat-error>
                <mat-icon
                  class="toggle-pass"
                  (mousedown)="showPassword('confirm-pass')"
                  (mouseup)="hidePassword('confirm-pass')">
                  remove_red_eye
                </mat-icon>
              </div>
            </div>
            <div class="requirements-wrapper">
              <h3>Password requirements:</h3>
              <div>
                <span [ngClass]="{
                  'invalid-span': activeControl && activeControl.hasError('minlength'),
                  'valid-span': activeControl && !activeControl.hasError('minlength')
                }">
                  Must contain at least 6 characters
                  <span class="m-0" *ngIf="activeControl && activeControl.hasError('minlength')">*</span>
                  <span class="m-0" *ngIf="activeControl && !activeControl.hasError('minlength')"><mat-icon>check</mat-icon></span>
                </span>
              </div>
              <div>
                <span [ngClass]="{
                  'invalid-span': activeControl && activeControl.hasError('uppercase'),
                  'valid-span': activeControl && !activeControl.hasError('uppercase')
                }">
                  Must contain at least one uppercase letter
                  <span class="m-0" *ngIf="activeControl && activeControl.hasError('uppercase')">*</span>
                  <span class="m-0" *ngIf="activeControl && !activeControl.hasError('uppercase')"><mat-icon>check</mat-icon></span>
                </span>
              </div>
              <div>
                <span [ngClass]="{
                  'invalid-span': activeControl && activeControl.hasError('lowercase'),
                  'valid-span': activeControl && !activeControl.hasError('lowercase')
                }">
                  Must contain at least one lowercase letter
                  <span class="m-0" *ngIf="activeControl && activeControl.hasError('lowercase')">*</span>
                  <span class="m-0" *ngIf="activeControl && !activeControl.hasError('lowercase')"><mat-icon>check</mat-icon></span>
                </span>
              </div>
              <div>
                <span [ngClass]="{
                  'invalid-span': activeControl && activeControl.hasError('containNumber'),
                  'valid-span': activeControl && !activeControl.hasError('containNumber')
                }">
                  Must contain at least one number
                  <span class="m-0" *ngIf="activeControl && activeControl.hasError('containNumber')">*</span>
                  <span class="m-0" *ngIf="activeControl && !activeControl.hasError('containNumber')"><mat-icon>check</mat-icon></span>
                </span>
              </div>
              <div>
                <span [ngClass]="{
                  'invalid-span': activeControl && activeControl.hasError('specialCharacter'),
                  'valid-span': activeControl && !activeControl.hasError('specialCharacter')
                }">
                  Must contain at least one special character
                  <span class="m-0" *ngIf="activeControl && activeControl.hasError('specialCharacter')">*</span>
                  <span class="m-0" *ngIf="activeControl && !activeControl.hasError('specialCharacter')"><mat-icon>check</mat-icon></span>
                </span>
              </div>
            </div>
            <div class="form-footer">
              <button class="cancel-btn" type="button" (click)="handleCancelBtn()" mat-raised-button>cancel</button>
              <button class="submit-btn" type="submit" 
              [disabled]="isSaving || (!isSetPassword && !changePassForm.controls['oldPassword'].value) 
                || !changePassForm.controls['newPassword'].value 
                || !changePassForm.controls['confirmPassword'].value 
                || (changePassForm.controls['newPassword'].status === 'INVALID' || changePassForm.controls['confirmPassword'].status === 'INVALID')" 
              mat-raised-button>
                <span *ngIf="!isSaving">Submit</span>
                <span class="spinner" *ngIf="isSaving"><mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner></span>
              </button>
            </div>
        </form>
    </div>
</div>
<ng-template #snackbar>
  <div class="d-flex justify-between align-center" [ngClass]="{'error-snack': !result }" >
    <div>
      <span>{{ resultMessage }}</span>
    </div>
    <div>
      <span>
        <mat-icon *ngIf="result">check</mat-icon>
        <mat-icon *ngIf="!result">close</mat-icon>
      </span>
    </div>
  </div>
</ng-template>