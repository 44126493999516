import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TeamModel } from 'src/app/models/left-menu';

@Component({
  selector: 'sidebar-team',
  templateUrl: './sidebar-team-dropdown.component.html',
  styleUrls: ['./sidebar-team-dropdown.component.css'],
})
export class SidebarTeamDropdown implements OnInit {
  @Input() sidenavOpened: boolean = true;
  @Input() teamData: TeamModel = new TeamModel();
  @Output() openSidenavToShowTeam = new EventEmitter();
  @Output() handleTeamOpenClose = new EventEmitter();

  public showTeamMembers: boolean = false;

  public onOpenCloseHandle = () => {
    this.showTeamMembers = !this.showTeamMembers;
    this.showTeam();
  };

  ngOnInit(): void {
    this.showTeamMembers = this.teamData.IsOpened;
    (this.teamData.Users as any) = this.teamData.Users.map((el) => {
      if (!el.UserIconUrl.includes('url')) {
        el.UserIconUrl = `url(${el.UserIconUrl})`;
      }
      return el;
    });
  }

  public showTeam(): void {
    this.openSidenavToShowTeam.emit();
  }

  public closeTeam(): void {
    this.showTeamMembers = false;
  }

  public checkTeamActivity(teamData: TeamModel): string {
    return teamData.Users.some((el) => el.Status === 'green')
      ? 'green'
      : teamData.Users.some((el) => el.Status === 'orange')
      ? 'orange'
      : 'red';
  }
}
