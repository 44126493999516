import { O } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Product } from './types';

@Component({
  selector: 'validation-modal',
  templateUrl: './validation-popup.component.html',
  styleUrls: ['./validation-popup.component.scss'],
})
export class ValidationPopup implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      displayData: Product[];
    }
  ) {}

  ngOnInit(): void {}

  public get ids(): string {
    return this.data.displayData.map((x) => x.Id).join(', ');
  }

  public loading: boolean = false;

  public handleRemoveArchiveDocument() {}
}
