import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Helper } from 'src/app/helpers/helper';
import { UserModel } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'action-assign-dialog',
  templateUrl: './action-assign-dialog.component.html',
  styleUrls: ['./action-assign-dialog.component.css'],
})
export class ActionAssignDialog implements OnInit {
  public users: UserModel[] = [];
  public isLoading: boolean = false;
  public searchText = '';

  constructor(
    private helper: Helper,
    public dialog: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.loadAnalysts();
  }

  loadAnalysts() {
    this.isLoading = true;
    this.userService.getAnalysts(this.helper.getUserId()).subscribe((res) => {
      if (res.Success && res.Data?.length) {
        this.users = res.Data;

        this.users.map((user) => {
          user.Selected = false;
          if(!user.AvatarUrl) {
            user.ImgUrl = './assets/icons/customer.png';
            user.Background = '#E7EBFB';
          } else {
            user.ImgUrl = user.AvatarUrl;
          }
        });
        this.users[0].Selected = true;
      }

      this.isLoading = false;
    });
  }

  isSomeSelected(): boolean {
    return !this.users.some((u) => u.Selected == true);
  }

  onClickAssign(selectedUser: UserModel) {
    this.users.forEach((user) => {
      if (user.Id != selectedUser.Id) {
        user.Selected = false;
      }
    });

    selectedUser.Selected = !selectedUser.Selected;
  }

  randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  selectedUserId(): string {
    return this.users.find((u) => u.Selected == true)?.Id ?? '';
  }
}
