import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';
import 'moment-timezone';
import {Subscription} from 'rxjs';
import {DocuLibraryService} from "../../../../../../../services/docu-library.service";
import {DialogData} from "../../sub-tabs/docu-lib-accounts/types";

@Component({
  selector: 'audit-log-modal',
  templateUrl: 'audit-log-modal.component.html',
  styleUrls: ['audit-log-modal.component.scss'],
})
export class DocuLibAuditLogModalComponent implements OnInit, OnDestroy {
  data: any[] = [];
  isLoading: boolean = false;

  audiLogSubscription!: Subscription;

  constructor(
    private docuLibService: DocuLibraryService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData
  ) {
  }

  public get isOnDocuLibProducts(): boolean {
    return this.dialogData.isOnDocuLibProducts;
  }

  public get isOnDocuLibAccounts(): boolean {
    return this.dialogData.isOnDocuLibAccounts;
  }

  ngOnInit(): void {
    this.isLoading = true;

    if (this.isOnDocuLibProducts) {
      this.audiLogSubscription = this.docuLibService
        .getDocuLibProductsAuditLog(this.dialogData.clientId)
        .subscribe((res: any) => {
          this.data = res.Data.map((log: any) => {
            const utcTimeString = log.CreatedOn;
            const timezone = moment.tz.guess();
            const localTime = moment.utc(utcTimeString).tz(timezone);
            log.CreatedOnDate = localTime.format('DD MMM YY');
            log.CreatedOnTime = localTime.format('HH:mm:ss');
            return log;
          });
          this.isLoading = false;
        });
    } else if(this.dialogData.isOnDocuLibAccounts) {
      this.audiLogSubscription = this.docuLibService
        .getDocuLibAccountsAuditLog(this.dialogData.clientId)
        .subscribe((res: any) => {
          this.data = res.Data.map((log: any) => {
            const utcTimeString = log.CreatedOn;
            const timezone = moment.tz.guess();
            const localTime = moment.utc(utcTimeString).tz(timezone);
            log.CreatedOnDate = localTime.format('DD MMM YY');
            log.CreatedOnTime = localTime.format('HH:mm:ss');
            return log;
          });
          this.isLoading = false;
        });
    }
  }

  ngOnDestroy(): void {
    this.audiLogSubscription.unsubscribe();
  }

  isAddedMessage(log: any) {
    return (
      log.PropertyName == 'Status' &&
      !log.OldValue &&
      log.NewValue.includes('Requested')
    );
  }

  getProperLogText(action: any) {
    switch (action.PropertyName) {
      case 'New document generation':
        return 'Generated By';

      case 'generate':
        return 'Generated By';

      case 'Action':
        return 'Set to';

      case 'Comment':
        return 'Comment Modified By';

      case 'DocumentUrl':
        return 'Uploaded By';

      case 'Auto generate':
        return 'Generated By System';

      default:
        return '';
    }
  }

  public getProperMessage(message: string): string {
    if(message.includes('was added')) return 'Added';
    if(message.includes('was modified')) return 'Modified';
    if(message.includes('was deleted')) return 'Deleted';
    if(message.includes('was set to ACTIVE')) return 'Active';
    if(message.includes('was set to CLOSED')) return 'Closed';
    if(message.includes('was set to FROZEN')) return 'Frozen';

    return  message;
  }
}
