import {AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';

@Component({
  selector: 'app-active-sorting-indicator',
  templateUrl: './active-sorting-indicator.component.html',
  styleUrls: ['./active-sorting-indicator.component.scss']
})
export class ActiveSortingIndicatorComponent implements OnInit, AfterViewInit {
  @Input() public customStyling: string = '';
  @ViewChild('activeIndicator', {static: true}) indicatorRef: ElementRef | undefined;

  constructor(private renderer: Renderer2) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.customStyling)
      this.addClassToElement(this.indicatorRef?.nativeElement, this.customStyling);
  }

  public addClassToElement(element: HTMLElement, className: string) {
    this.renderer.addClass(element, className);
  }
}
