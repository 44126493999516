<div class="change-display-columns" [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click"
    #popoverTrigger="mdePopoverTrigger">
    <img style="width: 15px" src="./../assets/icons/choose-columns.svg" alt="" />
</div>
<mde-popover class="columns-popover" #appPopover="mdePopover" [mdePopoverOverlapTrigger]="true" [mdePopoverCloseOnClick]="false" >
    <div>
        <div class="change-display-columns" (click)="closePopover()">
            <img style="width: 15px" src="./../assets/icons/choose-columns.svg" alt="" />
        </div>
        <div class="chips-wrapper">
            <mat-chip-list #columnChips aria-label="Fish selection" selectable multiple>
                <mat-chip *ngFor="let column of allColumns" #c="matChip" [value]="column" (click)="selectColumn(c, columnChips)"
                    class="orange-chip" selected="displayedColumns.some(dc => dc === column)">
                    {{ columnLabels[column] }}
                </mat-chip>
            </mat-chip-list>
            <div class="footer">
                <button class="cancel-btn" (click)="closePopover()" mat-button>close</button>
                <button class="submit-btn" (click)="submitDisplayedColumns(columnChips)" mat-button>save</button>
            </div>
        </div>
    </div>
</mde-popover>