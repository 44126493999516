<div class="main-container">
  <div mat-dialog-title class="header">
    <div *ngIf="dialogData.isOnDocuLibProducts">{{ "Log Product" }}</div>
    <div *ngIf="dialogData.isOnDocuLibAccounts">{{ "Log Account" }}</div>

    <div class="modal-close" mat-dialog-close>
      <img src="./../../../../assets/icons/close-solid.svg"/>
    </div>
  </div>
  <div class="modal-content">
    <!--  Loader  -->
    <div class="spinner" *ngIf="isLoading">
      <mat-spinner [diameter]="70"></mat-spinner>
    </div>

    <!--  No Data Component  -->
    <div *ngIf="!isLoading && data.length === 0">
      <div class="d-flex justify-content-center align-items-center">
        <no-data-component></no-data-component>
      </div>
    </div>

    <!--  Data View  -->
    <div class="logs-main-container" *ngIf="!isLoading && data.length > 0">
      <div class="logs-container">
        <div *ngFor="let log of data" class="log">
          <div class="is-seen-wrapper">
            <div class="is-seen seen"></div>
          </div>
          <div class="history-descr" *ngIf="!isAddedMessage(log)">
            <!--  Docu Lib Accounts View -->
            <ng-container *ngIf="isOnDocuLibAccounts; else regularView">
              <span style="color: #636775">
                {{ "Account ID #" + log.AccountNumber + " - " + getProperLogText(log) }}

                <ng-container *ngIf="log.PropertyName === 'Status'; else defaultLogView">
                  <span>status was set to </span>
                  <span [class]="getProperMessage(log.Message)"> {{ getProperMessage(log.Message) }}</span>
                </ng-container>
                <ng-template #defaultLogView>
                  <span>was </span>
                  <span [class]="getProperMessage(log.Message)"> {{ getProperMessage(log.Message) }}</span>
                </ng-template>

                <span>&nbsp;By&nbsp;</span>
                <span class="username" *ngIf="log.PropertyName !== 'Auto generate'">{{ log.User.FullName }}</span>
                <span> on </span>
                <span class="creationDate">{{ log.CreatedOnDate }}</span>
                <span> - </span>
                <span class="creationTime">{{ log.CreatedOnTime }}</span>
              </span>
            </ng-container>

            <!-- Regular view -->
            <ng-template #regularView>
              {{ "Product ID # " + log.Product?.Id + " - " + log?.PropertyName + " changed from " }}
              <span class="old-value">{{ log.OldValue }}</span>
              to
              <span class="new-value">{{ log.NewValue }}</span>
              by
              <span class="username">{{ log.User.FullName }}</span>
              <span> on </span
              ><span class="creationDate">{{ log.CreatedOnDate }}</span
            ><span> - </span
            ><span class="creationTime">{{ log.CreatedOnTime }}</span>
            </ng-template>
          </div>
          <div class="history-descr" *ngIf="isAddedMessage(log)">
            {{ "Product ID # " + log.Product.Id + " - Added By" }}
            <span class="username">{{ log.User.FullName }}</span>
            <span> on </span
            ><span class="creationDate">{{ log.CreatedOnDate }}</span
          ><span> - </span
          ><span class="creationTime">{{ log.CreatedOnTime }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
