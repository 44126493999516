import {FormGroup} from "@angular/forms";
import {Component, OnInit} from '@angular/core';
import {Helper} from "../../../../../helpers/helper";
import {StructuresService} from "../../../../../services/structures.service";

@Component({
    selector: 'app-structure-search-results',
    templateUrl: './structure-search-results.component.html',
    styleUrls: ['./structure-search-results.component.scss']
})
export class StructureSearchResultsComponent implements OnInit {
    public entityType: string = '';
    public formData: FormGroup | null = new FormGroup({});
    public isPanelOpened: boolean = false;
    public dataSource: {
        ClientAssciation: string,
        ClientId: number,
        Country: string,
        EntityName: string,
        FirstName: string,
        LastName: string,
        MiddleName: string,
        State: string,
        IsVisible: boolean,
        ParentId: number,
        Id: number
    }[] = []
    public isLoading: boolean = false;
    public isDataFetched: boolean = false;
    public totalCount: number = 0;
    public pageNumber: number = 0;
    public pageSize: number = 10;
    public rightTabOpened: boolean = false;
    private selectedTableRowId: number = 0;

    constructor(private helper: Helper, private structuresService: StructuresService) {
    }

    ngOnInit(): void {
        this.helper.onSearchResultsPanelChange().subscribe((data) => {
            this.isPanelOpened = data.isOpen;

            this.formData = data.formData;
            this.entityType = this.formData ? this.formData.controls['entityType'].value : '';

            if (data.isOpen)
                this.fetchStructureSearchResult();
        });

        this.helper.onSearchSectionRightTabOpen().subscribe((id) => {
            if (id)
                if (this.selectedTableRowId === id && this.rightTabOpened) {
                    this.rightTabOpened = false;
                    this.selectedTableRowId = 0;
                } else {
                    this.rightTabOpened = true;
                    this.selectedTableRowId = id;
                }
            else this.rightTabOpened = false;
        });
    }

    public handlePageChange(pageIndex: number) {
        this.pageNumber = pageIndex;
        this.fetchStructureSearchResult();
    }

    private fetchStructureSearchResult(): void {
        this.isLoading = true;
        this.structuresService.searchStructures({
            PageNumber: this.pageNumber + 1,
            PageSize: this.pageSize,
            EntityType: this.formData?.controls['entityType'].value,
            Filter: {
                ClientId: parseInt(this.formData?.controls['cid'].value) || null,
                EntityName: this.formData?.controls['entityName'].value || null,
                FirstName: this.formData?.controls['firstName'].value || null,
                MiddleName: this.formData?.controls['middleName'].value || null,
                LastName: this.formData?.controls['lastName'].value || null
            }
        }).subscribe((data: any) => {
            this.dataSource = data.Data.Data;
            this.totalCount = data.Data.Total;
            this.isLoading = false;
            this.isDataFetched = true;
        });
    }

    public fetchStructuresData(): void {

    }
}
