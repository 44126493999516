<div class="spinner-container" *ngIf="dataSource.loading$ | async">
  <mat-spinner [diameter]="70"></mat-spinner>
</div>

<!-- no data screen -->
<div
  *ngIf="dataSource.getData().length === 0"
  class="d-flex w-100 wrapper-no-data"
  [ngStyle]="{ opacity: (dataSource.loading$ | async) ? 0 : 1 }"
>
  <div class="img-label">
    <div class="no-data-box">
      <img
        alt="no data"
        style="width: 100px"
        src="../../../../assets/empty.png"
      />
    </div>
    <div class="no-data-label">No data</div>
    <div class="no-data-label-secondary">
      No results found for this particular setting
    </div>
  </div>
</div>
<mat-table
  [multiTemplateDataRows]="true"
  matSort
  #matTable
  [id]="tableId"
  matSortDisableClear
  matSortDirection="desc"
  class="mat-elevation-z8"
  [dataSource]="dataSource"
  [ngStyle]="{
      opacity:
        (dataSource.loading$ | async) || dataSource.getData().length === 0 ? 0 : 1
    }"
>
  <ng-container
    [matColumnDef]="column"
    [sticky]="i <= 5 && fixedColumns[column]"
    [stickyEnd]="fixedColumns[column] && i > 5"
    *ngFor="let column of displayedColumns; let i = index"
  >
    <!-- active nudge identifier -->
    <ng-container *ngIf="i === 0">
      <th
        mat-header-cell
        *matHeaderCellDef
        style="margin-right: 0.5rem !important"
        class="column-header-custom extra-column-header nudge"
      ></th>

      <td
        mat-cell
        *matCellDef="let row"
        [ngStyle]="{
            'background-color': row.IsNudge
              ? 'red'
              : selection.isSelected(row)
              ? '#F4F5F8'
              : 'white'
          }"
        class="nudge"
      >
        <div class="nudge-section"></div>
      </td>
    </ng-container>

    <!-- checkbox column -->
    <ng-container *ngIf="i === 1" matColumnDef="select">
      <th
        style="padding-left: 1.2rem !important"
        class="column-header-custom extra-column-header th-checkbox"
        mat-header-cell
        *matHeaderCellDef
      >
        <div class="checkbox-wrapper">
          <mat-checkbox
            (change)="$event ? toggleAllRows() : null"
            [checked]="
                selection.selected.length === dataSource.getData().length
              "
          >
          </mat-checkbox>
        </div>
      </th>

      <td mat-cell *matCellDef="let row" class="td-checkbox">
        <div class="checkbox-wrapper">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? handleCheckBoxChange(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </div>
      </td>
    </ng-container>

    <!-- other data columns -->
    <ng-container *ngIf="i > 1">
      <!-- Table Head -->
      <th mat-header-cell *matHeaderCellDef class="column-header-custom">
        <ng-container [ngSwitch]="column">
          <!-- Risk Score -->
          <div
            class="column-header-text d-flex"
            style="text-align: center"
            *ngSwitchCase="'RiskScore'"
          >
            <div class="d-flex" style="align-items: center">
                <span
                  class="filter-icon"
                  [ngStyle]="{
                    'background-image': isColumnFiltered(column)
                      ? 'url(\'./../../assets/icons/product-filter-indicator.svg\')'
                      : null
                  }"
                ></span>

              {{ columnNames[column] }}

              <sorting-filtering-component
                [filterColumns]="filterColumns"
                [sortingData]="sorting"
                [statusFilter]="statusFilter"
                [column]="column"
                [columnNames]="columnNames"
                [menuColumns]="menuColumns"
                [isAddNewEntryTable]="isAddNewEntryTable"
                (loadAnalytics)="
                    loadAnalyticPage($event.column, $event.direction)
                  "
                (clickFilterBy)="onClickfilterByColumn(column)"
                [displayedColumns]="displayedColumns"
                [dataSource]="dataSource"
                (clickApplyFilters)="
                    loadAnalyticPage(
                      $event.column,
                      $event.direction,
                      $event.filterCol,
                      $event.isReset
                    )
                  "
              >
              </sorting-filtering-component>
            </div>
          </div>

          <!-- Status -->
          <!-- <div
            *ngSwitchCase="'Status'"
            class="column-header-text d-flex"
            style="text-align: center; justify-content: center"
          >
            {{ columnNames[column] }}
          </div> -->

          <!-- Stage -->
          <div
            *ngSwitchCase="'Stage'"
            class="column-header-text d-flex"
            style="text-align: center; justify-content: center"
          >
            <!-- sorting & filtering -->
            <div class="d-flex" style="align-items: center">
                <span
                  class="filter-icon"
                  [ngStyle]="{
                    'background-image': isColumnFiltered(column)
                      ? 'url(\'./../../assets/icons/product-filter-indicator.svg\')'
                      : null
                  }"
                ></span>

              {{ columnNames[column] }}

              <sorting-filtering-component
                [filterColumns]="filterColumns"
                [sortingData]="sorting"
                [statusFilter]="statusFilter"
                [column]="column"
                [columnNames]="columnNames"
                [menuColumns]="menuColumns"
                [isAddNewEntryTable]="isAddNewEntryTable"
                (loadAnalytics)="
                    loadAnalyticPage($event.column, $event.direction)
                  "
                (clickFilterBy)="onClickfilterByColumn(column)"
                [displayedColumns]="displayedColumns"
                [dataSource]="dataSource"
                (clickApplyFilters)="
                    loadAnalyticPage(
                      $event.column,
                      $event.direction,
                      $event.filterCol,
                      $event.isReset
                    )
                  "
              >
              </sorting-filtering-component>
            </div>
          </div>

          <span *ngSwitchDefault class="column-header-text">
              <div class="d-flex" style="align-items: center">
                <span
                  class="filter-icon"
                  [ngStyle]="{
                    'background-image': isColumnFiltered(column)
                      ? 'url(\'./../../assets/icons/product-filter-indicator.svg\')'
                      : null
                  }"
                ></span>

                {{ columnNames[column] }}

                <sorting-filtering-component
                  [filterColumns]="filterColumns"
                  [sortingData]="sorting"
                  [statusFilter]="statusFilter"
                  *ngIf="
                    column !== 'RowMenu' &&
                    column !== 'Link' &&
                    column !== 'NextStage' &&
                    column !== 'Edit' &&
                    column !== 'Remove' &&
                    column !== 'Aging'
                  "
                  [column]="column"
                  [columnNames]="columnNames"
                  [menuColumns]="menuColumns"
                  [isAddNewEntryTable]="isAddNewEntryTable"
                  (loadAnalytics)="
                    loadAnalyticPage($event.column, $event.direction)
                  "
                  (clickFilterBy)="onClickfilterByColumn(column)"
                  [displayedColumns]="displayedColumns"
                  [dataSource]="dataSource"
                  (clickApplyFilters)="
                    loadAnalyticPage(
                      $event.column,
                      $event.direction,
                      $event.filterCol,
                      $event.isReset
                    )
                  "
                >
                </sorting-filtering-component>
              </div>
            </span>
        </ng-container>
      </th>

      <!-- Table Rows -->
      <td
        mat-cell
        class="custom-td"
        [formGroup]="editForm"
        *matCellDef="let element"
        [ngClass]="{
            fixedColumn: fixedColumns[column],
            unFixedColumn: !fixedColumns[column]
          }"
      >
        <ng-container [ngSwitch]="column">
          <!-- Client Id -->
          <ng-container *ngSwitchCase="'ClientId'">
            <input
              matInput
              class="edit-input short-input"
              [value]="element[column]"
              formControlName="clientId"
              [(ngModel)]="currentEditingNode.ClientId"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
            />
            <ng-template #elseBlock>
                <span class="regular-cell" style="font-weight: 600">
                  #INV-{{ element[column] }}
                </span>
            </ng-template>
          </ng-container>

          <!-- Client Name -->
          <ng-container *ngSwitchCase="'ClientName'">
            <input
              matInput
              class="edit-input"
              [value]="element[column]"
              formControlName="clientNameControl"
              [(ngModel)]="currentEditingNode.ClientName"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
            />
            <ng-template #elseBlock>
              <div class="d-flex">
                <div class="client-name-img-wrapper">
                  <img
                    *ngIf="element.ClientAvatarUrl && element.ClientAvatarUrl !== '-' && element.ClientAvatarUrl !== '0'; else noImgBlock"
                    alt="client-name-img"
                    [src]="element.ClientAvatarUrl"
                    class="client-name-img"
                  />

                  <ng-template #noImgBlock>
                    <div class="client-no-name-img">
                      <mat-icon class="default-client-img">add_a_photo</mat-icon>
                    </div>
                  </ng-template>
                </div>

                <div class="d-flex company-name">
                  <div class="upper">
                    {{ element[column] }}
                  </div>

                  <div class="bottom">Creative Agency</div>
                </div>
              </div>
            </ng-template>
          </ng-container>

          <!-- Parent Grouping -->
          <ng-container *ngSwitchCase="'ParentGrouping'">
            <input
              matInput
              class="edit-input"
              [value]="element[column]"
              formControlName="parentGroupingControl"
              [(ngModel)]="currentEditingNode.ParentGrouping"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
            />
            <ng-template #elseBlock>
              <div
                style="display: flex; justify-content: center"
                class="cell-content-colored"
              >
                {{ element[column] || "-" }}
              </div>
            </ng-template>
          </ng-container>

          <!-- Due Date -->
          <ng-container *ngSwitchCase="'DueDateStr'">
            <mat-form-field
              id="datepicker"
              appearance="fill"
              class="date-picker-edit-mode"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
            >
              <input
                matInput
                [matDatepicker]="picker"
                [value]="element[column]"
                formControlName="dueDateStrControl"
                [(ngModel)]="currentEditingNode.DueDate"
              />

              <mat-datepicker-toggle matSuffix [for]="picker">
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <ng-template #elseBlock>
                <span class="regular-cell">{{
                  element["DueDate"] | date: "dd/MM/yyyy"
                  }}</span>
            </ng-template>
          </ng-container>

          <!-- Country Of Incorporation -->
          <ng-container *ngSwitchCase="'CountryOfIncorporation'">
            <input
              matInput
              class="edit-input"
              [value]="element[column]"
              formControlName="countryOfIncorporationControl"
              [(ngModel)]="currentEditingNode.CountryOfIncorporation"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
            />
            <ng-template #elseBlock>
              <span class="cell-content-colored">{{ element[column] }}</span>
            </ng-template>
          </ng-container>

          <!-- Revenue -->
          <ng-container *ngSwitchCase="'Revenue'">
            <input
              matInput
              class="edit-input short-input"
              [value]="element[column]"
              formControlName="revenueControl"
              [(ngModel)]="currentEditingNode.Revenue"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
            />
            <ng-template #elseBlock>
              <span class="regular-cell">${{ element[column] || "-" }}</span>
            </ng-template>
          </ng-container>

          <!-- Bucket -->
          <ng-container *ngSwitchCase="'Bucket'">
            <mat-form-field
              style="padding: 0"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
              appearance="fill"
            >
              <mat-select
                formControlName="bucketControl"
                (change)="handleBucketChange($event)"
                [(ngModel)]="currentEditingNode.Bucket"
              >
                <mat-option
                  *ngFor="let item of bucketDropdownValues"
                  [value]="item.viewValue"
                >
                  {{ item.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <ng-template #elseBlock>
              <span class="cell-content-colored">{{ element[column] }}</span>
            </ng-template>
          </ng-container>

          <!-- Client Type -->
          <ng-container *ngSwitchCase="'ClientType'">
            <input
              matInput
              class="edit-input"
              [value]="element[column]"
              formControlName="clientTypeControl"
              [(ngModel)]="currentEditingNode.ClientType"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
            />
            <ng-template #elseBlock>
              <span class="regular-cell">{{ element[column] }}</span>
            </ng-template>
          </ng-container>

          <!-- Analyst -->
          <ng-container *ngSwitchCase="'Analyst'">
            <input
              matInput
              class="edit-input"
              [value]="element[column]"
              formControlName="analystControl"
              [(ngModel)]="currentEditingNode.Analyst"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
            />
            <ng-template #elseBlock>
              <span class="cell-content-colored">{{ element[column] }}</span>
            </ng-template>
          </ng-container>

          <!-- QC Reviewer -->
          <ng-container *ngSwitchCase="'QCReviewer'">
            <input
              matInput
              class="edit-input"
              [value]="element[column]"
              formControlName="qcReviewerControl"
              [(ngModel)]="currentEditingNode.QCReviewer"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
            />
            <ng-template #elseBlock>
              <span class="regular-cell">{{ element[column] }}</span>
            </ng-template>
          </ng-container>

          <!-- Client Contact Title -->
          <ng-container *ngSwitchCase="'ClientContactTitle'">
            <input
              matInput
              class="edit-input"
              [value]="element[column]"
              formControlName="clientContactTitleControl"
              [(ngModel)]="currentEditingNode.ClientContactTitle"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
            />
            <ng-template #elseBlock>
              <span class="cell-content-colored">{{ element[column] }}</span>
            </ng-template>
          </ng-container>

          <!-- Client Contact Email -->
          <ng-container *ngSwitchCase="'ClientContactEmail'">
            <input
              matInput
              class="edit-input"
              [value]="element[column]"
              formControlName="clientContactEmailControl"
              [(ngModel)]="currentEditingNode.ClientContactEmail"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
            />
            <ng-template #elseBlock>
              <span class="regular-cell">{{ element[column] }}</span>
            </ng-template>
          </ng-container>

          <!-- Client Contact Name -->
          <ng-container *ngSwitchCase="'ClientContactName'">
            <input
              matInput
              class="edit-input"
              [value]="element[column]"
              formControlName="clientContactNameControl"
              [(ngModel)]="currentEditingNode.ClientContactName"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
            />
            <ng-template #elseBlock>
              <span class="cell-content-colored">{{ element[column] }}</span>
            </ng-template>
          </ng-container>

          <!-- Completion Date -->
          <ng-container *ngSwitchCase="'CompletionDateStr'">
            <mat-form-field
              id="datepicker-completion"
              appearance="fill"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
            >
              <input
                matInput
                [matDatepicker]="picker"
                [value]="element[column]"
                formControlName="completionDateStrControl"
                [(ngModel)]="currentEditingNode.CompletionDate"
              />

              <mat-datepicker-toggle matSuffix [for]="picker">
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <ng-template #elseBlock>
                <span class="regular-cell">{{
                  element["CompletionDateStr"]
                  }}</span>
            </ng-template>
          </ng-container>

          <!-- Initial Outreach Date -->
          <ng-container *ngSwitchCase="'InitialOutreachDateStr'">
            <mat-form-field
              appearance="fill"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
              id="datepicker-initial-outreach"
            >
              <input
                matInput
                [matDatepicker]="picker"
                [value]="element[column]"
                formControlName="initialOutreachDateStrControl"
                [(ngModel)]="currentEditingNode.InitialOutreachDate"
              />

              <mat-datepicker-toggle matSuffix [for]="picker">
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <ng-template #elseBlock>
                <span class="cell-content-colored">{{
                  element["InitialOutreachDateStr"]
                  }}</span>
            </ng-template>
          </ng-container>

          <!-- Reason For Descope -->
          <ng-container *ngSwitchCase="'ReasonForDescope'">
            <input
              matInput
              class="edit-input"
              [value]="element[column]"
              formControlName="reasonForDescopeControl"
              [(ngModel)]="currentEditingNode.ReasonForDescope"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
            />
            <ng-template #elseBlock>
              <span class="regular-cell">{{ element[column] }}</span>
            </ng-template>
          </ng-container>

          <!-- Products -->
          <ng-container *ngSwitchCase="'Products'">
            <input
              matInput
              class="edit-input"
              [value]="element[column]"
              formControlName="productsControl"
              [(ngModel)]="currentEditingNode.Products"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
            />
            <ng-template #elseBlock>
              <div
                id="productCell"
                #tooltip="matTooltip"
                matTooltipPosition="above"
                color="primary"
                [matTooltip]="element[column] === '-' ? null : element[column]"
                class="cell-content-colored products"
              >
                {{ element[column] }}
              </div>
            </ng-template>
          </ng-container>

          <!-- Risk Score -->
          <ng-container *ngSwitchCase="'RiskScore'">
            <mat-form-field
              style="padding: 0"
              *ngIf="
                  editingStarted &&
                    currentEditingNode.ClientId === element.ClientId;
                  else elseBlock
                "
              appearance="fill"
            >
              <mat-select
                formControlName="riskScoreControl"
                [(ngModel)]="currentEditingNode.RiskScore"
              >
                <mat-option
                  *ngFor="let item of riskScoreDropdownValues"
                  [value]="item.value"
                >
                  {{ item.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <ng-template #elseBlock>
              <ng-container [ngSwitch]="element[column]">
                <div class="d-flex" style="justify-content: space-between">
                  <ng-container>
                    <!-- Low -->
                    <div
                      style="text-align: center"
                      *ngSwitchCase="'Low'"
                      class="low"
                    >
                      {{ element[column] || "-" }}
                    </div>
                    <!-- Low -->
                    <div
                      style="text-align: center"
                      *ngSwitchCase="'Low '"
                      class="low"
                    >
                      {{ element[column] || "-" }}
                    </div>

                    <!-- High -->
                    <div *ngSwitchCase="'High'" class="high">
                      {{ element[column] || "-" }}
                    </div>

                    <!-- Medium -->
                    <div *ngSwitchCase="'Moderate'" class="medium">Medium</div>
                  </ng-container>

                  <ng-container *ngIf="element.IsNudge && !isAdmin">
                    <img
                      class="nudge-icon"
                      src="./../assets/icons/nudge-alert.svg"
                      alt="nudge-alert"
                    />
                  </ng-container>
                </div>
              </ng-container>
              <!-- Nudge Alert Icon -->
            </ng-template>
          </ng-container>

          <!-- Status -->
          <ng-container *ngSwitchCase="'Status'">
            <ng-container [ngSwitch]="element[column]">
              <!-- Completed -->
              <div
                class="d-flex completed"
                *ngIf="element.Stage === 'Complete'"
              >
                <img
                  alt="completed"
                  src="./../assets/icons/tick.svg"
                  style="width: 30px; padding-right: 0.7rem"
                />
                Completed
              </div>

              <!-- Pending -->
              <div class="d-flex pending" *ngIf="element.Stage !== 'Complete'">
                <img
                  alt="pending"
                  src="./../assets/icons/pending.svg"
                  style="width: 30px; padding-right: 0.7rem"
                />
                Pending
              </div>
            </ng-container>
          </ng-container>

          <!-- Stage -->
          <ng-container *ngSwitchCase="'Stage'">
            <div class="d-flex" style="justify-content: space-between">
                <span class="regular-cell">
                  {{ element["Stage"] }}
                </span>

              <!-- Nudge Alert Icon -->
              <ng-container *ngIf="element.IsNudge">
                <img
                  class="nudge-icon"
                  src="./../assets/icons/nudge-alert.svg"
                  alt="nudge-alert"
                />
              </ng-container>
            </div>
          </ng-container>

          <!-- Aging -->
          <ng-container *ngSwitchCase="'Aging'">
            <div class="d-flex" style="justify-content: space-between">
                <span class="regular-cell">
                  {{ element["Aging"] || "-" }}
                </span>
            </div>
          </ng-container>

          <!-- Row Menu -->
          <ng-container *ngIf="!isEditable && isAdmin">
            <ng-container *ngSwitchCase="'RowMenu'">
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu"
              >
                <mat-icon style="height: 22px !important; width: 22px"
                >more_vert
                </mat-icon
                >
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <span>Client Details</span>
                </button>
                <button mat-menu-item>
                  <span>Outreach Questionnaire</span>
                </button>
                <button
                  mat-menu-item
                  (click)="handleOpenAuditLog(element.CaseId)"
                >
                  <span>Audit Log</span>
                </button>
              </mat-menu>
            </ng-container>
          </ng-container>

          <!-- Link -->
          <ng-container
            *ngIf="!isEditable && !isAdmin && !isMoveToNextStageHidden"
          >
            <ng-container *ngSwitchCase="'Link'">
              <a
                class="outbound-link"
                (click)="handleLinkClick(element)"
              >
                Link
              </a>
            </ng-container>
          </ng-container>

          <!-- Change section -->
          <ng-container
            *ngIf="!isEditable && !isAdmin && !isMoveToNextStageHidden"
          >
            <ng-container *ngSwitchCase="'NextStage'">
              <ng-container
                *ngIf="
                    (!element.IsInitialReviewComplete &&
                      statusFilter === 'Initial Review') ||
                      (!element.IsOutreachComplete &&
                        statusFilter === 'Outreach') ||
                      (!element.IsApprovalComplete &&
                        statusFilter === 'Approval');
                    else nextStageButton
                  "
              >
                <button
                  class="arrow-right-btn"
                  style="pointer-events: none; padding: 0"
                >
                  <img
                    alt="arrow-right"
                    src="./../assets/icons/disabled-arrow.svg"
                  />
                </button>
              </ng-container>

              <ng-template #nextStageButton>
                <button
                  class="arrow-right-btn"
                  style="padding: 0"
                  (click)="onNextStage(element)"
                >
                  <img
                    alt="arrow-right"
                    src="./../assets/icons/next-state-arrow.svg"
                  />
                </button>
              </ng-template>
            </ng-container>
          </ng-container>

          <!-- Edit button -->
          <ng-container *ngIf="isEditable">
            <ng-container *ngSwitchCase="'Edit'">
              <button
                *ngIf="
                    editingStarted &&
                      currentEditingNode.ClientId === element.ClientId;
                    else regular
                  "
                class="arrow-right-btn"
                (click)="finishEditing()"
              >
                <img alt="arrow-right" src="./../assets/icons/tick.svg"/>
              </button>

              <ng-template #regular>
                <button
                  class="arrow-right-btn"
                  (click)="handleStartEditing(element)"
                >
                  <img alt="arrow-right" src="./../assets/icons/edit-btn.svg"/>
                </button>
              </ng-template>
            </ng-container>
          </ng-container>

          <!-- Remove button -->
          <ng-container *ngIf="isEditable">
            <ng-container *ngSwitchCase="'Remove'">
              <button class="arrow-right-btn" (click)="removeRow(element)">
                <img alt="arrow-right" src="./../assets/icons/remove.svg"/>
              </button>
            </ng-container>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>
  </ng-container>

  <mat-header-row
    class="column-header"
    *matHeaderRowDef="displayedColumns"
  ></mat-header-row>

  <mat-row
    [ngStyle]="{
        backgroundColor: selection.isSelected(row) ? '#F4F5F8' : ''
      }"
    *matRowDef="let row; columns: displayedColumns"
  ></mat-row>
</mat-table>
<div class="pagination-wrapper">
  <custom-paginator
    (changePageEmit)="handlePageChange($event)"
    [itemsPerPage]="paginator.pageSize"
    [numberOfItems]="dataSource.analyticCount"
  ></custom-paginator>
</div>
