<div class="table-wrapper">
  <table
    mat-table
    *ngIf="opened"
    [dataSource]="dataSource"
    multiTemplateDataRows
    class="mat-elevation-z8"
  >
    <ng-container
      matColumnDef="{{ column }}"
      *ngFor="let column of columnsToDisplay"
    >
      <td mat-cell *matCellDef="let element">
        <ng-container [ngSwitch]="column">
          <ng-container *ngSwitchCase="'Analyst'">
            <div
              class="d-flex analyst-box"
              style="justify-content: left !important; margin-left: 20px"
            >
              <div [ngStyle]="{ backgroundColor: element['AnalystColor'], width: '10px', height: '10px', borderRadius: '5px', marginRight: '5px' }"></div>
              {{ element[column] }}
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ element[column] === -1 ? '-' : element[column] }}
          </ng-container>
        </ng-container>
      </td></ng-container
    >

    <tr
      mat-row
      *matRowDef="let element; columns: columnsToDisplayWithExpand"
      class="example-element-row"
      (click)="expandedElement = expandedElement === element ? null : element"
    ></tr>
  </table>
</div>
