<h2>Displayed Columns</h2>
<div mat-dialog-content class="form-container">
  <mat-list class="checkbox-list m-t-4">
    <mat-list-item *ngFor="let column of displayColumns; let i = index" [attr.data-index]="i">
      <div class="flex-row-inline">
        <mat-checkbox color="primary" field #PrimaryProdMng name="PrimaryProdMng" [(ngModel)]="column.checked">
          {{column.name}}
        </mat-checkbox>

      </div>
    </mat-list-item>
  </mat-list>
  <button mat-icon-button aria-label="Close" (click)="close()" class="btn-modal-close"></button>
</div>
<div mat-dialog-actions class="form-control-buttons center">
  <button mat-stroked-button class="cancelBtn" color="primary" (click)="close()">Cancel</button>
  <button mat-raised-button color="primary" (click)="applyNewColumns()">Apply</button>
</div>
