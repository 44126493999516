<div class="d-flex w-100 justify-center align-center spinner-box" *ngIf="isLoading; else dataBlock">
    <mat-spinner [diameter]="70"></mat-spinner>
</div>

<ng-template #dataBlock>
    <ng-container *ngIf="dataSource; else noDataBlock">
        <div class="d-flex general-info-box flex-col" style="background: #FCFBFB;">
            <!-- General Info Block -->
            <app-reusable-data-block-with-header [isHeaderVisible]="false" class="padding-bottom">
                <form [formGroup]="generalInfoForm" class="d-flex w-100 flex-wrap">
                    <!-- CID -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="cid">CID</label>
                        <input id="cid" formControlName="cid" readonly>
                    </div>

                    <!-- Client Name -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="clientName">Client Name</label>
                        <input id="clientName" formControlName="clientName" readonly>
                    </div>

                    <!-- Website -->
                    <div class="control-box input-width padding-bottom">
                        <label for="website">Website</label>
                        <input id="website" formControlName="website">
                    </div>

                    <!-- Also Known As -->
                    <div class="control-box input-width padding-right ">
                        <label for="alsoKnownAs">Also Known As</label>
                        <input id="alsoKnownAs" formControlName="alsoKnownAs">
                    </div>

                    <!-- Previously Known As -->
                    <div class="control-box input-width">
                        <label for="previouslyKnownAs">Previously Known As</label>
                        <input id="previouslyKnownAs" formControlName="previouslyKnownAs" readonly>
                    </div>
                </form>
            </app-reusable-data-block-with-header>

            <!-- Entity Type Block -->
            <app-reusable-data-block-with-header blockHeader="ENTITY TYPE" class="padding-bottom">
                <form [formGroup]="entityTypeForm" class="d-flex w-100 flex-wrap">
                    <!-- Entity Type -->
                    <div class="control-box input-width padding-right">
                        <label for="entityType">Entity Type</label>
                        <input id="entityType" formControlName="entityType" readonly>
                    </div>

                    <!-- Entity Sub Type -->
                    <div class="control-box input-width padding-right">
                        <label for="entitySubType">Entity Sub-Type</label>
                        <input id="entitySubType" formControlName="entitySubType" readonly>
                    </div>
                </form>
            </app-reusable-data-block-with-header>

            <!-- Incorporation Block -->
            <app-reusable-data-block-with-header blockHeader="INCORPORATION" class="padding-bottom">
                <form [formGroup]="incorporationForm" class="d-flex w-100 flex-wrap">
                    <!-- Incorporation Country -->
                    <div class="control-box input-width padding-right">
                        <label for="incorporationCountry">Incorporation Country</label>
                        <input id="incorporationCountry" formControlName="incorporationCountry" readonly>
                    </div>

                    <!-- Incorporation Date -->
                    <div class="control-box input-width padding-right">
                        <label for="incorporationDate">Incorporation Date</label>
                        <input
                            readonly
                            id="incorporationDate"
                            [matDatepicker]="picker"
                            class="date-picker-padding"
                            formControlName="incorporationDate"
                        >
                        <mat-datepicker-toggle [for]="picker" matIconSuffix class="custom-toggle">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </div>

                    <!-- Incorporation Number -->
                    <div class="control-box input-width">
                        <label for="incorporationNumber">Incorporation Number</label>
                        <input id="incorporationNumber" formControlName="incorporationNumber">
                    </div>
                </form>
            </app-reusable-data-block-with-header>

            <!-- Regulation Block -->
            <app-reusable-data-block-with-header blockHeader="REGULATION" class="padding-bottom">
                <form [formGroup]="regulationForm" class="d-flex w-100 flex-wrap">
                    <ng-container *ngFor="let regulation of regulationArray?.controls; let i = index">
                        <div
                            class="d-flex w-100"
                            [class.padding-bottom]="regulationArray.length > 1 && i !== regulationArray.length - 1"
                        >
                            <!-- Regulator Name / Country -->
                            <div class="control-box input-width padding-right">
                                <label [for]="'regulatorNameCountry-' + i">Regulator Name / Country</label>
                                <input [id]="'regulatorNameCountry-' + i"
                                       [formControl]="regulation.value.regulatorNameCountry" readonly>
                            </div>

                            <!-- Regulation ID -->
                            <div class="control-box input-width">
                                <label [for]="'regulationId-' + i">Regulation ID</label>
                                <input
                                    [id]="'regulationId-' + i"
                                    [formControl]="regulation.value.regulationId"
                                    [readOnly]="!regulation.value.regulatorNameCountry.value"
                                >
                            </div>
                        </div>
                    </ng-container>
                </form>
            </app-reusable-data-block-with-header>

            <!-- LISTING Block -->
            <app-reusable-data-block-with-header blockHeader="LISTING" class="padding-bottom">
                <form [formGroup]="listingForm" class="d-flex w-100 flex-wrap">
                    <ng-container *ngFor="let listing of listingArray?.controls; let i = index">
                        <div
                            class="d-flex w-100"
                            [class.padding-bottom]="listingArray.length > 1 && i !== listingArray.length - 1"
                        >
                            <!-- Exchange Name / Country -->
                            <div class="control-box input-width padding-right">
                                <label [for]="'exchangeNameCountry-' + i">Exchange Name / Country</label>
                                <input [id]="'exchangeNameCountry-' + i"
                                       [formControl]="listing.value.exchangeNameCountry" readonly>
                            </div>

                            <!-- Listing ID -->
                            <div class="control-box input-width">
                                <label [for]="'listingId-' + i">Listing ID</label>
                                <input
                                    [id]="'listingId-' + i"
                                    [formControl]="listing.value.listingId"
                                    [readOnly]="!listing.value.exchangeNameCountry.value"
                                >
                            </div>
                        </div>
                    </ng-container>

                </form>
            </app-reusable-data-block-with-header>

            <!-- CHARITY REGISTRATION -->
            <app-reusable-data-block-with-header blockHeader="CHARITY REGISTRATION" class="padding-bottom">
                <form [formGroup]="charityRegistrationForm" class="d-flex w-100 flex-wrap">
                    <!-- Country Of Registration -->
                    <div class="control-box input-width padding-right">
                        <label for="countryOfRegistration">Country Of Registration</label>
                        <input id="countryOfRegistration" formControlName="countryOfRegistration">
                    </div>

                    <!-- Name Of Federal Agency -->
                    <div class="control-box input-width padding-right">
                        <label for="nameOfFederalAgency">Name Of Federal Agency</label>
                        <input id="nameOfFederalAgency" formControlName="nameOfFederalAgency">
                    </div>

                    <!-- Registration ID -->
                    <div class="control-box input-width">
                        <label for="registrationId">Registration ID</label>
                        <input id="registrationId" formControlName="registrationId">
                    </div>
                </form>
            </app-reusable-data-block-with-header>

            <!-- Industry -->
            <app-reusable-data-block-with-header blockHeader="INDUSTRY" class="padding-bottom">
                <form [formGroup]="industryForm" class="d-flex w-100 flex-wrap">
                    <!-- Industry -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="industry">Industry</label>
                        <ng-select
                            id="industry"
                            formControlName="industry"
                            (ngModelChange)="change($event)"
                            [items]="dropdownData ? dropdownData.Industries : null"
                        >
                        </ng-select>
                    </div>

                    <!-- Industry Type -->
                    <div class="control-box input-width padding-right">
                        <label for="industryType">Industry Type</label>
                        <ng-select
                            id="industryType"
                            [items]="industryTypes"
                            formControlName="industryType"
                        ></ng-select>
                    </div>

                    <!-- Sector -->
                    <div class="control-box input-width">
                        <label for="sector">Sector</label>
                        <ng-select id="sector" [items]="industrySectors" formControlName="sector"></ng-select>
                    </div>

                    <!-- Nature Of Business -->
                    <div class="control-box w-100">
                        <label for="natureOfBusiness">Nature Of Business</label>
                        <textarea id="natureOfBusiness" formControlName="natureOfBusiness"></textarea>
                    </div>
                </form>
            </app-reusable-data-block-with-header>

            <!-- Registered Address -->
            <app-reusable-data-block-with-header class="padding-bottom" blockHeader="REGISTERED ADDRESS">
                <form [formGroup]="registeredAddressForm" class="d-flex w-100 flex-wrap">
                    <!-- Street -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="street">Street</label>
                        <input id="street" formControlName="street">
                    </div>

                    <!-- Suite/Unit # -->
                    <div class="control-box input-width padding-right">
                        <label for="suiteOrUnit">Suite/Unit #</label>
                        <input id="suiteOrUnit" formControlName="suiteOrUnit">
                    </div>

                    <!-- City -->
                    <div class="control-box input-width">
                        <label for="city">City</label>
                        <input id="city" formControlName="city">
                    </div>

                    <!-- Country -->
                    <div class="control-box input-width padding-right">
                        <label for="country">Country</label>
                        <input id="country" formControlName="country">
                    </div>

                    <!-- Country -->
                    <div class="control-box input-width padding-right">
                        <label for="provinceOrState">Province/State</label>
                        <input id="provinceOrState" formControlName="provinceOrState">
                    </div>

                    <!-- Postal/Zip Code -->
                    <div class="control-box input-width">
                        <label for="postalOrZip">Postal/Zip Code</label>
                        <input id="postalOrZip" formControlName="postalOrZip">
                    </div>
                </form>
            </app-reusable-data-block-with-header>

            <!-- Mailing Address -->
            <app-reusable-data-block-with-header class="padding-bottom" blockHeader="MAILING ADDRESS"
                                                 [isMailingAddressBlock]="true"
                                                 (setMailingAddressEmitter)="handleSetMailingAddress()">
                <form [formGroup]="mailingAddressForm" class="d-flex w-100 flex-wrap">
                    <!-- Street -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="mailing-street">Street</label>
                        <input id="mailing-street" formControlName="street">
                    </div>

                    <!-- Suite/Unit # -->
                    <div class="control-box input-width padding-right">
                        <label for="mailing-suiteOrUnit">Suite/Unit #</label>
                        <input id="mailing-suiteOrUnit" formControlName="suiteOrUnit">
                    </div>

                    <!-- City -->
                    <div class="control-box input-width">
                        <label for="mailing-city">City</label>
                        <input id="mailing-city" formControlName="city">
                    </div>

                    <!-- Country -->
                    <div class="control-box input-width padding-right">
                        <label for="mailing-country">Country</label>
                        <input id="mailing-country" formControlName="country">
                    </div>

                    <!-- Country -->
                    <div class="control-box input-width padding-right">
                        <label for="mailing-provinceOrState">Province/State</label>
                        <input id="mailing-provinceOrState" formControlName="provinceOrState">
                    </div>

                    <!-- Postal/Zip Code -->
                    <div class="control-box input-width">
                        <label for="mailing-postalOrZip">Postal/Zip Code</label>
                        <input id="mailing-postalOrZip" formControlName="postalOrZip">
                    </div>
                </form>
            </app-reusable-data-block-with-header>

            <!-- Contact Information -->
            <app-reusable-data-block-with-header class="padding-bottom" blockHeader="CONTACT INFORMATION">
                <form [formGroup]="contactInfoForm" class="d-flex w-100 flex-wrap">
                    <!-- Phone -->
                    <div class="control-box input-width padding-right">
                        <label for="phone">Phone</label>
                        <input id="phone" formControlName="phone">
                    </div>

                    <!-- Fax -->
                    <div class="control-box input-width padding-right">
                        <label for="fax">Fax</label>
                        <input id="fax" formControlName="fax">
                    </div>

                    <!-- email -->
                    <div class="control-box input-width">
                        <label for="email">Total Equity</label>
                        <input id="email" formControlName="email">
                    </div>
                </form>
            </app-reusable-data-block-with-header>

            <!-- Financial Information -->
            <app-reusable-data-block-with-header class="padding-bottom" blockHeader="FINANCIAL INFORMATION">
                <form [formGroup]="financialInformationForm" class="d-flex w-100 flex-wrap">
                    <!-- Revenue -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="revenue">Phone</label>
                        <input id="revenue" formControlName="revenue">
                    </div>

                    <!-- Total Assets / Assets Under Management -->
                    <div class="control-box input-width padding-right">
                        <label for="totalAssetsOrAssetsUnderManagement">Total Assets / Assets Under Management</label>
                        <input id="totalAssetsOrAssetsUnderManagement"
                               formControlName="totalAssetsOrAssetsUnderManagement">
                    </div>

                    <!-- Total Equity -->
                    <div class="control-box input-width">
                        <label for="totalEquity">Total Equity</label>
                        <input id="totalEquity" formControlName="totalEquity">
                    </div>

                    <!-- Type Of Financial Breakdown -->
                    <div class="control-box input-width padding-bottom">
                        <label for="typeOfFinancialBreakdown">Type Of Financial Breakdown</label>
                        <input id="typeOfFinancialBreakdown" formControlName="typeOfFinancialBreakdown" readonly>
                    </div>

                    <div class="control-box w-100"></div>

                    <!-- List of Regions -->
                    <div class="d-flex flex-wrap">
                        <div class="d-flex region-block-wrapper"
                             *ngFor="let region of financialInformationForm.controls['listOfRegions']?.value">
                            <div class="region-block d-flex">
                                <div class="d-flex">{{ region.Country }}</div>

                                <div class="region-percentage">
                                    <div class="d-flex">{{ region.Value }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </app-reusable-data-block-with-header>

            <!-- External Database -->
            <app-reusable-data-block-with-header
                [isExternalDatabaseBlock]="true"
                class="padding-bottom" blockHeader="EXTERNAL DATABASE"
                (addNewExternalDbClickedEmitter)="handleAddNewExternalDB()"
            >
                <ng-container *ngFor="let externalDB of externalDatabasesArray.controls; let i = index">
                    <!-- External Database Source -->
                    <div
                        class="control-box w-50 padding-right"
                        [class.padding-bottom]="externalDatabasesArray.length > 1 && i !== externalDatabasesArray.length - 1"
                    >
                        <label for="externalDatabaseSource">External Database Source</label>
                        <ng-select
                            id="externalDatabaseSource"
                            [items]="dropdownData ? dropdownData.ExternalDatabaseSources : null"
                            [formControl]="externalDB.value.externalDatabaseSource"
                        >
                        </ng-select>
                    </div>

                    <!-- External Database ID -->
                    <div class="control-box w-50">
                        <label for="externalDatabaseId">External Database ID</label>
                        <input class="w-100" id="externalDatabaseId"
                               [formControl]="externalDB.value.externalDatabaseId">
                    </div>
                </ng-container>
            </app-reusable-data-block-with-header>

            <!-- KEY DATES -->
            <app-reusable-data-block-with-header class="padding-bottom" blockHeader="KEY DATES">
                <form [formGroup]="keyDatesForm" class="d-flex w-100 flex-wrap">
                    <!-- Initial Onboarding Source -->
                    <div class="control-box padding-right">
                        <label for="initialOnboardingDate">Initial Onboarding Date</label>
                        <input id="initialOnboardingDate" formControlName="initialOnboardingDate" readonly>
                    </div>

                    <!-- External Database ID -->
                    <div class="control-box padding-right">
                        <label for="lastRefreshDate">Last Refresh Date</label>
                        <input id="lastRefreshDate" formControlName="lastRefreshDate" readonly>
                    </div>

                    <!-- External Database ID -->
                    <div class="control-box">
                        <label for="nextRefreshDate">Next Refresh Date</label>
                        <input id="nextRefreshDate" formControlName="nextRefreshDate" readonly>
                    </div>
                </form>
            </app-reusable-data-block-with-header>

            <!-- Save Block -->
            <div class="d-flex w-100 justify-end">
                <div class="d-flex save-wrapper">
                    <div class="d-flex flex-col">
                <span class="last-updated">
                    <span class="updated-on-label">
                        Last Updated On:
                    </span>
                    <span class="updated-on-value">
                        {{ dataSource.DateLastUpdated | date }}
                    </span>
                </span>
                        <div class="updated-by">
                            by {{ dataSource.UpdatedBy }}
                        </div>
                    </div>

                    <button [disabled]="isSaveDisabled" class="save-button" (click)="handleUpdateGeneralInfo()">Save
                    </button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #noDataBlock>
        <div class="d-flex justify-content-center">
            <no-data-component></no-data-component>
        </div>
    </ng-template>
</ng-template>

