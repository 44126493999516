<div mat-dialog-title s>
  <h1 class="dialog-heading">Upload New Document</h1>
  <div class="modal-close" mat-dialog-close>
    <img src="./../../../../assets/icons/close-solid.svg" />
  </div>
</div>

<div
  mat-dialog-content
  [ngClass]="{
    'content-wrapper': !fileSuccessfullyProcessed,
    'content-table-wrapper': fileSuccessfullyProcessed
  }"
>
  <ng-container
    *ngIf="
      !fileSuccessfullyProcessed && !isNewEntrySaved && !isErrorWhileSaving
    "
  >
    <!-- File select/dropzone section -->
    <div
      (click)="file.click()"
      class="file-selection-area"
      [ngStyle]="{ opacity: isLoading ? 0 : 1 }"
      Dnd
      (fileDropped)="onDrop($event)"
    >
      <input
        #file
        hidden
        multiple
        type="file"
        id="input-file-id"
        (change)="processFiles($event)"
        accept=".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .eml', .xlsm, .xlsb"
      />
      <div class="label-img-wrapper">
        <img
          src="./../assets/icons/file-upload.svg"
          class="img-upload"
          alt="file-upload"
        />
        <label class="md-button md-raised md-primary label">
          Select or Drop your file here
        </label>
      </div>
    </div>

    <!-- Uploaded files section -->
    <div
      [ngStyle]="{ opacity: isLoading ? 0 : 1 }"
      class="files-to-upload-section"
    >
      <div class="d-flex uploaded-files-wrapper">
        <img
          alt="uploaded-file"
          *ngIf="files.length"
          class="uploaded-file-img"
          src="./../assets/icons/uploaded-file.svg"
        />

        <div>{{ displayFiles() }}</div>

        <img
          alt="delete file"
          *ngIf="files.length"
          class="delete-file-img"
          (click)="deleteSelectedFile()"
          src="./../assets/icons/delete-uploaded-file.svg"
        />
      </div>
    </div>
  </ng-container>

  <div class="spinner-container" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>

  <!-- success screen -->
  <!-- <div *ngIf="isNewEntrySaved" class="success-screen">
    <img
      src="./../assets/icons/tick.svg"
      style="width: 20px; height: 20px; padding-right: 1rem"
    />
    New document uploaded!
  </div>
</div> -->

  <!-- error screen -->
  <div *ngIf="isErrorWhileSaving" class="error-screen">
    Something went wrong! Please try again
  </div>

  <div
    mat-dialog-actions
    class="actions-wrapper"
    [ngClass]="{
      'actions-success-wrapper': isErrorWhileSaving || isNewEntrySaved
    }"
  >
    <div *ngIf="!isErrorWhileSaving && !isNewEntrySaved">
      <button
        mat-button
        class="accept-btn"
        [disabled]="files.length === 0"
        *ngIf="!fileSuccessfullyProcessed"
        (click)="continue()"
      >
        Continue
      </button>

      <button
        mat-button
        (click)="save()"
        class="accept-btn"
        *ngIf="fileSuccessfullyProcessed"
        [disabled]="saveButtonDisabledDueToEditing"
      >
        Save
      </button>

      <button mat-button mat-dialog-close class="cancel-btn">Cancel</button>
    </div>

    <!-- success screen buttons -->
    <div *ngIf="isErrorWhileSaving || isNewEntrySaved">
      <button mat-button mat-dialog-close class="cancel-btn">Close</button>
    </div>
  </div>
</div>
