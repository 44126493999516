export class FilterColumn {
  ColumnName: string = '';
  Excludes: string[] = [];
  Includes: string[] = [];
  PageSize: number = 8;
  PageNumber: number = 1;
  SortBy: string = '';
  SortDirection: string = '';
  Filter: string = '';
  UserId: string = '';

  constructor(columnName: string) {
    this.ColumnName = columnName;
  }
}
