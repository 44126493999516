import {
  Input,
  Output,
  Component,
  OnChanges,
  EventEmitter,
  SimpleChanges,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-search-result-paginator',
  templateUrl: './custom-paginator.component.html',
  styleUrls: ['./custom-paginator.component.scss'],
})
export class SearchResultsCustomPaginator implements OnInit, OnChanges {
  @Input() public numberOfItems: number = 1;
  @Output() public changePageEmit = new EventEmitter();

  @Input() public showDataCounter: boolean = true;
  @Input() public itemsPerPage: number = 10;
  @Input() public hidePaginationLabel: boolean = false;
  @Input() public isLading: boolean = false;
  @Input() public isOnSearchView: boolean = false;

  public showingFrom: number = 1;
  public showingTo: number = 10;

  public pages: number = 1;
  public numberOfPages: number[] = new Array(this.pages).fill(0);
  public currentPage: { index: number; active: boolean } = {
    index: 0,
    active: true,
  };

  maxSize: number = 2;

  ngOnInit() {
    this.handlePageChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.numberOfItems) {
      this.pages = Math.ceil(this.numberOfItems / this.itemsPerPage);
      this.numberOfPages = new Array(this.pages).fill(0);
      this.handlePageChange();
    }
  }

  goToPage(index: number) {
    this.currentPage.index = index;
    this.changePageEmit.emit(index);
    this.handlePageChange();
  }

  nextPage() {
    this.currentPage.index = this.currentPage.index + 1;
    this.changePageEmit.emit(this.currentPage.index) ;
    this.handlePageChange();
  }

  prevPage() {
    this.currentPage.index = this.currentPage.index - 1;
    this.changePageEmit.emit(this.currentPage.index);
    this.handlePageChange();
  }

  handlePageChange() {
    this.showingTo =
      this.itemsPerPage < this.numberOfItems
        ? (this.currentPage.index + 1) * this.itemsPerPage + 1 - 1
        : this.numberOfItems;
    this.showingFrom =
      this.itemsPerPage < this.numberOfItems
        ? this.showingTo - this.itemsPerPage + 1
        : 1;
    if (this.currentPage.index === this.numberOfPages.length - 1) {
      this.showingTo = this.numberOfItems;
    }
  }
}
