<div class="paginator-container">
  <div class="pagination" [class.w-66]="isOnSearchView">
    <button
      class="prev-btn"
      (click)="prevPage()"
      [disabled]="currentPage.index === 0"
    >
      Previous
    </button>
    <button
      class="next-btn"
      (click)="nextPage()"
      [disabled]="currentPage.index === numberOfPages.length - 1"
    >
      Next
    </button>

    <div class="d-flex">
      <ng-container *ngFor="let page of numberOfPages; let i = index">
        <button
          class="page-btn-wrapper"
          (click)="goToPage(i)"
          *ngIf="
            i + 1 >= currentPage.index - maxSize &&
            i - 1 <= currentPage.index + maxSize
          "
          [class.active]="currentPage.index === i"
        >
          {{ i + 1 }}
        </button>
      </ng-container>
    </div>

    <mat-spinner *ngIf="isLading" [diameter]="40"></mat-spinner>
  </div>
  <div
    class="data-counter"
    *ngIf="showDataCounter && numberOfItems && !hidePaginationLabel"
  >
        <span class="counter-label">
          Showing
          <span class="value">{{ showingFrom + "-" + showingTo }}</span>
          out of
          <span class="value">{{ numberOfItems }}</span>
          entries
        </span>
  </div>
</div>
