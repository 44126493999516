<div mat-dialog-title class="d-flex justify-between">
  <h2 class="dialog-label">{{ header }}</h2>

  <div class="close-btn" (click)="onCancelClick()">
    <div class="icon-wrapper">
      <div class="horizontal"></div>
      <div class="vertical"></div>
    </div>
  </div>
</div>
<form [formGroup]="accountForm">
  <mat-dialog-content>
    <!--  Account Number  -->
    <div class="dialog-form-control">
      <mat-label>Account Number</mat-label>
      <input
        type="text"
        class="text-input-styled"
        formControlName="accountNumber"
        onlyNumber
      />
    </div>

    <!--  Account Name  -->
    <div class="dialog-form-control">
      <mat-label>Account Name</mat-label>
      <input class="text-input-styled" formControlName="accountName" />
    </div>

    <!--  Account Type  -->
    <div class="dialog-form-control">
      <mat-label>Account Type</mat-label>
      <input class="text-input-styled" formControlName="accountType" />
    </div>

    <!--  Account Status  -->
    <div class="dialog-form-control">
      <mat-label>Account Status</mat-label>
      <mat-select class="select-input-styled" formControlName="accountStatus">
        <mat-option value="ACTIVE">Active</mat-option>
        <mat-option value="CLOSED">Closed</mat-option>
        <mat-option value="FROZEN">Frozen</mat-option>
      </mat-select>
    </div>

    <!--  Balance   -->
    <div class="dialog-form-control">
      <mat-label>Balance</mat-label>
      <input
        appBalanceFormatter
        class="text-input-styled"
        formControlName="balance"
      />
    </div>

    <!--  Currency  -->
    <div class="dialog-form-control">
      <mat-label>Currency</mat-label>
      <mat-select class="select-input-styled" formControlName="currency">
        <mat-option value="cad">CAD</mat-option>
        <mat-option value="usd">USD</mat-option>
        <mat-option value="eur">EUR</mat-option>
      </mat-select>
    </div>

    <!--  Date Account Opened  -->
    <div class="dialog-form-control">
      <mat-label>Date Account Opened</mat-label>

      <div class="date-picker-box">
        <input
          [max]="today"
          readonly
          [matDatepicker]="picker"
          formControlName="dateOpened"
          class="datepicker-input-styled"
        />
        <mat-datepicker-toggle
          [for]="picker"
          matIconSuffix
          class="custom-toggle"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-button
      class="action-btn-base continue"
      (click)="onContinueClick()"
    >
      Continue
    </button>
    <button mat-button class="action-btn-base cancel" (click)="onCancelClick()">
      Cancel
    </button>
  </mat-dialog-actions>
</form>
