import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'forgot-password',
  templateUrl: 'forgot-password.component.html',
  styleUrls: ['forgot-password.component.scss'],
})
export class ForgotPassword implements OnInit {
  isLoading: boolean = false;
  forgotPassForm: FormGroup = new FormGroup({
    email: new FormControl('', Validators.required),
  });

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}
  ngOnInit(): void {
    
  }

  submitForm(): void {
    this.forgotPassForm.controls['email'].markAsTouched();
    if (this.forgotPassForm.valid) {
      this.userService
        .forgotPassword(this.forgotPassForm.value['email'])
        .subscribe((res: any) => {
          if (!res.Success) {
            this.snackBar.open(res.Data, 'X', {
              panelClass: ['error-snackbar'],
            });
          } else {
            this.snackBar.open(res.Data, 'X', {
              panelClass: ['success-snackbar'],
            });
            setTimeout(() => {
              this.router.navigate(['/login']);
            }, 3000);
          }
        });
    }
  }
}
