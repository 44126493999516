import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ContactDataPayload} from "../contact-data-table/types";

@Component({
    selector: 'app-delete-confirmation-popup',
    templateUrl: './delete-confirmation-popup.component.html',
    styleUrls: ['./delete-confirmation-popup.component.css']
})
export class ContactDeleteConfirmationPopupComponent implements OnInit {
    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ContactDeleteConfirmationPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ContactDataPayload,
    ) {}

    ngOnInit(): void {
    }

    public handleDeleteClick(): void {
        this.dialogRef.close(true);
    }
}
