<div class="d-flex w-100 justify-center align-center spinner-box" *ngIf="isLoading; else dataBlock">
    <mat-spinner [diameter]="70"></mat-spinner>
</div>

<ng-template #dataBlock>
    <ng-container *ngIf="dataSource; else noDataBlock">
        <div class="d-flex w-100 tab-three-column-box">
            <form [formGroup]="df1Form" class="d-flex w-100">
                <!-- I Column -->
                <div class="d-flex flex-column data-column">
                    <!-- Institution -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="institution">Institution</label>
                        <input id="institution" formControlName="institution">
                    </div>

                    <!-- Is The Institution Acting As An Investment Advisor, Asset Manager Or Other Agent? -->
                    <div class="control-box w-100">
                        <label for="institutionActingAs">
                            Is the Institution acting as an investment Advisor, Asset Manager or other agent?
                        </label>
                        <input id="institutionActingAs" formControlName="institutionActingAs">
                    </div>

                    <!-- NFA ID -->
                    <div class="control-box w-100">
                        <label for="nfaId">NFA ID</label>
                        <input id="nfaId" formControlName="nfaId">
                    </div>

                    <!-- PCA Principal Information Block -->
                    <app-regulatory-block-with-header blockHeader="PCA Principal Information">
                        <!-- Principal Business -->
                        <div class="control-box w-100">
                            <label for="principalBusiness">Principal Business</label>
                            <input id="principalBusiness" formControlName="principalBusiness">
                        </div>

                        <!-- TIN -->
                        <div class="control-box w-100">
                            <label for="tin">TIN</label>
                            <input id="tin" formControlName="tin">
                        </div>

                        <!-- Guarantor -->
                        <div class="control-box w-100">
                            <label for="guarantor">Guarantor</label>
                            <input id="guarantor" formControlName="guarantor">
                        </div>

                        <!-- Third Party Control Person -->
                        <div class="control-box w-100">
                            <label for="thirdPartyControlPerson">Third Party Control Person</label>
                            <input id="thirdPartyControlPerson" formControlName="thirdPartyControlPerson">
                        </div>

                        <!-- Evaluation Agent -->
                        <div class="control-box w-100">
                            <label for="evaluationAgent">Evaluation Agent</label>
                            <input id="evaluationAgent" formControlName="evaluationAgent">
                        </div>

                        <!-- Oral Disclosure For Pre-Trade Mark Election -->
                        <div class="control-box w-100">
                            <label for="oralDisclosureForPreTradeMarkElection">
                                Oral Disclosure For Pre-Trade Mark Election
                            </label>
                            <input
                                id="oralDisclosureForPreTradeMarkElection"
                                formControlName="oralDisclosureForPreTradeMarkElection"
                            >
                        </div>

                        <!-- QIR -->
                        <div class="control-box w-100">
                            <label for="qir">QIR</label>
                            <input id="qir" formControlName="qir">
                        </div>

                        <!-- Fiduciary -->
                        <div class="control-box w-100">
                            <label for="fiduciary">Fiduciary</label>
                            <input id="fiduciary" formControlName="fiduciary">
                        </div>
                    </app-regulatory-block-with-header>

                    <!-- Address for Complaints Block -->
                    <app-regulatory-block-with-header blockHeader="Address for Complaints">
                        <!-- Address 1 -->
                        <div class="control-box w-100">
                            <label for="address1">Address 1</label>
                            <input id="address1" formControlName="address1">
                        </div>

                        <!-- Address 2 -->
                        <div class="control-box w-100">
                            <label for="address2">Address 2</label>
                            <input id="address2" formControlName="address2">
                        </div>

                        <!-- City -->
                        <div class="control-box w-100">
                            <label for="city">City</label>
                            <input id="city" formControlName="city">
                        </div>

                        <!-- State -->
                        <div class="control-box w-100">
                            <label for="state">State</label>
                            <input id="state" formControlName="state">
                        </div>

                        <!-- Zip -->
                        <div class="control-box w-100">
                            <label for="zip">Zip</label>
                            <input id="zip" formControlName="zip">
                        </div>

                        <!-- Country -->
                        <div class="control-box w-100">
                            <label for="country">Country</label>
                            <input id="country" formControlName="country">
                        </div>

                        <!-- Email -->
                        <div class="control-box w-100">
                            <label for="email">Email</label>
                            <input id="email" formControlName="email">
                        </div>

                        <!-- Fax -->
                        <div class="control-box w-100">
                            <label for="fax">Fax</label>
                            <input id="fax" formControlName="fax">
                        </div>

                        <!-- Work Phone -->
                        <div class="control-box w-100">
                            <label for="workPhone">Work Phone</label>
                            <input id="workPhone" formControlName="workPhone">
                        </div>

                        <!-- DF Delivery Email -->
                        <div class="control-box w-100">
                            <label for="dfDeliveryEmail">DF Delivery Email</label>
                            <input id="dfDeliveryEmail" formControlName="dfDeliveryEmail">
                        </div>
                    </app-regulatory-block-with-header>

                    <!-- PCA Principal Status Representations and Elections Block -->
                    <app-regulatory-block-with-header blockHeader="PCA Principal Status Representations and Elections">
                        <!-- Are You A Federal Entity? -->
                        <div class="control-box w-100">
                            <label for="isAFederalEntity">Are You A Federal Entity?</label>
                            <input id="isAFederalEntity" formControlName="isAFederalEntity">
                        </div>

                        <!-- PCA Principal Type -->
                        <div class="control-box w-100">
                            <label for="pcaPrincipalType">PCA Principal Type</label>
                            <input id="pcaPrincipalType" formControlName="pcaPrincipalType">
                        </div>

                        <!-- Commodity Pool -->
                        <div class="control-box w-100">
                            <label for="commodityPool">Commodity Pool</label>
                            <input id="commodityPool" formControlName="commodityPool">
                        </div>
                    </app-regulatory-block-with-header>
                </div>

                <!-- II Column -->
                <div class="d-flex flex-column data-column">
                    <!-- Are You An Active Fund -->
                    <div class="control-box w-100">
                        <label for="isAnActiveFund">Are You An Active Fund</label>
                        <input id="isAnActiveFund" formControlName="isAnActiveFund">
                    </div>

                    <!-- Are You A Category 2 Entity -->
                    <div class="control-box w-100">
                        <label for="isCategory2Entity">Are You A Category 2 Entity</label>
                        <input id="isCategory2Entity" formControlName="isCategory2Entity">
                    </div>

                    <!-- Swap Dealer -->
                    <div class="control-box w-100">
                        <label for="swapDealer">Swap Dealer</label>
                        <input id="swapDealer" formControlName="swapDealer">
                    </div>

                    <!-- SBSD -->
                    <div class="control-box w-100">
                        <label for="sbsd">SBSD</label>
                        <input id="sbsd" formControlName="sbsd">
                    </div>

                    <!-- MSP -->
                    <div class="control-box w-100">
                        <label for="msp">MSP</label>
                        <input id="msp" formControlName="msp">
                    </div>

                    <!-- SB-MSP -->
                    <div class="control-box w-100">
                        <label for="sbMsp">SB-MSP</label>
                        <input id="sbMsp" formControlName="sbMsp">
                    </div>

                    <!-- Financial Institution -->
                    <div class="control-box w-100">
                        <label for="financialInstitution">Financial Institution</label>
                        <input id="financialInstitution" formControlName="financialInstitution">
                    </div>

                    <!-- Eligible Insurance Company -->
                    <div class="control-box w-100">
                        <label for="eligibleInsuranceCompany">Eligible Insurance Company</label>
                        <input id="eligibleInsuranceCompany" formControlName="eligibleInsuranceCompany">
                    </div>

                    <!-- Eligible Investment Company -->
                    <div class="control-box w-100">
                        <label for="eligibleInvestmentCompany">Eligible Investment Company</label>
                        <input id="eligibleInvestmentCompany" formControlName="eligibleInvestmentCompany">
                    </div>

                    <!-- Eligible Commodity Pool -->
                    <div class="control-box w-100">
                        <label for="eligibleCommodityPool">Eligible Commodity Pool</label>
                        <input id="eligibleCommodityPool" formControlName="eligibleCommodityPool">
                    </div>

                    <!-- Large Entity -->
                    <div class="control-box w-100">
                        <label for="largeEntity">Large Entity</label>
                        <input id="largeEntity" formControlName="largeEntity">
                    </div>

                    <!-- Hedging Entity -->
                    <div class="control-box w-100">
                        <label for="hedgingEntity">Hedging Entity</label>
                        <input id="hedgingEntity" formControlName="hedgingEntity">
                    </div>

                    <!-- Employee Benefits Plan -->
                    <div class="control-box w-100">
                        <label for="employeeBenefitsPlan">Employee Benefits Plan</label>
                        <input id="employeeBenefitsPlan" formControlName="employeeBenefitsPlan">
                    </div>

                    <!-- Eligible Gov Entity -->
                    <div class="control-box w-100">
                        <label for="eligibleGovEntity">Eligible Gov Entity</label>
                        <input id="eligibleGovEntity" formControlName="eligibleGovEntity">
                    </div>

                    <!-- Broker Or Dealer Is A Non- Natural Person -->
                    <div class="control-box w-100">
                        <label for="brokerOrDealerIsNonNaturalPerson">Broker Or Dealer Is A Non-Natural Person</label>
                        <input id="brokerOrDealerIsNonNaturalPerson" formControlName="brokerOrDealerIsNonNaturalPerson">
                    </div>

                    <!-- Broker Or Dealer Is A Natural Person -->
                    <div class="control-box w-100">
                        <label for="brokerOrDealerIsNaturalPerson">Broker Or Dealer Is A Natural Person</label>
                        <input id="brokerOrDealerIsNaturalPerson" formControlName="brokerOrDealerIsNaturalPerson">
                    </div>

                    <!-- AP Of Registered Broker -->
                    <div class="control-box w-100">
                        <label for="apOfRegisteredBroker">AP Of Registered Broker</label>
                        <input id="apOfRegisteredBroker" formControlName="apOfRegisteredBroker">
                    </div>

                    <!-- Investment Bank Holding Co -->
                    <div class="control-box w-100">
                        <label for="investmentBankHoldingCo">Investment Bank Holding Co</label>
                        <input id="investmentBankHoldingCo" formControlName="investmentBankHoldingCo">
                    </div>

                    <!-- FCM Is A Natural Person -->
                    <div class="control-box w-100">
                        <label for="fcmIsNaturalPerson">FCM Is A Natural Person</label>
                        <input id="fcmIsNaturalPerson" formControlName="fcmIsNaturalPerson">
                    </div>

                    <!-- FCM Is A Non-Natural Person -->
                    <div class="control-box w-100">
                        <label for="fcmIsNonNaturalPerson">FCM Is A Non-Natural Person</label>
                        <input id="fcmIsNonNaturalPerson" formControlName="fcmIsNonNaturalPerson">
                    </div>

                    <!-- Floor Broker Or Floor Trader -->
                    <div class="control-box w-100">
                        <label for="floorBrokerOrFloorTrader">Floor Broker Or Floor Trader</label>
                        <input id="floorBrokerOrFloorTrader" formControlName="floorBrokerOrFloorTrader">
                    </div>

                    <!-- Eligible Individual -->
                    <div class="control-box w-100">
                        <label for="eligibleIndividual">Eligible Individual</label>
                        <input id="eligibleIndividual" formControlName="eligibleIndividual">
                    </div>

                    <!-- Hedging Individual ECP -->
                    <div class="control-box w-100">
                        <label for="hedgingIndividualEcp">Hedging Individual ECP</label>
                        <input id="hedgingIndividualEcp" formControlName="hedgingIndividualEcp">
                    </div>

                    <!-- Alternative Commodity Pool -->
                    <div class="control-box w-100">
                        <label for="alternativeCommodityPool">Alternative Commodity Pool</label>
                        <input id="alternativeCommodityPool" formControlName="alternativeCommodityPool">
                    </div>

                    <!-- Other Eligible Person -->
                    <div class="control-box w-100">
                        <label for="otherEligiblePerson">Other Eligible Person</label>
                        <input id="otherEligiblePerson" formControlName="otherEligiblePerson">
                    </div>

                    <!-- Other Name -->
                    <div class="control-box w-100">
                        <label for="otherName">Other Name</label>
                        <input id="otherName" formControlName="otherName">
                    </div>

                    <!-- Comments -->
                    <div class="control-box w-100">
                        <label for="comments">Comments</label>
                        <input id="comments" formControlName="comments">
                    </div>

                    <!-- ECP Warning -->
                    <div class="control-box w-100">
                        <label for="ecpWarning">ECP Warning</label>
                        <input id="ecpWarning" formControlName="ecpWarning">
                    </div>

                    <!-- Specified FX Commodity Pool -->
                    <div class="control-box w-100">
                        <label for="specifiedFxCommodityPool">Specified FX Commodity Pool</label>
                        <input id="specifiedFxCommodityPool" formControlName="specifiedFxCommodityPool">
                    </div>

                    <!-- All ECP Fxcommodity Pool -->
                    <div class="control-box w-100">
                        <label for="allEcpFxcommodityPool">All ECP Fxcommodity Pool</label>
                        <input id="allEcpFxcommodityPool" formControlName="allEcpFxcommodityPool">
                    </div>

                    <!-- Non-US FX Commodity Pool -->
                    <div class="control-box w-100">
                        <label for="nonUsFxCommodityPool">Non-US FX Commodity Pool</label>
                        <input id="nonUsFxCommodityPool" formControlName="nonUsFxCommodityPool">
                    </div>

                    <!-- Schedule 1 -->
                    <div class="control-box w-100">
                        <label for="schedule1">Schedule 1</label>
                        <input id="schedule1" formControlName="schedule1">
                    </div>

                    <!-- Schedule 2 -->
                    <div class="control-box w-100">
                        <label for="schedule2">Schedule 2</label>
                        <input id="schedule2" formControlName="schedule2">
                    </div>

                    <!-- Schedule 3 -->
                    <div class="control-box w-100">
                        <label for="schedule3">Schedule 3</label>
                        <input id="schedule3" formControlName="schedule3">
                    </div>

                    <!-- Schedule 4 -->
                    <div class="control-box w-100">
                        <label for="schedule4">Schedule 4</label>
                        <input id="schedule4" formControlName="schedule4">
                    </div>

                    <!-- Schedule 5 -->
                    <div class="control-box w-100">
                        <label for="schedule5">Schedule 5</label>
                        <input id="schedule5" formControlName="schedule5">
                    </div>

                    <!-- Schedule 6 -->
                    <div class="control-box w-100">
                        <label for="schedule6">Schedule 6</label>
                        <input id="schedule6" formControlName="schedule6">
                    </div>

                    <!-- Financial Entity -->
                    <div class="control-box w-100">
                        <label for="financialEntity">Financial Entity</label>
                        <input id="financialEntity" formControlName="financialEntity">
                    </div>

                    <!-- Special Entity Type -->
                    <div class="control-box w-100">
                        <label for="specialEntityType">Special Entity Type</label>
                        <input id="specialEntityType" formControlName="specialEntityType">
                    </div>

                    <!-- DF Terms Agreement Elections -->
                    <div class="control-box w-100">
                        <label for="dfTermsAgreementElections">DF Terms Agreement Elections</label>
                        <input id="dfTermsAgreementElections" formControlName="dfTermsAgreementElections">
                    </div>
                </div>

                <!-- III Column -->
                <div class="d-flex flex-column data-column">
                    <!-- DF Term Agreement Contact Information Block -->
                    <app-regulatory-block-with-header [isHeaderMargin]="false"
                                                      blockHeader="DF Term Agreement Contact Information">
                        <!-- Name -->
                        <div class="control-box w-100">
                            <label for="dfTermAgreementName">Name</label>
                            <input id="dfTermAgreementName" formControlName="dfTermAgreementName">
                        </div>

                        <!-- Address 1 -->
                        <div class="control-box w-100">
                            <label for="dfTermAgreementAddress1">Address 1</label>
                            <input id="dfTermAgreementAddress1" formControlName="dfTermAgreementAddress1">
                        </div>

                        <!-- Address 2 -->
                        <div class="control-box w-100">
                            <label for="dfTermAgreementAddress2">Address 2</label>
                            <input id="dfTermAgreementAddress2" formControlName="dfTermAgreementAddress2">
                        </div>

                        <!-- City -->
                        <div class="control-box w-100">
                            <label for="dfTermAgreementCity">City</label>
                            <input id="dfTermAgreementCity" formControlName="dfTermAgreementCity">
                        </div>

                        <!-- State -->
                        <div class="control-box w-100">
                            <label for="dfTermAgreementAddress2">State</label>
                            <input id="dfTermAgreementState" formControlName="dfTermAgreementState">
                        </div>

                        <!-- Country -->
                        <div class="control-box w-100">
                            <label for="dfTermAgreementCountry">Country</label>
                            <input id="dfTermAgreementCountry" formControlName="dfTermAgreementCountry">
                        </div>

                        <!-- Zip -->
                        <div class="control-box w-100">
                            <label for="dfTermAgreementZip">Zip</label>
                            <input id="dfTermAgreementZip" formControlName="dfTermAgreementZip">
                        </div>

                        <!-- Email -->
                        <div class="control-box w-100">
                            <label for="dfTermAgreementEmail">Email</label>
                            <input id="dfTermAgreementEmail" formControlName="dfTermAgreementEmail">
                        </div>

                        <!-- Fax -->
                        <div class="control-box w-100">
                            <label for="dfTermAgreementFax">Fax</label>
                            <input id="dfTermAgreementFax" formControlName="dfTermAgreementFax">
                        </div>

                        <!-- Work Phone -->
                        <div class="control-box w-100">
                            <label for="dfTermAgreementWorkPhone">Work Phone</label>
                            <input id="dfTermAgreementWorkPhone" formControlName="dfTermAgreementWorkPhone">
                        </div>

                        <!-- Electronic Message System Details -->
                        <div class="control-box w-100">
                            <label for="dfTermAgreementElectronicMessageSystemDetails">Electronic Message System
                                Details</label>
                            <input
                                id="dfTermAgreementElectronicMessageSystemDetails"
                                formControlName="dfTermAgreementElectronicMessageSystemDetails"
                            >
                        </div>
                    </app-regulatory-block-with-header>
                    <!-- Signature Agreement Block -->
                    <app-regulatory-block-with-header blockHeader="Signature Agreement">
                        <!-- Specific Instructions -->
                        <div class="control-box w-100">
                            <label for="specificInstructions">Specific Instructions</label>
                            <input id="specificInstructions" formControlName="specificInstructions">
                        </div>

                        <!--  PCA Principal Signatory -->
                        <div class="control-box w-100">
                            <label for="pcaPrincipalSignatory">PCA Principal Signatory</label>
                            <input id="pcaPrincipalSignatory" formControlName="pcaPrincipalSignatory">
                        </div>

                        <!-- Title -->
                        <div class="control-box w-100">
                            <label for="title">Title</label>
                            <input id="title" formControlName="title">
                        </div>

                        <!-- Date Completed -->
                        <div class="control-box w-100">
                            <label for="dateCompleted">Date Completed</label>
                            <input
                                readonly
                                id="dateCompleted"
                                [matDatepicker]="picker"
                                class="date-picker-padding"
                                formControlName="dateCompleted"
                            >
                            <mat-datepicker-toggle [for]="picker" matIconSuffix class="custom-toggle">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                    </app-regulatory-block-with-header>

                    <!-- Election not to Receive disclosure of pre-trade market Block -->
                    <app-regulatory-block-with-header
                        blockHeader="Election not to Receive disclosure of pre-trade market">
                        <!-- Covered Forex Transaction -->
                        <div class="control-box w-100">
                            <label for="coveredForexTransaction">Covered Forex Transaction</label>
                            <input id="coveredForexTransaction" formControlName="coveredForexTransaction">
                        </div>

                        <!-- Covered Derivative Transaction -->
                        <div class="control-box w-100">
                            <label for="сoveredDerivativeTransaction">Covered Derivative Transaction</label>
                            <input id="сoveredDerivativeTransaction" formControlName="сoveredDerivativeTransaction">
                        </div>

                        <!-- Additional Pre-Trade Mark Transaction -->
                        <div class="control-box w-100">
                            <label for="additionalPreTradeMarkTransaction">Additional Pre-Trade Mark Transaction</label>
                            <input id="additionalPreTradeMarkTransaction"
                                   formControlName="additionalPreTradeMarkTransaction">
                        </div>

                        <!-- Are you a CFTC Interim Order US Person? -->
                        <div class="control-box w-100">
                            <label for="isCftcInterimOrderUsPerson">Are you a CFTC Interim Order US
                                Person?</label>
                            <input id="isCftcInterimOrderUsPerson" formControlName="isCftcInterimOrderUsPerson">
                        </div>

                        <!-- Notes -->
                        <div class="control-box w-100">
                            <label for="notes">Notes</label>
                            <input id="notes" formControlName="notes">
                        </div>

                        <!-- Match Status -->
                        <div class="control-box w-100">
                            <label for="matchStatus">Match Status</label>
                            <input id="matchStatus" formControlName="matchStatus">
                        </div>

                        <!-- My Questionnaire -->
                        <div class="control-box w-100">
                            <label for="myQuestionnaire">My Questionnaire</label>
                            <input id="myQuestionnaire" formControlName="myQuestionnaire">
                        </div>

                        <!-- Counterparty Questionnaire -->
                        <div class="control-box w-100">
                            <label for="сounterpartyQuestionnaire">Counterparty Questionnaire</label>
                            <input id="сounterpartyQuestionnaire" formControlName="сounterpartyQuestionnaire">
                        </div>
                    </app-regulatory-block-with-header>

                    <app-regulatory-block-with-header blockHeader="Top-up Protocols">
                        <!-- Dodd Frank Protocol 1.0 Adherence ID -->
                        <div class="control-box w-100">
                            <label for="doddFrankProtocol1AdherenceId">Dodd Frank Protocol 1.0 Adherence ID</label>
                            <input id="doddFrankProtocol1AdherenceId" formControlName="doddFrankProtocol1AdherenceId">
                        </div>

                        <!-- Dodd Frank Protocol 1.0 Match Date -->
                        <div class="control-box w-100">
                            <label for="doddFrankProtocol1MatchDate">Dodd Frank Protocol 1.0 Match Date</label>
                            <input id="doddFrankProtocol1MatchDate" formControlName="doddFrankProtocol1MatchDate">
                        </div>

                        <!-- Top up Share Status -->
                        <div class="control-box w-100">
                            <label for="topUpShareStatus">Top up Share Status</label>
                            <input id="topUpShareStatus" formControlName="topUpShareStatus">
                        </div>

                        <!-- Top up Protocol Adherence ID -->
                        <div class="control-box w-100">
                            <label for="topUpProtocolAdherenceId">Top up Protocol Adherence ID</label>
                            <input id="topUpProtocolAdherenceId" formControlName="topUpProtocolAdherenceId">
                        </div>

                        <!-- Protocol Name -->
                        <div class="control-box w-100">
                            <label for="protocolName">Protocol Name</label>
                            <input id="protocolName" formControlName="protocolName">
                        </div>

                        <!-- Organization Name -->
                        <div class="control-box w-100">
                            <label for="organizationName">Organization Name</label>
                            <input id="organizationName" formControlName="organizationName">
                        </div>

                        <!-- Organization LEI/CICI -->
                        <div class="control-box w-100">
                            <label for="organizationLeiCici">Organization LEI/CICI</label>
                            <input id="organizationLeiCici" formControlName="organizationLeiCici">
                        </div>

                        <!-- Accept Date -->
                        <div class="control-box w-100">
                            <label for="acceptDate">Accept Date</label>
                            <input
                                readonly
                                id="acceptDate"
                                [matDatepicker]="picker"
                                class="date-picker-padding"
                                formControlName="acceptDate"
                            >
                            <mat-datepicker-toggle [for]="picker" matIconSuffix class="custom-toggle">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>

                        <!-- Adherence Type -->
                        <div class="control-box w-100">
                            <label for="adherenceType">Adherence Type</label>
                            <input id="adherenceType" formControlName="adherenceType">
                        </div>

                        <!-- Covered SBS Entity -->
                        <div class="control-box w-100">
                            <label for="coveredSbsEntity">Covered SBS Entity</label>
                            <input id="coveredSbsEntity" formControlName="coveredSbsEntity">
                        </div>

                        <!-- Adherence Letter -->
                        <div class="control-box w-100">
                            <label for="adherenceLetter">Adherence Letter</label>
                            <input id="adherenceLetter" formControlName="adherenceLetter">
                        </div>
                    </app-regulatory-block-with-header>
                </div>
            </form>

            <!-- Save Block -->
            <div class="d-flex w-100 justify-end">
                <div class="d-flex save-wrapper">
                    <div class="d-flex flex-col">
                <span class="last-updated">
                    <span class="updated-on-label">
                        Last Updated On:
                    </span>
                    <span class="updated-on-value">
                        22 Jul 2023
                    </span>
                </span>
                        <div class="updated-by">
                            by Wiam Lou
                        </div>
                    </div>

                    <button class="save-button" [disabled]="df1Form.pristine" (click)="updateDf1Data()">Save</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #noDataBlock>
        <div class="d-flex justify-content-center">
            <no-data-component></no-data-component>
        </div>
    </ng-template>
</ng-template>

