<div class="d-flex chart-wrapper">
  <div class="line-chart-wrapper">
    <canvas
      [id]="chartId"
      #lineChartCanvas
      style="min-height: 200px; max-height: 350px"
      [ngClass]="{ width1: true }"
    >
      {{ chart }}
    </canvas>
  </div>
</div>
