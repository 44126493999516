import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DropdownOptions} from "../regulatory/common/data-tabs/sdl-data/types";
import {Helper} from "../../../../../../../helpers/helper";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DocuLibraryService} from "../../../../../../../services/docu-library.service";
import {TaxDataPayload} from "./types";

@Component({
    selector: 'app-tax',
    templateUrl: './tax.component.html',
    styleUrls: ['./tax.component.css']
})
export class TaxComponent implements OnInit {
    public isLoading: boolean = false;
    public taxForm: FormGroup = new FormGroup({});
    public dataSource: TaxDataPayload | undefined;
    public dropdownData: DropdownOptions | null = null;

    get clientId(): string {
        return this.helper.getQuestionnaireClientId();
    }

    constructor(
        private helper: Helper,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private regulatoryService: DocuLibraryService,
    ) {
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.regulatoryService.fetchRegulatoryDropdownOptions().subscribe((dropDawnData) => {
            this.dropdownData = dropDawnData.Data;

            this.regulatoryService.fetchTaxData(parseInt(this.clientId)).subscribe((data) => {
                this.dataSource = data.Data;
                this.fillTaxFormData(data.Data);
                this.isLoading = false;
                this.cdr.detectChanges();
            });
        });
    }

    private fillTaxFormData(data: TaxDataPayload): void {
        this.taxForm = this.formBuilder.group({
            CountryOfIssue: [data.CountryOfIssue, Validators.required],
            Type: [data.Type, Validators.required],
            OtherTypeData: [data.OtherTypeData, Validators.required],
            DocumentType: [data.DocumentType, Validators.required],
            TaxId: [data.TaxId, Validators.required],
            Id: [data.Id || 0, Validators.required]
        });
    }

    public updateTaxData(): void {
        this.isLoading = true;
        this.regulatoryService.updateTaxData(this.taxForm.getRawValue()).subscribe(res => {
            this.isLoading = false;
            this.taxForm.markAsPristine();
            this.snackBar.open('Changes saved!', 'X', {
                duration: 1500,
                panelClass: ['success-snackbar'],
            });
        });
    }
}
