<div class="wrapper" *ngIf="parentStructure?.IsVisible">
    <div [id]="parentStructure.Id" class="d-flex flex-column custom-node">
        <div *ngIf="parentStructure.ParentId === 0" class="parent-indicator-wrapper">
            <img class="main-parent-indicator" src="../assets/icons/parent-structure.svg">
        </div>

        <!--  Node Remove Action and Selected Node indicator  -->
        <div class="node-action-block">
            <ng-container *ngIf="isLoading; else icons">
                <mat-spinner [diameter]="20"></mat-spinner>
            </ng-container>
            <ng-template #icons>
                <div class="btn-wrapper">
                    <div
                        class="action-btn"
                        *ngIf="parentStructure.ParentId !== 0 && this.selectedStructureItem?.Id === parentStructure.Id; else removeIcon"
                    >
                        <img src="../assets/icons/selected-structure-item.svg" alt="">
                    </div>
                    <ng-template #removeIcon>
                        <div *ngIf="parentStructure.ParentId !== 0" class="action-btn"
                             (click)="removeStructureAndChildren(parentStructure)">
                            <img alt="remove structure icon" src="../assets/icons/remove-structure.svg">
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </div>

        <div class="node-client-label" (click)="previewStructureItem(parentStructure)">
            {{ parentStructure.ClientName }}
        </div>

        <div class="d-flex align-center">
            <div class="client-box">
                <span class="client-type">{{ parentStructure.ClientType }}</span>
                <div class="percentage">
                    {{
                         parentStructure.ClientPercentage.includes('%')
                            ? parentStructure.ClientPercentage
                            : parentStructure.ClientPercentage === '-'
                                 ? parentStructure.ClientPercentage
                                 : parentStructure.ClientPercentage + '%'
                    }}
                </div>
            </div>
            <div class="client-type-box">
                <ng-container *ngIf="parentStructure.EntityType === 'Entity'; else individualIcon">
                    <img alt="entity type structure" class="individual-structure"
                         src="../assets/icons/entity-type-structure.svg">
                </ng-container>

                <ng-template #individualIcon>
                    <img alt="individual client type" class="individual-structure"
                         src="../assets/icons/individual-structure-type.svg">
                </ng-template>
            </div>
        </div>

        <!--  horizontal line connecting structure nodes  -->
        <hr *ngIf="parentStructure.ParentId !== 0" class="hr-styled"/>

        <!--  add new structure icon  -->
        <div (click)="handleAddNewStructureItem()" class="icon-box">
            <div class="icon-wrapper">
                <img alt="" src="../assets/icons/add-new-structure.svg">
            </div>
        </div>

        <!--  vertical line connecting structure nodes  -->
        <div *ngIf="parentStructure.ParentId !== 0" [ngStyle]="{ top: 40 - 57 + 'px'}" class="vl"></div>
    </div>

    <!-- recursive call-out of the app-custom-node component to render all the child structures -->
    <ng-container *ngFor="let child of parentStructure.ChildStructure; let i = index">
        <app-custom-node
            class="custom-border"
            [parentStructure]="child"
            [id]="'child-component-' + i"
            [ngStyle]="{
                'border-left':
                    i === parentStructure.ChildStructure!.length - 1
                ? '1px solid transparent'
                : '1px solid #6c5dd3'
            }"
        >
        </app-custom-node>
    </ng-container>
</div>
