<div *ngIf="loading" class="loader-box">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="dataSource.length === 0 && !loading">
  <div class="d-flex justify-center no-data-box-1">
    <div class="d-flex flex-column align-center">
      <img
        alt="no data"
        style="width: 100px"
        src="../../../../assets/empty.png"
      />
      <div class="d-flex justify-center no-data">No data found</div>
    </div>
  </div>
</div>

<div
  [ngStyle]="{ opacity: !loading && dataSource.length > 0.5 ? 1 : 0 }"
  class="d-flex table-container"
  style="width: 100%; padding-bottom: 1rem"
>
  <form [formGroup]="productForm">
    <table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8 w-100 products-table"
    >
      <!-- Checkbox Column -->
      <ng-container matColumnDef="checkbox">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ 'th-backdrop': addNewProductInProgress || isEditMode }"
        >
          <mat-checkbox
            [ngStyle]="{
              'pointer-events':
                isCheckboxReadonly || !isCheckAllActiveInitialReview()
                  ? 'none'
                  : 'all'
            }"
            (change)="$event ? toggleAllRows() : null"
            [checked]="isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            [ngStyle]="{
              'pointer-events':
                isCheckboxReadonly ||
                row.Id === 0 ||
                isCheckboxActiveInitialReview(row)
                  ? 'none'
                  : 'all'
            }"
            *ngIf="
              currentCaseStage === 'Outreach'
                ? !(
                    row.Status === 'Closed' &&
                    (!updatedProducts.length || !updatedProducts.includes(row))
                  )
                : currentCaseStage === 'Approval'
                ? !updatedProducts.length || !updatedProducts.includes(row)
                : row.Status !== 'Closed'
            "
            (change)="handleCheckboxChange(row)"
            [checked]="row.IsSelected"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Product ID -->
      <ng-container matColumnDef="productId">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ 'th-backdrop': addNewProductInProgress || isEditMode }"
        >
          <div class="d-flex">
            <div class="d-flex head-sort-wrapper">Product ID</div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="user-id-field">
          {{ element.Id }}
        </td>
      </ng-container>

      <!-- Product Category -->
      <ng-container matColumnDef="productCategory">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ 'th-backdrop': addNewProductInProgress || isEditMode }"
        >
          <div class="d-flex">
            <!-- filter applied indicator -->
            <span
              class="filter-icon"
              [ngStyle]="{
                'background-image': isColumnFiltered('ProductCategory')
                  ? 'url(\'../../../../assets/icons/product-filter-indicator.svg\')'
                  : null
              }"
            ></span>

            <div class="d-flex head-sort-wrapper">Product Category</div>

            <filtering-component
              [clientId]="+clientId"
              columnName="ProductCategory"
              (ApplyFilterEmitter)="handleApplyFilters($event)"
            ></filtering-component>
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="user-id-field">
          <ng-container>
            <mat-select
              (selectionChange)="
                handleEditingNodeProductCategoryChange($event, element.Id)
              "
              (click)="onSelectOpen($event)"
              disableOptionCentering
              aria-placeholder="Product Category"
              placeholder="Product Category"
              class="wiam-select wiam-select-default"
              panelClass="wiam-select-panel wiam-select-panel-singl wiam-select-panel-default"
              [value]="currentEditNode.ProductCategory"
              [formControlName]="'ProductCategory'"
              *ngIf="
                (addNewProductInProgress || isEditMode) &&
                  currentEditNode.Id === element.Id &&
                  currentEditNode.Date === element.Date &&
                  currentEditNode.Id === 0;
                else elseBlock
              "
            >
              <mat-option
                *ngFor="let category of dropdownsCommonData.ProductCategories"
                [value]="category"
              >
                <span>{{ category }}</span>
              </mat-option>
            </mat-select>
            <ng-template #elseBlock>
              <div
                class="no-wrap-value"
                [ngClass]="{
                  'px-15':
                    (addNewProductInProgress || isEditMode) &&
                    currentEditNode.Id === element.Id
                }"
              >
                {{ element.ProductCategory }}
              </div>
            </ng-template>
          </ng-container>
        </td>
      </ng-container>

      <!-- Product Type -->
      <ng-container matColumnDef="productType">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ 'th-backdrop': addNewProductInProgress || isEditMode }"
        >
          <div class="d-flex">
            <!-- filter applied indicator -->
            <span
              class="filter-icon"
              [ngStyle]="{
                'background-image': isColumnFiltered('ProductType')
                  ? 'url(\'../../../../assets/icons/product-filter-indicator.svg\')'
                  : null
              }"
            ></span>

            <div class="d-flex head-sort-wrapper">Product Type</div>

            <filtering-component
              [clientId]="+clientId"
              columnName="ProductType"
              (ApplyFilterEmitter)="handleApplyFilters($event)"
            ></filtering-component>
          </div>
        </th>
        <td mat-cell *matCellDef="let element" style="background: transparent">
          <ng-container>
            <ng-container>
              <mat-select
                (selectionChange)="
                  handleEditingNodeProductTypeChange($event, element.Id)
                "
                (click)="onSelectOpen($event)"
                disableOptionCentering
                aria-placeholder="Product Type"
                placeholder="Product Type"
                class="wiam-select wiam-select-default"
                panelClass="wiam-select-panel wiam-select-panel-singl wiam-select-panel-default"
                [formControlName]="'ProductType'"
                [value]="currentEditNode.ProductType"
                *ngIf="
                  (addNewProductInProgress || isEditMode) &&
                    currentEditNode.Id === element.Id &&
                    currentEditNode.Date === element.Date &&
                    currentEditNode.Id === 0;
                  else elseBlock
                "
              >
                <ng-container *ngIf="currentEditNode.ProductCategory">
                  <mat-option *ngFor="let type of productTypes" [value]="type">
                    <span>{{ type }}</span>
                  </mat-option>
                </ng-container>
              </mat-select>
              <ng-template #elseBlock>
                <div
                  class="no-wrap-value"
                  [ngClass]="{
                    'px-15':
                      (addNewProductInProgress || isEditMode) &&
                      currentEditNode.Id === element.Id
                  }"
                >
                  {{ element.ProductType }}
                </div>
              </ng-template>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- Booking -->
      <ng-container matColumnDef="Booking">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ 'th-backdrop': addNewProductInProgress || isEditMode }"
        >
          <div class="d-flex">
            <!-- filter applied indicator -->
            <span
              class="filter-icon"
              [ngStyle]="{
                'background-image': isColumnFiltered('Booking')
                  ? 'url(\'../../../../assets/icons/product-filter-indicator.svg\')'
                  : null
              }"
            ></span>

            <div class="d-flex head-sort-wrapper">Booking</div>

            <filtering-component
              [clientId]="+clientId"
              [columnName]="'Booking'"
              (ApplyFilterEmitter)="handleApplyFilters($event)"
            ></filtering-component>
          </div>
        </th>

        <td mat-cell *matCellDef="let element">
          <ng-container>
            <div
              *ngIf="
                (addNewProductInProgress || isEditMode) &&
                  currentEditNode.Id === element.Id &&
                  currentEditNode.Date === element.Date &&
                  currentEditNode.Id === 0;
                else elseBlock
              "
              class="wiam-select wiam-select-default wiam-autocomplete"
            >
              <input
                type="text"
                aria-placeholder="Booking"
                placeholder="Booking"
                matInput
                [matAutocomplete]="auto"
                class="autocomplete-input"
                [formControlName]="'Booking'"
                [value]="currentEditNode.Booking"
                #bookingInput
              />
              <mat-autocomplete
                autoActiveFirstOption
                #auto="matAutocomplete"
                class="wiam-select-panel wiam-select-panel-singl wiam-select-panel-default"
                (optionSelected)="
                  handleEditingNodeBookingChange($event, element.Id)
                "
              >
                <mat-option
                  *ngFor="let booking of filteredBookings | async"
                  [value]="booking"
                >
                  {{ booking }}
                </mat-option>
              </mat-autocomplete>
            </div>
            <ng-template #elseBlock>
              <div
                [ngClass]="{
                  'px-15':
                    (addNewProductInProgress || isEditMode) &&
                    currentEditNode.Id === element.Id
                }"
              >
                {{ element.Booking }}
              </div>
            </ng-template>
          </ng-container>
        </td>
      </ng-container>

      <!-- Selling -->
      <ng-container matColumnDef="selling">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ 'th-backdrop': addNewProductInProgress || isEditMode }"
        >
          <div class="d-flex">
            <!-- filter applied indicator -->
            <span
              class="filter-icon"
              [ngStyle]="{
                'background-image': isColumnFiltered('Selling')
                  ? 'url(\'../../../../assets/icons/product-filter-indicator.svg\')'
                  : null
              }"
            ></span>

            <div class="d-flex head-sort-wrapper">Selling</div>

            <filtering-component
              [clientId]="+clientId"
              columnName="Selling"
              (ApplyFilterEmitter)="handleApplyFilters($event)"
            ></filtering-component>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container>
            <div
              *ngIf="
                (addNewProductInProgress || isEditMode) &&
                  currentEditNode.Id === element.Id &&
                  currentEditNode.Date === element.Date &&
                  currentEditNode.Id === 0;
                else elseBlock
              "
              class="wiam-autocomplete wiam-select wiam-select-default"
            >
              <input
                type="text"
                aria-placeholder="Selling"
                placeholder="Selling"
                matInput
                [matAutocomplete]="auto"
                class="autocomplete-input"
                [formControlName]="'Selling'"
                [value]="currentEditNode.Selling"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #auto="matAutocomplete"
                class="wiam-select-panel wiam-select-panel-singl wiam-select-panel-default"
                (optionSelected)="
                  handleEditingNodeSellingChange($event, element.Id)
                "
              >
                <mat-option
                  *ngFor="let selling of filteredSellings | async"
                  [value]="selling"
                >
                  {{ selling }}
                </mat-option>
              </mat-autocomplete>
            </div>
            <ng-template #elseBlock>
              <div
                [ngClass]="{
                  'px-15':
                    (addNewProductInProgress || isEditMode) &&
                    currentEditNode.Id === element.Id
                }"
              >
                {{ element.Selling }}
              </div>
            </ng-template>
          </ng-container>
        </td>
      </ng-container>

      <!-- Status -->
      <ng-container matColumnDef="status">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ 'th-backdrop': addNewProductInProgress || isEditMode }"
        >
          <div class="d-flex">
            <!-- filter applied indicator -->
            <span
              class="filter-icon"
              [ngStyle]="{
                'background-image': isColumnFiltered('Status')
                  ? 'url(\'../../../../assets/icons/product-filter-indicator.svg\')'
                  : null
              }"
            ></span>

            <div class="d-flex head-sort-wrapper">Status</div>

            <filtering-component
              [clientId]="+clientId"
              [columnName]="'Status'"
              (ApplyFilterEmitter)="handleApplyFilters($event)"
            ></filtering-component>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{ 'px-17': element.Id === 0 }"
        >
          <ng-container>
            <mat-select
              (selectionChange)="
                handleEditingNodeStatusChange($event, element.Id)
              "
              (click)="onSelectOpen($event)"
              disableOptionCentering
              aria-placeholder="Status"
              placeholder="Status"
              class="wiam-select wiam-select-default"
              panelClass="wiam-select-panel wiam-select-panel-singl wiam-select-panel-default"
              [formControlName]="'Status'"
              [value]="currentEditNode.Selling"
              *ngIf="
                (addNewProductInProgress ||
                  (isEditMode && !isApprovalComplianceOverride)) &&
                  currentEditNode.Id === element.Id &&
                  currentEditNode.Date === element.Date &&
                  element.Id !== 0 &&
                  (currentCaseStage === 'Outreach'
                    ? !(
                        element.Status === 'Closed' &&
                        (!updatedProducts.length ||
                          !updatedProducts.includes(element))
                      )
                    : currentCaseStage === 'Approval'
                    ? !(
                        element.Status === 'Closed' &&
                        (!updatedProducts.length ||
                          !updatedProducts.includes(element))
                      )
                    : element.Status !== 'Closed');
                else elseBlock
              "
            >
              <mat-option
                *ngFor="let status of getAvailableStatuses(element.Status)"
                [value]="status"
              >
                <span>{{ status }}</span>
              </mat-option>
            </mat-select>
            <ng-template #elseBlock>
              <div
                [ngClass]="{
                  'px-15':
                    (addNewProductInProgress || isEditMode) &&
                    currentEditNode.Id === element.Id
                }"
              >
                {{ element.Status }}
              </div>
            </ng-template>
          </ng-container>
        </td>
      </ng-container>

      <!-- Compliance -->
      <ng-container matColumnDef="compliance">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ 'th-backdrop': addNewProductInProgress || isEditMode }"
        >
          <div class="d-flex">
            <!-- filter applied indicator -->
            <span
              class="filter-icon"
              [ngStyle]="{
                'background-image': isColumnFiltered('Compliance')
                  ? 'url(\'../../../../assets/icons/product-filter-indicator.svg\')'
                  : null
              }"
            ></span>

            <div class="d-flex head-sort-wrapper">Compliance</div>

            <!-- filtering component -->
            <filtering-component
              [clientId]="+clientId"
              columnName="Compliance"
              (ApplyFilterEmitter)="handleApplyFilters($event)"
            ></filtering-component>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{ 'px-17': element.Id === 0 }"
        >
          <ng-container>
            <mat-select
              (selectionChange)="
                handleEditingNodeComplianceChange($event, element.Id)
              "
              (click)="onSelectOpen($event)"
              disableOptionCentering
              aria-placeholder="Compliance"
              placeholder="Compliance"
              class="wiam-select wiam-select-default"
              panelClass="wiam-select-panel wiam-select-panel-singl wiam-select-panel-default"
              [formControlName]="'Compliance'"
              [value]="currentEditNode.Compliance"
              *ngIf="
                (addNewProductInProgress ||
                  (isEditMode && isApprovalComplianceOverride)) &&
                  currentEditNode.Id === element.Id &&
                  currentEditNode.Date === element.Date &&
                  element.Id !== 0 &&
                  (currentCaseStage === 'Outreach'
                    ? !(
                        element.Status === 'Closed' &&
                        (!updatedProducts.length ||
                          !updatedProducts.includes(element))
                      )
                    : element.Status !== 'Closed');
                else elseBlock
              "
            >
              <mat-option
                *ngFor="let compliance of complianceOptions"
                [value]="compliance"
              >
                <span>{{ compliance }}</span>
              </mat-option>
            </mat-select>

            <ng-template #elseBlock>
              {{ element.Compliance }}
            </ng-template>
          </ng-container>
        </td>
      </ng-container>

      <!-- Bucket -->
      <ng-container matColumnDef="bucket">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ 'th-backdrop': addNewProductInProgress || isEditMode }"
        >
          <div class="d-flex">
            <span
              class="filter-icon"
              [ngStyle]="{
                'background-image': isColumnFiltered('Bucket')
                  ? 'url(\'../../../../assets/icons/product-filter-indicator.svg\')'
                  : null
              }"
            ></span>
            <div class="d-flex head-sort-wrapper">Bucket</div>
            <filtering-component
              [clientId]="+clientId"
              columnName="Bucket"
              (ApplyFilterEmitter)="handleApplyFilters($event)"
            ></filtering-component>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{ 'px-17': element.Id === 0 }"
        >
          <ng-container>
            <div
              *ngIf="
                (addNewProductInProgress || isEditMode) &&
                  currentEditNode.Id === element.Id &&
                  currentEditNode.Date === element.Date &&
                  element.Id !== 0;
                else elseBlock
              "
              [ngClass]="{
                'px-15':
                  (addNewProductInProgress || isEditMode) &&
                  currentEditNode.Id === element.Id
              }"
            >
              {{ currentEditNode.Bucket }}
            </div>
            <ng-template #elseBlock>
              <span class="no-wrap-value">
                {{ element.Bucket }}
              </span>
            </ng-template>
          </ng-container>
        </td>
      </ng-container>

      <!-- Date -->
      <ng-container matColumnDef="date">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ 'th-backdrop': addNewProductInProgress || isEditMode }"
        >
          <div class="d-flex">
            <div class="d-flex head-sort-wrapper">Date</div>
            <img
              alt="date-sort"
              class="date-sort-icon"
              (click)="handleSortByDate()"
              [class.rotate]="sortingDirection === 'asc'"
              src="../../../../assets/icons/product-date-sort.svg"
            />
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{ 'px-17': element.Id === 0 }"
        >
          <ng-container>
            <div
              class="paddings product-date"
              *ngIf="
                (addNewProductInProgress || isEditMode) &&
                  currentEditNode.Id === element.Id &&
                  currentEditNode.Date === element.Date;
                else elseBlock
              "
            >
              {{
                currentEditNode.Id !== 0
                  ? (currentEditNode.Date | date : "dd/MM/yyyy")
                  : (currentDate | date : "dd/MM/yyyy")
              }}
            </div>
            <ng-template #elseBlock>
              <div class="paddings product-date">
                {{ element.Date | date : "dd/MM/yyyy" }}
              </div>
            </ng-template>
          </ng-container>
        </td>
      </ng-container>

      <!-- Information -->
      <ng-container matColumnDef="information">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ 'th-backdrop': addNewProductInProgress || isEditMode }"
        >
          <div
            class="d-flex head-sort-wrapper"
            *ngIf="currentCaseStage !== 'Approval'"
          >
            Information
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'approval-padding': currentCaseStage === 'Approval'
          }"
        >
          <ng-container>
            <div *ngIf="checkIfRowInEditMode(element); else elseBlock">
              <button (click)="handleStopEditing()" class="close-product-btn">
                <mat-icon>close</mat-icon>
              </button>

              <button
                (click)="saveProductChanges(element)"
                class="save-product-btn"
                [ngClass]="{
                  disabled:
                    !currentEditNode.ProductCategory ||
                    !currentEditNode.ProductType ||
                    !currentEditNode.Booking ||
                    !currentEditNode.Selling ||
                    !currentEditNode.Status ||
                    !currentEditNode.Compliance ||
                    !currentEditNode.Bucket
                }"
              >
                <mat-icon>arrow_forward</mat-icon>
              </button>
            </div>

            <ng-template #elseBlock>
              <div
                class="d-flex"
                [ngClass]="{
                  'justify-center': currentCaseStage === 'Approval'
                }"
              >
                <button
                  class="info-eye-btn"
                  style="border-radius: 50%; border: none; background: none"
                  (click)="setProductInfo(element)"
                >
                  <div
                    class="info-eye-container"
                    *ngIf="selectedProductInfo.Id === element.Id"
                  >
                    <mat-icon>remove_red_eye</mat-icon>
                  </div>
                  <img
                    class="info-btn"
                    *ngIf="selectedProductInfo.Id !== element.Id"
                    src="../../../../assets/icons/product-info-icon.svg"
                    alt=""
                  />
                </button>
                <button
                  *ngIf="
                    currentCaseStage === 'Outreach'
                      ? !(
                          element.Status === 'Closed' &&
                          (!updatedProducts.length ||
                            !updatedProducts.includes(element))
                        ) &&
                        !(
                          currentCaseStage === 'Outreach' && !element.IsSelected
                        )
                      : currentCaseStage === 'Approval'
                      ? false
                      : element.Status !== 'Closed' &&
                        !(
                          currentCaseStage === 'Outreach' && !element.IsSelected
                        )
                  "
                  class="edit-btn"
                  style="border-radius: 50%; border: none; background: none"
                  (click)="handleEditProduct(element)"
                >
                  <img src="../../../../assets/icons/edit-btn.svg" alt="" />
                </button>
              </div>
            </ng-template>
          </ng-container>
        </td>
      </ng-container>

      <!-- 3 Dots menu column -->
      <ng-container matColumnDef="threeDotsMenu">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{ 'th-backdrop': addNewProductInProgress || isEditMode }"
        >
          <div class="d-flex head-sort-wrapper"></div>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'approval-padding': currentCaseStage === 'Approval'
          }"
        >
          <ng-container>
            <div
              [matTooltip]="
                !checkIfSelected(element.Id, element.Status) &&
                !(updatedProducts.length || productsThatShouldBeCreated.length)
                  ? 'Product must be selected'
                  : ''
              "
            >
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu"
                [disabled]="
                  !checkIfSelected(element.Id, element.Status) &&
                  !(
                    updatedProducts.length || productsThatShouldBeCreated.length
                  )
                "
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="handleEditProduct(element)">
                <span class="three-dots-menu-option">Edit</span>
              </button>

              <button
                mat-menu-item
                (click)="handleOverrideApprovalCompliance(element)"
              >
                <span class="three-dots-menu-option">Override Compliance</span>
              </button>
            </mat-menu>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{
          highlight: checkIfSelected(row.Id, row.Status),
          'th-backdrop':
            (addNewProductInProgress || isEditMode) &&
            !checkIfRowInEditMode(row),
          'editing-row': checkIfRowInEditMode(row),
          selected: checkIfSelected(row.Id, row.Status)
        }"
      ></tr>
    </table>
  </form>
</div>
