import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Helper} from "../../../../../../helpers/helper";

interface NewStructure {
    ClientAssciation: string,
    ClientId: number,
    Country: string,
    EntityName: string,
    FirstName: string,
    LastName: string,
    MiddleName: string,
    State: string,
    IsVisible: boolean,
    ParentId: number
    Id: number
}

enum TableHeaderCells {
    EntityName = 'Entity Name',
    Country = 'Country',
    State = 'Province/State',
    ClientAssciation = 'Client/Association',
    ClientId = 'CID',
    FirstName = 'First Name',
    MiddleName = 'Middle Name',
    LastName = 'Last Name',
}

@Component({
    selector: 'app-structures-search-result-table',
    templateUrl: './structures-search-result-table.component.html',
    styleUrls: ['./structures-search-result-table.component.scss']
})
export class StructuresSearchResultTableComponent implements OnInit, OnChanges {
    public displayedColumns: string[] = [];
    public rightTabOpened: boolean = false;

    public get tableHeaderCells(): Record<string, string> {
        return TableHeaderCells;
    }

    @Input() public dataSource: {
        ClientAssciation: string,
        ClientId: number,
        Country: string,
        EntityName: string,
        FirstName: string,
        LastName: string,
        MiddleName: string,
        State: string,
        IsVisible: boolean,
        isSelected?: boolean,
        ParentId: number,
        Id: number
    }[] = [];
    @Input() public selectedEntityType: string = '';
    @Input() public isLoading: boolean = false;

    constructor(private helper: Helper) {
    }

    ngOnInit(): void {
        this.dataSource = this.dataSource.map(x => ({...x, isChecked: false}));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedEntityType) {
            this.displayedColumns = changes.selectedEntityType.currentValue === 'Entity'
                ? ['checkbox', 'EntityName', 'Country', 'State', 'ClientAssciation', 'ClientId']
                : ['checkbox', 'FirstName', 'MiddleName', 'LastName', 'Country', 'State', 'ClientAssociation', 'ClientId'];
        }
    }

    public handleCheckboxChange(row: NewStructure) {
        this.helper.passNewStructureItem(row);
        this.helper.openRightTabForSelectedRow(row.Id);

        this.dataSource.map(x => {
            if (x.Id === row.Id) x.isSelected = !x.isSelected;
            else x.isSelected = false;
        });
    }
}
