import {Component, OnInit} from '@angular/core';
import {SelectedTabsIds} from "./types";

@Component({
    selector: 'app-regulatory-tabs',
    templateUrl: './regulatory-tabs.component.html',
    styleUrls: ['./regulatory-tabs.component.css']
})
export class RegulatoryTabsComponent implements OnInit {
    public navTabs: typeof SelectedTabsIds;
    public activeTab: SelectedTabsIds;

    constructor() {
        this.navTabs = SelectedTabsIds;
        this.activeTab = this.navTabs.SDL
    }

    ngOnInit(): void {
    }


    public handleChangeActiveTab(newActiveTab: SelectedTabsIds): void {
        this.activeTab = newActiveTab;
    }
}
