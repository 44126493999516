import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { ApprovalsComponent } from './components/approvals/approvals.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { CompleteComponent } from './components/complete/complete.component';
import { DataValidationComponent } from './components/data-validation/data-validation.component';
import { DescopeComponent } from './components/descope/descope.component';
import { EscalationComponent } from './components/escalation/escalation.component';
import { HomeComponent } from './components/home/home.component';
import { InitialReviewComponent } from './components/initial-review/initial-review.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/login/logout.component';
import { NameScreeningComponent } from './components/name-screening/name-screening.component';
import { OutreachComponent } from './components/outreach/outreach.component';
import { UserAccessManagementComponent } from './components/user-access-managament/user-access.component';
import { AuthGuard } from './helpers/AuthGuard';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { QuestionnaireContainerComponent } from './components/questionnaire/questionnaire-container/questionnaire-container.component';
import { ForgotPassword } from './components/login/forgot-password/forgot-password.component';
import { ArchiveComponent } from './components/questionnaire/document-requirement/archive/archive.component';
import { DocuLibraryContainerComponent } from './components/docu-library/docu-library-container.component';
import { DocuLibraryProfileTab } from './components/docu-library/components/tabs/profile/profile.component';
import { DocuLibraryProductsAndAccounts } from './components/docu-library/components/tabs/products-and-accounts/products-and-accounts.component';
import { DocuLibraryRegulatorySubTab } from './components/docu-library/components/tabs/profile/sub-tabs/regulatory/regulatory.component';
import { DocuLibraryExemptionTab } from './components/docu-library/components/tabs/exemptions/exemptions.component';
import { DocuLibraryRiskTab } from './components/docu-library/components/tabs/risks/risks.component';
import {
  DocuLibDocRequirementsComponent
} from "./components/docu-library/components/tabs/docu-lib-doc-requirements/docu-lib-doc-requirements.component";
import {
  DocuLibProductsComponent
} from "./components/docu-library/components/tabs/products-and-accounts/sub-tabs/docu-lib-products/docu-lib-products.component";
import {
  DocuLibAccountsComponent
} from "./components/docu-library/components/tabs/products-and-accounts/sub-tabs/docu-lib-accounts/docu-lib-accounts.component";
import {
  StructuresContainerComponent
} from "./components/structures/structures-container/structures-container.component";
import {
    GeneralInfoTabContainerComponent
} from "./components/docu-library/components/tabs/profile/sub-tabs/general-info/general-info-tab-container/general-info-tab-container.component";
import {TaxComponent} from "./components/docu-library/components/tabs/profile/sub-tabs/tax/tax.component";
import {ContactComponent} from "./components/docu-library/components/tabs/profile/sub-tabs/contact/contact.component";

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'logout', component: LogoutComponent, pathMatch: 'full' },
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'initial-review',
    component: InitialReviewComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'outreach',
    component: OutreachComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'nameScreening',
    component: NameScreeningComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'escalation',
    component: EscalationComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'data-validation',
    component: DataValidationComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'approvals',
    component: ApprovalsComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'complete',
    component: CompleteComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'descope',
    component: DescopeComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'analytics',
    component: AnalyticsComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'user-access-management',
    component: UserAccessManagementComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    data: {
      isSetPass: false,
    },
  },
  {
    path: 'set-password/:token/:email',
    component: ChangePasswordComponent,
    data: {
      isSetPass: true,
    },
  },
  {
    path: 'forgot-password',
    component: ForgotPassword,
    data: {
      isSetPass: true,
    },
  },
  {
    path: 'user-profile',
    component: UserProfileComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'questionnaire/:id',
    component: QuestionnaireContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'archive',
        component: ArchiveComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'docu-library',
    component: DocuLibraryContainerComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'docu-library/:id',
    component: DocuLibraryContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: DocuLibraryProfileTab,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'regulatory',
            component: DocuLibraryRegulatorySubTab,
            canActivate: [AuthGuard],
          },
          {
            path: 'general-information',
            component: GeneralInfoTabContainerComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'tax',
            component: TaxComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'contact',
            component: ContactComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'products-and-accounts',
        component: DocuLibraryProductsAndAccounts,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'products',
            component: DocuLibProductsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'accounts',
            component: DocuLibAccountsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'exemption',
        component: DocuLibraryExemptionTab,
        canActivate: [AuthGuard],
      },
      {
        path: 'risk',
        component: DocuLibraryRiskTab,
        canActivate: [AuthGuard],
      },
      {
        path: 'structure',
        component: DocuLibraryProfileTab,
        canActivate: [AuthGuard],
      },
      {
        path: 'doc-requirements',
        component: DocuLibDocRequirementsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'docu-lib-archive',
            component: ArchiveComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },
  {
    path: 'structures/:id',
    component: StructuresContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'archive',
        component: ArchiveComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
