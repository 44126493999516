import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Helper} from "../../../../../../../../../../helpers/helper";
import {DocuLibraryService} from "../../../../../../../../../../services/docu-library.service";
import {Df1DataPayload} from "./types";
import {DropdownOptions} from "../sdl-data/types";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-df1-data',
    templateUrl: './df1-data.component.html',
    styleUrls: ['./df1-data.component.css']
})
export class Df1DataComponent implements OnInit {
    public isLoading: boolean = false;
    public dataSource: Df1DataPayload | undefined;
    public df1Form: FormGroup = new FormGroup({});

    get clientId(): string {
        return this.helper.getQuestionnaireClientId();
    }

    constructor(
        private helper: Helper,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private regulatoryService: DocuLibraryService,
    ) { }

    ngOnInit(): void {
        this.isLoading = true;

        this.regulatoryService.fetchRegulatoryDf1Data(parseInt(this.clientId)).subscribe(res => {
            this.dataSource = res.Data;
            this.fillDf1FormData(this.dataSource);
            this.isLoading = false;
            this.cdr.detectChanges();
        });
    }

    private fillDf1FormData(data: Df1DataPayload): void {
        this.df1Form = this.formBuilder.group({
            institution: [data.Institution, Validators.required],
            institutionActingAs: [data.IsTheInstitutionActingAsAnInvestmentAdvisorAssetManagerOrOtherAgent, Validators.required],
            nfaId: [data.NFAID, Validators.required],
            principalBusiness: [data.PrincipalBusiness, Validators.required],
            tin: [data.TIN, Validators.required],
            guarantor: [data.Guarantor, Validators.required],
            thirdPartyControlPerson: [data.ThirdPartyControlPerson, Validators.required],
            evaluationAgent: [data.EvaluationAgent, Validators.required],
            oralDisclosureForPreTradeMarkElection: [data.OralDisclosureForPreTradeMarkElection, Validators.required],
            qir: [data.QIR, Validators.required],
            fiduciary: [data.Fiduciary, Validators.required],
            address1: [data.ComplaintsAdress1, Validators.required],
            address2: [data.ComplaintsAddress2, Validators.required],
            city: [data.ComplaintsCity, Validators.required],
            state: [data.ComplaintsState, Validators.required],
            zip: [data.ComplaintsZip, Validators.required],
            country: [data.ComplaintsCountry, Validators.required],
            email: [data.ComplaintsEmail, Validators.required],
            fax: [data.ComplaintsFax, Validators.required],
            workPhone: [data.ComplaintsWorkPhone, Validators.required],
            dfDeliveryEmail: [data.ComplaintsDFDeliveryEmail, Validators.required],
            isAFederalEntity: [data.AreYouAFederalEntity, Validators.required],
            pcaPrincipalType: [data.PCAPrincipalType, Validators.required],
            commodityPool: [data.CommodityPool, Validators.required],
            isAnActiveFund: [data.AreYouAnActiveFund, Validators.required],
            isCategory2Entity: [data.AreYouACategory2Entity, Validators.required],
            swapDealer: [data.SwapDealer, Validators.required],
            sbsd: [data.SBSD, Validators.required],
            msp: [data.MSP, Validators.required],
            sbMsp: [data.SBMSP, Validators.required],
            financialInstitution: [data.FinancialInstitution, Validators.required],
            eligibleInsuranceCompany: [data.EligibleInsuranceCompany, Validators.required],
            eligibleInvestmentCompany: [data.EligibleInvestmentCompany, Validators.required],
            eligibleCommodityPool: [data.EligibleCommidityPool, Validators.required],
            largeEntity: [data.LargeEntity, Validators.required],
            hedgingEntity: [data.HedgingEntity, Validators.required],
            employeeBenefitsPlan: [data.EmployeeBenefitsPlan, Validators.required],
            eligibleGovEntity: [data.EligibleGovEntity, Validators.required],
            brokerOrDealerIsNonNaturalPerson: [data.BrokerOrDealerIsANonNaturalPerson, Validators.required],
            brokerOrDealerIsNaturalPerson: [data.BrokerOrDealerIsANaturalPerson, Validators.required],
            apOfRegisteredBroker: [data.APOfRegisteredBroker, Validators.required],
            investmentBankHoldingCo: [data.InvestmentBankHoldingCo, Validators.required],
            fcmIsNaturalPerson: [data.FCMIsANaturalPerson, Validators.required],
            fcmIsNonNaturalPerson: [data.FCMIsANonNaturalPerson, Validators.required],
            floorBrokerOrFloorTrader: [data.FloorBrokerOrFloorTrader, Validators.required],
            eligibleIndividual: [data.EligibleIndividual, Validators.required],
            hedgingIndividualEcp: [data.HedgingIndividualECP, Validators.required],
            alternativeCommodityPool: [data.AlternativeCommodityPool, Validators.required],
            otherEligiblePerson: [data.OtherEligiblePerson, Validators.required],
            otherName: [data.OtherName, Validators.required],
            comments: [data.Comments, Validators.required],
            ecpWarning: [data.ECPWarning, Validators.required],
            specifiedFxCommodityPool: [data.SpecifiedFXCommodityPool, Validators.required],
            allEcpFxcommodityPool: [data.AllECPFXCommodityPool, Validators.required],
            nonUsFxCommodityPool: [data.NonUSFXCommodityPool, Validators.required],
            schedule1: [data.Schedule1, Validators.required],
            schedule2: [data.Schedule2, Validators.required],
            schedule3: [data.Schedule3, Validators.required],
            schedule4: [data.Schedule4, Validators.required],
            schedule5: [data.Schedule5, Validators.required],
            schedule6: [data.Schedule6, Validators.required],
            financialEntity: [data.FinacialEntity, Validators.required],
            specialEntityType: [data.SpecialEntityType, Validators.required],
            dfTermsAgreementElections: [data.DFTermsAgreementElections, Validators.required],
            dfTermAgreementName: [data.Name, Validators.required],
            dfTermAgreementAddress1: [data.Address1, Validators.required],
            dfTermAgreementAddress2: [data.Address2, Validators.required],
            dfTermAgreementCity: [data.City, Validators.required],
            dfTermAgreementState: [data.State, Validators.required],
            dfTermAgreementCountry: [data.Country, Validators.required],
            dfTermAgreementZip: [data.Zip, Validators.required],
            dfTermAgreementEmail: [data.Email, Validators.required],
            dfTermAgreementFax: [data.Fax, Validators.required],
            dfTermAgreementWorkPhone: [data.WorkPhone, Validators.required],
            dfTermAgreementElectronicMessageSystemDetails: [data.ElectronicMessageSystemDetails, Validators.required],
            specificInstructions: [data.SpecificInstructions, Validators.required],
            pcaPrincipalSignatory: [data.PCAPrincipalSignatory, Validators.required],
            title: [data.Title, Validators.required],
            dateCompleted: [data.DateCompleted, Validators.required],
            coveredForexTransaction: [data.CoveredForexTransaction, Validators.required],
            сoveredDerivativeTransaction: [data.CoveredDerivativeTransaction, Validators.required],
            additionalPreTradeMarkTransaction: [data.AdditionalPreTradeMarkTransaction, Validators.required],
            isCftcInterimOrderUsPerson: [data.AreYouACFTCInterimOrderUSPerson, Validators.required],
            notes: [data.Notes, Validators.required],
            matchStatus: [data.MatchStatus, Validators.required],
            myQuestionnaire: [data.MyQuestionnaire, Validators.required],
            сounterpartyQuestionnaire: [data.CounterpartyQuestionnaire, Validators.required],
            doddFrankProtocol1AdherenceId: [data.DoddFrankProtocol1AdherenceID, Validators.required],
            doddFrankProtocol1MatchDate: [data.DoddFrankProtocol1MatchDate, Validators.required],
            topUpShareStatus: [data.TopUpShareStatus, Validators.required],
            topUpProtocolAdherenceId: [data.TopUpProtocolAdherenceID, Validators.required],
            protocolName: [data.ProtocolName, Validators.required],
            organizationName: [data.OrganizationName, Validators.required],
            organizationLeiCici: [data.OrganizationLEICICI, Validators.required],
            acceptDate: [data.AcceptDate, Validators.required],
            adherenceType: [data.AdherenceType, Validators.required],
            coveredSbsEntity: [data.CoveredSBSEntity, Validators.required],
            adherenceLetter: [data.AdherenceLetter, Validators.required],
        });
    }

    public updateDf1Data(): void {
        this.isLoading = true;

        if (this.dataSource) {
            this.regulatoryService.updateRegulatoryDf1Data({
                Id: this.dataSource.Id,
                ClientId: this.dataSource.ClientId,
                Institution: this.df1Form.controls['institution'].value,
                IsTheInstitutionActingAsAnInvestmentAdvisorAssetManagerOrOtherAgent:
                    this.df1Form.controls['institutionActingAs'].value,
                NFAID: this.df1Form.controls['nfaId'].value,
                PrincipalBusiness: this.df1Form.controls['principalBusiness'].value,
                TIN: this.df1Form.controls['tin'].value,
                Guarantor: this.df1Form.controls['guarantor'].value,
                ThirdPartyControlPerson: this.df1Form.controls['thirdPartyControlPerson'].value,
                EvaluationAgent: this.df1Form.controls['evaluationAgent'].value,
                OralDisclosureForPreTradeMarkElection: this.df1Form.controls['oralDisclosureForPreTradeMarkElection'].value,
                QIR: this.df1Form.controls['qir'].value,
                Fiduciary: this.df1Form.controls['fiduciary'].value,
                ComplaintsAdress1: this.df1Form.controls['address1'].value,
                ComplaintsAddress2: this.df1Form.controls['address2'].value,
                ComplaintsCity: this.df1Form.controls['city'].value,
                ComplaintsState: this.df1Form.controls['state'].value,
                ComplaintsZip: this.df1Form.controls['zip'].value,
                ComplaintsCountry: this.df1Form.controls['country'].value,
                ComplaintsEmail: this.df1Form.controls['email'].value,
                ComplaintsFax: this.df1Form.controls['fax'].value,
                ComplaintsWorkPhone: this.df1Form.controls['workPhone'].value,
                ComplaintsDFDeliveryEmail: this.df1Form.controls['dfDeliveryEmail'].value,
                AreYouAFederalEntity: this.df1Form.controls['isAFederalEntity'].value,
                PCAPrincipalType: this.df1Form.controls['pcaPrincipalType'].value,
                CommodityPool: this.df1Form.controls['commodityPool'].value,
                AreYouAnActiveFund: this.df1Form.controls['isAnActiveFund'].value,
                AreYouACategory2Entity: this.df1Form.controls['isCategory2Entity'].value,
                SwapDealer: this.df1Form.controls['swapDealer'].value,
                SBSD: this.df1Form.controls['sbsd'].value,
                MSP: this.df1Form.controls['msp'].value,
                SBMSP: this.df1Form.controls['sbMsp'].value,
                FinancialInstitution: this.df1Form.controls['financialInstitution'].value,
                EligibleInsuranceCompany: this.df1Form.controls['eligibleInsuranceCompany'].value,
                EligibleInvestmentCompany: this.df1Form.controls['eligibleInvestmentCompany'].value,
                EligibleCommidityPool: this.df1Form.controls['eligibleCommodityPool'].value,
                LargeEntity: this.df1Form.controls['largeEntity'].value,
                HedgingEntity: this.df1Form.controls['hedgingEntity'].value,
                EmployeeBenefitsPlan: this.df1Form.controls['employeeBenefitsPlan'].value,
                EligibleGovEntity: this.df1Form.controls['eligibleGovEntity'].value,
                BrokerOrDealerIsANonNaturalPerson: this.df1Form.controls['brokerOrDealerIsNonNaturalPerson'].value,
                BrokerOrDealerIsANaturalPerson: this.df1Form.controls['brokerOrDealerIsNaturalPerson'].value,
                APOfRegisteredBroker: this.df1Form.controls['apOfRegisteredBroker'].value,
                InvestmentBankHoldingCo: this.df1Form.controls['investmentBankHoldingCo'].value,
                FCMIsANaturalPerson: this.df1Form.controls['fcmIsNaturalPerson'].value,
                FCMIsANonNaturalPerson: this.df1Form.controls['fcmIsNonNaturalPerson'].value,
                FloorBrokerOrFloorTrader: this.df1Form.controls['floorBrokerOrFloorTrader'].value,
                EligibleIndividual: this.df1Form.controls['eligibleIndividual'].value,
                HedgingIndividualECP: this.df1Form.controls['hedgingIndividualEcp'].value,
                AlternativeCommodityPool: this.df1Form.controls['alternativeCommodityPool'].value,
                OtherEligiblePerson: this.df1Form.controls['otherEligiblePerson'].value,
                OtherName: this.df1Form.controls['otherName'].value,
                Comments: this.df1Form.controls['comments'].value,
                ECPWarning: this.df1Form.controls['ecpWarning'].value,
                SpecifiedFXCommodityPool: this.df1Form.controls['specifiedFxCommodityPool'].value,
                AllECPFXCommodityPool: this.df1Form.controls['allEcpFxcommodityPool'].value,
                NonUSFXCommodityPool: this.df1Form.controls['nonUsFxCommodityPool'].value,
                Schedule1: this.df1Form.controls['schedule1'].value,
                Schedule2: this.df1Form.controls['schedule2'].value,
                Schedule3: this.df1Form.controls['schedule3'].value,
                Schedule4: this.df1Form.controls['schedule4'].value,
                Schedule5: this.df1Form.controls['schedule5'].value,
                Schedule6: this.df1Form.controls['schedule6'].value,
                FinacialEntity: this.df1Form.controls['financialEntity'].value,
                SpecialEntityType: this.df1Form.controls['specialEntityType'].value,
                DFTermsAgreementElections: this.df1Form.controls['dfTermsAgreementElections'].value,
                Name: this.df1Form.controls['dfTermAgreementName'].value,
                Address1: this.df1Form.controls['dfTermAgreementAddress1'].value,
                Address2: this.df1Form.controls['dfTermAgreementAddress2'].value,
                City: this.df1Form.controls['dfTermAgreementCity'].value,
                State: this.df1Form.controls['dfTermAgreementState'].value,
                Country: this.df1Form.controls['dfTermAgreementCountry'].value,
                Zip: this.df1Form.controls['dfTermAgreementZip'].value,
                Email: this.df1Form.controls['dfTermAgreementEmail'].value,
                Fax: this.df1Form.controls['dfTermAgreementFax'].value,
                WorkPhone: this.df1Form.controls['dfTermAgreementWorkPhone'].value,
                ElectronicMessageSystemDetails: this.df1Form.controls['dfTermAgreementElectronicMessageSystemDetails'].value,
                SpecificInstructions: this.df1Form.controls['specificInstructions'].value,
                PCAPrincipalSignatory: this.df1Form.controls['pcaPrincipalSignatory'].value,
                Title: this.df1Form.controls['title'].value,
                DateCompleted: this.df1Form.controls['dateCompleted'].value,
                CoveredForexTransaction: this.df1Form.controls['coveredForexTransaction'].value,
                CoveredDerivativeTransaction: this.df1Form.controls['сoveredDerivativeTransaction'].value,
                AdditionalPreTradeMarkTransaction: this.df1Form.controls['additionalPreTradeMarkTransaction'].value,
                AreYouACFTCInterimOrderUSPerson: this.df1Form.controls['isCftcInterimOrderUsPerson'].value,
                Notes: this.df1Form.controls['notes'].value,
                MatchStatus: this.df1Form.controls['matchStatus'].value,
                MyQuestionnaire: this.df1Form.controls['myQuestionnaire'].value,
                CounterpartyQuestionnaire: this.df1Form.controls['сounterpartyQuestionnaire'].value,
                DoddFrankProtocol1AdherenceID: this.df1Form.controls['doddFrankProtocol1AdherenceId'].value,
                DoddFrankProtocol1MatchDate: this.df1Form.controls['doddFrankProtocol1MatchDate'].value,
                TopUpShareStatus: this.df1Form.controls['topUpShareStatus'].value,
                TopUpProtocolAdherenceID: this.df1Form.controls['topUpProtocolAdherenceId'].value,
                ProtocolName: this.df1Form.controls['protocolName'].value,
                OrganizationName: this.df1Form.controls['organizationName'].value,
                OrganizationLEICICI: this.df1Form.controls['organizationLeiCici'].value,
                AcceptDate: this.df1Form.controls['acceptDate'].value,
                AdherenceType: this.df1Form.controls['adherenceType'].value,
                CoveredSBSEntity: this.df1Form.controls['coveredSbsEntity'].value,
                AdherenceLetter: this.df1Form.controls['adherenceLetter'].value,
            }).subscribe(res => {
                this.isLoading = false;
                this.df1Form.markAsPristine();
                this.snackBar.open('Changes saved!', 'X', {
                    duration: 1500,
                    panelClass: ['success-snackbar'],
                });
                this.cdr.detectChanges();
            });
        }
    }
}
