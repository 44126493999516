export class LeftMenuModel {
  DashboardCount: number = 0;
  InitialReviewCount: number = 0;
  OutreachCount: number = 0;
  NameScreeningCount: number = 0;
  EscalationCount: number = 0;
  ApprovalsCount: number = 0;
  CompleteCount: number = 0;
  DescopeCount: number = 0;
  TeamName: string = '';
  TeamStatusList: TeamModel[] = [];
  DataValidationCount: number = 0;
  IsNudge: boolean = false;
  ProfileUrl: string = '';
}

export class UserStatus {
  UserName: string = '';
  UserStatus: string = '';
  UserIconUrl: string = '';
  Status: string = '';
  UserId: string = '';
}

export class TeamModel {
  TeamId: number = 0;
  TeamName: string = '';
  IsOpened: boolean = false;
  Users: UserStatus[] = [];
  TeamImgUrl: string = '';
  TeamIconUrl: string = '';
}
