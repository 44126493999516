import { FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { InputTypesByLength } from '../../tab-data-table/types';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { AllocationTypes, SelectedOption } from './types';
import { Helper } from 'src/app/helpers/helper';

interface AutocompleteOptions {
  Name: string;
  Id: number;
}

@Component({
  selector: 'autocomplete-multiselect-component',
  templateUrl: './autocomplete-multiselect.component.html',
  styleUrls: ['./autocomplete-multiselect.component.scss'],
})
export class QuestionnaireAutocompleteMultiselect
  implements OnInit, AfterViewInit, AfterViewChecked, OnChanges
{
  public selectedOptions: SelectedOption[] = [];
  public selectedOptionsResult: SelectedOption[] = [];
  public searchText: string = '';
  public autocompleteControlWidth: number = 0;

  public currentAllocationMethod: AllocationTypes = AllocationTypes.perCountry;
  public controlValue: string = '';
  public dataModel: Record<
    number,
    { Name: string; Value: number; Id: number }
  > = {
    0: {
      Name: '',
      Value: 0,
      Id: 0,
    },
  };

  constructor(private helper: Helper) {}

  @Input() public regions: AutocompleteOptions[] = [];
  @Input() public countries: AutocompleteOptions[] = [];
  @Input() public inputLabel: string = '';
  @Input() public placeholder: string = '';
  @Input() public autocompleteOptions: AutocompleteOptions[] = [];
  @Input() public inputSize: InputTypesByLength = InputTypesByLength.smallInput;
  @Input() public control: FormControl = new FormControl('');
  @Input() public isAutocompleteSearchable: boolean = false;

  @Output() public removeBtnClick = new EventEmitter();

  public autocompleteControlHeight: number = 0;

  @ViewChild(MatMenuTrigger) autocomplete: MatMenuTrigger | undefined;
  @ViewChild('autocompleteControl') autocompleteControl: ElementRef =
    new ElementRef(null);
  // @ViewChild('autocomplete') control: ElementRef = new ElementRef(null);

  @ViewChild('menuBox') menuBox!: ElementRef;

  get isOptionSelected(): boolean {
    return !!this.selectedOptions.length;
  }

  ngOnInit(): void {
    this.selectedOptionsResult = this.control.value || [];

    this.helper.onQuestionnaireMultiselectChange().subscribe(() => {
      // setting all the multiselect values to null for all the controls
      // in case Investment/Revenue Breakdown or Source of Funds field changed

      this.control.setValue(null);
      this.selectedOptions = [];
      this.selectedOptionsResult = [];
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.countries) {
      this.autocompleteOptions = changes.countries.currentValue;

      this.mapAutocompleteOptions();
    }

    if (changes.formControl) this.helper.resetQuestionnaireMultiselectValue();

    // if (changes.currentAllocationMethod)
    this.controlValue = this.selectedOptionsResult
      .map((x) => x.Name)
      .join(', ');

    this.selectedOptions = JSON.parse(
      JSON.stringify(this.selectedOptionsResult)
    );
  }

  ngAfterViewInit(): void {
    this.menuBox.nativeElement.style.width =
      this.autocompleteControl.nativeElement.offsetWidth + 38.5 + 'px';
  }

  ngAfterViewChecked(): void {
    const element = this.autocompleteControl.nativeElement;
    if (element && element.offsetWidth !== this.autocompleteControlWidth) {
      this.autocompleteControlWidth = element.offsetWidth;
    }
  }

  public selectionMade(
    event: Event,
    option: AutocompleteOptions,
    i: number
  ): void {
    event.stopPropagation();
    const isAlreadySelected = this.selectedOptions.find(
      (x) => x.Name === option.Name
    );

    if (!isAlreadySelected)
      this.selectedOptions.push({
        Name: option.Name,
        Value: this.dataModel[i] ? this.dataModel[i].Value : 0,
        Id: this.dataModel[i] ? this.dataModel[i].Id : 0,
      });
    else {
      this.selectedOptions = this.selectedOptions.filter(
        (x, index) => index !== this.selectedOptions.indexOf(isAlreadySelected)
      );

      const dataModelArray = Object.values(this.dataModel).map((x) => {
        if (x.Name === option.Name) x.Value = 0;

        return x;
      });

      this.dataModel = dataModelArray.reduce((acc: any, item, idx) => {
        acc[idx] = {
          Name: item.Name,
          Value: item.Value,
          Id: item.Id,
        };
        return acc;
      }, {});
    }
  }

  public handleSearchSubmit(): void {
    const selectionList = JSON.stringify(this.selectedOptions);
    this.selectedOptionsResult = JSON.parse(selectionList);

    this.controlValue = this.selectedOptionsResult
      .map((x) => x.Name)
      .join(', ');
    this.control.setValue(this.selectedOptionsResult);

    this.autocomplete?.closeMenu();
    this.searchText = '';
  }

  public changeAllocationMethod(event: Event): void {
    event.stopPropagation();

    this.autocompleteOptions =
      this.currentAllocationMethod === AllocationTypes.perCountry
        ? this.regions
        : this.countries;

    this.mapAutocompleteOptions();

    this.currentAllocationMethod =
      this.currentAllocationMethod === AllocationTypes.perCountry
        ? AllocationTypes.perRegion
        : AllocationTypes.perCountry;
  }

  public checkIfSelected(option: AutocompleteOptions): boolean {
    return this.selectedOptions.some((x) => x.Name === option.Name);
  }

  public onPercentageInputChange(
    option: AutocompleteOptions,
    index: number,
    event: any
  ): void {
    this.dataModel[index].Value = +event.target.value;

    this.selectedOptions = this.selectedOptions.map((x) => {
      if (x.Name === option.Name) {
        x.Value = this.dataModel[index].Value;
      }
      return x;
    });
  }

  public isPercentagesValid(): boolean {
    const sum = this.selectedOptions
      .filter(Boolean)
      .reduce((partialSum, a) => partialSum + (a ? +a.Value : 0), 0);

    return sum <= 100;
  }

  public isControlValid(i: number): boolean {
    return this.dataModel[i].Value > 100;
  }

  public getControl(i: number) {
    return this.dataModel[i];
  }

  public onPropertyChange(event: Event, i: number) {
    this.dataModel[i].Value = +(event.target as HTMLInputElement).value;
  }

  public handleSearchInputChange(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  public isDisabled() {
    return this.selectedOptions.some(
      (x) => x.Value > 100 || !this.isPercentagesValid()
    );
  }

  public mapAutocompleteOptions() {
    this.autocompleteOptions.map((x, index) => {
      this.dataModel[index] = {
        Id: this.control.value
          ? this.control.value.find((el: any) => el.Name === x.Name)?.Id || 0
          : 0,
        Name: x.Name,
        Value: this.control.value
          ? this.control.value.find((el: any) => el.Name === x.Name)?.Value || 0
          : 0,
      };
    });
  }

  public handleRemoveOption(selectedOption: {
    Name: string;
    Value: number;
    Id: number;
  }): void {
    const indexToBeExcluded = this.selectedOptionsResult.findIndex(
      (x) => x.Name === selectedOption.Name
    );

    const dataModelArray = Object.values(this.dataModel).map((x) => {
      if (x.Name === selectedOption.Name) x.Value = 0;

      return x;
    });

    this.dataModel = dataModelArray.reduce((acc: any, item, idx) => {
      acc[idx] = {
        Name: item.Name,
        Value: item.Value,
        Id: item.Id,
      };
      return acc;
    }, {});

    this.selectedOptionsResult = JSON.parse(
      JSON.stringify(
        this.selectedOptionsResult.filter((x, i) => i !== indexToBeExcluded)
      )
    );

    this.controlValue = this.selectedOptionsResult
      .map((x) => x.Name)
      .join(', ');

    this.selectedOptions = JSON.parse(
      JSON.stringify(this.selectedOptionsResult)
    );

    this.removeBtnClick.emit(this.selectedOptionsResult);
  }
}
