<div class="d-flex flex-column tabs-wrapper">
    <div class="d-flex tabs-nav-header">
        <div class="nav-box">
            <!-- SDL -->
            <div
                class="nav-item"
                [class.active]="activeTab === navTabs.SDL"
                (click)="handleChangeActiveTab(navTabs.SDL)"
            >
                {{ navTabs.SDL }}
            </div>

            <!-- DF1 -->
            <div
                class="nav-item"
                [class.active]="activeTab === navTabs.DF1"
                (click)="handleChangeActiveTab(navTabs.DF1)"
            >
                {{ navTabs.DF1 }}
            </div>

            <!-- DF2 -->
            <div
                class="nav-item"
                [class.active]="activeTab === navTabs.DF2"
                (click)="handleChangeActiveTab(navTabs.DF2)"
            >
                {{ navTabs.DF2 }}
            </div>

            <!-- EMIR -->
            <div
                class="nav-item"
                [class.active]="activeTab === navTabs.EMIR"
                (click)="handleChangeActiveTab(navTabs.EMIR)"
            >
                {{ navTabs.EMIR }}
            </div>

            <!-- CCCL -->
            <div
                class="nav-item"
                [class.active]="activeTab === navTabs.CCCL"
                (click)="handleChangeActiveTab(navTabs.CCCL)"
            >
                {{ navTabs.CCCL }}
            </div>
        </div>
    </div>

    <div class="d-flex active-tab-content">
        <ng-container [ngSwitch]="activeTab">
            <!-- SDL -->
            <ng-container *ngSwitchCase="navTabs.SDL">
                <app-sdl-data class="w-100"></app-sdl-data>
            </ng-container>

            <!-- DF1 -->
            <ng-container *ngSwitchCase="navTabs.DF1">
                <app-df1-data class="w-100"></app-df1-data>
            </ng-container>

            <!-- DF2 -->
            <ng-container *ngSwitchCase="navTabs.DF2">
                <app-df2-data class="w-100"></app-df2-data>
            </ng-container>

            <!-- EMIR -->
            <ng-container *ngSwitchCase="navTabs.EMIR">
                <app-emir-data class="w-100"></app-emir-data>
            </ng-container>

            <!-- CCCL -->
            <ng-container *ngSwitchCase="navTabs.CCCL">
                <app-cccl-data class="w-100"></app-cccl-data>
            </ng-container>
        </ng-container>
    </div>
</div>
