import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Helper } from './helper';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private helper: Helper) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    let url: string = state.url;
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (this.helper.isAuthenticated()) { return true; }

    // Navigate to the login page with extras
    if (!this.helper.isAuthenticated()){
      this.helper.setIsAuth(false);
      this.router.navigate(['/login']);
    }
    else {
      var redirectUrl = this.helper.getRedirectUrl();
      if(redirectUrl?.length > 0) {
        this.router.navigate([redirectUrl]);
      }
      return true;
    }

    // Store the attempted URL for redirecting
    this.helper.setRedirectUrl(url);


    return false;
  }

}
