import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from './types';

@Component({
  selector: 'validation-modal',
  templateUrl: './validation-popup.component.html',
  styleUrls: ['./validation-popup.component.scss'],
})
export class ApproveValidationPopup implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      displayData: Product[];
      approvalConditionPassed: boolean;
    }
  ) {}

  ngOnInit(): void {}

  public loading: boolean = false;

  public handleRemoveArchiveDocument() {}
}
