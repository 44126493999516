import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';

interface ExemptionData {
  Area: string;
  AreaId: number;
  ClientId: number;
  Comment: string;
  ExemptionCategoryIds: number[];
  ExemptionNameIds: number[];
  ExemptionOverrideId: number;
  Id: number;
  OverrideExemptionCategoryId: number;
}

@Component({
  selector: 'exemptions',
  templateUrl: 'exemptions.component.html',
  styleUrls: ['exemptions.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'wiam-select-overlay' },
    },
  ],
})
export class ExemptionsComponent implements OnInit {
  dropdownReqData!: any;
  initialExemptions!: any;
  dropdownsData: any = [];

  @Input() isLoading: boolean = false;
  @Input() clientId!: number;

  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();

  public getExemptionNameDisplayValue(index: number) {
    if (this.dropdownReqData) {
      const exemptionCategoryIds = this.forms[index].value.ExemptionNameIds;

      const displayNames = this.dropdownReqData.Exemptions.filter((x: any) =>
        exemptionCategoryIds.includes(x.Id)
      ).map((x: any) => x.Name);

      return displayNames.length ? displayNames : [];
    }
  }

  public getExemptionCategoryDisplayValue(index: number) {
    if (this.dropdownReqData) {
      const exemptionCategoryIds = this.forms[index].value.ExemptionCategoryIds;

      const categoryObjects = this.dropdownReqData.Exemptions.filter(
        (x: any) => x.ExemptionCategories.length
      )
        .map((x: any) => x.ExemptionCategories)
        .flat()
        .filter((x: any) => exemptionCategoryIds.includes(x.Id));

      const displayNames = categoryObjects.map((x: any) => x.Name);

      return displayNames.length ? displayNames : null;
    }
  }

  isSubmitEnabled: boolean = true;
  forms: FormGroup[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private questionaireService: QuestionnaireService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.questionaireService.getExemptionDropdowns().subscribe((res: any) => {
      this.dropdownReqData = res.Data;
      this.questionaireService
        .getClientExemption(this.clientId)
        .subscribe((response) => {
          response.Data.forEach((item: ExemptionData) => {
            const form = this.createForm(item, res);
            this.forms.push(form);
          });

          this.isLoading = false;
        });
    });
  }

  createForm(item: ExemptionData, res: any): FormGroup {
    return this.formBuilder.group({
      Id: [item.Id],
      ClientId: [item.ClientId],
      Area: [item.Area],
      AreaId: [item.AreaId],
      ExemptionNameIds: [item.ExemptionNameIds],
      ExemptionCategoryIds: [item.ExemptionCategoryIds],
      ExemptionOverrideId: [item.ExemptionOverrideId],
      OverrideExemptionCategoryId: [item.OverrideExemptionCategoryId],
      Comment: [item.Comment],

      exemptionOverride: res.Data?.ExemptionOverrides.find(
        (v: any) => v.Id === item.ExemptionOverrideId
      )?.Name,

      overrideExemptionCategory: res.Data?.ExemptionOverrides?.find(
        (v: any) => v.Id === item.ExemptionOverrideId
      )?.OverrideExemptionCategories?.find(
        (category: any) => category.Id === item.OverrideExemptionCategoryId
      )?.Name,
      comment: item.Comment,
    });
  }

  getExemptionOverridesForForm(form: any) {
    return this.dropdownReqData.ExemptionOverrides.filter(
      (v: any) => v.AreaId === form.value.AreaId
    ).map((val: any) => val.Name);
  }

  getOverrideExemptionCategoriesForForm(form: any) {
    return this.dropdownReqData?.ExemptionOverrides.find(
      (x: any) =>
        x.Area.Name === form.value.Area &&
        x.Name === form.value.exemptionOverride
    )?.OverrideExemptionCategories.map((val: any) => val.Name);
  }

  isSaveEnabled(): boolean {
    return this.forms.some((f) => f.dirty);
  }

  markFormsAsAPristine(): void {
    this.forms.forEach((form: any) => {
      form.markAsPristine();
    });
  }

  saveExemptions() {
    this.isLoading = true;

    this.onSave.emit(
      this.forms
        .filter((x) => x.dirty)
        .map((x) => ({
          Id: x.value.Id,
          ClientId: x.value.ClientId,
          AreaId: x.value.AreaId,
          Area: null,
          ExemptionNameIds: x.value.ExemptionNameIds,
          ExemptionCategoryIds: x.value.ExemptionCategoryIds,
          ExemptionOverrideId: x.value.ExemptionOverrideId,
          OverrideExemptionCategoryId: x.value.OverrideExemptionCategoryId,
          Comment: x.value.Comment,
        }))
    );
    this.isSubmitEnabled = true;
  }

  submitExemptions() {
    this.onSubmit.emit();
  }

  handleExemptionOverride(selectedOption: string, form: FormGroup) {
    if (form.value.selectedExemptionCategory === selectedOption) {
      form.controls['ExemptionOverrideId'].setValue(null);
      form.value.selectedExemptionCategory = '';
      form.controls['OverrideExemptionCategoryId'].setValue(null);
      form.value.selectedExemptionOverride = '';
      form.controls['Comment'].setValue(null);

      return;
    }

    const exemptionOverride = this.dropdownReqData.ExemptionOverrides.find(
      (x: any) => x.AreaId === form.value.AreaId && x.Name === selectedOption
    );

    form.controls['ExemptionOverrideId'].setValue(exemptionOverride.Id);
    form.value.selectedExemptionCategory = selectedOption;

    this.forms = this.forms.map((x) => {
      if (x.value.Area === form.value.Area) {
        x.markAsDirty();
      }

      return x;
    });
  }

  handleExemptionOverrideCategory(selectedOption: string, form: FormGroup) {
    if (form.value.selectedExemptionCategory === selectedOption) {
      form.controls['OverrideExemptionCategoryId'].setValue(null);
      form.value.selectedExemptionOverride = '';
      form.controls['Comment'].setValue(null);

      return;
    }

    const selectedExemptionOverride =
      this.dropdownReqData.ExemptionOverrides.find(
        (x: any) => x.Id === form.value.ExemptionOverrideId
      );
    const selectedExemptionOverrideCategory =
      selectedExemptionOverride.OverrideExemptionCategories.find(
        (x: any) => x.Name === selectedOption
      );

    form.controls['OverrideExemptionCategoryId'].setValue(
      selectedExemptionOverrideCategory.Id
    );

    this.forms = this.forms.map((x) => {
      if (x.value.Area === form.value.Area) {
        x.markAsDirty();
      }

      return x;
    });
  }

  isVisible(region: string): boolean {
    const supportedRegions = ['United States', 'Canada', 'Europe', 'Australia'];

    return supportedRegions.includes(region);
  }
}
