
<div class="h-full">
  <form class="product-info-form" [formGroup]="productInfoForm">
    <div class="control-wrapper">
      <label>Requestor</label>
      <input class="primary-input" id="Requestor" formControlName="Requestor" readonly />
    </div>
    <div class="control-wrapper">
      <label>Date Requested</label>
      <div class="primary-input">
        {{ productInfoForm.controls['DateRequested'].value ? (productInfoForm.controls['DateRequested'].value | date : "dd MMM yyyy") : '-' }}
      </div>
    </div>
    <div class="control-wrapper">
      <label>Approver</label>
      <input class="primary-input" id="Approver" formControlName="Approver" readonly/>
    </div>
    <div class="control-wrapper">
      <label>Date Approved</label>
      <div class="primary-input">
        {{ productInfoForm.controls['DateApproved'].value ? (productInfoForm.controls['DateApproved'].value| date : "dd MMM yyyy") : '-' }}
      </div>
    </div>
    <div class="control-wrapper">
      <label>Antecedent Status</label>
      <input class="primary-input" id="AntecedentStatus" formControlName="AntecedentStatus" readonly/>
    </div>
    <div class="control-wrapper">
      <label>Date Status Change</label> 
      <div class="primary-input">
      {{ productInfoForm.controls['DateStatusChange'].value ? (productInfoForm.controls['DateStatusChange'].value | date : "dd MMM yyyy") : '-' }}
      </div>
    </div>
    <div class="control-wrapper">
      <label>Anticipated Activity</label>
      <textarea class="primary-textarea" 
        formControlName="AnticipatedActivity" 
        [readonly]="!productInfoForm.controls['AnticipatedActivity'].enabled" 
        (change)="handleInfoChanges()"
        placeholder="Anticipated Activity..." 
        rows="3"></textarea>
      <button *ngIf="productData && productData.Status !== 'Closed'" class="edit-textarea-btn" (click)="switchControlReadOnly('AnticipatedActivity')">
        <img *ngIf="!productInfoForm.controls['AnticipatedActivity'].enabled" src="../../../../assets/icons/pencil-outline.svg" alt="" />
        <img *ngIf="productInfoForm.controls['AnticipatedActivity'].enabled" src="../../../../assets/icons/lock-outline.svg" alt="" />
      </button>
    </div>
    <div class="control-wrapper">
      <label>Source System</label>
      <textarea 
        class="primary-textarea" 
        formControlName="SourceSystem"  
        [readonly]="!productInfoForm.controls['SourceSystem'].enabled"
        (change)="handleInfoChanges()"
        placeholder="Source System..." 
        rows="3"></textarea>
      <button *ngIf="productData && productData.Status !== 'Closed'" class="edit-textarea-btn" (click)="switchControlReadOnly('SourceSystem')">
        <img *ngIf="!productInfoForm.controls['SourceSystem'].enabled" src="../../../../assets/icons/pencil-outline.svg" alt="" />
        <img *ngIf="productInfoForm.controls['SourceSystem'].enabled" src="../../../../assets/icons/lock-outline.svg" alt="" />
      </button>
    </div>
    <div class="control-wrapper">
      <label>Comment</label>
      <textarea
        class="primary-textarea" 
        formControlName="Comment" 
        [readonly]="!productInfoForm.controls['Comment'].enabled"
        (change)="handleInfoChanges()" 
        placeholder="Comment..." 
        rows="3"></textarea>
      <button *ngIf="productData && productData.Status !== 'Closed'" class="edit-textarea-btn" (click)="switchControlReadOnly('Comment')">
        <img *ngIf="!productInfoForm.controls['Comment'].enabled" src="../../../../assets/icons/pencil-outline.svg" alt="" />
        <img *ngIf="productInfoForm.controls['Comment'].enabled" src="../../../../assets/icons/lock-outline.svg" alt="" />
      </button>
    </div>
    <div class="control-wrapper">
      <label>Intended Use Of Account</label>
      <textarea 
        class="primary-textarea" 
        formControlName="IntendedUseOfAccount" 
        [readonly]="!productInfoForm.controls['IntendedUseOfAccount'].enabled"
        (change)="handleInfoChanges()"
        placeholder="Intended Use Of Account..." 
        rows="3"></textarea>
      <button *ngIf="productData && productData.Status !== 'Closed'" class="edit-textarea-btn" (click)="switchControlReadOnly('IntendedUseOfAccount')">
        <img *ngIf="!productInfoForm.controls['IntendedUseOfAccount'].enabled" src="../../../../assets/icons/pencil-outline.svg" alt="" />
        <img *ngIf="productInfoForm.controls['IntendedUseOfAccount'].enabled" src="../../../../assets/icons/lock-outline.svg" alt="" />
      </button>
    </div>
    <div class="control-wrapper">
      <label>Limit</label>
      <textarea 
        class="primary-textarea" 
        formControlName="Limit" 
        [readonly]="!productInfoForm.controls['Limit'].enabled"
        (change)="handleInfoChanges()"
        placeholder="Limit..." 
        rows="3"></textarea>
      <button *ngIf="productData && productData.Status !== 'Closed'" class="edit-textarea-btn" (click)="switchControlReadOnly('Limit')">
        <img *ngIf="!productInfoForm.controls['Limit'].enabled" src="../../../../assets/icons/pencil-outline.svg" alt="" />
        <img *ngIf="productInfoForm.controls['Limit'].enabled" src="../../../../assets/icons/lock-outline.svg" alt="" />
      </button>
    </div>
    <div class="control-wrapper">
      <label>Currency</label>
      <textarea 
        class="primary-textarea" 
        formControlName="Currency" 
        [readonly]="!productInfoForm.controls['Currency'].enabled"
        (change)="handleInfoChanges()"
        placeholder="Currency..." 
        rows="3"></textarea>
      <button *ngIf="productData && productData.Status !== 'Closed'" class="edit-textarea-btn" (click)="switchControlReadOnly('Currency')">
        <img *ngIf="!productInfoForm.controls['Currency'].enabled" src="../../../../assets/icons/pencil-outline.svg" alt="" />
        <img *ngIf="productInfoForm.controls['Currency'].enabled" src="../../../../assets/icons/lock-outline.svg" alt="" />
      </button>
    </div>
  </form>
</div>