import { Component, Input, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
  selector: 'message-dialog', 
  templateUrl: 'message-dialog.component.html',
  styleUrls: ['message-dialog.component.scss']
})
export class MessageDialog {

  @Input('title') title: string = '';
  @Input('message') message: string = '';
  @Input('additionalHTML') additionalHTML: string = '';

  constructor(
    public dialogo: MatDialogRef<MessageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.message = data.message;
    this.additionalHTML = data.additionalHTML;
  }

}