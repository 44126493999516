<div class="exemptions-container">
    <!-- Risk Attributes -->
    <mat-accordion class="example-headers-align" multi>
        <ng-container *ngIf="isOnStructures; else regularView">
            <mat-expansion-panel hideToggle="true" [expanded]="true" #riskAttributes>
                <mat-expansion-panel-header>
                    <mat-panel-title> Risk Attributes</mat-panel-title>
                    <mat-panel-description>
                        <div class="expansion-icon-wrapper">
                            <mat-icon *ngIf="!riskAttributes.expanded">add</mat-icon>
                            <mat-icon *ngIf="riskAttributes.expanded">remove</mat-icon>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <form [formGroup]="riskAttributesForm">
                    <ng-container *ngFor="let item of formControls">
                        <div class="property-wrapper" *ngIf="item !== 'CountryCategoryIds'">
                            <div class="property-label col-5">{{ controls[item] }}</div>
                            <div class="property-input-wrapper col-11 d-flex">
                                {{
                                item === "CountryCategories" &&
                                riskAttributesControlValue(item).length
                                    ? riskAttributesControlValue(item).join(", ")
                                    : riskAttributesControlValue(item).Name || "-"
                                }}
                            </div>
                        </div>
                    </ng-container>
                </form>
            </mat-expansion-panel>
        </ng-container>

        <ng-template #regularView>
            <mat-expansion-panel hideToggle="true" [expanded]="true" #riskAttributes>
                <mat-expansion-panel-header>
                    <mat-panel-title> Risk Attributes</mat-panel-title>
                    <mat-panel-description>
                        <div class="expansion-icon-wrapper">
                            <mat-icon *ngIf="!riskAttributes.expanded">add</mat-icon>
                            <mat-icon *ngIf="riskAttributes.expanded">remove</mat-icon>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <form [formGroup]="riskAttributesForm">
                    <ng-container *ngFor="let item of formControls">
                        <div class="property-wrapper" *ngIf="item !== 'CountryCategoryIds'">
                            <div class="property-label col-5">{{ controls[item] }}</div>
                            <div class="property-input-wrapper col-11 d-flex">
                                <mat-select
                                    disableOptionCentering
                                    placeholder="Please Select"
                                    class="wiam-select no-events"
                                    aria-placeholder="Please Select"
                                    [value]="
                  item === 'CountryCategories' &&
                  riskAttributesControlValue(item).length
                    ? riskAttributesControlValue(item).join(', ')
                    : riskAttributesControlValue(item).Name
                "
                                    panelClass="wiam-select-panel wiam-select-panel-singl"
                                >
                                    <ng-container
                                        *ngIf="item === 'CountryCategories'; else elseTemp"
                                    >
                                        <mat-option
                                            *ngIf="riskAttributesControlValue(item)"
                                            [value]="riskAttributesControlValue(item).join(', ')"
                                        >
                    <span>
                      {{ riskCountryCategoryNames }}
                    </span>
                                        </mat-option>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="item === 'ReputationalRisk'; else elseTemp"
                                    >
                                        <mat-option
                                            *ngIf="riskAttributesForm.controls[item].value.Name"
                                            [value]="riskAttributesForm.controls[item].value"
                                        >
                    <span>
                      {{ riskAttributesForm.controls[item].value?.Name }}
                    </span>
                                        </mat-option>
                                    </ng-container>

                                    <ng-template #elseTemp>
                                        <mat-option *ngFor="let risk of risks" [value]="risk.Name">
                    <span>
                      {{ riskAttributesForm.controls[item].value.Name }}
                    </span>
                                        </mat-option>
                                    </ng-template>
                                </mat-select>

                                <div
                                    class="selected-categories d-flex"
                                    *ngIf="
                  riskCountryCategoryNames &&
                  item === 'CountryCategories' &&
                  riskCountryCategoryNames.split(', ').length > 0
                "
                                >
                                    <div
                                        class="data-block"
                                        *ngFor="
                    let category of riskAttributesControlValue(
                      'CountryCategories'
                    )
                  "
                                    >
                                        <img
                                            class="selected"
                                            alt="selected item indicator"
                                            src="../../../../assets/icons/product-filter-active-indicator.svg"
                                        />
                                        {{ category }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </form>
            </mat-expansion-panel>
        </ng-template>
    </mat-accordion>

    <!-- Potential Screening Outcome -->
    <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel
            hideToggle="true"
            [expanded]="true"
            #potentialScreeningOutcome
        >
            <mat-expansion-panel-header>
                <mat-panel-title> Potential Screening Outcome</mat-panel-title>
                <mat-panel-description>
                    <div class="expansion-icon-wrapper">
                        <mat-icon *ngIf="!potentialScreeningOutcome.expanded">add</mat-icon>
                        <mat-icon *ngIf="potentialScreeningOutcome.expanded">
                            remove
                        </mat-icon>
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <form [formGroup]="potentialScreeningOutcomeForm">
                <!-- PEP -->
                <div class="property-wrapper">
                    <div class="property-label col-5">PEP</div>
                    <div class="property-input-wrapper col-11">
                        <mat-select
                            style="width: 400px"
                            disableOptionCentering
                            aria-placeholder="Please Select"
                            placeholder="Please Select"
                            class="wiam-select"
                            (selectionChange)="
                onControlChange($event, 'Pep', 'potential-screening-outcome')
              "
                            formControlName="Pep"
                            [value]="
                potentialScreeningOutcomeForm.controls['Pep'].value?.Name ||
                null
              "
                            panelClass="wiam-select-panel wiam-select-panel-singl"
                        >
                            <mat-option
                                *ngFor="let pep of PEPs"
                                [value]="pep.Name"
                                (click)="handleSelectPepOption(pep)"
                            >
                                <span>{{ pep.Name }}</span>
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <!-- PEP DESCRIPTION -->
                <div class="property-wrapper">
                    <div class="property-label col-5">PEP Description</div>
                    <div class="property-input-wrapper col-11">
                        <input
                            class="primary-input"
                            placeholder="Enter text here..."
                            formControlName="PepDescription"
                            [value]="
                potentialScreeningOutcomeForm.controls['PepDescription'].value
              "
                        />
                        <div class="arrow-box">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </div>
                    </div>
                </div>

                <!-- Adverse Information -->
                <div class="property-wrapper">
                    <div class="property-label col-5">Adverse Information</div>
                    <div class="property-input-wrapper col-11">
                        <mat-select
                            disableOptionCentering
                            aria-placeholder="Please Select"
                            placeholder="Please Select"
                            class="wiam-select"
                            formControlName="AdverseInformation"
                            (selectionChange)="
                onControlChange(
                  $event,
                  'AdverseInformation',
                  'potential-screening-outcome'
                )
              "
                            [value]="
                potentialScreeningOutcomeForm.controls['AdverseInformation']
                  .value?.Name
              "
                            panelClass="wiam-select-panel wiam-select-panel-singl"
                        >
                            <mat-option
                                *ngFor="let adverseInfo of adverseInformation"
                                [value]="adverseInfo.Name"
                                (click)="handleSelectAdverseInfoOption(adverseInfo)"
                            >
                                <span>{{ adverseInfo.Name }}</span>
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <!-- Negative Information Detail -->
                <div class="property-wrapper">
                    <div class="property-label col-5">Negative Information Detail</div>
                    <div class="property-input-wrapper col-11">
                        <input
                            class="primary-input"
                            placeholder="Enter text here..."
                            formControlName="NegativeInformationDetail"
                            [value]="
                potentialScreeningOutcomeForm.controls[
                  'NegativeInformationDetail'
                ].value
              "
                        />
                        <div class="arrow-box">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </div>
                    </div>
                </div>
            </form>
        </mat-expansion-panel>
    </mat-accordion>

    <!-- Potential Risk -->
    <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel hideToggle="true" [expanded]="true" #potentialRisk>
            <mat-expansion-panel-header>
                <mat-panel-title> Potential Risk</mat-panel-title>
                <mat-panel-description>
                    <div class="expansion-icon-wrapper">
                        <mat-icon *ngIf="!potentialRisk.expanded">add</mat-icon>
                        <mat-icon *ngIf="potentialRisk.expanded">remove</mat-icon>
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <form [formGroup]="potentialRiskForm">
                <!-- Client Risk Score -->
                <div class="property-wrapper">
                    <div class="property-label col-5">Client Risk Score</div>
                    <div class="property-input-wrapper col-11">
                        <mat-select
                            disableOptionCentering
                            placeholder="Please Select"
                            class="wiam-select no-events"
                            aria-placeholder="Please Select"
                            formControlName="ClientRiskScore"
                            panelClass="wiam-select-panel wiam-select-panel-singl"
                            [value]="potentialRiskForm.controls['ClientRiskScore'].value.Name"
                        >
                            <mat-option *ngFor="let risk of risks" [value]="risk.Name">
                <span>
                  {{ risk.Name }}
                </span>
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <!-- Override Client Risk Score -->
                <div class="property-wrapper">
                    <div class="property-label col-5">Override Client Risk Score</div>
                    <div class="property-input-wrapper col-11">
                        <mat-select
                            disableOptionCentering
                            placeholder="Please Select"
                            class="wiam-select"
                            aria-placeholder="Please Select"
                            formControlName="OverrideClientRiskScore"
                            panelClass="wiam-select-panel wiam-select-panel-singl"
                            [value]="
                potentialRiskForm.controls['OverrideClientRiskScore'].value
                  ?.Name || null
              "
                            (selectionChange)="
                onControlChange(
                  $event,
                  'OverrideClientRiskScore',
                  'potential-risk'
                )
              "
                        >
                            <mat-option
                                *ngFor="let risk of risks"
                                [value]="risk.Name"
                                (click)="handleSelectOverrideRiscScoreOption(risk)"
                            >
                                <span>{{ risk.Name }}</span>
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <!-- Comment -->
                <div class="property-wrapper">
                    <div class="property-label col-5">Comment</div>
                    <div class="property-input-wrapper col-11">
                        <input
                            class="primary-input"
                            placeholder="Enter text here..."
                            formControlName="Comment"
                            [value]="potentialRiskForm.controls['Comment'].value"
                        />
                        <div class="arrow-box">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </div>
                    </div>
                </div>
            </form>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div class="action-buttons">
    <mat-spinner [diameter]="35" *ngIf="isLoading"></mat-spinner>
    <button class="save" (click)="handleSave()" [disabled]="isSaveDisabled">
        Save
    </button>
    <button class="submit" (click)="handleSubmit()" [disabled]="isSubmitDisabled">
        Submit
    </button>
</div>
