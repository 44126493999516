<div class="d-flex w-100 justify-center align-center spinner-box" *ngIf="isLoading; else dataBlock">
    <mat-spinner [diameter]="70"></mat-spinner>
</div>

<ng-template #dataBlock>
    <ng-container *ngIf="true; else noDataBlock">
        <div class="d-flex w-100 tab-three-column-box">
            <app-contact-data-table class="w-100 h-full"></app-contact-data-table>
        </div>
    </ng-container>

    <ng-template #noDataBlock>
        <div class="d-flex justify-content-center">
            <no-data-component></no-data-component>
        </div>
    </ng-template>
</ng-template>

