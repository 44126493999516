import {Component} from '@angular/core';

@Component({
  selector: 'no-data-component',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
})
export class NoDataComponent {




}
