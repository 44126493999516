<div
  class="container-tab"
  [ngStyle]="{ 'overflow-y': isLoading ? 'hidden' : 'auto' }"
>
  <questionnaire-tab-table
    class="w-100"
    [isLoading]="isLoading"
    [questionnaireData]="questionnaireData"
    (initDataPopulated)="handleDataPopulated()"
    (isSaveDisabled)="handleIsSaveDisabled($event)"
    (isSubmitDisabled)="handleIsSubmitDisabled($event)"
    (isSectionOpened)="handleQuestionnaireOpened()"
    (isSectionCollapsed)="handleQuestionnaireCollapsed()"
  ></questionnaire-tab-table>
  <div class="action-buttons">
    <mat-spinner [diameter]="35" *ngIf="isSaveLoading"></mat-spinner>
    <button
      class="save"
      (click)="handleSave()"
      [disabled]="saveDisabled || sectionClosed"
    >
      Save
    </button>
    <button class="submit" (click)="handleSubmit()" [disabled]="submitDisabled">
      Submit
    </button>
  </div>
</div>
