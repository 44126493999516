<div class="client-info-policies">
  <!-- client info section -->
  <div
    class="client-info-section"
    [ngClass]="{ 'background-blur': addNewProductInProgress || isEditMode }"
  >
    <client-info-section
      [clientId]="clientId"
      [clientName]="clientName"
      [clientBucket]="clientBucket"
      [isOnArchive]="isOnArchivePage"
    ></client-info-section>
  </div>

  <policies
    *ngIf="!isOnArchivePage"
    class="policies-wrapper"
    [ngClass]="{ 'background-blur': addNewProductInProgress || isEditMode }"
    [clientId]="+clientId"
  >
  </policies>
</div>
