import {
  AfterContentChecked,
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { QuestionnaireTabTableComponent } from '../tab-data-table/questionnaire-tab-data-table.component';
import { QuestionnaireDataList, QuestionnaireTabs } from './types';
import { Helper } from 'src/app/helpers/helper';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'questionnaire-tab-container',
  templateUrl: './questionnaire-tab-container.component.html',
  styleUrls: ['./questionnaire-tab-container.component.scss'],
})
export class QuestionnaireTabContainerComponent implements OnInit {
  public saveDisabled: boolean = true;
  public submitDisabled: boolean = false;
  public questionnaireData: QuestionnaireDataList | undefined;
  public isLoading: boolean = false;
  public sectionClosed: boolean = false;

  public get isSaveLoading(): boolean {
    return this.tableComponent && this.tableComponent.isSaving ? true : false;
  }

  @Input() public clientId: number | undefined;
  @ViewChild(QuestionnaireTabTableComponent) tableComponent:
    | QuestionnaireTabTableComponent
    | undefined;

  constructor(
    private helper: Helper,
    private questionnaireService: QuestionnaireService
  ) {}

  ngOnInit(): void {
    if (this.clientId) this.fetchQuestionnaireData(this.clientId);
  }

  public fetchQuestionnaireData(clientId: number): void {
    this.isLoading = true;

    this.questionnaireService
      .fetchQuestionnaireData(clientId)
      .pipe(
        catchError(() => of({ Data: [] as unknown as QuestionnaireDataList }))
      )
      .subscribe((res) => {
        this.questionnaireData = res.Data;

        setTimeout(() => {
          this.isLoading = false;
        }, 1500);
      });
  }

  public handleSubmit(): void {
    this.helper.switchQuestionnaireTab(QuestionnaireTabs.Exemptions);
  }

  public handleSave(): void {
    if (this.tableComponent) {
      if (this.tableComponent.isNewLegalNameValid) {
        this.tableComponent.onSubmit();
      } else {
        // scroll and validate
        this.tableComponent.handleNewLegalNameInvalid();
      }
    }
  }

  public handleIsSaveDisabled(isDisabled: boolean): void {
    this.saveDisabled = isDisabled;
  }

  public handleIsSubmitDisabled(isDisabled: boolean): void {
    this.submitDisabled = isDisabled;
  }

  public handleDataPopulated() {
    this.saveDisabled = true;
    this.submitDisabled = false;
  }

  public handleQuestionnaireOpened() {
    this.sectionClosed = false;
  }

  public handleQuestionnaireCollapsed() {
    this.sectionClosed = true;
  }
}
