<div class="spinner-container" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<div class="table-wrapper" *ngIf="!loading">
  <table
    mat-table
    multiTemplateDataRows
    class="mat-elevation-z8"
    [dataSource]="dataSource"
    *ngIf="filteredData.length"
  >
    <ng-container
      matColumnDef="{{ column }}"
      *ngFor="let column of columnsToDisplay"
    >
      <ng-container [ngSwitch]="column">
        <ng-container *ngSwitchCase="'CaseStatus'">
          <th mat-header-cell *matHeaderCellDef>{{ "Case Status" }}</th>
        </ng-container>
        <ng-container *ngSwitchCase="'Total'">
          <th mat-header-cell *matHeaderCellDef>{{ "Total" }}</th>
        </ng-container>
        <ng-container *ngSwitchCase="'Daily'">
          <th mat-header-cell *matHeaderCellDef>
            <div>{{ "Daily" }}</div>
            <div>{{ "(Delta)" }}</div>
          </th>
        </ng-container>
        <ng-container *ngSwitchCase="'Monthly'">
          <th mat-header-cell *matHeaderCellDef>
            <div>{{ "Monthly" }}</div>
            <div>{{ "(Delta)" }}</div>
          </th>
        </ng-container>
        <ng-container *ngSwitchCase="'AverageAging'">
          <th mat-header-cell *matHeaderCellDef>
            {{ "Average Aging (Days)" }}
          </th>
        </ng-container>
      </ng-container>

      <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
      <td mat-cell *matCellDef="let element" [class.border]="element.Expanded">
        <ng-container [ngSwitch]="column">
          <ng-container *ngSwitchCase="'CaseStatus'">
            <!-- Not started section is not expandable -->
            <ng-container [ngSwitch]="element[column]">
              <ng-container *ngSwitchCase="'Not Started'">
                {{ element[column] }}
              </ng-container>

              <ng-container *ngSwitchDefault>
                <div class="d-flex case-status-box">
                  <div class="d-flex" style="flex-grow: 1">
                    {{ element[column] }}
                  </div>
                  <img
                    alt="indicator"
                    class="analyst-indicator"
                    [class.transform]="element.Expanded"
                    *ngIf="element.CaseStatus !== 'Total'"
                    src="./../assets/icons/table-arrow.svg"
                  />
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ element[column] === -1 ? '-' : element[column] }}
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="ExpandedDetail">
      <td
        mat-cell
        class="expand-section"
        *matCellDef="let element"
        [attr.colspan]="columnsToDisplayWithExpand.length"
      >
        <div
          class="example-element-detail"
          [@detailExpand]="element.Expanded ? 'Expanded' : 'collapsed'"
        >
          <nested-data-table
            [data]="allData"
            [opened]="element.Expanded"
            [status]="element.CaseStatus"
            [selectedAnalysts]="selectedAnalysts"
          ></nested-data-table>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
    <tr
      mat-row
      class="example-element-row"
      (click)="handleExpand(element)"
      [class.example-Expanded-row]="element.Expanded"
      [class.total-row]="element.CaseStatus === 'Total'"
      [class.not-expandable]="element.CaseStatus === 'Not Started'"
      *matRowDef="let element; columns: columnsToDisplayWithExpand"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['ExpandedDetail']"
      class="example-detail-row"
    ></tr>
  </table>
  <div class="no-data-box" *ngIf="!filteredData.length">
    <div class="img-container">
      <img class="width-80" src="./../../../../../assets/empty.png" alt="" />
    </div>
    <div class="descr-container">
      <h6 class="label">No data</h6>
      <span class="descr"
        >No result found for this particular filter setting</span
      >
    </div>
  </div>
</div>
