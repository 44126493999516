import { Component, Input, OnInit } from '@angular/core';
import { Helper } from 'src/app/helpers/helper';

@Component({
  selector: 'questionnaire-header',
  templateUrl: 'questionnaire-header.component.html',
  styleUrls: ['questionnaire-header.component.scss'],
})
export class QuestionnaireHeaderComponent implements OnInit {
  @Input('caseId') caseId!: number;
  @Input('clientId') clientId!: string;
  @Input('clientName') clientName!: string;
  @Input('clientBucket') clientBucket!: string;
  @Input('addNewProductInProgress') addNewProductInProgress!: boolean;
  @Input('isEditMode') isEditMode!: boolean;

  policiesList: any[] = [];
  isSelectedPolicies: boolean = true;
  public isOnArchivePage: boolean = false;

  constructor(private helper: Helper) {}

  ngOnInit(): void {
    this.helper
      .onQuestionnaireHeaderChange()
      .subscribe((isOnArchive) => (this.isOnArchivePage = isOnArchive));
  }
}
