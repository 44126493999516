import { OnInit, Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Helper } from 'src/app/helpers/helper';
import { UpdateUserStatusModel } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  isError: boolean = false;
  isLoading: boolean = false;
  errorMessage: string = '';
  userName: string = '';
  password: string = '';
  loginForm: FormGroup = new FormGroup({});

  constructor(
    private helper: Helper,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar
  ) {}

  get loginFormControl() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    if (this.helper.isAuthenticated()) {
      this.successLogin();
    } else {
      this.helper.setIsAuth(false);
      this.helper.isAuthenticationChanged();
    }

    this.helper.setIsDataFirstUpload(false);
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  showPassword() {
    const passwordInput = document.getElementById(
      'password-input'
    ) as HTMLInputElement;

    if (passwordInput) passwordInput.type = 'text';
  }

  hidePassword() {
    const passwordInput = document.getElementById(
      'password-input'
    ) as HTMLInputElement;

    if (passwordInput) passwordInput.type = 'password';
  }

  login(username: string, password: string): void {
    this.isLoading = true;
    let authValues = { userName: username, password: password };
    this.authService
      .authorize(authValues)
      .subscribe(
        async (response: any) => {
          if (response.Success && response.Data.User.Id) {
            this.helper.setIsAuth(true);
            this.helper.setUser(response.Data.User);
            this.helper.setToken(response.Data.Token);
            this.successLogin();
            this.helper.setUserRole(response.Data.User.Role);
          }
          if (!response.Success) {
            this._snackBar.open(response.Message, 'X', {
              duration: 4000,
              panelClass: ['error-snackbar'],
            });
          }
        },
        (error) => {
          this.isLoading = false;
          this.isError = true;
          this.errorMessage = 'Incorrect User Email Address or Password';
        }
      )
      .add(() => {
        this.isLoading = false;
      });
  }

  successLogin(): void {
    var userStatus = new UpdateUserStatusModel();
    userStatus.UserStatus = 1; //1 - Online
    userStatus.UserId = this.helper.getUserId();
    this.userService
      .putUserStatus(userStatus)
      .subscribe((data) => {
        var redirectUrl = this.helper.getRedirectUrl();
        if (redirectUrl != undefined) {
          this.router.navigate([redirectUrl]);
        } else {
          // this.router.navigate(['/welcome']);
        }
      })
      .add(() => {
        this.isLoading = false;
      });
    this.router.navigate(['/welcome']);
  }

  handleInputChange(): void {
    if (this.isError) {
      this.isError = false;
    }
  }

  forgotPassword() {
    this.helper.setIsAuth(true);
    this.router.navigateByUrl('/forgot-password', {
      state: { isSetPass: true },
    });
  }
}
