import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { Helper } from '../helpers/helper';
import { Observable } from 'rxjs/internal/Observable';
import { LeftMenuModel } from '../models/left-menu';

@Injectable()
export class MenuService extends BaseService {
  private baseUrl: string = environment.baseUrl;
  public errorMessage!: string;
  constructor(private http: HttpClient, helper: Helper) {
    super(helper);
   }
   public getLeftMenu(): Observable<LeftMenuModel> {
    return this.http.get<LeftMenuModel>(this.baseUrl + `/Menu`, {
      headers: super.header().headers});
  }
}
