<div mat-dialog-title class="dialog-heading">Item stage change</div>

<div mat-dialog-content class="dialog-content">
  <p>
    {{ selectedData.length > 1 ? "Items" : "Item" }}
    <b>{{ selectedData.join(", ") }}</b> will be rescoped. Are you sure you want
    to proceed?
  </p>
  <button
    mat-icon-button
    aria-label="Close"
    tabindex="-1"
    (click)="closeDialog()"
    class="btn-modal-close"
  ></button>
</div>

<div mat-dialog-actions class="dialog-actions">
  <button
    mat-button
    data-test-id="confirmation-no-btn"
    color="primary"
    class="confirm-button"
    (click)="confirm()"
    cdkFocusInitial
  >
    Proceed
  </button>
  <button
    mat-button
    class="deny-button"
    (click)="closeDialog()"
    data-test-id="confisrmation-yes-btn"
  >
    Cancel
  </button>
</div>
