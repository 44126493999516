import {DocuLibSearchPayload, NewCasePayload, TableItem} from './types';
import {Subscription} from 'rxjs';
import {Helper} from 'src/app/helpers/helper';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {DocuLibraryService} from '../../services/docu-library.service';
import {TableHeadCells} from './components/docu-lib-data-table/table-types';
import {SelectedDocuLibProductInfo} from './components/tabs/products-and-accounts/sub-tabs/docu-lib-products/types';
import {
    UploadNewDocument
} from "../questionnaire/document-requirement/new-document-upload/new-document-upload.component";
import {MatDialog} from "@angular/material/dialog";
import {AddNewClientOrCaseComponent} from "./components/add-new-client-or-case/add-new-client-or-case.component";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'docu-library-container',
    templateUrl: './docu-library-container.component.html',
    styleUrls: ['./docu-library-container.component.scss'],
})
export class DocuLibraryContainerComponent implements OnInit, OnDestroy {
    public isLoading: boolean = false;
    public isPageDataLoading: boolean = false;
    public isSortingDataLoading: boolean = false;
    public isPreviewVisible: boolean = false;
    public selectedId: string = '';
    public dataSource: TableItem[] = [];
    public isOnChildRoute: boolean = false;
    public activeRouteSubscription: Subscription = new Subscription();
    public activeRouteIdSubscription: Subscription = new Subscription();
    public selectedProduct: SelectedDocuLibProductInfo = {
        Id: '',
        Requestor: '',
        DateRequested: '',
        Approver: '',
        DateApproved: '',
        AntecedentStatus: '',
        DateStatusChange: '',
        AnticipatedActivity: '',
        SourceSystem: '',
        Comment: '',
        IntendedUseOfAccount: '',
        Limit: '',
        Currency: '',
        Status: '',
    };
    public sortDirection: 'asc' | 'desc' | null = null;
    public filterColumns: any;
    public totalItemCount: number = 0;
    private sortColName: string = 'ClientId';
    private selectedTableItem: TableItem | null = null;

    public get tableColumns(): typeof TableHeadCells {
        return TableHeadCells;
    }

    constructor(
        private helper: Helper,
        private router: Router,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private docuLibService: DocuLibraryService,
    ) {
    }

    ngOnInit(): void {
        this.isLoading = true;

        this.helper.onDocuLibSearchSubmit().subscribe((data) => {
            this.handleSearchSubmit(data);
        });

        this.docuLibService
            .getDocuLibraryDataList({
                PageNumber: 1,
                PageSize: 14,
                SortBy: 'ClientId',
                SortDirection: 'desc',
                Filter: {
                    ClientId: null,
                    AccountNumber: null,
                    LegalName: null,
                    Lei: null,
                },
            })
            .subscribe((data) => {
                this.isLoading = false;
                this.dataSource = data.Data.Response.map(x => ({...x, isSelected: false}));
                this.totalItemCount = data.Data.Total;
            });

        this.router.navigate(['/docu-library', this.selectedId]);

        this.helper
            .onDocuLibProductInfoChange()
            .subscribe((isOpened) => (this.isPreviewVisible = isOpened));

        this.activeRouteIdSubscription = this.activatedRoute.paramMap.subscribe(
            (params) => {
                const id = params.get('id');
                if (id) {
                    this.selectedId = id;
                    this.helper.setSelectedDocuLibId(id);
                }
            }
        );

        this.activeRouteSubscription = this.helper
            .onDocuLibraryNavInfoChange()
            .subscribe((data) => (this.isOnChildRoute = data));
    }

    ngOnDestroy(): void {
        if (!this.router.url.includes('/docu-library')) {
            this.isOnChildRoute = false;
            this.helper.setDocuLibChildRouteActive(false);

            this.activeRouteSubscription.unsubscribe();
            this.activeRouteIdSubscription.unsubscribe();
        }
    }

    private handleSearchSubmit(data: DocuLibSearchPayload): void {
        this.isLoading = true;
        this.filterColumns = {
            ClientId: data.id ? +data.id : null,
            AccountNumber: data.accountNumber ? data.accountNumber : null,
            LegalName: data.legalName ? data.legalName : null,
            Lei: data.lei ? data.lei : null,
        };

        this.docuLibService
            .getDocuLibraryDataList({
                PageNumber: 1,
                PageSize: 14,
                SortBy: 'ClientId',
                SortDirection: 'desc',
                Filter: this.filterColumns,
            })
            .subscribe((data) => {
                this.isLoading = false;
                this.dataSource = data.Data.Response;
                this.totalItemCount = data.Data.Total;
            });
    }

    private getSortBy(column: TableHeadCells): string {
        switch (column) {
            case this.tableColumns.CustomerId:
                return 'ClientId';
            case this.tableColumns.AccountNumber:
                return 'AccountNumber';
            case this.tableColumns.LegalName:
                return 'LegalName';

            default:
                return column;
        }
    }

    public handleSorting(payload: {
        sortColumn: TableHeadCells | null;
        sortDirection: 'asc' | 'desc' | null;
    }): void {
        this.isSortingDataLoading = true;
        const {sortColumn, sortDirection} = payload;
        this.sortColName = sortColumn ? this.getSortBy(sortColumn) : '';
        this.sortDirection = sortDirection ? sortDirection : 'asc';

        this.docuLibService
            .getDocuLibraryDataList({
                PageNumber: 1,
                PageSize: 14,
                SortBy: this.sortColName,
                SortDirection: this.sortDirection,
                Filter: this.filterColumns,
            })
            .subscribe((data) => {
                this.isSortingDataLoading = false;
                this.dataSource = data.Data.Response;
                this.totalItemCount = data.Data.Total;
            });
    }

    public handlePageChange(pageNumber: number): void {
        this.isPageDataLoading = true;
        // to improve user experience we're showing loader for at least 1 second
        const loaderTimeout = setTimeout(() => {
            this.isPageDataLoading = false;
        }, 1000);

        this.docuLibService
            .getDocuLibraryDataList({
                PageNumber: pageNumber + 1,
                PageSize: 14,
                SortBy: this.sortColName,
                SortDirection: this.sortDirection || 'desc',
                Filter: this.filterColumns,
            })
            .subscribe((data) => {
                clearTimeout(loaderTimeout);
                this.isPageDataLoading = false;
                this.dataSource = data.Data.Response;
                this.totalItemCount = data.Data.Total;
            });
    }

    public handleOnSelectedTableItemChange(row: TableItem | null): void {
        this.selectedTableItem = row;
    }

    public handleAddClick(): void {
        const dialogRef = this.dialog.open(AddNewClientOrCaseComponent, {
            width: '535px',
            autoFocus: false,
            data: this.selectedTableItem,
        });

        dialogRef.afterClosed().subscribe((result: NewCasePayload) => {
            if (result)
                this.docuLibService.createNewCase(result).subscribe(res => {
                    if (res.Data.includes("You can't add new case while current cases not completed or descoped.")) {
                        this.snackBar.open(
                            'You can\'t add new cases unless the current ones are completed or descoped',
                            'X',
                            {
                                panelClass: ['error-snackbar'],
                                duration: 5000,
                            }
                        );
                    } else {
                        this.isLoading = true;

                        this.helper.onDocuLibSearchSubmit().subscribe((data) => {
                            this.handleSearchSubmit(data);
                        });

                        this.docuLibService
                            .getDocuLibraryDataList({
                                PageNumber: 1,
                                PageSize: 14,
                                SortBy: 'ClientId',
                                SortDirection: 'desc',
                                Filter: {
                                    ClientId: null,
                                    AccountNumber: null,
                                    LegalName: null,
                                    Lei: null,
                                },
                            })
                            .subscribe((data) => {
                                this.isLoading = false;
                                this.dataSource = data.Data.Response.map(x => ({...x, isSelected: false}));
                                this.totalItemCount = data.Data.Total;

                                this.snackBar.open(
                                    'New case successfully added!',
                                    'X',
                                    {
                                        panelClass: ['success-snackbar'],
                                        duration: 5000,
                                    }
                                );
                            });

                    }
                })
        });

    }
}
