import {Helper} from "../../../helpers/helper";
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {StructuresService} from "../../../services/structures.service";
import {StructureItemModel} from "./components/custom-node/custom-node.component";

export interface StructuresDataList {
    ChildStructure: StructuresDataList[],
    EntityType: string,
    ClientName: string,
    ClientPercentage: string,
    ClientType: string,
    Id: number,
    IsVisible: boolean,
    ParentId: number,
}

@Component({
    selector: 'app-structures-container',
    templateUrl: './structures-container.component.html',
    styleUrls: ['./structures-container.component.css']
})
export class StructuresContainerComponent implements OnInit, AfterViewInit {
    public structure: StructuresDataList = new StructureItemModel();
    public isLoading: boolean = false;
    public rightTabOpened: boolean = false;
    public selectedZoomPercentage: 100 | 75 | 50 = 100;
    public zoomDropdownOpened: boolean = false;
    public isAddNewStructureLoading: boolean = false;

    constructor(private helper: Helper, private structuresService: StructuresService) {}

    public get clientId(): number {
        return this.helper.getQuestionnaireClientId();
    }

    ngOnInit(): void {
        this.fetchStructuresData();
        this.helper.onNewStructureCreate().subscribe(() => {
            this.fetchStructuresData();
        });
        this.helper.onRightTabStateChange().subscribe((isOpened) => {
            this.rightTabOpened = isOpened;
        });
    }

    public fetchStructuresData(): void {
        this.structuresService.getStructureData(this.clientId).subscribe(res => {
            this.structure = JSON.parse(res.Data);
            this.isAddNewStructureLoading = false;
        });
    }

    ngAfterViewInit() {
    }

    public handleToggleZoomDropdown(): void {
        this.zoomDropdownOpened = !this.zoomDropdownOpened;
    }

    public handleSelectZoomOption(zoomPercentage: 100 | 75 | 50): void {
        this.selectedZoomPercentage = zoomPercentage;
        this.zoomDropdownOpened = false;
    }
}
