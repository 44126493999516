import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject, merge } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Helper } from 'src/app/helpers/helper';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  startWith,
  tap,
} from 'rxjs/operators';
import { WidgetDataModel } from 'src/app/models/widget';
import { AnalyticModel } from 'src/app/models/analytic';
import { FormBuilder, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterColumn } from 'src/app/models/filter_column';
import { MatSort, SortDirection } from '@angular/material/sort';
import { StatisticService } from 'src/app/services/statistic.service';
import { AnalyticDataSource } from 'src/app/services/analytic.datasource';
import { AuditLogDialog } from '../common/audit-log-dialog/audit-log.component';
import { NewExcelEntrySelectComponent } from '../new-entry-excel/new-entry-excel.component';
import { ConfirmationDialog } from '../common/confirmation-dialog/confirmation-dialog.component';
import { ReusableMultipurposeDialog } from '../common/dialog-popup-window/dialog-popup.component';
import { DisplayColumnsDialogComponent } from '../common/display-columns-dialog/display-columns-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTable implements OnInit, AfterViewInit, AfterViewChecked {
  public dialogRef: any;
  public filterColumn: any;
  public columnValues: any;
  public filter: string = '';
  public panelOpenState = false;
  public filterChangeSubscription: any;
  public nudgeRecievedSubscription: any;
  public widgets: WidgetDataModel[] = [];
  public editingStarted: boolean = false;
  public filterColumns: FilterColumn[] = [];
  public shouldUpdateFilterList: boolean = false;
  public isFilterOptionsLoading: boolean = false;
  public selection = new SelectionModel<any>(true, []);
  public currentEditingNode: AnalyticModel = {
    ClientId: 0,
    ClientName: '',
    ParentGrouping: '',
    DueDate: '',
    CountryOfIncorporation: '',
    Stage: '',
    Revenue: '',
    RiskScore: '',
    Bucket: '',
    ClientType: '',
    Analyst: '',
    AnalystId: 0,
    QCReviewer: '',
    ClientContactTitle: '',
    ClientContactEmail: '',
    ClientContactName: '',
    CompletionDate: new Date(),
    InitialOutreachDate: new Date(),
    ReasonForDescope: '',
    Products: '',
    Selected: false,
    IsNudge: false,
    CaseId: 0,
  };
  public riskScoreDropdownValues = [
    { viewValue: 'Low', value: 'Low ' },
    { viewValue: 'Medium', value: 'Moderate' },
    { viewValue: 'High', value: 'High' },
  ];

  public bucketDropdownValues = [
    { viewValue: 'Remediation', value: 'Remediation ' },
    { viewValue: 'Refresh', value: 'Refresh' },
    { viewValue: 'Onboarding', value: 'Onboarding' },
  ];

  public tabs = [
    'Dashboard',
    'Initial Review',
    'Outreach',
    'Name Screening',
    'Escalation',
    'Data Validation',
    'Approval',
    'Completed',
    'Analytics',
  ];

  paginator: any = {
    itemsPerPage: 0,
    numberOfItems: 0,
    pageIndex: 0,
    pageSize: 8,
  };

  sorting = {
    sortBy: 'Id',
    sortDirection: 'desc',
  };

  // @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  @ViewChild('search') input?: ElementRef;
  @ViewChild(MatTable) matTable?: MatTable<any>;

  @Output() selectionEvent = new EventEmitter<any>();
  @Output() public disableNewEntrySaveButton = new EventEmitter();
  @Output() public loadingEvent = new EventEmitter();
  @Output() public noDisplayDataEmitter = new EventEmitter();

  @Input() isAdmin: boolean = false;
  @Input() statusFilter: string = '';
  @Input() isEditable: boolean = false;
  @Input() isMoveToNextStageHidden: boolean = false;
  @Input() selectedItems: any[] = [];
  @Input() dataSource: AnalyticDataSource = new AnalyticDataSource(
    this.statisticService
  );
  dataNotChanged: any[] = [];
  @Input() displayedColumns: string[] = [
    'NudgeIdentifier',
    'CheckBox',
    'ClientId',
    'ClientName',
    'ParentGrouping',
    'DueDateStr',
    'CountryOfIncorporation',
    'Revenue',
    'Bucket',
    'ClientType',
    'Analyst',
    'QCReviewer',
    'ClientContactTitle',
    'ClientContactEmail',
    'ClientContactName',
    'CompletionDateStr',
    'InitialOutreachDateStr',
    'ReasonForDescope',
    'Products',
    'RiskScore',
    'RowMenu',
    'Link',
    'NextStage',
    'Edit',
    'Remove',
  ];

  @Input() public pageSize: number = 8;
  @Input() public tableId: string = '';
  @Input() public isAddNewEntryTable: boolean = false;

  private updateTableSubject = new Subject<void>();

  allColumns = [
    'NudgeIdentifier',
    'CheckBox',
    'ClientId',
    'ClientName',
    'ParentGrouping',
    'DueDateStr',
    'CountryOfIncorporation',
    'Status',
    'Revenue',
    'RiskScore',
    'Bucket',
    'ClientType',
    'Analyst',
    'QCReviewer',
    'ClientContactTitle',
    'ClientContactEmail',
    'ClientContactName',
    'CompletionDateStr',
    'InitialOutreachDateStr',
    'ReasonForDescope',
    'Products',
    'Stage',
    'RowMenu',
    'Aging',
    'Link',
    'NextStage',
    'Edit',
    'Remove',
  ];

  fixedColumns: any = {
    NudgeIdentifier: true,
    CheckBox: true,
    ClientId: true,
    ClientName: true,
    ParentGrouping: true,
    DueDateStr: true,
    CountryOfIncorporation: false,
    Revenue: false,
    Bucket: false,
    ClientType: false,
    Analyst: false,
    QCReviewer: false,
    ClientContactTitle: false,
    ClientContactEmail: false,
    ClientContactName: false,
    CompletionDateStr: false,
    InitialOutreachDateStr: false,
    ReasonForDescope: false,
    Products: false,
    RiskScore: true,
    Status: true,
    Stage: true,
    RowMenu: true,
    Aging: true,
    Link: true,
    NextStage: true,
    Edit: true,
    Remove: true,
  };

  menuColumns = [
    'NudgeIdentifier',
    'CheckBox',
    'ClientId',
    'ClientName',
    'ParentGrouping',
    'CountryOfIncorporation',
    'Revenue',
    'Bucket',
    'Analyst',
    'QCReviewer',
    'ClientContactEmail',
    'ReasonForDescope',
    'Products',
    'DueDateStr',
    'RiskScore',
    'ClientType',
    'ClientContactTitle',
    'ClientContactName',
    'CompletionDateStr',
    'InitialOutreachDateStr',
    'Products',
    'Stage',
    'RowMenu',
    'Aging',
    'Link',
    'NextStage',
    'Edit',
    'Remove',
  ];

  columnNames: any = {
    ClientId: ' Client ID ',
    ClientName: ' Client Name ',
    ParentGrouping: ' Parent Grouping ',
    DueDateStr: ' Due Date ',
    CountryOfIncorporation: ' Country of Incorporation ',
    Status: ' Status ',
    Revenue: ' Revenue ',
    RiskScore: ' Risk Score ',
    Bucket: ' Bucket ',
    ClientType: ' Client Type ',
    Analyst: ' Analyst ',
    QCReviewer: ' QC Reviewer ',
    ClientContactTitle: ' Client Contact Title ',
    ClientContactEmail: ' Client Contact Email ',
    ClientContactName: ' Client Contact Name ',
    CompletionDateStr: ' Completion Date ',
    InitialOutreachDateStr: ' Initial Outreach Date ',
    ReasonForDescope: ' Reason for Descope ',
    Products: ' Products ',
    Stage: 'Stage',
    Aging: 'Aging',
  };

  constructor(
    private helper: Helper,
    private ngZone: NgZone,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private cdr: ChangeDetectorRef,
    public statisticService: StatisticService
  ) {
    this.updateTableSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.updateTable();
      });
  }

  private updateTable() {
    if (this.matTable) {
      this.ngZone.onMicrotaskEmpty.subscribe(() => {
        this.matTable && this.matTable.updateStickyColumnStyles();
      });
    }
  }

  public editForm = this.fb.group({
    clientId: [0, Validators.required],
    clientNameControl: ['', Validators.required],
    parentGroupingControl: ['', Validators.required],
    dueDateStrControl: ['', Validators.required],
    countryOfIncorporationControl: ['', Validators.required],
    revenueControl: ['', Validators.required],
    bucketControl: ['', Validators.required],
    clientTypeControl: ['', Validators.required],
    analystControl: ['', Validators.required],
    qcReviewerControl: ['', Validators.required],
    clientContactTitleControl: ['', Validators.required],
    clientContactEmailControl: ['', Validators.required],
    clientContactNameControl: ['', Validators.required],
    completionDateStrControl: ['', Validators.required],
    initialOutreachDateStrControl: ['', Validators.required],
    reasonForDescopeControl: ['', Validators.required],
    productsControl: ['', Validators.required],
    riskScoreControl: ['', Validators.required],
  });

  openDialog() {
    this.dialog.open(NewExcelEntrySelectComponent);
  }

  handleStartEditing(currentNode: AnalyticModel): void {
    this.editingStarted = true;
    this.currentEditingNode = this.dataSource
      .getData()
      .find((el) => el.ClientId === currentNode.ClientId);
    this.disableNewEntrySaveButton.emit();
  }

  finishEditing(): void {
    this.disableNewEntrySaveButton.emit();
    this.editingStarted = false;
    this.editForm.setValue({
      clientId: this.currentEditingNode.ClientId,
      clientNameControl: this.currentEditingNode.ClientName,
      parentGroupingControl: this.currentEditingNode.ParentGrouping,
      dueDateStrControl: this.currentEditingNode.DueDate,
      countryOfIncorporationControl:
        this.currentEditingNode.CountryOfIncorporation,
      revenueControl: this.currentEditingNode.Revenue,
      bucketControl: this.currentEditingNode.Bucket,
      clientTypeControl: this.currentEditingNode.ClientType,
      analystControl: this.currentEditingNode.Analyst,
      qcReviewerControl: this.currentEditingNode.QCReviewer,
      clientContactTitleControl: this.currentEditingNode.ClientContactTitle,
      clientContactEmailControl: this.currentEditingNode.ClientContactEmail,
      clientContactNameControl: this.currentEditingNode.ClientContactName,
      completionDateStrControl: this.currentEditingNode.CompletionDate,
      initialOutreachDateStrControl:
        this.currentEditingNode.InitialOutreachDate,
      reasonForDescopeControl: this.currentEditingNode.ReasonForDescope,
      productsControl: this.currentEditingNode.Products,
      riskScoreControl: this.currentEditingNode.RiskScore,
    });

    // resetting the current node to default value
    this.currentEditingNode = {
      ClientId: 0,
      ClientName: '',
      ParentGrouping: '',
      DueDate: '',
      CountryOfIncorporation: '',
      Stage: '',
      Revenue: '',
      RiskScore: '',
      Bucket: '',
      ClientType: '',
      Analyst: '',
      AnalystId: 0,
      QCReviewer: '',
      ClientContactTitle: '',
      ClientContactEmail: '',
      ClientContactName: '',
      CompletionDate: new Date(),
      InitialOutreachDate: new Date(),
      ReasonForDescope: '',
      Products: '',
      Selected: false,
      IsNudge: false,
      CaseId: 0,
    };
  }

  removeRow(rowToDelete: AnalyticModel): void {
    if (this.isAddNewEntryTable) {
      this.dataSource.getDataForPagination().subscribe((data: any) => {
        const rowToBeDeleted = data.indexOf(rowToDelete);
        data.splice(rowToBeDeleted, 1);

        const paginated = data.slice(
          this.paginator.pageSize * this.paginator.pageIndex,
          this.paginator.pageSize * this.paginator.pageIndex +
            this.paginator.pageSize
        );

        if (paginated.length > 0) {
          this.dataSource.passData(paginated);
        } else {
          this.paginator.pageIndex = this.paginator.pageIndex - 1;
          this.dataSource.passData(
            data.slice(
              this.paginator.pageSize * this.paginator.pageIndex,
              this.paginator.pageSize * this.paginator.pageIndex +
                this.paginator.pageSize
            )
          );
        }
      });
    } else {
      const data = this.dataSource.getData();
      const rowToBeDeleted = data.indexOf(rowToDelete);

      data.splice(rowToBeDeleted, 1);

      this.dataSource.passData(data);
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.getData().length ?? 0;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selectionEvent.emit(this.selection.selected);
      return;
    }

    this.dataSource.getData().map((el: any) => this.selection.select(el));
    this.selectionEvent.emit(this.selection.selected);
  }

  public clearSelection() {
    this.selection.clear();
    this.selectionEvent.emit(this.selection.selected);
  }

  handleCheckBoxChange(row: any): void {
    this.selection.toggle(row);
    this.selectionEvent.emit(this.selection.selected);
  }

  checkboxLabel(row?: any) {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  getRandomCompanyType() {
    const companyTypes = [
      'Creative Agency',
      'Network Service',
      'Online Shop',
      'Software House',
      'Video Games Company',
      'Photograph Agency',
      'Creative Agency',
    ];

    return companyTypes[Math.floor(Math.random() * companyTypes.length)];
  }

  ngOnInit(): void {
    this.helper.isNewEntryAdded().subscribe((x) => {
      this.handleUpdateTableData();
    });
    this.helper.setSearchFilter('');
    this.paginator.pageSize = this.pageSize;
    if (this.isAddNewEntryTable) {
      this.displayedColumns = this.displayedColumns.filter(
        (column) =>
          column !== 'CompletionDateStr' && column !== 'InitialOutreachDateStr'
      );
    }
    this.filterChangeSubscription = this.helper
      .isSearchFilterChanged()
      .pipe(startWith(this.helper.isSearchFilterChanged()), delay(500))
      .subscribe((value: any) => {
        if (
          (typeof value === 'string' || value instanceof String) &&
          this.filter !== value
        ) {
          this.filter = value.toString().replace('#INV-', '').replace('$', '');
          if (this.paginator) this.paginator.pageIndex = 0;
          this.selection.clear();
          this.loadAnalyticPage(
            this.sort?.active ?? 'Id',
            this.sort?.direction ?? 'desc'
          );
        }
      });

    this.nudgeRecievedSubscription = this.helper
      .isNudgeRecieved()
      .subscribe((msg: any) => {
        if (msg && this.matTable) {
          msg.forEach((id: any) => {
            var item = this.dataSource.getData().find((c) => c.CaseId === id);
            if (item) {
              item.IsNudge = true;
            }
          });
        }
      });

    const statusFilter = new FilterColumn('CaseStatus');
    if (this.statusFilter) {
      statusFilter.UserId = this.helper.getUserId();
      statusFilter.Includes.push(this.statusFilter);
      this.filterColumns.push(statusFilter);
    }

    // we do not need to send extra request if the data has been passed already
    // example: once we upload Excel file, we pass result data using the dataSource.passData() method
    if (!this.dataSource.getData().length) {
      this.loadingEvent.emit(true);
      this.dataSource = new AnalyticDataSource(this.statisticService);
      this.dataSource.loadAnalytics(
        this.helper.getUser().Id,
        '',
        'Id',
        'desc',
        this.paginator!.pageIndex + 1,
        this.paginator!.pageSize,
        this.filterColumns
      );
      this.dataSource.loading$.subscribe((res: any) => {
        this.loadingEvent.emit(res);

        // emitting boolean value for Eye button functionality
        if (res === false) {
          this.noDisplayDataEmitter.emit(
            this.dataSource.getData().length ? true : false
          );
        }
      });
    } else {
      // handling UI pagination for the data table opened in modal
      this.dataSource.getDataForPagination().subscribe((data) => {
        const paginated = data.slice(
          0 * this.paginator.pageSize,
          (0 + 1) * this.paginator.pageSize
        );
        this.dataSource.passData(paginated);
      });
    }

    this.dataNotChanged = Object.create(this.dataSource.getData());
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      if (this.sort) {
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        merge(this.sort!.sortChange, this.paginator.page)
          .pipe(
            tap(() => {
              this.loadAnalyticPage(
                this.sort?.active ?? 'Id',
                this.sort?.direction ?? 'desc'
              );

              // UI pagination handling
              this.dataSource.getDataForPagination().subscribe((data) => {
                if (this.paginator) {
                  const { pageIndex, pageSize } = this.paginator;

                  const paginated = data.slice(
                    pageIndex * pageSize,
                    (pageIndex + 1) * pageSize
                  );

                  this.dataSource.passData(paginated);
                  this.cdr.detectChanges();
                }
              });
            })
          )
          .subscribe();
      }
    }
  }

  ngAfterViewChecked() {
    if (this.matTable) this.updateTableSubject.next();
  }

  loadAnalyticPage(
    sortByColumn: string,
    sortDirection: string,
    filterCol?: any,
    isReset?: boolean
  ) {
    this.sorting!.sortBy = sortByColumn;
    this.sorting!.sortDirection = sortDirection as SortDirection;

    if (isReset) {
      const sortBy = this.getDBColumnName(sortByColumn);
      this.filterColumns = filterCol;

      this.dataSource.loadAnalytics(
        this.helper.getUser().Id,
        this.filter,
        sortBy,
        sortDirection,
        this.paginator!.pageIndex + 1,
        this.paginator!.pageSize,
        filterCol
      );

      return;
    }

    // making sure we don't add duplicate filter columns to the this.filterColumns array
    if (filterCol) {
      const uniqueFilters: any[] = [];

      this.filterColumns = [
        ...new Set([...this.filterColumns, ...filterCol]),
      ].filter((element) => {
        const isDuplicate = uniqueFilters.includes(element.ColumnName);

        if (!isDuplicate) {
          uniqueFilters.push(element.ColumnName);

          return true;
        }

        return false;
      });
    }

    if (this.isEditable) {
      this.dataSource.filterData(
        this.dataNotChanged,
        this.filterColumns,
        sortByColumn,
        sortDirection
      );
      return;
    }

    const sortBy = this.getDBColumnName(sortByColumn);
    this.clearSelection();
    this.dataSource.loadAnalytics(
      this.helper.getUser().Id,
      this.filter,
      sortBy,
      sortDirection,
      this.paginator!.pageIndex + 1,
      this.paginator!.pageSize,
      this.filterColumns
    );
  }

  onClickChangeDisplayColumns() {
    this.dialogRef = this.dialog.open(DisplayColumnsDialogComponent, {
      data: {
        allColumns: this.allColumns,
        displayColumns: this.displayedColumns,
      },
      panelClass: 'popup-884',
    });

    this.dialogRef.afterClosed().subscribe((res: string[]) => {
      if (res) {
        this.displayedColumns = res;
      }
      this.dialogRef = null;
    });
  }

  onClickfilterByColumn(columnName: string) {
    var columnValues = [];
    this.filterColumn = this.filterColumns.find(
      (x) => x.ColumnName == columnName
    );

    if (!this.filterColumn) {
      this.filterColumn = new FilterColumn(columnName);
    }

    this.statisticService
      .getDistinctColumnValues(columnName)
      .subscribe((res: string[]) => {
        columnValues = res;
        this.columnValues = res;
      });
  }

  getDBColumnName(columnName: string): string {
    if (columnName == 'ClientName') {
      return 'Client.ClientName';
    } else if (columnName == 'ClientId') {
      return 'Client.Id';
    } else if (columnName == 'ClientContactName') {
      return 'Client.ClientContact.ContactName';
    } else if (columnName == 'ClientContactEmail') {
      return 'Client.ClientContact.ContactEmail';
    } else if (columnName == 'ClientContactTitle') {
      return 'Client.ClientContact.ContactTitle';
    } else if (columnName == 'ClientType') {
      return 'Client.ClientType.TypeName';
    } else if (columnName == 'ParentGrouping') {
      return 'Client.ParentGrouping';
    } else if (columnName == 'DueDateStr') {
      return 'DueDate';
    } else if (columnName == 'CompletionDateStr') {
      return 'CompletionDate';
    } else if (columnName == 'InitialOutreachDateStr') {
      return 'InitialOutreachDate';
    } else if (columnName == 'Stage') {
      return 'CaseStatus';
    } else if (columnName == 'CountryOfIncorporation') {
      return 'Client.CountryOfIncorporation';
    } else if (columnName == 'Analyst') {
      return 'Analyst.UserName';
    }

    return columnName;
  }

  isColumnFiltered(columnName: string): boolean {
    const column = this.filterColumns.find(
      (x) => x.ColumnName == this.getDBColumnName(columnName)
    );
    return column != null;
  }

  handleMoveToNextStage(currentRow: any) {
    this.dialogRef = this.dialog.open(ReusableMultipurposeDialog, {
      data: {
        dialogHeader: 'Item stage change',
        clientId: currentRow.ClientId,

        confirmAction: 'Proceed',
        denyAction: 'Cancel',
      },
    });
  }

  onNextStage(row: AnalyticModel) {
    const currentStageIndex = this.tabs.indexOf(row.Stage);

    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'Item stage change',
        clientId: row.ClientId,
        nextStage: this.tabs[currentStageIndex + 1],
      },
      panelClass: 'popup-884',
    });

    this.dialogRef.afterClosed().subscribe((confirmation: any) => {
      if (confirmation.confirm) {
        var nextStage = this.getNextStage(row.Stage);
        if (nextStage) {
          row.Stage = nextStage;
          row.IsNudge = false;
          this.statisticService
            .updateAnalytic({
              Data: [row],
              UserId: this.helper.getUserId(),
            })
            .subscribe((res: any) => {
              if (res.Success) {
                this.helper.callLeftMenuChanged();
                if (
                  this.dataSource.getData().length === 1 &&
                  this.paginator.pageIndex > 0
                ) {
                  this.paginator.pageIndex = this.paginator.pageIndex - 1;
                }
                this.loadAnalyticPage(
                  this.sort?.active ?? 'Id',
                  this.sort?.direction ?? 'desc'
                );
              }
            });
        }
      }
    });
  }

  getNextStage(currentStage: string) {
    switch (currentStage.toLowerCase()) {
      case 'initial review':
        return 'Outreach';
      case 'outreach':
        return 'Name Screening';
      case 'name screening':
        return 'Escalation';
      case 'escalation':
        return 'Data Validation';
      case 'data validation':
        return 'Approval';
      case 'approval':
        return 'Complete';
    }
    return '';
  }

  handleRescope() {
    this.selection.selected
      .filter((el) => el.Stage === 'Descoped')
      .map((el) => (el.Stage = 'Rescope'));

    this.statisticService
      .updateAnalytic({
        Data: this.selection.selected,
        UserId: this.helper.getUserId(),
      })
      .subscribe((res: any) => {
        if ((el: any) => el.Success) {
          this.helper.callLeftMenuChanged();
          this.loadAnalyticPage(
            this.sort?.active ?? 'Id',
            this.sort?.direction ?? 'desc'
          );
        }
      });
  }

  handleDescope(reasonForDescope: string) {
    // we need to filter out all the Descoped items from the list before changing item status
    this.selection.selected
      .filter((el) => el.Stage !== 'Descoped')
      .map((el) => {
        el.Stage = 'Descoped';
        el.ReasonForDescope = reasonForDescope;
        return el;
      });

    this.statisticService
      .updateAnalytic({
        Data: this.selection.selected,
        UserId: this.helper.getUserId(),
      })
      .subscribe((res: any) => {
        if ((el: any) => el.Success) {
          this.helper.callLeftMenuChanged();
          this.loadAnalyticPage(
            this.sort?.active ?? 'Id',
            this.sort?.direction ?? 'desc'
          );
        }
      });
  }

  handleSelection(selectedItems: any[]) {
    // this.selection.select(selectedItems);
  }

  handleOpenAuditLog(caseId: number) {
    this.dialog.open(AuditLogDialog, { data: caseId });
  }

  handleFilterListUpdate(event: any) {
    this.dataSource.analyticCount = this.dataSource.getData().length;
  }

  handlePageChange(index: number) {
    const sortBy = this.getDBColumnName(this.sorting!.sortBy);
    this.paginator!.pageIndex = index;
    this.loadingEvent.emit(true);
    if (this.isAddNewEntryTable) {
      // handling UI pagination for the data table opened in modal
      this.dataSource.getDataForPagination().subscribe((data) => {
        const paginated = data.slice(
          this.paginator.pageSize * this.paginator.pageIndex,
          this.paginator.pageSize * this.paginator.pageIndex +
            this.paginator.pageSize
        );

        this.dataSource.passData(paginated);
      });
    } else {
      this.dataSource = new AnalyticDataSource(this.statisticService);
      this.loadAnalyticPage(
        this.sort?.active ?? 'Id',
        this.sort?.direction ?? 'desc'
      );
    }

    this.dataSource.loading$.subscribe((res: any) => {
      this.loadingEvent.emit(res);
    });
  }

  handleBucketChange(event: any): void {}

  handleUpdateTableData(): void {
    const sortBy = this.getDBColumnName(this.sorting!.sortBy);
    this.dataSource.loadAnalytics(
      this.helper.getUser().Id,
      '',
      sortBy,
      this.sorting!.sortDirection,
      this.paginator!.pageIndex + 1,
      this.paginator!.pageSize,
      this.filterColumns
    );
  }

  handleLinkClick(element: {
    CaseId: string;
    ClientId: string;
    ClientName: string;
    Bucket: string;
  }): void {
    this.helper.setQuestionnaireClientId(element.ClientId);
    this.helper.setQuestionnaireCaseId(element.CaseId);
    this.helper.setQuestionnaireClientName(element.ClientName);
    this.helper.setQuestionnaireBucket(element.Bucket);
    this.helper.updateQuestionnaireCaseStage(this.statusFilter);

    switch (this.statusFilter) {
      case 'Name Screening':
        this.router.navigate(['/structures', element.CaseId]);
        break;

      default:
        this.router.navigate(['/questionnaire', element.CaseId]);
    }
  }
}
