import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Helper } from '../helpers/helper';
import { BaseService } from './base.service';

@Injectable()
export class AnalyticService extends BaseService {
  private baseUrl: string = environment.baseUrl;
  public errorMessage!: string;
  constructor(private http: HttpClient, helper: Helper) {
    super(helper);
  }

  public getAnalyticTable(
    bucket: string[],
    reportdate: string
  ): Observable<any> {
    var userId = this.helper.getUserId();
    let params = new HttpParams();
    bucket.forEach(b => {
      params = params.append('bucket', b);
    });
    params = params.set('reportDate', reportdate);
    return this.http.get<any>(this.baseUrl + `/Analytics`, {
      params: params,
      headers: super.header().headers,
    });
  }

  public getTrendingGraphData(): Observable<any> {
    var userId = this.helper.getUserId();
    return this.http.get<any>(this.baseUrl + `/Analytics/trending`, {
      params: new HttpParams().set('userId', userId),
      headers: super.header().headers,
    });
  }

  public getTeams(): Observable<any> {
    var userId = this.helper.getUserId();
    return this.http.get<any>(this.baseUrl + `/Analytics/teams`, {
      params: new HttpParams().set('userId', userId),
      headers: super.header().headers,
    });
  }

  public getFile(bucket: string, reportdate: string): Observable<any> {
    var userId = this.helper.getUserId();
    return this.http.get<any>(this.baseUrl + `/Analytics/getFile`, {
      headers: super.header().headers,
      responseType: 'blob' as 'json',
    });
  }

  public getBuckets(): Observable<any> {
    const userId = this.helper.getUserId();
    return this.http.get<any>(this.baseUrl + `/Analytics/buckets`, {
      params: new HttpParams().set('userId', userId),
      headers: super.header().headers,
    });
  }

  public getPieChart(): Observable<any> {
    const userId = this.helper.getUserId();
    return this.http.get<any>(this.baseUrl + `/Analytics/piechart`, {
      params: new HttpParams().set('userId', userId),
      headers: super.header().headers,
    });
  }

  public updateTrendingGraphTarget(newTarget: number): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + `/Analytics/trending?newTrending=${newTarget}`,
      {
        newTrending: newTarget,
      },
      super.header()
    );
  }
}
