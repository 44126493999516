import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { SelectedProductInfo } from "../questionnaire-data-table/questionnaire-table.component";
import { DatePipe } from "@angular/common";


@Component({
  selector: 'product-info',
  templateUrl: 'product-info.component.html',
  styleUrls: ['product-info.component.scss']
})
export class ProductInfoComponent implements OnInit, OnChanges {

  @Input('productData') productData!: SelectedProductInfo;
  @Output('onProductInfoChange') productInfoChange = new EventEmitter<any>();

  productInfoForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    Requestor: new FormControl(''),
    DateRequested: new FormControl(null),
    Approver: new FormControl(''),
    DateApproved: new FormControl(null),
    AntecedentStatus: new FormControl(''),
    DateStatusChange: new FormControl(null),
    AnticipatedActivity: new FormControl({value: '', disabled: true}),
    SourceSystem: new FormControl({value: '', disabled: true}),
    Comment: new FormControl({value: '', disabled: true}),
    IntendedUseOfAccount: new FormControl({value: '', disabled: true}),
    Limit: new FormControl({value: '', disabled: true}),
    Currency: new FormControl({value: '', disabled: true})
  });

  constructor(public datepipe: DatePipe) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['productData'] && changes['productData'].currentValue) {
      const { Id, Requestor, DateRequested, Approver,
        DateApproved, AntecedentStatus, DateStatusChange, AnticipatedActivity,
        SourceSystem, Comment, IntendedUseOfAccount, Limit, Currency  } = changes['productData'].currentValue;

      this.productInfoForm.setValue({
        Id: Id,
        Requestor: Requestor || '-',
        DateRequested: DateRequested,
        Approver: Approver || '-',
        DateApproved: DateApproved,
        AntecedentStatus: AntecedentStatus || '-',
        DateStatusChange: DateStatusChange,
        AnticipatedActivity: AnticipatedActivity && AnticipatedActivity !== '-' ? AnticipatedActivity : '',
        SourceSystem: SourceSystem && SourceSystem !== '-' ? SourceSystem : '',
        Comment: Comment && Comment !== '-' ? Comment : '',
        IntendedUseOfAccount: IntendedUseOfAccount && IntendedUseOfAccount !== '-' ? IntendedUseOfAccount : '',
        Limit: Limit && Limit !== '-' ? Limit : '',
        Currency: Currency && Currency !== '-' ? Currency : ''
      });

      this.productInfoForm.disable();

    }
  }

  switchControlReadOnly(controlName: string): void {
    if(this.productInfoForm.controls[controlName].enabled) {
      this.productInfoForm.controls[controlName].disable();
    } else {
      this.productInfoForm.controls[controlName].enable();
    }
  }

  handleInfoChanges() {
    this.productInfoChange.emit(this.productInfoForm.getRawValue());
  }
}
