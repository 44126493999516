<div class="test">
    <div mat-dialog-title class="d-flex justify-between">
        <div class="dialog-label">{{ dialogHeader }}</div>

        <div class="close-btn" (click)="onCancelClick()">
            <div class="icon-wrapper">
                <div class="horizontal"></div>
                <div class="vertical"></div>
            </div>
        </div>
    </div>

    <mat-dialog-content class="add-new-contact-dialog">
        <form [style.overflow]="'hidden'" [formGroup]="dialogForm">
            <!--  Client Name  -->
            <div class="dialog-form-control">
                <mat-label>Client Name</mat-label>
                <input type="text" class="text-input-styled" formControlName="ClientName"/>
            </div>

            <!--  Parent Grouping  -->
            <div class="dialog-form-control">
                <mat-label>Parent Grouping</mat-label>
                <input class="text-input-styled" formControlName="ParentGrouping"/>
            </div>

            <!--  Country Of Incorporation  -->
            <div class="dialog-form-control">
                <mat-label>Country Of Incorporation</mat-label>
                <mat-select class="select-input-styled" formControlName="CountryOfIncorporation">
                    <ng-container *ngFor="let option of dropdownData.Countries">
                        <mat-option [value]="option">{{ option }}</mat-option>
                    </ng-container>
                </mat-select>
            </div>

            <!--  Client Type  -->
            <div class="dialog-form-control">
                <mat-label>Client Type</mat-label>
                <mat-select class="select-input-styled" formControlName="ClientType">
                    <ng-container *ngFor="let option of dropdownData.ClientTypes">
                        <mat-option [value]="option">{{ option }}</mat-option>
                    </ng-container>
                </mat-select>
            </div>

            <!--  Client Contact Name  -->
            <div class="dialog-form-control">
                <mat-label>Client Contact Name</mat-label>
                <input class="text-input-styled" formControlName="ClientContactName"/>
            </div>

            <!--  Client Contact Email   -->
            <div class="dialog-form-control">
                <mat-label>Client Contact Email</mat-label>
                <input class="text-input-styled" formControlName="ClientContactEmail"/>
            </div>

            <!--  Client Contact Title  -->
            <div class="dialog-form-control">
                <mat-label>Client Contact Title</mat-label>
                <input class="text-input-styled" formControlName="ClientContactTitle"/>
            </div>

            <!--  Revenue  -->
            <div class="dialog-form-control">
                <mat-label>Revenue</mat-label>
                <input class="text-input-styled" formControlName="Revenue"/>
            </div>

            <!--  Bucket  -->
            <div class="dialog-form-control d-flex flex-column">
                <mat-label>Bucket</mat-label>
                <mat-select class="select-input-styled" formControlName="Bucket">
                    <ng-container *ngFor="let option of dropdownData.Buckets">
                        <mat-option [value]="option">{{ option }}</mat-option>
                    </ng-container>
                </mat-select>
            </div>

            <!--  DueDate  -->
            <div class="dialog-form-control d-flex flex-column">
                <mat-label>Due Date</mat-label>

                <div class="date-picker-box">
                    <input
                        readonly
                        [matDatepicker]="picker"
                        formControlName="DueDate"
                        class="datepicker-input-styled"
                        [min]="today"
                    />
                    <mat-datepicker-toggle
                        [for]="picker"
                        matIconSuffix
                        class="custom-toggle"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </div>
            </div>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button
            mat-button
            (click)="onContinueClick()"
            [disabled]="isContinueDisabled"
            class="action-btn-base continue"
        >
            Continue
        </button>
        <button mat-button class="action-btn-base cancel" (click)="onCancelClick()">
            Cancel
        </button>
    </mat-dialog-actions>
</div>
