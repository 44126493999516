import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Df1DataPayload} from "../df1-data/types";
import {Helper} from "../../../../../../../../../../helpers/helper";
import {DocuLibraryService} from "../../../../../../../../../../services/docu-library.service";
import {DropdownOptions} from "../sdl-data/types";
import {Df2DataPayload} from "./types";
import {isNull} from "@angular/compiler/src/output/output_ast";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-df2-data',
    templateUrl: './df2-data.component.html',
    styleUrls: ['./df2-data.component.css']
})
export class Df2DataComponent implements OnInit {
    public isLoading: boolean = false;
    public dataSource: Df2DataPayload | undefined;
    public df2Form: FormGroup = new FormGroup({});
    public dropdownData: DropdownOptions | null = null;

    get clientId(): string {
        return this.helper.getQuestionnaireClientId();
    }

    constructor(
        private helper: Helper,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private regulatoryService: DocuLibraryService,
    ) {
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.regulatoryService.fetchRegulatoryDropdownOptions().subscribe((dropDawnData) => {
            this.dropdownData = dropDawnData.Data;

            this.regulatoryService.fetchRegulatoryDf2Data(parseInt(this.clientId)).subscribe((data) => {
                this.dataSource = data.Data;
                this.fillDf2FormData(this.dataSource);
                this.isLoading = false;
                this.cdr.detectChanges();
            });
        });
    }

    private fillDf2FormData(data: Df2DataPayload): void {
        this.df2Form = this.formBuilder.group({
            institution: [data.Institution, Validators.required],
            institutionActingAs: [data.IsTheInstitutionActingAsInvestmentAdvisorAssetManagerOrOtherAgent, Validators.required],
            nfaId: [data.NFAID, Validators.required],
            principalBusiness: [data.PrincipalBusiness, Validators.required],

            cftcSwapEntity: [data.CFTCSwapEntity, Validators.required],
            financialEntity: [data.FinancialEntity, Validators.required],
            financialCompany: [data.FinancialCompany, Validators.required],
            insuredDepositoryInstitution: [data.InsuredDepositoryInstitution, Validators.required],
            emailAddressForDeliveryOfNotices: [data.EmailAdressForDeliveryOfNotices, Validators.required],
            emailAddressForDeliveryOfRiskEvaluations: [data.EmailAdressForDeliveryOfRiskEvaluations, Validators.required],
            emailAddressForDeliveryOfPortfolioData: [data.EmailAdressForDeliveryOfPortfolioData, Validators.required],
            localBusinessdayCityOrCities: [data.LocalBusinessDayCityOrCities, Validators.required],
            dfSchedule3: [data.DFSchedule3, Validators.required],
            dfSchedule4: [data.DFSchedule4, Validators.required],
            pcaPrincipalAgreeToReviewOrExchangeData: [data.DoesPCAPrincipalAgreeToReviewOrExchangePortfolioData, Validators.required],
            pcaPrincipalAgreeToReconcileAgainstSdrData: [data.DoesPCAPrincipalAgreeRoReconcileAgainstSDRData, Validators.required],
            standingEndUserExceptionElection: [data.StandingEndUserExceptionElection, Validators.required],
            standingOptOutOfAnnualFiling: [data.StandingOptOutOfAnnualFiling, Validators.required],
            isPcaPrincipalElectingTheFinanceAffiliateException: [data.IsPCAPrincipalElectingTheFinanceAffiliateException, Validators.required],
            isPcaPrincipalElectingTheHedgingAffiliateException: [data.IsPCAPrincipalElectingTheHedgingAffiliateException, Validators.required],
            isPcaPrincipalExemptUnderTheSmallBankExemption: [data.IsPCAPrincipalExemptUnderTheSmallBankExemption, Validators.required],
            howPcaPrincipalMeetsFinancialObligationsAssociatedWithEnteringintoNonClearedSwaps: [
                data.HowDoesPCAPrincipalGenerallyMeetItsFinancialObligationsAssociatedWithEnteringIntoNonClearedSwaps,
                Validators.required
            ],
            isPcaPrincipalSecIssuesFiler: [data.IsPCAPrincipalAnSECIssuesFiler, Validators.required],
            secCentralIndexKeyNumber: [data.SECCentralIndexKeyNumber, Validators.required],
            didPcaPrincipalReceiveElectionApproval: [data.DidPCAPrincipalReceiveElectionApproval, Validators.required],
            pcaPrincipalAgreeToIsdaMarch2013DfProtocolMasterAgreement: [
                data.DoesPCAPrincipalAgreeToTheISDAMarch2013DFProtocolMasterAgreement,
                Validators.required
            ],
            name: [data.Name, Validators.required],
            noticeAddress1: [data.NoticeAddress1, Validators.required],
            noticeAddress2: [data.NoticeAddress2, Validators.required],
            noticeCity: [data.NoticeCity, Validators.required],
            noticeState: [data.NoticeState, Validators.required],
            noticeCountry: [data.NoticeCountry, Validators.required],
            noticeZipCode: [data.NoticeZipCode, Validators.required],
            phone: [data.Phone, Validators.required],
            fax: [data.Fax, Validators.required],
            email: [data.Email, Validators.required],
            electronicMessagingSystemDetails: [data.ElectronicMessagingSystemDetails, Validators.required],
            specificInstructions: [data.SpecificInstructions, Validators.required],
            status: [data.Status, Validators.required],
            note: [data.Note, Validators.required],
            doddFrankProtocol2AdherenceId: [data.DoddFrankProtocol2AdherenceID, Validators.required],
            doddFrankProtocol2MatchDate: [data.DoddFrankProtocol2MatchDate, Validators.required],
            topUpShareStatus: [data.TopUpShareStatus, Validators.required],
            topUpProtocolAdherenceId: [data.TopUpProtocolAdherenceID, Validators.required],
            protocolName: [data.ProtocolName, Validators.required],
            acceptDate: [data.AcceptDate, Validators.required],
            adherenceType: [data.AdherenceType, Validators.required],
            coveredSbsEntity: [data.CoveredSBSEntity, Validators.required],
            adherenceLetter: [data.AdherenceLetter, Validators.required],
            pcaIdentification: [data.PCAIdentification, Validators.required],
            fullLegalNameOfPcaPrincipalOrPcaAgent: [data.FullLegalNameOfPCAPrincipalOrPCAAgent, Validators.required],
            individualName: [data.IndividualName, Validators.required],
            individualTitle: [data.IndividualTitle, Validators.required],
        });
    }

    public updateDf2Data(): void {
        this.isLoading = true;
        if (this.dataSource)
            this.regulatoryService.updateRegulatoryDf2Data({
                Id: this.dataSource.Id,
                ClientId: this.dataSource.ClientId,
                Institution: this.df2Form.controls['institution'].value,
                IsTheInstitutionActingAsInvestmentAdvisorAssetManagerOrOtherAgent:
                    this.df2Form.controls['institutionActingAs'].value,
                NFAID: this.df2Form.controls['nfaId'].value,
                PrincipalBusiness: this.df2Form.controls['principalBusiness'].value,
                CFTCSwapEntity: this.df2Form.controls['cftcSwapEntity'].value,
                FinancialEntity: this.df2Form.controls['financialEntity'].value,
                FinancialCompany: this.df2Form.controls['financialCompany'].value,
                InsuredDepositoryInstitution: this.df2Form.controls['insuredDepositoryInstitution'].value,
                EmailAdressForDeliveryOfNotices: this.df2Form.controls['emailAddressForDeliveryOfNotices'].value,
                EmailAdressForDeliveryOfRiskEvaluations: this.df2Form.controls['emailAddressForDeliveryOfRiskEvaluations'].value,
                EmailAdressForDeliveryOfPortfolioData: this.df2Form.controls['emailAddressForDeliveryOfPortfolioData'].value,
                LocalBusinessDayCityOrCities: this.df2Form.controls['localBusinessdayCityOrCities'].value,
                DFSchedule3: this.df2Form.controls['dfSchedule3'].value,
                DFSchedule4: this.df2Form.controls['dfSchedule4'].value,
                DoesPCAPrincipalAgreeToReviewOrExchangePortfolioData:
                    this.df2Form.controls['pcaPrincipalAgreeToReviewOrExchangeData'].value,
                DoesPCAPrincipalAgreeRoReconcileAgainstSDRData:
                    this.df2Form.controls['pcaPrincipalAgreeToReconcileAgainstSdrData'].value,
                StandingEndUserExceptionElection: this.df2Form.controls['standingEndUserExceptionElection'].value,
                StandingOptOutOfAnnualFiling: this.df2Form.controls['standingOptOutOfAnnualFiling'].value,
                IsPCAPrincipalElectingTheFinanceAffiliateException:
                    this.df2Form.controls['isPcaPrincipalElectingTheFinanceAffiliateException'].value,
                IsPCAPrincipalElectingTheHedgingAffiliateException:
                    this.df2Form.controls['isPcaPrincipalElectingTheHedgingAffiliateException'].value,
                IsPCAPrincipalExemptUnderTheSmallBankExemption:
                    this.df2Form.controls['isPcaPrincipalExemptUnderTheSmallBankExemption'].value,
                HowDoesPCAPrincipalGenerallyMeetItsFinancialObligationsAssociatedWithEnteringIntoNonClearedSwaps:
                    this.df2Form.controls['howPcaPrincipalMeetsFinancialObligationsAssociatedWithEnteringintoNonClearedSwaps'].value,
                IsPCAPrincipalAnSECIssuesFiler: this.df2Form.controls['isPcaPrincipalSecIssuesFiler'].value,
                SECCentralIndexKeyNumber: this.df2Form.controls['secCentralIndexKeyNumber'].value,
                DoesPCAPrincipalAgreeToTheISDAMarch2013DFProtocolMasterAgreement:
                    this.df2Form.controls['pcaPrincipalAgreeToIsdaMarch2013DfProtocolMasterAgreement'].value,
                DidPCAPrincipalReceiveElectionApproval:this.df2Form.controls['didPcaPrincipalReceiveElectionApproval'].value,
                Name: this.df2Form.controls['name'].value,
                NoticeAddress1: this.df2Form.controls['noticeAddress1'].value,
                NoticeAddress2: this.df2Form.controls['noticeAddress2'].value,
                NoticeCity: this.df2Form.controls['noticeCity'].value,
                NoticeState: this.df2Form.controls['noticeState'].value,
                NoticeCountry: this.df2Form.controls['noticeCountry'].value,
                NoticeZipCode: this.df2Form.controls['noticeZipCode'].value,
                Phone: this.df2Form.controls['phone'].value,
                Fax: this.df2Form.controls['fax'].value,
                Email: this.df2Form.controls['email'].value,
                ElectronicMessagingSystemDetails: this.df2Form.controls['electronicMessagingSystemDetails'].value,
                SpecificInstructions: this.df2Form.controls['specificInstructions'].value,
                Status: this.df2Form.controls['status'].value,
                Note: this.df2Form.controls['note'].value,
                DoddFrankProtocol2AdherenceID: this.df2Form.controls['doddFrankProtocol2AdherenceId'].value,
                DoddFrankProtocol2MatchDate: this.df2Form.controls['doddFrankProtocol2MatchDate'].value,
                TopUpShareStatus: this.df2Form.controls['topUpShareStatus'].value,
                TopUpProtocolAdherenceID: this.df2Form.controls['topUpProtocolAdherenceId'].value,
                ProtocolName: this.df2Form.controls['protocolName'].value,
                AcceptDate: this.df2Form.controls['acceptDate'].value,
                AdherenceType: this.df2Form.controls['adherenceType'].value,
                CoveredSBSEntity: this.df2Form.controls['coveredSbsEntity'].value,
                AdherenceLetter: this.df2Form.controls['adherenceLetter'].value,
                PCAIdentification: this.df2Form.controls['pcaIdentification'].value,
                FullLegalNameOfPCAPrincipalOrPCAAgent: this.df2Form.controls['fullLegalNameOfPcaPrincipalOrPcaAgent'].value,
                IndividualName: this.df2Form.controls['individualName'].value,
                IndividualTitle: this.df2Form.controls['individualTitle'].value,
            }).subscribe(res => {
                this.isLoading = false;
                this.df2Form.markAsPristine();
                this.snackBar.open('Changes saved!', 'X', {
                    duration: 1500,
                    panelClass: ['success-snackbar'],
                });
                this.cdr.detectChanges();
            });
    }
}
