import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilterColumn } from 'src/app/models/filter_column';
import { MatChipInputEvent } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-filter-by-dialog',
  templateUrl: './filter-by-dialog.component.html',
  styleUrls: ['./filter-by-dialog.component.css']
})
export class FilterByDialogComponent implements OnInit {
  private dialog: any;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  filteredColumn: FilterColumn;
  excludeText: string = '';
  includeText: string = '';
  columnValues: string[] = [];
  filteredIncludeOptions!: Observable<string[]>;
  filteredExcludeOptions!: Observable<string[]>;
  includesControl = new FormControl('');
  excludesControl = new FormControl();
  options: string[] = this.columnValues;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any
  , private dialogRef: MatDialogRef<FilterByDialogComponent>) {
    this.dialog = dialogRef;
    this.filteredColumn = data.column;
    this.columnValues = data.columnValues;
    this.options = Array.from(data.columnValues);
    this.filteredIncludeOptions = this.includesControl.valueChanges.pipe(
      startWith(null),
      map((value: string | null) => value ? this._filter(value) : this.options.slice()));
    this.filteredExcludeOptions = this.excludesControl.valueChanges.pipe(
      startWith(null),
      map((value: string | null) => value ? this._filter(value) : this.options.slice()));
  }

  ngOnInit(): void {

  }

  private _filter(name: string): string[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  displayFn(value: string): string {
    return value;
  }

  close(){
    this.dialog.close('cancel');
  }

  deleteFilter() {
    this.dialog.close('delete');
  }

  addIncludes(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our include
    if (value) {
      this.filteredColumn.Includes.push(value);
    }

    // Clear the input value
    this.includeText = '';
  }

  selectedInclude(event: MatAutocompleteSelectedEvent): void {
    this.filteredColumn.Includes.push(event.option.viewValue);
    this.options.splice(this.options.indexOf(event.option.viewValue), 1);
    this.includeText = '';
    this.includesControl.setValue(null);
    this.excludesControl.setValue(null);
  }

  addExcludes(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our exclude
    if (value) {
      this.filteredColumn.Excludes.push(value);
    }

    // Clear the input value
    this.excludeText = '';
  }

  selectedExclude(event: MatAutocompleteSelectedEvent): void {
    this.filteredColumn.Excludes.push(event.option.viewValue);
    this.options.splice(this.options.indexOf(event.option.viewValue), 1);
    this.excludeText = '';
    this.excludesControl.setValue(null);
    this.includesControl.setValue(null);
  }

  removeIncludes(include: string): void {
    const index = this.filteredColumn.Includes.indexOf(include);

    if (index >= 0) {
      this.filteredColumn.Includes.splice(index, 1);
      var index2 = this.columnValues.indexOf(include);
      if (index2 > 0) {
        this.options.splice(this.options.length -1 >= index2 ? index2 : 0, 0, include);
        this.excludesControl.setValue(null);
        this.includesControl.setValue(null);
      }

    }
  }

  removeExcludes(exclude: string): void {
    const index = this.filteredColumn.Excludes.indexOf(exclude);

    if (index >= 0) {
      this.filteredColumn.Excludes.splice(index, 1);
      var index2 = this.columnValues.indexOf(exclude);
      if (index2 > 0) {
        this.options.splice(this.options.length -1 >= index2 ? index2 : 0, 0, exclude);
        this.excludesControl.setValue(null);
        this.includesControl.setValue(null);
      }

    }
  }

}
