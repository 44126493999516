export enum RiskAttributesControls {
  CountryCategories = 'Country Category',
  CountryRisk = 'Country Risk',
  CountryExposureRisk = 'Country Exposure Risk',
  ReputationalRisk = 'Reputational Risk',
  PoliticalExposureRisk = 'Political Exposure Risk',
  IndustryRisk = 'Industry Risk',
  ProductRisk = 'Product Risk',
  BusinessStructureRisk = 'Business Structure Risk',
}

export enum PotentialScreeningOutcomeControls {
  Pep = 'PEP',
  PepDescription = 'PEP Description',
  AdverseInformation = 'Adverse Information',
  NegativeInformationDetail = 'Negative Information Detail',
}

export enum PotentialRiskControls {
  ClientRiskScore = 'Client Risk Score',
  OverrideClientRiskScore = 'Override Client Risk Score',
  Comment = 'Comment',
}

export enum RiskFormType {
  RiskAttributes = 'risk-attributes',
  PotentialScreeningOutcome = 'potential-screening-outcome',
  PotentialRisk = 'potential-risk',
}

export interface RiskScoreData {
  Id: number;
  ClientId: number;
  CountryCategories: string[];
  CountryCategoryIds: number[];
  CountryRisk: string;
  CountryRiskId: number;
  CountryExposureRisk: string;
  CountryExposureRiskId: number;
  ReputationalRisk: string;
  ReputationalRiskId: number;
  PoliticalExposureRisk: string;
  PoliticalExposureRiskId: number;
  IndustryRisk: string;
  IndustryRiskId: number;
  ProductRisk: string;
  ProductRiskId: number;
  BusinessStructureRisk: string;
  BusinessStructureRiskId: number;
  Pep: string;
  PepId: number;
  PepDescription: string;
  AdverseInformation: string;
  AdverseInformationId: number;
  NegativeInformationDetail: string;
  ClientRiskScore: string;
  ClientRiskScoreId: number;
  OverrideClientRiskScore: string;
  OverrideClientRiskScoreId: number;
  Comment: string;
}

export const enum RiskScoresByName {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  VeryHigh = 'Very High',
}

export const enum ControlValue {
  Name = 'Name',
  Id = 'Id',
}

export const enum RiskScoresById {
  Low = 4,
  Medium = 3,
  High = 2,
  VeryHigh = 1,
}
