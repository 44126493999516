import {
  Input,
  OnInit,
  Output,
  ViewChild,
  Component,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Helper } from 'src/app/helpers/helper';
import { FilterColumn } from 'src/app/models/filter_column';
import { UserService } from 'src/app/services/user.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatExpansionPanel } from '@angular/material/expansion';
import { StatisticService } from 'src/app/services/statistic.service';
import { AnalyticDataSource } from 'src/app/services/analytic.datasource';

interface DataTableAppliedFilters {
  ColumnName: string;
  Includes: string[];
  Excludes: string[];
  PageSize: number;
  PageNumber: number;
  SortBy: string;
  SortDirection: string;
  Filter: string;
  UserId: string;
}

@Component({
  selector: 'sorting-filtering-component',
  templateUrl: './data-table-sorting-filtering.component.html',
  styleUrls: ['./data-table-sorting-filtering.component.scss'],
})
export class SortingFilteringComponent implements OnInit, OnChanges {
  public isExpanded: boolean = false;
  public dataForUiSortingFiltering: any[] = [];

  @Input() statusFilter: string = '';
  @Input() public column: string = '';
  @Input() public columnNames: any = {};
  @Input() public menuColumns: string[] = [];
  @Input() public displayedColumns: string[] = [];
  @Input() public isAddNewEntryTable: boolean = false;
  @Input() public isUserAccessDataTable: boolean = false;
  @Input() public dataSource: AnalyticDataSource = new AnalyticDataSource(
    this.statisticService
  );
  @Input() public sortingData: {
    sortBy: string;
    sortDirection: string;
  } = { sortBy: 'Id', sortDirection: 'asc' };

  @Output() public clickFilterBy = new EventEmitter();
  @Output() public loadAnalytics = new EventEmitter<{
    column: string;
    direction: string;
    filterCol: FilterColumn[];
  }>();
  @Output() public clickApplyFilters = new EventEmitter();
  @Output() public clickApplyFiltersUserAccess = new EventEmitter();
  @Output() public userAccessDataSourceUpdate = new EventEmitter();

  @ViewChild('expansionPanel', { static: false }) panel!: MatExpansionPanel;

  public filterColumn!: FilterColumn;
  public isOpen: boolean = true;
  public searchText: string = '';
  public columnValues: string[] = [];
  public columnValuesAdjusted: any[] = [];
  @Input() public filterColumns: FilterColumn[] = [];
  public isFilterOptionsLoading: boolean = false;
  public sortDirection: string = 'asc';
  public appliedFilter: { Name: string; Includes: string[] }[] = [];
  public appliedDataTableFilter: DataTableAppliedFilters[] = [];

  @Output() public updateNumberOfTableItems = new EventEmitter();

  constructor(
    private helper: Helper,
    private ref: ChangeDetectorRef,
    private statisticService: StatisticService,
    private userService: UserService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.statusFilter) {
      this.helper.callDataTableFiltersChanged(
        this.appliedDataTableFilter.filter((x) => x.ColumnName !== 'CaseStatus')
      );
    }
  }

  public getColumnName(columnName: string): string {
    switch (columnName) {
      case 'DueDateStr':
        return 'DueDate';

      case 'Status':
        return 'CaseStatus';

      case 'CompletionDateStr':
        return 'CompletionDate';

      case 'InitialOutreachDateStr':
        return 'InitialOutreachDate';

      default:
        return columnName;
    }
  }

  ngOnInit(): void {
    // subscribe here to sync applied User Access filters between all the filtering components
    this.helper
      .isUserAccessFiltersChanged()
      .subscribe((appliedFilters: { Name: string; Includes: string[] }[]) => {
        this.appliedFilter = appliedFilters;
      });

    //subscribe here to sync applied data table filters between all the filtering components
    this.helper.isDataTableFiltersChanged().subscribe((dataTableFilters) => {
      this.appliedDataTableFilter = dataTableFilters;
    });
  }

  public handleOpenAddNewEntryFilter() {
    this.filterColumn.SortBy = this.getColumnName(this.column);

    this.columnValuesAdjusted = [
      ...new Set(
        this.dataSource
          .getData()
          .map((el) => el[this.filterColumn.SortBy])
          .filter(Boolean)
      ),
    ].map((column) => ({
      column,
      checked: true,
    }));

    this.columnValuesAdjusted?.unshift({
      column: '(Unselect All)',
      checked: false,
    });

    this.filterColumn.SortDirection = this.sortDirection;
    this.isFilterOptionsLoading = false;
  }

  public clickOnFilterByHandler(event: MouseEvent): void {
    event.stopPropagation();
    this.isExpanded = !this.isExpanded;

    const alreadyExistingFilterColumn = this.filterColumns.find(
      (x) => x.ColumnName == this.column
    );

    this.filterColumn = alreadyExistingFilterColumn
      ? alreadyExistingFilterColumn
      : new FilterColumn(this.getDBColumnName(this.column));

    if (!this.columnValuesAdjusted.length) {
      this.isFilterOptionsLoading = true;
      this.ref.detectChanges();

      // handling ui sorting & filtering for the Add new entry table
      if (this.isAddNewEntryTable) {
        this.handleOpenAddNewEntryFilter();
      }

      // fetching filter columns for User Access Data Table
      else if (this.isUserAccessDataTable) {
        this.userService
          .getUserAccessFilteredColumnValues(
            this.getDBColumnName(this.column),
            this.appliedFilter
          )
          .subscribe((res: any) => {
            this.columnValues = res.Data;
            this.columnValuesAdjusted = this.columnValues
              .filter(Boolean)
              .map((column) => ({
                column,
                checked: true,
              }));

            // adding Unselect all button
            this.columnValuesAdjusted.unshift({
              column: '(Unselect All)',
              checked: false,
            });

            setTimeout(() => {
              this.isFilterOptionsLoading = false;
            }, 1000);
          });
      } else {
        if (!this.appliedDataTableFilter.length) {
          this.appliedDataTableFilter.push({
            ColumnName: 'CaseStatus',
            Includes: [this.statusFilter].filter(Boolean),
            Excludes: [],
            PageSize: 8,
            PageNumber: 1,
            SortBy: this.getDBColumnName(this.sortingData.sortBy),
            SortDirection: this.sortingData.sortDirection,
            Filter: '',
            UserId: this.helper.getUserId(),
          });
        }

        this.statisticService
          .getDataTableFilteredColumnValues(this.getDBColumnName(this.column), [
            {
              ColumnName: 'CaseStatus',
              Includes: [this.statusFilter].filter(Boolean),
              Excludes: [],
              PageSize: 8,
              PageNumber: 1,
              SortBy: this.getDBColumnName(this.sortingData.sortBy),
              SortDirection: this.sortingData.sortDirection,
              Filter: '',
              UserId: this.helper.getUserId(),
            },
          ])
          .subscribe((filteredRes: any) => {
            this.columnValues = filteredRes.Data;
            this.statisticService
              .getDataTableFilteredColumnValues(
                this.getDBColumnName(this.column),
                this.appliedDataTableFilter
              )
              .subscribe((res: any) => {
                if (this.column === 'Status') {
                  this.columnValues = ['Pending', 'Complete'];

                  const completedStatus = ['Complete'];
                  const pendingStatuses = [
                    'Initial Review',
                    'Outreach',
                    'Name Screening',
                    'Escalation',
                    'Data Validation',
                    'Approval',
                    'Descoped',
                  ];

                  this.columnValuesAdjusted = this.columnValues.map(
                    (column) => {
                      return {
                        column,
                        checked: res.Data.some((x: string) => {
                          if (
                            column === 'Pending' &&
                            pendingStatuses.some((pend) => x === pend)
                          )
                            return true;

                          if (
                            column === 'Complete' &&
                            completedStatus.some((pend) => x === pend)
                          )
                            return true;

                          return false;
                        }),
                      };
                    }
                  );

                  // adding Unselect all button
                  this.columnValuesAdjusted.unshift({
                    column: '(Unselect All)',
                    checked: false,
                  });
                } else {
                  this.columnValuesAdjusted = this.columnValues
                    ?.filter(Boolean)
                    .map((column) => ({
                      column,
                      checked: res.Data.some((r: string) => r === column),
                    }))
                    .sort((a, b) => b.checked - a.checked);

                  // adding Unselect all button
                  this.columnValuesAdjusted.unshift({
                    column: '(Unselect All)',
                    checked: false,
                  });
                }
              });

            setTimeout(() => {
              this.isFilterOptionsLoading = false;
            }, 1000);
          });
      }
    }
  }

  getDBColumnName(columnName: string): string {
    if (columnName == 'ClientName') {
      return 'Client.ClientName';
    } else if (columnName == 'ClientId') {
      return 'Client.Id';
    } else if (columnName == 'ClientContactName') {
      return 'Client.ClientContact.ContactName';
    } else if (columnName == 'ClientContactEmail') {
      return 'Client.ClientContact.ContactEmail';
    } else if (columnName == 'ClientContactTitle') {
      return 'Client.ClientContact.ContactTitle';
    } else if (columnName == 'ClientType') {
      return 'Client.ClientType.TypeName';
    } else if (columnName == 'ParentGrouping') {
      return 'Client.ParentGrouping';
    } else if (columnName == 'DueDateStr') {
      return 'DueDate';
    } else if (columnName == 'CompletionDateStr') {
      return 'CompletionDate';
    } else if (columnName == 'InitialOutreachDateStr') {
      return 'InitialOutreachDate';
    } else if (columnName == 'Status') {
      return 'Status';
    } else if (columnName == 'Stage') {
      return 'CaseStatus';
    } else if (columnName == 'CountryOfIncorporation') {
      return 'Client.CountryOfIncorporation';
    } else if (columnName == 'Analyst') {
      return 'Analyst.FullName';
    } // User Access Management table
    else if (columnName == 'User Id') {
      return 'Id';
    } else if (columnName == 'Access Role') {
      return 'Role';
    } else if (columnName == 'Employee Name') {
      return 'FullName';
    }

    return columnName;
  }

  public handleCheckboxChangeNewEntry(event: MatCheckboxChange): void {
    const indexToBeExcluded = this.columnValuesAdjusted
      .map((el) => el.column)
      .indexOf(event.source.name!);

    // handling select all case
    if (event.source.name === '(Unselect All)') {
      if (event.checked) {
        this.columnValuesAdjusted.map((el) => {
          if (el.column === '(Unselect All)') {
            return el;
          }
          el.checked = false;
          return el;
        });
      } else {
        this.columnValuesAdjusted.map((el) => {
          if (el.column === '(Unselect All)') {
            return el;
          }
          el.checked = true;
          return el;
        });
      }
    }

    this.columnValuesAdjusted.map((el, index) => {
      if (index === indexToBeExcluded) {
        el.checked = !el.checked;
        return el;
      }

      return el;
    });

    this.filterColumn.Excludes = this.columnValuesAdjusted
      .filter((el) => !el.checked)
      .filter((el) => el.column !== '(Unselect All)')
      .map((el) => el.column);
  }

  public onCheckboxChange(event: MatCheckboxChange): void {
    if (this.isAddNewEntryTable) {
      this.handleCheckboxChangeNewEntry(event);

      return;
    } else {
      // finding the index of item that needs to be excluded

      const indexToBeExcluded = this.columnValues.indexOf(event.source.name!);

      // handling Unselect All toggle
      if (event.source.name === '(Unselect All)') {
        if (event.checked) {
          // unselecting every item on the list
          this.columnValuesAdjusted.map((el) => {
            if (el.column === '(Unselect All)') {
              el.checked = true;
              return el;
            }
            el.checked = false;
            return el;
          });
        } else {
          // selecting every item on the list
          this.columnValuesAdjusted.map((el) => {
            if (el.column === '(Unselect All)') {
              return el;
            }
            el.checked = true;
            return el;
          });
        }
      }

      // toggling selected item

      this.columnValuesAdjusted.map((el) => {
        if (el.column === this.columnValues[indexToBeExcluded]) {
          el.checked = !el.checked;
        }
        if (
          event.source.name !== '(Unselect All)' &&
          event.checked &&
          el.column === '(Unselect All)' &&
          el.checked
        ) {
          el.checked = false;
          return el;
        }
        return el;
      });

      this.filterColumn.Excludes = this.columnValuesAdjusted
        .filter((el) => !el.checked)
        .filter((el) => el.column !== '(Unselect All)')
        .map((el) => el.column);
      this.filterColumn.Includes = this.columnValuesAdjusted
        .filter((el) => el.checked)
        .filter((el) => el.column !== '(Unselect All)')
        .map((el) => el.column);
    }
  }

  public applyFilters(): void {
    this.filterColumn.UserId = this.helper.getUserId();
    this.filterColumn.SortBy = this.isAddNewEntryTable
      ? this.getColumnName(this.column)
      : this.getDBColumnName(this.column);
    this.filterColumn.SortDirection = this.sortDirection;

    if (
      !this.filterColumns.some((x) => x.ColumnName === 'CaseStatus') &&
      this.statusFilter
    ) {
      const caseStatusFilter = new FilterColumn('CaseStatus');
      caseStatusFilter.Includes = [this.statusFilter].filter(Boolean);
      caseStatusFilter.UserId = this.helper.getUserId();
      caseStatusFilter.SortBy = this.getDBColumnName(this.sortingData.sortBy);
      caseStatusFilter.SortDirection = this.sortingData.sortDirection;

      this.filterColumns.push(caseStatusFilter);
    }

    const filterExistsAlready = this.filterColumns.some(
      (x) => (x as any).Name === this.filterColumn.ColumnName
    );

    if (filterExistsAlready) {
      const existingFilter = this.filterColumns.find(
        (x) => (x as any).Name === this.filterColumn.ColumnName
      );
      const index = this.filterColumns.indexOf(existingFilter!);
      if (index !== -1) {
        this.filterColumns = this.filterColumns.filter(
          (x) => (x as any).Name !== this.filterColumn.ColumnName
        );
        this.filterColumns.push(this.filterColumn);
      }
    } else {
      this.filterColumns.push(this.filterColumn);
    }
    this.filterColumns = [...new Set(this.filterColumns)];

    if (this.isAddNewEntryTable) {
      // handling filtering for add new entry
      this.handleUiFilteringAddNewEntry();
    } else if (this.isUserAccessDataTable) {
      // handling filtering for user access data table
      this.handleFilteringUserAccess();
    } else {
      this.clickApplyFilters.emit({
        column: this.getDBColumnName(this.column),
        direction: this.sortDirection,
        filterCol: this.filterColumns,
      });

      this.helper.callDataTableFiltersChanged(this.filterColumns);
    }
  }

  public resetFilters(): void {
    this.columnValuesAdjusted.map((element) => {
      element.checked = element.column === '(Unselect All)' ? false : true;
      return element;
    });

    if (this.isUserAccessDataTable) {
      const filterColAdjusted: any[] = this.filterColumns.map((x) => {
        if ((x as any).Name === this.getDBColumnName(this.column)) {
          return {
            Name: (x as any).Name,
            Includes: this.columnValues,
            SortDirection: x.SortDirection,
          };
        }

        return x;
      });

      this.clickApplyFiltersUserAccess.emit({
        column: this.getDBColumnName(this.column),
        direction: this.sortDirection,
        filterCol: this.filterColumns,
        filterColAdjusted: filterColAdjusted,
        isReset: true,
      });
    }
    if (this.isAddNewEntryTable) {
      this.handleAddNewEntryFilterReset();
    } else {
      this.filterColumns = this.filterColumns.filter(
        (x) => x.ColumnName === 'CaseStatus'
      );
      // this.filterColumn.Excludes = [];/
      // this.helper.callDataTableFiltersChanged(
      //   this.appliedDataTableFilter.filter((x) => x.ColumnName == 'CaseStatus')
      // );

      this.clickApplyFilters.emit({
        column: this.getDBColumnName(this.column),
        direction: this.sortDirection,
        filterCol: this.filterColumns,
        isReset: true,
      });
    }
  }

  public onMenuClose() {
    this.isOpen = false;
  }

  public onMenuOpen() {
    this.isOpen = true;
  }

  public handleSortingChange(direction: string): void {
    if (this.isUserAccessDataTable) {
      const filterColAdjusted: any = this.filterColumns.map((el) => {
        if (el.Excludes) {
          return {
            Name: el.ColumnName,
            Includes: this.columnValues.filter(
              (excluded) => !el.Excludes.some((x) => x === excluded)
            ),
            SortDirection: el.SortDirection,
          };
        } else return el;
      });

      this.userService
        .getUserAccess({
          PageNumber: 0,
          PageSize: 10,
          SortBy: this.getDBColumnName(this.column),
          SortDirection: direction,
          FilterColumns: filterColAdjusted,
        })
        .subscribe((res) => {
          if (res.Success) {
            this.userAccessDataSourceUpdate.emit(res.Data);
          } else {
            console.error(res.Message);
          }
        });
    } else {
      this.sortDirection = direction;
      this.loadAnalytics.emit({
        column: this.column,
        direction: direction,
        filterCol: this.filterColumns,
      });
    }
  }

  public handleUiFilteringAddNewEntry(): void {
    let existingFilters = this.dataSource.getFilterValues();
    const filterAlreadyApplied = existingFilters.some(
      (x) => x.ColumnName === this.filterColumn.ColumnName
    );

    if (!filterAlreadyApplied) {
      const filters = [...existingFilters, ...this.filterColumns];
      this.dataSource.saveFiltersForAddNewEntryTable(filters);
    } else {
      existingFilters = existingFilters.filter(
        (x) => x.ColumnName !== this.filterColumn.ColumnName
      );

      existingFilters.push(this.filterColumn);

      this.dataSource.saveFiltersForAddNewEntryTable(existingFilters);
    }

    this.dataSource.getDataForPagination().subscribe((res) => {
      this.dataSource.filterData(
        res,
        this.dataSource.getFilterValues(),
        this.getColumnName(this.column),
        this.sortDirection
      );
    });
  }

  public handleAddNewEntryFilterReset(): void {
    this.filterColumn.Excludes = [];

    this.dataSource.saveFiltersForAddNewEntryTable(
      this.dataSource
        .getFilterValues()
        .filter((x) => x.ColumnName !== this.filterColumn.ColumnName)
    );

    this.dataSource.getDataForPagination().subscribe((x) => {
      this.dataSource.filterData(
        x,
        this.dataSource
          .getFilterValues()
          .filter((x) => x.ColumnName !== this.filterColumn.ColumnName),
        this.getColumnName(this.column),
        this.sortDirection
      );
    });
  }

  public handleFilteringUserAccess(): void {
    const filterColAdjusted: any = this.filterColumns.map((el) => {
      if (el.Excludes) {
        return {
          Name: el.ColumnName,
          Includes: this.columnValues.filter(
            (excluded) => !el.Excludes.some((x) => x === excluded)
          ),
          SortDirection: el.SortDirection,
        };
      } else return el;
    });

    this.clickApplyFiltersUserAccess.emit({
      column: this.getDBColumnName(this.column),
      direction: this.sortDirection,
      filterCol: this.filterColumns,
      filterColAdjusted: filterColAdjusted,
    });

    this.helper.callUserAccessFiltersChanged(filterColAdjusted);
    //fetch user access data with filters applied
    this.userService
      .getUserAccess({
        PageNumber: 0,
        PageSize: 10,
        SortBy: this.getDBColumnName(this.column),
        SortDirection: this.sortDirection,
        FilterColumns: filterColAdjusted.map((x: any) => {
          if (!x.Includes.length) {
            x.Includes = ['null'];
          }

          return x;
        }),
      })
      .subscribe((res) => {
        if (res.Success) {
          this.updateNumberOfTableItems.emit(
            this.appliedFilter
              .filter(
                (x) =>
                  x.Includes.length + 1 !==
                  res.Data.find((x: any) => x.TotalCount)?.TotalCount
              )
              .filter(Boolean).length
              ? res.Data.length
              : res.Data.find((x: any) => x.TotalCount)?.TotalCount
          );
          this.userAccessDataSourceUpdate.emit(res.Data);
        } else {
          console.error(res.Message);
        }
      });
  }
}
