import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/helpers/helper';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { ArchiveDataSource, ArchiveTableKeys } from './types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DeleteArchiveDialogComponent } from './delete-archive-dialog/delete-archive-dialog.component';

@Component({
  selector: 'archive-component',
  templateUrl: 'archive.component.html',
  styleUrls: ['archive.component.scss'],
})
export class ArchiveComponent implements OnInit {
  public isPreviewVisible: boolean = false;
  public displayedColumns: ArchiveTableKeys[] = [
    ArchiveTableKeys.RequirementId,
    ArchiveTableKeys.DocumentCategoryName,
    ArchiveTableKeys.DocumentRequirementName,
    ArchiveTableKeys.UploadedOn,
    ArchiveTableKeys.UploadedBy,
    ArchiveTableKeys.Delete,
  ];
  public dataSource: ArchiveDataSource[] = [];
  public selectedDocId: number = -1;
  public documentUrl: string = '';
  public isPreviewDataLoading: boolean = false;
  public isArchiveLoading: boolean = false;

  public get totalArchiveCount(): number {
    return this.dataSource.length ? this.dataSource[0].Total : 0;
  }

  constructor(
    private helper: Helper,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private questionnaireService: QuestionnaireService
  ) {}

  ngOnInit(): void {
    this.isArchiveLoading = true;
    this.questionnaireService
      .getDocumentRequirementsArchivePaginated(+this.clientId, 1, 10)
      .subscribe((res) => {
        this.dataSource = res.Data;
        this.isArchiveLoading = false;
      });

    this.helper.onPreviewOpenedChange().subscribe((isOpened) => {
      if (!isOpened) {
        this.displayedColumns = [
          ArchiveTableKeys.RequirementId,
          ArchiveTableKeys.DocumentCategoryName,
          ArchiveTableKeys.DocumentRequirementName,
          ArchiveTableKeys.UploadedOn,
          ArchiveTableKeys.UploadedBy,
          ArchiveTableKeys.Delete,
        ];
      }
      this.isPreviewVisible = isOpened;
    });
  }

  public get userId(): string {
    return this.helper.getUserId();
  }

  public get idList(): typeof ArchiveTableKeys {
    return ArchiveTableKeys;
  }

  public get clientId() {
    return this.helper.getQuestionnaireClientId();
  }

  public get clientName() {
    return this.helper.getQuestionnaireClientName();
  }

  public get clientBucket() {
    return this.helper.getQuestionnaireBucket();
  }

  public previewDataLoaded(): void {
    this.isPreviewDataLoading = false;
  }

  public openDocumentPreview(elem: ArchiveDataSource): void {
    if (elem.DocumentUrl === null || elem.DocumentUrl === '') {
      this._snackBar.open('There are no files attached to this requirement', 'X', {
        duration: 4000,
        panelClass: ['error-snackbar'],
      });
      return;
    }

    this.displayedColumns = this.isPreviewVisible
      ? [
          ArchiveTableKeys.RequirementId,
          ArchiveTableKeys.DocumentCategoryName,
          ArchiveTableKeys.DocumentRequirementName,
          ArchiveTableKeys.UploadedOn,
          ArchiveTableKeys.UploadedBy,
          ArchiveTableKeys.Delete,
        ]
      : this.displayedColumns.filter((x) => x !== ArchiveTableKeys.Delete);

    if (elem.Id === this.selectedDocId && this.isPreviewVisible) {
      this.isPreviewVisible = false;
      this.helper.updatePreviewOpened(false);
    } else {
      this.isPreviewVisible = true;
      this.selectedDocId = elem.Id;
      this.helper.updatePreviewOpened(true);
    }

    this.isPreviewDataLoading = true;
    this.documentUrl = elem.DocumentUrl;
    this.helper.updateArchiveDocUrl(this.documentUrl);
  }

  public handleDeleteDocClick(document: ArchiveDataSource) {
    const dialogRef = this.dialog.open(DeleteArchiveDialogComponent, {
      autoFocus: false,
      data: { document },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.isArchiveLoading = true;
      this.questionnaireService
        .getDocumentRequirementsArchive(this.clientId)
        .subscribe((res) => {
          this.dataSource = res.Data;
          this.isArchiveLoading = false;
        });
    });
  }

  public handlePageChange(event: number) {
    this.isArchiveLoading = true;
    this.questionnaireService
      .getDocumentRequirementsArchivePaginated(+this.clientId, event + 1, 10)
      .subscribe((res) => {
        this.dataSource = res.Data;
        this.isArchiveLoading = false;
      });

    this.helper.onPreviewOpenedChange().subscribe((isOpened) => {
      if (!isOpened) {
        this.displayedColumns = [
          ArchiveTableKeys.RequirementId,
          ArchiveTableKeys.DocumentCategoryName,
          ArchiveTableKeys.DocumentRequirementName,
          ArchiveTableKeys.UploadedOn,
          ArchiveTableKeys.UploadedBy,
          ArchiveTableKeys.Delete,
        ];
      }
      this.isPreviewVisible = isOpened;
    });
  }
}
