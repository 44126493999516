<div *ngIf="isArchiveLoading" class="loader-box">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="dataSource.length === 0 && !isArchiveLoading">
  <div class="d-flex justify-center no-data-box-1">
    <div class="d-flex flex-column align-center" rou>
      <img
        alt="no data"
        style="width: 100px"
        src="../../../../assets/empty.png"
      />
      <div class="d-flex justify-center no-data">No data found</div>
    </div>
  </div>
</div>

<div
  [ngStyle]="{
    opacity: isArchiveLoading
      ? 0
      : dataSource.length === 0 && !isArchiveLoading
      ? 0
      : 1
  }"
  class="archive-wrapper d-lex"
  style="position: relative"
>
  <div class="archive-content">
    <div class="collapsible-table">
      <form class="d-flex w-100 form-style">
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z8 w-100 products-table"
        >
          <!-- Document ID -->
          <ng-container [matColumnDef]="idList.RequirementId">
            <th mat-header-cell *matHeaderCellDef>
              <div class="d-flex justify-center">
                <div class="d-flex head-sort-wrapper">Document ID</div>
              </div>
            </th>
            <td mat-cell *matCellDef="let element" class="user-id-field">
              <div class="d-flex justify-center">
                {{ element.Id }}
              </div>
            </td>
          </ng-container>

          <!-- Category -->
          <ng-container [matColumnDef]="idList.DocumentCategoryName">
            <th mat-header-cell *matHeaderCellDef>
              <div class="d-flex justify-center">
                <div class="d-flex head-sort-wrapper">Category</div>
              </div>
            </th>
            <td mat-cell *matCellDef="let element" class="user-id-field">
              <div class="d-flex no-wrap-value justify-center">
                {{ element.DocumentCategoryName }}
              </div>
            </td>
          </ng-container>

          <!-- List Of Requirements -->
          <ng-container [matColumnDef]="idList.DocumentRequirementName">
            <th mat-header-cell *matHeaderCellDef>
              <div class="d-flex justify-center">
                <div class="d-flex head-sort-wrapper">List Of Requirements</div>
              </div>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              style="background: transparent"
            >
              <div
                class="d-flex no-wrap-value justify-center cursor-pointer"
                (click)="openDocumentPreview(element)"
              >
                {{ element.DocumentRequirementName }}
              </div>
            </td>
          </ng-container>

          <!-- Uploaded On -->
          <ng-container [matColumnDef]="idList.UploadedOn">
            <th mat-header-cell *matHeaderCellDef>
              <div class="d-flex justify-center">
                <div class="d-flex head-sort-wrapper no-wrap">
                  <div class="d-flex flex-column">
                    <div class="d-flex justify-center">Uploaded On</div>
                    <div class="d-flex justify-center date-format-label">
                      DD/MM/YY
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              style="background: transparent"
            >
              <div class="d-flex no-wrap-value justify-center">
                <div class="uploaded-on">
                  {{ element.UploadedOn | date : "dd/MM/yyyy" }}
                </div>
              </div>
            </td>
          </ng-container>

          <!-- Uploaded By -->
          <ng-container [matColumnDef]="idList.UploadedBy">
            <th mat-header-cell *matHeaderCellDef>
              <div class="d-flex justify-start">
                <div class="d-flex head-sort-wrapper no-wrap">Uploaded By</div>
              </div>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              style="background: transparent"
            >
              <div class="d-flex no-wrap-value justify-start">
                {{ element.UploadedBy }}
              </div>
            </td>
          </ng-container>

          <!-- Delete -->
          <ng-container [matColumnDef]="idList.Delete">
            <th mat-header-cell *matHeaderCellDef>
              <div class="d-flex justify-start">
                <div class="d-flex head-sort-wrapper no-wrap">&nbsp;</div>
              </div>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              style="background: transparent"
            >
              <div class="d-flex no-wrap-value justify-center">
                <img
                  class="cursor-pointer"
                  src="../../../../../assets/icons/remove-requirement.svg"
                  alt="remove"
                  (click)="handleDeleteDocClick(element)"
                />
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </form>
    </div>
    <div [ngStyle]="{ 'flex-grow': isPreviewVisible ? 2 : 0 }" class="preview">
      <div *ngIf="isPreviewVisible" class="preview-doc">
        <app-loader-with-logo
          *ngIf="isPreviewDataLoading"
          [isLoading]="true"
          [isFullScreen]="false"
        ></app-loader-with-logo>
        <ngx-doc-viewer
          [ngStyle]="{ opacity: isPreviewDataLoading ? 0 : 1 }"
          [url]="documentUrl"
          viewer="google"
          style="width: 100%; height: 100%"
          (loaded)="previewDataLoaded()"
        ></ngx-doc-viewer>
      </div>
    </div>
  </div>
</div>
<div class="d-flex" style="padding-top: 1rem">
  <custom-paginator
    #pagination
    (changePageEmit)="handlePageChange($event)"
    [numberOfItems]="totalArchiveCount"
    [itemsPerPage]="10"
    [hidePaginationLabel]="true"
  ></custom-paginator>
</div>
