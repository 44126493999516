<div class="spinner-container" *ngIf="isDataLoading">
  <mat-spinner></mat-spinner>
</div>

<div class="header">
  <div style="max-width: 55vw !important"
    *ngIf="!isDataLoading"
    mat-dialog-title
    class="dialog-heading">
    Audit Log
  </div>
  <div class="modal-close" mat-dialog-close>
    <img src="./../../../../assets/icons/close-solid.svg" />
  </div>
</div>


<div
  style="max-width: 55vw !important"
  *ngIf="!isDataLoading"
  mat-dialog-content
  class="dialog-content"
>
  <ng-container *ngFor="let log of auditLogData">
    <div class="d-flex action-wrapper">
      <div class="created-on">
        {{ log.CreatedOn | date: "dd/MM/yyyy hh:mm:ss" }}
      </div>
      <div class="action">{{ log.Message }}</div>
    </div>
  </ng-container>
</div>
