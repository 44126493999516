<div class="main-container">
  <div mat-dialog-title class="header">
    <div>{{ isOnDocReqPage ? "Log Document" : "Log Product" }}</div>
    <div class="modal-close" mat-dialog-close>
      <img src="./../../../../assets/icons/close-solid.svg" />
    </div>
  </div>
  <div class="modal-content">
    <div class="spinner" *ngIf="isLoading">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isLoading && data.length === 0">
      <div class="no-data-box">
        <div class="img-container">
          <img
            class="width-80"
            src="./../../../../../assets/empty.png"
            alt=""
          />
        </div>
        <div class="descr-container">
          <h6 class="label">No data</h6>
          <span class="descr">Audit Log is empty</span>
        </div>
      </div>
    </div>
    <div class="logs-main-container" *ngIf="!isLoading && data.length > 0">
      <div class="logs-container">
        <div *ngFor="let log of data" class="log">
          <div class="is-seen-wrapper">
            <div class="is-seen seen"></div>
          </div>
          <div class="history-descr" *ngIf="!isAddedMessage(log)">
            <!-- Doc Requirements View -->
            <ng-container *ngIf="isOnDocReqPage; else regularView">
              <span style="color: #636775">
                {{
                  "Document ID #" +
                    log.Requirement?.Id +
                    " - " +
                    getProperLogText(log)
                }}

                <span *ngIf="log.PropertyName === 'Action'">
                  <span
                    [ngStyle]="{
                      color:
                        log.NewValue === 'Valid'
                          ? '#0049c6'
                          : log.NewValue === 'Waived'
                          ? '#6c5dd3'
                          : '#ff754c'
                    }"
                  >
                    {{ log.NewValue }}
                  </span>

                  <span>&nbsp;By&nbsp;</span>
                </span>

                <span
                  class="username"
                  *ngIf="log.PropertyName !== 'Auto generate'"
                  >{{ log.User.FullName }}</span
                >
                <span> on </span>
                <span class="creationDate">{{ log.CreatedOnDate }}</span>
                <span> - </span>
                <span class="creationTime">{{ log.CreatedOnTime }}</span>
              </span>
            </ng-container>

            <!-- Regular view -->
            <ng-template #regularView>
              {{
                "Product ID # " +
                  log.Product?.Id +
                  " - " +
                  log?.PropertyName +
                  " changed from "
              }}
              <span class="old-value">{{ log.OldValue }}</span>
              to
              <span class="new-value">{{ log.NewValue }}</span>
              by
              <span class="username">{{ log.User.FullName }}</span>
              <span> on </span
              ><span class="creationDate">{{ log.CreatedOnDate }}</span
              ><span> - </span
              ><span class="creationTime">{{ log.CreatedOnTime }}</span>
            </ng-template>
          </div>
          <div class="history-descr" *ngIf="isAddedMessage(log)">
            {{ "Product ID # " + log.Product.Id + " - Added By" }}
            <span class="username">{{ log.User.FullName }}</span>
            <span> on </span
            ><span class="creationDate">{{ log.CreatedOnDate }}</span
            ><span> - </span
            ><span class="creationTime">{{ log.CreatedOnTime }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
