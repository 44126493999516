import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputTypesByLength } from '../../tab-data-table/types';

@Component({
  selector: 'regular-input-component',
  templateUrl: './regular-input.component.html',
  styleUrls: ['./regular-input.component.scss'],
})
export class QuestionnaireInput {
  @Input() public elId = '';
  @Input() public inputLabel: string = '';
  @Input() public placeholder: string = '';
  @Input() public control: FormControl = new FormControl('');
  @Input() public inputSize: InputTypesByLength = InputTypesByLength.smallInput;
  @Input() public showValidationError: boolean = false;

  public inputValue = this.control.value;
}
