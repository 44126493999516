import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AnalyticTableItem } from 'src/app/models/analytic';

export interface TableData {
  Analyst: number;
  AverageAging: number;
  CaseStatus: string;
  Daily: number;
  Monthly: number;
  Total: number;
  Expanded?: boolean;
}

@Component({
  selector: 'analytics-data-table',
  templateUrl: './analytics-data-table.component.html',
  styleUrls: ['./analytics-data-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('Expanded', style({ height: '*' })),
      transition(
        'Expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class AnalyticsDataTable implements OnInit, OnChanges {
  @Input() bucket: string = '';
  @Input() reportDate: string = '';
  @Input() allData = [];
  @Input() selectedAnalysts: any[] = [];
  @Input() loading: boolean = false;
  dataSource = [];
  filteredData = [];
  columnsToDisplay = [
    'CaseStatus',
    'Total',
    'Daily',
    'Monthly',
    'AverageAging',
  ];
  columnsToDisplayWithExpand = [...this.columnsToDisplay];
  ExpandedElement!: AnalyticTableItem | null;

  constructor() {}

  handleExpand(element: TableData) {
    element.Expanded =
      element.CaseStatus === 'Total' ? false : !element.Expanded;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reportDate) {
      var d = new Date();
      d.setDate(d.getDate() - 1);
      if (
        changes.reportDate.firstChange ||
        (changes.reportDate.previousValue == '' &&
          changes.reportDate.currentValue == this.formatDate(d))
      ) {
        return;
      }
      if (changes.reportDate.currentValue == this.formatDate(d)) {
        this.reportDate = '';
      }
    }

    this.dataSource = this.allData?.filter(
      (c: { Analyst: string }) => c.Analyst == null
    );

    if (this.dataSource) {
      (this.dataSource as unknown as TableData[]).push({
        Analyst: 0,
        AverageAging: this.dataSource
          .filter((x: TableData) => x.CaseStatus !== 'Not Started')
          .reduce((partialSum, a: TableData) => partialSum + a.AverageAging, 0),
        CaseStatus: 'Total',
        Daily: this.dataSource
          .filter((x: TableData) => x.CaseStatus !== 'Not Started')
          .reduce((partialSum, a: TableData) => partialSum + a.Daily, 0),
        Monthly: this.dataSource
          .filter((x: TableData) => x.CaseStatus !== 'Not Started')
          .reduce((partialSum, a: TableData) => partialSum + a.Monthly, 0),
        Total: this.dataSource
          .filter((x: TableData) => x.CaseStatus !== 'Not Started')
          .reduce((partialSum, a: TableData) => partialSum + a.Total, 0),
      });

      this.filteredData = this.allData.filter((c: { Analyst: string }) =>
        this.selectedAnalysts.length
          ? this.selectedAnalysts.some((analyst) => analyst.label === c.Analyst)
          : c.Analyst != null
      );
    }
  }

  ngOnInit(): void {}

  formatDate(date: Date): string {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    var ddStr = dd.toString();
    var mmStr = mm.toString();
    var yyyyStr = yyyy.toString();

    if (dd < 10) {
      ddStr = '0' + ddStr;
    }
    if (mm < 10) {
      mmStr = '0' + mmStr;
    }

    return ddStr + '-' + mmStr + '-' + yyyyStr;
  }
}
