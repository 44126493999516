import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Helper } from 'src/app/helpers/helper';
import { AppTypes } from 'src/app/layout/types';

@Component({
  selector: 'welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css'],
  host: {
    class: 'welcome',
  },
})
export class WelcomeComponent implements OnInit {
  public get appTypes(): typeof AppTypes {
    return AppTypes;
  }

  constructor(private dialog: MatDialog, private helper: Helper) {}

  ngOnInit(): void {
    this.helper.setIsDataFirstUpload(true);
  }

  openDialog(templateRef: any) {
    let dialogRef = this.dialog.open(templateRef, {
      width: 'auto',
      panelClass: 'welcome-dialog',
    });
  }

  handleNavigateToSelectedApp(appType: AppTypes): void {
    this.helper.setAppType(appType);
    window.localStorage.setItem('isNavOpened', 'true');
  }
}
