<div mat-dialog-title s>
  <h1 class="dialog-heading">Assign</h1>
</div>

<div mat-dialog-content class="assign-content">
  <div
    style="display: flex; justify-content: center"
    class="spinner-container"
    *ngIf="isLoading"
  >
    <mat-spinner></mat-spinner>
  </div>

  <ng-container *ngIf="!isLoading">
    <div class="d-flex" style="justify-content: center">
      <span innerText="Search by name"></span>
      <input
        *ngIf="users.length > 0"
        tabindex="0"
        #searchInput
        id="searchInput"
        class="assign-search"
        [(ngModel)]="searchText"
        placeholder="Search by name"
      />
    </div>
    <ng-container *ngIf="users.length === 0; else displayUsers">
      <div class="no-analysts-box">No analysts found</div>
    </ng-container>
    <ng-template #displayUsers>
      <ng-container *ngFor="let person of users | assignSearch : searchText">
        <div class="list-item d-flex">
          <img
            class="img"
            src="{{ person.ImgUrl }}"
            [ngStyle]="{ background: person.Background }"
            alt="assign-img"
          />

          <div class="assign-btn">
            {{ person.FirstName + " " + person.LastName }}
            <ng-container [ngSwitch]="person.Selected">
              <ng-container *ngSwitchCase="true">
                <div
                  mat-button
                  class="button-base assign-button"
                  (click)="onClickAssign(person)"
                >
                  <img
                    src="./../assets/icons/person-assigned-btn.svg"
                    style="cursor: pointer"
                    alt=""
                  />
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <div
                  mat-button
                  class="button-base assign-button"
                  (click)="onClickAssign(person)"
                >
                  <img
                    src="./../assets/icons/person-assign-btn.svg"
                    style="cursor: pointer"
                    alt=""
                  />
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
</div>

<div mat-dialog-actions class="actions-wrapper">
  <button
    mat-button
    mat-dialog-close
    class="assign-btn-submit"
    [disabled]="isSomeSelected()"
    [mat-dialog-close]="selectedUserId()"
  >
    Select
  </button>
</div>
