<div layout="column" flex id="content" class="page-content">
  <div class="d-flex space-between p-b-30">
    <div class="header-wrapper">
      <span class="page-header">Descoped</span>
      <column-chips-popover
        *ngIf="noDataAvailable"
        (changeDisplayedColumns)="changeDisplayedColumns($event)"
      ></column-chips-popover>
    </div>

    <div class="d-flex p-r-3">
      <button
        mat-button
        (click)="handleRescope()"
        class="button-base action-button m-r-7"
        [disabled]="!rescopeRowSelected"
      >
        Rescope
      </button>
    </div>
  </div>

  <div>
    <data-table
      #descopeTable
      statusFilter="Descoped"
      [dataSource]="dataSource"
      [displayedColumns]="displayedColumns"
      [isMoveToNextStageHidden]="true"
      (selectionEvent)="selectChanged($event)"
      (noDisplayDataEmitter)="handleNoTableData($event)"
    ></data-table>
  </div>
</div>
