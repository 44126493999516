import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Helper} from "../../../../../../../../../../helpers/helper";
import {DocuLibraryService} from "../../../../../../../../../../services/docu-library.service";
import {CcclDataPayload, ClearingParticipantAffiliates} from "./types";
import {DropdownOptions} from "../sdl-data/types";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-cccl-data',
    templateUrl: './cccl-data.component.html',
    styleUrls: ['./cccl-data.component.css']
})
export class CcclDataComponent implements OnInit {
    public isLoading: boolean = false;
    public dataSource: CcclDataPayload | undefined;
    public ccclForm: FormGroup = new FormGroup({});
    public dropdownData: DropdownOptions | null = null;

    get clientId(): string {
        return this.helper.getQuestionnaireClientId();
    }

    constructor(
        private helper: Helper,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private regulatoryService: DocuLibraryService
    ) {
    }

    get clearingParticipantAffiliatesArray() {
        return this.ccclForm.get('clearingParticipantAffiliates') as FormArray;
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.regulatoryService.fetchRegulatoryDropdownOptions().subscribe((dropDawnData) => {
            this.dropdownData = dropDawnData.Data;

            this.regulatoryService.fetchRegulatoryCcclData(parseInt(this.clientId)).subscribe((data) => {
                this.dataSource = data.Data;
                this.fillCcclFormData(this.dataSource);
                this.isLoading = false;
                this.cdr.detectChanges();
            });
        });
    }

    private fillCcclFormData(data: CcclDataPayload): void {
        this.ccclForm = this.formBuilder.group({
            clearingAgencyParticipant: [data.ClearingAgencyParticipant, Validators.required],
            clearingParticipantAffiliate: [data.ClearingParticipantAffiliate, Validators.required],
            largeNotionalCounterparty: [data.LargeNotionalCounterparty, Validators.required],
            notionalThresholdInformation: [data.NotionalThresholdInformation, Validators.required],
            monthYearExceededMonthEndGrossNotionalAmount: [
                data.MonthYearExceededMonthEndGrossNotionalAmount,
                Validators.required
            ],
            clearingAgencyParticipantsThatHaveClearingParticipantAffiliates: [
                data.ClearingAgencyParticipantsThatHaveClearingParticipantAffiliate,
                Validators.required
            ],

            clearingParticipantAffiliates: this.formBuilder.array(
                data.ClearingParticipantAffiliates.length ?
                    data.ClearingParticipantAffiliates.map(x => ({
                            fullLegalNameOfClearingParticipantAffiliates:
                                this.formBuilder.control(x.FullLegalNameOfClearingParticipantAffiliate),
                            leiCiciOfClearingParticipantAffiliate:
                                this.formBuilder.control(x.LEICICIAlternativeIdentifierOfClearingParticipantAffiliate),
                        }),
                    ) : [],
            ),

            nonCanadianLocalCounterparty: [data.NonCanadianLocalCounterparty, Validators.required],
            lastUpdatedOn: [data.DateLastUpdated, Validators.required],
            nameOfSignatory: [data.NameOfSignatory, Validators.required],
            titleOfSignatory: [data.TitleOfSignatory, Validators.required],
        });
    }

    public addNewClearingParticipant(): void {
        this.ccclForm.markAsDirty();

        const newControl = this.formBuilder.control({
            fullLegalNameOfClearingParticipantAffiliates: this.formBuilder.control(null),
            leiCiciOfClearingParticipantAffiliate: this.formBuilder.control(null),
        }, Validators.required);

        this.clearingParticipantAffiliatesArray.push(newControl);
    }

    private formatDataForRequest (): ClearingParticipantAffiliates[] {
        return this.clearingParticipantAffiliatesArray.getRawValue().map(x => {
            return {
                FullLegalNameOfClearingParticipantAffiliate: x.fullLegalNameOfClearingParticipantAffiliates.value,
                LEICICIAlternativeIdentifierOfClearingParticipantAffiliate: x.leiCiciOfClearingParticipantAffiliate.value,
            }
        });
    }

    public updateCcclData(): void {
        this.isLoading = true;

        if (this.dataSource)
            this.regulatoryService.updateRegulatoryCcclData({
                Id: this.dataSource.Id,
                ClientId: this.dataSource.ClientId,
                ClearingAgencyParticipant: this.ccclForm.controls['clearingAgencyParticipant'].value,
                ClearingParticipantAffiliate: this.ccclForm.controls['clearingParticipantAffiliate'].value,
                LargeNotionalCounterparty: this.ccclForm.controls['largeNotionalCounterparty'].value,
                NotionalThresholdInformation: this.ccclForm.controls['notionalThresholdInformation'].value,
                MonthYearExceededMonthEndGrossNotionalAmount:
                    this.ccclForm.controls['monthYearExceededMonthEndGrossNotionalAmount'].value,
                ClearingAgencyParticipantsThatHaveClearingParticipantAffiliate:
                    this.ccclForm.controls['clearingAgencyParticipantsThatHaveClearingParticipantAffiliates'].value,
                NonCanadianLocalCounterparty: this.ccclForm.controls['nonCanadianLocalCounterparty'].value,
                ClearingParticipantAffiliates: this.formatDataForRequest(),
                NameOfSignatory: this.ccclForm.controls['nameOfSignatory'].value,
                TitleOfSignatory: this.ccclForm.controls['titleOfSignatory'].value,
            }).subscribe((res) => {
                this.isLoading = false;
                this.ccclForm.markAsPristine();
                this.snackBar.open('Changes saved!', 'X', {
                    duration: 1500,
                    panelClass: ['success-snackbar'],
                });
                this.cdr.detectChanges();
            });
    }
}
