<div class="search-results-wrapper" [ngStyle]="{ 'height': isPanelOpened ? '42rem' : '0rem' }" *ngIf="isPanelOpened">
    <mat-spinner class="position-absolute" [diameter]="70" *ngIf="isLoading"></mat-spinner>
    <ng-container>
        <ng-container *ngIf="dataSource.length === 0 && isDataFetched; else table">
            <div class="d-flex justify-center align-center w-100 bg-white">
                <img class="h-75" src="../assets/icons/no-data-structure-search.svg">
            </div>
        </ng-container>

        <ng-template #table>
            <div class="d-flex flex-column w-100" [ngStyle]="{ opacity: isPanelOpened ? 1 : 0, 'transition-duration': '0.2s' }">
                <div class="d-flex overflow-hidden">
                    <app-structures-search-result-table
                        class="w-100"
                        [isLoading]="isLoading"
                        [dataSource]="dataSource"
                        [selectedEntityType]="entityType">
                    </app-structures-search-result-table>

                    <div class="d-flex w-0 right-tab-container overflow-auto no-pointer-events" [class.w-20]="rightTabOpened">
                        <app-right-tab
                            class="w-100"
                            *ngIf="rightTabOpened"
                            (reFetchStructureData)="fetchStructuresData()"
                        ></app-right-tab>
                    </div>
                </div>

                <app-search-result-paginator
                    class="w-100"
                    [itemsPerPage]="10"
                    [numberOfItems]="totalCount"
                    [hidePaginationLabel]="true"
                    [isOnSearchView]="rightTabOpened"
                    (changePageEmit)="handlePageChange($event)">
                </app-search-result-paginator>
            </div>
        </ng-template>
    </ng-container>
</div>
