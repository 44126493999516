<div>
    <div class="calendar-header">
        <div>
            <span>{{ date.day }}</span>
            <div ngbDropdown class="d-inline-block">
                <button mat-flat-button type="button" id="mnths-dropdown" ngbDropdownToggle>{{ months[date.month === 0 ? 0 : date.month - 1].label }}</button>
                <div ngbDropdownMenu class="months-dropdown" aria-labelledby="mnths-dropdown">
                  <button *ngFor="let month of months, let idx = index" ngbDropdownItem [disabled]="month.value > currentDate.getMonth() + 1" (click)="changeMonth(month.value)">{{ month.label }}</button>
                </div>
            </div>
        </div>
        <div>
            <span>{{ date.year }}</span>
        </div>
    </div>

    <ngb-datepicker #datepicker [(ngModel)]="model" (dateSelect)="onDateSelect($event)" [minDate]="minDate" [maxDate]="maxDate" class="wiam-calendar" ></ngb-datepicker>
    <div class="width-100 d-flex justify-content-center">
        <button mat-raised-button [disabled]="isPreviousMonthDisabled" (click)="handlePrevious()"><mat-icon>arrow_back</mat-icon></button>
        <button mat-raised-button [disabled]="isNextMonthDisabled" (click)="handleNext()"><mat-icon>arrow_forward</mat-icon></button>
    </div>
    <div class="m-x-24 m-y-15 border-bottom-1"></div>
</div>
