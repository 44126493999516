import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Helper} from "../../../../../../../../../../helpers/helper";
import {DocuLibraryService} from "../../../../../../../../../../services/docu-library.service";
import {Df1DataPayload} from "../df1-data/types";
import {EmirDataPayload} from "./types";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-emir-data',
    templateUrl: './emir-data.component.html',
    styleUrls: ['./emir-data.component.css']
})
export class EmirDataComponent implements OnInit {
    public isLoading: boolean = false;
    public dataSource: EmirDataPayload | undefined;
    public emirForm: FormGroup = new FormGroup({});

    get clientId(): string {
        return this.helper.getQuestionnaireClientId();
    }

    constructor(
        private helper: Helper,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private regulatoryService: DocuLibraryService
    ) {
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.regulatoryService.fetchRegulatoryEmirData(parseInt(this.clientId)).subscribe((res) => {
            this.dataSource = res.Data;
            this.fillEmirFormData(this.dataSource);
            this.isLoading = false;
            this.cdr.detectChanges();
        });
    }

    private fillEmirFormData(data: EmirDataPayload): void {
        this.emirForm = this.formBuilder.group({
            institution: [data.Institution, Validators.required],
            thirdCountryEntity: [data.ThirdCountryEntity, Validators.required],
            outOfScopeEntity: [data.OutofScopeEntity, Validators.required],
            outOfScopeEntityType: [data.OutofScopeEntityType, Validators.required],
            emirEntityType: [data.EMIREntityType, Validators.required],
            clearingThreshold: [data.ClearingThreshold, Validators.required],
            pensionSchemeArrangement: [data.PensionSchemeArrangement, Validators.required],
            utilizingPensionSchemeExemption: [data.UtilizingPensionSchemeExemption, Validators.required],

            clearingCategoryIrs: [data.ClearingCategoryIRS, Validators.required],
            clearingCategorySecondRatesRts: [data.ClearingCategorySecondRatesRTS, Validators.required],
            clearingCategoryIndexCds: [data.ClearingCategoryIndexCDS, Validators.required],
            alternativeInvestmentFund: [data.AlternativeInvestmentFund, Validators.required],
            typeOfPensionSchemeArrangement: [data.TypeOfPensionSchemeArrangement, Validators.required],
            useOfPensionArrangementExemption: [data.UseOfPensionArrangementExemption, Validators.required],
            entityCorporateSector: [data.EntityCorporateSector, Validators.required],
            tradingCapacityOfReportingParty: [data.TradingCapacityOfReportingParty, Validators.required],
            markitBeneficiaryId: [data.MarkitBeneficiaryID, Validators.required],
            transactionsUndertakenByEntityDirectlyLinkedToCommercialActivityOrTreasuryFinancing: [
                data.TransactionsUndertakenByThisEntityAreDirectlyLinkedToCommercialActivityOrTreasuryFinancing,
                Validators.required
            ],
            dateLastUpdated: [data.DateLastUpdated, Validators.required],
            useOfPensionArrangementExemptionTradeReporting: [data.UtilizingPensionSchemeExemption, Validators.required],
        });
    }

    public updateEmirData(): void {
        this.isLoading = true;
        if (this.dataSource)
            this.regulatoryService.updateRegulatoryEmirData({
                Id: this.dataSource.Id,
                ClientId: this.dataSource.ClientId,
                Institution: this.emirForm.controls['institution'].value,
                ThirdCountryEntity: this.emirForm.controls['thirdCountryEntity'].value,
                OutofScopeEntity: this.emirForm.controls['outOfScopeEntity'].value,
                OutofScopeEntityType: this.emirForm.controls['outOfScopeEntityType'].value,
                EMIREntityType: this.emirForm.controls['emirEntityType'].value,
                ClearingThreshold: this.emirForm.controls['clearingThreshold'].value,
                PensionSchemeArrangement: this.emirForm.controls['pensionSchemeArrangement'].value,
                UtilizingPensionSchemeExemption: this.emirForm.controls['utilizingPensionSchemeExemption'].value,
                ClearingCategoryIRS: this.emirForm.controls['clearingCategoryIrs'].value,
                ClearingCategorySecondRatesRTS: this.emirForm.controls['clearingCategorySecondRatesRts'].value,
                ClearingCategoryIndexCDS: this.emirForm.controls['clearingCategoryIndexCds'].value,
                AlternativeInvestmentFund: this.emirForm.controls['alternativeInvestmentFund'].value,
                TypeOfPensionSchemeArrangement: this.emirForm.controls['typeOfPensionSchemeArrangement'].value,
                UseOfPensionArrangementExemption: this.emirForm.controls['useOfPensionArrangementExemption'].value,
                EntityCorporateSector: this.emirForm.controls['entityCorporateSector'].value,
                TradingCapacityOfReportingParty: this.emirForm.controls['tradingCapacityOfReportingParty'].value,
                MarkitBeneficiaryID: this.emirForm.controls['markitBeneficiaryId'].value,
                TransactionsUndertakenByThisEntityAreDirectlyLinkedToCommercialActivityOrTreasuryFinancing:
                    this.emirForm.controls['transactionsUndertakenByEntityDirectlyLinkedToCommercialActivityOrTreasuryFinancing'].value,
            }).subscribe(res => {
                this.isLoading = false;
                this.emirForm.markAsPristine();
                this.snackBar.open('Changes saved!', 'X', {
                    duration: 1500,
                    panelClass: ['success-snackbar'],
                });
                this.cdr.detectChanges();
            });
    }
}
