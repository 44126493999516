<div style="background-color: #fff">
  <div class="d-flex space-between p-b-30" style="padding-top: 3rem !important">
    <div class="header-wrapper">
      <div class="page-header d-flex" style="align-items: center">
        User Access Management
      </div>
      <div
        *ngIf="isUserAvailableToAddNewUser"
        matTooltip="You cannot add a new user until you save your changes."
        [matTooltipDisabled]="!addNewUserInProgress && isChangesSaved"
        [matTooltipPosition]="'above'"
      >
        <button
          class="add-new-user-btn"
          (click)="handleAddNewUser()"
          [disabled]="addNewUserInProgress || !isChangesSaved || editingStarted"
          [ngClass]="{ disabled: addNewUserInProgress || !isChangesSaved }"
          mat-button
        >
          Add New User
        </button>
      </div>
    </div>
  </div>

  <div class="spinner-container" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>

  <div
    class="d-flex"
    [ngStyle]="{
      opacity: loading ? 0 : 1,
      height: loading ? '0rem' : 'fit-content',
      overflow: loading ? 'hidden' : 'auto'
    }"
    style="width: 100%; padding-bottom: 1rem"
  >
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- User Id -->
      <ng-container matColumnDef="userId">
        <th mat-header-cell *matHeaderCellDef>
          <div class="d-flex head-sort-wrapper">
            <span
              class="filter-icon"
              [ngStyle]="{
                'background-image': isColumnFiltered('Id')
                  ? 'url(\'./../../assets/icons/product-filter-indicator.svg\')'
                  : null
              }"
            ></span>

            User Id
            
            <div style="width: fit-content">
              <sorting-filtering-component
                [column]="'User Id'"
                [columnNames]="columnNames"
                [menuColumns]="menuColumns"
                [displayedColumns]="displayedColumns"
                [dataSource]="dataSource"
                [isUserAccessDataTable]="true"
                [filterColumns]="this.filterColumns"
                (clickApplyFiltersUserAccess)="
                  handleApplyFiltersUserAccess($event)
                "
                (userAccessDataSourceUpdate)="handleDataSourceUpdate($event)"
                (updateNumberOfTableItems)="
                  handleUpdateNumberOfItemsForPagination($event)
                "
              >
              </sorting-filtering-component>
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="user-id-field">
          {{ element.UserId }}
        </td>
      </ng-container>

      <!-- Employee Name -->
      <ng-container matColumnDef="employeeName">
        <th mat-header-cell *matHeaderCellDef>
          <div class="d-flex head-sort-wrapper">
            <span
              class="filter-icon"
              [ngStyle]="{
                'background-image': isColumnFiltered('FullName')
                  ? 'url(\'./../../assets/icons/product-filter-indicator.svg\')'
                  : null
              }"
            ></span>

            Employee Name
            
            <sorting-filtering-component
              [column]="'Employee Name'"
              [columnNames]="columnNames"
              [menuColumns]="menuColumns"
              [displayedColumns]="displayedColumns"
              [dataSource]="dataSource"
              [isUserAccessDataTable]="true"
              [filterColumns]="this.filterColumns"
              (clickApplyFiltersUserAccess)="
                handleApplyFiltersUserAccess($event)
              "
              (userAccessDataSourceUpdate)="handleDataSourceUpdate($event)"
              (updateNumberOfTableItems)="
                handleUpdateNumberOfItemsForPagination($event)
              "
            >
            </sorting-filtering-component>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          style="background: transparent"
          [ngClass]="{
            'edit-mode':
              (editingStarted || addNewUserInProgress) &&
              currentEditNode.UserId === element.UserId
          }"
        >
          <ng-container>
            <div
              *ngIf="
                (editingStarted || addNewUserInProgress) &&
                  currentEditNode.UserId === element.UserId &&
                  currentEditNode.UserId === 0;
                else elseBlock
              "
              [formGroup]="userForm"
            >
              <input
                matInput
                placeholder="Enter Name"
                class="edit-input"
                [value]="element.EmployeeName"
                style="background: transparent !important"
                [(ngModel)]="currentEditNode.EmployeeName"
                (ngModelChange)="modelChanged($event, 'EmployeeName')"
                formControlName="name"
                [ngClass]="{
                  disabled: editingStarted && currentEditNode.UserId !== 0
                }"
              />
              <div
                class="error-container"
                *ngIf="
                  userForm.touched &&
                  userForm.controls['name'].hasError('required')
                "
              >
                <div class="error-message">Name is required!</div>
              </div>
            </div>

            <ng-template #elseBlock>
              {{ element.EmployeeName }}
            </ng-template>
          </ng-container>
        </td>
      </ng-container>

      <!-- Email -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>
          <div class="d-flex head-sort-wrapper">
            <span
              class="filter-icon"
              [ngStyle]="{
                'background-image': isColumnFiltered('Email')
                  ? 'url(\'./../../assets/icons/product-filter-indicator.svg\')'
                  : null
              }"
            ></span>
            Email
            
            <sorting-filtering-component
              [column]="'Email'"
              [columnNames]="columnNames"
              [menuColumns]="menuColumns"
              [displayedColumns]="displayedColumns"
              [dataSource]="dataSource"
              [isUserAccessDataTable]="true"
              [filterColumns]="this.filterColumns"
              (clickApplyFiltersUserAccess)="
                handleApplyFiltersUserAccess($event)
              "
              (userAccessDataSourceUpdate)="handleDataSourceUpdate($event)"
              (updateNumberOfTableItems)="
                handleUpdateNumberOfItemsForPagination($event)
              "
            >
            </sorting-filtering-component>
          </div>
        </th>

        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'edit-mode':
              (editingStarted || addNewUserInProgress) &&
              currentEditNode.UserId === element.UserId,
            blocked:
              (editingStarted || addNewUserInProgress) &&
              currentEditNode.UserId === element.UserId &&
              !userForm.controls['name'].value
          }"
          matTooltip="Please enter employee name first."
          [matTooltipDisabled]="
            ((!editingStarted || !addNewUserInProgress) &&
              currentEditNode.UserId !== element.UserId) ||
            !!userForm.controls['name'].value
          "
        >
          <ng-container>
            <div
              [formGroup]="userForm"
              *ngIf="
                (editingStarted || addNewUserInProgress) &&
                  currentEditNode.UserId === element.UserId &&
                  currentEditNode.UserId === 0;
                else elseBlock
              "
            >
              <input
                matInput
                placeholder="Enter Email"
                class="edit-input"
                [value]="element.Email"
                [(ngModel)]="currentEditNode.Email"
                style="background: transparent !important"
                (ngModelChange)="modelChanged($event, 'Email')"
                formControlName="email"
                [ngClass]="{
                  disabled:
                    (editingStarted && currentEditNode.UserId !== 0) ||
                    ((editingStarted || addNewUserInProgress) &&
                      currentEditNode.UserId === element.UserId &&
                      !userForm.controls['name'].value)
                }"
              />
              <div
                class="error-container"
                *ngIf="
                  userForm.touched &&
                  userForm.controls['email'].hasError('required')
                "
              >
                <div class="error-message">Email is required!</div>
              </div>
            </div>
            <ng-template #elseBlock>
              {{ element.Email }}
            </ng-template>
          </ng-container>
        </td>
      </ng-container>

      <!-- Access Role -->
      <ng-container matColumnDef="accessRole">
        <th mat-header-cell *matHeaderCellDef>
          <div class="d-flex head-sort-wrapper">
            <span
              class="filter-icon"
              [ngStyle]="{
                'background-image': isColumnFiltered('Role')
                  ? 'url(\'./../../assets/icons/product-filter-indicator.svg\')'
                  : null
              }"
            ></span>

            Access Role
            
            <div style="width: fit-content">
              <sorting-filtering-component
                [column]="'Access Role'"
                [columnNames]="columnNames"
                [menuColumns]="menuColumns"
                [displayedColumns]="displayedColumns"
                [dataSource]="dataSource"
                [isUserAccessDataTable]="true"
                [filterColumns]="this.filterColumns"
                (clickApplyFiltersUserAccess)="
                  handleApplyFiltersUserAccess($event)
                "
                (userAccessDataSourceUpdate)="handleDataSourceUpdate($event)"
                (updateNumberOfTableItems)="
                  handleUpdateNumberOfItemsForPagination($event)
                "
              >
              </sorting-filtering-component>
            </div>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'edit-mode':
              (editingStarted || addNewUserInProgress) &&
              currentEditNode.UserId === element.UserId,
            'pt-10':
              userForm.touched &&
              userForm.controls['manager'].hasError('required') &&
              element.UserId === currentEditNode.UserId,
            blocked:
              (editingStarted || addNewUserInProgress) &&
              currentEditNode.UserId === element.UserId &&
              !userForm.controls['email'].value
          }"
          matTooltip="Please enter employee email first."
          [matTooltipDisabled]="
            ((!editingStarted || !addNewUserInProgress) &&
              currentEditNode.UserId !== element.UserId) ||
            !!userForm.controls['email'].value
          "
        >
          <div
            #t="matMenuTrigger"
            #accessRoleControl
            class="access-role-chips"
            [matMenuTriggerFor]="accessMenu"
            [ngClass]="{
              disabled:
                element.UserId !== currentEditNode.UserId ||
                (element.UserId === 0
                  ? !addNewUserInProgress
                  : !editingStarted) ||
                ((editingStarted || addNewUserInProgress) &&
                  currentEditNode.UserId === element.UserId &&
                  !userForm.controls['email'].value)
            }"
            (click)="handleAccessRoleChange($event, t)"
          >
            <div
              class="d-flex chips-wrapper"
              *ngIf="
                !element.AccessRoles.length &&
                  (editingStarted || addNewUserInProgress) &&
                  currentEditNode.UserId === element.UserId;
                else rolesPlaceholder
              "
            >
              <div class="chip-item">
                <div [style]="{ color: '#b1b1b1', 'font-weight': 300 }">
                  Select user roles
                </div>
              </div>
            </div>
            <ng-template #rolesPlaceholder>
              <ng-container *ngFor="let item of element.AccessRoles">
                <div
                  name="remove-btn"
                  class="chip-item"
                  (click)="setRemoveItem(item)"
                >
                  <div>
                    {{ item }}
                  </div>

                  <div class="d-flex remove-icon-box" name="remove-btn">
                    <img
                      name="remove-btn"
                      alt="remove"
                      src="./../assets/icons/remove-access-role.svg"
                    />
                  </div>
                </div>
              </ng-container>
            </ng-template>
          </div>

          <mat-menu
            class="access-role-menu"
            #accessMenu="matMenu"
            id="user-access-button-grroup"
          >
            <mat-button-toggle-group
              multiple
              name="ingredients"
              aria-label="Ingredients"
              [ngStyle]="{
                width: accessRoleControlWidth
                  ? accessRoleControlWidth - 1 + 'px'
                  : 'fit-content'
              }"
            >
              <div class="access-role">
                <ng-container *ngFor="let accessRole of element.AllRoles">
                  <mat-button-toggle
                    id="accessRole"
                    [value]="accessRole"
                    class="access-role-item"
                    (click)="handleAddNewRoleChip($event, element)"
                    [checked]="checkIfSelected(element, accessRole)"
                  >
                    <div
                      class="d-flex"
                      style="align-items: center; padding-left: 10px"
                    >
                      <div
                        class="selectedAccessRoleIndicator"
                        *ngIf="checkIfSelected(element, accessRole)"
                      ></div>
                      <div class="label">
                        {{ accessRole }}
                      </div>
                    </div>
                  </mat-button-toggle>
                </ng-container>
              </div>
            </mat-button-toggle-group>
          </mat-menu>
          <div
            class="error-container"
            *ngIf="
              userForm.touched &&
              userForm.controls['accessRoles'].hasError('required') &&
              element.UserId === currentEditNode.UserId
            "
          >
            <div class="error-message">Role selection is required!</div>
          </div>
        </td>
      </ng-container>

      <!-- Manager -->
      <ng-container matColumnDef="manager">
        <th mat-header-cell *matHeaderCellDef>
          <div class="d-flex head-sort-wrapper">
            <span
              class="filter-icon"
              [ngStyle]="{
                'background-image': isColumnFiltered('Manager')
                  ? 'url(\'./../../assets/icons/product-filter-indicator.svg\')'
                  : null
              }"
            ></span>

            Manager
            
            <sorting-filtering-component
              [column]="'Manager'"
              [columnNames]="columnNames"
              [menuColumns]="menuColumns"
              [displayedColumns]="displayedColumns"
              [dataSource]="dataSource"
              [isUserAccessDataTable]="true"
              [filterColumns]="this.filterColumns"
              (clickApplyFiltersUserAccess)="
                handleApplyFiltersUserAccess($event)
              "
              (userAccessDataSourceUpdate)="handleDataSourceUpdate($event)"
              (updateNumberOfTableItems)="
                handleUpdateNumberOfItemsForPagination($event)
              "
            >
            </sorting-filtering-component>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'edit-mode':
              (editingStarted || addNewUserInProgress) &&
              currentEditNode.UserId === element.UserId,
            'pt-10':
              userForm.touched &&
              userForm.controls['manager'].hasError('required') &&
              element.UserId === currentEditNode.UserId,
            blocked:
              (editingStarted || addNewUserInProgress) &&
              currentEditNode.UserId === element.UserId &&
              !userForm.controls['accessRoles'].value.length
          }"
          matTooltip="Please choose user role first."
          [matTooltipDisabled]="
            ((!editingStarted || !addNewUserInProgress) &&
              currentEditNode.UserId !== element.UserId) ||
            !!userForm.controls['accessRoles'].value.length
          "
        >
          <div
            #t="matMenuTrigger"
            #managerControl
            class="dropdown-box"
            [matMenuTriggerFor]="menu"
            [ngClass]="{
              disabled:
                (!editingStarted && !addNewUserInProgress) ||
                element.UserId !== currentEditNode.UserId ||
                isManagerSelectDisabled ||
                ((editingStarted || addNewUserInProgress) &&
                  !userForm.controls['accessRoles'].value.length)
            }"
          >
            <div class="content">
              {{ element.Manager }}
            </div>
            <div class="arrow-toggle">
              <img
                alt="arrow"
                [ngClass]="{ rotate: t.menuOpen }"
                src="./../assets/icons/black-arrow.svg"
              />
            </div>
          </div>
          <mat-menu class="manager-menu" #menu="matMenu">
            <ng-container *ngFor="let manager of element.ManagersList">
              <button
                [ngStyle]="{
                  width: managerControlWidth
                    ? managerControlWidth - 1 + 'px'
                    : 'fit-content'
                }"
                mat-menu-item
                class="menu-item"
                (click)="handleChangeManager($event, element)"
              >
                <div class="d-flex button-content-wrapper">
                  <div class="manager-name">
                    {{ manager }}
                  </div>
                  <div class="selected-indicator">
                    <ng-container [ngSwitch]="element.Manager === manager">
                      <ng-container *ngSwitchCase="true">
                        <div class="selected-item">
                          <img
                            alt="selected item icon"
                            src="./../assets/icons/manager-selected.svg"
                          />
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="false">
                        <div class="not-selected-item"></div>
                      </ng-container>
                      <ng-container *ngSwitchDefault></ng-container>
                    </ng-container>
                  </div>
                </div>
              </button>
            </ng-container>

            <div
              (click)="handleSearchChange($event)"
              class="search-submit-wrapper"
            >
              <input
                type="text"
                class="search-input"
                placeholder="Search by name"
                (change)="handleSearchChange($event)"
                (click)="$event.stopPropagation()"
                style="margin-bottom: 2rem"
              />
            </div>
          </mat-menu>
          <div
            class="error-container"
            *ngIf="
              userForm.touched &&
              userForm.controls['manager'].hasError('required') &&
              element.UserId === currentEditNode.UserId
            "
          >
            <div class="error-message">Manager selection is required!</div>
          </div>
        </td>
      </ng-container>

      <!-- Active -->
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef>
          <div class="d-flex head-sort-wrapper">
            Active
            <!-- <span
                class="filter-icon"
                [ngStyle]="{
                  'background-image': isColumnFiltered('Active')
                    ? 'url(\'./../../assets/icons/product-filter-indicator.svg\')'
                    : null
                }"
              ></span>
              <div style="width: fit-content">
                <sorting-filtering-component
                  [column]="'Active'"
                  [columnNames]="columnNames"
                  [menuColumns]="menuColumns"
                  [displayedColumns]="displayedColumns"
                  [dataSource]="dataSource"
                  [isUserAccessDataTable]="true"
                  [filterColumns]="this.filterColumns"
                  (clickApplyFiltersUserAccess)="
                    handleApplyFiltersUserAccess($event)
                  "
                  (userAccessDataSourceUpdate)="handleDataSourceUpdate($event)"
                >
                </sorting-filtering-component>
              </div> -->
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div
            [ngClass]="{
              disabled:
                element.UserId !== currentEditNode.UserId ||
                ((editingStarted || addNewUserInProgress) &&
                  !userForm.controls['manager'].value.length)
            }"
          >
            <mat-slide-toggle
              [ngClass]="{
                disabled: element.UserId !== currentEditNode.UserId
              }"
              [(ngModel)]="element.Active"
              (toggleChange)="onActiveToggleChange($event, element)"
            ></mat-slide-toggle>
          </div>
        </td>
      </ng-container>

      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex" style="justify-content: center">
            <!-- edit button -->
            <ng-template #tick>
              <div
                [ngClass]="{
                  disabled:
                    (element.UserId !== currentEditNode.UserId &&
                      editingStarted) ||
                    isTableReadOnly
                }"
                class="d-flex align-center justify-center"
                style="margin-right: 0.5rem; cursor: pointer"
              >
                <img
                  alt="arrow-right"
                  style="padding-right: 1rem"
                  src="./../assets/icons/edit-btn.svg"
                  (click)="handleStartEditing(element)"
                />
              </div>
            </ng-template>

            <!-- stop editing button -->
            <div
              *ngIf="
                (editingStarted || addNewUserInProgress) &&
                  element.UserId === currentEditNode.UserId;
                else tick
              "
              class="d-flex align-center justify-center"
              style="margin-right: 0.5rem; cursor: pointer"
            >
              <img
                alt="save"
                style="padding-right: 1rem"
                src="./../assets/icons/tick.svg"
                (click)="handleStopEditing()"
              />
            </div>

            <!-- remove item button -->
            <div
              *ngIf="isUserAvailableToAddNewUser"
              class="d-flex align-center justify-center"
              [ngClass]="{ disabled: isTableReadOnly }"
            >
              <img
                alt="arrow-right"
                style="cursor: pointer"
                src="./../assets/icons/remove.svg"
                (click)="onDeleteClick(element.UserId)"
              />
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div
    class="d-flex"
    *ngIf="changesWereMade && !editingStarted"
    style="justify-content: flex-end; margin-bottom: 1rem; margin-right: 1rem"
  >
    <button mat-button (click)="onSaveClick()" class="add-new-user-btn">
      Save
    </button>
  </div>

  <div class="pagination-wrapper">
    <custom-paginator
      #pagination
      (changePageEmit)="handlePageChange($event)"
      [numberOfItems]="numberOfUserAccessItems"
      [itemsPerPage]="paginator.pageSize"
    ></custom-paginator>
  </div>
</div>
