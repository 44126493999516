<div mat-dialog-title class="dialog-heading">Item stage change</div>

<div mat-dialog-content class="dialog-content">
  <p *ngIf="isDiscopeDialog">
    All the selected items but
    {{ descopeItemsToBeExcluded.length > 1 ? "items" : "the item" }}
    <b>{{ descopeItemsToBeExcluded.join(",  ") }}</b> will be processed.
    {{ descopeItemsToBeExcluded.length > 1 ? "Items" : "Item" }}
    <b>{{ descopeItemsToBeExcluded.join(",  ") }}</b>
    {{ descopeItemsToBeExcluded.length > 1 ? "have" : "has" }} already been
    descoped
  </p>

  <p *ngIf="isRescopeDialog">
    All the selected items but
    {{ rescopeItemsToBeExcluded.length > 1 ? "items" : "the item" }}
    <b>{{ rescopeItemsToBeExcluded.join(",  ") }}</b> will be processed.
    {{ rescopeItemsToBeExcluded.length > 1 ? "Items" : "Item" }}
    <b>{{ rescopeItemsToBeExcluded.join(",  ") }}</b>
    {{ rescopeItemsToBeExcluded.length > 1 ? "are" : "is" }} not in Descope
    stage
  </p>

  <button
    mat-icon-button
    aria-label="Close"
    tabindex="-1"
    (click)="closeDialog()"
    class="btn-modal-close"
  ></button>
</div>

<div mat-dialog-actions class="dialog-actions">
  <button
    mat-button
    data-test-id="confirmation-no-btn"
    color="primary"
    class="confirm-button"
    (click)="confirm()"
    cdkFocusInitial
  >
    Ok
  </button>
</div>
