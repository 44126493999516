<div class="flex-row">
  <div>
    <h2>Filter by {{ filteredColumn.ColumnName }}</h2>
  </div>
  <div class="clean-btn">
    <button
      mat-stroked-button
      color="primary"
      (click)="deleteFilter()"
      [disabled]="
        filteredColumn.Includes.length == 0 &&
        filteredColumn.Excludes.length == 0
      "
    >
      Clean the filter
    </button>
  </div>
</div>
<div class="fcontainer frows m-t-10">
  <div class="frow form-one-line flex-row">
    <div class="col4 form-group">
      <input
        class="input-filter"
        type="text"
        #includes
        [(ngModel)]="includeText"
        placeholder="Search for what to include"
        [matAutocomplete]="auto"
        [matChipInputFor]="includeChipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="addIncludes($event)"
        [formControl]="includesControl"
      />
      <mat-chip-list #includeChipList>
        <mat-chip
          *ngFor="let include of filteredColumn.Includes"
          color="primary"
          selected
          (removed)="removeIncludes(include)"
        >
          {{ include }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-list>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selectedInclude($event)"
      >
        <mat-option
          *ngFor="let option of filteredIncludeOptions | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </div>
    <div class="col4 form-group">
      <input
        class="input-filter"
        type="text"
        #excludes
        [(ngModel)]="excludeText"
        placeholder="Search for what to exclude"
        [matAutocomplete]="auto2"
        [matChipInputFor]="excludeChipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="addExcludes($event)"
        [formControl]="excludesControl"
      />
      <mat-chip-list #excludeChipList>
        <mat-chip
          *ngFor="let exclude of filteredColumn.Excludes"
          color="accent"
          selected
          (removed)="removeExcludes(exclude)"
        >
          {{ exclude }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-list>
      <mat-autocomplete
        #auto2="matAutocomplete"
        (optionSelected)="selectedExclude($event)"
      >
        <mat-option
          *ngFor="let option of filteredExcludeOptions | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </div>
  <button
    mat-icon-button
    aria-label="Close"
    (click)="close()"
    class="btn-modal-close"
  ></button>
</div>
<div class="form-control-buttons center">
  <button
    mat-stroked-button
    class="cancelBtn"
    color="primary"
    (click)="close()"
  >
    Cancel
  </button>
  <button mat-raised-button color="primary">Save</button>
</div>
