export enum ArchiveTableKeys {
  RequirementId = 'DocumentID',
  DocumentCategoryName = 'Category',
  DocumentRequirementName = 'ListOfRequirements',
  UploadedOn = 'UploadedOn',
  UploadedBy = 'UploadedBy',
  Delete = 'Delete',
}

export interface ArchiveDataSource {
  DocumentCategoryName: string;
  DocumentRequirementName: string;
  DocumentUrl: string;
  Id: number;
  RequirementId: number;
  Total: number;
  UploadedBy: string;
  UploadedOn: string;
}
