import { Component, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'action-assign-dialog',
  templateUrl: './descope-action-dialog.component.html',
  styleUrls: ['./descope-action-dialog.component.css'],
})
export class DescopeAssignDialog implements OnInit, OnDestroy {
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DescopeAssignDialog>
  ) {
    this.data = this.data.filter((el: any) => el.Stage !== 'Descoped');
  }

  @Input() itemForDescope: any = {};

  public descopeReason: string = '';

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.data = [];
  }

  handleRemove(item: any): void {
    const index = this.data.indexOf(item);
    if (index > -1) {
      this.data.splice(index, 1);
    }
    if(this.data.length === 0) {
      this.dialogRef.close({ data: this.data });
    }
  }

  handleClose() {
    this.dialogRef.close();
  }

  handleSave() {
    this.dialogRef.close({ reasonForDescope: this.descopeReason, data: this.data });
  }
}
