import {Component, Input, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Helper} from 'src/app/helpers/helper';
import {QuestionnaireService} from 'src/app/services/questionnaire.service';
import {ArchiveComponent} from './archive/archive.component';
import {MatDialog} from '@angular/material/dialog';
import {AddNewDocument} from './add-new-document-dialog/add-new-document.component';
import {UploadNewDocument} from './new-document-upload/new-document-upload.component';
import {DocRequirement} from './types';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuditLogModalComponent} from '../audit-log-modal/audit-log-modal.component';
import {StatisticService} from 'src/app/services/statistic.service';
import {EffectiveDateValidationComponent} from "./effective-date-validation/effective-date-validation.component";

@Component({
  selector: 'document-requirement',
  templateUrl: 'document-requirement.component.html',
  styleUrls: ['document-requirement.component.scss'],
})
export class DocumentRequirementComponent implements OnInit {
  public isChildRouteActive: boolean = false;
  public isPreviewOpened: boolean = false;
  public componentInstance: ArchiveComponent | undefined;
  public selectedFile: File | null = null;

  public documentUrl: string = '';
  public isPreviewVisible: boolean = false;
  public isPreviewDataLoading: boolean = false;
  public isTableDataChanged: boolean = false;
  public isSubmitEnabled: boolean = true;
  public archiveDocumentUrl: string = '';
  public selectedCommentId: number = 0;

  public get caseId() {
    return this.helper.getQuestionnaireCaseId();
  }

  public get currentCaseStage() {
    return this.helper.getQuestionnaireCaseStage();
  }

  @Input() public loading: boolean = false;
  @Input() public dataSource: any[] = [];
  @Input('clientId') public clientId: string = '';

  public displayedColumns: string[] = [
    'checkbox',
    'Id',
    'DocumentCategory',
    'DocumentRequirement',
    'Action',
    'Validity',
    'UploadedOn',
    'EffectiveDate',
    'ValidUntil',
    'UploadDocument',
    'Comment',
    'EditLock',
  ];
  availableActions: string[] = ['Request', 'Valid', 'Waive'];

  constructor(
    private helper: Helper,
    private router: Router,
    public dialog: MatDialog,
    private questionnaireService: QuestionnaireService,
    private statisticService: StatisticService,
    private _snackBar: MatSnackBar,
  ) {
    this.router.events.subscribe((event) => {
      if (
        event instanceof NavigationEnd &&
        this.router.url.includes('/questionnaire') &&
        !this.router.url.includes('archive')
      )
        this.navigateToParentRoute();
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AddNewDocument, {autoFocus: false});

    dialogRef.afterClosed().subscribe((result) => {
      this.loading = true;

      this.questionnaireService
        .getDocumentRequirements(this.clientId)
        .subscribe((res: any) => {
          if (res.Success) {
            this.dataSource = res.Data.map((item: any) => ({
              ...item,
              Action:
                item.Action !== null
                  ? item.Action
                  : item.Validity === 'Expired'
                    ? 'Request'
                    : 'Valid',
              isLocked: true,
            }));
          }
          this.loading = false;
        });
    });
  }

  public navigateToChildRoute() {
    this.router.navigate(['/questionnaire', this.caseId, 'archive']);
    this.helper.updateQuestionnaireHeader(true);
    this.isChildRouteActive = true;
  }

  public navigateToParentRoute() {
    this.router.navigate(['/questionnaire', this.caseId]);
    this.isChildRouteActive = false;
    this.helper.updateQuestionnaireHeader(false);
  }

  ngOnInit(): void {
    this.loading = true;
    const currentStage = this.helper.getQuestionnaireCaseStage();

    switch (currentStage) {
      case 'Initial Review':
        this.questionnaireService
          .deleteRequirement(this.caseId!, +this.clientId)
          .subscribe(() => {
            this.questionnaireService
              .autogenerateRequirement(this.caseId, this.clientId)
              .subscribe((res: any) => {
                if (res.Success) {
                  this.questionnaireService
                    .getDocumentRequirements(this.clientId)
                    .subscribe((res: any) => {
                      this.loading = false;
                      if (res.Success) {
                        this.dataSource = res.Data.map((item: any) => ({
                          ...item,
                          Action:
                            item.Action !== null
                              ? item.Action
                              : item.Validity === 'Expired'
                                ? 'Request'
                                : 'Valid',
                          isLocked: true,
                        }));
                      }
                    });
                }
              });
          });
        break;
      case 'Outreach':
        this.questionnaireService
          .getDocumentRequirements(this.clientId)
          .subscribe((res: any) => {
            this.loading = false;
            if (res.Success) {
              this.dataSource = res.Data.map((item: any) => ({
                ...item,
                Action:
                  item.Action !== null
                    ? item.Action
                    : item.Validity === 'Expired'
                      ? 'Request'
                      : 'Valid',
                isLocked: true,
              }));
            }
          });

        break;

      default:
        break;
    }

    this.helper
      .onPreviewOpenedChange()
      .subscribe((isOpened) => (this.isPreviewOpened = isOpened));

    this.helper
      .onArchiveDocUrlChange()
      .subscribe((archiveDocUrl) => (this.archiveDocumentUrl = archiveDocUrl));

    this.helper.onShouldDocRequirementsUpdate().subscribe(() => {
      this.questionnaireService
        .getDocumentRequirements(this.clientId)
        .subscribe((res: any) => {
          if (res.Success) {
            this.dataSource = res.Data.map((item: any) => ({
              ...item,
              Action:
                item.Action !== null
                  ? item.Action
                  : item.Validity === 'Expired'
                    ? 'Request'
                    : 'Valid',
              isLocked: true,
            }));
          }
        });
    });
  }

  checkIfAllRowsLocked(): boolean {
    if (this.dataSource.every((i) => i.isLocked === true)) {
      return true;
    } else if (this.dataSource.every((i) => i.isLocked === false)) {
      return false;
    } else {
      return true;
    }
  }

  changeRequirementAction(
    index: number,
    currentAction: string,
    setNextAction: boolean
  ): void {
    const currentActionIndex = this.availableActions.indexOf(currentAction);

    this.isTableDataChanged = true;
    if (setNextAction) {
      this.dataSource[index].Action =
        this.availableActions[
        (currentActionIndex + 1) % this.availableActions.length
          ];
    } else {
      this.dataSource[index].Action =
        this.availableActions[
        (currentActionIndex - 1 + this.availableActions.length) %
        this.availableActions.length
          ];
    }
  }

  onCommentChange(event: any, index: number): void {
    this.isTableDataChanged = true;
    this.dataSource[index].Comment = event.target.value;
  }

  handleLockAll(): void {
    const isAllRowsLocked = this.checkIfAllRowsLocked();
    if (isAllRowsLocked) {
      this.dataSource.forEach((i) => {
        i.isLocked = false;
      });
    } else {
      this.dataSource.forEach((i) => {
        i.isLocked = true;
      });
    }
  }

  handleClose() {
    this.helper.updatePreviewOpened(false);
  }

  handleOpenUploadNewDoc(requirement: DocRequirement) {
    const dialogRef = this.dialog.open(UploadNewDocument, {
      autoFocus: false,
      data: {requirement},
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.questionnaireService
        .getDocumentRequirements(this.clientId)
        .subscribe((res: any) => {
          if (res.Success) {
            this.dataSource = res.Data.map((item: any) => ({
              ...item,
              Action:
                item.Action !== null
                  ? item.Action
                  : item.Validity === 'Expired'
                    ? 'Request'
                    : 'Valid',
              isLocked: true,
            }));
          }
        });
    });
  }

  openDocumentPreview(url: string): void {
    if (url === null || url === '') {
      this._snackBar.open('There are no files attached to this requirement', 'X', {
        duration: 4000,
        panelClass: ['error-snackbar'],
      });
      return;
    }
    this.isPreviewDataLoading = true;
    this.isPreviewVisible = !this.isPreviewVisible;
    this.documentUrl = url;
  }

  closeDocumentPreview(): void {
    this.isPreviewVisible = false;
  }

  previewDataLoaded(): void {
    this.isPreviewDataLoading = false;
  }

  effectiveDateChanged(event: any): void {
    const date = new Date(event.value);
    const day = date.getDate().toString();

    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    this.dataSource[event.idx].EffectiveDate = new Date(
      new Date(`${year}/${month}/${day}`)
    ).toISOString();

    this.questionnaireService
      .updateExistingDocument({
        CaseId: this.caseId!,
        RequirementToSave: this.dataSource,
      })
      .subscribe((res: any) => {
        if (res.Success) {
          this._snackBar.open('Changes saved!', 'X', {
            duration: 3000,
            panelClass: ['success-snackbar'],
          });

          this.helper.updateDocRequirements();
        }
      });
  }

  saveRequirements(): void {
    this.isTableDataChanged = false;
    this.isSubmitEnabled = true;
    const updatedDataSource = this.dataSource.map((obj) => {
      const {isLocked, ...rest} = obj;
      return rest;
    });
    this.questionnaireService
      .updateExistingDocument({
        CaseId: this.caseId!,
        RequirementToSave: updatedDataSource,
      })
      .subscribe((res: any) => {
        if (res.Success) {
          this.helper.updateDocRequirements();

          this._snackBar.open('Changes saved!', 'X', {
            duration: 3000,
            panelClass: ['success-snackbar'],
          });
        }
      });
  }

  submitRequirements(): void {
    const clientId = this.helper.getQuestionnaireClientId();
    const caseId = this.helper.getQuestionnaireCaseId();

    switch (this.currentCaseStage) {
      case 'Initial Review':

        // For Initial Review cases Doc Req is the last tab
        // that's why we navigate to Initial Review dashboard on submit
        if (this.dataSource.some(x => x.DocumentUrl && !x.EffectiveDate)) {
          // display effective date required validation modal
          this.dialog.open(EffectiveDateValidationComponent, {});
        } else if (clientId && caseId)
          this.statisticService
            .updateCaseIsInitialReviewCompleted(clientId, caseId)
            .subscribe(() => {
              this.router.navigate(['/initial-review']);
            });

        break;

      case 'Outreach':
        // For Outreach cases Doc Req is the first tab
        // that's why we navigate to Products tab next

        if (this.dataSource.some(x => x.DocumentUrl && !x.EffectiveDate)) {
          // display effective date required validation modal
          this.dialog.open(EffectiveDateValidationComponent, {});
        } else
          this.helper.switchQuestionnaireTab(1);
        break;

      default:
        break;
    }
  }

  handleOpenAuditLog() {
    const dialogRef = this.dialog.open(AuditLogModalComponent, {
      autoFocus: false,
      data: {
        isOnDocReq: true,
        ClientId: this.clientId,
      },
    });
  }

  handleOpenCommentPreview(element: DocRequirement) {
    if (element.Comment) this.selectedCommentId = element.Id;
  }

  closeCommentSection() {
    this.selectedCommentId = 0;
  }
}
