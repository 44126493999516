import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-regulatory-block-with-header',
    templateUrl: './regulatory-block-with-header.component.html',
    styleUrls: ['./regulatory-block-with-header.component.css']
})
export class RegulatoryBlockWithHeaderComponent implements OnInit {
    @Input() public blockHeader: string = '';
    @Input() public  isHeaderMargin: boolean = true;

    constructor() {
    }

    ngOnInit(): void {
    }

}
