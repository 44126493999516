import { Type } from '@angular/core';
import { AbstractControl } from '@angular/forms';

export enum InputTypesByLength {
  smallInput = 'input-small',
  semiSmallInput = 'input-semi-small',
  mediumInput = 'input-medium',
  largeInput = 'input-large',
  textField = 'text',
}

export enum InputTypesByPurpose {
  regularInput = 'input-regular',
  autocompleteInput = 'input-autocomplete',
  autocompleteInputWithSelections = 'input-autocomplete-selections',
  readonlyInput = 'input-readonly',
}

export enum QuestionnaireItemIds {
  Id = 'Id',
  CaseId = 'CaseId',
  ClientName = 'ClientName',

  ClientId = 'ClientId',
  LEI = 'LEI',
  ClientLegalName = 'ClientLegalName',
  ClientLegalChange = 'ClientLegalChange',
  NewLegalName = 'NewLegalName',
  IsClientIncorporated = 'IsClientIncorporated',
  CountryOfIncorporation = 'CountryOfIncorporation',

  EntityType = 'EntityType',
  EntityTypeId = 'EntityTypeId',

  EntitySubType = 'EntitySubType',
  EntitySubTypeId = 'EntitySubTypeId',

  ClientHasAssetManagerInvestAdvisor = 'ClientHasAssetManagerInvestAdvisor',
  LegalNameAssetManagerInvestAdvisor = 'LegalNameAssetManagerInvestAdvisor',
  IsAssetManagerAiRegulated = 'IsAssetManagerAiRegulated',
  IsAssetManagerAlListed = 'IsAssetManagerAlListed',
  HighRiskBusiness = 'HighRiskBusiness',
  InvestmentRevenueBreakdownSourceOfFunds = 'InvestmentRevenueBreakdownSourceOfFunds',
  CountryOfOperationKnown = 'CountryOfOperationKnown',
  InvestmentBreakdown = 'InvestmentBreakdown',
  RevenueBreakdown = 'RevenueBreakdown',
  SourceOfFunds = 'SourceOfFunds',
  IsClientListed = 'IsClientListed',

  NameOfExchange = 'NameOfExchange',
  NameOfExchangeId = 'NameOfExchangeId',

  DoesClientMoreThan75MillionCad = 'DoesClientMoreThan75MillionCad',
  IsClientRegulated = 'IsClientRegulated',

  NameOfRegulator = 'NameOfRegulator',
  NameOfRegulatorId = 'NameOfRegulatorId',

  IsOwnershipKnown = 'IsOwnershipKnown',
  IsOwnershipKnownId = 'IsOwnershipKnownId',

  ListedRegulatedGovernmentOwnership = 'ListedRegulatedGovernmentOwnership',
  ListedRegulatedGovernmentOwnershipId = 'ListedRegulatedGovernmentOwnership',

  IsParentListed = 'IsParentListed',
  NameOfParentExchange = 'NameOfParentExchange',
  DoesListedParentMoreThan75MillionCad = 'DoesListedParentMoreThan75MillionCad',
  IsClientFinancialConsoloidatedWithParent = 'IsClientFinancialConsoloidatedWithParent',
  IsParentRegulated = 'IsParentRegulated',
  NameOfParentRegulator = 'NameOfParentRegulator',
  NameOfParentRegulatorId = 'NameOfParentRegulatorId',

  HowMuchAssetsUnderManagement = 'HowMuchAssetsUnderManagement',
  HowMuchAssetsUnderManagementId = 'HowMuchAssetsUnderManagementId',

  WasChangeOwnerhsipSinceLastCollected = 'WasChangeOwnerhsipSinceLastCollected',
  WasChangeControlPersonSinceLastColl = 'WasChangeControlPersonSinceLastColl',
}

export interface QuestionnaireListType {
  id: QuestionnaireItemIds;
  label: string;
  inputSize?: InputTypesByLength;
  inputType: InputTypesByPurpose;
  isVisible?: boolean; // set to false in case the field is not visible initially and can only be seen on matching the pre-defined condition
  formControl: AbstractControl;
  isAutocompleteSearchable?: boolean;
  autocompleteOptions?: any[];
  isMultiselect?: boolean;
}

export enum BooleanDropdownType {
  Yes = 'Yes',
  No = 'No',
}

export interface Regulators {
  Id: number;
  ListOfRegulators: string;
  Name: string;
}

export interface SubEntityTypes {
  EntityType: string | null;
  EntityTypeId: number;
  Id: number;
  Name: string;
  RiskScore: string;
}
export interface EntityTypes {
  Id: number;
  Name: string;
  SubEntityTypes?: SubEntityTypes[];
}

export interface HightRiskBusiness {
  Id: number;
  Name: string;
}
export interface Regions {
  Id: number;
  Name: string;
}

export interface Countries {
  // CountryCode: string;
  // CountryId: number;
  // CountryName: string;
  Id: number;
  Name: string;
}

export interface Breakdowns {
  label: string;
  percentage: number;
  id: number;
}

export interface Exchanges {
  Description: string;
  Id: number;
  ListOfExchange: string;
}

export interface KnownOwnerships {
  Id: number;
  Value: string;
}

export interface ExchangesItem {
  Description: string;
  Id: number;
  IsCRAApproved: boolean;
  ListOfExchange: string;
}

export interface RegulatedGovernmentOwnership {
  Id: number;
  Value: string;
}

export enum InvestmentRevenueBreakdownSourceOfFunds {
  InvestmentBreakdown = 'Investment Breakdown',
  RevenueBreakdown = 'Revenue Breakdown',
  SourceOfFunds = 'Source of Funds',
}
export interface SelectedPolicies {
  CountryCode: string;
  Status: string;
}

export enum FormValidityStatus {
  valid = 'VALID',
  invalid = 'INVALID',
}
