import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {ContactDataPayload, Paginator, TableHeadCells} from "./types";
import {Helper} from "../../../../../../../../../helpers/helper";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
    ContactDeleteConfirmationPopupComponent
} from "../delete-confirmation-popup/delete-confirmation-popup.component";
import {AddEditDialogComponent} from "../add-edit-dialog/add-edit-dialog.component";
import {DocuLibraryService} from "../../../../../../../../../services/docu-library.service";
import {CustomPaginator} from "../../../../../../../../common/custom-paginator/custom-paginator.component";

@Component({
    selector: 'app-contact-data-table',
    templateUrl: './contact-data-table.component.html',
    styleUrls: ['./contact-data-table.component.css']
})
export class ContactDataTableComponent implements OnInit {
    public isLoading: boolean = false;
    public dataSource: ContactDataPayload[] = [];
    public displayedColumns: string[] = [
        'FirstName',
        'MiddleName',
        'LastName',
        'Type',
        'Role',
        'JobTitle',
        'Phone',
        'Email',
        'Primary',
        'Edit',
        'Delete',
    ];
    public totalNumberOfItems: number = 0;
    public paginator: Paginator = {
        itemsPerPage: 0,
        numberOfItems: 0,
        pageIndex: 1,
        pageSize: 10,
    };
    @ViewChild('pagination') pagination: CustomPaginator = new CustomPaginator();

    constructor(
        private helper: Helper,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private dataService: DocuLibraryService,
    ) {}

    get clientId(): string {
        return this.helper.getQuestionnaireClientId();
    }

    public get tableColumns(): typeof TableHeadCells {
        return TableHeadCells;
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.fetchContactData();
    }

    private fetchContactData(): void {
        this.dataService.fetchContactData(parseInt(this.clientId), 0, 10).subscribe((res) => {
            this.totalNumberOfItems = res.Data.Total;
            this.dataSource = res.Data.Contacts;
            this.isLoading = false;
            this.pagination.currentPage.index = 0;
            this.pagination.currentPage.active = true;
        });
    }

    private handleAfterDialogClosed(result: ContactDataPayload, snackLabel: string): void {
        if (result) {
            this.isLoading = true;
            this.dataService.saveContactData(result).subscribe(() => {
                this.fetchContactData();
                this.snackBar.open('New contact added!', 'X', {
                    duration: 3000,
                    panelClass: ['success-snackbar'],
                });
            });
        }
    }

    public handleAddNewContact(): void {
        const newContact: ContactDataPayload = {
            ClientId: parseInt(this.clientId),
            Email: '',
            FirstName: '',
            Id: 0,
            IsPrimary: false,
            JobTitle: '',
            LastName: '',
            MiddleName: '',
            Phone: '',
            Role: '',
            Type: '',
        }
        const dialogRef: MatDialogRef<AddEditDialogComponent> = this.dialog.open(AddEditDialogComponent, {
            width: '535px',
            data: newContact,
        });

        dialogRef.afterClosed().subscribe((result) => this.handleAfterDialogClosed(result, 'New contact added!'));
    }

    public handleEditContact(editNode: ContactDataPayload): void {
        const dialogRef: MatDialogRef<AddEditDialogComponent> = this.dialog.open(AddEditDialogComponent, {
            width: '535px',
            data: editNode,
        });

        dialogRef.afterClosed().subscribe((result) => this.handleAfterDialogClosed(result, 'Contact updated!'));
    }

    public handleContactDelete(contactToBeDeleted: ContactDataPayload): void {
        const dialogRef: MatDialogRef<ContactDeleteConfirmationPopupComponent> = this.dialog.open(
            ContactDeleteConfirmationPopupComponent, { data: contactToBeDeleted }
        );

        dialogRef.afterClosed().subscribe((shouldDelete) => {
            if (shouldDelete) {
                this.isLoading = true;
                this.dataService.deleteContactData(contactToBeDeleted.ClientId, contactToBeDeleted.Id).subscribe((res) => {
                    this.fetchContactData();
                    this.snackBar.open('Contact deleted!', 'X', {
                        duration: 3000,
                        panelClass: ['success-snackbar'],
                    });
                });
            }
        });
    }

    public handlePageChange(index: number): void {
        this.isLoading = true;
        this.dataService.fetchContactData(parseInt(this.clientId), index + 1, 10).subscribe((res) => {
            this.dataSource = res.Data.Contacts;
            this.totalNumberOfItems = res.Data.Total;
            this.isLoading = false;
            this.pagination.currentPage.index = index;
            this.pagination.currentPage.active = true;
        });
    }
}
