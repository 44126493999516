import {
  OnInit,
  Inject,
  ViewChild,
  Component,
  ElementRef,
} from '@angular/core';
import { AnalyticModel } from 'src/app/models/analytic';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocRequirement } from '../types';
import { Helper } from 'src/app/helpers/helper';

@Component({
  selector: 'upload-new-doc',
  templateUrl: './new-document-upload.component.html',
  styleUrls: ['./new-document-upload.component.css'],
})
export class UploadNewDocument implements OnInit {
  @ViewChild('file') fileInput?: ElementRef;

  public files: any[] = [];
  public processedFileData?: AnalyticModel[];
  public isLoading: boolean = false;
  public isNewEntrySaved: boolean = false;
  public isErrorWhileSaving: boolean = false;
  public formData: FormData = new FormData();
  public fileSuccessfullyProcessed: boolean = false;
  public saveButtonDisabledDueToEditing: boolean = false;

  constructor(
    private helper: Helper,
    private snackBar: MatSnackBar,
    public service: QuestionnaireService,
    private dialogRef: MatDialogRef<UploadNewDocument>,
    @Inject(MAT_DIALOG_DATA) public data: { requirement: DocRequirement }
  ) {}

  public get isEffectiveDateDisabled(): boolean {
    return this.data.requirement.IsEffectiveDateDisabled;
  }

  public get caseId() {
    return this.helper.getQuestionnaireCaseId();
  }

  ngOnInit(): void {}

  public processFiles(event: any): void {
    const targetFiles = [...event.target.files];

    if (targetFiles.length) {
      this.files = targetFiles;
      this.formData.append('file', targetFiles[0]);
    }
  }

  public displayFiles() {
    if (this.files.length) {
      return this.files.map((file) => file.name);
    }

    return 'No file selected.';
  }

  public deleteSelectedFile() {
    this.fileInput!.nativeElement.value = [];
    this.files = [];
  }

  public continue() {
    this.isLoading = true;

    const formData = new FormData();
    formData.append('file', this.files[0]);

    this.service.uploadNewFileDocument(formData).subscribe(
      (res) => {
        if (res.Success) {
          const updatePayload = this.data.requirement;
          updatePayload.DocumentUrl = res.Data;

          this.service
            .updateExistingDocument({
              CaseId: this.caseId!,
              RequirementToSave: [updatePayload],
            })
            .subscribe((response) => {
              if (response.Success) {
                this.isLoading = false;
                this.dialogRef.close();
                this.snackBar.open('New file uploaded!', 'X', {
                  duration: 3000,
                  panelClass: ['success-snackbar'],
                });
              }

              this.helper.updateDocRequirements();
            });
        }
      },
      (err) => {
        this.dialogRef.close();
        throw err;
      }
    );
  }

  public save(): void {
    this.isLoading = true;
  }

  public disableSaveButton(): void {
    this.saveButtonDisabledDueToEditing = !this.saveButtonDisabledDueToEditing;
  }

  onDrop(event: any): void {
    const targetFiles: any = Array.from(event);

    if (targetFiles.length) {
      this.files = targetFiles;
      this.formData.append('file', targetFiles[0]);
    }
  }
}
