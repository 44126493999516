import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-sort-indicator',
  templateUrl: './sort-indicator.component.html',
  styleUrls: ['./sort-indicator.component.scss']
})
export class SortIndicatorComponent implements OnInit {
  @Input() public isActive: boolean = false;
  @Input() public sortDirection: 'asc' | 'desc' | null = null;

  constructor() {
  }

  ngOnInit(): void {
  }

}
