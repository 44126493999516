import {Router} from '@angular/router';
import {Helper} from 'src/app/helpers/helper';
import {FormBuilder, FormGroup} from '@angular/forms';
import {OnInit, Component, ChangeDetectorRef} from '@angular/core';

@Component({
    selector: 'docu-library-left-panel',
    templateUrl: './docu-lib-left-panel.component.html',
    styleUrls: ['./docu-lib-left-panel.component.scss'],
})
export class DocuLibraryLeftPanelComponent implements OnInit {
    public userName: string = '';
    public clientForm: FormGroup = new FormGroup({});
    public childRouteActive: boolean = false;
    public currentDocuLibId: string = '';
    public tabs: any[] = [];
    public selectedLegalName: string = '';
    public selectedCaseAvatarUrl: string = '';

    public isOnProfileChildRoute(tab: any): boolean {
        return this.router.url.includes(tab.routerLink + '/');
    }

    constructor(
        public router: Router,
        private helper: Helper,
        private crd: ChangeDetectorRef,
        private formBuilder: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.helper.onSelectedDocuLibCaseChange().subscribe((selectedCase) => {
            console.log(selectedCase)
            this.selectedLegalName = selectedCase.LegalName;
            this.selectedCaseAvatarUrl = selectedCase.AvatarUrl;
            this.crd.detectChanges();
        });
        this.helper.onDocuLibIdChange().subscribe((id) => {
            this.currentDocuLibId = id;
            this.tabs = this.updateTabs();
            this.crd.detectChanges();
        });

        this.helper.onDocuLibraryNavInfoChange().subscribe((data) => {
            this.childRouteActive = data;
        });

        this.clientForm = this.formBuilder.group({
            id: [''],
            accountNumber: [''],
            legalName: [''],
            lei: [''],
            clientOnly: [true],
        });
    }

    public updateTabs() {
        return [
            {
                routerLink: `docu-library/${this.currentDocuLibId}/profile`,
                imgUrl: './assets/icons/profile-icon-docu-lib.svg',
                tabName: 'Profile',
                subTabs: [
                    {
                        routerLink: `docu-library/${this.currentDocuLibId}/profile/general-information`,
                        tabName: 'General Information',
                    },
                    {
                        routerLink: `docu-library/${this.currentDocuLibId}/profile/regulatory`,
                        tabName: 'Regulatory',
                    },
                    {
                        routerLink: `docu-library/${this.currentDocuLibId}/profile/tax`,
                        tabName: 'Tax',
                    },
                    {
                        routerLink: `docu-library/${this.currentDocuLibId}/profile/contact`,
                        tabName: 'Contact',
                    },
                ],
            },
            {
                routerLink: `docu-library/${this.currentDocuLibId}/products-and-accounts`,
                imgUrl: './assets/icons/products-and-accounts.svg',
                tabName: 'Products & Accounts',
                subTabs: [
                    {
                        routerLink: `docu-library/${this.currentDocuLibId}/products-and-accounts/products`,
                        tabName: 'Products',
                    },
                    {
                        routerLink: `docu-library/${this.currentDocuLibId}/products-and-accounts/accounts`,
                        tabName: 'Accounts',
                    },
                ],
            },
            {
                routerLink: `docu-library/${this.currentDocuLibId}/structure`,
                imgUrl: './assets/icons/docu-lib-structure.svg',
                tabName: 'Structure',
            },
            {
                routerLink: `docu-library/${this.currentDocuLibId}/exemption`,
                imgUrl: './assets/icons/docu-lib-exemption.svg',
                tabName: 'Exemption',
            },
            {
                routerLink: `docu-library/${this.currentDocuLibId}/risk`,
                imgUrl: './assets/icons/docu-lib-risk.svg',
                tabName: 'Risk',
            },
            {
                routerLink: `docu-library/${this.currentDocuLibId}/doc-requirements`,
                imgUrl: './assets/icons/docu-lib-doc-requirements.svg',
                tabName: 'Document Requirements',
            },
        ];
    }

    onSubmit() {
        this.helper.triggerDocuLibSearchSubmit(this.clientForm.value);
    }
}
