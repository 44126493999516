<div class="control-box input-width padding-right padding-bottom position-relative">
    <label [for]="'fullLegalNameOfClearingParticipantAffiliates' + pairId">
        Full Legal Name Of Clearing Participant Affiliate(S)
    </label>
    <input
        [id]="'fullLegalNameOfClearingParticipantAffiliates' + pairId"
        [formControl]="abstractCcclControl.value.fullLegalNameOfClearingParticipantAffiliates"
    >
    <div class="horizontal-line"></div>
    <div class="vertical-line"></div>
</div>

<div class="control-box input-width padding-right padding-bottom position-relative">
    <label [for]="'leiCiciOfClearingParticipantAffiliate' + pairId">
        LEI/CICI/[Alternative Identifier] Of Clearing Participant Affiliate(S)
    </label>
    <input
        [id]="'leiCiciOfClearingParticipantAffiliate' + pairId"
        [formControl]="abstractCcclControl.value.leiCiciOfClearingParticipantAffiliate"
    >
    <div class="horizontal-line"></div>
</div>
