import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'reusable-multipurpose-dialog',
  templateUrl: './dialog-popup.component.html',
  styleUrls: ['./dialog-popup.component.scss'],
})
export class ReusableMultipurposeDialog implements OnInit {
  public dialogHeader: string = '';
  public clientId: string = '';
  public nextStage: string = '';
  public dialogConfirmAction: string = '';
  public dialogDenyAction: string = '';

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.dialogHeader = this.data.dialogHeader;
    this.clientId = this.data.clientId;
    this.nextStage = this.data.nextStage;
    this.dialogConfirmAction = this.data.confirmAction;
    this.dialogDenyAction = this.data.denyAction;
  }

  public onMoveToNextStage() {}
}
