import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'autocompleteSearch' })
export class AutocompleteSearchPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }

    if (!searchText) {
      return items;
    }

    searchText = searchText.toLocaleLowerCase();

    return items.filter((it) => {
      if (typeof it === 'string') {
        return it.toLocaleLowerCase().includes(searchText);
      } else if (typeof it === 'object') {
        for (const key in it) {
          if (it.hasOwnProperty(key) && typeof it[key] === 'string') {
            if (
              (it as { [key: string]: string })[key]
                .toLocaleLowerCase()
                .includes(searchText)
            ) {
              return true;
            }
          }
        }
      }
      return false;
    });
  }
}
