import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { AnalyticService } from 'src/app/services/analytic.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'data-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class DataPieChart implements OnInit {

  public chart!: Chart<any>;

  public chartData: {
    bucketName: string,
    notStarted: { value: number, percent: number },
    inProgress: { value: number, percent: number },
    complete: { value: number, percent: number }
  }[] = [];
  public chartValues: number[] = [0, 0, 0];

  public loading: boolean = false;
  public isChartSetup: boolean = false;
  public chartId: string = 'trending-pie-chart';

  public selectedBucket: any = this.chartData[0] || {
    bucketName: '',
    notStarted: { value: 0, percent: 0 },
    inProgress: { value: 0, percent: 0 },
    complete: { value: 0, percent: 0 }
  };

  public isAllChartValuesZero: boolean = false;

  constructor(private analyticService: AnalyticService) {}

  ngOnInit(): void {
    this.loading = true;
    this.setBucketsInfo();
  }

  ngOnChanges(): void {
    const ctx = document.getElementById(this.chartId);

    if (ctx && !this.isChartSetup && !this.chart) {
      this.createChart();
      this.isChartSetup = true;
    }
  }

  ngAfterContentChecked(): void {
    const ctx = document.getElementById(this.chartId);

    if (ctx && !this.isChartSetup && !this.chart) {
      this.createChart();
      this.isChartSetup = true;
    }
  }

  setBucketsInfo() {
    this.analyticService.getPieChart().subscribe(res => {
      const { Data } = res;

      const onboardingTotalAmount = Data['Onboarding'] ? 
        (Data['Onboarding']['Not Started'] ? Data['Onboarding']['Not Started'] : 0) +
        (Data['Onboarding']['In Progress'] ? Data['Onboarding']['In Progress'] : 0) + 
        (Data['Onboarding']['Completed'] ? Data['Onboarding']['Completed'] : 0) : 0;
      const refreshTotalAmount = Data['Refresh'] ? 
        (Data['Refresh']['Not Started'] ? Data['Refresh']['Not Started'] : 0) + 
        (Data['Refresh']['In Progress'] ? Data['Refresh']['In Progress'] : 0) + 
        (Data['Refresh']['Completed'] ? Data['Refresh']['Completed'] : 0) : 0;
      const remediationTotalAmount = Data['Remediation'] ? 
        (Data['Remediation']['Not Started'] ? Data['Remediation']['Not Started'] : 0) + 
        (Data['Remediation']['In Progress'] ? Data['Remediation']['In Progress'] : 0) + 
        (Data['Remediation']['Completed'] ? Data['Remediation']['Completed'] : 0) : 0;
      const values: number[] = [onboardingTotalAmount, refreshTotalAmount, remediationTotalAmount];

      this.chartData[0] = {
        bucketName: 'Onboarding', 
        notStarted: { 
          value: Data['Onboarding'] && Data['Onboarding']['Not Started'] || 0, 
          percent: this.percentage((Data['Onboarding'] && Data['Onboarding']['Not Started'] || 0), onboardingTotalAmount) 
        }, 
        inProgress: {  
          value: Data['Onboarding'] && Data['Onboarding']['In Progress'] || 0, 
          percent: this.percentage(Data['Onboarding'] && Data['Onboarding']['In Progress'] || 0, onboardingTotalAmount) }, 
        complete: { 
          value: Data['Onboarding'] && Data['Onboarding']['Completed'] || 0, 
          percent: this.percentage(Data['Onboarding'] && Data['Onboarding']['Completed'] || 0, onboardingTotalAmount)  } 
      }
      this.chartData[1] = {
        bucketName: 'Refresh', 
        notStarted: { 
          value: Data['Refresh'] && Data['Refresh']['Not Started'] || 0, 
          percent: this.percentage((Data['Refresh'] && Data['Refresh']['Not Started'] || 0), refreshTotalAmount) 
        }, 
        inProgress: { 
          value: Data['Refresh'] && Data['Refresh']['In Progress'] || 0, 
          percent: this.percentage(Data['Refresh'] && Data['Refresh']['In Progress'] || 0, refreshTotalAmount) }, 
        complete: { 
          value: Data['Refresh'] && Data['Refresh']['Completed'] || 0, 
          percent: this.percentage(Data['Refresh'] && Data['Refresh']['Completed'] || 0, refreshTotalAmount)  } 
      }
      this.chartData[2] = {
        bucketName: 'Remediation', 
        notStarted: { 
          value: Data['Remediation'] && Data['Remediation']['Not Started'] || 0, 
          percent: this.percentage((Data['Remediation'] && Data['Remediation']['Not Started'] || 0), remediationTotalAmount) 
        }, 
        inProgress: { 
          value: Data['Remediation'] && Data['Remediation']['In Progress'] || 0, 
          percent: this.percentage(Data['Remediation'] && Data['Remediation']['In Progress'] || 0, remediationTotalAmount) }, 
        complete: { 
          value: Data['Remediation'] && Data['Remediation']['Completed'] || 0, 
          percent: this.percentage(Data['Remediation'] && Data['Remediation']['Completed'] || 0, remediationTotalAmount)  } 
      }
      
      this.chartValues = values;

      if(this.chartValues.every(i => i === 0)) {
        this.isAllChartValuesZero = true;
      } else {
        this.isAllChartValuesZero = false;
      }

      if(this.chartValues.some(i => i > 0)) {
        if(this.chartValues[2] > 0) {
          this.selectedBucket =  this.chartData[2];
        }
        if(this.chartValues[1] > 0) {
          this.selectedBucket =  this.chartData[1];
        }
        if(this.chartValues[0] > 0) {
          this.selectedBucket =  this.chartData[0];
        }
      } else {
        this.selectedBucket =  this.chartData[0];
      }

    });
  }

  percentage(partialValue: any, totalValue: any) {
    if(partialValue === 0 && totalValue === 0) {
      return 0;
    }
    return Math.round((100 * partialValue) / totalValue);
 } 

  createChart() {
    Chart.register(...registerables);

    const element = document.getElementById(this.chartId) as HTMLCanvasElement;

    const backgroundColors: any[] = [];
    const chartLabels: string[] = ['Onboarding', 'Refresh', 'Remediation'];

    if (element) {
      const ctx = element.getContext('2d');

      backgroundColors.push(...this.generateBackgroundColors(chartLabels, element));

      if(ctx) {
        ctx.rect(100, 40, 200, 100);
        ctx.fillStyle = 'green';
        ctx.shadowColor = '#898';
        ctx.shadowBlur = 20;
        ctx.shadowOffsetX = 20;
        ctx.shadowOffsetY = 20;
        ctx.fill();
      }
    }
    
    const data = {
      labels: chartLabels,
      datasets: [{
        data: this.chartValues,
        backgroundColor: backgroundColors,
        hoverOffset: 0,
        shadowOffsetX: 30,
        shadowOffsetY: 30,
        shadowBlur: 100,
        backgroundShadowColor: 'black',
      }]
    };
    const ShadowPlugin = {
      id: 'test',
      afterDraw: (chart: any, args: any, options: any) => {
        const { ctx } = chart;
        // ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
        // ctx.shadowBlur = 10;
        // ctx.shadowOffsetX = 2;
        // ctx.shadowOffsetY = 2;
        return chart;
      },
    };

    this.chart = new Chart(this.chartId, {
      type: 'pie',
      plugins: [ChartDataLabels, ShadowPlugin],
      data: data,
      options: {
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        plugins: {
          datalabels: {
            color: '#fff',
            labels: {
              title: {
                formatter: (value, context) => {
                  let sum = 0;
                  let dataArr = context.chart.data.datasets[0].data;
                  dataArr.map((data: any) => {
                      sum += data;
                  });
                  let percentage = +(value*100 / sum).toFixed(2);
                  if (percentage < 5) return '';
                  if (percentage < 10) return percentage + '%';
    
                  if(context?.chart?.data?.labels) {
                    return [context.chart.data.labels[context.dataIndex], percentage + '%', value ];
                  }

                  return ''
                },
                textAlign: 'center',
                font: {
                  size: 12,
                  family: 'Poppins-Latin'
                }
              },

            }
          },
          tooltip: {
            enabled: true
          },
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              usePointStyle: true,
            },
          },
        },
        onClick: (e, activeEls) => {
          const { index: dataIndex } = activeEls[0];

          let label = this.chart?.data?.labels && this.chart.data.labels[dataIndex];
          this.selectedBucket = this.chartData.find(bucket => bucket.bucketName === label);

        }
      }
    });
  }

  generateBackgroundColors(bucketNames: string[], element: any): any[] {
    const ctx = element.getContext('2d');
    const colors: any[] = [];

      if (ctx) {
        let gradientColor;

        bucketNames.forEach((bucket: string) => {
          switch(bucket) {
            case 'Refresh': {
              gradientColor = ctx.createLinearGradient(0, 0, 0, 500);
              gradientColor.addColorStop(0, 'rgba(255,244,0,1)');
              gradientColor.addColorStop(0.85, 'rgba(242,8,8,1)');
              gradientColor.addColorStop(1, 'rgba(255,0,0,1)');

              colors.push(gradientColor);
              return;
            }
            case 'Onboarding': {
              gradientColor = ctx.createLinearGradient(0, 0, 0, 500);
              gradientColor.addColorStop(0.5, 'rgba(0,212,255,1)');
              gradientColor.addColorStop(1, 'rgba(7,0,131,1)');

              colors.push(gradientColor);
              return;
            }
            case 'Remediation': {
              gradientColor = ctx.createLinearGradient(0, 0, 0, 500);
              gradientColor.addColorStop(0, 'rgba(206,67,255,1)');
              gradientColor.addColorStop(0.85, 'rgba(133,8,242,1)');
              gradientColor.addColorStop(1, 'rgba(119,0,255,1)');

              colors.push(gradientColor);
              return;
            }
          }
        });
    }
    return colors;
  }
}
