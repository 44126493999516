import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class CalendarHelper {
  private calendarDateSubject = new BehaviorSubject<Date>(new Date());

  public onCalendarDateChange() {
    return this.calendarDateSubject.asObservable();
  }

  public updateCalendarDate(param: Date = new Date()) {
    return this.calendarDateSubject.next(param);
  }
}
