<div>
  <ng-container [ngSwitch]="sidenavOpened">
    <!-- Team section for Opened Sidenav -->
    <ng-container *ngSwitchCase="true">
      <div class="team-container" (click)="onOpenCloseHandle()">
        <div class="d-flex box-with-label">
          <div class="d-flex">
            <span
              class="side-user-icon"
              [ngStyle]="{
                'background-image': teamData.TeamIconUrl
                  ? 'url(' + teamData.TeamIconUrl + ')'
                  : teamData.TeamImgUrl
              }"
              ><b>&nbsp;</b></span
            >

            <div class="user-info" style="color: #8f95b2">
              <div
                style="
                  display: flex;
                  font-size: 11px !important;
                  font-family: Poppins-Regular;
                "
              >
                {{ teamData.TeamName }}
                <img
                  alt="arrow"
                  src="./../assets/icons/arrow.svg"
                  [ngClass]="{ 'rotate-arrow': showTeamMembers }"
                  style="margin-left: 1rem; margin-right: 1rem; width: 7px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Team section for Closed Sidenav -->
    <ng-container *ngSwitchCase="false">
      <div class="team-container-collapsed" (click)="onOpenCloseHandle()">
        <div class="d-flex box-with-label">
          <div class="d-flex">
            <div
              class="d-flex collapsed-sidenav-team-viewer"
              [matTooltip]="
                teamData.TeamName +
                ' ' +
                '(' +
                teamData.Users.length +
                ' ' +
                'team members' +
                ')'
              "
              matTooltipPosition="right"
            >
              <div class="side-user-icon-border">
                <div
                  class="activity-indicator"
                  [ngStyle]="{
                    background:
                      checkTeamActivity(teamData) === 'green'
                        ? '#17E933'
                        : checkTeamActivity(teamData) === 'orange'
                        ? '#E9D717'
                        : '#FF5E61'
                  }"
                ></div>
                <span
                  class="side-user-icon"
                  [ngStyle]="{
                    'background-image': teamData.TeamIconUrl
                      ? 'url(' + teamData.TeamIconUrl + ')'
                      : teamData.TeamImgUrl
                  }"
                  ><b>&nbsp;</b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div *ngFor="let member of teamData.Users">
    <div class="collapsible-wrapper" *ngIf="showTeamMembers">
      <div class="d-flex team-member-item">
        <div class="d-flex">
          <span
            class="side-user-icon"
            [ngStyle]="{ 'background-image': member.UserIconUrl }"
            style="color: #8f95b2 !important; margin-right: 1rem"
            ><b>&nbsp;</b></span
          >
          <div
            class="d-flex"
            style="
              font-size: 9px !important;
              align-items: center;
              font-family: Poppins-Regular;
            "
          >
            {{ member.UserName }}
          </div>
        </div>
        <div
          class="team-member-indicator"
          [ngStyle]="{
            background:
              member.Status === 'green'
                ? '#17E933'
                : member.Status === 'orange'
                ? '#E9D717'
                : '#FF5E61'
          }"
        ></div>
      </div>
    </div>
  </div>
</div>
