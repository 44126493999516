import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductFiltersPayload } from 'src/app/models/questionnaire';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { AuditLogModalComponent } from '../audit-log-modal/audit-log-modal.component';
import {
  Products,
  QuestionnaireTableComponent,
} from '../questionnaire-data-table/questionnaire-table.component';
import { Helper } from 'src/app/helpers/helper';
import { MatTabGroup } from '@angular/material/tabs';
import { ExemptionsComponent } from '../exemptions/exemptions.component';
import { CaseStage } from './types';
import { StatisticService } from 'src/app/services/statistic.service';
import { ValidationPopup } from './submit-validation-popup/validation-popup.component';
import { ApproveValidationPopup } from './approve-validation-popup/validation-popup.component';

interface TabsAccess {
  products: boolean;
  questionaire: boolean;
  exemptions: boolean;
  riskScore: boolean;
  documentRequirement: boolean;
}

@Component({
  selector: 'questionnaire-container',
  templateUrl: './questionnaire-container.component.html',
  styleUrls: ['./questionnaire-container.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'wiam-select-overlay' },
    },
  ],
})
export class QuestionnaireContainerComponent implements OnInit, AfterViewInit {
  currentTabIdx: number = 0;
  tabsAccess: TabsAccess = {
    products: true,
    questionaire: true,
    exemptions: true,
    riskScore: true,
    documentRequirement: true,
  };

  public dialogRef: MatDialogRef<ApproveValidationPopup> | undefined;

  @ViewChild(QuestionnaireTableComponent)
  questionnaireTable!: QuestionnaireTableComponent;
  @ViewChild('exemptions') exemptions!: ExemptionsComponent;
  @ViewChild('questionnaireTabs') questionnaireTabs: MatTabGroup | undefined;
  @ViewChild('productInfo') productInfo!: ElementRef;
  @ViewChild('tableWrapper') tWrapper!: ElementRef;

  mutationObserver!: MutationObserver;

  addNewProductInProgress: boolean = false;
  public isNewProductInTable: boolean = false;
  isEditMode: boolean = false;

  public questionnaireDataSource: any[] = [];

  public loading = false;

  public get caseId(): number {
    return this.helper.getQuestionnaireCaseId()!;
  }

  public get clientId(): string {
    return this.helper.getQuestionnaireClientId();
  }

  public get clientName(): string {
    return this.helper.getQuestionnaireClientName();
  }

  public get clientBucket(): string {
    return this.helper.getQuestionnaireBucket();
  }

  public get isApprovalProductsReadonly(): boolean {
    const accessRole = this.helper.getUserRole();

    return (
      this.currentCaseStage === 'Approval' &&
      !(accessRole!.includes('Manager') || accessRole!.includes('Admin'))
    );
  }

  public get isInitialReviewActiveProducts(): boolean {
    return (
      this.currentCaseStage === 'Initial Review' &&
      this.questionnaireDataSource
        .filter((x) => x.IsSelected)
        .filter((x) => x.Status !== 'Closed').length > 0
    );
  }

  updatedBucket: string = '';

  selectedProductsIds: number[] = [];
  selectedProductInfo: any = null;

  public numberOfProductItems: number = 1;

  public filtersApplied: boolean = false;
  public paginationPayload: ProductFiltersPayload = {
    ClientId: 0,
    PageNumber: 0,
    PageSize: 10,
    SortBy: '',
    SortDirection: '',
    FilterColumns: '',
  };

  isTableDataChanged: boolean = false;
  isSubmitEnabled: boolean = true;

  public productsToUpdate: Products[] = [];

  public buckets: any[] = [];

  public get currentCaseStage() {
    return this.helper.getQuestionnaireCaseStage();
  }

  public get caseStages(): typeof CaseStage {
    return CaseStage;
  }

  constructor(
    private questionnaireService: QuestionnaireService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private helper: Helper,
    private statisticService: StatisticService
  ) {}

  ngOnInit(): void {
    if (this.isApprovalProductsReadonly) {
      this.matSnackBar.open(
        "In order to make any changes at this stage, you'll need to be an Admin or a Manager",
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    }

    if (!this.clientId) {
      this.router.navigateByUrl('/home');
    } else {
      try {
        this.loading = true;
        this.questionnaireService
          .getQuestionnaire(
            +this.clientId,
            this.paginationPayload.PageNumber,
            this.paginationPayload.PageSize,
            'Id',
            'desc'
          )
          .subscribe((res: any) => {
            this.questionnaireDataSource = (res as any).Data;
            this.numberOfProductItems =
              this.questionnaireDataSource.find((el: any) => el.Total)?.Total ??
              0;
            if (this.questionnaireDataSource.length && this.tWrapper) {
              this.questionnaireTable.setProductInfo(
                this.questionnaireDataSource[0]
              );
            }
            this.loading = false;
            this.updateHeightForProductInfo();
          });
      } catch (error) {
        this.loading = false;
      }

      this.questionnaireService
        .getBuckets()
        .subscribe((res) => (this.buckets = res.Data));

      this.helper.questionnaireTabSwitched().subscribe((index) => {
        if (this.questionnaireTabs)
          this.questionnaireTabs.selectedIndex = index;
      });
    }
  }

  ngAfterViewInit(): void {
    this.mutationObserver = new MutationObserver(() => {
      this.updateHeightForProductInfo();
    });

    const config = { attributes: true, childList: true, subtree: true };
    if (this.tWrapper)
      this.mutationObserver.observe(this.tWrapper.nativeElement, config);
  }

  openAuditLogModal() {
    const auditLogModalRef = this.dialog.open(AuditLogModalComponent, {
      panelClass: 'audit-log-modal',
      width: '70%',
      data: {
        selectedProductsIds: this.selectedProductsIds,
        ClientId: this.clientId,
      },
    });
  }

  updateHeightForProductInfo(): void {
    if (this.tWrapper) {
      const heightValue = this.tWrapper?.nativeElement?.offsetHeight;
      this.productInfo.nativeElement.style.maxHeight = heightValue + 'px';
      this.productInfo.nativeElement.style.height = heightValue + 'px';
    }
  }

  setProductInfo(event: Event) {
    this.selectedProductInfo = { ...event };
  }

  setBackdrop(event: any) {
    this.addNewProductInProgress =
      event.addNewProductInProgress !== undefined
        ? event.addNewProductInProgress
        : this.addNewProductInProgress;
    this.isEditMode =
      event.isEditMode !== undefined ? event.isEditMode : this.isEditMode;
    this.isTableDataChanged =
      event.isEdited !== undefined ? event.isEdited : this.isTableDataChanged;
    this.isSubmitEnabled =
      event.isEdited !== undefined ? !event.isEdited : this.isSubmitEnabled;

    if (event.updatedProducts || event.newProducts) {
      this.productsToUpdate = [...event.updatedProducts, ...event.newProducts];
    }
    if (event.newProducts && event.newProducts.length) {
      this.isNewProductInTable = true;
    }
  }

  handleAddNewProduct() {
    this.questionnaireTable.handleEditProduct();
    this.addNewProductInProgress = true;
  }

  handleProductSelection(selectedProductsIds: number[]) {
    this.selectedProductsIds = selectedProductsIds;
    this.isTableDataChanged = true;
    this.isSubmitEnabled = false;
  }

  public handleApplyFilters(event: ProductFiltersPayload): void {
    try {
      this.loading = true;
      this.filtersApplied = true;
      this.paginationPayload = event;
      this.questionnaireService
        .getProductsWithFilters(event)
        .subscribe((res) => {
          this.questionnaireDataSource = res.Data;
          this.numberOfProductItems =
            this.questionnaireDataSource.find((el: any) => el.Total)?.Total ??
            0;
          this.loading = false;
        });
    } catch (error) {
      this.loading = false;
    }
  }

  handlePageChange(index: number) {
    this.loading = true;
    this.paginationPayload.PageNumber = index + 1;

    if (this.filtersApplied) {
      this.questionnaireService
        .getProductsWithFilters(this.paginationPayload)
        .subscribe((res: any) => {
          this.questionnaireDataSource = (res as any).Data;
          this.numberOfProductItems =
            this.questionnaireDataSource.find((el: any) => el.Total)?.Total ??
            0;
          this.loading = false;
        });
    } else {
      this.questionnaireService
        .getQuestionnaire(
          +this.clientId,
          this.paginationPayload.PageNumber,
          this.paginationPayload.PageSize,
          'Id',
          'desc'
        )
        .subscribe((res: any) => {
          this.questionnaireDataSource = (res as any).Data;
          this.numberOfProductItems =
            this.questionnaireDataSource.find((el: any) => el.Total)?.Total ??
            0;
          this.loading = false;
        });
    }
  }

  productInfoChange(value: any) {
    this.isTableDataChanged = true;
    this.isSubmitEnabled = false;

    const productIdx = this.questionnaireDataSource
      .map((v) => v.Id)
      .indexOf(value.Id);
    Object.assign(this.questionnaireDataSource[productIdx], value);

    if (this.productsToUpdate.length > 0) {
      const updateIdx = this.productsToUpdate
        .map((v) => v.Id)
        .indexOf(value.Id);
      this.productsToUpdate[updateIdx] =
        this.questionnaireDataSource[productIdx];
    } else {
      this.productsToUpdate.push(this.questionnaireDataSource[productIdx]);
    }
  }

  caseBucketChange(event: any): void {
    this.updatedBucket = event.value;
    this.isTableDataChanged = this.clientBucket !== this.updatedBucket;
    this.isSubmitEnabled = this.clientBucket == this.updatedBucket;
  }

  saveProducts() {
    if (this.productsToUpdate.length) {
      this.loading = true;
      this.questionnaireService
        .createNewProducts({
          CaseId: this.caseId,
          ProductToSave: this.productsToUpdate,
        })
        .subscribe((res) => {
          this.questionnaireTable.updatedProducts = [];
          this.questionnaireTable.productsThatShouldBeCreated = [];
          this.productsToUpdate = [];
          this.isSubmitEnabled = true;
          if (res.Success) {
            this.isNewProductInTable = false;
            this.isTableDataChanged = false;

            switch (this.currentCaseStage) {
              case 'Initial Review':
                this.questionnaireService
                  .getQuestionnaire(
                    +this.clientId,
                    this.paginationPayload.PageNumber,
                    this.paginationPayload.PageSize,
                    'Id',
                    'desc'
                  )
                  .subscribe((res: any) => {
                    this.questionnaireDataSource = (res as any).Data;
                    this.numberOfProductItems =
                      this.questionnaireDataSource.find((el: any) => el.Total)
                        ?.Total ?? 0;
                    this.loading = false;
                    if (this.questionnaireDataSource.length) {
                      this.questionnaireTable.setProductInfo(
                        this.questionnaireTable.selectedViewProduct?.Id
                          ? this.questionnaireTable.selectedViewProduct
                          : this.questionnaireDataSource[0]
                      );
                    }
                    this.helper.refreshPolicy();

                    this.questionnaireService
                      .documentIsSelectedUpdate(+this.clientId, this.caseId)
                      .subscribe();

                    this.snackBar.open('Changes submitted!', 'X', {
                      duration: 3000,
                      panelClass: ['success-snackbar'],
                    });
                  });
                break;

              case 'Outreach':
                if (this.currentTabIdx === 1)
                  this.questionnaireService
                    .calculateProductComplianceStatus(
                      +this.clientId,
                      this.caseId
                    )
                    .subscribe((res) => {
                      this.questionnaireService
                        .getQuestionnaire(
                          +this.clientId,
                          this.paginationPayload.PageNumber,
                          this.paginationPayload.PageSize,
                          'Id',
                          'desc'
                        )
                        .subscribe((res: any) => {
                          this.questionnaireDataSource = (res as any).Data;
                          this.numberOfProductItems =
                            this.questionnaireDataSource.find(
                              (el: any) => el.Total
                            )?.Total ?? 0;
                          this.loading = false;
                        });
                    });
                break;

              case 'Approval':
                this.questionnaireService
                  .getQuestionnaire(
                    +this.clientId,
                    this.paginationPayload.PageNumber,
                    this.paginationPayload.PageSize,
                    'Id',
                    'desc'
                  )
                  .subscribe((res: any) => {
                    this.questionnaireDataSource = (res as any).Data;
                    this.numberOfProductItems =
                      this.questionnaireDataSource.find((el: any) => el.Total)
                        ?.Total ?? 0;
                    this.loading = false;
                    if (this.questionnaireDataSource.length) {
                      this.questionnaireTable.setProductInfo(
                        this.questionnaireDataSource[0]
                      );
                    }
                    this.helper.refreshPolicy();

                    this.questionnaireService
                      .documentIsSelectedUpdate(+this.clientId, this.caseId)
                      .subscribe();

                    this.snackBar.open('Changes submitted!', 'X', {
                      duration: 3000,
                      panelClass: ['success-snackbar'],
                    });
                  });
                break;
              default:
                break;
            }
          }
        });
    }

    if (this.updatedBucket !== this.clientBucket && this.updatedBucket !== '') {
      this.loading = true;
      this.questionnaireService
        .setNewBucketForCase(this.caseId, this.updatedBucket)
        .subscribe((res) => {
          this.loading = false;
          if (res.Success) {
            this.isNewProductInTable = false;
            this.isTableDataChanged = false;
            this.isSubmitEnabled = true;
            this.helper.setQuestionnaireBucket(this.updatedBucket);

            const redirectUrl = '/questionnaire/' + this.caseId;
            this.router.navigateByUrl(redirectUrl);
            this.matSnackBar.open(res.Message, 'X', {
              duration: 3000,
              panelClass: ['success-snackbar'],
            });
          } else {
            this.matSnackBar.open(res.Message, 'X', {
              duration: 3000,
              panelClass: ['error-snackbar'],
            });
          }
        });
    }
  }

  onTabChange(event: any) {
    if (this.questionnaireTabs && this.questionnaireTabs.selectedIndex !== null)
      this.currentTabIdx = this.questionnaireTabs.selectedIndex;

    switch (this.currentCaseStage) {
      case 'Outreach':
        if (this.currentTabIdx === 1)
          this.questionnaireService
            .calculateProductComplianceStatus(+this.clientId, this.caseId)
            .subscribe((res) => {
              this.questionnaireService
                .getQuestionnaire(
                  +this.clientId,
                  this.paginationPayload.PageNumber,
                  this.paginationPayload.PageSize,
                  'Id',
                  'desc'
                )
                .subscribe((res: any) => {
                  this.questionnaireDataSource = (res as any).Data;

                  if (this.questionnaireDataSource.length && this.tWrapper) {
                    this.questionnaireTable.setProductInfo(
                      this.questionnaireDataSource[0]
                    );
                  }

                  this.numberOfProductItems =
                    this.questionnaireDataSource.find((el: any) => el.Total)
                      ?.Total ?? 0;
                });
            });
        break;

      default:
        break;
    }
  }

  submitProducts(): void {
    const clientId = this.helper.getQuestionnaireClientId();
    const caseId = this.helper.getQuestionnaireCaseId();

    // VALIDATION rules are applicable just for products with "Active" checkboxes
    const activeProducts = this.questionnaireDataSource
      .filter((x) => x.IsSelected)
      .filter((x) => x.Status !== 'Closed');
    const compliantStatuses = ['Active', 'Requested'];

    switch (this.currentCaseStage) {
      case 'Initial Review':
        if (activeProducts.length === 0) {
          this.matSnackBar.open(
            'You need to select at least one product in order to continue',
            'X',
            {
              duration: 3000,
              panelClass: ['error-snackbar'],
            }
          );
        } else {
          // For Initial Review cases Products is the first tab
          // that's why we navigate to Questionnaire tab next
          this.currentTabIdx = this.currentTabIdx + 1;
          this.tabsAccess.questionaire = true;
        }
        break;

      case 'Outreach':
        if (
          activeProducts.some(
            (x) =>
              x.Compliance === 'Not-Compliant' &&
              compliantStatuses.includes(x.Status)
          )
        ) {
          const products = activeProducts.filter(
            (x) =>
              x.Compliance === 'Not-Compliant' &&
              compliantStatuses.includes(x.Status)
          );

          this.dialog.open(ValidationPopup, {
            data: {
              displayData: products,
            },
          });
        } else {
          if (clientId && caseId)
            this.statisticService
              .updateCaseIsInitialReviewCompleted(clientId, caseId)
              .subscribe(() => {
                this.router.navigate(['/outreach']);
              });
        }

        break;

      default:
        break;
    }
  }

  approveProducts(): void {
    const caseId = this.helper.getQuestionnaireCaseId();
    const clientId = this.helper.getQuestionnaireClientId();

    const activeProducts = this.questionnaireDataSource
      .filter((x) => x.IsSelected)
      .filter((x) => x.Status !== 'Closed');

    // Block the submission if a checked product has a "Requested", "Pending closure", or "Pending freezing" status.
    if (
      activeProducts.some((x) =>
        ['Requested', 'Pending closure', 'Pending freezing'].includes(x.Status)
      )
    ) {
      this.dialog.open(ApproveValidationPopup, {
        data: {
          displayData: activeProducts.some((x) =>
            ['Requested', 'Pending closure', 'Pending freezing'].includes(
              x.Status
            )
          ),
          approvalConditionPassed: false,
        },
      });
    } else {
      this.dialogRef = this.dialog.open(ApproveValidationPopup, {
        data: {
          displayData: this.questionnaireDataSource,
          approvalConditionPassed: true,
        },
      });

      this.dialogRef.afterClosed().subscribe((isApprove) => {
        if (clientId && caseId && isApprove)
          this.statisticService
            .updateCaseIsInitialReviewCompleted(clientId, caseId)
            .subscribe(() => {
              this.questionnaireService
                .moveToDocumentLib(clientId)
                .subscribe(() => {
                  this.questionnaireService
                    .updateProductBucket(clientId, caseId)
                    .subscribe(() => {
                      this.router.navigate(['/approvals']);
                    });
                });
            });
      });
    }
  }

  onExemptionsSave(event: any): void {
    this.questionnaireService.saveExemptions(event).subscribe((res) => {
      if (res.Success) {
        this.matSnackBar.open('Changes saved successfully!', 'X', {
          duration: 3000,
          panelClass: ['success-snackbar'],
        });
        this.exemptions.markFormsAsAPristine();
      } else {
        this.matSnackBar.open(res.Message, 'X', {
          duration: 3000,
          panelClass: ['error-snackbar'],
        });
      }

      this.exemptions.isLoading = false;
    });
  }

  onExemptionsSubmit(event: any): void {
    this.currentTabIdx = this.currentTabIdx + 1;
    this.tabsAccess.riskScore = true;
  }
}
