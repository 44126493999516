import {TableItem} from "../../types";
import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DocuLibraryService} from "../../../../services/docu-library.service";

@Component({
    selector: 'app-add-new-client-or-case',
    templateUrl: './add-new-client-or-case.component.html',
    styleUrls: ['./add-new-client-or-case.component.css']
})
export class AddNewClientOrCaseComponent implements OnInit {
    public dialogForm: FormGroup;
    public dropdownData: {
        ClientTypes: string[],
        Countries: string[],
        Buckets: string[],
    } = {ClientTypes: [], Countries: [], Buckets: []};
    @Input() public dialogHeader: string = 'Create New Case';

    constructor(
        private fb: FormBuilder,
        private dataService: DocuLibraryService,
        public dialogRef: MatDialogRef<AddNewClientOrCaseComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {

        this.dataService.getCreateNewCaseDropdown().subscribe(data => {
            const dropdownValues = data.Data;
            this.dropdownData = dropdownValues;
        });

        this.dialogForm = this.fb.group({
            ClientId: [this.data?.ClientId || 0, [Validators.required]],
            ClientName: [this.data?.LegalName, [Validators.required]],
            ParentGrouping: [this.data?.ParentGrouping, [Validators.required]],
            CountryOfIncorporation: [this.data?.CountryOfIncorporation, [Validators.required]],
            ClientType: [this.data?.ClientType, [Validators.required]],
            ClientContactName: [this.data?.ClientContactName, [Validators.required]],
            ClientContactEmail: [this.data?.ClientContactEmail, [Validators.required, Validators.email]],
            ClientContactTitle: [this.data?.ClientContactTitle, [Validators.required]],
            Revenue: [this.data?.Revenue, [Validators.required]],
            Bucket: [this.data?.Bucket, [Validators.required]],
            DueDate: [this.data?.DueDate, [Validators.required]],
        });
    }

    get isContinueDisabled() {
        return this.dialogForm.invalid;
    }

    get today() {
        return new Date();
    }

    ngOnInit(): void {
    }

    public onCancelClick(): void {
        this.dialogRef.close();
    }

    public onContinueClick(): void {
        this.dialogRef.close(this.dialogForm.getRawValue());
    }
}
