import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-reusable-data-block-with-header',
    templateUrl: './reusable-data-block-with-header.component.html',
    styleUrls: ['./reusable-data-block-with-header.component.css']
})
export class ReusableDataBlockWithHeaderComponent implements OnInit {
    @Input() public blockHeader: string = '';
    @Input() public isHeaderVisible: boolean = true;
    @Input() public isMailingAddressBlock: boolean = false;
    @Input() public isExternalDatabaseBlock: boolean = false;
    @Output() public addNewExternalDbClickedEmitter = new EventEmitter();
    @Output() public setMailingAddressEmitter = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
    }

}
