import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'data-progress-bars',
  templateUrl: './progress-bars.component.html',
  styleUrls: ['./progress-bars.component.css']
})
export class DataProgressBars implements OnInit {

  @Input() selectedBucket: { 
    bucketName: string,
    notStarted: { value: number, percent: number },
    inProgress: { value: number, percent: number },
    complete: { value: number, percent: number }
  } = {
    bucketName: '',
    notStarted: { value: 0, percent: 0 },
    inProgress: { value: 0, percent: 0 },
    complete: { value: 0, percent: 0 },
  }

  constructor() { }

  ngOnInit(): void {
  }

}
