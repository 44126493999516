export enum TableHeadCells {
    FirstName = 'FIRST NAME',
    MiddleName = 'MIDDLE NAME',
    LastName = 'LAST NAME',
    Type = 'TYPE',
    Role = "ROLE",
    JobTitle = 'JOB TITLE',
    Phone = 'PHONE',
    Fax = 'FAX',
    Email = 'Email',
    Primary = 'Primary',
}

export interface TableData {
    FirstName: string,
    MiddleName: string,
    LastName: string,
    Type: string,
    Role: string,
    JobTitle: string,
    Phone: string,
    Fax: string,
    Email: string,
    Primary: string,
}

export interface ContactDataPayload {
    Id: number,
    ClientId: number,
    FirstName: string,
    MiddleName: string,
    LastName: string,
    Type: string,
    Role: string,
    JobTitle: string,
    Phone: string,
    Email: string,
    IsPrimary: boolean,

    DateLastUpdated?: string,
    UpdatedBy?: string,
}

export interface Paginator {
    itemsPerPage: number,
    numberOfItems: number,
    pageIndex: number,
    pageSize: number,
}
