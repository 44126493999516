import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountData } from '../../sub-tabs/docu-lib-accounts/types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-multy-action-dialog',
  templateUrl: './multy-action-dialog.component.html',
  styleUrls: ['./multy-action-dialog.component.scss'],
})
export class MultyActionDialogComponent implements OnInit {
  public accountForm: FormGroup;
  public header: string = '';
  public today: Date = new Date();

  @Input() public dialogHeader: string = 'Add New Entry';

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MultyActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AccountData
  ) {
    this.header = data.headLabel;
    this.accountForm = this.fb.group({
      accountNumber: [data.accountData.AccountNumber, Validators.required],
      accountName: [data.accountData.AccountName, Validators.required],
      accountType: [data.accountData.AccountType, Validators.required],
      accountStatus: [data.accountData.AccountStatus],
      balance: [data.accountData.Balance, Validators.required],
      currency: [data.accountData.Currency],
      dateOpened: [data.accountData.DateAccountOpened],
    });
  }

  ngOnInit(): void {}

  onContinueClick(): void {
    if (this.accountForm.valid) {
      this.dialogRef.close(this.accountForm.value);
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
