import { NgxImageCompressService } from 'ngx-image-compress';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Helper } from 'src/app/helpers/helper';

@Component({
  selector: 'profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.css'],
})
export class ProfilePhotoControl {
  public imgResult: string = '';
  public fileTypeError: boolean = false;
  public imageSrc: string | ArrayBuffer | null = '';
  public allowedExtensions = /(\.jpg|\.jpeg|\.png|\.tif|\.tiff|\.gif|\.bmp)$/i;

  @Input() public index = 0;
  @Input() public imgSrc = '';
  @Input() public imgAlt = '';
  @Input() public isAddNewIcon = false;
  @Input() public selected = false;
  @Input() public isAddNewIconButton = false;

  @Output() public clickEmitter = new EventEmitter();
  @Output() public imageUploadedEmitter = new EventEmitter();
  @Output() public fileTypeErrorEmitter = new EventEmitter();

  constructor(
    private helper: Helper,
    private userService: UserService,
    private imageCompress: NgxImageCompressService
  ) {}

  public handleClickEvent(event: MouseEvent): void {
    this.clickEmitter.emit(this.index);
  }

  dataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  public compressFile(): void {
    this.imageCompress
      .uploadFile()
      .then(({ image, orientation, fileName }) => {
        if (!this.allowedExtensions.exec(fileName)?.length) {
          // checking if new file extension marches the allowed file type
          if (
            !this.allowedExtensions.exec(fileName)?.length &&
            !this.fileTypeError
          ) {
            this.fileTypeError = true;

            // emitting file type error event
            this.fileTypeErrorEmitter.emit();
          }
        } else if (
          // if user uploads correct file but we have validation error from previous attempts we need to reset validation
          this.allowedExtensions.exec(fileName)?.length &&
          this.fileTypeError
        ) {
          this.fileTypeError = false;

          // emitting reset file validation type error event
          this.fileTypeErrorEmitter.emit();
        }

        return { image, orientation, fileName };
      })
      .then(({ image, orientation }) => {
        if (!this.fileTypeError) {
          // emitting null to add empty icon cell with a loader to showcase the progress
          this.imageUploadedEmitter.emit(null);

          this.imageCompress
            .compressFile(image, orientation, 50, 50) // 50% ratio, 50% quality
            .then((compressedImage) => {
              this.imgResult = compressedImage;

              const formData = new FormData();
              if (this.imgResult.includes('base64')) {
                const file = this.dataURIToBlob(this.imgResult);
                formData.append('avatar', file);
              }

              this.userService
                .postUserProfileIcon(formData)
                .subscribe((res) => {
                  this.imageUploadedEmitter.emit(res.Data);
                  this.helper.callLeftMenuChanged();
                });
            });
        }
      });
  }
}
