<div class="d-flex w-100 justify-center align-center spinner-box" *ngIf="isLoading; else dataBlock">
    <mat-spinner [diameter]="70"></mat-spinner>
</div>

<ng-template #dataBlock>
    <ng-container *ngIf="dataSource; else noDataBlock">
        <div class="d-flex w-100 tab-three-column-box">
            <form [formGroup]="taxForm" class="d-flex w-100">
                <div class="d-flex flex-column data-column">
                    <!-- Country Of Issue -->
                    <div class="control-box w-100">
                        <label for="CountryOfIssue">Country Of Issue</label>
                        <input id="CountryOfIssue" formControlName="CountryOfIssue">
                    </div>

                    <!--  Type Of ID -->
                    <div class="control-box w-100">
                        <label for="Type">Type Of ID</label>
                        <ng-select
                            [items]="dropdownData ? dropdownData.TaxIdTypes : null"
                            id="Type"
                            formControlName="Type">
                        </ng-select>
                    </div>

                    <!-- Other Type Details -->
                    <div class="control-box w-100">
                        <label for="OtherTypeData">Other Type Details</label>
                        <input id="OtherTypeData" formControlName="OtherTypeData">
                    </div>

                    <!-- Document Type -->
                    <div class="control-box w-100">
                        <label for="DocumentType">Document Type</label>
                        <ng-select
                            [items]="dropdownData ? dropdownData.TaxDocumentTypes : null"
                            id="DocumentType"
                            formControlName="DocumentType">
                        </ng-select>
                    </div>

                    <!-- Id -->
                    <div class="control-box w-100">
                        <label for="TaxId">ID</label>
                        <input id="TaxId" formControlName="TaxId">
                    </div>
                </div>
            </form>

            <!-- Save Block -->
            <div class="d-flex w-100 justify-end">
                <div class="d-flex save-wrapper">
                    <div class="d-flex flex-col">
                <span class="last-updated">
                    <span class="updated-on-label">
                        Last Updated On:
                    </span>
                    <span class="updated-on-value">
                        {{ '22 Jul 2024' }}
                    </span>
                </span>
                        <div class="updated-by">
                            by Wiam Lou
                        </div>
                    </div>

                    <button class="save-button" [disabled]="taxForm.pristine" (click)="updateTaxData()">Save</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #noDataBlock>
        <div class="d-flex justify-content-center">
            <no-data-component></no-data-component>
        </div>
    </ng-template>
</ng-template>
