import { Component, Input } from '@angular/core';

@Component({
  selector: 'notification_btn-with-count',
  templateUrl: './notification-count.component.html',
  styleUrls: ['./notification-count.component.scss'],
})
export class NotificationCountComponent {
  @Input() public numberOfNotification: number = 0;
}
