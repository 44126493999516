import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import { Helper } from '../../../../../../../helpers/helper';
import { MatDialog } from '@angular/material/dialog';
import { MultyActionDialogComponent } from '../../components/multy-action-dialog/multy-action-dialog.component';
import { DocuLibraryService } from '../../../../../../../services/docu-library.service';
import { AccountListItem } from './types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteAccountDialogComponent } from '../../components/delete-account-dialog/delete-archive-dialog.component';
import { DocuLibAuditLogModalComponent } from '../../components/docu-lib-audit-log-modal/audit-log-modal.component';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-docu-lib-accounts',
  templateUrl: './docu-lib-accounts.component.html',
  styleUrls: ['./docu-lib-accounts.component.scss'],
})
export class DocuLibAccountsComponent implements OnInit, AfterContentInit {
  public selectedId: string = '';
  public isLoading: boolean = false;
  public isPreviewVisible: boolean = false;
  public displayedColumns: string[] = [
    'accountNumber',
    'accountName',
    'accountType',
    'accountStatus',
    'balance',
    'date',
    'edit',
    'remove',
  ];
  public sortingColumn: string | null = null;
  public sortingDirection: 'asc' | 'desc' | null = null;

  public get clientId(): string {
    return this.helper.getQuestionnaireClientId();
  }

  public get selectedAccountId(): number | null {
    const id = this.helper.getDocuLibSelectedAccountId();
    return id ? parseInt(id) : null;
  }

  public dataSource: any[] = [];

  constructor(
    private helper: Helper,
    public dialog: MatDialog,
    private docuLibService: DocuLibraryService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.helper.onDocuLibIdChange().subscribe((id) => {
      if (id) this.helper.setDocuLibSelectedAccountId(+id);
    });
  }

  ngAfterContentInit() {
    this.fetchAccountsData(true);
  }

  public toUpperCase(data: string): string {
    return data.toUpperCase();
  }

  public fetchAccountsData(isInitialFetch?: boolean): void {
    if (isInitialFetch) this.isLoading = true;

    if (this.selectedAccountId)
      this.docuLibService
        .getDocuLibAccounts({
          id: this.selectedAccountId,
          sortBy: this.sortingColumn
            ? this.getSortingColumn(this.sortingColumn)
            : 'Id',
          sortDirection: this.sortingDirection || 'asc',
        })
        .subscribe((res) => {
          this.dataSource = res.Data;
          this.dataSource.map((x) => {
            x.DateAccountOpened = this.convertFromUtc(x.DateAccountOpened);
            return x;
          });
          this.isLoading = false;
        });
  }

  private convertFromUtc(date: Date) {
    const utcTimeString = date;
    const timezone = moment.tz.guess();
    const localTime = moment.utc(utcTimeString).tz(timezone);

    return new Date(localTime.toLocaleString());
  }

  public addNewAccount(): void {
    const dialogRef = this.dialog.open(MultyActionDialogComponent, {
      width: '535px',
      data: { accountData: {}, headLabel: 'Add New Entry' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.docuLibService
          .addDocuLibAccount({
            AccountToSave: {
              ClientId: +this.clientId,
              AccountNumber: result.accountNumber,
              AccountName: result.accountName,
              AccountType: result.accountType,
              AccountStatus: result.accountStatus,
              Balance: this.getAccountBalance(result.balance),
              Currency: result.currency,
              DateAccountOpened: result.dateOpened,
            },
          })
          .subscribe(() => {
            this.fetchAccountsData();
            this.snackBar.open('New entry added!', 'X', {
              duration: 3000,
              panelClass: ['success-snackbar'],
            });
          });
      }
    });
  }

  private getAccountBalance(balance: string | number): number {
    return typeof balance === 'number'
      ? balance
      : parseInt(balance.replace(/\s/g, ''));
  }

  public handleEditAccount(element: AccountListItem): void {
    const dialogRef = this.dialog.open(MultyActionDialogComponent, {
      width: '535px',
      data: { accountData: element, headLabel: 'Modify Entry' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.docuLibService
          .addDocuLibAccount({
            AccountToSave: {
              Id: element.Id,
              ClientId: +this.clientId,
              AccountNumber: result.accountNumber,
              AccountName: result.accountName,
              AccountType: result.accountType,
              AccountStatus: result.accountStatus,
              Balance: this.getAccountBalance(result.balance),
              Currency: result.currency,
              DateAccountOpened: result.dateOpened,
            },
          })
          .subscribe(() => {
            this.fetchAccountsData();
            this.snackBar.open('Changes saved!', 'X', {
              duration: 3000,
              panelClass: ['success-snackbar'],
            });
          });
      }
    });
  }

  public handleDeleteAccount(element: AccountListItem): void {
    const dialogRef = this.dialog.open(DeleteAccountDialogComponent, {
      data: element,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fetchAccountsData();
        this.snackBar.open('Account deleted!', 'X', {
          duration: 3000,
          panelClass: ['success-snackbar'],
        });
      }
    });
  }

  public formatBalance(balance: string): string {
    let value = `${balance}`.replace(/\D/g, ''); // Remove non-numeric characters
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // Add spaces every 3 digits
    return value;
  }

  public handleSorting(column: string): void {
    if (this.sortingColumn !== column) {
      this.sortingColumn = column;
      this.sortingDirection = 'asc';
      this.fetchAccountsData();
    } else if (
      this.sortingColumn === column &&
      this.sortingDirection === 'asc'
    ) {
      this.sortingDirection = 'desc';
      this.fetchAccountsData();
    } else {
      this.sortingColumn = null;
      this.fetchAccountsData();
    }
  }

  private getSortingColumn(columnName: string): string {
    switch (columnName) {
      case 'Account Number':
        return 'AccountNumber';
      case 'Account Name':
        return 'AccountName';
      case 'Account Type':
        return 'AccountType';
      case 'Account Status':
        return 'AccountStatus';

      case 'Date':
        return 'DateAccountOpened';

      default:
        return columnName;
    }
  }

  public isSortingActive(sortingColumn: string): boolean {
    return sortingColumn === this.sortingColumn;
  }

  public handleOpenAuditLog(): void {
    const auditLogModalRef = this.dialog.open(DocuLibAuditLogModalComponent, {
      panelClass: 'audit-log-modal',
      width: '50%',
      data: {
        clientId: parseInt(this.clientId),
        isOnDocuLibProducts: false,
        isOnDocuLibAccounts: true,
      },
    });
  }
}
