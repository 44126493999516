<div layout="column" flex id="content" class="page-content">
  <div class="d-flex space-between p-b-30">
    <div class="header-wrapper">
      <span class="page-header">Name Screening</span>
      <column-chips-popover
        *ngIf="noDataAvailable"
        [defaultDisplayedColumns]="displayedColumns"
        (changeDisplayedColumns)="changeDisplayedColumns($event)"
      ></column-chips-popover>
    </div>
  </div>

  <div>
    <data-table
      [dataSource]="dataSource"
      statusFilter="Name Screening"
      [displayedColumns]="displayedColumns"
      (noDisplayDataEmitter)="handleNoTableData($event)"
    ></data-table>
  </div>
</div>
