import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {TableItem} from '../../types';
import {Helper} from 'src/app/helpers/helper';
import {TableHeadCells} from './table-types';

@Component({
    selector: 'docu-lib-data-table',
    templateUrl: './docu-lib-data-table.component.html',
    styleUrls: ['./docu-lib-data-table.component.scss'],
})
export class DocuLibDataTableComponent {
    public displayedColumns: string[] = [
        'Checkbox',
        'ClientId',
        'AccountNumber',
        'LegalName',
        'Lei',
        'Type',
        'Status',
    ];
    public sortingColumn: TableHeadCells | null = null;
    public sortingDirection: 'asc' | 'desc' | null = null;

    public get tableColumns(): typeof TableHeadCells {
        return TableHeadCells;
    }

    @Input() public dataSource: TableItem[] = [];

    @Output() public applySortingEmitter = new EventEmitter<{
        sortColumn: TableHeadCells | null;
        sortDirection: 'asc' | 'desc' | null;
    }>();

    @Output() public selectTableItemEmitter = new EventEmitter<TableItem | null>();

    constructor(private router: Router, private helper: Helper) {
    }

    public getStatusClass(status: string): string {
        return status !== '-' ? status.toUpperCase() : 'NO-STATUS';
    }

    public toUpperCase(data: string): string {
        return data.toUpperCase();
    }

    public handleLegalNameClick(element: TableItem): void {
        const id = element.ClientId;

        this.helper.setQuestionnaireClientId(id);
        this.helper.setDocuLibChildRouteActive(true);
        this.helper.setSelectedDocuLibCase(element);
        this.router.navigate(['docu-library', id, 'profile', 'general-information']);
    }

    public handleSorting(column: TableHeadCells): void {
        if (this.sortingColumn !== column) {
            this.sortingColumn = column;
            this.sortingDirection = 'asc';
            this.applySortingEmitter.emit({
                sortColumn: this.sortingColumn,
                sortDirection: this.sortingDirection,
            });
        } else if (
            this.sortingColumn === column &&
            this.sortingDirection === 'asc'
        ) {
            this.sortingDirection = 'desc';
            this.applySortingEmitter.emit({
                sortColumn: this.sortingColumn,
                sortDirection: this.sortingDirection,
            });
        } else {
            this.sortingColumn = null;
            this.applySortingEmitter.emit({
                sortColumn: this.tableColumns.CustomerId,
                sortDirection: 'desc',
            });
        }
    }

    public isSortingActive(sortingColumn: TableHeadCells): boolean {
        return sortingColumn === this.sortingColumn;
    }

    public displayAccountNumber(accountNumbers: string[]): string {
        return accountNumbers.length === 0
            ? '-'
            : accountNumbers.length === 1
                ? accountNumbers[0]
                : accountNumbers.join(', ');
    }

    public isShowMore(accountName: string[]): boolean {
        return accountName ? accountName.length > 4 : false;
    }

    public getAccountNums(data: string[] | undefined): string {
        return data && data.length
            ? 'Account Number: ' + data.join(', ')
            : 'No Account Number just yet';
    }

    public handleCheckboxChange(row: TableItem): void {
        this.dataSource = this.dataSource.map(x => {
            if (x.ClientId === row.ClientId) {
                this.selectTableItemEmitter.emit(row.isSelected ? null : row);
                x.isSelected = !x.isSelected
            } else x.isSelected = false;

            return x;
        });
    }
}
