import {Component, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DropdownOptions, SdlDataPayload} from "../regulatory/common/data-tabs/sdl-data/types";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
    public isLoading: boolean = false;
    public sdlForm: FormGroup = new FormGroup({});
    public dataSource: SdlDataPayload | undefined;
    public dropdownData: DropdownOptions | null = null;


    constructor() {
    }

    ngOnInit(): void {
    }

    public updateContactData(): void {

    }
}
