import {Subject, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {Helper} from "../../../../../helpers/helper";
import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CommonData, StructuresService} from "../../../../../services/structures.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-right-tab',
    templateUrl: './right-tab.component.html',
    styleUrls: ['./right-tab.component.scss']
})
export class RightTabComponent implements OnInit, OnDestroy {
    public files: File[] = [];
    public profileForm: FormGroup = new FormGroup({});
    public identificationInformationForm: FormGroup = new FormGroup({});
    public relationshipForm: FormGroup = new FormGroup({});
    public screeningOutcomeForm: FormGroup = new FormGroup({});
    public isLoading: boolean = false;
    private selectedStructureSubscription: Subscription = new Subscription();
    private structureUpdateSubject: Subject<boolean> = new Subject<boolean>();
    private formData: FormData = new FormData();
    public isRelationshipFormReadOnly: boolean = false;
    private selectedSearchViewStructureSubscription: Subscription = new Subscription();


    @Output() private reFetchStructureData = new EventEmitter();

    public commonData: CommonData | undefined;

    constructor(
        private helper: Helper,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private structuresService: StructuresService,
    ) {
    }

    ngOnInit(): void {
        this.structureUpdateSubject.pipe(debounceTime(1000)).subscribe(() => {
            this.updateStructureData();
        });

        this.selectedSearchViewStructureSubscription = this.helper.onSearchSectionRightTabOpen().subscribe(id => {
            if (id) this.populateFormData(id);
            else {
                setTimeout(() => {
                    this.helper.changeRightTabState(true);
                }, 2000)
            }
        });

        this.selectedStructureSubscription = this.helper.onNewStructureSelected().subscribe(structure => {
            this.isLoading = true;
            this.structuresService.fetchCommonData().subscribe(data => {
                this.commonData = data.Data;
                this.isRelationshipFormReadOnly = structure && structure.ParentId === 0 ? true : false;

                if (structure) {
                    this.populateFormData(structure.Id);
                }
                this.isLoading = false;
            });
        });
    }

    ngOnDestroy() {
        this.selectedStructureSubscription.unsubscribe();
        this.selectedSearchViewStructureSubscription.unsubscribe()
    }

    private updateStructureData(): void {
        const ownershipPercentage = this.relationshipForm.controls['ownershipPercentage'].value;

        this.structuresService.updateStructureDate({
            Id: this.profileForm.controls['cid'].value,
            EntityName: this.profileForm.controls['entityName'].value,
            FirstName: this.profileForm.controls['firstName'].value,
            MiddleName: this.profileForm.controls['middleName'].value,
            LastName: this.profileForm.controls['lastName'].value,
            StreetAddress: this.profileForm.controls['streetAddress'].value,
            Country: this.profileForm.controls['country'].value,
            State: this.profileForm.controls['stateOrProvince'].value,
            PostalCode: this.profileForm.controls['postalCode'].value,
            ClientAssotiationType: this.profileForm.controls['type'].value,
            EntityType: this.profileForm.controls['entityType'].value,

            IdentificationType: this.identificationInformationForm.controls['idType'].value,
            IdenatificationOther: this.identificationInformationForm.controls['other'].value,
            // IdNumber is needed
            BusinessNumber: this.identificationInformationForm.controls['idNumber'].value,
            CountryofIssue: this.identificationInformationForm.controls['countryOfIssue'].value,
            ExpiryDate: this.identificationInformationForm.controls['expiryDate'].value,
            DocumentUrl: this.identificationInformationForm.controls['documentUpload'].value,
            Occupation: this.identificationInformationForm.controls['occupation'].value,
            NatureOfBusiness: this.identificationInformationForm.controls['natureOfBusiness'].value,

            RelationshipType: this.relationshipForm.controls['relationshipType'].value,
            RelationshipOther: this.relationshipForm.controls['other'].value,
            Role: this.relationshipForm.controls['role'].value,
            OwnershipType: this.relationshipForm.controls['ownershipType'].value,
            OwnershipPercentage: ownershipPercentage ? `${ownershipPercentage}` : ownershipPercentage,
            OwnershipRange: this.relationshipForm.controls['ownershipRange'].value,

            PEPStatus: this.screeningOutcomeForm.controls['pepStatus'].value,
            NegativeNewsType: this.screeningOutcomeForm.controls['negativeNews'].value,
            ScreeningOutcomeOther: this.screeningOutcomeForm.controls['other'].value,
            ScreeningAssessment: this.screeningOutcomeForm.controls['screeningAssessment'].value,
            DescribeHitResults: this.screeningOutcomeForm.controls['describeHitResult'].value
        }).subscribe(res => {
            this.reFetchStructureData.emit();

            this.snackBar.open('Changes successfully saved!', 'x', {
                panelClass: ['success-snackbar'],
                duration: 3000,
            });
        });
    }

    private populateFormData(structureId: number): void {
        this.structuresService.fetchRightTabData(structureId).subscribe((data) => {
            const tabData = data.Data;

            this.profileForm = this.formBuilder.group({
                cid: [tabData.Id, [Validators.required]],
                entityName: [tabData.EntityName, [Validators.required]],
                firstName: [tabData.FirstName, [Validators.required]],
                middleName: [tabData.MiddleName, [Validators.required]],
                lastName: [tabData.LastName, [Validators.required]],
                type: [tabData.ClientAssotiationType, [Validators.required]],
                streetAddress: [tabData.StreetAddress, [Validators.required]],
                country: [tabData.Country, [Validators.required]],
                stateOrProvince: [tabData.State, [Validators.required]],
                postalCode: [tabData.PostalCode, [Validators.required]],
                entityType: [tabData.EntityType, [Validators.required]],
            });

            this.identificationInformationForm = this.formBuilder.group({
                idType: [tabData.IdentificationType, Validators.required],
                other: [tabData.IdenatificationOther, Validators.required],
                idNumber: [tabData.BusinessNumber, Validators.required], // TODO: ID NUMBER should be here
                businessNumber: [tabData.BusinessNumber, Validators.required],
                countryOfIssue: [tabData.CountryofIssue, Validators.required],
                expiryDate: [tabData.ExpiryDate, Validators.required],
                documentUpload: [tabData.DocumentUrl, Validators.required],
                occupation: [tabData.Occupation, Validators.required],
                natureOfBusiness: [tabData.NatureOfBusiness, Validators.required],
            });

            this.relationshipForm = this.formBuilder.group({
                relationshipType: [tabData.RelationshipType, Validators.required],
                other: [tabData.RelationshipOther, Validators.required],
                role: [tabData.Role, Validators.required],
                ownershipType: [tabData.OwnershipType, Validators.required],
                ownershipPercentage: [tabData.OwnershipPercentage, Validators.required],
                ownershipRange: [tabData.OwnershipRange, Validators.required],
            });

            this.screeningOutcomeForm = this.formBuilder.group({
                pepStatus: [tabData.PEPStatus, Validators.required],
                negativeNews: [tabData.NegativeNewsType, Validators.required],
                other: [tabData.ScreeningOutcomeOther, Validators.required],
                screeningAssessment: [tabData.ScreeningAssessment, Validators.required],
                describeHitResult: [tabData.DescribeHitResults, Validators.required],
            });
            this.cdr.detectChanges();
            //tracking the changes in all the above forms
            this.profileForm.valueChanges.subscribe((data) => {

                this.structureUpdateSubject.next();
            });

            this.identificationInformationForm.valueChanges.subscribe(data => {
                this.structureUpdateSubject.next();
            });

            this.relationshipForm.valueChanges.subscribe((data) => {
                if (data.ownershipPercentage > 100) {
                    this.relationshipForm.controls['ownershipPercentage'].setValue(100);


                    this.snackBar.open('Max allowed Ownership Percentage is 100%!', 'x', {
                        panelClass: ['attention-snackbar'],
                        duration: 3000,
                    });

                    setTimeout(() => {
                        this.structureUpdateSubject.next();
                    }, 1000);

                    return;
                }

                this.structureUpdateSubject.next();
            });

            this.screeningOutcomeForm.valueChanges.subscribe(() => {
                this.structureUpdateSubject.next();
            });
        });

        this.isLoading = false;
    }

    public processFiles(event: any): void {
        const targetFiles = [...event.target.files];

        if (targetFiles.length) {
            this.files = targetFiles;
            this.formData.append('file', targetFiles[0]);

            this.structuresService.attachFileToStructure(this.formData).subscribe((res: any) => {
                this.identificationInformationForm.controls['documentUpload'].setValue(res.Data);
                this.updateStructureData();
            });
        }
    }

    public onDrop(event: any): void {
        const targetFiles: any = Array.from(event)

        if (targetFiles.length) {
            this.files = targetFiles;
            this.formData.append('file', targetFiles[0]);
        }
    }

    public handleRemoveAttachedDocument(): void {
        this.identificationInformationForm.controls['documentUpload'].setValue(null);
        this.updateStructureData();
    }
}
