import {DialogFormPayload} from "./types";
import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DropdownOptions} from "../../../regulatory/common/data-tabs/sdl-data/types";
import {DocuLibraryService} from "../../../../../../../../../services/docu-library.service";

@Component({
    selector: 'app-add-edit-dialog',
    templateUrl: './add-edit-dialog.component.html',
    styleUrls: ['./add-edit-dialog.component.css']
})
export class AddEditDialogComponent implements OnInit {
    public dropdownData: DropdownOptions | null = null;
    public dialogForm: FormGroup = new FormGroup({});
    @Input() public dialogHeader: string = 'Add New Entry';
    constructor(
        private fb: FormBuilder,
        private dataService: DocuLibraryService,
        public dialogRef: MatDialogRef<AddEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogFormPayload,
    ) {}

    ngOnInit(): void {
        this.dialogForm = this.fb.group({
            Id: [this.data.Id || 0, Validators.required],
            ClientId: [this.data.ClientId, Validators.required],
            FirstName: [this.data.FirstName, Validators.required],
            MiddleName: [this.data.MiddleName, Validators.required],
            LastName: [this.data.LastName, Validators.required],
            Type: [this.data.Type, Validators.required],
            Role: [this.data.Role, Validators.required],
            JobTitle: [this.data.JobTitle, Validators.required],
            Phone: [this.data.Phone, Validators.required],
            Email: [this.data.Email, Validators.required],
            IsPrimary: [this.data.IsPrimary, Validators.required],
        });

        this.dataService.fetchRegulatoryDropdownOptions().subscribe((dropDawnData) => {
            this.dropdownData = dropDawnData.Data;
        });
    }

    public onCancelClick(): void {
        this.dialogRef.close();
    }

    public onContinueClick(): void {
        this.dialogRef.close(this.dialogForm.getRawValue());
    }
}
