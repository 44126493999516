import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "src/environments/environment";
import { Helper } from "../helpers/helper";
import { MessageModel, NotificationRequestModel } from "../models/websocket";
import { BaseService } from "./base.service";

@Injectable()
export class NotificationService extends BaseService {
  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient, helper: Helper) {
    super(helper);
  }

  public postNotification(notification: NotificationRequestModel) : Observable<MessageModel>{
    var data = JSON.stringify(notification);
    return this.http.post<MessageModel>(this.baseUrl + `/notification`, data, super.header());
  }

  public getNotifications(take: number, skip: number): Observable<any> {
    return this.http.get<MessageModel>(this.baseUrl + `/notification`, {
      params: new HttpParams().set('take', take).set('skip', skip),
      headers: super.header().headers,
    });
  }

  public setNotificationsAsSeen() {
    return this.http.put(this.baseUrl + `/notification`, null, super.header());
  }
}
