import {Component, OnInit} from '@angular/core';
import {Helper} from "../../../../../helpers/helper";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {StructuresService} from "../../../../../services/structures.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-add-new-structure-header',
    templateUrl: './add-new-structure-header.component.html',
    styleUrls: ['./add-new-structure-header.component.scss']
})
export class AddNewStructureHeaderComponent implements OnInit {
    public isPanelOpened: boolean = false;
    public isSearchActive: boolean = false;
    public addNewStructureForm: FormGroup = new FormGroup({});
    public selectedParentId: number = 0;
    public selectedId: any;

    public get clientId(): number {
        return this.helper.getQuestionnaireClientId();
    }

    constructor(
        private helper: Helper,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder,
        private structuresService: StructuresService,
    ) {
    }

    ngOnInit(): void {
        this.helper.onSelectedParentIdChange().subscribe(id => this.selectedParentId = id);
        this.helper.onNewStructureItemCreated().subscribe(structure => this.selectedId = structure.ClientId);
        this.addNewStructureForm = this.formBuilder.group({
            firstName: ['', [Validators.required]],
            middleName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            entityType: ['', [Validators.required]],
            cid: ['', [Validators.required]],
            entityName: ['', [Validators.required]],
            isVisible: [true, [Validators.required]],
            restoreParentId: ['', Validators.required]
        });

        this.helper.onAddNewStructurePanelChange().subscribe((isOpened) => {
            this.isPanelOpened = isOpened;
        });
    }

    public onSubmit() {
        this.isSearchActive = true;
        this.helper.updateSearchResultsPanel(true, this.addNewStructureForm);
    }

    public handleAddNew(): void {
        this.isSearchActive = false;
        this.helper.updateAddNewStructurePanel(false);
        this.helper.openRightTabForSelectedRow(null);

        this.helper.onAddNewStructureClick().subscribe((data) => {
            // Restoring previously existing Structure Item. IsVisible false means that item has been created previously
            // and then hidden (or deleted) from the UI
            if (data && data.IsVisible === false && data.RestoreParentId === this.selectedParentId) {
                this.structuresService.restoreStructureAndChildren(+this.clientId, this.selectedId).subscribe((data) => {
                    this.helper.updateSearchResultsPanel(false, null);
                    this.helper.refreshStructuresData();

                    this.snackBar.open('Structure item successfully restored!', 'x', {
                        panelClass: ['success-snackbar'],
                        duration: 3000,
                    });
                });
            } else
            // Creating new Structure Item by selecting one from the Search Results table
            if (data) {
                this.structuresService.createNewStructureItem(data).subscribe((data) => {
                    this.helper.updateSearchResultsPanel(false, null);
                    this.helper.refreshStructuresData();
                    this.helper.passNewItemId(data.Data);

                    this.snackBar.open('Structure item successfully added!', 'x', {
                        panelClass: ['success-snackbar'],
                        duration: 3000,
                    });
                });
            }
            else {
                // Creating new Structure Item from the Data filled in the Add new structure header
                this.helper.passNewStructureForCreation({
                    ParentId: this.selectedParentId,
                    ClientId: +this.clientId,
                    ClientName: this.addNewStructureForm.controls['entityName'].value || null,
                    FirstName: this.addNewStructureForm.controls['firstName'].value || null,
                    MiddleName: this.addNewStructureForm.controls['middleName'].value || null,
                    LastName: this.addNewStructureForm.controls['lastName'].value || null,
                    EntityType: this.addNewStructureForm.controls['entityType'].value,
                    IsVisible: this.addNewStructureForm.controls['isVisible'].value,
                    RestoreParentId: this.addNewStructureForm.controls['restoreParentId'].value
                });
            }
            this.addNewStructureForm.reset();
        }).unsubscribe();
        this.helper.passNewStructureForCreation(null);
    }
}
