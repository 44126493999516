<div class="container-box">
    <!-- Docu Library Header -->
    <docu-library-header></docu-library-header>

    <ng-container *ngIf="!isOnChildRoute; else childRoute">
        <!-- Add button -->
        <div class="d-flex justify-between add-button-wrapper">
            <mat-spinner [ngStyle]="{ opacity: isSortingDataLoading ? 1 : 0 }" [diameter]="40"></mat-spinner>
            <button mat-button class="add-button" (click)="handleAddClick()">+ Add</button>
        </div>

        <!-- Data table -->
        <div class="loader-container" *ngIf="isLoading; else dataBlock">
            <mat-spinner [diameter]="70"></mat-spinner>
        </div>
        <ng-template #dataBlock>
            <ng-container *ngIf="dataSource.length > 0; else noDataBlock">

                <docu-lib-data-table
                    [dataSource]="dataSource"
                    style.transition-duration="0.25s"
                    (applySortingEmitter)="handleSorting($event)"
                    (selectTableItemEmitter)="handleOnSelectedTableItemChange($event)"
                    [ngStyle]="{ opacity: isPageDataLoading || isSortingDataLoading ? 0.5 : 1 }">
                </docu-lib-data-table>

                <!-- Pagination component -->
                <app-docu-lib-paginator
                    [itemsPerPage]="15"
                    [isLading]="isPageDataLoading"
                    [numberOfItems]="totalItemCount"
                    (changePageEmit)="handlePageChange($event)"
                ></app-docu-lib-paginator>
            </ng-container>
        </ng-template>
    </ng-container>

    <ng-template #childRoute></ng-template>

    <router-outlet></router-outlet>

    <ng-template #noDataBlock>
        <div class="d-flex justify-content-center">
            <no-data-component></no-data-component>
        </div>
    </ng-template>
</div>
