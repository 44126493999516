import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { StatisticService } from 'src/app/services/statistic.service';
import { AnalyticDataSource } from 'src/app/services/analytic.datasource';
import { DataTable } from '../data-table/data-table.component';
import { RescopeDialog } from '../common/confirmation-rescope-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AnalyticModel } from 'src/app/models/analytic';

@Component({
  selector: 'app-descope',
  templateUrl: './descope.component.html',
  styleUrls: ['./descope.component.css'],
})
export class DescopeComponent implements OnInit, AfterViewInit {
  public noDataAvailable: boolean = false;
  @ViewChild('descopeTable', { static: false }) descopeTable!: DataTable;
  rescopeRowSelected: boolean = false;
  public dialogRef: any;
  dataSource: AnalyticDataSource = new AnalyticDataSource(
    this.statisticService
  );

  public displayedColumns = [
    'NudgeIdentifier',
    'CheckBox',
    'ClientId',
    'ClientName',
    'ParentGrouping',
    'DueDateStr',
    'CountryOfIncorporation',
    'Revenue',
    'Bucket',
    'ClientType',
    'Analyst',
    'QCReviewer',
    'ClientContactTitle',
    'ClientContactEmail',
    'ClientContactName',
    'CompletionDateStr',
    'InitialOutreachDateStr',
    'ReasonForDescope',
    'Products',
    'RiskScore',
    'Status',
    'Aging',
    // 'Stage',
    'RowMenu',
  ];

  constructor(
    public statisticService: StatisticService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  onAddNewEntry() {}

  onPrint() {}

  onUploadExcel() {}

  public handleRescope(): void {
    this.dialogRef = this.dialog.open(RescopeDialog, {
      data: {
        data: this.descopeTable.selection.selected,
      },
    });

    this.dialogRef.afterClosed().subscribe((res: any) => {
      if(res.confirm) {
        this.descopeTable.handleRescope();
        this.descopeTable.clearSelection();
      }
    });
  }

  selectChanged(event: any) {
    this.rescopeRowSelected = event.some(
      (row: AnalyticModel) => row.Stage === 'Descoped'
    );
  }

  changeDisplayedColumns(chips: any) {
    this.displayedColumns = chips;
  }

  handleNoTableData(event: boolean) {
    this.noDataAvailable = event;
  }
}
