<ng-container *ngIf="!isChildRoute">
    <ng-container *ngIf="dataSource.length; else noDataBlock">
        <h1>Profile tab data</h1>
    </ng-container>

    <ng-template #noDataBlock>
        <div class="d-flex justify-content-center align-items-center">
            <no-data-component></no-data-component>
        </div>
    </ng-template>
</ng-container>

<router-outlet></router-outlet>
