<div layout="column" flex id="content" class="page-content">
  <div class="d-flex space-between p-b-30">
    <div class="header-wrapper">
      <div class="page-header d-flex" style="align-items: center">
        Dashboard
        <column-chips-popover
          *ngIf="noDataAvailable"
          [defaultDisplayedColumns]="displayedColumns"
          (changeDisplayedColumns)="changeDisplayedColumns($event)"
        ></column-chips-popover>
      </div>
    </div>

    <div class="d-flex" style="max-height: 100%">
      <!-- Action button group -->
      <div
        #tooltip="matTooltip"
        [matTooltip]="
          !selectedCases.length
            ? 'Please select at least one item from the list'
            : ''
        "
      >
        <button
          mat-button
          [matMenuTriggerFor]="action"
          [disabled]="!selectedCases.length"
          class="button-base action-button m-r-7"
          *ngIf="!userAcceses.caseActions.disabledAll"
        >
          Action
        </button>
        <mat-menu #action="matMenu">
          <!-- Assign -->
          <button mat-menu-item (click)="openAssignDialog()" [disabled]="!isAssignAllowed"  *ngIf="userAcceses.caseActions.assign">
            <div class="action-button-group"
              [ngStyle]="{
                color: !isAssignAllowed ? '#7E7D7D' : '#6418c3'
              }">
              Assign
              <img src="./../assets/icons/assign-button.svg" [ngClass]="{ 'descope-img': !isAssignAllowed }" alt="assign" />
            </div>
          </button>

          <!-- Nudge -->
          <button mat-menu-item (click)="nudge()" [disabled]="!isNudgeAllowed" *ngIf="userAcceses.caseActions.nudge">
            <div class="action-button-group"
              [ngStyle]="{
                color: !isNudgeAllowed ? '#7E7D7D' : '#6418c3'
              }">
              Nudge
              <img src="./../assets/icons/nudge-button.svg" [ngClass]="{ 'descope-img': !isNudgeAllowed }" alt="nudge" />
            </div>
          </button>

          <!-- Descope -->
          <div
            #tooltip="matTooltip"
            matTooltipPosition="right"
            [matTooltip]="
              !descopeRowSelected
                ? 'Select at least one none Descope stage item'
                : ''
            "
          >
            <button
              #descope_btn
              mat-menu-item
              (click)="openAssignDescope($event)"
              [disabled]="!descopeRowSelected"
              *ngIf="userAcceses.caseActions.descope"
            >
              <div
                class="action-button-group"
                [ngStyle]="{
                  color: !descopeRowSelected ? '#7E7D7D' : '#6418c3'
                }"
              >
                Descope
                <img
                  alt="descope"
                  class="descope-img"
                  [ngClass]="{ 'descope-img': !descopeRowSelected }"
                  src="./../assets/icons/descope-button.svg"
                />
              </div>
            </button>
          </div>

          <!-- Rescope -->
          <div
            #tooltip="matTooltip"
            matTooltipPosition="right"
            [matTooltip]="
              !rescopeRowSelected
                ? 'Select at least one Descope item from the list'
                : ''
            "
          >
            <button
              mat-menu-item
              [disabled]="!rescopeRowSelected"
              (click)="handleRescope()"
              *ngIf="userAcceses.caseActions.rescope"
            >
              <div
                [ngStyle]="{
                  color: !rescopeRowSelected ? '#7E7D7D' : '#6418c3'
                }"
                class="action-button-group"
              >
                Rescope
                <img
                  class="descope-img"
                  [ngClass]="{ 'descope-img': !rescopeRowSelected }"
                  src="./../assets/icons/rescope-button.svg"
                  alt="rescope"
                />
              </div>
            </button>
          </div>
        </mat-menu>
      </div>

      <!-- Add New Entry -->
      <button
        *ngIf="userAcceses.addNewEntry"
        mat-button
        [matMenuTriggerFor]="newEntry"
        class="button-base add-new-entry-button m-r-22"
      >
        Add New Entry
      </button>
      <mat-menu #newEntry="matMenu">
        <button disabled mat-menu-item>
          <div class="new-entry-button-group-disabled">
            Manual
            <img
              alt="manual"
              [class.disabled-icon]="true"
              src="./../assets/icons/manual-btn.svg"
            />
          </div>
        </button>
        <button mat-menu-item (click)="openExcelDialog()">
          <div class="new-entry-button-group">
            Excel
            <img src="./../assets/icons/excel-btn.svg" alt="excel" />
          </div>
        </button>
        <button disabled mat-menu-item [class.disabled]="true">
          <div class="new-entry-button-group-disabled">
            Database
            <img
              alt="database"
              [class.disabled-icon]="true"
              src="./../assets/icons/database-btn.svg"
            />
          </div>
        </button>
      </mat-menu>
    </div>
  </div>

  <div>
    <data-table
      #dtTable
      [isAdmin]="true"
      [dataSource]="dataSource"
      [selectedItems]="selectedCases"
      [displayedColumns]="displayedColumns"
      (loadingEvent)="loadingChange($event)"
      (selectionEvent)="selectChanged($event)"
      (noDisplayDataEmitter)="handleNoTableData($event)"
    ></data-table>
  </div>
</div>
<div *ngIf="isFirstUpload && busy" class="spinner-all-page">
  <app-loader-with-logo [isLoading]="busy"></app-loader-with-logo>
</div>
