import { Component, Inject } from '@angular/core';
import { ArchiveDataSource } from '../types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'delete-archive-dialog',
  templateUrl: 'delete-archive-dialog.component.html',
  styleUrls: ['delete-archive-dialog.component.scss'],
})
export class DeleteArchiveDialogComponent {
  constructor(
    private _snackBar: MatSnackBar,
    private questionnaireService: QuestionnaireService,
    private dialogRef: MatDialogRef<DeleteArchiveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { document: ArchiveDataSource }
  ) {}

  public loading: boolean = false;

  public handleRemoveArchiveDocument() {
    this.loading = true;
    this.questionnaireService
      .removeDocumentRequirement(this.data.document.Id)
      .subscribe((res) => {
        if (res.Success) {
          this.loading = false;
          this.dialogRef.close();
          this._snackBar.open(
            `Document #${this.data.document.Id} successfully deleted!`,
            'X',
            {
              duration: 3000,
              panelClass: ['success-snackbar'],
            }
          );
        }
      });
  }
}
