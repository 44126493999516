import {Helper} from 'src/app/helpers/helper';
import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {QuestionnaireService} from 'src/app/services/questionnaire.service';
import {
  RiskScoreData,
  PotentialRiskControls,
  RiskAttributesControls,
  PotentialScreeningOutcomeControls,
} from 'src/app/components/questionnaire/tabs/risk-score/tab-container/types';

interface RiskAttributesControl {
  [key: string]: string;
}

interface DropdownData {
  Name: string;
  Id: number;
}

@Component({
  selector: 'docu-library-risks',
  templateUrl: './risks.component.html',
  styleUrls: ['./risks.component.scss'],
})
export class DocuLibraryRiskTab implements OnInit {
  public dataSource: any;
  public controls: RiskAttributesControl;
  public potentialScreeningOutcomeControls: RiskAttributesControl;
  public potentialRiskControls: RiskAttributesControl;
  public risks: DropdownData[] = [];
  public id: number = 0;
  public highestRiskScore: { Name: string; Id: number } | undefined;
  public isLoading: boolean = false;
  public selectedPep: { Name: string; Id: number } | null = null;
  public selectedAdverseInfo: { Name: string; Id: number } | null = null;
  public selectedOverrideRiscScore: { Name: string; Id: number } | null = null;

  @Input() public isOnStructures: boolean = false;

  public get clientId() {
    return this.helper.getQuestionnaireClientId();
  }

  public get formControls() {
    return Object.keys(this.riskAttributesForm.controls);
  }

  public get potentialScreeningOutcomeFormControls() {
    return Object.keys(this.potentialScreeningOutcomeForm.controls);
  }

  public get potentialRiskFormControls() {
    return Object.keys(this.potentialRiskForm.controls);
  }

  public riskAttributesControlValue(key: string): any {
    return this.riskAttributesForm.controls[key].value;
  }

  public riskAttributesForm: FormGroup = new FormGroup({
    CountryCategories: new FormControl(''),
    CountryCategoryIds: new FormControl(''),
    CountryRisk: new FormControl(''),
    CountryExposureRisk: new FormControl(''),
    ReputationalRisk: new FormControl(''),
    PoliticalExposureRisk: new FormControl(''),
    IndustryRisk: new FormControl(''),
    ProductRisk: new FormControl(''),
    BusinessStructureRisk: new FormControl(''),
  });

  public potentialScreeningOutcomeForm: FormGroup = new FormGroup({
    Pep: new FormControl(''),
    PepDescription: new FormControl(''),
    AdverseInformation: new FormControl(''),
    NegativeInformationDetail: new FormControl(''),
  });

  public potentialRiskForm: FormGroup = new FormGroup({
    ClientRiskScore: new FormControl(''),
    OverrideClientRiskScore: new FormControl(''),
    Comment: new FormControl(''),
  });

  constructor(
    private helper: Helper,
    private snackBar: MatSnackBar,
    private questionnaireService: QuestionnaireService
  ) {
    this.controls = RiskAttributesControls;
    this.potentialRiskControls = PotentialRiskControls;
    this.potentialScreeningOutcomeControls = PotentialScreeningOutcomeControls;
  }

  ngOnInit(): void {
    // risk attributes changes handler
    this.riskAttributesForm.valueChanges.subscribe((changes) => {
      const formControlKeys = Object.keys(this.riskAttributesForm.controls);

      const riskScores = formControlKeys
        .map((key) => this.riskAttributesForm.controls[key].value.Id)
        .sort();

      this.highestRiskScore = this.risks.find((x) => x.Id === riskScores[0]);

      if (
        this.potentialRiskForm.controls['ClientRiskScore'].value?.Name !==
        this.highestRiskScore?.Name
      ) {
        this.potentialRiskForm.controls['ClientRiskScore'].setValue({
          ...this.highestRiskScore,
        });
      }
    });


    this.isLoading = true;
    this.questionnaireService.fetchRiskScoreDropdownData().subscribe((res) => {
      const data = res.Data;
      this.dataSource = res.Data;

      this.risks = data.Risks;

      this.questionnaireService
        .fetchRiskScoreData(this.clientId)
        .subscribe((res) => {
          this.isLoading = false;
          const data = res.Data;

          this.id = data && data.Id ? data.Id : 0;

          if (data) {
            this.populateRiskAttributesForm(data);
            this.populatePotentialScreeningOutcomeForm(data);
            this.populatePotentialRiskForm(data);
          }

          if (res.Message === 'Please, complete Questionnaire first') {
            this.snackBar.open(
              'Please make sure to complete the Questionnaire step first!',
              'X',
              {
                panelClass: ['error-snackbar'],
                duration: 3000,
              }
            );
          }
        });
    });
  }

  private populateRiskAttributesForm(data: RiskScoreData) {
    this.formControls.map((control) => {
      const idKey = control + 'Id';

      if (control === 'CountryCategories') {
        this.riskAttributesForm.controls[control].setValue(
          data[control as keyof RiskScoreData]
        );
        return;
      }

      if (control === 'CountryCategoryIds') {
        this.riskAttributesForm.controls[control].setValue(
          data[control as keyof RiskScoreData]
        );
        return;
      }

      this.riskAttributesForm.controls[control].setValue({
        Name: data[control as keyof RiskScoreData],
        Id: data[idKey as keyof RiskScoreData],
      });
    });
  }

  private populatePotentialScreeningOutcomeForm(data: RiskScoreData) {
    this.potentialScreeningOutcomeFormControls.map((control) => {
      const idKey = control + 'Id';

      if (control === 'PepDescription')
        this.potentialScreeningOutcomeForm.controls[control].setValue(
          data['PepDescription']
        );
      else if (control === 'NegativeInformationDetail')
        this.potentialScreeningOutcomeForm.controls[control].setValue(
          data['NegativeInformationDetail']
        );
      else {
        this.potentialScreeningOutcomeForm.controls[control].setValue({
          Name: data[control as keyof RiskScoreData],
          Id: data[idKey as keyof RiskScoreData],
        });
      }
    });
  }

  private populatePotentialRiskForm(data: RiskScoreData) {
    this.potentialRiskFormControls.map((control) => {
      const idKey = control + 'Id';
      if (control === 'ClientRiskScore') {
        this.potentialRiskForm.controls[control].setValue({
          ...this.highestRiskScore,
        });
      } else if (control === 'Comment')
        this.potentialRiskForm.controls[control].setValue(data['Comment']);
      else
        this.potentialRiskForm.controls[control].setValue({
          Name: data[control as keyof RiskScoreData],
          Id: data[idKey as keyof RiskScoreData],
        });
    });
  }
}
