<div class="icons-popup">
  <div class="icon-list-wrapper">
    <!-- Add new Photo button -->
    <profile-photo
      [isAddNewIconButton]="true"
      (imageUploadedEmitter)="handleNewPhotoAdded($event)"
      (fileTypeErrorEmitter)="fileTypeErrorHandler($event)"
    ></profile-photo>

    <!-- Icon list  -->
    <ng-container *ngFor="let icon of iconList; let i = index">
      <div
        class="img-box"
        [ngClass]="{ selected: icon.isSelected }"
        [ngStyle]="{
          background: icon.compressionInProgress ? 'white' : icon.background
        }"
      >
        <profile-photo
          [index]="i"
          [imgSrc]="icon.imgSrc"
          [imgAlt]="icon.imgAlt"
          [selected]="icon.isSelected"
          *ngIf="!icon.compressionInProgress"
          (clickEmitter)="handleIconClick($event)"
          (fileTypeErrorEmitter)="fileTypeErrorHandler($event)"
        ></profile-photo>

        <div class="profile-spinner-box" *ngIf="icon.compressionInProgress">
          <mat-spinner [diameter]="60"></mat-spinner>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Actions section -->
  <div class="actions-section">
    <div class="">
      <span class="error" *ngIf="isFileTypeError">
        Incorrect file type. Please make sure to select a file that matches one
        of the following type extensions: .jpg, .jpeg, .png, .svg, .tif, .tiff,
        .gif, .bmp
      </span>
    </div>
    <div class="d-flex error-message-wrapper">
      <button class="cancel-btn" (click)="handleCancel()">Cancel</button>
      <button
        class="save-btn"
        (click)="handleSave()"
        [disabled]="isSaveDisabled"
      >
        Save
      </button>
    </div>
  </div>
</div>
