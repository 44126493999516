<div class="dialog-wrapper">
  <div class="header d-flex">Remove Account</div>

  <div class="d-flex justify-space-between dialog-content">
    Are you absolutely certain that you wish to permanently delete Account #{{
      data.Id
    }}?
  </div>

  <div class="action-footer">
    <mat-spinner
      [diameter]="35"
      *ngIf="loading"
      style="margin-right: 1rem"
    ></mat-spinner>
    <button mat-button class="action-base" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      mat-button
      class="action-base submit"
      (click)="handleRemoveArchiveDocument()"
    >
      Yes, delete it!
    </button>
  </div>
</div>
