import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MdePopoverTrigger } from '@material-extended/mde';

@Component({
  selector: 'column-chips-popover',
  templateUrl: 'column-chips-popover.component.html',
  styleUrls: ['column-chips-popover.component.css'],
})
export class ColumnChipsPopover {
  @Output() changeDisplayedColumns: EventEmitter<any> = new EventEmitter();
  @ViewChild('popoverTrigger') popoverTrigger!: MdePopoverTrigger;

  @Input('defaultDisplayedColumns') displayedColumns = [
    'NudgeIdentifier',
    'CheckBox',
    'ClientId',
    'ClientName',
    'ParentGrouping',
    'DueDateStr',
    'CountryOfIncorporation',
    'Revenue',
    'Bucket',
    'ClientType',
    'Analyst',
    'QCReviewer',
    'ClientContactTitle',
    'ClientContactEmail',
    'ClientContactName',
    'CompletionDateStr',
    'InitialOutreachDateStr',
    'ReasonForDescope',
    'Products',
    'RiskScore',
    'Status',
    'Stage',
    'Aging',
    'Link',
    'NextStage',
    'RowMenu',
  ];

  public allColumns = [
    'CountryOfIncorporation',
    'Revenue',
    'Bucket',
    'ClientType',
    'Analyst',
    'QCReviewer',
    'ClientContactTitle',
    'ClientContactEmail',
    'ClientContactName',
    'CompletionDateStr',
    'InitialOutreachDateStr',
    'ReasonForDescope',
    'Products',
  ];

  columnLabels: any = {
    ClientId: ' Client ID ',
    ClientName: ' Client Name ',
    ParentGrouping: ' Parent Grouping ',
    DueDateStr: ' Due Date ',
    CountryOfIncorporation: ' Country of Incorporation ',
    Status: ' Status ',
    Revenue: ' Revenue ',
    RiskScore: ' Risk Score ',
    Bucket: ' Bucket ',
    ClientType: ' Client Type ',
    Analyst: ' Analyst ',
    QCReviewer: ' QC Reviewer ',
    ClientContactTitle: ' Client Contact Title ',
    ClientContactEmail: ' Client Contact Email ',
    ClientContactName: ' Client Contact Name ',
    CompletionDateStr: ' Completion Date ',
    InitialOutreachDateStr: ' Initial Outreach Date ',
    ReasonForDescope: ' Reason for Descope ',
    Products: ' Products ',
    Stage: 'Stage',
    Aging: 'Aging',
  };

  selectColumn(chip: any, chips: any): void {
    const selectedChips = chips.chips.filter((chip: any) => chip.selected);

    if (selectedChips.length > 1 || !chip.selected) {
      chip.toggleSelected();
    }
  }

  closePopover() {
    this.popoverTrigger.closePopover();
  }

  submitDisplayedColumns(chips: any) {
    const selectedValues = chips.chips
      .filter((chip: any) => chip.selected)
      .map((chip: any) => chip.value);

    this.changeDisplayedColumns.emit(
      [
        this.displayedColumns.includes('NudgeIdentifier') && 'NudgeIdentifier',
        this.displayedColumns.includes('CheckBox') && 'CheckBox',
        this.displayedColumns.includes('ClientId') && 'ClientId',
        this.displayedColumns.includes('ClientName') && 'ClientName',
        this.displayedColumns.includes('ParentGrouping') && 'ParentGrouping',
        this.displayedColumns.includes('DueDateStr') && 'DueDateStr',
        ...selectedValues,
        this.displayedColumns.includes('RiskScore') && 'RiskScore',
        this.displayedColumns.includes('Status') && 'Status',
        this.displayedColumns.includes('Stage') && 'Stage',
        this.displayedColumns.includes('Aging') && 'Aging',
        this.displayedColumns.includes('Link') && 'Link',
        this.displayedColumns.includes('NextStage') && 'NextStage',
        this.displayedColumns.includes('RowMenu') && 'RowMenu',
      ].filter(Boolean)
    );

    this.popoverTrigger.closePopover();
  }
}
