<div class="exemptions-container">
  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel
      hideToggle="true"
      [expanded]="true"
      #QuestionnairePanel
      (opened)="isSectionOpened.emit()"
      (closed)="isSectionCollapsed.emit()"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Questionnaire </mat-panel-title>
        <mat-panel-description>
          <div class="expansion-icon-wrapper">
            <mat-icon *ngIf="!QuestionnairePanel.expanded">add</mat-icon>
            <mat-icon *ngIf="QuestionnairePanel.expanded">remove</mat-icon>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <form
        [formGroup]="questionnaireForm"
        (ngSubmit)="onSubmit()"
        class="form"
      >
        <div class="spinner-container" style="padding: 20% 0" *ngIf="isLoading">
          <mat-spinner></mat-spinner>
        </div>

        <table
          mat-table
          #questionnaireMatTable
          [dataSource]="dataSource"
          class="mat-elevation-z8 w-100"
          (contentChanged)="tableContentChanged()"
          [ngStyle]="{
            opacity: isLoading ? 0 : 1
          }"
        >
          <!-- Question label -->
          <ng-container matColumnDef="questionLabel">
            <td mat-cell *matCellDef="let element" class="cell-border">
              <div class="plain-label">{{ element.label }}</div>
            </td>
          </ng-container>

          <!-- Answer section -->
          <ng-container matColumnDef="dataInputSection">
            <td mat-cell *matCellDef="let element">
              <!-- Switch between input types -->
              <ng-container [ngSwitch]="element.inputType">
                <!-- Regular Input -->
                <ng-container *ngSwitchCase="inputTypes.regularInput">
                  <regular-input-component
                    [id]="element.id"
                    [inputSize]="element.inputSize"
                    [control]="element.formControl"
                    [elId]="element.id"
                    [showValidationError]="showNewLegalNameValidationMessage"
                  >
                  </regular-input-component>
                </ng-container>

                <!-- Autocomplete -->
                <ng-container *ngSwitchCase="inputTypes.autocompleteInput">
                  <autocomplete-component
                    [id]="element.id"
                    [inputSize]="element.inputSize"
                    [control]="element.formControl"
                    [questionnaireData]="questionnaireData"
                    [autocompleteOptions]="element.autocompleteOptions"
                    [isAutocompleteSearchable]="
                      element.isAutocompleteSearchable
                    "
                    [isMultiselect]="element.isMultiselect"
                    (hideEntitySubType)="handleHideFormField()"
                  >
                  </autocomplete-component>
                </ng-container>

                <!-- Autocomplete multiselect with selected items displayed near the control -->
                <ng-container
                  *ngSwitchCase="inputTypes.autocompleteInputWithSelections"
                >
                  <autocomplete-multiselect-component
                    [id]="element.id"
                    [regions]="regions"
                    [countries]="countries"
                    [inputSize]="element.inputSize"
                    [control]="element.formControl"
                    [autocompleteOptions]="element.autocompleteOptions"
                    [isAutocompleteSearchable]="
                      element.isAutocompleteSearchable
                    "
                    (removeBtnClick)="handleRemoveItemClick($event)"
                  >
                  </autocomplete-multiselect-component>
                </ng-container>

                <!-- default textfield -->
                <ng-container *ngSwitchDefault>
                  <div class="plain-text">{{ element.formControl.value }}</div>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>

          <tr row mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>
