import { Injectable } from "@angular/core";
import { Observable, Observer, Subject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { Helper } from "../helpers/helper";
import { UserService } from "./user.service";

@Injectable()
export class WebsocketService {
  constructor(private userService: UserService, private helper: Helper) {}

  private subject!: Subject<MessageEvent>;

  public connect(userId: string): Observable<Subject<MessageEvent>>{
      return this.getTokenUrl(userId).pipe(map((response:any) => {
          this.subject = this.create(response.Data);
          this.helper.setPubSubUrl(response.Data);
          return this.subject;
      }));
  }

  public getTokenUrl(userId: string): Observable<any> {
    return this.userService.getPubSubUrl(userId);
  }

  private create(url: any): Subject<MessageEvent> {
    try{
      return this.createWebSocketSubject(url);
    }
    catch(err){
      console.log(err);
      this.helper.setPubSubUrl('');
      this.getTokenUrl(this.helper.getUserId()).subscribe(response => {
        if (response.Success){
          this.helper.setPubSubUrl(response.Data);
          console.log("Successfully connected: " + response.Data);
          return this.createWebSocketSubject(response.Data);
        }
        else
        {
          console.log("Error connecting: " + response.Message);
          return new Subject();
        }
      });
    }
    return new Subject();
  }

  createWebSocketSubject(url: string): Subject<MessageEvent>{
    let ws = new WebSocket(url);
    let observable = new Observable((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });
    let observer = {
      next: (data: Object) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      }
    };
    return Subject.create(observer, observable);
  }
}
