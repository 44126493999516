<!-- Initial Review Stage Questionnaire Data -->
<ng-container *ngIf="currentCaseStage === 'Initial Review'">
  <mat-tab-group
    #questionnaireTabs
    class="questionnaire-tabs"
    [selectedIndex]="currentTabIdx"
    (selectedTabChange)="onTabChange($event)"
    mat-align-tabs="center"
  >
    <!-- Products -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="tab-wrapper">
          <span>Products</span>
        </div>
      </ng-template>
      <div class="products-main-wrapper">
        <div
          class="table-wrapper"
          #tableWrapper
          [ngClass]="{
            'background-blocked': addNewProductInProgress || isEditMode
          }"
        >
          <ng-container [ngTemplateOutlet]="questionnaireHeader"></ng-container>
          <div
            class="d-flex justify-between mb-20"
            [ngClass]="{
              'background-blur': addNewProductInProgress || isEditMode
            }"
          >
            <div class="bucket-selection col-6">
              <div class="label-box col-4">Bucket</div>
              <div class="col-5">
                <mat-select
                  disableOptionCentering
                  aria-placeholder="Bucket"
                  placeholder="Bucket"
                  class="wiam-select secondary-select"
                  panelClass="wiam-select-panel wiam-select-panel-singl"
                  [value]="clientBucket"
                  (selectionChange)="caseBucketChange($event)"
                >
                  <mat-option *ngFor="let bucket of buckets" [value]="bucket">
                    {{ bucket }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="col-10 d-flex justify-end">
              <div>
                <ngx-skeleton-loader
                  count="1"
                  appearance="circle"
                  class="skeleton-loader"
                  *ngIf="loading"
                  [theme]="{
                    margin: '0 10px',
                    borderRadius: '0',
                    width: '80px'
                  }"
                ></ngx-skeleton-loader>
                <button
                  *ngIf="!loading"
                  class="primary-white-btn"
                  (click)="openAuditLogModal()"
                  mat-button
                >
                  Audit Log
                </button>
              </div>
              <div>
                <ngx-skeleton-loader
                  count="1"
                  appearance="circle"
                  class="skeleton-loader"
                  *ngIf="loading"
                  [theme]="{
                    margin: '0 10px',
                    borderRadius: '0'
                  }"
                ></ngx-skeleton-loader>
                <button
                  class="add-btn"
                  (click)="handleAddNewProduct()"
                  *ngIf="!loading"
                  mat-button
                  [disabled]="addNewProductInProgress"
                  [ngClass]="{
                    disabled: addNewProductInProgress
                  }"
                >
                  <img
                    class="plus-icon"
                    src="../../../../assets/icons/plus.svg"
                  />
                </button>
              </div>
            </div>
          </div>
          <questionnaire-table
            [loading]="loading"
            [dataSource]="questionnaireDataSource"
            (onSetProductInfo)="setProductInfo($event)"
            (onStopEditingProduct)="setBackdrop($event)"
            class="questionnaire-table"
            [clientId]="clientId"
            (applyProductFilterEmitter)="handleApplyFilters($event)"
            (onProductSelection)="handleProductSelection($event)"
          ></questionnaire-table>
          <div
            class="table-footer"
            [ngClass]="{
              'background-blur': addNewProductInProgress || isEditMode
            }"
          >
            <div class="d-flex">
              <div class="save-btn">
                <button
                  class="primary-purple-btn"
                  (click)="saveProducts()"
                  [ngClass]="{
                    disabled: !isTableDataChanged && !isNewProductInTable
                  }"
                >
                  Save
                </button>
              </div>
              <div class="submit-btn">
                <button
                  class="primary-purple-btn"
                  (click)="submitProducts()"
                  [ngClass]="{
                    disabled: !isSubmitEnabled || isNewProductInTable
                  }"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div #productInfo class="product-info" style="overflow-y: auto">
          <product-info
            [ngClass]="{
              'background-blur': addNewProductInProgress || isEditMode
            }"
            [productData]="selectedProductInfo"
            (onProductInfoChange)="productInfoChange($event)"
          ></product-info>
        </div>
      </div>
    </mat-tab>

    <!-- Questionaire -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="tab-wrapper">
          <span>Questionnaire</span>
        </div>
      </ng-template>
      <!-- Questionaire tab content -->
      <div *ngIf="currentTabIdx === 1">
        <div
          class="questionnaire-header-wrapper"
          [ngStyle]="{
            'pointer-events': isInitialReviewActiveProducts
              ? 'default'
              : 'none',
            opacity: isInitialReviewActiveProducts ? 1 : 0.5
          }"
        >
          <ng-container [ngTemplateOutlet]="questionnaireHeader"></ng-container>
        </div>
        <questionnaire-tab-container
          [ngStyle]="{
            'pointer-events': isInitialReviewActiveProducts
              ? 'default'
              : 'none',
            opacity: isInitialReviewActiveProducts ? 1 : 0.5
          }"
          [clientId]="+clientId"
        >
        </questionnaire-tab-container>
      </div>
    </mat-tab>

    <!-- Exemptions -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="tab-wrapper">
          <span>Exemptions</span>
        </div>
      </ng-template>
      <div *ngIf="currentTabIdx === 2">
        <div
          class="exemptions-header-wrapper"
          [ngStyle]="{
            'pointer-events': isInitialReviewActiveProducts
              ? 'default'
              : 'none',
            opacity: isInitialReviewActiveProducts ? 1 : 0.5
          }"
        >
          <ng-container [ngTemplateOutlet]="questionnaireHeader"></ng-container>
        </div>
        <div class="exemptions-wrapper">
          <exemptions
            [ngStyle]="{
              'pointer-events': isInitialReviewActiveProducts
                ? 'default'
                : 'none',
              opacity: isInitialReviewActiveProducts ? 1 : 0.5
            }"
            [clientId]="+clientId"
            (onSave)="onExemptionsSave($event)"
            (onSubmit)="onExemptionsSubmit($event)"
            #exemptions
          ></exemptions>
        </div>
      </div>
    </mat-tab>

    <!-- Risk Score -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="tab-wrapper">
          <span>Risk Score</span>
          <div class="cursive">(Preliminary)</div>
        </div>
      </ng-template>
      <div *ngIf="currentTabIdx === 3">
        <div
          class="questionnaire-header-wrapper"
          [ngStyle]="{
            'pointer-events': isInitialReviewActiveProducts
              ? 'default'
              : 'none',
            opacity: isInitialReviewActiveProducts ? 1 : 0.5
          }"
        >
          <ng-container [ngTemplateOutlet]="questionnaireHeader"></ng-container>
        </div>

        <risk-score-tab-container
          [ngStyle]="{
            'pointer-events': isInitialReviewActiveProducts
              ? 'default'
              : 'none',
            opacity: isInitialReviewActiveProducts ? 1 : 0.5
          }"
        ></risk-score-tab-container>
      </div>
    </mat-tab>

    <!-- Document Requirements -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="tab-wrapper">
          <span>Document Requirement</span>
        </div>
      </ng-template>
      <div *ngIf="currentTabIdx === 4">
        <div
          class="document-requirements-header-wrapper"
          [ngStyle]="{
            'pointer-events': isInitialReviewActiveProducts
              ? 'default'
              : 'none',
            opacity: isInitialReviewActiveProducts ? 1 : 0.5
          }"
        >
          <ng-container [ngTemplateOutlet]="questionnaireHeader"></ng-container>
        </div>
        <div class="document-requirements-wrapper">
          <document-requirement
            [ngStyle]="{
              'pointer-events': isInitialReviewActiveProducts
                ? 'default'
                : 'none',
              opacity: isInitialReviewActiveProducts ? 1 : 0.5
            }"
            [clientId]="clientId"
          ></document-requirement>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</ng-container>

<!-- Outreach Stage Questionnaire Data -->
<ng-container *ngIf="currentCaseStage === 'Outreach'">
  <mat-tab-group
    #questionnaireTabs
    class="questionnaire-tabs"
    [selectedIndex]="currentTabIdx"
    (selectedTabChange)="onTabChange($event)"
    mat-align-tabs="center"
  >
    <!-- Document Requirement -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="tab-wrapper">
          <span>Document Requirement</span>
        </div>
      </ng-template>
      <div *ngIf="currentTabIdx === 0">
        <div class="document-requirements-header-wrapper">
          <ng-container [ngTemplateOutlet]="questionnaireHeader"></ng-container>
        </div>
        <div class="document-requirements-wrapper">
          <document-requirement [clientId]="clientId"></document-requirement>
        </div>
      </div>
    </mat-tab>

    <!-- Products -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="tab-wrapper">
          <span>Products</span>
        </div>
      </ng-template>
      <div class="products-main-wrapper" *ngIf="currentTabIdx === 1">
        <div
          class="table-wrapper"
          #tableWrapper
          [ngClass]="{
            'background-blocked': addNewProductInProgress || isEditMode
          }"
        >
          <ng-container [ngTemplateOutlet]="questionnaireHeader"></ng-container>
          <div
            class="d-flex justify-between mb-20"
            [ngClass]="{
              'background-blur': addNewProductInProgress || isEditMode
            }"
          >
            <div class="w-100 d-flex justify-end">
              <div>
                <ngx-skeleton-loader
                  count="1"
                  appearance="circle"
                  class="skeleton-loader"
                  *ngIf="loading"
                  [theme]="{
                    margin: '0 10px',
                    borderRadius: '0',
                    width: '80px'
                  }"
                ></ngx-skeleton-loader>
                <button
                  *ngIf="!loading"
                  class="primary-white-btn"
                  (click)="openAuditLogModal()"
                  mat-button
                >
                  Audit Log
                </button>
              </div>
            </div>
          </div>
          <questionnaire-table
            [isCheckboxReadonly]="true"
            [loading]="loading"
            [dataSource]="questionnaireDataSource"
            (onSetProductInfo)="setProductInfo($event)"
            (onStopEditingProduct)="setBackdrop($event)"
            class="questionnaire-table"
            [clientId]="clientId"
            (applyProductFilterEmitter)="handleApplyFilters($event)"
            (onProductSelection)="handleProductSelection($event)"
          ></questionnaire-table>
          <div
            class="table-footer"
            [ngClass]="{
              'background-blur': addNewProductInProgress || isEditMode
            }"
          >
            <div class="d-flex">
              <div class="save-btn">
                <button
                  class="primary-purple-btn"
                  (click)="saveProducts()"
                  [ngClass]="{
                    disabled: !isTableDataChanged && !isNewProductInTable
                  }"
                >
                  Save
                </button>
              </div>
              <div class="submit-btn">
                <button
                  class="primary-purple-btn"
                  (click)="submitProducts()"
                  [ngClass]="{
                    disabled: !isSubmitEnabled || isNewProductInTable
                  }"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div #productInfo class="product-info" style="overflow-y: auto">
          <product-info
            [ngClass]="{
              'background-blur': addNewProductInProgress || isEditMode
            }"
            [productData]="selectedProductInfo"
            (onProductInfoChange)="productInfoChange($event)"
          ></product-info>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</ng-container>

<!-- Approvals Stage Questionnaire Data -->
<ng-container *ngIf="currentCaseStage === 'Approval'">
  <!-- Products -->
  <div
    class="products-main-wrapper"
    [class.readonly]="isApprovalProductsReadonly"
  >
    <div
      class="table-wrapper"
      #tableWrapper
      [ngClass]="{
        'background-blocked': addNewProductInProgress || isEditMode
      }"
    >
      <ng-container [ngTemplateOutlet]="questionnaireHeader"></ng-container>
      <div
        class="d-flex justify-between mb-20"
        [ngClass]="{
          'background-blur': addNewProductInProgress || isEditMode
        }"
      >
        <div class="w-100 d-flex justify-end">
          <div>
            <ngx-skeleton-loader
              count="1"
              appearance="circle"
              class="skeleton-loader"
              *ngIf="loading"
              [theme]="{
                margin: '0 10px',
                borderRadius: '0',
                width: '80px'
              }"
            ></ngx-skeleton-loader>
            <button
              *ngIf="!loading"
              class="primary-white-btn"
              (click)="openAuditLogModal()"
              mat-button
            >
              Audit Log
            </button>
          </div>
        </div>
      </div>
      <questionnaire-table
        [isCheckboxReadonly]="true"
        [loading]="loading"
        [dataSource]="questionnaireDataSource"
        (onSetProductInfo)="setProductInfo($event)"
        (onStopEditingProduct)="setBackdrop($event)"
        class="questionnaire-table"
        [clientId]="clientId"
        (applyProductFilterEmitter)="handleApplyFilters($event)"
        (onProductSelection)="handleProductSelection($event)"
      ></questionnaire-table>
      <div
        class="table-footer"
        [ngClass]="{
          'background-blur': addNewProductInProgress || isEditMode
        }"
      >
        <div class="d-flex">
          <div class="save-btn">
            <button
              class="primary-purple-btn"
              (click)="saveProducts()"
              [ngClass]="{
                disabled: !isTableDataChanged && !isNewProductInTable
              }"
            >
              Save
            </button>
          </div>
          <div class="submit-btn">
            <button
              class="primary-purple-btn"
              (click)="approveProducts()"
              [ngClass]="{
                disabled: !isSubmitEnabled || isNewProductInTable
              }"
            >
              Approve
            </button>
          </div>
        </div>
      </div>
    </div>
    <div #productInfo class="product-info" style="overflow-y: auto">
      <product-info
        [ngClass]="{
          'background-blur': addNewProductInProgress || isEditMode
        }"
        [productData]="selectedProductInfo"
        (onProductInfoChange)="productInfoChange($event)"
      ></product-info>
    </div>
  </div>
</ng-container>

<ng-template #questionnaireHeader>
  <questionnaire-header
    [caseId]="caseId"
    [clientId]="clientId"
    [clientName]="clientName"
    [clientBucket]="clientBucket"
    [addNewProductInProgress]="addNewProductInProgress"
    [isEditMode]="isEditMode"
    [ngClass]="{
      'background-blur': addNewProductInProgress || isEditMode
    }"
  >
  </questionnaire-header>
</ng-template>
