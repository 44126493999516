<ng-container [ngSwitch]="isAddNewIconButton">
  <!-- Add New Icon Button -->
  <ng-container *ngSwitchCase="true">
    <div class="add-new-icon-button" (click)="compressFile()">
      <div class="cross-stick horizontal"></div>
      <div class="cross-stick vertical"></div>
    </div>
  </ng-container>

  <!-- Available Icons list -->
  <ng-container *ngSwitchCase="false">
    <div
      class="icon-wrapper"
      (click)="handleClickEvent($event)"
      [ngClass]="{ selected: selected }"
    >
      <img
        [src]="imgSrc"
        [alt]="imgAlt"
        [name]="index"
        class="profile-icon-base"
      />
    </div>
  </ng-container>
</ng-container>
