import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { Helper } from '../helpers/helper';

@Injectable()
export class AuthService extends BaseService {
  private baseUrl: string = environment.baseUrl;
  public errorMessage!: string;
  constructor(private http: HttpClient, helper: Helper) {
    super(helper);
  }

  public authorize(data: any) {
    let body = JSON.stringify(data);
    return this.authorizeMe(body);
  }

  private authorizeMe(data: any) {
    return this.http
      .post<any>(this.baseUrl + '/User', data, super.header())
      .pipe(catchError(super.handleError));
  }
}
