<div class="dialog-wrapper">
  <div class="header d-flex">Action Required</div>

  <div class="d-flex justify-space-between dialog-content">
    <ng-container
      *ngIf="!data.approvalConditionPassed; else confirmationScreen"
    >
      <!-- Non Compliant Product Validation -->
      <ng-container>
        <div style="padding: 3rem 0">
          Cannot proceed: Invalid Product Status
        </div>
      </ng-container>
    </ng-container>

    <!--  Compliant Product Validation -->
    <ng-template #confirmationScreen>
      <div style="padding: 3rem 0">
        Are you sure you want to approve the case with the current product(s)
        status(es)?
      </div>
    </ng-template>
  </div>

  <div class="action-footer">
    <mat-spinner
      [diameter]="35"
      *ngIf="loading"
      style="margin-right: 1rem"
    ></mat-spinner>

    <button
      mat-button
      class="action-base submit"
      *ngIf="data.approvalConditionPassed"
      [mat-dialog-close]="true"
    >
      Yes
    </button>
    <button
      mat-button
      class="action-base"
      [class.submit]="!data.approvalConditionPassed"
      [mat-dialog-close]="false"
    >
      {{ data.approvalConditionPassed ? "No" : "Close" }}
    </button>
  </div>
</div>
