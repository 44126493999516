<div class="d-flex w-100 justify-center align-center spinner-box" *ngIf="isLoading">
    <mat-spinner [diameter]="70"></mat-spinner>
</div>

<ng-container>
    <div class="d-flex" [style.opacity]="isLoading ? 0 : 1">
        <button class="add-button-contact" (click)="handleAddNewContact()">+ Add</button>
    </div>

    <ng-container>
        <div class="d-flex flex-column contact-table-wrapper" [style.opacity]="isLoading ? 0 : 1">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <!-- First Name -->
                <ng-container matColumnDef="FirstName">
                    <th mat-header-cell *matHeaderCellDef class="mat-column-id">
                        <div class="d-flex t-head-styled">{{ tableColumns.FirstName }}</div>
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-id">
                        <div class="t-body-styled" [style.color]="'#25213B'" [style.font-family]="'Poppins-Regular'">
                            {{ element.FirstName }}
                        </div>
                    </td>
                </ng-container>

                <!-- Middle Name -->
                <ng-container matColumnDef="MiddleName">
                    <th mat-header-cell *matHeaderCellDef class="mat-column-id">
                        <div class="d-flex t-head-styled">{{ tableColumns.MiddleName }}</div>
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-id">
                        <div class="t-body-styled id-section">
                            {{ element.MiddleName }}
                        </div>
                    </td>
                </ng-container>

                <!-- Last Name -->
                <ng-container matColumnDef="LastName">
                    <th mat-header-cell *matHeaderCellDef class="mat-column-id">
                        <div class="d-flex t-head-styled">{{ tableColumns.LastName }}</div>
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-id">
                        <div class="t-body-styled id-section" [style.color]="'#25213B'" [style.font-family]="'Poppins-Regular'">
                            {{ element.LastName }}
                        </div>
                    </td>
                </ng-container>

                <!-- Type -->
                <ng-container matColumnDef="Type">
                    <th mat-header-cell *matHeaderCellDef class="mat-column-id">
                        <div class="d-flex t-head-styled">{{ tableColumns.Type }}</div>
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-id">
                        <div
                            class="t-body-styled"
                            [style.font-family]="'Poppins-Regular'"
                            [style.color]="element.Type === 'Internal' ? '#4A4AFF' : '#EF6A55'"
                        >
                            {{ element.Type }}
                        </div>
                    </td>
                </ng-container>

                <!-- Role -->
                <ng-container matColumnDef="Role">
                    <th mat-header-cell *matHeaderCellDef class="mat-column-id">
                        <div class="d-flex t-head-styled">{{ tableColumns.Role }}</div>
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-id">
                        <div class="t-body-styled id-section">
                            {{ element.Role }}
                        </div>
                    </td>
                </ng-container>

                <!-- Job Title -->
                <ng-container matColumnDef="JobTitle">
                    <th mat-header-cell *matHeaderCellDef class="mat-column-id">
                        <div class="d-flex t-head-styled">{{ tableColumns.JobTitle }}</div>
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-id">
                        <div class="t-body-styled id-section">
                            {{ element.JobTitle }}
                        </div>
                    </td>
                </ng-container>

                <!-- Phone -->
                <ng-container matColumnDef="Phone">
                    <th mat-header-cell *matHeaderCellDef class="mat-column-id">
                        <div class="d-flex t-head-styled">{{ tableColumns.Phone }}</div>
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-id">
                        <div class="t-body-styled phone" [style.color]="'#FD683E'">
                            {{ element.Phone }}
                        </div>
                    </td>
                </ng-container>

                <!-- Email -->
                <ng-container matColumnDef="Email">
                    <th mat-header-cell *matHeaderCellDef class="mat-column-id">
                        <div class="d-flex t-head-styled">{{ tableColumns.Email }}</div>
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-id">
                        <div class="t-body-styled id-section" [style.color]="'#6E6893'">
                            {{ element.Email }}
                        </div>
                    </td>
                </ng-container>

                <!-- Primary -->
                <ng-container matColumnDef="Primary">
                    <th mat-header-cell *matHeaderCellDef class="mat-column-id">
                        <div class="d-flex t-head-styled justify-center">{{ tableColumns.Primary }}</div>
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-id">
                        <div class="t-body-styled d-flex justify-center" [style.color]="'#7D8187'">
                            <mat-checkbox disabled class="scaled-checkbox" [checked]="element.IsPrimary"></mat-checkbox>
                        </div>
                    </td>
                </ng-container>

                <!-- Edit -->
                <ng-container matColumnDef="Edit">
                    <th mat-header-cell *matHeaderCellDef class="mat-column-id">
                        <div class="d-flex t-head-styled"></div>
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-id">
                        <div class="t-body-styled justify-center" [style.color]="'#7D8187'">
                            <mat-icon (click)="handleEditContact(element)" class="cursor-pointer" [style.color]="'#6418C3'">edit</mat-icon>
                        </div>
                    </td>
                </ng-container>

                <!-- Delete -->
                <ng-container matColumnDef="Delete">
                    <th mat-header-cell *matHeaderCellDef class="mat-column-id">
                        <div class="d-flex t-head-styled"></div>
                    </th>
                    <td mat-cell *matCellDef="let element" class="mat-column-id">
                        <div class="t-body-styled justify-center" [style.color]="'#7D8187'">
                            <mat-icon (click)="handleContactDelete(element)" class="cursor-pointer" [style.color]="'#6418C3'">delete_outline</mat-icon>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    [style.background]="i % 2 === 0 ? 'white' : '#FAF7FD7A'"
                    *matRowDef="let row; columns: displayedColumns; let i = index;"
                ></tr>
            </table>

            <custom-paginator
                [style.margin-top]="'1rem'"
                #pagination
                (changePageEmit)="handlePageChange($event)"
                [numberOfItems]="totalNumberOfItems"
                [itemsPerPage]="10"
                [hidePaginationLabel]="true"
            ></custom-paginator>
        </div>
    </ng-container>

    <ng-template #noData>
        <no-data-component></no-data-component>
    </ng-template>
</ng-container>
