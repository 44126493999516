import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { switchMap } from 'rxjs/operators';
import { Helper } from 'src/app/helpers/helper';

export const mockPolicies = [
  { countryCode: 'USA', status: 'Active' },
  { countryCode: 'CA', status: 'Frozen' },
  { countryCode: 'AUS', status: 'Frozen' },
  { countryCode: 'UK', status: 'Active' },
  { countryCode: 'EU', status: 'Active' },
  { countryCode: 'USA', status: 'Frozen' },
  { countryCode: 'USA', status: 'Requested' },
  { countryCode: 'CA', status: 'Active' },
  { countryCode: 'UK', status: 'Frozen' },
  { countryCode: 'USA', status: 'Requested' },
  { countryCode: 'AUS', status: 'Active' },
  { countryCode: 'CA', status: 'Frozen' },
  { countryCode: 'CA', status: 'Active' },
];

type Policy = {
  countryCode: string;
  status: string;
};

type ShowNextPoliciesFunc = {
  (): void;
  index?: number;
};

@Component({
  selector: 'policies',
  templateUrl: 'policies.component.html',
  styleUrls: ['policies.component.scss'],
})
export class PoliciesComponent implements OnInit, AfterViewInit {
  isLoading: boolean = true;

  policies: Policy[] = [];
  policiesToShow: Policy[] = [];
  itemsPerPage: number = 8;

  isPoliciesForSelectedProducts: boolean = true;

  @Input() clientId!: number;

  @ViewChild('policiesWrapper') policiesWrapper!: ElementRef;
  @ViewChildren('policyBox') childDivs!: QueryList<ElementRef>;
  @ViewChild('selectedBtn') selectedUnselectedBtn!: ElementRef;

  constructor(
    private helper: Helper,
    private questionnaireService: QuestionnaireService
  ) {}

  ngAfterViewInit(): void {
    const buttonClicks$ = fromEvent(
      this.selectedUnselectedBtn.nativeElement,
      'click'
    );

    this.isLoading = true;
    this.getPolicies();

    buttonClicks$
      .pipe(
        switchMap(() => {
          this.isLoading = true;
          this.isPoliciesForSelectedProducts =
            !this.isPoliciesForSelectedProducts;
          return this.questionnaireService.getPolicies(
            +this.clientId,
            this.isPoliciesForSelectedProducts
          );
        })
      )
      .subscribe((response) => {
        if (response.Success) {
          this.policiesToShow = response.Data.slice(0, this.itemsPerPage).map(
            (p: any) => ({ countryCode: p.CountryCode, status: p.Status })
          );
          this.policies = response.Data.map((p: any) => ({
            countryCode: p.CountryCode,
            status: p.Status,
          }));
          this.showNextPolicies.index = this.itemsPerPage;
        }
        this.isLoading = false;
      });
  }

  ngOnInit(): void {
    this.helper.onPoliciesChange().subscribe(() => this.getPolicies());
  }

  showNextPolicies: ShowNextPoliciesFunc = () => {
    if (
      !this.showNextPolicies.index ||
      this.showNextPolicies.index >= this.policies.length
    ) {
      this.showNextPolicies.index = 0;
    }
    this.policiesToShow = this.policies.slice(
      this.showNextPolicies.index,
      this.showNextPolicies.index + this.itemsPerPage
    );
    this.showNextPolicies.index += this.itemsPerPage;
  };

  public getPolicies(): void {
    this.isLoading = true;

    const clientId = this.clientId
      ? this.clientId
      : this.helper.getQuestionnaireClientId();

    this.questionnaireService
      .getPolicies(+clientId, this.isPoliciesForSelectedProducts)
      .subscribe((res) => {
        if (res.Success) {
          this.policiesToShow = res.Data.slice(0, this.itemsPerPage).map(
            (p: any) => ({ countryCode: p.CountryCode, status: p.Status })
          );
          this.policies = res.Data.map((p: any) => ({
            countryCode: p.CountryCode,
            status: p.Status,
          }));
          this.itemsPerPage = this.calculateAvailablePoliciesInWrapper();
          this.policiesToShow = res.Data.slice(0, this.itemsPerPage).map(
            (p: any) => ({ countryCode: p.CountryCode, status: p.Status })
          );
          this.showNextPolicies.index = this.itemsPerPage;
        }
        this.isLoading = false;
      });
  }

  calculateAvailablePoliciesInWrapper(): any {
    const wrapperWidth = this.policiesWrapper.nativeElement.offsetWidth * 2;
    let availableLength = 8;
    // if(!!this.childDivs.length) {
    //   const widestChild = this.childDivs.toArray().reduce((prev, current) => {
    //     if (current.nativeElement.offsetWidth > prev.nativeElement.offsetWidth) {
    //       return current;
    //     }
    //     return prev;
    //   });
    //   availableLength = Math.floor(wrapperWidth / widestChild.nativeElement.offsetWidth);
    // }
    return availableLength = Math.floor(wrapperWidth / 135);
    ;
  }
}
