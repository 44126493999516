<div class="apps-container">
  <div class="blocks-wrapper">
    <div class="label">Features</div>
    <div class="blocks">
      <a
        [routerLink]="'/home'"
        [style]="{ margin: '1rem' }"
        (click)="handleNavigateToSelectedApp(appTypes.Dashboard)"
      >
        <div class="block">
          <div class="block-icon dashboard-icon">
            <img src="../../../assets/icons/welcome-dashboard.svg" alt="" />
          </div>
          <div>
            <div class="block-label">Dashboard</div>
            <div class="block-description">
              Take control of your work day. In a just a few minutes, create a
              customized workflow
            </div>
          </div>
        </div>
      </a>

      <div
        class="block"
        (click)="openDialog(comingSoonPopover)"
        [style]="{ margin: '1rem' }"
      >
        <div class="block-icon onboarding-icon">
          <img src="../../../assets/icons/welcome-onboarding-req.svg" alt="" />
        </div>
        <div>
          <div class="block-label">Onboarding Request</div>
          <div class="block-description">
            Take control of your work day. In a just a few minutes, create a
            customized workflow
          </div>
        </div>
      </div>
    </div>
    <div class="blocks">
      <div
        class="block"
        (click)="openDialog(comingSoonPopover)"
        [style]="{ margin: '1rem' }"
      >
        <div class="block-icon outreact-icon">
          <img src="../../../assets/icons/welcome-outreach-portal.svg" alt="" />
        </div>
        <div>
          <div class="block-label">Client Outreach Portal</div>
          <div class="block-description">
            Take control of your work day. In a just a few minutes, create a
            customized workflow
          </div>
        </div>
      </div>
      <div
        class="block"
        routerLink="/docu-library"
        [style]="{ margin: '1rem' }"
        (click)="handleNavigateToSelectedApp(appTypes.DocLibrary)"
      >
        <div class="block-icon docu-icon">
          <img
            class="mr-t-15"
            src="../../../assets/icons/welcome-docu.svg"
            alt=""
          />
        </div>
        <div>
          <div class="block-label">Docu Library</div>
          <div class="block-description">
            Take control of your work day. In a just a few minutes, create a
            customized workflow
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="logo-wrapper">
    <img src="../../../assets/icons/login-logo.svg" />
  </div>
</div>

<ng-template #comingSoonPopover>
  <div class="coming-soon">
    <div>
      <img src="../../../assets/coming-soon.png" alt="" />
    </div>
    <div>
      <h3 class="coming-soon-label">Keep in touch</h3>
    </div>
    <div class="coming-soon-description">App is currently in develoment</div>
  </div>
</ng-template>
