<div class="main-container">
  <div *ngIf="!isLoading" class="login-container">
    <div class="login-form-wrapper">
      <div class="flex-box login-logo-box">
        <img src="../../../assets/icons/login-logo.svg" alt="" />
      </div>

      <form
        class="form-styled"
        [formGroup]="forgotPassForm"
        (ngSubmit)="submitForm()"
      >
        <div class="flex-box flex-column" style="position: relative">
          <input
            name="email"
            class="styled-login-input"
            placeholder="Email"
            formControlName="email"
            [ngClass]="{
              'error-input':
                forgotPassForm.controls['email'].errors &&
                forgotPassForm.controls['email'].touched
            }"
          />
        </div>

        <div>
          <div
            *ngIf="
              forgotPassForm.controls['email'].errors &&
              forgotPassForm.controls['email'].errors.required &&
              forgotPassForm.controls['email'].touched
            "
            class="error-message"
          >
            Email is required.
          </div>
        </div>

        <div class="form-actions">
          <div class="button-wrapper">
            <button mat-stroked-button routerLink="/login">Back</button>
          </div>
          <div class="button-wrapper submit-btn">
            <button mat-button type="submit">Submit email</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <app-loader-with-logo [isLoading]="isLoading"></app-loader-with-logo>
</div>
