<div mat-dialog-title class="dialog-heading">
  {{ dialogHeader }}
</div>

<div mat-dialog-content class="dialog-content">
  Item <b style="color: #6418c3">#INV-{{ clientId }}</b> will be moved to the
  <b style="color: #ffc806">{{ nextStage }}</b> stage. Are you sure you want to
  proceed?
</div>

<div mat-dialog-actions class="dialog-actions">
  <button mat-button class="confirm-button" (click)="onMoveToNextStage()">
    {{ dialogConfirmAction }}
  </button>
  <button
    mat-button
    mat-dialog-close
    class="deny-button"
    *ngIf="dialogDenyAction"
  >
    {{ dialogDenyAction }}
  </button>
</div>
