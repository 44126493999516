import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-display-columns-dialog',
  templateUrl: './display-columns-dialog.component.html',
  styleUrls: ['./display-columns-dialog.component.css']
})
export class DisplayColumnsDialogComponent {
  private dialog: any;
  displayColumns: { name: string, checked: boolean }[] = [];
  displayColumnsOld: { name: string, checked: boolean }[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any
  , private dialogRef: MatDialogRef<DisplayColumnsDialogComponent>) {
    this.dialog = dialogRef;

    data.allColumns.forEach((column: any) => {
      this.displayColumns.push({name: column, checked: false});
      this.displayColumnsOld.push({name: column, checked: false});
    });
    data.displayColumns.forEach((column: any) => {
      if (this.displayColumns.find(c => c.name === column)) {
        this.displayColumns.find(c => c.name === column)!.checked = true;
        this.displayColumnsOld.find(c => c.name === column)!.checked = true;
      }
    });
  }

  close(){
    this.dialog.close();
  }

  applyNewColumns(): void {
    this.dialog.close(this.displayColumns.filter(c => c.checked).map(c => c.name));
  }
}
