<div class="header-wrapper">
  <div class="header-label">{{ headerText }}</div>

  <div class="nav-section" [class.product-info-margin]="isProductInfoOpened">
    <div class="nav-link" routerLink="/welcome">Home</div>
    <div class="nav-separator">|</div>
    <div
      class="nav-link"
      routerLink="/home"
      (click)="handleNavigateToDashboard()"
    >
      Dashboard
    </div>
    <ng-container *ngIf="isOnChildRoute">
      <div class="nav-separator">|</div>
      <div
        class="nav-link"
        routerLink="/docu-library"
        (click)="handleNavigateToDocuLibSearch()"
      >
        Search
      </div>
    </ng-container>
  </div>
</div>
