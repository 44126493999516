<div class="wrapper">
  <button class="notif-btn">
    <!-- number of notification -->
    <div *ngIf="numberOfNotification > 0"
      class="num-of-notifs"
      [ngClass]="{
        'notif-count-over_99': numberOfNotification > 99,
        'notif-count-over_999': numberOfNotification > 999
      }"
    >
      {{ numberOfNotification }}
    </div>

    <img
      class="notif-icon"
      [ngClass]="{
        'notif-icon-over_99': numberOfNotification > 99,
        'notif-icon-over_999': numberOfNotification > 999
      }"
      src="./../assets/icons/notif.svg"
      alt="notifications"
    />
  </button>
</div>
