import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Helper } from 'src/app/helpers/helper';
import { FilterColumn } from 'src/app/models/filter_column';
import { UserService } from 'src/app/services/user.service';
import { ConfirmationDialog } from '../common/confirmation-dialog/confirmation-dialog.component';
import { CustomPaginator } from '../common/custom-paginator/custom-paginator.component';
import { MessageDialog } from '../common/message-dialog/message-dialog.component';

export interface UserAccessModel {
  UserId: number;
  EmployeeName: string;
  Email: string;
  Manager: string;
  AccessRoles: string[];
  Active: boolean;
  AllRoles: string[];
  ManagersList: string[];
}

interface ColumnNames {
  UserId: string;
  EmployeeName: string;
  Email: string;
  Manager: string;
  AccessRole: string;
  Active: string;
}

interface ApplyUserAccessFiltersPayload {
  column: string;
  direction: string;
  filterCol: any;
  filterColAdjusted: any;
  isReset?: boolean;
}

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.scss'],
})
export class UserAccessManagementComponent implements OnInit, AfterViewChecked {
  public managerControlWidth: number = 0;
  public accessRoleControlWidth: number = 0;
  @ViewChild('managerControl', { static: false }) managerControl: ElementRef =
    new ElementRef(null);
  @ViewChild('accessRoleControl', { static: false })
  accessRoleControl: ElementRef = new ElementRef(null);
  @ViewChild('pagination') pagination: CustomPaginator = new CustomPaginator();

  public numberOfUserAccessItems: number = 0;
  public changesWereMade: boolean = false;
  public editingStarted: boolean = false;
  public updatedUserManager: string = '';
  public currentEditNode: UserAccessModel = {
    UserId: 0,
    EmployeeName: '',
    Email: '',
    Manager: '',
    AccessRoles: [],
    Active: false,
    AllRoles: [],
    ManagersList: [],
  };

  public dialogRef: any;
  public dataSource!: any;
  public loading: boolean = false;
  public favoriteSeason: string = '';
  public addNewUserInProgress: boolean = false;
  public isChangesSaved: boolean = true;
  public seasons: string[] = ['Winter', 'Spring', 'Summer', 'Autumn'];
  public columnNames: ColumnNames = {
    UserId: 'User Id',
    EmployeeName: 'Employee Name',
    Email: 'Email',
    AccessRole: 'Access Role',
    Manager: 'Manager',
    Active: 'Active',
  };
  public menuColumns: string[] = [
    'UserId',
    'EmployeeName',
    'Email',
    'AccessRole',
    'Manager',
    'Active',
  ];
  public displayedColumns: string[] = [
    'userId',
    'employeeName',
    'email',
    'accessRole',
    'manager',
    'active',
    'actions',
  ];
  public filterColumns: FilterColumn[] = [];
  public filters: any = [];

  sorting = {
    sortBy: 'Id',
    sortDirection: 'desc',
  };

  paginator: any = {
    itemsPerPage: 0,
    numberOfItems: 0,
    pageIndex: 0,
    pageSize: 10,
  };

  isUserAvailableToAddNewUser: boolean = false;
  isManagerSelectDisabled: boolean = false;
  isTableReadOnly: boolean = false;

  userForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    accessRoles: new FormControl('', [Validators.required]),
    manager: new FormControl('', [Validators.required]),
  });

  updatedUsers: any[] = [];

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public cdr: ChangeDetectorRef,
    private userService: UserService,
    private helper: Helper
  ) {}

  public accessRoles: string[] = [];
  public managers: string[] = [];

  ngOnInit(): void {
    this.loading = true;

    const USERROLE = this.helper.getUser().Role.toLowerCase();

    if (USERROLE.includes('manager') || USERROLE.includes('administrator')) {
      this.isUserAvailableToAddNewUser = true;
    } else {
      this.isUserAvailableToAddNewUser = false;
    }

    if (
      (USERROLE.includes('kyc analyst') ||
        USERROLE.includes('name screening analyst') ||
        USERROLE.includes('otreach analyst')) &&
      (!USERROLE.includes('manager') || !USERROLE.includes('administrator'))
    ) {
      this.displayedColumns.pop();
      this.isTableReadOnly = true;
    } else {
      this.isTableReadOnly = false;
    }

    this.userService
      .getUserAccessColumnValues('Role')
      .subscribe((res) => (this.accessRoles = (res as any).Data));

    this.userService
      .getUserAccessColumnValues('Manager')
      .subscribe((res) => (this.managers = (res as any).Data));

    this.userService
      .getUserAccess({
        PageNumber: this.paginator.pageIndex,
        PageSize: this.paginator.pageSize,
        SortBy: 'Id',
        SortDirection: 'asc',
        FilterColumns: [],
      })
      .subscribe((res) => {
        if (res.Success) {
          this.dataSource = res.Data;
          this.numberOfUserAccessItems =
            this.dataSource.find((el: any) => el.TotalCount)?.TotalCount ?? 0;
        } else {
          console.log(res.Message);
        }
        this.loading = false;
      });
  }

  ngAfterViewChecked(): void {
    if (this.managerControl?.nativeElement) {
      this.managerControlWidth = this.managerControl.nativeElement.offsetWidth;
    }

    if (this.accessRoleControl?.nativeElement) {
      this.accessRoleControlWidth =
        this.accessRoleControl.nativeElement.offsetWidth;
    }
  }

  handleSearchChange(event: Event): void {}

  handleAddNewRoleChip(event: MouseEvent, element: UserAccessModel): void {
    event.stopPropagation();
    this.changesWereMade = true;

    const newValue = event.target && (event.target as HTMLElement).innerText;

    const currentEditingRow: UserAccessModel = this.dataSource.find(
      (el: UserAccessModel) => el.UserId === element.UserId
    );

    const isDuplicate = currentEditingRow.AccessRoles.some(
      (el) => el === newValue
    );

    if (newValue && isDuplicate) {
      const itemToBeDeleted = currentEditingRow.AccessRoles.findIndex(
        (el) => el === newValue
      );

      // remove the duplicate item
      this.dataSource = this.dataSource.map((el: UserAccessModel) => {
        if (
          el.UserId === element.UserId &&
          el.AccessRoles.some((el) => el === newValue)
        ) {
          el.AccessRoles.splice(itemToBeDeleted, 1);
        }
        return el;
      });
    } else {
      this.dataSource = this.dataSource.map((el: UserAccessModel) => {
        if (el.UserId === element.UserId && newValue) {
          el.AccessRoles.push(newValue);
        }
        return el;
      });
    }
    if (element.AccessRoles.some((item) => item === 'Manager')) {
      const user = this.helper.getUser();
      this.isManagerSelectDisabled = true;
      this.userForm.controls['manager'].setValue(
        user.FirstName + ' ' + user.LastName
      );
      this.dataSource = this.dataSource.map((el: UserAccessModel) => {
        if (el.UserId === element.UserId) {
          el.Manager = user.FirstName + ' ' + user.LastName;
        }
        return el;
      });
    }
    if (element.AccessRoles.some((item) => item === 'Administrator')) {
      this.dataSource = this.dataSource.map((el: UserAccessModel) => {
        if (el.UserId === element.UserId) {
          el.Manager = '';
        }
        return el;
      });
      this.isManagerSelectDisabled = true;
      this.userForm.controls['manager'].setValue('');
      this.userForm.controls['manager'].clearValidators();
      this.userForm.controls['manager'].setErrors({ required: false });
      this.userForm.controls['manager'].updateValueAndValidity();
    } else {
      this.isManagerSelectDisabled = false;
      if (!element.Manager.length) {
        this.userForm.controls['manager'].setErrors({ required: true });
      }
    }

    this.userForm.controls['accessRoles'].setValue(element.AccessRoles);
  }

  onSaveClick(): void {
    try {
      this.loading = true;
      console.log(this.updatedUsers);
      this.userService.updateUserAccess(this.dataSource).subscribe((res) => {
        if (!res.Success) {
          if (!res.Data) {
            this.loading = false;
            this.snackBar.open(res.Message, 'X', {
              duration: 3000,
              panelClass: ['error-snackbar'],
            });

            // removing newly added item from data source if we got BE error upon creation
            this.dataSource.shift();
            this.dataSource = [...this.dataSource];

            return;
          }

          const updatedItems = res.Data;

          // updating data source
          this.dataSource.map((el: any) => {
            const updatedItem = updatedItems?.find(
              (updatedEl: UserAccessModel) => el.UserId === updatedEl.UserId
            );

            return updatedItem ? updatedItem : el;
          });

          this.loading = false;
          this.editingStarted = false;

          // resetting the current edit node to default
          this.currentEditNode = {
            UserId: 0,
            EmployeeName: '',
            Email: '',
            Manager: '',
            AccessRoles: [],
            Active: false,
            AllRoles: [],
            ManagersList: [],
          };
          this.changesWereMade = false;
        } else {
          this.loading = false;
          this.isChangesSaved = true;
          this.dataSource = res.Data;
          this.numberOfUserAccessItems = this.dataSource.find(
            (el: any) => el.TotalCount
          ).TotalCount;
        }
      });
    } catch (error) {
      this.loading = false;
      this.isChangesSaved = true;
      this.snackBar.open((error as any).message, 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    }
  }

  handleChangeManager(event: MouseEvent, element: UserAccessModel): void {
    event.stopPropagation();

    this.currentEditNode = this.dataSource.find(
      (el: UserAccessModel) => el.UserId === element.UserId
    );

    this.dataSource = this.dataSource.map((el: UserAccessModel) => {
      if (el.UserId === element.UserId) {
        el.Manager = (event.target as HTMLElement)?.innerText;
      }
      return el;
    });

    this.changesWereMade = true;
    this.userForm.controls['manager'].setValue(element.Manager);
  }

  checkIfSelected(element: UserAccessModel, value: string): boolean {
    return element.AccessRoles.some((el) => el === value);
  }

  onActiveToggleChange(event: any, element: any) {
    this.dataSource = this.dataSource.map((el: UserAccessModel) => {
      if (el.UserId === element.UserId) {
        el.Active = !el.Active;
      }

      return el;
    });
    this.changesWereMade = true;
  }

  handleStartEditing(currentNode: UserAccessModel): void {
    this.editingStarted = true;
    this.currentEditNode = this.dataSource.find(
      (el: UserAccessModel) => el.UserId === currentNode.UserId
    );
    this.userForm.setValue({
      name: this.currentEditNode.EmployeeName,
      email: this.currentEditNode.Email,
      accessRoles: this.currentEditNode.AccessRoles,
      manager: this.currentEditNode.Manager,
    });

    if (currentNode.AccessRoles.some((item) => item === 'Administrator')) {
      this.isManagerSelectDisabled = true;
    } else {
      this.isManagerSelectDisabled = false;
    }
  }

  modelChanged(event: string, field: any) {
    this.dataSource = this.dataSource.map((el: any) => {
      if (el.UserId === this.currentEditNode.UserId) {
        el[field] = event;
      }
      return el;
    });
    this.changesWereMade = true;
  }

  handleAddNewUser() {
    this.dataSource = [
      {
        UserId: 0,
        EmployeeName: '',
        Email: '',
        Manager: '',
        AccessRoles: [],
        AllRoles: this.accessRoles,
        Active: true,
        ManagersList: this.managers,
      },
      ...this.dataSource,
    ];

    this.addNewUserInProgress = true;
    this.isChangesSaved = false;
    this.currentEditNode = this.dataSource[0];

    this.userForm.setValue({
      name: this.currentEditNode.EmployeeName,
      email: this.currentEditNode.Email,
      accessRoles: this.currentEditNode.AccessRoles,
      manager: this.currentEditNode.Manager,
    });
    Object.keys(this.userForm.controls).forEach((field) => {
      this.userForm.controls[field].markAsUntouched();
    });

    this.changesWereMade = true;
    this.cdr.detectChanges();
  }

  handleStopEditing() {
    if (!this.userForm.valid) {
      Object.keys(this.userForm.controls).forEach((field) => {
        this.userForm.controls[field].markAsTouched();
      });
      this.snackBar.open('Please fill all required fields.', 'X', {
        duration: 3000,
        panelClass: ['attention-snackbar'],
      });
      return;
    }
    const currentNodeIndex = this.updatedUsers.indexOf(this.currentEditNode);

    if (currentNodeIndex >= 0) {
      this.updatedUsers[currentNodeIndex] = this.currentEditNode;
    } else {
      this.updatedUsers.push(this.currentEditNode);
    }

    // resetting the current edit node to default
    this.currentEditNode = {
      UserId: 0,
      EmployeeName: '',
      Email: '',
      Manager: '',
      AccessRoles: [],
      Active: false,
      AllRoles: [],
      ManagersList: [],
    };

    this.editingStarted = false;
    this.addNewUserInProgress = false;
    this.isManagerSelectDisabled = false;
  }

  onDeleteClick(deleteUserId: string): void {
    //IMPLEMENT A SERVICE TO CHECK IF USER CAN BE DELETED
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'Are you sure you want to delete this user?',
        showMessage: false,
      },
      panelClass: 'popup-884',
    });

    this.dialogRef.afterClosed().subscribe((confirmation: any) => {
      try {
        if (confirmation.confirm) {
          this.loading = true;
          this.userService.deleteUserAccess(deleteUserId).subscribe((res) => {
            if (!res.Success) {
              this.dialog.open(MessageDialog, {
                data: {
                  title: '',
                  message: res.Message,
                },
                panelClass: 'popup-884',
              });
              this.loading = false;
            } else {
              if (
                this.dataSource.length === 1 &&
                this.paginator.pageIndex > 0
              ) {
                this.paginator.pageIndex = this.paginator.pageIndex - 1;
                this.pagination.currentPage.index = this.paginator.pageIndex;
                this.pagination.currentPage.active = true;
              }
              this.updateDataSource();
            }
          });
        }
      } catch (error) {
        this.loading = false;
        this.snackBar.open((error as any).message, 'X', {
          duration: 3000,
          panelClass: ['error-snackbar'],
        });
      }
    });
  }

  handlePageChange(index: number) {
    this.loading = true;
    this.paginator!.pageIndex = index;
    this.userService
      .getUserAccess({
        PageNumber: this.paginator.pageIndex,
        PageSize: this.paginator.pageSize,
        SortBy: 'Id',
        SortDirection: 'asc',
        FilterColumns: [],
      })
      .subscribe((res) => {
        if (res.Success) {
          this.dataSource = res.Data;
        } else {
          console.log(res.Message);
        }
        this.loading = false;
      });
  }

  handleApplyFiltersUserAccess(event: ApplyUserAccessFiltersPayload): void {
    this.loading = true;

    this.sorting.sortBy = event.column;
    this.sorting.sortDirection = event.direction;

    if (event.isReset) {
      this.filterColumns = this.filterColumns.filter(
        (x: any) => (x as any).Name !== event.column
      );
      event.filterColAdjusted = event.filterColAdjusted.filter(
        (x: any) => (x as any).Name !== event.column
      );

      this.userService
        .getUserAccess({
          PageNumber: this.paginator.pageIndex,
          PageSize: this.paginator.pageSize,
          SortBy: this.sorting.sortBy,
          SortDirection: this.sorting.sortDirection,
          FilterColumns: event.filterColAdjusted.filter(
            (x: any) => (x as any).Name !== event.column
          ),
        })
        .subscribe((res) => {
          if (res.Success) {
            this.numberOfUserAccessItems = res.Data.find(
              (x: any) => x.TotalCount
            )?.TotalCount;
            this.dataSource = res.Data;
          } else {
            console.error(res.Message);
          }
        });
    } else {
      this.filterColumns = event.filterColAdjusted;
      this.filters = event.filterColAdjusted;

      const currentFilter = this.filterColumns.find(
        (x) => x.ColumnName === event.column
      );
      const currentFilterIndex = this.filterColumns.indexOf(currentFilter!);
      const isCurrentFilterEmpty = currentFilter?.Excludes.length === 0;

      if (currentFilter && isCurrentFilterEmpty && currentFilterIndex !== -1) {
        this.filterColumns = this.filterColumns.filter((x, index) => {
          if (index === currentFilterIndex) return false;
          return x;
        });
      }
    }

    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  isColumnFiltered(columnName: string): boolean {
    return this.filterColumns.length
      ? !!this.filterColumns.find((x) => (x as any).Name === columnName)
      : false;
  }

  handleDataSourceUpdate(data: any): void {
    this.dataSource = data;
  }

  handleUpdateNumberOfItemsForPagination(numberOfItems: number): void {
    this.numberOfUserAccessItems = numberOfItems;
  }

  public removeAccessRole: string = '';

  handleAccessRoleChange(event: MouseEvent, trigger: MatMenuTrigger) {
    this.changesWereMade = true;
    if ((event.target as HTMLInputElement).name === 'remove-btn') {
      trigger.closeMenu();
    }

    this.dataSource.map((x: any) => {
      if (x.UserId === this.currentEditNode.UserId) {
        x.AccessRoles = this.currentEditNode.AccessRoles.filter(
          (x) => x !== this.removeAccessRole
        );
      }
    });

    this.userForm.controls['accessRoles'].setValue(
      this.currentEditNode.AccessRoles.filter(
        (x) => x !== this.removeAccessRole
      )
    );

    if (
      this.currentEditNode.AccessRoles.some((item) => item === 'Administrator')
      &&  this.userForm.controls['manager'].value === ''
    ) {
      this.dataSource = this.dataSource.map((el: UserAccessModel) => {
        if (el.UserId === this.currentEditNode.UserId) {
          el.Manager = '';
        }
        return el;
      });
      this.isManagerSelectDisabled = true;
      this.userForm.controls['manager'].setValue('');
      this.userForm.controls['manager'].clearValidators();
      this.userForm.controls['manager'].setErrors({ required: false });
      this.userForm.controls['manager'].updateValueAndValidity();
    } else {
      this.isManagerSelectDisabled = false;
      if (!this.currentEditNode.Manager.length) {
        this.userForm.controls['manager'].setErrors({ required: true });
      }
    }
  }

  setRemoveItem(item: string) {
    this.removeAccessRole = item;
  }

  updateDataSource() {
    const updateSubscription = this.userService
      .getUserAccess({
        PageNumber: this.paginator.pageIndex,
        PageSize: this.paginator.pageSize,
        SortBy: 'Id',
        SortDirection: 'asc',
        FilterColumns: [],
      })
      .subscribe((res) => {
        if (res.Success) {
          this.dataSource = res.Data;
          this.numberOfUserAccessItems =
            this.dataSource.find((el: any) => el.TotalCount)?.TotalCount ?? 0;
        } else {
          console.log(res.Message);
        }
        updateSubscription.unsubscribe();
        this.loading = false;
      })
  }
}
