import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Helper } from 'src/app/helpers/helper';
import { UserService } from 'src/app/services/user.service';

export interface IconList {
  imgSrc: string;
  imgAlt: string;
  isSelected: boolean;
  background: string;
  compressionInProgress?: boolean;
  uploadedButNotYetSaved?: boolean;
}

@Component({
  selector: 'avatar-selector',
  templateUrl: './avatar-selector-dialog.component.html',
  styleUrls: ['./avatar-selector-dialog.component.css'],
})
export class AvatarSelector implements OnChanges {
  public iconList: IconList[] = Array(240)
    .fill(null)
    .map((el, index) => ({
      imgSrc: `./../assets/common-user-profile-icons/avatar-${index + 1}.png`,
      imgAlt: `Profile Photo ${index + 1}`,
      isSelected: false,
      background: '#E7EBFB',
    }));

  public isSaveDisabled: boolean = true;
  public isFileTypeError: boolean = false;
  public compressionInProgress: boolean = false;

  @Input() public customImages: string[] = [];
  @Output() public closeDialogEmitter = new EventEmitter();

  constructor(private userService: UserService, private helper: Helper) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.customImages.currentValue &&
      changes.customImages.currentValue.length
    ) {
      changes.customImages.currentValue.map(
        (iconUrl: string, index: number) => {
          this.iconList.unshift({
            imgSrc: iconUrl,
            imgAlt: `Custom Photo ${index + 1}`,
            isSelected: false,
            background: '#E7EBFB',
          });
        }
      );
    }
  }

  public handleSave(): void {
    this.helper.updateImgLoadingStatus(true);
    if (this.iconList.some((el) => el.isSelected)) {
      const selectedItem = this.iconList.find((el) => el.isSelected);

      if (selectedItem)
        this.userService
          .putSelectedUserProfileIcon(selectedItem.imgSrc)
          .subscribe(() => {
            this.closeDialogEmitter.emit();

            // updating profile URL
            this.helper.callLeftMenuChanged();
            window.localStorage.setItem(
              'profileUrlBackground',
              selectedItem.background
            );

            // this.helper.updateImgLoadingStatus(false);
          });
    }
  }

  dataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  public handleCancel(): void {
    this.closeDialogEmitter.emit();
    this.isFileTypeError = false;
  }

  public handleIconClick(index: number): void {
    // check if any icons were selected
    if (this.iconList.some((el) => el.isSelected)) {
      const selectedItem = this.iconList.find((el) => el.isSelected);

      // if user clicks on previously selected item, we need to unselect it
      if (this.iconList.indexOf(selectedItem!) === index) {
        this.iconList[this.iconList.indexOf(selectedItem!)].isSelected = false;
        this.isSaveDisabled = true;
        return;
      }

      // unselect every selected item
      this.iconList.map((el) => (el.isSelected = false));
    }

    // select chosen icon
    this.iconList[index].isSelected = !this.iconList[index].isSelected;
    this.isSaveDisabled = false;
  }

  public handleNewPhotoAdded(data: string): void {
    const newImageData: IconList = {
      imgSrc: data ? data : '',
      imgAlt: 'newly uploaded image',
      isSelected: false,
      compressionInProgress: data ? false : true,
      uploadedButNotYetSaved: true,
      background: 'white',
    };

    // check if there are any icons that are being compressed right now
    if (data && this.iconList.find((el) => !el.imgSrc)) {
      const processingImage = this.iconList.find((el) => !el.imgSrc);

      if (processingImage) {
        processingImage.imgSrc = data;
        processingImage.compressionInProgress = false;
      }
    } else this.iconList.unshift(newImageData);
  }

  public fileTypeErrorHandler(event: any): void {
    this.isFileTypeError = !this.isFileTypeError;
  }
}
