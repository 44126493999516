import {Helper} from '../helpers/helper';
import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

interface StructureItem {
    ChildStructure: StructureItem[],
    EntityType: string,
    ClientName: string,
    ClientPercentage: string,
    ClientType: string,
    Id: number,
    IsVisible: boolean,
    ParentId: number,
}

export interface StructuresDataList {
    ChildStructure: StructuresDataList[],
    EntityType: string,
    ClientName: string,
    ClientPercentage: string,
    ClientType: string,
    Id: number,
    IsVisible: boolean,
    ParentId: number,
}

interface StructuresPayload {
    Data: string
}

interface RightTabPayload {
    Data: RightTabData
}

export interface CommonData {
    ClientAssociationTypes: string[],
    EntityTypes: string[],
    IdentificationTypesForEntity: string[],
    IdentificationTypesForIndividual: string[],
    NegativeNewsTypes: string[],
    OwnershipRanges: string[],
    OwnershipTypes: string[],
    PEPStatuses: string[],
    RelationshipTypes: string[],
    ScreeningAssessments: string[]
}

interface CommonDataPayload {
    Data: CommonData,
}

interface RightTabData {
    BusinessNumber: string,
    ClientAssotiationType: string,
    Country: string,
    CountryofIssue: string,
    DescribeHitResults: string,
    DocumentUrl: string,
    EntityType: string,
    EntityName: string,
    ExpiryDate: string,
    FirstName: string,
    LastName: string,
    MiddleName: string,
    Id: number,
    IdenatificationOther: string,
    IdentificationType: string,
    NatureOfBusiness: string,
    NegativeNewsType: string,
    Occupation: string,
    OwnershipPercentage: string,
    OwnershipRange: string,
    OwnershipType: string,
    PEPStatus: string,
    PostalCode: string,
    RelationshipOther: string,
    RelationshipType: string,
    Role: string,
    ScreeningAssessment: string,
    ScreeningOutcomeOther: string,
    State: string,
    StreetAddress: string
}

interface AddStructureItemPayload {
    ParentId: number,
    ClientId: number,
    ClientName: string | null,
    FirstName: string | null,
    MiddleName: string | null,
    LastName: string | null,
    EntityType: string
}

interface StructureSearchPayload {
    PageNumber: number,
    PageSize: number,
    EntityType: string,
    Filter: {
        ClientId: number | null,
        EntityName: string | null,
        FirstName: string | null,
        MiddleName: string | null,
        LastName: string | null
    } | null
}

interface StructureUpdatePayload {
    Id: number,
    EntityName: string,
    FirstName: string,
    MiddleName: string,
    LastName: string,
    StreetAddress: string,
    Country: string,
    State: string,
    PostalCode: string,
    ClientAssotiationType: string,
    EntityType: string,
    IdentificationType: string,
    IdenatificationOther: string,
    BusinessNumber: number,
    CountryofIssue: string,
    ExpiryDate: string,
    DocumentUrl: string,
    Occupation: string,
    NatureOfBusiness: string,
    RelationshipType: string,
    RelationshipOther: string,
    Role: string,
    OwnershipType: string,
    OwnershipPercentage: string,
    OwnershipRange: string,
    PEPStatus: string,
    NegativeNewsType: string,
    ScreeningOutcomeOther: string,
    ScreeningAssessment: string,
    DescribeHitResults: string
}

@Injectable()
export class StructuresService extends BaseService {
    private baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient, helper: Helper) {
        super(helper);
    }

    public getStructureData(clientId: number): Observable<StructuresPayload> {
        return this.http.get<StructuresPayload>(this.baseUrl + `/Structure`, {
            params: new HttpParams()
                .set('clientId', +clientId),
            headers: super.header().headers,
        });
    }

    public createNewStructureItem(payload: AddStructureItemPayload) {
        return this.http.post<{ Data: StructureItem }>(
            this.baseUrl + `/Structure/childItem`,
            payload,
            super.header()
        );
    }

    public searchStructures(payload: StructureSearchPayload) {
        return this.http.post<StructureSearchPayload>(
            this.baseUrl + `/Structure/search`,
            payload,
            super.header()
        );
    }

    public fetchRightTabData(structureId: number): Observable<RightTabPayload> {
        return this.http.get<RightTabPayload>(this.baseUrl + `/Structure/rightTab`, {
            params: new HttpParams()
                .set('structureItemId', structureId),
            headers: super.header().headers,
        });
    }

    public fetchCommonData(): Observable<CommonDataPayload> {
        return this.http.get<CommonDataPayload>(this.baseUrl + `/Structure/commonData`, {
            headers: super.header().headers,
        });
    }

    public updateStructureDate(payload: StructureUpdatePayload) {
        return this.http.post<StructureUpdatePayload>(
            this.baseUrl + `/Structure/save`,
            payload,
            super.header()
        );
    }

    public removeStructureAndChildren(clientId: number, structureItemId: number) {
        return this.http.put(
            this.baseUrl + `/Structure/delete?clientId=${clientId}&structureItemId=${structureItemId}`,
            {},
            super.header());
    }

    public restoreStructureAndChildren(clientId: number, structureItemId: number) {
        return this.http.put(
            this.baseUrl + `/Structure/restore?clientId=${clientId}&structureItemId=${structureItemId}`,
            {},
            super.header());
    }

    public attachFileToStructure(file: FormData) {
        const headerFileUpload = () => {
            let headers = new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                withCredentials: 'true',
            });
            if (this.helper.isAuthenticated()) {
                headers = headers.set(
                    'Authorization',
                    'Bearer ' + this.helper.getToken()
                );
            }
            return { headers: headers };
        };

        return this.http.put(
            this.baseUrl + `/Structure/uploadFile`,
            file,
            headerFileUpload()
        );
    }
}
