<div class="d-flex w-100 justify-center align-center spinner-box" *ngIf="isLoading; else dataBlock">
    <mat-spinner [diameter]="70"></mat-spinner>
</div>

<ng-template #dataBlock>
    <ng-container *ngIf="dataSource; else noDataBlock">
        <div class="d-flex w-100 tab-three-column-box">
            <form [formGroup]="df2Form" class="d-flex w-100">
                <!-- I Column -->
                <div class="d-flex flex-column data-column">
                    <!-- Institution -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="institution">Institution</label>
                        <input id="institution" formControlName="institution">
                    </div>

                    <!-- Is The Institution Acting As An Investment Advisor, Asset Manager Or Other Agent? -->
                    <div class="control-box w-100">
                        <label for="institutionActingAs">Is The Institution Acting As An Investment Advisor, Asset
                            Manager Or
                            Other Agent?</label>
                        <input id="institutionActingAs" formControlName="institutionActingAs">
                    </div>

                    <!-- NFA ID -->
                    <div class="control-box w-100">
                        <label for="nfaId">NFA ID</label>
                        <input id="nfaId" formControlName="nfaId">
                    </div>

                    <!-- Principal Business -->
                    <div class="control-box w-100">
                        <label for="principalBusiness">Principal Business</label>
                        <input id="principalBusiness" formControlName="principalBusiness">
                    </div>

                    <!-- CFTC Swap Entity -->
                    <div class="control-box w-100">
                        <label for="cftcSwapEntity">CFTC Swap Entity</label>
                        <input id="cftcSwapEntity" formControlName="cftcSwapEntity">
                    </div>

                    <!-- Financial Entity -->
                    <div class="control-box w-100">
                        <label for="financialEntity">Financial Entity</label>
                        <input id="financialEntity" formControlName="financialEntity">
                    </div>

                    <!-- Financial Company -->
                    <div class="control-box w-100">
                        <label for="financialCompany">Financial Company</label>
                        <input id="financialCompany" formControlName="financialCompany">
                    </div>

                    <!-- PCA Principal Information -->
                    <app-regulatory-block-with-header blockHeader="PCA Principal Information">
                        <!-- Insured Depository Institution -->
                        <div class="control-box w-100">
                            <label for="insuredDepositoryInstitution">Insured Depository Institution</label>
                            <input id="insuredDepositoryInstitution" formControlName="insuredDepositoryInstitution">
                        </div>

                        <!-- Email Address For Delivery Of Notices -->
                        <div class="control-box w-100">
                            <label for="emailAddressForDeliveryOfNotices">Email Address For Delivery Of Notices</label>
                            <input id="emailAddressForDeliveryOfNotices"
                                   formControlName="emailAddressForDeliveryOfNotices">
                        </div>

                        <!-- Email Address For Delivery Of Risk Evaluations -->
                        <div class="control-box w-100">
                            <label for="emailAddressForDeliveryOfRiskEvaluations">Email Address For Delivery Of Risk
                                Evaluations</label>
                            <input id="emailAddressForDeliveryOfRiskEvaluations"
                                   formControlName="emailAddressForDeliveryOfRiskEvaluations">
                        </div>

                        <!-- Email Address For Delivery Of Portfolio Data -->
                        <div class="control-box w-100">
                            <label for="emailAddressForDeliveryOfPortfolioData">Email Address For Delivery Of Portfolio
                                Data</label>
                            <input id="emailAddressForDeliveryOfPortfolioData"
                                   formControlName="emailAddressForDeliveryOfPortfolioData">
                        </div>

                        <!-- Local Businessday City Or Cities -->
                        <div class="control-box w-100">
                            <label for="localBusinessdayCityOrCities">Local Businessday City Or Cities</label>
                            <input id="localBusinessdayCityOrCities" formControlName="localBusinessdayCityOrCities">
                        </div>

                        <!-- DF Schedule 3 -->
                        <div class="control-box w-100">
                            <label for="dfSchedule3">DF Schedule 3</label>
                            <input id="dfSchedule3" formControlName="dfSchedule3">
                        </div>

                        <!-- DF Schedule 4 -->
                        <div class="control-box w-100">
                            <label for="dfSchedule4">DF Schedule 4</label>
                            <input id="dfSchedule4" formControlName="dfSchedule4">
                        </div>
                    </app-regulatory-block-with-header>
                </div>

                <!-- II Column -->
                <div class="d-flex flex-column data-column">
                    <!-- Does PCA Principal Agree To Review Or Exchange Portfolio Data? -->
                    <div class="control-box w-100">
                        <label for="pcaPrincipalAgreeToReviewOrExchangeData">
                            Does PCA Principal Agree To Review Or Exchange Portfolio Data?
                        </label>
                        <input
                            id="pcaPrincipalAgreeToReviewOrExchangeData"
                            formControlName="pcaPrincipalAgreeToReviewOrExchangeData"
                        >
                    </div>

                    <!-- Does PCA Principal Agree To Reconcile Against SDR Data -->
                    <div class="control-box w-100">
                        <label for="pcaPrincipalAgreeToReconcileAgainstSdrData">
                            Does PCA Principal Agree To Reconcile Against SDR Data
                        </label>
                        <input
                            id="pcaPrincipalAgreeToReconcileAgainstSdrData"
                            formControlName="pcaPrincipalAgreeToReconcileAgainstSdrData"
                        >
                    </div>

                    <!-- Standing End User Exception Election -->
                    <div class="control-box w-100">
                        <label for="standingEndUserExceptionElection">
                            Standing End User Exception Election
                        </label>
                        <input id="standingEndUserExceptionElection" formControlName="standingEndUserExceptionElection">
                    </div>

                    <!-- Standing Opt-Out Of Annual Filing -->
                    <div class="control-box w-100">
                        <label for="standingOptOutOfAnnualFiling">Standing Opt-Out Of Annual Filing</label>
                        <input id="standingOptOutOfAnnualFiling" formControlName="standingOptOutOfAnnualFiling">
                    </div>

                    <!-- Is PCA Principal Electing The Finance Affiliate Exception -->
                    <div class="control-box w-100">
                        <label for="isPcaPrincipalElectingTheFinanceAffiliateException">
                            Is PCA Principal Electing The Finance Affiliate Exception
                        </label>
                        <input
                            id="isPcaPrincipalElectingTheFinanceAffiliateException"
                            formControlName="isPcaPrincipalElectingTheFinanceAffiliateException"
                        >
                    </div>

                    <!-- Is PCA Principal Electing The Hedging Affiliate Exception -->
                    <div class="control-box w-100">
                        <label for="isPcaPrincipalElectingTheHedgingAffiliateException">
                            Is PCA Principal Electing The Hedging Affiliate Exception
                        </label>
                        <input
                            id="isPcaPrincipalElectingTheHedgingAffiliateException"
                            formControlName="isPcaPrincipalElectingTheHedgingAffiliateException"
                        >
                    </div>

                    <!-- Is PCA Principal Electing The Hedging Affiliate Exception -->
                    <div class="control-box w-100">
                        <label for="isPcaPrincipalExemptUnderTheSmallBankExemption">
                            Is PCA Principal Exempt Under The Small Bank Exemption
                        </label>
                        <input
                            id="isPcaPrincipalExemptUnderTheSmallBankExemption"
                            formControlName="isPcaPrincipalExemptUnderTheSmallBankExemption"
                        >
                    </div>

                    <!-- How Does PCA Principal Generally Meet Its Financial Obligations Associated With Enteringinto Non Cleared Swaps -->
                    <div class="control-box w-100">
                        <label for="howPcaPrincipalMeetsFinancialObligationsAssociatedWithEnteringintoNonClearedSwaps">
                            How Does PCA Principal Generally Meet Its Financial Obligations Associated With Entering
                            into Non
                            Cleared Swaps
                        </label>
                        <ng-select
                            [items]="
                                dropdownData
                                ? dropdownData.HowDoesPCAPrincipalGenerallyMeetItsFinancialObligationsAssociatedWithEnteringIntoNonClearedSwaps
                                : null
                            "
                            id="howPcaPrincipalMeetsFinancialObligationsAssociatedWithEnteringintoNonClearedSwaps"
                            formControlName="howPcaPrincipalMeetsFinancialObligationsAssociatedWithEnteringintoNonClearedSwaps">
                        </ng-select>
                    </div>

                    <!-- Is PCA Principal An SEC Issues/Filer -->
                    <div class="control-box w-100">
                        <label for="isPcaPrincipalSecIssuesFiler">Is PCA Principal An SEC Issues/Filer</label>
                        <input id="isPcaPrincipalSecIssuesFiler" formControlName="isPcaPrincipalSecIssuesFiler">
                    </div>

                    <!-- SEC Central Index Key Number -->
                    <div class="control-box w-100">
                        <label for="secCentralIndexKeyNumber">SEC Central Index Key Number</label>
                        <input id="secCentralIndexKeyNumber" formControlName="secCentralIndexKeyNumber">
                    </div>

                    <!-- Did PCA Principal Receive Election Approval -->
                    <div class="control-box w-100">
                        <label for="didPcaPrincipalReceiveElectionApproval">Did PCA Principal Receive Election
                            Approval</label>
                        <input id="didPcaPrincipalReceiveElectionApproval"
                               formControlName="didPcaPrincipalReceiveElectionApproval">
                    </div>

                    <!-- Does PCA Principal Agree To The ISDA March 2013 DF Protocol Master Agreement -->
                    <div class="control-box w-100">
                        <label for="pcaPrincipalAgreeToIsdaMarch2013DfProtocolMasterAgreement">
                            Does PCA Principal Agree To The ISDA March 2013 DF Protocol Master Agreement
                        </label>
                        <input
                            id="pcaPrincipalAgreeToIsdaMarch2013DfProtocolMasterAgreement"
                            formControlName="pcaPrincipalAgreeToIsdaMarch2013DfProtocolMasterAgreement"
                        >
                    </div>

                    <app-regulatory-block-with-header blockHeader="Notice Information">
                        <!-- Name -->
                        <div class="control-box w-100">
                            <label for="name">Name</label>
                            <input id="name" formControlName="name">
                        </div>

                        <!-- Notice Address 1 -->
                        <div class="control-box w-100">
                            <label for="noticeAddress1">Notice Address 1</label>
                            <input id="noticeAddress1" formControlName="noticeAddress1">
                        </div>

                        <!-- Notice Address 2 -->
                        <div class="control-box w-100">
                            <label for="noticeAddress2">Notice Address 2</label>
                            <input id="noticeAddress2" formControlName="noticeAddress2">
                        </div>

                        <!-- Notice City -->
                        <div class="control-box w-100">
                            <label for="noticeCity">Notice City</label>
                            <input id="noticeCity" formControlName="noticeCity">
                        </div>

                        <!-- Notice State -->
                        <div class="control-box w-100">
                            <label for="noticeState">Notice State</label>
                            <input id="noticeState" formControlName="noticeState">
                        </div>

                        <!-- Notice Country -->
                        <div class="control-box w-100">
                            <label for="noticeCountry">Notice Country</label>
                            <input id="noticeCountry" formControlName="noticeCountry">
                        </div>

                        <!-- Notice Zip Code -->
                        <div class="control-box w-100">
                            <label for="noticeZipCode">Notice Zip Code</label>
                            <input id="noticeZipCode" formControlName="noticeZipCode">
                        </div>

                        <!-- Phone -->
                        <div class="control-box w-100">
                            <label for="phone">Phone</label>
                            <input id="phone" formControlName="phone">
                        </div>

                        <!-- Fax -->
                        <div class="control-box w-100">
                            <label for="fax">Fax</label>
                            <input id="fax" formControlName="fax">
                        </div>

                        <!-- Email -->
                        <div class="control-box w-100">
                            <label for="email">Email</label>
                            <input id="email" formControlName="email">
                        </div>

                        <!-- Electronic Messaging System Details -->
                        <div class="control-box w-100">
                            <label for="electronicMessagingSystemDetails">Electronic Messaging System Details</label>
                            <input id="electronicMessagingSystemDetails"
                                   formControlName="electronicMessagingSystemDetails">
                        </div>

                        <!-- Specific Instructions -->
                        <div class="control-box w-100">
                            <label for="specificInstructions">Specific Instructions</label>
                            <input id="specificInstructions" formControlName="specificInstructions">
                        </div>
                    </app-regulatory-block-with-header>
                </div>

                <!-- III Column -->
                <div class="d-flex flex-column data-column">
                    <!-- Status -->
                    <div class="control-box w-100">
                        <label for="status">Status</label>
                        <input id="status" formControlName="status">
                    </div>

                    <!-- Note -->
                    <div class="control-box w-100">
                        <label for="note">Note</label>
                        <input id="note" formControlName="note">
                    </div>

                    <!-- Top-up Protocols Block -->
                    <app-regulatory-block-with-header blockHeader="Top-up Protocols">
                        <!-- Dodd Frank Protocol 2.0 Adherence ID -->
                        <div class="control-box w-100">
                            <label for="doddFrankProtocol2AdherenceId">Dodd Frank Protocol 2.0 Adherence ID</label>
                            <input id="doddFrankProtocol2AdherenceId" formControlName="doddFrankProtocol2AdherenceId">
                        </div>

                        <!-- Dodd Frank Protocol 2.0 Match Date -->
                        <div class="control-box w-100">
                            <label for="doddFrankProtocol2MatchDate">Dodd Frank Protocol 2.0 Match Date</label>
                            <input id="doddFrankProtocol2MatchDate" formControlName="doddFrankProtocol2MatchDate">
                        </div>

                        <!-- Top Up Share Status -->
                        <div class="control-box w-100">
                            <label for="topUpShareStatus">Top Up Share Status</label>
                            <input id="topUpShareStatus" formControlName="topUpShareStatus">
                        </div>

                        <!-- Top Up Protocol Adherence ID -->
                        <div class="control-box w-100">
                            <label for="topUpProtocolAdherenceId">Top Up Protocol Adherence ID</label>
                            <input id="topUpProtocolAdherenceId" formControlName="topUpProtocolAdherenceId">
                        </div>

                        <!-- Protocol Name -->
                        <div class="control-box w-100">
                            <label for="protocolName">Protocol Name</label>
                            <input id="protocolName" formControlName="protocolName">
                        </div>

                        <!-- Accept Date -->
                        <div class="control-box w-100">
                            <label for="acceptDate">Accept Date</label>
                            <input
                                readonly
                                id="acceptDate"
                                [matDatepicker]="picker"
                                class="date-picker-padding"
                                formControlName="acceptDate"
                            >
                            <mat-datepicker-toggle [for]="picker" matIconSuffix class="custom-toggle"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>

                        <!-- Adherence Type -->
                        <div class="control-box w-100">
                            <label for="adherenceType">Adherence Type</label>
                            <input id="adherenceType" formControlName="adherenceType">
                        </div>

                        <!-- Covered SBS Entity -->
                        <div class="control-box w-100">
                            <label for="coveredSbsEntity">Covered SBS Entity</label>
                            <input id="coveredSbsEntity" formControlName="coveredSbsEntity">
                        </div>

                        <!-- Adherence Letter -->
                        <div class="control-box w-100">
                            <label for="adherenceLetter">Adherence Letter</label>
                            <input id="adherenceLetter" formControlName="adherenceLetter">
                        </div>
                    </app-regulatory-block-with-header>

                    <app-regulatory-block-with-header blockHeader="PCA Principal Agent Contact">
                        <!-- PCA Identification -->
                        <div class="control-box w-100">
                            <label for="pcaIdentification">PCA Identification</label>
                            <input id="pcaIdentification" formControlName="pcaIdentification">
                        </div>

                        <!-- Full Legal Name Of PCA Principal Or PCA Agent -->
                        <div class="control-box w-100">
                            <label for="fullLegalNameOfPcaPrincipalOrPcaAgent">
                                Full Legal Name Of PCA Principal Or PCA Agent
                            </label>
                            <input
                                id="fullLegalNameOfPcaPrincipalOrPcaAgent"
                                formControlName="fullLegalNameOfPcaPrincipalOrPcaAgent"
                            >
                        </div>

                        <!-- Individual Name -->
                        <div class="control-box w-100">
                            <label for="individualName">Individual Name</label>
                            <input id="individualName" formControlName="individualName">
                        </div>

                        <!-- Individual Title -->
                        <div class="control-box w-100">
                            <label for="individualTitle">Individual Title</label>
                            <input id="individualTitle" formControlName="individualTitle">
                        </div>

                        <!--                        &lt;!&ndash; Date &ndash;&gt;-->
                        <!--                        <div class="control-box w-100">-->
                        <!--                            <label for="date">Date</label>-->
                        <!--                            <input id="date" formControlName="date">-->
                        <!--                        </div>-->
                    </app-regulatory-block-with-header>
                </div>
            </form>

            <!-- Save Block -->
            <div class="d-flex w-100 justify-end">
                <div class="d-flex save-wrapper">
                    <div class="d-flex flex-col">
                <span class="last-updated">
                    <span class="updated-on-label">
                        Last Updated On:
                    </span>
                    <span class="updated-on-value">
                        {{ dataSource.DateLastUpdated | date }}
                    </span>
                </span>
                        <div class="updated-by">
                            by Wiam Lou
                        </div>
                    </div>

                    <button class="save-button" [disabled]="df2Form.pristine" (click)="updateDf2Data()">Save</button>
                </div>
            </div>
        </div>

    </ng-container>

    <ng-template #noDataBlock>
        <div class="d-flex justify-content-center">
            <no-data-component></no-data-component>
        </div>
    </ng-template>
</ng-template>
