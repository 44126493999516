import {
  AfterViewInit,
  Component, Output, ViewChild,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements AfterViewInit, OnChanges {
  @Input() currentDate: any = new Date();

  @ViewChild("datepicker", { static: false }) datepicker: any;

  @Output() public changeDateEvent = new EventEmitter();

  model: NgbDateStruct = {
    year: this.currentDate.getFullYear(),
    month: this.currentDate.getMonth() + 1,
    day: this.currentDate.getDate()
  };
  date: { year: number, month: number, day: number } = {
    year: this.currentDate.getFullYear(),
    month: this.currentDate.getMonth() + 1,
    day: this.currentDate.getDate()
  };
  selectedDate: { year: number, month: number, day: number } = {
    year: this.currentDate.getFullYear(),
    month: this.currentDate.getMonth() + 1,
    day: this.currentDate.getDate()
  }
  maxDate: { year: number, month: number, day: number } = {
    year: this.currentDate.getFullYear(),
    month: this.currentDate.getMonth() + 1,
    day: this.currentDate.getDate()
  };
  minDate: { year: number, month: number, day: number } = {
    year: this.currentDate.getFullYear(),
    month: 1,
    day: 1
  };

  months: { label: string, value: number }[] = [
    {label: 'January', value: 1},
    {label: 'February', value: 2},
    {label: 'March', value: 3},
    {label: 'April', value: 4},
    {label: 'May', value: 5},
    {label: 'June', value: 6},
    {label: 'July', value: 7},
    {label: 'August', value: 8},
    {label: 'September', value: 9} ,
    {label: 'October', value: 10},
    {label: 'November', value: 11},
    {label: 'December', value: 12}
  ];

  isNextMonthDisabled: boolean = true;
  isPreviousMonthDisabled: boolean = false;

  constructor(private calendar: NgbCalendar) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.model = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.date = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
  }

  ngAfterViewInit(): void {
    // this.datepicker.focusSelect({ ...this.date });
  }

  onDateSelect(event: NgbDate) {
    this.selectedDate = event;

    this.changeDateEvent.emit(`${ event.day.toString().padStart(2, "0") }-${ event.month.toString().padStart(2, "0") }-${event.year}`);
  }

  changeMonth(value: number) {
    if(this.date.month !== value) {
      this.date.month = value;

      this.datepicker.navigateTo({ ...this.date });
    }
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  handlePrevious() {
    if(this.date.month === 0) {
      this.date.month = 12;
    } else {
      this.date.month = this.date.month - 1;
    }

    this.isPreviousMonthDisabled = this.date.month === 1;
    this.isNextMonthDisabled = this.date.month === this.currentDate.getMonth() + 1;

    this.datepicker.navigateTo({ ...this.date });
  }

  handleNext() {
    if(this.date.month === 12) {
      this.date.month = 0;
    } else {
      this.date.month = this.date.month + 1;
    }

    this.isPreviousMonthDisabled = this.date.month === 1;
    this.isNextMonthDisabled = this.date.month === this.currentDate.getMonth() + 1;

    this.datepicker.navigateTo(this.date);
  }

}
