import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ConfirmationData {
  title!: string;
  message!: string;
  activeMessage!: string;
  showActive!: boolean;
  clientId!: string;
  nextStage!: string;
  showMessage!: boolean;
}
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css'],
})
export class ConfirmationDialog implements OnInit {
  showActive: boolean = false;
  showMessage: boolean = true;
  activate!: boolean;
  message: string = '';
  activeMessage: string = '';
  title: string = '';
  clientId: string = '';
  nextStage: string = '';

  constructor(
    public dialogo: MatDialogRef<ConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationData
  ) {
    this.title = data.title;
    this.message = data.message;
    this.activeMessage = data.activeMessage;
    this.showActive = data.showActive == null ? false : data.showActive;
    this.clientId = data.clientId;
    this.nextStage = data.nextStage;
    this.showMessage = data.showMessage == null ? true : data.showMessage;
  }

  closeDialog(): void {
    this.dialogo.close({ confirm: false, active: this.activate });
  }

  confirm(): void {
    this.dialogo.close({ confirm: true, active: this.activate });
  }

  ngOnInit(): void {}
}
