<div class="progress-bar-container">
    <div class="width-100" [ngClass]="selectedBucket.bucketName.toLowerCase()">
      <div class="progress-header d-flex">
        <h3>Not Started : {{ selectedBucket.notStarted.value }} </h3>
        <div class="d-flex align-center">
          <div class="line-20"></div>
          <span>{{ selectedBucket.notStarted.percent }}%</span>
        </div>
      </div>
      <mat-progress-bar mode="determinate" [value]="selectedBucket.notStarted.percent"></mat-progress-bar>
    </div>
    <div class="width-100" [ngClass]="selectedBucket.bucketName.toLowerCase()">
      <div class="progress-header d-flex">
        <h3>In Progress : {{ selectedBucket.inProgress.value }} </h3>
        <div class="d-flex align-center">
          <div class="line-20"></div>
          <span>{{ selectedBucket.inProgress.percent }}%</span>
        </div>
      </div>
      <mat-progress-bar mode="determinate" [value]="selectedBucket.inProgress.percent"></mat-progress-bar>
    </div>
    <div class="width-100" [ngClass]="selectedBucket.bucketName.toLowerCase()">
      <div class="progress-header d-flex">
        <h3>Completed : {{ selectedBucket.complete.value }}</h3>
        <div class="d-flex align-center">
          <div class="line-20"></div>
          <span>{{ selectedBucket.complete.percent }}%</span>
        </div>
      </div>
      <mat-progress-bar mode="determinate" [value]="selectedBucket.complete.percent"></mat-progress-bar>
    </div>
  </div>