<div class="dialog-wrapper">
  <div class="header d-flex">Action Required</div>

  <div class="d-flex justify-space-between dialog-content">
    Oops! It looks like there's an issue
    <br />
    <br />

    <ng-container>
      <!-- Non Compliant Product Validation -->
      <ng-container>
        <span>
          To proceed, Not-Compliant
          {{ data.displayData.length > 1 ? "Products" : "Product" }}
          <span style="font-family: Poppins-Bold; color: #41026a"
            >#{{ ids }}</span
          >
          must have the following conditions met:
        </span>
        <br />
        <ul>
          <li>
            <span style="font-family: Poppins-SemiBold; color: #41026a">
              Status:
            </span>
            Pending Freezing or Pending Closure
          </li>
          <li>
            <span style="font-family: Poppins-SemiBold; color: #41026a">
              Compliance:
            </span>
            Not-Compliant
          </li>
        </ul></ng-container
      >
    </ng-container>
  </div>

  <div class="action-footer">
    <mat-spinner
      [diameter]="35"
      *ngIf="loading"
      style="margin-right: 1rem"
    ></mat-spinner>

    <button mat-button class="action-base submit" [mat-dialog-close]="true">
      Close
    </button>
  </div>
</div>
