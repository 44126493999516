import {Helper} from "../../../../../helpers/helper";
import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {StructuresService} from "../../../../../services/structures.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {DeleteConfirmationPopupComponent} from "../delete-confirmation-popup/delete-confirmation-popup.component";
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

export class StructureItemModel {
    ChildStructure: StructureItem[] = [];
    EntityType: string = '';
    ClientName: string = '';
    ClientPercentage: string = '';
    ClientType: string = '';
    Id: number = 0;
    IsVisible: boolean = true;
    ParentId: number = 0;
}

interface StructureItem {
    ChildStructure: StructureItem[],
    EntityType: string,
    ClientName: string,
    ClientPercentage: string,
    ClientType: string,
    Id: number,
    IsVisible: boolean,
    ParentId: number,
}

@Component({
    selector: 'app-custom-node',
    templateUrl: './custom-node.component.html',
    styleUrls: ['./custom-node.component.scss']
})
export class CustomNodeComponent implements OnInit {
    public isLoading: boolean = false;
    public selectedStructureItem: StructureItem | null = null;
    public isBlockHighlighted: boolean = true;
    public newStructureId: number = 0;
    @Input() parentStructure: StructureItem = new StructureItemModel();
    private updateSubject = new Subject<StructureItem>();


    constructor(
        private helper: Helper,
        private el: ElementRef,
        private dialog: MatDialog,
        private renderer: Renderer2,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private structuresService: StructuresService) {
        this.updateSubject.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((newStructure) => {
            this.helper.selectNewStructureItem(newStructure);
        });
    }

    public get clientId(): number {
        return this.helper.getQuestionnaireClientId();
    }

    ngOnInit(): void {
        this.helper.onNewStructureSelected().subscribe((selectedStructure) => {
            this.selectedStructureItem = selectedStructure;
        });

        this.helper.onNewItemGenerated().subscribe(newStructure => {
            this.isBlockHighlighted = true;
            this.newStructureId = newStructure.Id;

            let newBlockElement: HTMLElement | null;

            const interval = setInterval(() => {
                newBlockElement = document.getElementById(`${newStructure.Id}`);

                if (newBlockElement) {
                    clearInterval(interval);
                    newBlockElement.scrollIntoView({
                        behavior: "smooth",
                        block: 'center',
                        inline: 'center'
                    });
                    this.renderer.setStyle(newBlockElement, 'box-shadow', '0 0 20px 0px #6c5dd3');
                    this.renderer.setStyle(newBlockElement, 'transition-duration', '.5s');
                    this.helper.selectNewStructureItem(newStructure);

                    setTimeout(() => {
                        this.renderer.setStyle(newBlockElement, 'box-shadow', 'none');
                    }, 3000);
                }
            }, 500);
        });
    }

    public handleAddNewStructureItem(): void {
        this.selectedStructureItem = null;
        this.helper.changeRightTabState(false);

        const structuresContainer = document.querySelector('.mat-tab-group.mat-primary');
        if (structuresContainer) structuresContainer.scrollIntoView({behavior: "smooth"});

        this.helper.setNewSelectedParentId(this.parentStructure.Id);

        this.helper.onNewStructureItemCreated().subscribe((newStructure) => {
            if (newStructure)
                this.helper.passNewStructureForCreation({
                    ParentId: this.parentStructure.Id,
                    ClientId: +this.clientId,
                    ClientName: newStructure.EntityName || null,
                    FirstName: newStructure.FirstName || null,
                    MiddleName: newStructure.MiddleName || null,
                    LastName: newStructure.LastName || null,
                    EntityType: newStructure.EntityName ? 'Entity' : 'Individual',
                    IsVisible: newStructure.IsVisible,
                    RestoreParentId: newStructure.ParentId
                });
        });

        this.helper.updateAddNewStructurePanel(true);
    }

    protected readonly parent = parent;

    public previewStructureItem(structureItem: StructureItem): void {
        if (this.selectedStructureItem && this.selectedStructureItem.Id === structureItem.Id) {
            this.helper.selectNewStructureItem(null);
            this.helper.changeRightTabState(false);
        } else {
            this.helper.selectNewStructureItem(structureItem);

            // closing add new structure panel as well as structure search results
            this.helper.updateSearchResultsPanel(false, null);
            this.helper.updateAddNewStructurePanel(false);

            // adding slight timeout to wait for the animation end of closing the above blocks
            setTimeout(() => {
                this.helper.changeRightTabState(true);
            }, 100);
        }
    }

    public removeStructureAndChildren(structureItem: StructureItem): void {
        this.isLoading = true;

        const dialogRef = this.dialog.open(DeleteConfirmationPopupComponent, {
            width: '390px',
            data: {structureName: structureItem.ClientName},
        });

        dialogRef.afterClosed().subscribe((shouldDelete) => {
            if (shouldDelete) {
                this.structuresService.removeStructureAndChildren(this.clientId, structureItem.Id).subscribe(data => {
                    this.helper.refreshStructuresData();
                    this.isLoading = false;

                    this.snackBar.open('Structure item successfully removed!', 'x', {
                        panelClass: ['success-snackbar'],
                        duration: 3000,
                    });
                });
            } else this.isLoading = false;
        });
    }

    public checkIfVisible(parent: StructureItem, child: StructureItem[]): boolean {

        if (!(parent.IsVisible && parent.ChildStructure[child.length - 1].IsVisible)) {
            return true;
        }

        return false
    }
}
