<button
  [matMenuTriggerFor]="menu"
  (onMenuOpen)="onMenuOpen()"
  (onMenuClose)="onMenuClose()"
  #menuTrigger="matMenuTrigger"
  class="column-header-menu-btn"
>
  <div
    [ngClass]="{ 'rotate-arrow': menuTrigger.menuOpen }"
    class="column-header-menu-icon"
    [class.column-header-access-mngmnt]="isUserAccessDataTable"
  ></div>
</button>

<mat-menu style="overflow-y: hidden" #menu="matMenu" class="sorting-sort-block">
  <!-- A to z sorting -->
  <button mat-menu-item class="sort-btn" (click)="handleSortingChange('asc')">
    <ng-container [ngSwitch]="isUserAccessDataTable">
      <ng-container *ngSwitchCase="true">
        <div class="d-flex sort-item">
          <img
            src="./../assets/icons/sort-arrow-down.svg"
            style="width: 1rem"
            alt="sort-arrow-down"
          />
          <span style="line-height: 24px">Sort Ascending</span>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="false">
        <div
          class="d-flex sort-item font-color-purple"
          style="justify-content: space-between"
        >
          <span style="line-height: 24px">Sort Ascending</span>
          <img src="./../assets/icons/AtoZsort.svg" alt="sort-arrow-down" />
        </div>
      </ng-container>
    </ng-container>
  </button>

  <!-- z to A sorting -->
  <button mat-menu-item class="sort-btn" (click)="handleSortingChange('desc')">
    <ng-container [ngSwitch]="isUserAccessDataTable">
      <ng-container *ngSwitchCase="true">
        <div
          class="d-flex sort-item"
          [class.font-color-purple]="!isUserAccessDataTable"
        >
          <img
            src="./../assets/icons/sort-arrow-up.svg"
            style="width: 1rem"
            alt="sort-arrow-down"
          />
          <span style="line-height: 24px">Sort Descending</span>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <div
          class="d-flex sort-item font-color-purple"
          style="justify-content: space-between"
        >
          <span style="line-height: 24px">Sort Descending</span>
          <img src="./../assets/icons/ZtoAsort.svg" alt="sort-arrow-down" />
        </div>
      </ng-container>
    </ng-container>
  </button>

  <!-- Custom filtering -->
  <button
    mat-menu-item
    class="open-filters"
    (click)="clickOnFilterByHandler($event)"
  >
    <ng-container [ngSwitch]="isUserAccessDataTable">
      <ng-container *ngSwitchCase="true">
        <div
          class="d-flex sort-item"
          [class.font-color-purple]="!isUserAccessDataTable"
        >
          <img src="./../assets/icons/filter-by.svg" alt="" />
          <span style="line-height: 24px">Filter by</span>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="false">
        <div
          class="d-flex sort-item font-color-purple"
          style="justify-content: space-between"
        >
          <span style="line-height: 24px">Filter by</span>
          <img
            src="./../assets/icons/filter-by-purple.svg"
            alt="sort-arrow-down"
          />
        </div>
      </ng-container>
    </ng-container>
  </button>

  <div class="expandable-content" [ngClass]="{ expand: isExpanded }">
    <ng-container *ngIf="isFilterOptionsLoading">
      <div class="spinner-box">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
    </ng-container>

    <ng-container *ngIf="!isFilterOptionsLoading">
      <!-- filter search -->
      <div class="filter-search-box">
        <span innerText="Search by name"></span>
        <input
          tabindex="0"
          #searchInput
          id="searchInput"
          class="filter-search"
          [(ngModel)]="searchText"
          placeholder="Search"
          (click)="$event.stopPropagation()"
        />
      </div>
      <div class="content-wrapper" (click)="$event.stopPropagation()">
        <!-- main content -->
        <ng-container
          *ngIf="columnValuesAdjusted | search : searchText as result"
        >
          <ng-container *ngFor="let filter of result">
            <div class="checkbox-wrapper">
              <mat-checkbox
                class="checkbox-filter"
                [name]="filter.column"
                [checked]="filter.checked"
                (change)="onCheckboxChange($event)"
                labelPosition="before"
              >
                <ng-container [ngSwitch]="column">
                  <ng-container *ngSwitchCase="'ClientId'">
                    <ng-container [ngSwitch]="filter.column">
                      <ng-container *ngSwitchCase="'(Unselect All)'">
                        <span class="filter-label">{{ filter.column }}</span>
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        <span class="filter-label">
                          #INV-{{ filter.column }}
                        </span>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    <div class="filter-label">
                      <div class="d-flex tree-wrapper">
                        <div class="tree"></div>
                        <div class="tree-label">
                          {{ filter.column }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </mat-checkbox>
            </div>
          </ng-container>

          <!-- No results found -->
          <ng-container *ngIf="result.length === 0">
            <div class="no-results">No matching items</div>
          </ng-container>
        </ng-container>
      </div>

      <!-- action section -->
      <div class="d-flex filter-action-section">
        <button class="reset-button" (click)="resetFilters()">Reset</button>
        <button class="apply-button" (click)="applyFilters()">Apply</button>
      </div>
    </ng-container>
  </div>
</mat-menu>
