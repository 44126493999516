import { OnInit, Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StatisticService } from 'src/app/services/statistic.service';
import { AnalyticDataSource } from 'src/app/services/analytic.datasource';
import { NewExcelEntrySelectComponent } from '../new-entry-excel/new-entry-excel.component';
import { ActionAssignDialog } from '../assign-action-dialog/action-assign-dialog.component';
import { DescopeAssignDialog } from '../descope-action-dialog/descope-action-dialog.component';
import { AnalyticModel, AnalyticRequestModel } from 'src/app/models/analytic';
import { Helper } from 'src/app/helpers/helper';
import { NotificationService } from 'src/app/services/notification.service';
import {
  NotificationRequestModel,
  NotificationTypeEnum,
} from 'src/app/models/websocket';
import { DatePipe } from '@angular/common';
import { RescopeDialog } from '../common/confirmation-rescope-dialog/confirmation-dialog.component';
import { NotAllItemsProcessedDialog } from '../common/not-all-items-processed-dialog/confirmation-dialog.component';
import { MessageDialog } from '../common/message-dialog/message-dialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  public noDataAvailable: boolean = false;
  @ViewChild('dtTable') dataTable: any;
  public dialogRef: any;
  public assignAnalystId: number = 0;
  public dataSource: AnalyticDataSource = new AnalyticDataSource(
    this.statisticService
  );

  public descopeRowSelected: boolean = false;
  public rescopeRowSelected: boolean = false;
  public isNudgeAllowed: boolean = false;
  public isAssignAllowed: boolean = false;

  busy: boolean = true;
  public isFirstUpload: boolean = false;

  selectedCases: AnalyticModel[] = [];

  public displayedColumns = [
    'NudgeIdentifier',
    'CheckBox',
    'ClientId',
    'ClientName',
    'ParentGrouping',
    'DueDateStr',
    'CountryOfIncorporation',
    'Revenue',
    'Bucket',
    'ClientType',
    'Analyst',
    'QCReviewer',
    'ClientContactTitle',
    'ClientContactEmail',
    'ClientContactName',
    'CompletionDateStr',
    'InitialOutreachDateStr',
    'ReasonForDescope',
    'Products',
    'RiskScore',
    'Status',
    'Stage',
    'RowMenu',
  ];

  public allColumns = [
    'ClientId',
    'ClientName',
    'ParentGrouping',
    'DueDateStr',
    'CountryOfIncorporation',
    'Status',
    'Revenue',
    'RiskScore',
    'Bucket',
    'ClientType',
    'Analyst',
    'QCReviewer',
    'ClientContactTitle',
    'ClientContactEmail',
    'ClientContactName',
    'CompletionDateStr',
    'InitialOutreachDateStr',
    'ReasonForDescope',
    'Products',
    'Stage',
    'RowMenu',
  ];

  public userAcceses: {
    addNewEntry: boolean,
    caseActions: {
      allowedAll: boolean,
      disabledAll: boolean,
      assign: boolean,
      nudge: boolean,
      descope: boolean,
      rescope: boolean
    }
  } = {
    addNewEntry: false,
    caseActions: {
      disabledAll: true,
      allowedAll: false,
      assign: false,
      nudge: false,
      descope: false,
      rescope: false
    }
  }

  constructor(
    public dialog: MatDialog,
    public statisticService: StatisticService,
    private helper: Helper,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private changeDetectorRefs: ChangeDetectorRef
  ) {
    this.userAcceses = this.getUserAccesses();
  }

  ngOnInit(): void {
    this.isFirstUpload = this.helper.isDataFirstUpload();
  }

  public getUserAccesses(): any {
    const userRoles = this.helper.getUser().Role.split(', ');

    // Descope and rescope always will be true, because if user have only role compliance he won't be able to see actions button. 
    // For any other roles it's allowed
    return {
      addNewEntry: userRoles.some((role: string) => role === "Administrator" || role === "Manager" || role === "Backup Manager"),
      caseActions: {
        disabledAll: userRoles[0] === "Compliance",
        allowedAll: userRoles.some((role: string) => role === "Administrator" || role === "Manager"),
        assign: userRoles.some((role: string) => role === "Administrator" || role === "Manager" || role === "Backup Manager"),
        nudge: userRoles.some((role: string) => role === "Administrator" || role === "Manager" || role === "Backup Manager"),
        descope: true,
        rescope: true
      }
    };
  }

  public openExcelDialog(): void {
    this.dialog.open(NewExcelEntrySelectComponent, {
      panelClass: 'excel-entry-dialog',
    });
  }

  public openAssignDialog(): void {

    if (this.selectedCases.some((el: any) => el.Stage === 'Descoped' || el.Stage === 'Complete')) {
      const casesToAssign = this.selectedCases.filter(
        (item: any) =>
          item.Stage !== 'Descoped' &&
          item.Stage !== 'Complete'
      );

      const wrongCases = this.selectedCases
        .map((item: any) =>
          item.Stage === 'Descoped' || item.Stage === 'Complete'
            ? ' Case: ' + item.CaseId + ' Client: ' + item.ClientName
            : null
        )
        .filter(Boolean);

      if (wrongCases.length) {
        this.dialogRef = this.dialog.open(MessageDialog, {
          data: {
            title: "Some cases can't reassigned.",
            additionalHTML: `
              <div class="d-flex flex-column">
                ${ wrongCases.length 
                  ? `<div class="m-y-10"> Next cases can't be reassigned from their current stage: ${wrongCases} </div>`
                  : ''
                }
              </div>
            `
          },
        });

        this.dialogRef.afterClosed().subscribe(() => {
          this.dialogRef = this.dialog.open(ActionAssignDialog);

          this.dialogRef.afterClosed().subscribe((res: number) => {
            if (res) {
              this.busy = true;
              this.assignAnalystId = res;
              casesToAssign.forEach((item) => {
                item.AnalystId = this.assignAnalystId;
                item.Analyst = item.Analyst ?? '';
                item.QCReviewer = item.QCReviewer ?? '';
              });
              var requestData = new AnalyticRequestModel();
              requestData.UserId = this.helper.getUserId();
              requestData.Data = this.selectedCases;
      
              this.statisticService
                .updateAnalytic({
                  Data: this.selectedCases,
                  UserId: this.helper.getUserId(),
                })
                .subscribe((res: any) => {
                  this.dataTable.loadAnalyticPage(
                    this.dataTable.sort?.active ?? 'Id',
                    this.dataTable.sort?.direction ?? 'desc'
                  );
                  this.helper.callLeftMenuChanged();
                  this.busy = false;
                  this.helper.setSearchFilter('');
                });
            }
            this.dialogRef = null;
          });
          
        });
      }
    } else {
      this.dialogRef = this.dialog.open(ActionAssignDialog);

      this.dialogRef.afterClosed().subscribe((res: number) => {
        if (res) {
          this.busy = true;
          this.assignAnalystId = res;
          this.selectedCases.forEach((item) => {
            item.AnalystId = this.assignAnalystId;
            item.Stage = item.Stage !== 'Not Started' ? item.Stage : 'Not Started';
            item.Analyst = item.Analyst ?? '';
            item.QCReviewer = item.QCReviewer ?? '';
          });
          var requestData = new AnalyticRequestModel();
          requestData.UserId = this.helper.getUserId();
          requestData.Data = this.selectedCases;
  
          this.statisticService
            .updateAnalytic({
              Data: this.selectedCases,
              UserId: this.helper.getUserId(),
            })
            .subscribe((res: any) => {
              this.dataTable.loadAnalyticPage(
                this.dataTable.sort?.active ?? 'Id',
                this.dataTable.sort?.direction ?? 'desc'
              );
              this.helper.callLeftMenuChanged();
              this.busy = false;
              this.helper.setSearchFilter('');
            });
        }
        this.dialogRef = null;
      });
    }
  }

  public openAssignDescope(event: any): void {
    // if there are any items that have been already descoped, showing extra dialog with the info
    if (this.selectedCases.some((el: any) => el.Stage === 'Descoped')) {
      this.dialogRef = this.dialog.open(NotAllItemsProcessedDialog, {
        data: {
          selected: this.selectedCases,
          isDescopeDialog: true,
          isRescopeDialog: false,
        },
      });

      this.dialogRef.afterClosed().subscribe((res: any) => {
        this.dialogRef = this.dialog.open(DescopeAssignDialog, {
          data: this.selectedCases,
        });

        this.dialogRef.afterClosed().subscribe((res: any) => {
          if (res.reasonForDescope) {
            this.dataTable.handleDescope(res.reasonForDescope);
          }
          if (res.data) {
            this.dataTable.handleSelection(res.data);
          }
        });
      });
    } else {
      this.dialogRef = this.dialog.open(DescopeAssignDialog, {
        data: this.selectedCases,
      });

      this.dialogRef.afterClosed().subscribe((res: any) => {
        if (res.reasonForDescope) {
          this.dataTable.handleDescope(res.reasonForDescope);
        }
        if (res.data) {
          this.dataTable.handleSelection(res.data);
        }
      });
    }
  }

  public handleRescope(): void {
    if (this.selectedCases.some((el: any) => el.Stage !== 'Descoped')) {
      this.dialogRef = this.dialog.open(NotAllItemsProcessedDialog, {
        data: {
          selected: this.selectedCases,
          isDescopeDialog: false,
          isRescopeDialog: true,
        },
      });

      this.dialogRef.afterClosed().subscribe((res: any) => {
        this.dialogRef = this.dialog.open(RescopeDialog, {
          data: {
            data: this.selectedCases,
          },
        });

        this.dialogRef.afterClosed().subscribe((res: any) => {
          this.dataTable.handleRescope();
        });
      });
    } else {
      this.dialogRef = this.dialog.open(RescopeDialog, {
        data: {
          data: this.selectedCases,
        },
      });

      this.dialogRef.afterClosed().subscribe((res: any) => {
        this.dataTable.handleRescope();
      });
    }
  }

  selectChanged(event: any) {
    this.selectedCases = event;

    this.descopeRowSelected = !!event.filter(
      (row: AnalyticModel) => row.Stage !== 'Descoped'
    ).length;

    this.rescopeRowSelected = event.some(
      (row: AnalyticModel) => row.Stage === 'Descoped'
    );

    this.isNudgeAllowed = event.some(
      (row: AnalyticModel) => {
        return !row.IsNudge && row.Stage !== 'Not Started' && row.Stage !== 'Complete' && row.Stage !== 'Descoped';
      }
    );

    this.isAssignAllowed = event.some(
      (row: AnalyticModel) => {
        return row.Stage !== 'Descoped' && row.Stage !== 'Complete';
      }
    );

  }

  nudge() {
    if (
      this.selectedCases.some(
        (el: any) =>
          el.Stage === 'Not Started' || el.Stage === 'Complete' || el.IsNudge
      )
    ) {
      const casesToNudge = this.selectedCases.filter(
        (item: any) =>
          !item.IsNudge &&
          item.Stage !== 'Not Started' &&
          item.Stage !== 'Complete'
      );
      const nudgedCases = this.selectedCases
        .map((item: any) =>
          item.IsNudge
            ? ' Case: ' + item.CaseId + ' Client: ' + item.ClientName
            : null
        )
        .filter(Boolean);
      const wrongCases = this.selectedCases
        .map((item: any) =>
          item.Stage === 'Not Started' || item.Stage === 'Complete'
            ? ' Case: ' + item.CaseId + ' Client: ' + item.ClientName
            : null
        )
        .filter(Boolean);

      if (nudgedCases.length || wrongCases.length) {
        this.dialogRef = this.dialog.open(MessageDialog, {
          data: {
            title: "Some cases can't nudged.",
            additionalHTML: `
              <div class="d-flex flex-column">
                ${ nudgedCases.length 
                  ? `<div class="m-b-20"> Next cases already nudged: ${nudgedCases}</div>`
                  : ''
                }
                ${ wrongCases.length 
                  ? `<div class="m-y-10"> Next cases can't be nudged from their current stage: ${wrongCases} </div>`
                  : ''
                }
              </div>
            `
          },
        });

        this.dialogRef.afterClosed().subscribe(() => {
          if (casesToNudge.length) {
            var request = new NotificationRequestModel();
            request.UserId = this.helper.getUserId();
            request.Message = 'Nudge';
            request.Data = casesToNudge;
            request.SaveToDb = false;
            request.Type = NotificationTypeEnum.Nudge;
            this.notificationService
              .postNotification(request)
              .subscribe((res: any) => {
                this.helper.callNudgeRecieved(
                  request.Data.map((item) => item.CaseId)
                );
                if (res.Success) {
                }
              });
          }
        });
      }
    } else {
      var request = new NotificationRequestModel();
      request.UserId = this.helper.getUserId();
      request.Message = 'Nudge';
      request.Data = this.selectedCases;
      request.SaveToDb = false;
      request.Type = NotificationTypeEnum.Nudge;
      this.notificationService
        .postNotification(request)
        .subscribe((res: any) => {
          this.helper.callNudgeRecieved(
            request.Data.map((item) => item.CaseId)
          );
          if (res.Success) {
          }
        });
    }
    
  }

  loadingChange(event: boolean) {
    if (this.helper.isDataFirstUpload() && !event) {
      this.helper.setIsDataFirstUpload(false);
    }
    this.isFirstUpload = this.helper.isDataFirstUpload();
    this.busy = event;
  }

  changeDisplayedColumns(chips: any) {
    this.displayedColumns = chips;
  }

  handleNoTableData(event: boolean) {
    this.noDataAvailable = event;
  }
}
