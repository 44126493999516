import {Component, Input, OnInit} from '@angular/core';
import {Helper} from "../../../../../helpers/helper";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DocuLibraryService} from "../../../../../services/docu-library.service";

@Component({
  selector: 'app-avatar-uploader',
  templateUrl: './avatar-uploader.component.html',
  styleUrls: ['./avatar-uploader.component.scss']
})
export class AvatarUploaderComponent implements OnInit {
  selectedFile: File | null = null;
  formData: FormData = new FormData();

  @Input() avatarUrl: string = '';
  @Input() isLoading: boolean = false;

  get clientId(): string {
    return  this.helper.getQuestionnaireClientId();
  }

  constructor(
    private helper: Helper,
    private snackBar: MatSnackBar,
    private docuLibService: DocuLibraryService,
  ) {}

  ngOnInit(): void {}

  onFileSelected(event: Event) {
    this.isLoading = true;

    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      this.selectedFile = inputElement.files[0];
      this.formData.append('avatar', this.selectedFile);

      this.docuLibService.uploadClientAvatar({ clientId: this.clientId, avatar: this.formData }).subscribe(res => {
        const url = res.Data;
        if (url !== '-') this.avatarUrl = url;

        this.docuLibService.saveClientAvatar({ clientId: this.clientId, avatarUrl:  url }).subscribe((res) => {
          this.isLoading = false;
          this.snackBar.open('Avatar successfully changed!', 'x', {
            panelClass: ['success-snackbar'],
            duration: 3000,
          });
        })
      });
    }
  }
}
