<div class="loader-container" *ngIf="isLoading; else dataBlock">
  <mat-spinner [diameter]="70"></mat-spinner>
</div>

<ng-template #dataBlock>

  <div class="container-page">
    <div class="collapsible-table">
      <div class="table-wrapper">
        <!-- Audit Log Button -->
        <div class="d-flex justify-content-end audit-log-wrapper">
          <button class="audit-log-btn" (click)="handleOpenAuditLog()" mat-button>
            Audit Log
          </button>
          <button
            mat-button
            class="audit-log-btn add-btn"
            (click)="addNewAccount()"
          >
            + Add
          </button>
        </div>
        <ng-container *ngIf="dataSource.length > 0; else noDataBlock">
          <!-- Data Table -->
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- Account Number -->
            <ng-container matColumnDef="accountNumber">
              <th mat-header-cell *matHeaderCellDef class="mat-column-id">
                <div class="d-flex t-head-styled">
                  <app-active-sorting-indicator
                    customStyling="account-num"
                    *ngIf="isSortingActive('Account Number')"
                  >
                  </app-active-sorting-indicator>
                  Account Number
                  <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting('Account Number')"
                    [isActive]="isSortingActive('Account Number')">
                  </app-sort-indicator>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="mat-column-id">
                <div class="d-flex align-center h-100">
                  <div
                    class="t-body-styled id-section"
                    [style.color]="'#7D8187'"
                    matTooltipPosition="above"
                  >
                    {{ element.AccountNumber }}
                  </div>
                </div>
              </td>
            </ng-container>

            <!-- Account Name -->
            <ng-container matColumnDef="accountName">
              <th mat-header-cell *matHeaderCellDef [style.color]="'#6E6893'">
                <div class="d-flex t-head-styled">
                  <app-active-sorting-indicator
                    customStyling="docu-lib-accounts"
                    *ngIf="isSortingActive('Account Name')">
                  </app-active-sorting-indicator>
                  Account Name
                  <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting('Account Name')"
                    [isActive]="isSortingActive('Account Name')">
                  </app-sort-indicator>
                </div>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="t-body-styled account-number">
                  {{ element.AccountName }}
                </div>
              </td>
            </ng-container>

            <!-- Account Type -->
            <ng-container matColumnDef="accountType">
              <th mat-header-cell *matHeaderCellDef>
                <div class="d-flex t-head-styled">
                  <app-active-sorting-indicator
                    customStyling="docu-lib-accounts"
                    *ngIf="isSortingActive('Account Type')">
                  </app-active-sorting-indicator>
                  Account Type
                  <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting('Account Type')"
                    [isActive]="isSortingActive('Account Type')">
                  </app-sort-indicator>
                </div>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="t-body-styled legal-name-styled">
                  {{ element.AccountType }}
                </div>
              </td>
            </ng-container>

            <!-- Account Status -->
            <ng-container matColumnDef="accountStatus">
              <th mat-header-cell *matHeaderCellDef>
                <div class="d-flex t-head-styled">
                  <app-active-sorting-indicator
                    customStyling="docu-lib-accounts"
                    *ngIf="isSortingActive('Account Status')">
                  </app-active-sorting-indicator>
                  Account Status
                  <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting('Account Status')"
                    [isActive]="isSortingActive('Account Status')">
                  </app-sort-indicator>
                </div>
              </th>
              <td mat-cell *matCellDef="let element">
                <div
                  class="t-body-styled"
                  [class]="'t-status-base' + ' ' + element.AccountStatus"
                >
                  {{ toUpperCase(element.AccountStatus) }}
                </div>
              </td>
            </ng-container>

            <!-- Balance -->
            <ng-container matColumnDef="balance">
              <th mat-header-cell *matHeaderCellDef class="mat-column-status">
                <div class="d-flex t-head-styled">
                  <app-active-sorting-indicator
                    customStyling="docu-lib-accounts"
                    *ngIf="isSortingActive('Balance')">
                  </app-active-sorting-indicator>
                  Balance
                  <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting('Balance')"
                    [isActive]="isSortingActive('Balance')">
                  </app-sort-indicator>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="mat-column-status">
                <div class="t-body-styled">
                  {{ formatBalance(element.Balance) }}
                </div>
              </td>
            </ng-container>

            <!-- Date -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef class="mat-column-status">
                <div class="d-flex t-head-styled">
                  <app-active-sorting-indicator
                    customStyling="docu-lib-accounts"
                    *ngIf="isSortingActive('Date')">
                  </app-active-sorting-indicator>
                  Date
                  <app-sort-indicator
                    [sortDirection]="sortingDirection"
                    (click)="handleSorting('Date')"
                    [isActive]="isSortingActive('Date')">
                  </app-sort-indicator>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="mat-column-status">
                <div class="t-body-styled">
                  {{ element.DateAccountOpened | date:'dd MMM yyyy' }}
                </div>
              </td>
            </ng-container>

            <!-- Edit -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef class="mat-column-edit"></th>
              <td mat-cell *matCellDef="let element" class="mat-column-edit">
                <div class="t-body-styled">
                  <img src="../../../../assets/icons/edit-btn.svg" alt="edit" class="cursor-pointer"
                       (click)="handleEditAccount(element)"/>
                </div>
              </td>
            </ng-container>

            <!-- Remove -->
            <ng-container matColumnDef="remove">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="mat-column-remove"
              ></th>
              <td mat-cell *matCellDef="let element" class="mat-column-remove">
                <div class="t-body-styled remove-styled">
                  <img
                    src="../../../../../assets/icons/remove-docu-lib-account.svg"
                    alt="remove"
                    class="cursor-pointer"
                    (click)="handleDeleteAccount(element)"
                  />
                </div>
              </td>
            </ng-container>

            <tr
              mat-header-row
              class="sticky-header"
              *matHeaderRowDef="displayedColumns"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns; let i = index"
            ></tr>
          </table>
        </ng-container>
      </div>
    </div>

    <div
      class="collapsible-info-section"
      [class.flex-grow-custom]="isPreviewVisible"
      [class.flex-grow-custom-0]="!isPreviewVisible"
      [ngStyle]="{ 'margin-left': isPreviewVisible ? '1.5rem' : '0' }"
    >
      <div *ngIf="isPreviewVisible">
        <div
          #productInfo
          class="product-info container-overflow"
          style="overflow-y: auto; width: 100%"
        >
          <product-info></product-info>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noDataBlock>
    <div class="d-flex justify-content-center">
      <no-data-component></no-data-component>
    </div>
  </ng-template>
</ng-template>
