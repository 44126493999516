import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { AnalyticTableItem } from 'src/app/models/analytic';

@Component({
  selector: 'nested-data-table',
  templateUrl: './nested-data-table.component.html',
  styleUrls: ['./nested-data-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class NestedDataTable implements OnInit, OnChanges {
  @Input() status: string = '';
  @Input() opened: boolean = false;
  @Input() data: AnalyticTableItem[] = [];
  @Input() selectedAnalysts: any[] = [];
  dataSource: AnalyticTableItem[] = [];
  columnsToDisplay = ['Analyst', 'Total', 'Daily', 'Monthly', 'AverageAging'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay];
  expandedElement!: AnalyticTableItem | null;

  constructor() {}

  ngOnInit(): void {
    this.dataSource = this.data.filter(
      (c) =>
        c.CaseStatus == this.status &&
        (this.selectedAnalysts.length
          ? this.selectedAnalysts.some((analyst) => analyst.label === c.Analyst)
          : c.Analyst != null)
    );
  }

  ngOnChanges(changes: any): void {
    this.dataSource = this.data.filter(
      (c) =>
        c.CaseStatus == this.status &&
        (this.selectedAnalysts.length
          ? this.selectedAnalysts.some((analyst) => analyst.label === c.Analyst)
          : c.Analyst != null)
    );
  }
}
