export interface QuestionnaireDataList {
  CaseId: number;
  LEI: string | null;
  ClientHasAssetManagerInvestAdvisor: string | null;
  ClientId: number;
  ClientLegalChange: boolean;
  ClientLegalName: string;
  ClientName: string;
  CountryOfIncorporation: string | null;
  CountryOfIncorporationId: string | null;
  CountryOfOperationKnown: boolean;
  DoesClientMoreThan75MillionCad: boolean;
  EntitySubType: string | null;
  EntitySubTypeId: string | null;
  EntityType: string | null;
  EntityTypeId: string | null;
  HighRiskBusiness: string | null;
  HighRiskBusinessId: string | null;

  InvestmentRevenueBreakdownSourceOfFunds: number;

  HowMuchAssetsUnderManagement: string | null;
  HowMuchAssetsUnderManagementId: string | null;
  Id: number;
  InvestmentBreakdown: any;
  IsAssetManagerAiRegulated: boolean;
  IsAssetManagerAlListed: boolean;
  IsClientIncorporated: boolean;
  IsClientListed: boolean;
  IsClientRegulated: boolean;
  IsOwnershipKnown: string | null;
  IsOwnershipKnownId: string | null;
  IsParentRegulated: boolean;
  LegalNameAssetManagerInvestAdvisor: string | null;
  ListedRegulatedGovernmentOwnership: string | null;
  ListedRegulatedGovernmentOwnershipId: string | null;
  NameOfExchange: string | null;
  NameOfExchangeId: string | null;
  NameOfParentRegulator: string | null;
  NameOfParentRegulatorId: string | null;
  NameOfRegulator: string | null;
  NameOfRegulatorId: string | null;
  NewLegalName: string | null;
  RevenueBreakdown: { Name: string; Value: number; Id: number }[];
  SourceOfFunds: { Name: string; Value: number; Id: number }[];
  WasChangeControlPersonSinceLastColl: string | null;
  WasChangeOwnerhsipSinceLastCollected: string | null;
  IsParentListed: string;
  NameOfParentExchange: string | null;
  DoesListedParentMoreThan75MillionCad: string | null;
  IsClientFinancialConsoloidatedWithParent: string | null;
}

export enum QuestionnaireTabs {
  Products = 0,
  Questionnaire = 1,
  Exemptions = 2,
  RiskScore = 3,
  DocumentRequirement = 4,
}
