import { Helper } from 'src/app/helpers/helper';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StatisticService } from 'src/app/services/statistic.service';
import { AnalyticDataSource } from 'src/app/services/analytic.datasource';
import { ThrowStmt } from '@angular/compiler';
import { AnalyticModel } from 'src/app/models/analytic';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'select-new-excel-entry',
  templateUrl: './new-entry-excel.component.html',
  styleUrls: ['./new-entry-excel.component.css'],
})
export class NewExcelEntrySelectComponent implements OnInit {
  @ViewChild('file') fileInput?: ElementRef;

  public files: any[] = [];
  public processedFileData?: AnalyticModel[];
  public isLoading: boolean = false;
  public isNewEntrySaved: boolean = false;
  public isErrorWhileSaving: boolean = false;
  public formData: FormData = new FormData();
  public fileSuccessfullyProcessed: boolean = false;
  public saveButtonDisabledDueToEditing: boolean = false;
  public dataSource: AnalyticDataSource = new AnalyticDataSource(
    this.statisticService
  );
  public allColumns = [
    'CheckBox',
    'ClientId',
    'ClientName',
    'ParentGrouping',
    'DueDateStr',
    'CountryOfIncorporation',
    'Revenue',
    'Bucket',
    'ClientType',
    'Analyst',
    'QCReviewer',
    'ClientContactTitle',
    'ClientContactEmail',
    'ClientContactName',
    'CompletionDateStr',
    'InitialOutreachDateStr',
    'ReasonForDescope',
    'Products',
    'RiskScore',
    'Edit',
    'Remove',
  ];

  constructor(
    private helper: Helper,
    public statisticService: StatisticService,
    private dialogRef: MatDialogRef<NewExcelEntrySelectComponent>
  ) {}

  ngOnInit(): void {}

  public processFiles(event: any): void {
    const targetFiles = [...event.target.files];

    if (targetFiles.length) {
      this.files = targetFiles;
      this.formData.append('file', targetFiles[0]);
    }
  }

  public displayFiles() {
    if (this.files.length) {
      return this.files.map((file) => file.name);
    }

    return 'No file selected.';
  }

  public deleteSelectedFile() {
    this.fileInput!.nativeElement.value = [];
    this.files = [];
  }

  public continue() {
    const userId = this.helper.getUserId();
    const formData = new FormData();

    formData.append('file', this.files[0]);
    this.isLoading = true;

    this.statisticService.postExcelFile(formData, userId).subscribe(
      (res) => {
        this.dataSource.passData(res.Data);
        // saving all data from processed file so that we could use it later on for UI pagination
        this.dataSource.saveDataForPagination(res.Data);
        this.processedFileData = res.Data;
        this.isLoading = false;
        this.fileSuccessfullyProcessed = true;
      },
      (err) => {
        this.dialogRef.close();
        throw err;
      }
    );
  }

  public save(): void {
    const userId = this.helper.getUserId();
    this.isLoading = true;
    this.statisticService
      .postSaveExcelFile({ Data: this.processedFileData, UserId: userId })
      .subscribe(
        (res) => {
          setTimeout(() => {
            this.isLoading = false;
            this.isNewEntrySaved = true;
            this.helper.callLeftMenuChanged();
            this.helper.newEntryAddedSuccessfully(true);
          }, 2000);
        },
        (err) => {
          console.log(err);
          this.isLoading = false;
          this.isErrorWhileSaving = true;
        }
      );
  }

  public disableSaveButton(): void {
    this.saveButtonDisabledDueToEditing = !this.saveButtonDisabledDueToEditing;
  }
}
