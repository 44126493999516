import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'docu-library-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class DocuLibraryProfileTab implements OnInit {
    public dataSource: [] = [];
    public isChildRoute: boolean = false;
    public childRoutes: string[] = [
        'tax',
        'contact',
        'regulatory',
        'general-information',
    ];

    constructor(private route: Router, private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.activatedRoute.url.subscribe(() => {
            this.isChildRoute = this.childRoutes.some((x) =>
                this.route.url.includes(x)
            );
        });
    }
}
