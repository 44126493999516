import {Component, Input, OnInit} from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import {
  ControlValue,
  PotentialRiskControls,
  PotentialScreeningOutcomeControls,
  RiskAttributesControls,
  RiskFormType,
  RiskScoreData,
  RiskScoresById,
  RiskScoresByName,
} from './types';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { Helper } from 'src/app/helpers/helper';
import { QuestionnaireTabs } from '../../questionaire-tab/tab-container/types';
import { MatSnackBar } from '@angular/material/snack-bar';

interface RiskAttributesControl {
  [key: string]: string;
}

interface DropdownData {
  Name: string;
  Id: number;
}

@Component({
  selector: 'risk-score-tab-container',
  templateUrl: './risk-score-tab-container.component.html',
  styleUrls: ['./risk-score-tab-container.component.scss'],
})
export class RiskScoreTabContainerComponent implements OnInit {
  public controls: RiskAttributesControl;
  public potentialScreeningOutcomeControls: RiskAttributesControl;
  public potentialRiskControls: RiskAttributesControl;
  public adverseInformation: DropdownData[] = [];
  public countryCategories: DropdownData[] = [];
  public PEPs: DropdownData[] = [];
  public risks: DropdownData[] = [];
  public isSaveDisabled: boolean = true;
  public isSubmitDisabled: boolean = false;
  public id: number = 0;
  public highestRiskScore: { Name: string; Id: number } | undefined;
  public isLoading: boolean = false;
  public selectedPep: { Name: string; Id: number } | null = null;
  public selectedAdverseInfo: { Name: string; Id: number } | null = null;
  public selectedOverrideRiscScore: { Name: string; Id: number } | null = null;

  @Input() public isOnStructures: boolean = false;

  public get clientId() {
    return this.helper.getQuestionnaireClientId();
  }

  public get formControls() {
    return Object.keys(this.riskAttributesForm.controls);
  }

  public get potentialScreeningOutcomeFormControls() {
    return Object.keys(this.potentialScreeningOutcomeForm.controls);
  }

  public get potentialRiskFormControls() {
    return Object.keys(this.potentialRiskForm.controls);
  }

  public get riskCountryCategoryNames() {
    const preselectedNames =
      this.riskAttributesForm.controls['CountryCategories'].value;

    return preselectedNames.length ? preselectedNames.join(', ') : '';
  }

  public riskAttributesControlValue(key: string): any {
    return this.riskAttributesForm.controls[key].value;
  }

  public riskAttributesForm: FormGroup = new FormGroup({
    CountryCategories: new FormControl(''),
    CountryCategoryIds: new FormControl(''),
    CountryRisk: new FormControl(''),
    CountryExposureRisk: new FormControl(''),
    ReputationalRisk: new FormControl(''),
    PoliticalExposureRisk: new FormControl(''),
    IndustryRisk: new FormControl(''),
    ProductRisk: new FormControl(''),
    BusinessStructureRisk: new FormControl(''),
  });

  public potentialScreeningOutcomeForm: FormGroup = new FormGroup({
    Pep: new FormControl(''),
    PepDescription: new FormControl(''),
    AdverseInformation: new FormControl(''),
    NegativeInformationDetail: new FormControl(''),
  });

  public potentialRiskForm: FormGroup = new FormGroup({
    ClientRiskScore: new FormControl(''),
    OverrideClientRiskScore: new FormControl(''),
    Comment: new FormControl(''),
  });

  constructor(
    private helper: Helper,
    private snackBar: MatSnackBar,
    private questionnaireService: QuestionnaireService
  ) {
    this.controls = RiskAttributesControls;
    this.potentialRiskControls = PotentialRiskControls;
    this.potentialScreeningOutcomeControls = PotentialScreeningOutcomeControls;
  }

  ngOnInit(): void {
    // risk attributes changes handler
    this.riskAttributesForm.valueChanges.subscribe((changes) => {
      const formControlKeys = Object.keys(this.riskAttributesForm.controls);

      const riskScores = formControlKeys
        .map((key) => this.riskAttributesForm.controls[key].value.Id)
        .sort();

      this.highestRiskScore = this.risks.find((x) => x.Id === riskScores[0]);

      if (
        this.potentialRiskForm.controls['ClientRiskScore'].value?.Name !==
        this.highestRiskScore?.Name
      ) {
        this.potentialRiskForm.controls['ClientRiskScore'].setValue({
          ...this.highestRiskScore,
        });
      }
    });

    // potential screening outcome changes handler
    this.potentialScreeningOutcomeForm.valueChanges.subscribe((changes) => {
      this.isSaveDisabled = false;
      this.isSubmitDisabled = true;
    });

    this.potentialRiskForm.valueChanges.subscribe(() => {
      this.isSaveDisabled = false;
      this.isSubmitDisabled = true;
    });

    this.questionnaireService.fetchRiskScoreDropdownData().subscribe((res) => {
      const data = res.Data;

      this.PEPs = data.PEPs;
      this.risks = data.Risks;
      this.countryCategories = data.CountryCategories;
      this.adverseInformation = data.AdverseInformations;

      this.questionnaireService
        .fetchRiskScoreData(this.clientId)
        .subscribe((res) => {
          const data = res.Data;

          this.selectedPep = this.PEPs.find((pep) => pep.Id === data.PepId)!;
          this.selectedAdverseInfo = this.adverseInformation.find(
            (ai) => ai.Id === data.AdverseInformationId
          )!;
          this.selectedOverrideRiscScore = this.risks.find(
            (x) => x.Id === data.OverrideClientRiskScoreId
          )!;

          this.id = data && data.Id ? data.Id : 0;

          if (data) {
            this.populateRiskAttributesForm(data);
            this.populatePotentialScreeningOutcomeForm(data);
            this.populatePotentialRiskForm(data);
          }

          if (res.Message === 'Please, complete Questionnaire first') {
            this.snackBar.open(
              'Please make sure to complete the Questionnaire step first!',
              'X',
              {
                panelClass: ['error-snackbar'],
                duration: 3000,
              }
            );
          }

          this.isSaveDisabled = true;
          this.isSubmitDisabled = false;
        });
    });
  }

  private populateRiskAttributesForm(data: RiskScoreData) {
    this.formControls.map((control) => {
      const idKey = control + 'Id';

      if (control === 'CountryCategories') {
        this.riskAttributesForm.controls[control].setValue(
          data[control as keyof RiskScoreData]
        );
        return;
      }

      if (control === 'CountryCategoryIds') {
        this.riskAttributesForm.controls[control].setValue(
          data[control as keyof RiskScoreData]
        );
        return;
      }

      this.riskAttributesForm.controls[control].setValue({
        Name: data[control as keyof RiskScoreData],
        Id: data[idKey as keyof RiskScoreData],
      });
    });
  }

  private populatePotentialScreeningOutcomeForm(data: RiskScoreData) {
    this.potentialScreeningOutcomeFormControls.map((control) => {
      const idKey = control + 'Id';

      if (control === 'PepDescription')
        this.potentialScreeningOutcomeForm.controls[control].setValue(
          data['PepDescription']
        );
      else if (control === 'NegativeInformationDetail')
        this.potentialScreeningOutcomeForm.controls[control].setValue(
          data['NegativeInformationDetail']
        );
      else {
        this.potentialScreeningOutcomeForm.controls[control].setValue({
          Name: data[control as keyof RiskScoreData],
          Id: data[idKey as keyof RiskScoreData],
        });
      }
    });
  }

  private populatePotentialRiskForm(data: RiskScoreData) {
    this.potentialRiskFormControls.map((control) => {
      const idKey = control + 'Id';
      if (control === 'ClientRiskScore') {
        this.potentialRiskForm.controls[control].setValue({
          ...this.highestRiskScore,
        });
      } else if (control === 'Comment')
        this.potentialRiskForm.controls[control].setValue(data['Comment']);
      else
        this.potentialRiskForm.controls[control].setValue({
          Name: data[control as keyof RiskScoreData],
          Id: data[idKey as keyof RiskScoreData],
        });
    });
  }

  public onControlChange(event: MatSelectChange, key: string, form: string) {
    switch (form) {
      case RiskFormType.RiskAttributes:
        this.riskAttributesForm.controls[key].setValue(event.value);
        break;

      case RiskFormType.PotentialScreeningOutcome:
        if (key === 'AdverseInformation') {
          const highReputationRiskAdverse = ['Major Negative News', 'Sanction'];

          const adverseInfo = this.adverseInformation.find(
            (el) => el.Name === event.value
          );

          if (adverseInfo)
            this.potentialScreeningOutcomeForm.controls[key].setValue({
              Name: adverseInfo.Name,
              Id: adverseInfo.Id,
            });

          // Setting Reputational Risk to "High" is Adverse Information is 'Major Negative News' or 'Sanction'
          if (
            adverseInfo &&
            highReputationRiskAdverse.includes(adverseInfo.Name) &&
            this.riskAttributesForm.controls['ReputationalRisk'].value.Id !==
              RiskScoresById.High
          ) {
            setTimeout(() => {
              this.riskAttributesForm.controls['ReputationalRisk'].patchValue({
                Name: RiskScoresByName.High,
                Id: RiskScoresById.High,
              });
            }, 200);
          } else if (
            adverseInfo &&
            !highReputationRiskAdverse.includes(adverseInfo.Name) &&
            this.riskAttributesForm.controls['ReputationalRisk'].value.Id !==
              RiskScoresById.Low
          ) {
            setTimeout(() => {
              this.riskAttributesForm.controls['ReputationalRisk'].setValue({
                Name: RiskScoresByName.Low,
                Id: RiskScoresById.Low,
              });
            }, 200);
          }
        } else if (key === 'Pep') {
          const pep = this.PEPs.find((el) => el.Name === event.value);
          const highRiskPeps = [
            'PEP Majority Owner',
            'PEP in Control Position',
          ];

          if (
            pep &&
            highRiskPeps.includes(pep.Name) &&
            this.riskAttributesForm.controls['PoliticalExposureRisk'].value
              .Name !== RiskScoresByName.High
          ) {
            // setting Political Exposure Risk to HIGH is PEP is one of the above mentioned
            this.riskAttributesForm.controls['PoliticalExposureRisk'].setValue({
              Name: RiskScoresByName.High,
              Id: RiskScoresById.High,
            });
          }

          if (
            pep &&
            !highRiskPeps.includes(pep.Name) &&
            this.riskAttributesForm.controls['PoliticalExposureRisk'].value
              .Name !== RiskScoresByName.Low
          ) {
            // setting Political Exposure Risk to LOW is PEP is different
            this.riskAttributesForm.controls['PoliticalExposureRisk'].setValue({
              Name: RiskScoresByName.Low,
              Id: RiskScoresById.Low,
            });
          }

          if (pep)
            this.potentialScreeningOutcomeForm.controls[key].setValue({
              Name: pep.Name,
              Id: pep.Id,
            });
        } else
          this.potentialScreeningOutcomeForm.controls[key].setValue(
            event.value
          );

        break;

      case RiskFormType.PotentialRisk:
        if (key === 'OverrideClientRiskScore') {
          const overrideRiskScore = this.risks.find(
            (el) => el.Name === event.value
          );
          if (overrideRiskScore)
            this.potentialRiskForm.controls[key].setValue({
              Name: overrideRiskScore.Name,
              Id: overrideRiskScore.Id,
            });
        } else this.potentialRiskForm.controls[key].setValue(event.value);
        break;

      default:
        break;
    }
  }

  private createSavePayload(): RiskScoreData {
    const getRiskAttributesFieldNameOrId = (
      control: keyof RiskScoreData,
      key: ControlValue
    ) => {
      const controlValue = this.riskAttributesForm.controls[control].value;

      if (control === 'CountryCategories') {
        return controlValue;
      }

      if (control === 'CountryCategoryIds') {
        return controlValue;
      }

      return controlValue
        ? controlValue[key]
        : key === ControlValue.Name
        ? RiskScoresByName.Low
        : RiskScoresById.Low;
    };

    return {
      Id: this.id,
      ClientId: +this.clientId,
      CountryCategories: getRiskAttributesFieldNameOrId(
        'CountryCategories',
        ControlValue.Name
      ),
      CountryCategoryIds: getRiskAttributesFieldNameOrId(
        'CountryCategoryIds',
        ControlValue.Id
      ),

      CountryRisk: getRiskAttributesFieldNameOrId(
        'CountryRisk',
        ControlValue.Name
      ),
      CountryRiskId: getRiskAttributesFieldNameOrId(
        'CountryRisk',
        ControlValue.Id
      ),

      CountryExposureRisk: getRiskAttributesFieldNameOrId(
        'CountryExposureRisk',
        ControlValue.Name
      ),
      CountryExposureRiskId: getRiskAttributesFieldNameOrId(
        'CountryExposureRisk',
        ControlValue.Id
      ),

      ReputationalRisk: getRiskAttributesFieldNameOrId(
        'ReputationalRisk',
        ControlValue.Name
      ),
      ReputationalRiskId: getRiskAttributesFieldNameOrId(
        'ReputationalRisk',
        ControlValue.Id
      ),

      PoliticalExposureRisk: getRiskAttributesFieldNameOrId(
        'PoliticalExposureRisk',
        ControlValue.Name
      ),
      PoliticalExposureRiskId: getRiskAttributesFieldNameOrId(
        'PoliticalExposureRisk',
        ControlValue.Id
      ),

      IndustryRisk: getRiskAttributesFieldNameOrId(
        'IndustryRisk',
        ControlValue.Name
      ),
      IndustryRiskId: getRiskAttributesFieldNameOrId(
        'IndustryRisk',
        ControlValue.Id
      ),

      ProductRisk: getRiskAttributesFieldNameOrId(
        'ProductRisk',
        ControlValue.Name
      ),
      ProductRiskId: getRiskAttributesFieldNameOrId(
        'ProductRisk',
        ControlValue.Id
      ),

      BusinessStructureRisk: getRiskAttributesFieldNameOrId(
        'BusinessStructureRisk',
        ControlValue.Name
      ),
      BusinessStructureRiskId: getRiskAttributesFieldNameOrId(
        'BusinessStructureRisk',
        ControlValue.Id
      ),

      Pep: this.potentialScreeningOutcomeForm.controls['Pep'].value?.Name,
      PepId: this.potentialScreeningOutcomeForm.controls['Pep'].value?.Id,
      PepDescription:
        this.potentialScreeningOutcomeForm.controls['PepDescription'].value,
      AdverseInformation:
        this.potentialScreeningOutcomeForm.controls['AdverseInformation'].value
          ?.Name,
      AdverseInformationId:
        this.potentialScreeningOutcomeForm.controls['AdverseInformation'].value
          ?.Id,
      NegativeInformationDetail:
        this.potentialScreeningOutcomeForm.controls['NegativeInformationDetail']
          .value,

      ClientRiskScore:
        this.potentialRiskForm.controls['ClientRiskScore'].value?.Name,
      ClientRiskScoreId:
        this.potentialRiskForm.controls['ClientRiskScore'].value?.Id,
      OverrideClientRiskScore:
        this.potentialRiskForm.controls['OverrideClientRiskScore'].value?.Name,
      OverrideClientRiskScoreId:
        this.potentialRiskForm.controls['OverrideClientRiskScore'].value?.Id,
      Comment: this.potentialRiskForm.controls['Comment'].value,
    };
  }

  public handleSave() {
    this.isLoading = true;
    const payload = this.createSavePayload();

    this.questionnaireService.saveRiskScoreData(payload).subscribe((res) => {
      this.isSaveDisabled = true;
      this.isSubmitDisabled = false;
      this.isLoading = false;

      this.snackBar.open('Changes successfully saved!', 'X', {
        panelClass: ['success-snackbar'],
        duration: 3000,
      });
    });
  }

  public handleSubmit() {
    this.helper.switchQuestionnaireTab(QuestionnaireTabs.DocumentRequirement);
  }

  handleSelectPepOption(event: { Name: string; Id: number }) {
    if (event.Id === this.selectedPep?.Id) {
      this.potentialScreeningOutcomeForm.controls['Pep'].setValue(null);
      this.selectedPep = null;

      // Pep and PoliticalExposureRisk are logically connected
      // should we reset the value of Pep, PoliticalExposureRisk should also be reset
      this.riskAttributesForm.controls['PoliticalExposureRisk'].setValue('');
      return;
    }

    this.selectedPep = event;
  }

  handleSelectAdverseInfoOption(adverseInfo: { Name: string; Id: number }) {
    if (adverseInfo.Id === this.selectedAdverseInfo?.Id) {
      this.potentialScreeningOutcomeForm.controls[
        'AdverseInformation'
      ].setValue(null);
      this.selectedAdverseInfo = null;

      // AdverseInformation and ReputationalRisk are logically connected
      // should we reset the value of AdverseInformation, ReputationalRisk should also be reset
      this.riskAttributesForm.controls['ReputationalRisk'].patchValue('');
      return;
    }

    this.selectedAdverseInfo = adverseInfo;
  }

  handleSelectOverrideRiscScoreOption(overrideScore: {
    Name: string;
    Id: number;
  }) {
    if (overrideScore.Id === this.selectedOverrideRiscScore?.Id) {
      this.potentialRiskForm.controls['OverrideClientRiskScore'].setValue(null);
      this.selectedOverrideRiscScore = null;
      return;
    }

    this.selectedOverrideRiscScore = overrideScore;
  }
}
