import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DocuLibraryService} from "../../../../../../../../../../services/docu-library.service";
import {Helper} from "../../../../../../../../../../helpers/helper";
import {DropdownOptions, SdlDataPayload} from "./types";
import {isNull} from "@angular/compiler/src/output/output_ast";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-sdl-data',
    templateUrl: './sdl-data.component.html',
    styleUrls: ['./sdl-data.component.css']
})
export class SdlDataComponent implements OnInit {
    public isLoading: boolean = false;
    public sdlForm: FormGroup = new FormGroup({});
    public dataSource: SdlDataPayload | undefined;
    public dropdownData: DropdownOptions | null = null;

    get clientId(): string {
        return this.helper.getQuestionnaireClientId();
    }

    constructor(
        private helper: Helper,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private regulatoryService: DocuLibraryService,
    ) { }

    ngOnInit(): void {
        this.isLoading = true;
        this.regulatoryService.fetchRegulatoryDropdownOptions().subscribe((dropDawnData) => {
            this.dropdownData = dropDawnData.Data;

            this.regulatoryService.fetchRegulatorySdlData(parseInt(this.clientId)).subscribe((data) => {
                this.dataSource = data.Data;
                this.fillFormData(data.Data);
                this.isLoading = false;
                this.cdr.detectChanges();
            });
        });
    }

    private parseStringFromBoolean(value: string | boolean | null): string | null {
        return value === null ? null : value ? 'Yes' : 'No';
    }

    private fillFormData(data: SdlDataPayload): void {
        this.sdlForm = this.formBuilder.group({
            informationRecipientStatus: [data.InformationRecipientStatus, Validators.required],
            informationRequestedForPurposesOfTransactingIn: [data.InformationRequestedForPurposesOfTransactingIn, Validators.required],
            applicableMarginRegulation: [data.ApplicableMarginRegulation, Validators.required],
            registrationCategory: [data.RegistrationCategory, Validators.required],
            prudentialRegulatorStatus: [this.parseStringFromBoolean(data.IsPrudentialRegulatorStatus), Validators.required],
            multiBranchStatus: [this.parseStringFromBoolean(data.IsMultiBranchStatus), Validators.required],
            usaResidentOrEntity: [this.parseStringFromBoolean(data.IsUSResidentOrEntity), Validators.required],
            usaPersonCftc2020: [this.parseStringFromBoolean(data.IsUSPersonCFTC2020RulesSECRules), Validators.required],
            usaPersonGuaranteesCftc2020: [data.USPersonGuaranteesCFTC2020Rules, Validators.required],
            financialEntityGuarantees: [data.FinancialEntityGuaranteesCFTC2020Rules, Validators.required],
            sdAffiliate: [this.parseStringFromBoolean(data.IsSDAffiliate), Validators.required],
            swapEntitySignificantRiskSubsidiary: [
                this.parseStringFromBoolean(data.IsSwapEntitySignificantRiskSubsidiary), Validators.required
            ],
            usaPerson: [this.parseStringFromBoolean(data.IsUSPerson), Validators.required],
            affiliateConduit: [this.parseStringFromBoolean(data.IsAffiliateConduit), Validators.required],
            usaPersonGuarantees: [data.USPersonGuarantees, Validators.required],
            usaPersonCftcMargin: [this.parseStringFromBoolean(data.IsUSPersonCFTCMarginRules), Validators.required],
            foreignConsolidatedSubsidiaryCftcMarginRules: [
                this.parseStringFromBoolean(data.IsForeignConsolidatedSubsidiaryCFTCMarginRules), Validators.required
            ],
            usaPersonGuaranteesCftcMarginRules: [data.USPersonGuaranteesCFTCMarginRules, Validators.required],
            prForeignConsolidatedSubsidiary: [this.parseStringFromBoolean(data.IsPRForeignConsolidatedSubsidiary), Validators.required],
            prUsaGuarantees: [data.PRUSGuarantees, Validators.required],
            sbsThroughForeignBranchSecRules: [data.SBSThroughForeignBranchSECRules, Validators.required],
            swapsBookedThroughUsaBranchCftc2020Rules: [data.SwapsBookedThroughUSBranchCFTC2020Rules, Validators.required],
            swapsConductedThroughForeignBranchCftc2020Rules: [data.SwapsConductedThroughForeignBranchCFTC2020Rules, Validators.required],
            swapsThroughUsaBranchCftcMarginRules: [data.SwapsThroughUSBranchCFTCMarginRules, Validators.required],
            swapsOrSbsThroughUsaBranchPrMarginRules: [data.SwapsOrSBSThroughUSBranchPRMarginRules, Validators.required],
            nonFinancialEndUserStatus: [data.NonFinancialEndUserStatus, Validators.required],
            hedgingExemptionStatus: [data.HedgingExemptionStatus, Validators.required],
            exemptionElections: [data.ExemptionElections, Validators.required],
            exemptionReporting: [data.ExemptionReporting, Validators.required],
            financialObligations: [data.FinancialObligations, Validators.required],
            secIssuerFiler: [data.SECIssuerFiler, Validators.required],
            secCentralIndexKeyNumber: [data.SECCentralIndexKeyNumber, Validators.required],
            boardApproval: [data.BoardApproval, Validators.required],
            exemptionStatus: [data.ExemptionStatus, Validators.required],
            exemptionElection: [data.ExemptionElection, Validators.required],
            commercialEndUserReporting: [data.CommercialEndUserReporting, Validators.required],
            financialEntity: [this.parseStringFromBoolean(data.IsFinancialEntity), Validators.required],
        });
    }

    public updateSdlData(): void {
        this.isLoading = true;

        if (this.dataSource) {
            const isPrudentialRegulatorStatus = this.sdlForm.controls['prudentialRegulatorStatus'].value;
            const multiBranchStatus = this.sdlForm.controls['multiBranchStatus'].value;
            const usaResidentOrEntity = this.sdlForm.controls['usaResidentOrEntity'].value;
            const usaPersonCftc2020 = this.sdlForm.controls['usaPersonCftc2020'].value;
            const sdAffiliate = this.sdlForm.controls['sdAffiliate'].value;
            const swapEntitySignificantRiskSubsidiary = this.sdlForm.controls['swapEntitySignificantRiskSubsidiary'].value;
            const usaPerson = this.sdlForm.controls['usaPerson'].value;
            const affiliateConduit = this.sdlForm.controls['affiliateConduit'].value;
            const usaPersonCftcMargin = this.sdlForm.controls['usaPersonCftcMargin'].value;
            const foreignConsolidatedSubsidiaryCftcMarginRules =
                this.sdlForm.controls['foreignConsolidatedSubsidiaryCftcMarginRules'].value;
            const prForeignConsolidatedSubsidiary = this.sdlForm.controls['prForeignConsolidatedSubsidiary'].value;
            const financialEntity = this.sdlForm.controls['financialEntity'].value;

            this.regulatoryService.updateRegulatorySdlData({
                Id: this.dataSource.Id,
                ClientId: this.dataSource.ClientId,
                InformationRecipientStatus: this.sdlForm.controls['informationRecipientStatus'].value,
                InformationRequestedForPurposesOfTransactingIn:
                    this.sdlForm.controls['informationRequestedForPurposesOfTransactingIn'].value,
                ApplicableMarginRegulation: this.sdlForm.controls['applicableMarginRegulation'].value,
                RegistrationCategory: this.sdlForm.controls['registrationCategory'].value,
                IsPrudentialRegulatorStatus: isPrudentialRegulatorStatus === null ? null : isPrudentialRegulatorStatus === 'Yes',
                IsMultiBranchStatus: multiBranchStatus === null ? null : multiBranchStatus === 'Yes',
                IsUSResidentOrEntity: usaResidentOrEntity === null ? null : usaResidentOrEntity === 'Yes',
                IsUSPersonCFTC2020RulesSECRules: usaPersonCftc2020 === null ? null : usaPersonCftc2020 === 'Yes',
                USPersonGuaranteesCFTC2020Rules: this.sdlForm.controls['usaPersonGuaranteesCftc2020'].value,
                FinancialEntityGuaranteesCFTC2020Rules: this.sdlForm.controls['financialEntityGuarantees'].value,
                IsSDAffiliate: sdAffiliate === null ? null : sdAffiliate === 'Yes',
                IsSwapEntitySignificantRiskSubsidiary:
                    swapEntitySignificantRiskSubsidiary === null ? null : swapEntitySignificantRiskSubsidiary === 'Yes',
                IsUSPerson: usaPerson === null ? null : usaPerson === 'Yes',
                IsAffiliateConduit: affiliateConduit === null ? null : affiliateConduit === 'Yes',
                USPersonGuarantees: this.sdlForm.controls['usaPersonGuarantees'].value,
                IsUSPersonCFTCMarginRules: usaPersonCftcMargin === null ? null : usaPersonCftcMargin === 'Yes',
                IsForeignConsolidatedSubsidiaryCFTCMarginRules:
                    foreignConsolidatedSubsidiaryCftcMarginRules === null ? null : foreignConsolidatedSubsidiaryCftcMarginRules === 'Yes',
                USPersonGuaranteesCFTCMarginRules: this.sdlForm.controls['usaPersonGuaranteesCftcMarginRules'].value,
                IsPRForeignConsolidatedSubsidiary:
                    prForeignConsolidatedSubsidiary === null ? null : prForeignConsolidatedSubsidiary === 'Yes',
                PRUSGuarantees: this.sdlForm.controls['prUsaGuarantees'].value,
                SBSThroughForeignBranchSECRules: this.sdlForm.controls['sbsThroughForeignBranchSecRules'].value,
                SwapsBookedThroughUSBranchCFTC2020Rules: this.sdlForm.controls['swapsBookedThroughUsaBranchCftc2020Rules'].value,
                SwapsConductedThroughForeignBranchCFTC2020Rules:
                    this.sdlForm.controls['swapsConductedThroughForeignBranchCftc2020Rules'].value,
                SwapsThroughUSBranchCFTCMarginRules: this.sdlForm.controls['swapsThroughUsaBranchCftcMarginRules'].value,
                SwapsOrSBSThroughUSBranchPRMarginRules: this.sdlForm.controls['swapsOrSbsThroughUsaBranchPrMarginRules'].value,
                NonFinancialEndUserStatus: this.sdlForm.controls['nonFinancialEndUserStatus'].value,
                HedgingExemptionStatus: this.sdlForm.controls['hedgingExemptionStatus'].value,
                ExemptionElections: this.sdlForm.controls['exemptionElections'].value,
                ExemptionElection: this.sdlForm.controls['exemptionElection'].value,
                ExemptionReporting: this.sdlForm.controls['exemptionReporting'].value,
                FinancialObligations: this.sdlForm.controls['financialObligations'].value,
                SECIssuerFiler: this.sdlForm.controls['secIssuerFiler'].value,
                SECCentralIndexKeyNumber: this.sdlForm.controls['secCentralIndexKeyNumber'].value,
                BoardApproval: this.sdlForm.controls['boardApproval'].value,
                ExemptionStatus: this.sdlForm.controls['exemptionStatus'].value,
                CommercialEndUserReporting: this.sdlForm.controls['commercialEndUserReporting'].value,
                IsFinancialEntity: financialEntity === null ? null : financialEntity === 'Yes',
            }).subscribe(res => {
                this.isLoading = false;
                this.sdlForm.markAsPristine();
                this.snackBar.open('Changes saved!', 'X', {
                    duration: 1500,
                    panelClass: ['success-snackbar'],
                });
            });
        }
    }
}
