<div class="d-flex analytics-container">
  <div class="d-flex tabs-wrapper w-100 align-items-start">
    <mat-tab-group
      #tabGroup
      id="analytics"
      (selectedTabChange)="tabChanged($event)"
    >
      <!-- Tables -->
      <mat-tab>
        <!-- tab head -->
        <ng-template mat-tab-label>
          <div class="tab-wrapper">
            <img
              alt="tables"
              class="tab-icon"
              [src]="
                tabGroup.selectedIndex === 0
                  ? './../assets/icons/analytics-tables-active.svg'
                  : './../assets/icons/analytics-tables.svg'
              "
            />
            <span>Tables</span>
            <img
              alt="arrow"
              class="arrow-icon"
              [src]="
                tabGroup.selectedIndex === 0
                  ? './../assets/icons/analytics-arrow-active.svg'
                  : './../assets/icons/analytics-arrow.svg'
              "
            />
          </div>
        </ng-template>
        <div class="separator-bloc">{{ "   " }}</div>
        <div class="sep-shadow">{{ "    " }}</div>

        <!-- actions section -->
        <div class="analytics-actions">
          <div>
            <button mat-button class="refresh-button" (click)="refreshClick()">
              <img
                alt="refresh"
                class="refresh-icon"
                src="./../assets/icons/refresh.svg"
              />
            </button>
            <button
              mat-button
              class="download-report-button"
              (click)="onDownloadClick()"
              *ngIf="isUserCanDownloadReport"
            >
              <img
                alt="download"
                class="download-icon"
                src="./../assets/icons/Ebene 2.svg"
              />
              <span style="color: white"> Download Report</span>
            </button>
          </div>

          <div class="d-flex">
            <div></div>

            <div>
              <mat-menu #action2="matMenu">
                <div
                  class="report-dates-wrapper"
                  *ngFor="let date of reportDates; let i = index"
                >
                  <button
                    id="i"
                    mat-menu-item
                    class="report-dates-base"
                    (click)="reportDate = date"
                  >
                    {{ date }}
                  </button>
                </div>
              </mat-menu>
            </div>
          </div>
        </div>

        <!-- tab content -->
        <analytics-data-table
          [bucket]="bucket"
          [loading]="loading"
          [reportDate]="reportDate"
          [allData]="analyticsTable"
          [selectedAnalysts]="selectedAnalysts"
        >
        </analytics-data-table>
      </mat-tab>

      <!-- Graphs -->
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="tab-wrapper">
            <img
              alt="tables"
              class="tab-icon"
              style="fill: green"
              [src]="
                tabGroup.selectedIndex === 1
                  ? './../assets/icons/graphs-active.svg'
                  : './../assets/icons/graphs.svg'
              "
            />
            <span>Graphs</span>
            <img
              alt="arrow"
              class="arrow-icon"
              [src]="
                tabGroup.selectedIndex === 1
                  ? './../assets/icons/analytics-arrow-active.svg'
                  : './../assets/icons/analytics-arrow.svg'
              "
            />
          </div>
        </ng-template>
        <div class="separator-bloc">{{ "   " }}</div>
        <div class="sep-shadow">{{ "    " }}</div>

        <!-- NUMBER OF CASES: Toggle Buttons section -->
        <div class="d-flex steps-toggle-box">
          <div class="chart-title">Number of cases</div>
          <mat-button-toggle-group
            class="steps-toggle"
            id="toggle-button-group"
            [value]="selectedStep.value"
          >
            <ng-container *ngFor="let step of allSteps; let i = index">
              <mat-button-toggle
                [value]="step.value"
                (click)="handleStepChange(step.value)"
                [ngClass]="step.label.toLowerCase().replace(' ', '-')"
              >
                {{ step.label === 'Complete' ? 'Completed' : step.label }}
              </mat-button-toggle>
            </ng-container>
          </mat-button-toggle-group>
        </div>

        <div class="d-flex data-chart-box">
          <div class="spinner-container" *ngIf="loading">
            <mat-spinner></mat-spinner>
          </div>
          <data-chart
            class="w-100"
            [ngStyle]="{ opacity: loading ? 0 : 1 }"
            title="Number of cases"
            chartId="numOfCases"
            [selectedStep]="selectedStep"
            (loadingEvent)="handleDataLoading($event)"
            [chartData]="chartData"
            [allData]="allData"
            [analysts]="analysts"
            [selectedAnalysts]="selectedAnalysts"
          ></data-chart>
        </div>

        <!-- AVERAGE AGING: Toggle Buttons section -->
        <div class="d-flex steps-toggle-box">
          <div class="chart-title">Average aging</div>
          <mat-button-toggle-group
            class="steps-toggle"
            id="toggle-button-group"
            [value]="selectedStep.value"
          >
            <ng-container *ngFor="let step of allSteps; let i = index">
              <mat-button-toggle
                [value]="step.value"
                (click)="handleStepChange(step.value)"
                [ngClass]="step.label.toLowerCase().replace(' ', '-')"
              >
                {{ step.label === 'Complete' ? 'Completed' : step.label }}
              </mat-button-toggle>
            </ng-container>
          </mat-button-toggle-group>
        </div>

        <div class="d-flex data-chart-box">
          <div class="spinner-container" *ngIf="loading">
            <mat-spinner></mat-spinner>
          </div>
          <data-chart
            class="w-100"
            title="Average aging"
            chartId="averageAging"
            [selectedStep]="selectedStep"
            [ngStyle]="{ opacity: loading ? 0 : 1 }"
            (loadingEvent)="handleDataLoading($event)"
            [chartData]="chartData"
            [allData]="allData"
            [analysts]="analysts"
            [selectedAnalysts]="selectedAnalysts"
          ></data-chart>
        </div>

        <div style="padding: 3rem 0"></div>
      </mat-tab>

      <!-- Trending -->
      <mat-tab label="Third">
        <ng-template mat-tab-label>
          <div class="tab-wrapper">
            <img
              alt="tables"
              class="tab-icon"
              [src]="
                tabGroup.selectedIndex === 2
                  ? './../assets/icons/trending-active.svg'
                  : './../assets/icons/trending.svg'
              "
            />
            <span>Trending</span>
            <img
              class="arrow-icon"
              alt="arrow"
              [src]="
                tabGroup.selectedIndex === 2
                  ? './../assets/icons/analytics-arrow-active.svg'
                  : './../assets/icons/analytics-arrow.svg'
              "
            />
          </div>
        </ng-template>
        <div class="separator-bloc">{{ "   " }}</div>
        <div class="sep-shadow">{{ "    " }}</div>

        <div id="trendingSteps" class="d-flex steps-toggle-box">
          <div
            class="target-section"
            [ngClass]="{ targetSet: targetSet }"
            (click)="handleOpenChangeTarget($event)"
          >
            <span *ngIf="!targetSet">Enter Daily Target</span>
            <span *ngIf="targetSet">Edit Daily Target</span>

            <div *ngIf="showChangeTarget" class="change-target">
              {{ currentTargetValue }}
              <input
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                matInput
                [value]="currentTargetValue"
                id="change-target-input"
                placeholder="Enter Target"
                class="change-target-input"
                (change)="handleInputChange($event)"
              />

              <img
                class="target-img"
                alt="set new target"
                (click)="setNewTarget()"
                src="./../assets/icons/change-target-tick.svg"
              />
            </div>
          </div>

          <mat-button-toggle-group
            class="steps-toggle"
            id="toggle-button-group"
            [value]="selectedStepTrending.value"
          >
            <ng-container *ngFor="let step of allStepsTrending; let i = index">
              <mat-button-toggle
                [value]="step.value"
                (click)="handleTrendingStepChange(step.value)"
              >
                {{ step.label }}
              </mat-button-toggle>
            </ng-container>
          </mat-button-toggle-group>
        </div>
        <div
          id="test"
          class="d-flex data-chart-box data-chart-box-trending"
          (scroll)="onScroll($event)"
        >
          <data-line-chart
            [chartData]="trendingData"
            [currentMonth]="selectedStepTrending.label"
            [currentStepObj]="selectedStepTrending"
            #lineChart
            (chartDataEmitter)="handleGetCurrentTarget($event)"
          ></data-line-chart>
        </div>
        <div class="pie-chart-container">
          <data-pie-chart></data-pie-chart>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div
    *ngIf="tabGroup.selectedIndex === 0 || tabGroup.selectedIndex === 1"
    class="w-20 d-flex sidebar-container"
  >
    <div class="d-flex align-items-center">
      <button
        mat-raised-button
        type="button"
        (click)="drawer.toggle()"
        class="toggle-btn"
      >
        <mat-icon *ngIf="!drawer.opened">keyboard_arrow_left</mat-icon>
        <mat-icon *ngIf="drawer.opened">keyboard_arrow_right</mat-icon>
      </button>
    </div>
    <mat-drawer-container class="example-container" autosize>
      <mat-drawer #drawer class="example-sidenav" mode="side" position="end">
        <calendar
          [currentDate]="calendarDate"
          (changeDateEvent)="handleFilterDate($event)"
        ></calendar>
        <div class="m-x-24">
          <div class="m-y-10">
            <mat-select
              (selectionChange)="handleTeamChange($event)"
              disableOptionCentering
              aria-placeholder="Select Team"
              placeholder="Select Team"
              class="wiam-select"
              panelClass="wiam-select-panel wiam-select-panel-singl"
              [value]="selectedTeam"
            >
              <mat-option *ngFor="let team of teams" [value]="team">
                <span *ngIf="team === selectedTeam" class="dot"></span>
                <span>{{ team.label }}</span>
              </mat-option>
            </mat-select>
          </div>
          <div class="m-y-10">
            <mat-select
              aria-placeholder="Select Analysts"
              placeholder="Select Analysts"
              (selectionChange)="handleAnalystChange($event)"
              disableOptionCentering
              class="wiam-select"
              panelClass="wiam-select-panel wiam-select-panel-mult"
              multiple
              [value]="selectedAnalysts"
            >
              <div class="search-input-wrapper">
                <input
                  (keyup)="handleAnalystFilter($event)"
                  (keydown)="$event.stopPropagation()"
                  class="search-input"
                  placeholder="Search By Name"
                  type="text"
                />
                <div class="search-btn">
                  <mat-icon *ngIf="drawer.opened">search</mat-icon>
                </div>
              </div>
              <div class="analysts-list-wrapper">
                <div
                  class="select-all-wrapper"
                  *ngIf="filteredAnalysts.length > 4"
                  (click)="handleUnselectAllAnalysts()"
                >
                  <div>
                    <span>Unselect All</span>
                  </div>
                  <div>
                    <mat-checkbox
                      class="example-margin"
                      disabled
                      [checked]="selectedAnalysts.length === 0"
                    ></mat-checkbox>
                  </div>
                </div>
                <div *ngFor="let analyst of filteredAnalysts">
                  <mat-option [value]="analyst">{{ analyst.label }}</mat-option>
                  <div
                    class="analyst-separator"
                    [style.background]="
                      'linear-gradient(90deg, rgba(255,255,255,1) 0%,' +
                      analyst.color +
                      ' 49%, rgba(255,255,255,1) 100%)'
                    "
                  >
                    {{ analyst.color }}
                  </div>
                </div>
              </div>
            </mat-select>
          </div>
          <div *ngIf="selectedAnalysts.length" class="m-y-10">
            <mat-grid-list class="analysts-grid" cols="3" rowHeight="45px">
              <mat-grid-tile
                *ngFor="let analyst of selectedAnalysts"
                [colspan]="1"
                [rowspan]="1"
              >
                <span
                  class="analyst-color"
                  [style.background-color]="analyst.color"
                ></span
                ><span>{{ analyst.label }}</span>
              </mat-grid-tile>
            </mat-grid-list>
          </div>

          <div class="m-y-10">
            <mat-select
              (selectionChange)="handleBucketChange($event)"
              disableOptionCentering
              aria-placeholder="Bucket"
              placeholder="Bucket"
              class="wiam-select"
              panelClass="wiam-select-panel wiam-select-panel-singl"
              multiple
              [value]="selectedBuckets"
            >
              <mat-option *ngFor="let bucket of buckets" [value]="bucket">
                <span>{{ bucket.label }}</span>
              </mat-option>
            </mat-select>
          </div>
        </div>
      </mat-drawer>
    </mat-drawer-container>
  </div>
</div>
