<div class="input-wrapper w-100">
  <mat-label class="input-label-base">{{ label }}</mat-label>
  <input
    readonly
    [value]="value"
    [formControl]="control"
    class="input-base w-100"
    [placeholder]="placeholder"
  />
</div>
