import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Helper } from '../../helpers/helper';
import { AuthService } from 'src/app/services/auth.service';
import { UpdateUserStatusModel } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-logout',
  template:'<ng-content></ng-content>'
})
export class LogoutComponent {
  constructor(private router: Router, private helper: Helper, private authService: AuthService) {
    this.logout();
  }

  logout() {
      this.helper.logout();
      this.helper.setIsAuth(false);
      this.helper.isAuthenticationChanged();
      this.router.navigate(['/login']);
  }
}
