import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-delete-confirmation-popup',
    templateUrl: './delete-confirmation-popup.component.html',
    styleUrls: ['./delete-confirmation-popup.component.css']
})
export class DeleteConfirmationPopupComponent implements OnInit {
    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<DeleteConfirmationPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { structureName: string }
    ) {}

    ngOnInit(): void {
    }

    public handleDeleteClick(): void {
        this.dialogRef.close(true);
    }
}
