import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';

import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatFormFieldModule} from '@angular/material/form-field';
import {RouterModule} from '@angular/router';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './layout/app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {Helper} from './helpers/helper';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

import {HeadComponent} from './layout/head.component';
import {LeftPanelComponent} from './layout/left-panel.component';
import {HomeComponent} from './components/home/home.component';
import {OutreachComponent} from './components/outreach/outreach.component';
import {NameScreeningComponent} from './components/name-screening/name-screening.component';
import {EscalationComponent} from './components/escalation/escalation.component';
import {ApprovalsComponent} from './components/approvals/approvals.component';
import {CompleteComponent} from './components/complete/complete.component';
import {AnalyticsComponent} from './components/analytics/analytics.component';
import {AuthGuard} from './helpers/AuthGuard';
import {AuthService} from './services/auth.service';
import {LoginComponent} from './components/login/login.component';
import {LogoutComponent} from './components/login/logout.component';
import {StatisticService} from './services/statistic.service';
import {MatInputModule} from '@angular/material/input';
import {
    DisplayColumnsDialogComponent
} from './components/common/display-columns-dialog/display-columns-dialog.component';
import {LayoutModule} from '@angular/cdk/layout';
import {FilterByDialogComponent} from './components/common/filter-by-dialog/filter-by-dialog.component';
import {SidebarTeamDropdown} from './layout/left-panel-team-dropdown/sidebar-team-dropdown.component';
import {InitialReviewComponent} from './components/initial-review/initial-review.component';
import {DescopeComponent} from './components/descope/descope.component';
import {MenuService} from './services/menu.service';
import {UserService} from './services/user.service';
import {NewExcelEntrySelectComponent} from './components/new-entry-excel/new-entry-excel.component';
import {DataTable} from './components/data-table/data-table.component';
import {ActionAssignDialog} from './components/assign-action-dialog/action-assign-dialog.component';
import {DescopeAssignDialog} from './components/descope-action-dialog/descope-action-dialog.component';

import {ErrorInterceptor} from './helpers/error.interceptor';
import {WebsocketService} from './services/websocket.service';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {ReusableMultipurposeDialog} from './components/common/dialog-popup-window/dialog-popup.component';
import {ConfirmationDialog} from './components/common/confirmation-dialog/confirmation-dialog.component';
import {AssignFilterPipe} from './components/assign-action-dialog/assign-filter.pipe';
import {DataValidationComponent} from './components/data-validation/data-validation.component';
import {NotificationService} from './services/notification.service';
import {
    SortingFilteringComponent
} from './components/data-table/data-table-sorting-filtering/data-table-sorting-filtering.component';
import {MatTabsModule} from '@angular/material/tabs';
import {AuditLogDialog} from './components/common/audit-log-dialog/audit-log.component';
import {
    AnalyticsDataTable
} from './components/analytics/data-tables/analytics-data-table/analytics-data-table.component';
import {NestedDataTable} from './components/analytics/data-tables/nested-data-table/nested-data-table.component';
import {AnalyticService} from './services/analytic.service';
import {UserAccessManagementComponent} from './components/user-access-managament/user-access.component';
import {RescopeDialog} from './components/common/confirmation-rescope-dialog/confirmation-dialog.component';
import {
    NotAllItemsProcessedDialog
} from './components/common/not-all-items-processed-dialog/confirmation-dialog.component';
import {CustomPaginator} from './components/common/custom-paginator/custom-paginator.component';

import {NgxChartsModule} from '@swimlane/ngx-charts';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {CalendarComponent} from './components/common/calendar/calendar.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DataChart} from './components/analytics/data-charts/bar-chart/data-charts.component';
import {DataLineChart} from './components/analytics/data-charts/line-chart/line-chart.component';
import {TrendingTabComponent} from './components/analytics/tabs/trending/trending.component';
import {DataPieChart} from './components/analytics/data-charts/pie-chart/pie-chart.component';
import {DataProgressBars} from './components/analytics/data-charts/pie-chart/progress-bars/progress-bars.component';
import {LoaderWithLogo} from './components/common/loader-with-logo/loader.component';
import {WelcomeComponent} from './components/welcome/welcome.component';
import {MdePopoverModule} from '@material-extended/mde';
import {ChipsMultiSelectComponent} from './components/common/chips-multi-select/chips-multi-select.component';
import {ChangePasswordComponent} from './components/change-password/change-password.component';
import {UserProfileComponent} from './components/user-profile/user-profile.component';
import {StyledInputField} from './components/common/styled-input-with-label/styled-input-field.component';
import {ChangeProfileAvatar} from './components/user-profile/change-user-avatar/change-profile-avatar.component';
import {AvatarSelector} from './components/user-profile/avatar-selector-dialog/avatar-selector-dialog.component';
import {
    ProfilePhotoControl
} from './components/user-profile/avatar-selector-dialog/profile-photo-control/profile-photo.component';
import {NgxImageCompressService} from 'ngx-image-compress';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {NotificationCountComponent} from './layout/notification-count/notification-count.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ColumnChipsPopover} from './components/common/column-chips-popover/column-chips-popover.component';
import {DataTableFilterPipe} from './components/data-table/data-table-sorting-filtering/filter.pipe';
import {
    QuestionnaireContainerComponent
} from './components/questionnaire/questionnaire-container/questionnaire-container.component';
import {
    QuestionnaireTableComponent
} from './components/questionnaire/questionnaire-data-table/questionnaire-table.component';
import {QuestionnaireService} from './services/questionnaire.service';
import {AuditLogModalComponent} from './components/questionnaire/audit-log-modal/audit-log-modal.component';
import {ProductInfoComponent} from './components/questionnaire/product-info/product-info.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {
    QuestionnaireClientInfo
} from './components/questionnaire/questionnaire-header/client-info-section/client-info-section.component';
import {ProductFilteringComponent} from './components/questionnaire/filtering-component/filtering-component.component';
import {MessageDialog} from './components/common/message-dialog/message-dialog.component';
import {ForgotPassword} from './components/login/forgot-password/forgot-password.component';
import {
    QuestionnaireTabContainerComponent
} from './components/questionnaire/tabs/questionaire-tab/tab-container/questionnaire-tab-container.component';
import {
    QuestionnaireTabTableComponent
} from './components/questionnaire/tabs/questionaire-tab/tab-data-table/questionnaire-tab-data-table.component';
import {
    QuestionnaireAutocomplete
} from './components/questionnaire/tabs/questionaire-tab/common/autocomplete/autocomplete.component';
import {
    QuestionnaireInput
} from './components/questionnaire/tabs/questionaire-tab/common/text-field/regular-input.component';
import {
    AutocompleteSearchPipe
} from './components/questionnaire/tabs/questionaire-tab/common/autocomplete/autocomplete-filter.pipe';
import {
    QuestionnaireAutocompleteMultiselect
} from './components/questionnaire/tabs/questionaire-tab/common/autocomplete-multiselect/autocomplete-multiselect.component';
import {
    AutocompleteMultiselectSearchPipe
} from './components/questionnaire/tabs/questionaire-tab/common/autocomplete-multiselect/autocomplete-filter.pipe';
import {ExemptionsComponent} from './components/questionnaire/exemptions/exemptions.component';
import {PoliciesComponent} from './components/questionnaire/questionnaire-header/policies/policies.component';
import {
    QuestionnaireHeaderComponent
} from './components/questionnaire/questionnaire-header/questionnaire-header.component';
import {
    RiskScoreTabContainerComponent
} from './components/questionnaire/tabs/risk-score/tab-container/risk-score-tab-container.component';
import {
    DocumentRequirementComponent
} from './components/questionnaire/document-requirement/document-requirement.component';
import {ArchiveComponent} from './components/questionnaire/document-requirement/archive/archive.component';
import {
    CustomCalendarHeader,
    DatepickerCustomHeaderExample,
} from './components/questionnaire/document-requirement/calendar/calendar.component';
import {
    AddNewDocument
} from './components/questionnaire/document-requirement/add-new-document-dialog/add-new-document.component';
import {
    UploadNewDocument
} from './components/questionnaire/document-requirement/new-document-upload/new-document-upload.component';
import {NgxDocViewerModule} from 'ngx-doc-viewer';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DndDirective} from './components/questionnaire/document-requirement/new-document-upload/dnd.directive';
import {CalendarHelper} from './components/questionnaire/document-requirement/calendar/calendar-helper';
import {
    DeleteArchiveDialogComponent
} from './components/questionnaire/document-requirement/archive/delete-archive-dialog/delete-archive-dialog.component';
import {
    ValidationPopup
} from './components/questionnaire/questionnaire-container/submit-validation-popup/validation-popup.component';
import {
    ApproveValidationPopup
} from './components/questionnaire/questionnaire-container/approve-validation-popup/validation-popup.component';
import {DocuLibraryContainerComponent} from './components/docu-library/docu-library-container.component';
import {
    DocuLibraryLeftPanelComponent
} from './components/docu-library/components/docu-lib-left-side-menu/docu-lib-left-panel.component';
import {
    DocuLibDataTableComponent
} from './components/docu-library/components/docu-lib-data-table/docu-lib-data-table.component';
import {DocuLibraryProfileTab} from './components/docu-library/components/tabs/profile/profile.component';
import {
    DocuLibraryHeaderComponent
} from './components/docu-library/components/docu-library-header/docu-library-header.component';
import {
    DocuLibraryProductsAndAccounts
} from './components/docu-library/components/tabs/products-and-accounts/products-and-accounts.component';
import {
    DocuLibraryRegulatorySubTab
} from './components/docu-library/components/tabs/profile/sub-tabs/regulatory/regulatory.component';
import {DocuLibraryExemptionTab} from './components/docu-library/components/tabs/exemptions/exemptions.component';
import {DocuLibraryService} from './services/docu-library.service';
import {DocuLibraryRiskTab} from './components/docu-library/components/tabs/risks/risks.component';
import {NoDataComponent} from "./components/docu-library/common/no-data-component/no-data.component";
import {
    AvatarUploaderComponent
} from './components/docu-library/components/docu-lib-left-side-menu/avatar-uploader/avatar-uploader.component';
import {
    DocuLibDocRequirementsComponent
} from './components/docu-library/components/tabs/docu-lib-doc-requirements/docu-lib-doc-requirements.component';
import {
    DocuLibProductsComponent
} from './components/docu-library/components/tabs/products-and-accounts/sub-tabs/docu-lib-products/docu-lib-products.component';
import {
    DocuLibAccountsComponent
} from './components/docu-library/components/tabs/products-and-accounts/sub-tabs/docu-lib-accounts/docu-lib-accounts.component';
import {
    MultyActionDialogComponent
} from './components/docu-library/components/tabs/products-and-accounts/components/multy-action-dialog/multy-action-dialog.component';
import {
    DeleteAccountDialogComponent
} from "./components/docu-library/components/tabs/products-and-accounts/components/delete-account-dialog/delete-archive-dialog.component";
import {SortIndicatorComponent} from './components/docu-library/common/sort-indicator/sort-indicator.component';
import {
    ActiveSortingIndicatorComponent
} from './components/docu-library/common/active-sorting-indicator/active-sorting-indicator.component';
import {
    BalanceFormatterDirectiveDirective
} from './components/docu-library/components/tabs/products-and-accounts/directives/balance-formatter-directive.directive';
import {
    DocuLibCustomPaginator
} from "./components/docu-library/components/docu-lib-custom-paginator/custom-paginator.component";
import {
    DocuLibAuditLogModalComponent
} from "./components/docu-library/components/tabs/products-and-accounts/components/docu-lib-audit-log-modal/audit-log-modal.component";
import {
    OnlyNumberDirective
} from './components/docu-library/components/tabs/products-and-accounts/components/multy-action-dialog/only-number.directive';
import {
    EffectiveDateValidationComponent
} from './components/questionnaire/document-requirement/effective-date-validation/effective-date-validation.component';
import {
    StructuresContainerComponent
} from './components/structures/structures-container/structures-container.component';
import {NgxGraphModule} from "@swimlane/ngx-graph";
import {
    CustomNodeComponent
} from './components/structures/structures-container/components/custom-node/custom-node.component';
import {
    CustomEdgeComponent
} from './components/structures/structures-container/components/custom-edge/custom-edge.component';
import {StructuresService} from "./services/structures.service";
import {
    AddNewStructureHeaderComponent
} from './components/structures/structures-container/components/add-new-structure-header/add-new-structure-header.component';
import {
    StructureSearchResultsComponent
} from './components/structures/structures-container/components/structure-search-results/structure-search-results.component';
import {
    StructuresSearchResultTableComponent
} from './components/structures/structures-container/components/structure-search-results/structures-search-result-table/structures-search-result-table.component';
import {RightTabComponent} from './components/structures/structures-container/components/right-tab/right-tab.component';
import {
    TabBlockWithHeaderComponent
} from './components/structures/structures-container/components/right-tab/tab-block-with-header/tab-block-with-header.component';
import {
    SearchResultsCustomPaginator
} from "./components/structures/structures-container/components/search-results-custom-paginator/custom-paginator.component";
import { DeleteConfirmationPopupComponent } from './components/structures/structures-container/components/delete-confirmation-popup/delete-confirmation-popup.component';
import { GeneralInfoTabContainerComponent } from './components/docu-library/components/tabs/profile/sub-tabs/general-info/general-info-tab-container/general-info-tab-container.component';
import { ReusableDataBlockWithHeaderComponent } from './components/docu-library/components/tabs/profile/sub-tabs/general-info/common/reusable-data-block-with-header/reusable-data-block-with-header.component';
import { RegulatoryTabsComponent } from './components/docu-library/components/tabs/profile/sub-tabs/regulatory/common/regulatory-tabs/regulatory-tabs.component';
import { SdlDataComponent } from './components/docu-library/components/tabs/profile/sub-tabs/regulatory/common/data-tabs/sdl-data/sdl-data.component';
import { RegulatoryBlockWithHeaderComponent } from './components/docu-library/components/tabs/profile/sub-tabs/regulatory/common/regulatory-block-with-header/regulatory-block-with-header.component';
import { RegulatorySubBlockWithHeaderComponent } from './components/docu-library/components/tabs/profile/sub-tabs/regulatory/common/regulatory-sub-block-with-header/regulatory-sub-block-with-header.component';
import { Df1DataComponent } from './components/docu-library/components/tabs/profile/sub-tabs/regulatory/common/data-tabs/df1-data/df1-data.component';
import { Df2DataComponent } from './components/docu-library/components/tabs/profile/sub-tabs/regulatory/common/data-tabs/df2-data/df2-data.component';
import { EmirDataComponent } from './components/docu-library/components/tabs/profile/sub-tabs/regulatory/common/data-tabs/emir-data/emir-data.component';
import { CcclDataComponent } from './components/docu-library/components/tabs/profile/sub-tabs/regulatory/common/data-tabs/cccl-data/cccl-data.component';
import { ConnectedCcclInputsComponent } from './components/docu-library/components/tabs/profile/sub-tabs/regulatory/common/connected-cccl-inputs/connected-cccl-inputs.component';
import { TaxComponent } from './components/docu-library/components/tabs/profile/sub-tabs/tax/tax.component';
import { ContactComponent } from './components/docu-library/components/tabs/profile/sub-tabs/contact/contact.component';
import { ContactDataTableComponent } from './components/docu-library/components/tabs/profile/sub-tabs/contact/common/contact-data-table/contact-data-table.component';
import { AddEditDialogComponent } from './components/docu-library/components/tabs/profile/sub-tabs/contact/common/add-edit-dialog/add-edit-dialog.component';
import {
    ContactDeleteConfirmationPopupComponent
} from "./components/docu-library/components/tabs/profile/sub-tabs/contact/common/delete-confirmation-popup/delete-confirmation-popup.component";
import { AddNewClientOrCaseComponent } from './components/docu-library/components/add-new-client-or-case/add-new-client-or-case.component';

@NgModule({
    declarations: [
        DndDirective,
        AppComponent,
        HeadComponent,
        LeftPanelComponent,
        HomeComponent,
        NewExcelEntrySelectComponent,
        ActionAssignDialog,
        DescopeAssignDialog,
        DataTable,
        SortingFilteringComponent,
        OutreachComponent,
        NameScreeningComponent,
        EscalationComponent,
        ApprovalsComponent,
        CompleteComponent,
        AnalyticsComponent,
        LoginComponent,
        LogoutComponent,
        DisplayColumnsDialogComponent,
        FilterByDialogComponent,
        SidebarTeamDropdown,
        InitialReviewComponent,
        DescopeComponent,
        DataValidationComponent,
        ReusableMultipurposeDialog,
        ConfirmationDialog,
        AssignFilterPipe,
        AutocompleteSearchPipe,
        DataTableFilterPipe,
        AuditLogDialog,
        AnalyticsDataTable,
        NestedDataTable,
        UserAccessManagementComponent,
        RescopeDialog,
        NotAllItemsProcessedDialog,
        CustomPaginator,
        SearchResultsCustomPaginator,
        DataChart,
        CalendarComponent,
        DataLineChart,
        TrendingTabComponent,
        DataPieChart,
        DataProgressBars,
        LoaderWithLogo,
        WelcomeComponent,
        ChipsMultiSelectComponent,
        ChangePasswordComponent,
        UserProfileComponent,
        StyledInputField,
        ChangeProfileAvatar,
        AvatarSelector,
        ProfilePhotoControl,
        NotificationCountComponent,
        ColumnChipsPopover,
        QuestionnaireContainerComponent,
        QuestionnaireTableComponent,
        AuditLogModalComponent,
        ProductInfoComponent,
        QuestionnaireClientInfo,
        ProductFilteringComponent,
        MessageDialog,
        ForgotPassword,
        QuestionnaireTabContainerComponent,
        RiskScoreTabContainerComponent,
        QuestionnaireTabTableComponent,
        QuestionnaireAutocomplete,
        QuestionnaireInput,
        QuestionnaireAutocompleteMultiselect,
        AutocompleteMultiselectSearchPipe,
        ExemptionsComponent,
        PoliciesComponent,
        QuestionnaireHeaderComponent,
        DocumentRequirementComponent,
        ArchiveComponent,
        DatepickerCustomHeaderExample,
        CustomCalendarHeader,
        AddNewDocument,
        UploadNewDocument,
        DeleteArchiveDialogComponent,
        ValidationPopup,
        ApproveValidationPopup,
        DocuLibraryContainerComponent,
        DocuLibraryLeftPanelComponent,
        DocuLibDataTableComponent,
        DocuLibraryProfileTab,
        DocuLibraryHeaderComponent,
        DocuLibraryProductsAndAccounts,
        DocuLibraryRegulatorySubTab,
        DocuLibraryExemptionTab,
        DocuLibraryRiskTab,
        NoDataComponent,
        AvatarUploaderComponent,
        DocuLibDocRequirementsComponent,
        DocuLibProductsComponent,
        DocuLibAccountsComponent,
        MultyActionDialogComponent,
        DeleteAccountDialogComponent,
        SortIndicatorComponent,
        ActiveSortingIndicatorComponent,
        BalanceFormatterDirectiveDirective,
        DocuLibCustomPaginator,
        DocuLibAuditLogModalComponent,
        OnlyNumberDirective,
        EffectiveDateValidationComponent,
        StructuresContainerComponent,
        CustomNodeComponent,
        CustomEdgeComponent,
        AddNewStructureHeaderComponent,
        StructureSearchResultsComponent,
        StructuresSearchResultTableComponent,
        RightTabComponent,
        TabBlockWithHeaderComponent,
        DeleteConfirmationPopupComponent,
        ContactDeleteConfirmationPopupComponent,
        GeneralInfoTabContainerComponent,
        ReusableDataBlockWithHeaderComponent,
        RegulatoryTabsComponent,
        SdlDataComponent,
        RegulatoryBlockWithHeaderComponent,
        RegulatorySubBlockWithHeaderComponent,
        Df1DataComponent,
        Df2DataComponent,
        EmirDataComponent,
        CcclDataComponent,
        ConnectedCcclInputsComponent,
        TaxComponent,
        ContactComponent,
        ContactDataTableComponent,
        AddEditDialogComponent,
        AddNewClientOrCaseComponent,
    ],
    imports: [
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatNativeDateModule,
        MatDatepickerModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatExpansionModule,
        MatIconModule,
        MatMenuModule,
        HttpClientModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatTableModule,
        RouterModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatCheckboxModule,
        LayoutModule,
        MatChipsModule,
        MatTooltipModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        NgxChartsModule,
        MatGridListModule,
        MatCardModule,
        NgbModule,
        MdePopoverModule,
        MatSnackBarModule,
        NgxSkeletonLoaderModule,
        InfiniteScrollModule,
        NgSelectModule,
        NgxDocViewerModule,
        DragDropModule,
        NgxGraphModule
    ],
    providers: [
        DatePipe,
        Helper,
        CalendarHelper,
        AuthGuard,
        AuthService,
        StatisticService,
        QuestionnaireService,
        DocuLibraryService,
        MenuService,
        UserService,
        StructuresService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        WebsocketService,
        NotificationService,
        AnalyticService,
        NgxImageCompressService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
