<div
  class="d-flex justify-center"
  style="position: relative"
  (click)="picker.open()"
>
  <div style="white-space: nowrap; position: absolute">
    {{ selectedDateString }}
  </div>
  <input
    [max]="today"
    readonly
    class="styled-input-doc-req"
    matInput
    [matDatepicker]="picker"
    placeholder="--/--/----"
    (dateChange)="dateChanged($event)"
    [value]="selectedDate"
    [ngStyle]="{ color: theme, opacity: 0 }"
    [ngClass]="{
      blue: theme === '#244CB2',
      orange: theme === '#ff754c',
      purple: theme === '#6C5DD3'
    }"
  />
  <mat-datepicker-toggle
    hidden
    matSuffix
    [for]="picker"
  ></mat-datepicker-toggle>
  <mat-datepicker
    #picker
    #datePicker
    [startAt]="createDate(selectedDate)"
    [calendarHeaderComponent]="exampleHeader"
  ></mat-datepicker>
</div>
