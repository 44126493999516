<div class="table-wrapper" style.min-height="35rem">
    <table mat-table [dataSource]="dataSource" [ngStyle]="{ opacity: isLoading ? 0.1 : 1 }">
        <ng-container *ngFor="let column of displayedColumns">
            <ng-container [matColumnDef]="column" *ngIf="column !== 'checkbox'; else checkbox">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="header-label">
                        {{ tableHeaderCells[column] }}
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="body-cell-value">
                        {{ element[column] || '-' }}
                    </div>
                </td>
            </ng-container>

            <ng-template #checkbox>
                <!-- Checkbox Column -->
                <ng-container matColumnDef="checkbox">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="header-label"></div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <div class="body-cell-value">
                            <mat-checkbox (change)="handleCheckboxChange(row)"  [checked]="row.isSelected"></mat-checkbox>
                        </div>
                    </td>
                </ng-container>
            </ng-template>
        </ng-container>

        <tr mat-header-row class="header-row-bg" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
