<!-- User info section -->
<div *ngIf="isNavOpened" class="d-flex collapse-menu">
  <div class="section-wrapper">
    <!-- opened sidenav avatar + skeleton -->
    <div class="avatar-border">
      <img
        *ngIf="!isProfileImgLoading"
        [ngStyle]="{
          'background-color': profileImgBackground,
          padding: menuData.ProfileUrl ? '0px' : '8px'
        }"
        mat-button
        class="side-user-icon"
        alt="user account"
        style="width: 52px; height: 52px"
        [src]="
          menuData.ProfileUrl
            ? menuData.ProfileUrl
            : './assets/icons/customer.png'
        "
      />
      <ngx-skeleton-loader
        count="1"
        appearance="circle"
        *ngIf="isProfileImgLoading"
        class="skeleton-loader"
        animation="progress-dark"
        [theme]="{
          height: '52px',
          width: '52px',
          background: '#eff1f6',
          margin: '0px'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>

  <!-- opened sidenav username area -->
  <div class="d-flex opened-username-area">
    <div class="user-info">
      <div style="font-family: Poppins-Regular; font-size: 11px">
        {{ userName }}
      </div>

      <!-- user role section -->
      <div class="user-role">
        <div>{{ userRoleMain }}{{ userRoleSecondary.length ? "," : "" }}</div>
        <div
          class="role-tooltip"
          #tooltip="matTooltip"
          *ngIf="userRoleSecondary"
          matTooltipPosition="right"
          [matTooltip]="userRoleSecondary"
        >
          and {{ userRoleSecondary.split(", ").length }} other
        </div>
      </div>
    </div>

    <!-- opened sidenav Collapse button -->
    <img
      class="opened-toggle-btn"
      (click)="onSidenavClose()"
      src="./assets/icons/collapse.svg"
    />
  </div>
</div>

<!-- Collapsed toggle button -->
<div
  *ngIf="!isNavOpened"
  class="d-flex collapsed-switch-wrapper"
  [class.collapsed-switch-wrapper-border]="!isNavOpened"
>
  <img
    *ngIf="!isNavOpened"
    (click)="onSidenavClose()"
    class="toggle-button-styles"
    src="./assets/icons/collapse.svg"
  />
</div>

<!-- collapsed user avatar -->
<div class="collapsed-avatar-wrapper" *ngIf="!isNavOpened">
  <div class="avatar-border">
    <img
      *ngIf="!isProfileImgLoading"
      [ngStyle]="{
        'background-color': profileImgBackground,
        padding: menuData.ProfileUrl ? '0px' : '8px'
      }"
      mat-button
      class="side-user-icon"
      alt="user account"
      style="width: 52px; height: 52px"
      [src]="
        menuData.ProfileUrl
          ? menuData.ProfileUrl
          : './assets/icons/customer.png'
      "
    />
    <ngx-skeleton-loader
      count="1"
      appearance="circle"
      *ngIf="isProfileImgLoading"
      class="skeleton-loader"
      animation="progress-dark"
      [theme]="{
        height: '52px',
        width: '52px',
        background: '#eff1f6',
        margin: '0px'
      }"
    ></ngx-skeleton-loader>
  </div>
</div>

<ng-container [ngSwitch]="isNavOpened">
  <!-- sidenav opened -->
  <ng-container *ngSwitchCase="true">
    <mat-nav-list class="sidemenu">
      <ng-container *ngFor="let tab of tabs">
        <a
          [ngStyle]="{
            'margin-top': tab.tabName === 'Analytics' ? '3rem' : '0'
          }"
          mat-list-item
          [routerLink]="[tab.routerLink]"
          [routerLinkActive]="['selected-item']"
          style="width: 100% !important; margin: 0.75rem 0px"
          #tooltip="matTooltip"
          matTooltipPosition="right"
          matTooltip="
            {{ tab.tabName }}
            {{ tab.numberOfItems ? '(' : '' }}
            {{ tab.numberOfItems }}
            {{ tab.numberOfItems ? ')' : '' }}
          "
          [matTooltipDisabled]="isNavOpened"
        >
          <div
            class="nav-caption"
            [routerLinkActive]="['nav-caption-selected']"
          >
            <div class="icon-text">
              <img
                routerLinkActive
                class="button-base"
                #rla="routerLinkActive"
                [ngClass]="{
                  'sidebar-icon-base': !tab.isAnalytics,
                  'icon-selected': rla.isActive && !tab.isAnalytics,
                  'sidebar-analytics-icon-base': tab.isAnalytics
                }"
                alt="analytics icon"
                src="{{
                  tab.isAnalytics && rla.isActive
                    ? tab.imgUrlHighlight
                    : tab.imgUrl
                }}"
              />

              <span
                *ngIf="isNavOpened"
                style="
                  font-size: 14px !important;
                  position: relative;
                  font-family: Poppins-Regular;
                "
              >
                {{ tab.tabName }}
                <img
                  alt="notification"
                  class="notif-base-style"
                  *ngIf="tab.showNotification"
                  src="./assets/icons/notification.svg"
                  [routerLinkActive]="['icon-selected']"
                  [ngClass]="{ 'sidebar-icon-base': !tab.isAnalytics }"
                />
              </span>
            </div>
            <div *ngIf="isNavOpened && !tab.isAnalytics" class="wrapper">
              <ng-container *ngIf="menuDataLoading; else counter">
                <ngx-skeleton-loader
                  count="1"
                  class="skeleton-loader"
                  animation="progress-dark"
                  [theme]="{
                    height: '29px',
                    width: '29px',
                    background: '#8f95b2',
                    margin: '12px 0 0 0',
                    padding: '5px',
                    'border-radius': '0'
                  }"
                ></ngx-skeleton-loader>
              </ng-container>
              <ng-template #counter>
                <div
                  class="section-records-amount"
                  [routerLinkActive]="['selected-section-records-amount']"
                >
                  {{ tab.numberOfItems ? tab.numberOfItems : 0 }}
                </div>
              </ng-template>
            </div>
          </div>
        </a>
      </ng-container>
    </mat-nav-list>
  </ng-container>

  <!-- sidenav collapsed -->
  <ng-container *ngSwitchCase="false">
    <mat-nav-list class="sidemenu">
      <ng-container *ngFor="let tab of tabs">
        <a
          mat-list-item
          #tooltip="matTooltip"
          matTooltipPosition="right"
          [routerLink]="[tab.routerLink]"
          [matTooltipDisabled]="isNavOpened"
          [routerLinkActive]="['selected-item']"
          style="width: 100% !important; margin: 0.75rem 0px"
          matTooltip="
            {{ tab.tabName }}
            {{ tab.numberOfItems ? '(' : '' }}
            {{ tab.numberOfItems }}
            {{ tab.numberOfItems ? ')' : '' }}
          "
        >
          <div
            class="nav-caption-collapsed"
            [routerLinkActive]="['nav-caption-selected-collapsed']"
          >
            <div
              class="icon-text-collapsed"
              [ngClass]="{
                'icon-background-collapsed': rla.isActive && !tab.isAnalytics,
                'analytics-icon-background-collapsed':
                  rla.isActive && tab.isAnalytics
              }"
            >
              <img
                routerLinkActive
                class="no-padding"
                #rla="routerLinkActive"
                [ngClass]="{
                  'sidebar-icon-base': !tab.isAnalytics,
                  'icon-selected-collapsed': rla.isActive && !tab.isAnalytics,
                  'sidebar-analytics-icon-base': tab.isAnalytics
                }"
                src="{{
                  tab.isAnalytics && rla.isActive
                    ? tab.imgUrlHighlightCollapsed
                    : tab.imgUrl
                }}"
              />
            </div>
            <div *ngIf="isNavOpened && !tab.isAnalytics" class="wrapper">
              <div
                class="section-records-amount"
                [routerLinkActive]="['selected-section-records-amount']"
              >
                {{ tab.numberOfItems ? tab.numberOfItems : 0 }}
              </div>
            </div>
          </div>
        </a>
      </ng-container>
    </mat-nav-list>
  </ng-container>
</ng-container>

<!-- Teams dropdown -->
<div>
  <div class="d-flex teams-label-box" *ngIf="!isNavOpened">
    <span class="teams-label">Teams</span>
  </div>
  <ng-container *ngFor="let team of teams; let index = index">
    <sidebar-team
      #team_dropdown
      [teamData]="team"
      [sidenavOpened]="isNavOpened"
      (openSidenavToShowTeam)="showTeam()"
    ></sidebar-team>
  </ng-container>
</div>
