<div class="d-flex flex-col">
    <div class="d-flex justify-center align-center loader-block" *ngIf="isLoading; else tabContent">
        <mat-spinner [diameter]="70"></mat-spinner>
    </div>

    <ng-template #tabContent>
        <app-tab-block-with-header sectionLabel="PROFILE">
            <form [formGroup]="profileForm" class="d-flex w-100">
                <div class="d-flex w-100 flex-col">
                    <!-- CID -->
                    <div class="control">
                        <label for="cid">CID</label>
                        <input readonly [value]="profileForm.controls['cid']?.value" type="text" id="cid"
                               formControlName="cid">
                    </div>

                    <ng-container *ngIf="profileForm.controls['entityType']?.value === 'Individual'; else entityName">
                        <!-- First Name -->
                        <div class="control">
                            <label for="firstName">First Name</label>
                            <input type="text" id="firstName" formControlName="firstName">
                        </div>

                        <!-- Middle Name -->
                        <div class="control">
                            <label for="middleName">Middle Name</label>
                            <input type="text" id="middleName" formControlName="middleName">
                        </div>

                        <!-- Last Name -->
                        <div class="control">
                            <label for="lastName">Last Name</label>
                            <input type="text" id="lastName" formControlName="lastName">
                        </div>
                    </ng-container>

                    <ng-template #entityName>
                        <!-- Entity Name -->
                        <div class="control">
                            <label for="entityName">Legal Name</label>
                            <input type="text" id="entityName" formControlName="entityName">
                        </div>
                    </ng-template>

                    <!-- Client/Association -->
                    <div class="control no-pointer-events">
                        <label for="type" class="entity-name-label">Client/Association</label>
                        <mat-select
                            id="type"
                            disableOptionCentering
                            aria-placeholder="Please Select"
                            placeholder="Please Select"
                            class="right-tab-select wiam-select entity-type"
                            panelClass="wiam-select-panel-singl"
                            formControlName="type"
                        >
                            <mat-option *ngFor="let type of commonData?.ClientAssociationTypes" [value]="type">
                                <span>{{ type }}</span>
                            </mat-option>
                        </mat-select>
                    </div>

                    <!-- Street Address -->
                    <div class="control">
                        <label for="streetAddress" class="entity-name-label">Street Address</label>
                        <input id="streetAddress" class="entity-name" formControlName="streetAddress">
                    </div>

                    <!-- Country -->
                    <div class="control">
                        <label for="country" class="entity-name-label">Country</label>
                        <input id="country" class="entity-name" formControlName="country">
                    </div>

                    <!-- State or Province -->
                    <div class="control">
                        <label for="stateOrProvince" class="entity-name-label">State/Province</label>
                        <input id="stateOrProvince" class="entity-name" formControlName="stateOrProvince">
                    </div>

                    <!-- Postal Code -->
                    <div class="control">
                        <label for="postalCode" class="entity-name-label">Postal Code</label>
                        <input id="postalCode" class="entity-name" formControlName="postalCode">
                    </div>

                    <!-- Profile Type -->
                    <div class="control no-pointer-events">
                        <label for="entityType" class="entity-name-label">Profile Type</label>
                        <mat-select
                            id="entityType"
                            disableOptionCentering
                            aria-placeholder="Please Select"
                            placeholder="Please Select"
                            class="right-tab-select wiam-select entity-type"
                            panelClass="wiam-select-panel-singl"
                            formControlName="entityType"
                        >
                            <mat-option *ngFor="let type of commonData?.EntityTypes" [value]="type">
                                <span>{{ type }}</span>
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </form>
        </app-tab-block-with-header>

        <app-tab-block-with-header sectionLabel="IDENTIFICATION INFORMATION">
            <form [formGroup]="identificationInformationForm" class="d-flex w-100">
                <div class="d-flex w-100 flex-col">
                    <!-- ID Type -->
                    <div class="control">
                        <label for="idType">ID Type</label>
                        <mat-select
                            id="idType"
                            disableOptionCentering
                            aria-placeholder="Please Select"
                            placeholder="Please Select"
                            class="right-tab-select wiam-select entity-type"
                            panelClass="wiam-select-panel-singl"
                            formControlName="idType"
                        >
                            <ng-container
                                *ngIf="profileForm.controls['entityType']?.value === 'Individual'; else entityTypes">
                                <mat-option *ngFor="let type of commonData?.IdentificationTypesForIndividual"
                                            [value]="type">
                                    <span>{{ type }}</span>
                                </mat-option>
                            </ng-container>
                            <ng-template #entityTypes>
                                <mat-option *ngFor="let type of commonData?.IdentificationTypesForEntity"
                                            [value]="type">
                                    <span>{{ type }}</span>
                                </mat-option>
                            </ng-template>
                        </mat-select>
                    </div>

                    <!-- Other -->
                    <div class="control" *ngIf="identificationInformationForm.controls['idType']?.value === 'Other'">
                        <label for="other">Other (Specify)</label>
                        <input type="text" id="other" formControlName="other">
                    </div>

                    <ng-container
                        *ngIf="profileForm.controls['entityType']?.value === 'Individual'; else businessNumber">
                        <!-- ID Number -->
                        <div class="control">
                            <label for="idNumber">Id Number</label>
                            <input type="number" id="idNumber" formControlName="idNumber">
                        </div>
                    </ng-container>

                    <ng-template #businessNumber>
                        <!-- Business Number -->
                        <div class="control">
                            <label for="businessNumber">Business Number</label>
                            <input type="number" id="businessNumber" formControlName="businessNumber">
                        </div>
                    </ng-template>

                    <!-- Country of Issue -->
                    <div class="control">
                        <label for="countryOfIssue">Country of Issue</label>
                        <input type="text" id="countryOfIssue" formControlName="countryOfIssue">
                    </div>

                    <!-- Expiry Date -->
                    <ng-container *ngIf="profileForm.controls['entityType']?.value === 'Individual'">
                        <div class="control" style="position: relative">
                            <label for="expiryDate">Expiry Date</label>
                            <input
                                style="padding-left: 4rem"
                                id="expiryDate"
                                [matDatepicker]="picker"
                                formControlName="expiryDate"
                            >
                            <mat-datepicker-toggle
                                [for]="picker"
                                matIconSuffix
                                class="custom-toggle"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                    </ng-container>

                    <!-- File Upload -->
                    <div class="control">
                        <div
                            (click)="file.click()"
                            class="file-selection-area"
                            [ngStyle]="{ opacity: isLoading ? 0 : 1 }"
                            Dnd
                            (fileDropped)="onDrop($event)"
                        >
                            <input
                                #file
                                hidden
                                multiple
                                type="file"
                                id="input-file-id"
                                (change)="processFiles($event)"
                                accept=".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .eml', .xlsm, .xlsb"
                            />
                            <div class="label-img-wrapper d-flex flex-column justify-center align-center">
                                <img
                                    src="./../assets/icons/right-tab-file-upload.svg"
                                    class="img-upload"
                                    alt="file-upload"
                                />
                                <label class="md-button md-raised md-primary label cursor-pointer">
                                    Drop Document
                                </label>
                            </div>
                        </div>

                        <ng-container *ngIf="identificationInformationForm.controls['documentUpload']?.value">
                            <div class="d-flex align-center">
                                <a class="text-decoration-none"
                                   [href]="identificationInformationForm.controls['documentUpload'].value">
                                    <div class="d-flex justify-start align-center;"
                                         style="padding: 0.5rem 0; color: #4d4e4e; align-items: center">
                                        <mat-icon style="color: #4d4e4e"> insert_drive_file</mat-icon>
                                        Document attached
                                    </div>
                                </a>
                                <img style="margin-left: 0.5rem; cursor: pointer;" alt="remove structure icon"
                                     (click)="handleRemoveAttachedDocument()"
                                     src="../assets/icons/remove-structure.svg">
                            </div>
                        </ng-container>
                    </div>

                    <ng-container
                        *ngIf="profileForm.controls['entityType'].value === 'Individual'; else natureOfBusiness">
                        <!-- Occupation -->
                        <div class="control">
                            <label for="occupation" class="entity-name-label">Occupation</label>
                            <textarea type="text" id="occupation" formControlName="occupation"></textarea>
                        </div>
                    </ng-container>

                    <ng-template #natureOfBusiness>
                        <!-- Nature of Business -->
                        <div class="control">
                            <label for="natureOfBusiness" class="entity-name-label">Nature of Business</label>
                            <textarea type="text" id="natureOfBusiness" formControlName="natureOfBusiness"></textarea>
                        </div>
                    </ng-template>

                </div>
            </form>
        </app-tab-block-with-header>

        <app-tab-block-with-header sectionLabel="RELATIONSHIP">
            <form [formGroup]="relationshipForm" class="d-flex w-100"
                  [class.no-pointer-events]="isRelationshipFormReadOnly">
                <div class="d-flex w-100 flex-col">
                    <!-- Relationship Type -->
                    <div class="control" [class.no-pointer-events]="isRelationshipFormReadOnly">
                        <label for="relationshipType">Relationship Type</label>
                        <mat-select
                            multiple
                            id="relationshipType"
                            disableOptionCentering
                            aria-placeholder="Please Select"
                            placeholder="Please Select"
                            class="right-tab-select wiam-select entity-type"
                            panelClass="wiam-select-panel-singl"
                            formControlName="relationshipType"
                            [disabled]="isRelationshipFormReadOnly"
                        >
                            <mat-option *ngFor="let type of commonData?.RelationshipTypes" [value]="type">
                                <span>{{ type }}</span>
                            </mat-option>
                        </mat-select>
                    </div>

                    <!-- Other -->
                    <div class="control" *ngIf="relationshipForm.controls['relationshipType'].value === 'Other'">
                        <label for="otherRelationshipType">Other (Specify)</label>
                        <input [class.no-pointer-events]="isRelationshipFormReadOnly"
                               [disabled]="isRelationshipFormReadOnly" type="text" id="otherRelationshipType"
                               formControlName="other">
                    </div>

                    <!-- Role -->
                    <div class="control">
                        <label for="role">Role</label>
                        <input [class.no-pointer-events]="isRelationshipFormReadOnly"
                               [disabled]="isRelationshipFormReadOnly" type="text" id="role" formControlName="role">
                    </div>

                    <!-- Ownership Type -->
                    <div class="control">
                        <label for="countryOfIssue">Ownership Type</label>
                        <mat-select
                            multiple
                            id="ownershipType"
                            disableOptionCentering
                            aria-placeholder="Please Select"
                            placeholder="Please Select"
                            class="right-tab-select wiam-select entity-type"
                            panelClass="wiam-select-panel-singl"
                            formControlName="ownershipType"
                            [class.disabled-ownership-type]="
                                relationshipForm.controls['role'].value &&
                                relationshipForm.controls['role'].value !== 'Owner'
                            "
                            [disabled]="
                                (relationshipForm.controls['role'].value &&
                                relationshipForm.controls['role'].value !== 'Owner') || isRelationshipFormReadOnly
                            "
                        >
                            <mat-option *ngFor="let type of commonData?.OwnershipTypes" [value]="type">
                                <span>{{ type }}</span>
                            </mat-option>
                        </mat-select>
                    </div>

                    <!-- Ownership Percentage -->
                    <div class="control">
                        <label for="ownershipPercentage">Ownership Percentage</label>
                        <input
                            type="number"
                            [max]="100"
                            [min]="1"
                            id="ownershipPercentage"
                            formControlName="ownershipPercentage"
                            [class.disabled-ownership-type]="
                               (relationshipForm.controls['role'].value && relationshipForm.controls['role'].value !== 'Owner') || isRelationshipFormReadOnly
                            "
                            [readOnly]="
                                (relationshipForm.controls['role'].value && relationshipForm.controls['role'].value !== 'Owner') || isRelationshipFormReadOnly
                            "
                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                    </div>

                    <!-- Ownership Range -->
                    <div class="control">
                        <label for="ownershipRange">Ownership Range</label>
                        <mat-select
                            id="ownershipRange"
                            disableOptionCentering
                            aria-placeholder="Please Select"
                            placeholder="Please Select"
                            class="right-tab-select wiam-select entity-type"
                            panelClass="wiam-select-panel-singl"
                            formControlName="ownershipRange"
                            [class.disabled-ownership-type]="
                                (relationshipForm.controls['role'].value && relationshipForm.controls['role'].value !== 'Owner') ||
                                relationshipForm.controls['ownershipPercentage'].value || isRelationshipFormReadOnly
                            "
                            [disabled]=" (
                                (relationshipForm.controls['role'].value && relationshipForm.controls['role'].value !== 'Owner')) ||
                                relationshipForm.controls['ownershipPercentage'].value || isRelationshipFormReadOnly"
                        >
                            <mat-option *ngFor="let range of commonData?.OwnershipRanges" [value]="range">
                                <span>{{ range }}</span>
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </form>
        </app-tab-block-with-header>

        <app-tab-block-with-header sectionLabel="SCREENING OUTCOME">
            <form [formGroup]="screeningOutcomeForm" class="d-flex w-100">
                <div class="d-flex w-100 flex-col">
                    <!-- PEP Status -->
                    <div class="control">
                        <label for="pepStatus">PEP Status</label>
                        <mat-select
                            id="pepStatus"
                            disableOptionCentering
                            aria-placeholder="Please Select"
                            placeholder="Please Select"
                            class="right-tab-select wiam-select entity-type"
                            panelClass="wiam-select-panel-singl"
                            formControlName="pepStatus"
                        >
                            <mat-option *ngFor="let pep of commonData?.PEPStatuses" [value]="pep">
                                <span>{{ pep }}</span>
                            </mat-option>
                        </mat-select>
                    </div>

                    <!-- Negative News -->
                    <div class="control">
                        <label for="role">Negative News Type</label>
                        <mat-select
                            multiple
                            id="negativeNews"
                            disableOptionCentering
                            aria-placeholder="Please Select"
                            placeholder="Please Select"
                            class="right-tab-select wiam-select entity-type"
                            panelClass="wiam-select-panel-singl"
                            formControlName="negativeNews"
                        >
                            <mat-option *ngFor="let news of commonData?.NegativeNewsTypes" [value]="news">
                                <span>{{ news }}</span>
                            </mat-option>
                        </mat-select>
                    </div>

                    <!-- Other -->
                    <div class="control" *ngIf="screeningOutcomeForm.controls['negativeNews'].value === 'Other'">
                        <label for="otherScreeningOutcome">Other (Specify)</label>
                        <input type="text" id="otherScreeningOutcome" formControlName="other">
                    </div>

                    <!-- Screening Assessment -->
                    <div class="control">
                        <label for="screeningAssessment">Screening Assessment</label>
                        <mat-select
                            id="screeningAssessment"
                            disableOptionCentering
                            aria-placeholder="Please Select"
                            placeholder="Please Select"
                            class="right-tab-select wiam-select entity-type"
                            panelClass="wiam-select-panel-singl"
                            formControlName="screeningAssessment"
                        >
                            <mat-option *ngFor="let assessment of commonData?.ScreeningAssessments"
                                        [value]="assessment">
                                <span>{{ assessment }}</span>
                            </mat-option>
                        </mat-select>
                    </div>

                    <!-- Describe Hit Result -->
                    <div class="control">
                        <label for="describeHitResult">Describe Hit Result</label>
                        <textarea type="text" id="describeHitResult" formControlName="describeHitResult"></textarea>
                    </div>
                </div>
            </form>
        </app-tab-block-with-header>
    </ng-template>
</div>
