<div *ngIf="loading" class="loader-box">
  <mat-spinner></mat-spinner>
</div>
<div
  *ngIf="selectedCommentId > 0"
  class="overlay-background"
  (click)="closeCommentSection()"
></div>
<div
  class="d-flex table-container"
  style="width: 100%; padding-bottom: 1rem; flex-direction: column"
  *ngIf="!isChildRouteActive; else child"
>
  <div class="actions-wrapper">
    <button mat-button class="action-base" (click)="navigateToChildRoute()">
      <div class="d-flex">
        <img src="../../../../assets/icons/archive.svg" alt="archive icon" />
        <span>Archive</span>
      </div>
    </button>
    <button mat-button class="action-base" (click)="handleOpenAuditLog()">
      Audit Log
    </button>

    <button class="add-btn" *ngIf="!loading" mat-button (click)="openDialog()">
      <img class="plus-icon" src="../../../../assets/icons/plus.svg" />
    </button>
  </div>

  <div *ngIf="dataSource.length === 0 && !loading">
    <div class="d-flex justify-center no-data-box-1">
      <div class="d-flex flex-column align-center" rou>
        <img
          alt="no data"
          style="width: 100px"
          src="../../../../assets/empty.png"
        />
        <div class="d-flex justify-center no-data">No data found</div>
      </div>
    </div>
  </div>
  <form
    class="document-requirement-form"
    [ngStyle]="{ opacity: !loading && dataSource.length > 0.5 ? 1 : 0 }"
  >
    <div class="table-wrapper">
      <table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8 w-100 document-requirements-table"
      >
        <!-- Checkbox Column -->
        <ng-container matColumnDef="checkbox">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox disabled [checked]="false"> </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox disabled [checked]="row.IsSelected"> </mat-checkbox>
          </td>
        </ng-container>

        <!-- ID -->
        <ng-container matColumnDef="Id">
          <th mat-header-cell *matHeaderCellDef class="list-of-requirement-th">
            <div class="d-flex">
              <div class="d-flex head-sort-wrapper">ID</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="id">
            {{ element.Id }}
          </td>
        </ng-container>

        <!-- Category -->
        <ng-container matColumnDef="DocumentCategory">
          <th mat-header-cell *matHeaderCellDef class="list-of-requirement-th">
            <div class="d-flex">
              <div class="d-flex head-sort-wrapper">Category</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="category">
            <div class="no-wrap-value">
              {{ element.DocumentCategory }}
            </div>
          </td>
        </ng-container>

        <!-- List Of Requirements -->
        <ng-container matColumnDef="DocumentRequirement">
          <th mat-header-cell *matHeaderCellDef class="list-of-requirement-th">
            <div class="d-flex">
              <div class="d-flex head-sort-wrapper">List Of Requirements</div>
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="list-of-requirements"
            style="background: transparent"
            (click)="openDocumentPreview(element.DocumentUrl)"
          >
            <div class="no-wrap-value">
              {{ element.DocumentRequirement }}
            </div>
          </td>
        </ng-container>

        <!-- Action -->
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef class="list-of-requirement-th">
            <div class="d-flex justify-center">
              <div class="d-flex head-sort-wrapper">Action</div>
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            class="action"
            style="background: transparent"
          >
            <div class="wrapper" [ngClass]="element.Action">
              <button
                class="arrow-btn"
                (click)="changeRequirementAction(i, element.Action, false)"
              >
                <mat-icon class="icon">keyboard_arrow_left</mat-icon>
              </button>
              <div class="label">
                <span>{{ element.Action }}</span>
              </div>
              <button
                class="arrow-btn"
                (click)="changeRequirementAction(i, element.Action, true)"
              >
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <!-- Validity -->
        <ng-container matColumnDef="Validity">
          <th mat-header-cell *matHeaderCellDef class="validity-th">
            <div class="d-flex justify-center">
              <div class="d-flex head-sort-wrapper">Validity</div>
            </div>
          </th>

          <td mat-cell *matCellDef="let element" class="validity">
            <div>
              {{ element.Validity }}
            </div>
          </td>
        </ng-container>

        <!-- Uploaded On -->
        <ng-container matColumnDef="UploadedOn">
          <th mat-header-cell *matHeaderCellDef class="uploaded-on-th">
            <div class="d-flex align-center flex-column">
              <div class="d-flex head-sort-wrapper">Uploaded On</div>
              <div class="d-flex justify-center date-format-label">
                DD/MM/YY
              </div>
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="uploaded-on"
            style="background: transparent"
          >
            <div class="wrapper no-pointer-events">
              <datepicker-custom-header-example
                theme="#244CB2"
                [selectedDate]="element.UploadedOn"
              ></datepicker-custom-header-example>
            </div>
          </td>
        </ng-container>

        <!-- Effective Date -->
        <ng-container matColumnDef="EffectiveDate">
          <th mat-header-cell *matHeaderCellDef class="effective-date-th">
            <div class="d-flex align-center flex-column">
              <div class="d-flex head-sort-wrapper">Effective Date</div>
              <div class="d-flex justify-center date-format-label">
                DD/MM/YY
              </div>
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            class="effective-date"
            style="background: transparent"
          >
            <div class="d-flex effective-date-wrapper">
              <div
                class="wrapper"
                [class.no-pointer-events]="element.IsEffectiveDateDisabled"
              >
                <datepicker-custom-header-example
                  theme="#ff754c"
                  [elementIdx]="i"
                  [selectedDate]="element.EffectiveDate"
                  (dateChanged)="effectiveDateChanged($event)"
                ></datepicker-custom-header-example>
              </div>
              <div
                class="required-indicator"
                matTooltipPosition="above"
                matTooltip="Choose an Effective Date for the document"
                *ngIf="element.DocumentUrl && !element.EffectiveDate"
              >
                *
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Valid Until -->
        <ng-container matColumnDef="ValidUntil">
          <th mat-header-cell *matHeaderCellDef class="valid-until-th">
            <div class="d-flex align-center flex-column">
              <div class="d-flex head-sort-wrapper">Valid Until</div>
              <div class="d-flex justify-center date-format-label">
                DD/MM/YY
              </div>
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="valid-until no-pointer-events"
            style="background: transparent"
          >
            <div class="wrapper">
              <div
                *ngIf="
                  element.DocumentUrl &&
                    element.ValidityPeriod === 0 &&
                    element.EffectiveDate;
                  else datePicker
                "
              >
                Null
              </div>

              <ng-template #datePicker>
                <datepicker-custom-header-example
                  theme="#6C5DD3"
                  [selectedDate]="element.ValidUntil"
                ></datepicker-custom-header-example>
              </ng-template>
            </div>
          </td>
        </ng-container>

        <!-- Upload Document -->
        <ng-container matColumnDef="UploadDocument">
          <th mat-header-cell *matHeaderCellDef class="upload-document-th">
            <div class="d-flex justify-center">
              <div class="d-flex head-sort-wrapper">Upload Document</div>
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="upload-document"
            style="background: transparent"
          >
            <div class="text-center">
              <button
                mat-button
                [disabled]="element.isLocked"
                (click)="handleOpenUploadNewDoc(element)"
              >
                <img src="./../../../../assets/icons/upload-icon.svg" />
              </button>
              <div class="no-file" *ngIf="!element.DocumentUrl">
                No file uploaded
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Comment -->
        <ng-container matColumnDef="Comment">
          <th mat-header-cell *matHeaderCellDef class="comment-th">
            <div class="d-flex">
              <div class="d-flex head-sort-wrapper">Comment</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let element; let i = index" class="comment">
            <div class="text-center wrapper" style="position: relative">
              <div
                class="locked-text-area"
                *ngIf="element.isLocked; else textArea"
                (click)="handleOpenCommentPreview(element)"
              >
                {{
                  element.Comment ? element.Comment : "Type your comment here"
                }}
              </div>

              <div
                class="expanded-comment"
                *ngIf="element.Id === selectedCommentId && element.Comment"
              >
                {{ element.Comment }}
              </div>
              <ng-template #textArea>
                <textarea
                  [value]="element.Comment"
                  (input)="onCommentChange($event, i)"
                  [disabled]="element.isLocked"
                  class="textarea"
                  rows="1"
                  placeholder="Type your comment here"
                ></textarea>
              </ng-template>
            </div>
          </td>
        </ng-container>

        <!-- EditLock -->
        <ng-container matColumnDef="EditLock">
          <th mat-header-cell *matHeaderCellDef class="edit-lock-th">
            <div class="d-flex justify-center" (click)="handleLockAll()">
              <div class="d-flex head-sort-wrapper">
                <img
                  *ngIf="checkIfAllRowsLocked()"
                  src="./../../../../assets/icons/lock.svg"
                />
                <img
                  *ngIf="!checkIfAllRowsLocked()"
                  src="./../../../../assets/icons/green-lock.svg"
                />
              </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="edit-lock">
            <div class="text-center">
              <img
                *ngIf="element.isLocked"
                (click)="element.isLocked = false"
                src="./../../../../assets/icons/lock.svg"
              />
              <img
                *ngIf="!element.isLocked"
                (click)="element.isLocked = true"
                src="./../../../../assets/icons/green-lock.svg"
              />
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{
            selected: row.IsSelected
          }"
        ></tr>
      </table>
    </div>
    <div *ngIf="isPreviewVisible" class="preview">
      <app-loader-with-logo
        *ngIf="isPreviewDataLoading"
        [isLoading]="true"
        [isFullScreen]="false"
      ></app-loader-with-logo>
      <ngx-doc-viewer
        [ngStyle]="{ opacity: isPreviewDataLoading ? 0 : 1 }"
        [url]="documentUrl"
        viewer="google"
        style="width: 100%; height: 100%"
        (loaded)="previewDataLoaded()"
      ></ngx-doc-viewer>
    </div>
  </form>
  <div *ngIf="isPreviewVisible" class="d-flex actions-wrapper preview-actions">
    <button
      mat-button
      class="action-base exit"
      (click)="closeDocumentPreview()"
    >
      Exit
    </button>
    <a mat-button class="action-base download m-l-22" [href]="documentUrl"
      >Download</a
    >
  </div>
  <div
    *ngIf="!isPreviewVisible"
    class="d-flex justify-content-end"
    style="padding-top: 1rem"
  >
    <div class="save-btn">
      <button
        class="primary-purple-btn"
        (click)="saveRequirements()"
        [ngClass]="{ disabled: !isTableDataChanged }"
      >
        Save
      </button>
    </div>
    <div class="submit-btn ml-10">
      <button
        class="primary-purple-btn"
        (click)="submitRequirements()"
        [ngClass]="{ disabled: !isSubmitEnabled || isTableDataChanged }"
      >
        Submit
      </button>
    </div>
  </div>
</div>

<ng-template #child>
  <div class="d-flex actions-wrapper">
    <button mat-button class="action-base" (click)="handleOpenAuditLog()">
      Audit Log
    </button>
    <button mat-button class="action-base" (click)="navigateToParentRoute()">
      List Of Requirements
    </button>
  </div>

  <router-outlet></router-outlet>

  <div *ngIf="isPreviewOpened" class="d-flex actions-wrapper preview-actions">
    <button mat-button class="action-base exit" (click)="handleClose()">
      Exit
    </button>

    <a
      mat-button
      class="action-base download m-l-22"
      [href]="archiveDocumentUrl"
    >
      Download
    </a>
  </div>
</ng-template>
