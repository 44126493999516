import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'assignSearch' })
export class AssignFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }

    if (!searchText) {
      return items;
    }

    searchText = searchText.toLocaleLowerCase();

    return items.filter((it) => {
      return (
        it.FirstName.toLocaleLowerCase().includes(searchText) ||
        it.LastName.toLocaleLowerCase().includes(searchText)
      );
    });
  }
}
