<div class="d-flex w-100 justify-center align-center spinner-box" *ngIf="isLoading; else dataBlock">
    <mat-spinner [diameter]="70"></mat-spinner>
</div>

<ng-template #dataBlock>
    <ng-container *ngIf="dataSource; else noDataBlock">
        <div class="d-flex w-100 tab-three-column-box">
            <form [formGroup]="ccclForm" class="d-flex w-100">
                <!-- I Column -->
                <div class="d-flex flex-column data-column">
                    <!-- Clearing Agency Participant -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="clearingAgencyParticipant">Clearing Agency Participant</label>
                        <ng-select
                            id="clearingAgencyParticipant"
                            formControlName="clearingAgencyParticipant"
                            [items]="dropdownData ? dropdownData.ClearingAgencyParticipants : null"
                        ></ng-select>
                    </div>

                    <!-- Clearing Participant Affiliate -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="clearingParticipantAffiliate">Clearing Participant Affiliate</label>
                        <ng-select
                            id="clearingParticipantAffiliate"
                            formControlName="clearingParticipantAffiliate"
                            [items]="dropdownData ? dropdownData.ClearingParticipantAffiliates : null"
                        ></ng-select>
                    </div>

                    <!-- Large Notional Counterparty -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="largeNotionalCounterparty">Large Notional Counterparty</label>
                        <ng-select
                            id="largeNotionalCounterparty"
                            formControlName="largeNotionalCounterparty"
                            [items]="dropdownData ? dropdownData.LargeNotionalCounterparties : null"
                        ></ng-select>
                    </div>

                    <!-- Notional Threshold Information -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="notionalThresholdInformation">Notional Threshold Information</label>
                        <ng-select
                            id="notionalThresholdInformation"
                            formControlName="notionalThresholdInformation"
                            [items]="dropdownData ? dropdownData.NotionalThresholdInformations : null"
                        ></ng-select>
                    </div>

                    <!-- Month/Year Exceeded Month-End Gross Notional Amount -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="monthYearExceededMonthEndGrossNotionalAmount">
                            Month/Year Exceeded Month-End Gross Notional Amount
                        </label>
                        <input
                            id="monthYearExceededMonthEndGrossNotionalAmount"
                            formControlName="monthYearExceededMonthEndGrossNotionalAmount"
                        >
                    </div>

                    <!--  Clearing Agency Participants That Have Clearing Participant Affiliates -->
                    <div class="control-box input-width padding-right padding-bottom">
                        <label for="clearingAgencyParticipantsThatHaveClearingParticipantAffiliates">
                            Clearing Agency Participants That Have Clearing Participant Affiliates
                        </label>
                        <ng-select
                            id="clearingAgencyParticipantsThatHaveClearingParticipantAffiliates"
                            formControlName="clearingAgencyParticipantsThatHaveClearingParticipantAffiliates"
                            [items]="dropdownData ? dropdownData.ClearingAgencyParticipantsThatHaveClearingParticipantAffiliates : null"
                        >
                        </ng-select>
                    </div>
                </div>

                <!-- II Column -->
                <div class="d-flex flex-column data-column">
                    <button class="add-new" (click)="addNewClearingParticipant()">
                        <div class="d-flex align-center justify-between">
                            <div class="label">ADD CLEARING PARTICIPANT AFFILIATES</div>
                            <div class="d-flex justify-center align-center plus-sign">+</div>
                        </div>
                    </button>

                    <ng-container *ngFor="let arrayItem of clearingParticipantAffiliatesArray.controls; let i = index;">
                        <app-connected-cccl-inputs
                            [pairId]="i"
                            [abstractCcclControl]="arrayItem"
                        ></app-connected-cccl-inputs>
                    </ng-container>
                </div>

                <!-- III Column -->
                <div class="d-flex flex-column data-column">
                    <!-- Non-Canadian Local Counterparty -->
                    <div class="control-box w-100">
                        <label for="nonCanadianLocalCounterparty">Non-Canadian Local Counterparty</label>
                        <ng-select
                            id="nonCanadianLocalCounterparty"
                            formControlName="nonCanadianLocalCounterparty"
                            [items]="dropdownData ? dropdownData.NonCanadianLocalCounterparties : null"
                        ></ng-select>
                    </div>

                    <!-- Last Updated On -->
                    <div class="control-box w-100">
                        <label for="lastUpdatedOn">Last Updated On</label>

                        <input
                            readonly
                            id="lastUpdatedOn"
                            [matDatepicker]="picker"
                            class="date-picker-padding"
                            formControlName="lastUpdatedOn"
                        >
                        <mat-datepicker-toggle [for]="picker" matIconSuffix class="custom-toggle"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </div>

                    <!-- Name Of Signatory -->
                    <div class="control-box w-100">
                        <label for="nameOfSignatory">Name Of Signatory</label>
                        <input id="nameOfSignatory" formControlName="nameOfSignatory">
                    </div>

                    <!-- Title Of Signatory -->
                    <div class="control-box w-100">
                        <label for="titleOfSignatory">Title Of Signatory</label>
                        <input id="titleOfSignatory" formControlName="titleOfSignatory">
                    </div>
                </div>
            </form>

            <!-- Save Block -->
            <div class="d-flex w-100 justify-end">
                <div class="d-flex save-wrapper">
                    <div class="d-flex flex-col">
                        <span class="last-updated">
                            <span class="updated-on-label">
                                Last Updated On:
                            </span>
                            <span class="updated-on-value">
                                {{ dataSource.DateLastUpdated | date }}
                            </span>
                        </span>
                        <div class="updated-by">
                            by Wiam Lou
                        </div>
                    </div>

                    <button class="save-button" [disabled]="ccclForm.pristine" (click)="updateCcclData()">Save</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #noDataBlock>
        <div class="d-flex justify-content-center">
            <no-data-component></no-data-component>
        </div>
    </ng-template>
</ng-template>
