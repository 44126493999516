import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {DocuLibraryService} from "../../../../../../../services/docu-library.service";

@Component({
  selector: 'delete-account-dialog',
  templateUrl: 'delete-archive-dialog.component.html',
  styleUrls: ['delete-archive-dialog.component.scss'],
})
export class DeleteAccountDialogComponent {
  constructor(
    private _snackBar: MatSnackBar,
    private docuLibService: DocuLibraryService,
    private dialogRef: MatDialogRef<DeleteAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { Id: number }
  ) {}

  public loading: boolean = false;

  public handleRemoveArchiveDocument() {
    this.docuLibService.deleteDocuLibAccountHistory(this.data.Id).subscribe(() => {
      this.docuLibService.deleteDocuLibAccount(this.data.Id).subscribe(() => {
        this.dialogRef.close(true);
      });
    })
  }
}
